<template>
    <div class="flex-1 sm:px-0 min-h-screen">
        <delete-Modal v-if="deleteModal" @deleteConfirmed="deleteStore" />
        <!-- Stores Card -->
        <div class="flex lgqw8 f0dty l66z3 d1k81 k3u76 tgfrq gl9vb dark:bg-neutral-800 dark:border-neutral-700">
            <!-- Header -->
            <div class="p-5 qyf7u jjege knnnf ftz41 s9mcu gap-2">
                <h2 class="inline-block kxhcs v7056 dark:text-neutral-200">
                    Stores
                </h2>

                <!-- Form Group -->
                <div class="flex hzyrh items-center gap-x-2">
                    <!-- Search Input -->
                    <div class="relative">
                        <div class="absolute vt6s4 atruh flex items-center i24sd ootlu ps-3">
                            <svg class="flex-shrink-0 sfo8l qcpnt fyxhw dark:text-neutral-400"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <circle cx="11" cy="11" r="8"></circle>
                                <path d="m21 21-4.3-4.3"></path>
                            </svg>
                        </div>
                        <input type="text"
                            class="py-1.5 vrf2y bdml7 ccpye mn7of block em545 yj6bp nq4w8 dtjcu focus:border-blue-500 focus:ring-blue-500 kko9e tk4f7 dark:bg-neutral-700 dark:border-transparent dark:text-neutral-400 dark:placeholder:text-neutral-400 dark:focus:ring-neutral-600"
                            placeholder="Search">
                    </div>
                    <!-- End Search Input -->

                    <!-- Button -->
                    <button type="button"
                        class="p-2 pqrvw items-center pla0f rvi38 kxhcs nq4w8 l66z3 yj6bp wpeco dafkk nkmbv kko9e tk4f7 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        data-hs-overlay="#hs-pro-createstore">
                        <svg class="hidden p6u16 wlxy3 sfo8l h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round">
                            <path d="M5 12h14"></path>
                            <path d="M12 5v14"></path>
                        </svg>
                        Add Store
                    </button>
                    <!-- End Button -->
                </div>
                <!-- End Form Group -->
            </div>
            <!-- End Header -->

            <!-- Nav Tab -->
            <div class="px-5">
                <nav class="relative flex e56at tau54 lwsuf a6pwg tjqz1 zsc42 dark:after:border-neutral-700"
                    aria-label="Tabs" role="tablist">
                    <button type="button"
                        class="hs-tab-active:after:bg-gray-800 qpfd1 kdz1q zdxcz tn07p relative pqrvw items-center dqqs4 zvbcs fyxhw p5a84 dtjcu nq4w8 kko9e tk4f7 focus:outline-none focus:bg-gray-100 tau54 ztotz a6pwg orkl1 kcadt aaznu dark:hs-tab-active:text-neutral-200 dark:hs-tab-active:after:bg-neutral-400 dark:text-neutral-500 dark:hover:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 active"
                        id="hs-pro-tabs-dupmp-item-open" data-hs-tab="#hs-pro-tabs-dupmp-open"
                        aria-controls="hs-pro-tabs-dupmp-open" role="tab">
                        <svg class="flex-shrink-0 sfo8l h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round">
                            <rect width="20" height="14" x="2" y="7" rx="2" ry="2"></rect>
                            <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                        </svg>
                        4 Open
                    </button>
                    <button type="button"
                        class="hs-tab-active:after:bg-gray-800 qpfd1 kdz1q zdxcz tn07p relative pqrvw items-center dqqs4 zvbcs fyxhw p5a84 dtjcu nq4w8 kko9e tk4f7 focus:outline-none focus:bg-gray-100 tau54 ztotz a6pwg orkl1 kcadt aaznu dark:hs-tab-active:text-neutral-200 dark:hs-tab-active:after:bg-neutral-400 dark:text-neutral-500 dark:hover:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                        id="hs-pro-tabs-dupmp-item-closed" data-hs-tab="#hs-pro-tabs-dupmp-closed"
                        aria-controls="hs-pro-tabs-dupmp-closed" role="tab">
                        <svg class="flex-shrink-0 sfo8l h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round">
                            <rect width="20" height="5" x="2" y="4" rx="2"></rect>
                            <path d="M4 9v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9"></path>
                            <path d="M10 13h4"></path>
                        </svg>
                        0 Closed
                    </button>
                </nav>
            </div>
            <!-- End Nav Tab -->

            <!-- Tab Content -->
            <div>
                <!-- Tab Content -->
                <div id="hs-pro-tabs-dupmp-open" role="tabpanel" aria-labelledby="hs-pro-tabs-dupmp-item-open" class="">
                    <!-- Stores List Group -->
                    <ul class="py-2 px-5" v-if="userStores != null">
                        <!-- List Item -->
                        <li class="py-3 bg8gm ih1jz d1k81 dark:border-neutral-700" v-for="store in userStores">
                            <div class="flex gap-x-3">
                                <span
                                    class="mt-1 flex wlxy3 pdrgo items-center w13ko e4kj6 f0dty l66z3 d1k81 nq4w8 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300">
                                    <img :src="store.logo" alt="" class="w-5 v834c fyxhw dark:text-neutral-500">
                                </span>
                                <div class="grow">
                                    <router-link
                                        class="font-medium v7056 amv69 focus:outline-none focus:text-blue-600 dark:text-neutral-200 dark:hover:text-blue-500 dark:focus:text-blue-500"
                                        :to="`/store/dashboard/${store._id}`">
                                        {{ store.store_name }}
                                    </router-link>
                                    <p class="text-xs fyxhw dark:text-neutral-500">
                                        {{ store.store_address }}
                                    </p>
                                </div>

                                <!-- Button Group -->
                                <div>
                                    <div
                                        class="flex l66z3 d1k81 hl4p4 gdqph nq4w8 -space-x-px dark:border-neutral-700 dark:divide-neutral-700">
                                        <!-- Close Store Button Tooltip -->
                                        <div class="hs-tooltip inline-block">
                                            <!-- Checkbox Button -->
                                            <label for="hs-pro-daicn1"
                                                class="relative xxfsd kdz1q w-full jrsb5 block zpy54 ya2lc nq4w8 cursor-pointer rvi38 eass7 focus:outline-none">

                                                <span v-if="StoreConnected(store._id)"
                                                    class="relative wlsmd pdrgo items-center pla0f hidden kykai zniu5 eot1b dark:text-white dark:peer-checked:text-white">
                                                    <svg class="flex-shrink-0 xtsb0 f5cx3 mt-0.5"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
                                                        <polyline points="20 6 9 17 4 12"></polyline>
                                                    </svg>
                                                    Connected
                                                </span>
                                                <div v-else class="relative uiqm5">
                                                    <input type="checkbox" id="hs-pro-epts1" @click="connectStore(store)"
                                                        class="relative wjffe uneb9 si6qh yj6bp tj0d4 iwtls cursor-pointer xgbpo le6lt kfd49 focus:ring-green-600 kko9e tk4f7 checked:bg-none checked:text-green-600 checked:border-green-600 focus:checked:border-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-900

                              o707b pjsxf u5yzy sll73 checked:before:bg-white uim48 checked:before:translate-x-full ud3ze odb7z hv39m dyuqh yr432 hjcrw fx7fi dark:before:bg-neutral-400 dark:checked:before:bg-white">
                                                </div>
                                            </label>
                                            <!-- End Checkbox Button -->
                                            <span
                                                class="hs-tooltip-content tnij2 hnhol opacity-0 uiqm5 absolute imt5j ootlu zdxcz kdz1q bg-gray-900 rvi38 dafkk nq4w8 dark:bg-neutral-700 hidden"
                                                role="tooltip" data-popper-placement="top"
                                                style="position: fixed; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(959px, -353px, 0px);">
                                                Store Settings
                                            </span>
                                        </div>
                                        <!-- End Close Store Button Tooltip -->
                                    </div>
                                </div>
                                <!-- End Button Group -->
                            </div>
                        </li>
                        <!-- End List Item -->

                    </ul>
                    <!-- End Stores List Group -->
                </div>
                <!-- Tab Content -->

                <!-- Tab Content -->
                <div id="hs-pro-tabs-dupmp-closed" role="tabpanel" class="hidden"
                    aria-labelledby="hs-pro-tabs-dupmp-item-closed">
                    <!-- Empty State -->
                    <div class="max-w-sm qazpp q2x60 flex lgqw8 pdrgo items-center zpy54 space-y-3">
                        <span
                            class="flex wlxy3 pdrgo items-center w13ko e4kj6 f0dty l66z3 d1k81 iwtls dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300">
                            <svg class="flex-shrink-0 sfo8l h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <rect width="20" height="14" x="2" y="7" rx="2" ry="2"></rect>
                                <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                            </svg>
                        </span>
                        <p class="text-sm fyxhw dark:text-neutral-500">
                            No closed Stores
                        </p>
                        <button type="button" data-hs-overlay="#hs-pro-createstore"
                            class="py-2 vrf2y pqrvw items-center dqqs4 dtjcu kxhcs nq4w8 l66z3 yj6bp wpeco dafkk nkmbv kko9e tk4f7 focus:outline-none focus:ring-2 focus:ring-blue-500">
                            <svg class="hidden p6u16 wlxy3 sfo8l h-4" xmlns="http://www.w3.org/2000/svg" width="24"
                                height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path d="M5 12h14"></path>
                                <path d="M12 5v14"></path>
                            </svg>
                            Add Store
                        </button>
                    </div>
                    <!-- End Empty State -->
                </div>
                <!-- Tab Content -->
            </div>
            <!-- Tab Content -->

            <!-- Footer -->
            <div class="text-center lz37y d1k81 dark:border-neutral-700 bottom-0 absolute w-full  lg:ps-64">
                <a class="p-3 flex pdrgo items-center azej2 dtjcu m7g7n eass7 a9vfa qhplw focus:outline-none focus:bg-gray-100 dark:text-blue-500 dark:hover:text-blue-600 dark:focus:bg-neutral-700"
                    href="#">


                </a>
            </div>
            <!-- End Footer -->
        </div>
        <!-- End Stores Card -->



        <div id="hs-pro-createstore"
            class="hidden w-full h-full fixed top-0 start-0 z-[60] overflow-x-hidden overflow-y-auto">
            <div
                class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all md:max-w-2xl md:w-full m-3 md:mx-auto">
                <div
                    class="relative flex flex-col bg-white border shadow-sm rounded-xl overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">
                    <div class="absolute top-2 end-2">
                        <button type="button"
                            class="flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-lg border border-transparent text-neutral-800 hover:bg-neutral-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:border-transparent dark:hover:bg-neutral-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
                            data-hs-overlay="#hs-pro-createstore">
                            <span class="sr-only">Close</span>
                            <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path d="M18 6 6 18" />
                                <path d="m6 6 12 12" />
                            </svg>
                        </button>
                    </div>

                    <div class="t6yan">
                        <!-- Store Settings Card -->
                        <div
                            class="pqx67 bcfme flex lgqw8 f0dty l66z3 ffqht tgfrq k3u76 dark:bg-neutral-800 dark:border-neutral-700">
                            <!-- Input Group -->
                            <div>
                                <label class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                    Logo
                                </label>

                                <div class="l2ej6 flex items-center ukt6v">
                                    <span
                                        class="flex pdrgo items-center f30ck m9pyi u40k2 nqwon dmrud qv2j8 iwtls dark:border-neutral-700 dark:text-neutral-600">
                                        <img :src="selectedFileDataUrl" />
                                    </span>

                                    <div class="zfftf">
                                        <div class="flex items-center dqqs4">
                                            <div class="mt-4 flex flex-wrap pdrgo dtjcu m11p8 text-stone-600">
                                                <label for="hs-pro-deuuf"
                                                    class="relative cursor-pointer f0dty kxhcs mq8xz w25pk nq4w8 cmffi cdcib pgmdi pi3re b9h40 q8e5i dark:bg-neutral-800 dark:text-green-500 dark:hover:text-green-400">
                                                    <span>
                                                        <vue-feather type="edit-2" />
                                                    </span>
                                                    <input id="hs-pro-deuuf" type="file" class="sr-only" name="hs-pro-deuuf"
                                                        @change="handleFileChange">
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End Input Group -->

                            <!-- Input Grid -->
                            <div class="jjege no4dk c6e0s otbql">
                                <!-- Input -->
                                <div>
                                    <label for="hs-pro-esssn" class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                        Store name
                                    </label>
                                    <input id="hs-pro-esssn" v-model="StoreName" type="text"
                                        class="xxfsd vrf2y block w-full ffqht nq4w8 dtjcu j4ei7 mjqc8 focus:z-10 focus:border-green-600 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                </div>
                                <!-- End Input -->

                                <!-- Input -->
                                <div>
                                    <label for="hs-pro-esssu" class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                        Store URL
                                    </label>
                                    <div class="relative">
                                        <input id="hs-pro-esssu" type="text" v-model="StoreURL"
                                            class="xxfsd c8qx6 otmcw block w-full ffqht nq4w8 dtjcu j4ei7 mjqc8 focus:z-10 focus:border-green-600 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                        <div class="absolute vt6s4 dy90t flex items-center i24sd ootlu og41d">
                                            <span class="dtjcu fj6be dark:text-neutral-500">.zookish.com</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- End Input -->

                                <!-- Input -->
                                <div>
                                    <label for="hs-pro-essce" class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                        Contact email
                                    </label>
                                    <input id="hs-pro-essce" type="email" v-model="ContactEmail"
                                        class="xxfsd vrf2y block w-full ffqht nq4w8 dtjcu j4ei7 mjqc8 focus:z-10 focus:border-green-600 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                </div>
                                <!-- End Input -->

                                <!-- Input -->
                                <div>
                                    <label for="hs-pro-esscp" class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                        Contact phone
                                    </label>
                                    <input id="hs-pro-esscp" type="text" v-model="ContactPhone"
                                        class="xxfsd vrf2y block w-full ffqht nq4w8 dtjcu j4ei7 mjqc8 focus:z-10 focus:border-green-600 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                </div>
                                <!-- End Input -->


                                <!-- End Input -->
                            </div>
                            <!-- End Input Grid -->

                            <!-- Input Grid -->
                            <div class="knmvi">
                                <div class="jjege no4dk c6e0s otbql">
                                    <div id="hs-wrapper-for-address-copy" class="bcfme">
                                        <!-- Input -->

                                        <!-- End Input -->
                                        <div id="hs-content-for-address-copy">
                                            <label for="hs-pro-esssa"
                                                class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                                Store address
                                            </label>
                                            <input id="hs-pro-esssa" type="email" v-model="StoreAddress"
                                                class="xxfsd vrf2y block w-full ffqht nq4w8 dtjcu j4ei7 mjqc8 focus:z-10 focus:border-green-600 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <!-- End Input Grid -->

                            <!-- Input Grid -->
                            <div class="jjege zvyci ib2k0 c6e0s otbql">
                                <!-- Input -->
                                <div class="dwnnx">
                                    <label for="hs-pro-essun" class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                        Apartment, Suite
                                    </label>
                                    <input id="hs-pro-essun" type="text" v-model="AddressApartmentSuite"
                                        class="xxfsd vrf2y block w-full ffqht nq4w8 dtjcu j4ei7 mjqc8 focus:z-10 focus:border-green-600 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                </div>
                                <!-- End Input -->

                                <!-- Input -->
                                <div class="dwnnx">
                                    <label for="hs-pro-esszc" class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                        ZIP / Postal code
                                    </label>
                                    <input id="hs-pro-esszc" type="text" v-model="AddressPostalCode"
                                        class="xxfsd vrf2y block w-full ffqht nq4w8 dtjcu j4ei7 mjqc8 focus:z-10 focus:border-green-600 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                </div>
                                <!-- End Input -->

                                <!-- Input -->
                                <div class="r40h3">
                                    <label for="hs-pro-esscy" class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                        City
                                    </label>
                                    <input id="hs-pro-esscy" type="text" v-model="AddressCity"
                                        class="xxfsd vrf2y block w-full ffqht nq4w8 dtjcu j4ei7 mjqc8 focus:z-10 focus:border-green-600 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                </div>
                                <!-- End Input -->
                            </div>
                            <!-- End Input Grid -->

                        </div>
                        <!-- End Store Settings Card -->
                    </div>
                    <!-- End Col -->

                    <div
                        class="flex justify-end items-center gap-x-2 py-3 px-4 bg-neutral-50 border-t dark:bg-neutral-800 dark:border-neutral-700">
                        <button type="button"
                            class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-neutral-200 bg-white text-neutral-800 shadow-sm hover:bg-neutral-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
                            data-hs-overlay="#hs-pro-createstore">
                            Cancel
                        </button>
                        <button data-hs-overlay="#hs-pro-createstore" @click="CreateStore"
                            class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-green-500 text-white hover:bg-green-600 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                            Create
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <success v-if="hasSuccess" :message="message" @ack="hasSuccess = false" />
</template>

<script>
import EcomMixin from '@/mixins/EcomMixin'
import ImageMixin from '@/mixins/ImageMixin'


export default {
    data: () => {
        return {
            StoreName: '',
            StoreURL: '',
            ContactEmail: '',
            ContactPhone: '',
            Currency: '',
            StoreAddress: '',
            AddressApartmentSuite: '',
            AddressPostalCode: '',
            AddressCity: '',
        }
    },
    mixins: [EcomMixin, ImageMixin],
    mounted() {
        this.GetUserStores()
    }
}
</script>