// stores/HostStore.js
import { defineStore } from 'pinia';

export const useHostStore = defineStore('host', {
  state: () => ({
    hostname: `https://zookish.com`,
    shop: {},
    siteData: {
      title: 'My website',
      description: 'My beautiful website',
    },
  }),
  actions: {
    setShop(r) {
      this.shop = r
    },
    setSiteData(r) {
      this.siteData = r;
    },
    getSiteData() {
      return this.siteData;
    },
  }
});
// beth@kenic.or.ke
