<template>
    <navigation v-if="$route.name == 'Store Settings'" />
    <main id="content" class="bg-neutral-900  min-h-screen" role="main">
        <div class="cl6bi pgrdw qbu9t r0x22 qazpp">
            <!-- Breadcrumb -->
            <ol class="bzhis qsf3i flex items-center mfkro" aria-label="Breadcrumb" v-if="$route.name == 'Store Settings'">
                <li class="flex items-center dtjcu v474b dark:text-neutral-500">
                    Others
                    <svg class="wlxy3 lo4kb trvun qcpnt sfo8l qv2j8 dark:text-neutral-600"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path d="m9 18 6-6-6-6"></path>
                    </svg>
                </li>
                <li class="dtjcu kxhcs j4ei7 truncate dark:text-neutral-200" aria-current="page">
                    Store
                </li>
            </ol>
            <!-- End Breadcrumb -->

            <div class="xxfsd ugq3y sly8f bcfme">
                <!-- Page Header -->
                <div>
                    <h1 class="tn07p hbd69 p1q58 kxhcs j4ei7 dark:text-neutral-200">
                        Store
                    </h1>
                    <!-- End Page Header -->

                    <!-- Store Nav Link -->
                    <nav v-if="$route.name == 'Store Settings'"
                        class="relative flex e56at tau54 lwsuf a6pwg pqyi6 ywzje dark:after:border-neutral-700">
                        <a
                            class="kdz1q zdxcz tn07p relative pqrvw items-center dqqs4 mmcy8 fj6be dtjcu nq4w8 kko9e tk4f7 focus:outline-none focus:bg-stone-200/50 tau54 ztotz a6pwg a6xvj kcadt aaznu dark:hover:bg-neutral-700/50 dark:focus:bg-neutral-700/50 p2vge j4ei7 fckxo dark:after:bg-neutral-400 dark:text-neutral-200">
                            Overview
                        </a>
                        <router-link
                            class="kdz1q zdxcz tn07p relative pqrvw items-center dqqs4 mmcy8 fj6be dtjcu nq4w8 kko9e tk4f7 focus:outline-none focus:bg-stone-200/50 tau54 ztotz a6pwg a6xvj kcadt aaznu dark:hover:bg-neutral-700/50 dark:focus:bg-neutral-700/50 "
                            :to="`/store/payouts/${$route.params.id}`">
                            Payouts
                        </router-link>
                    </nav>
                </div>
                <!-- End Store Nav Link -->

                <!-- Form -->
                <form>
                    <!-- Store Settings -->
                    <div class="xxz9p zdbyx bcfme lz37y ffqht x6keb dark:border-neutral-700">
                        <!-- Grid -->
                        <div class="jjege oh4rk lc276 m9ksf okw5h yumkl cqb3l">
                            <div class="yxdqn flex lgqw8 n8wpz zc0ft">
                                <div class="mpjk1">
                                    <h2 class="eass7 j4ei7 dark:text-neutral-200">
                                        Store Settings
                                    </h2>

                                    <p class="dtjcu fj6be dark:text-neutral-500">
                                        View and update your store details.
                                    </p>
                                </div>
                            </div>
                            <!-- End Col -->

                            <div class="g8ngf">
                                <!-- Store Settings Card -->
                                <div
                                    class="pqx67 bcfme flex lgqw8 f0dty l66z3 ffqht tgfrq k3u76 dark:bg-neutral-800 dark:border-neutral-700">
                                    <!-- Input Group -->
                                    <div>
                                        <label class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                            Logo
                                        </label>

                                        <div class="l2ej6 flex items-center ukt6v">
                                            <span
                                                class="flex pdrgo items-center f30ck m9pyi u40k2 nqwon dmrud qv2j8 iwtls dark:border-neutral-700 dark:text-neutral-600">
                                                <img :src="selectedFileDataUrl" />
                                            </span>

                                            <div class="zfftf">
                                                <div class="flex items-center dqqs4">
                                                    <div class="mt-4 flex flex-wrap pdrgo dtjcu m11p8 text-stone-600">
                                                        <label for="hs-pro-deuuf"
                                                            class="relative cursor-pointer f0dty kxhcs mq8xz w25pk nq4w8 cmffi cdcib pgmdi pi3re b9h40 q8e5i dark:bg-neutral-800 dark:text-green-500 dark:hover:text-green-400">
                                                            <span>
                                                                <vue-feather type="edit-2" />
                                                            </span>
                                                            <input id="hs-pro-deuuf" type="file" class="sr-only"
                                                                name="hs-pro-deuuf" @change="handleFileChange">
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <button @click="uploadFile" type="button"
                                                class="xxfsd vrf2y pqrvw items-center dqqs4 rvi38 kxhcs nq4w8 l66z3 yj6bp k6q5b dafkk zwj7f kko9e tk4f7 focus:outline-none focus:ring-2 focus:ring-green-500">
                                                <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24"
                                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="17 8 12 3 7 8"></polyline>
                                                    <line x1="12" x2="12" y1="3" y2="15"></line>
                                                </svg>
                                                Upload photo
                                            </button>
                                        </div>
                                    </div>
                                    <!-- End Input Group -->

                                    <div>
                                        <label for="hs-color-input"
                                            class="block text-sm font-medium mb-2 dark:text-white">Primary Brand
                                            Color</label>
                                        <input type="color" v-model="primaryColor"
                                            class="p-1 h-10 w-14 block bg-white border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-gray-700"
                                            id="hs-color-input" title="Choose your color">
                                    </div>
                                    <!-- Input Grid -->
                                    <div class="jjege no4dk c6e0s otbql">
                                        <!-- Input -->
                                        <div>
                                            <label for="hs-pro-esssn"
                                                class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                                Store name
                                            </label>
                                            <input id="hs-pro-esssn" v-model="StoreName" type="text"
                                                class="xxfsd vrf2y block w-full ffqht nq4w8 dtjcu j4ei7 mjqc8 focus:z-10 focus:border-green-600 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                        </div>
                                        <!-- End Input -->

                                        <!-- Input -->
                                        <div>
                                            <label for="hs-pro-esssu"
                                                class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                                Store URL
                                            </label>
                                            <div class="relative">
                                                <input id="hs-pro-esssu" type="text" v-model="StoreURL"
                                                    class="xxfsd c8qx6 otmcw block w-full ffqht nq4w8 dtjcu j4ei7 mjqc8 focus:z-10 focus:border-green-600 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                                <div class="absolute vt6s4 dy90t flex items-center i24sd ootlu og41d">
                                                    <span class="dtjcu fj6be dark:text-neutral-500">.zookish.com</span>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- End Input -->

                                        <!-- Input -->
                                        <div>
                                            <label for="hs-pro-essce"
                                                class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                                Contact email
                                            </label>
                                            <input id="hs-pro-essce" type="email" v-model="ContactEmail"
                                                class="xxfsd vrf2y block w-full ffqht nq4w8 dtjcu j4ei7 mjqc8 focus:z-10 focus:border-green-600 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                        </div>
                                        <!-- End Input -->

                                        <!-- Input -->
                                        <div>
                                            <label for="hs-pro-esscp"
                                                class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                                Contact phone
                                            </label>
                                            <input id="hs-pro-esscp" type="text" v-model="ContactPhone"
                                                class="xxfsd vrf2y block w-full ffqht nq4w8 dtjcu j4ei7 mjqc8 focus:z-10 focus:border-green-600 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                        </div>
                                        <!-- End Input -->


                                        <!-- End Input -->
                                    </div>
                                    <!-- End Input Grid -->

                                    <!-- Input Grid -->
                                    <div class="knmvi">
                                        <div class="jjege no4dk c6e0s otbql">
                                            <div id="hs-wrapper-for-address-copy" class="bcfme">
                                                <!-- Input -->

                                                <!-- End Input -->
                                                <div id="hs-content-for-address-copy">
                                                    <label for="hs-pro-esssa"
                                                        class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                                        Store address
                                                    </label>
                                                    <input id="hs-pro-esssa" type="email" v-model="StoreAddress"
                                                        class="xxfsd vrf2y block w-full ffqht nq4w8 dtjcu j4ei7 mjqc8 focus:z-10 focus:border-green-600 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                    <!-- End Input Grid -->

                                    <!-- Input Grid -->
                                    <div class="jjege zvyci ib2k0 c6e0s otbql">
                                        <!-- Input -->
                                        <div class="dwnnx">
                                            <label for="hs-pro-essun"
                                                class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                                Apartment, Suite
                                            </label>
                                            <input id="hs-pro-essun" type="text" v-model="AddressApartmentSuite"
                                                class="xxfsd vrf2y block w-full ffqht nq4w8 dtjcu j4ei7 mjqc8 focus:z-10 focus:border-green-600 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                        </div>
                                        <!-- End Input -->

                                        <!-- Input -->
                                        <div class="dwnnx">
                                            <label for="hs-pro-esszc"
                                                class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                                ZIP / Postal code
                                            </label>
                                            <input id="hs-pro-esszc" type="text" v-model="AddressPostalCode"
                                                class="xxfsd vrf2y block w-full ffqht nq4w8 dtjcu j4ei7 mjqc8 focus:z-10 focus:border-green-600 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                        </div>
                                        <!-- End Input -->

                                        <!-- Input -->
                                        <div class="r40h3">
                                            <label for="hs-pro-esscy"
                                                class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                                City
                                            </label>
                                            <input id="hs-pro-esscy" type="text" v-model="AddressCity"
                                                class="xxfsd vrf2y block w-full ffqht nq4w8 dtjcu j4ei7 mjqc8 focus:z-10 focus:border-green-600 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                        </div>
                                        <!-- End Input -->
                                    </div>
                                    <!-- End Input Grid -->

                                </div>
                                <!-- End Store Settings Card -->

                                <!-- Button Group -->
                                <div class="vd2z8 flex prqc8 ukt6v">
                                    <button type="button"
                                        class="xxfsd vrf2y pqrvw items-center dqqs4 dtjcu eass7 nq4w8 l66z3 ffqht f0dty j4ei7 tgfrq jonah kko9e tk4f7 focus:outline-none focus:bg-stone-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                                        Cancel
                                    </button>
                                    <button type="button" @click="UpdateStore"
                                        class="xxfsd vrf2y pqrvw items-center dqqs4 dtjcu kxhcs nq4w8 l66z3 yj6bp k6q5b dafkk zwj7f kko9e tk4f7 focus:outline-none focus:ring-2 focus:ring-green-500">
                                        Save changes
                                    </button>
                                </div>
                                <!-- End Button Group -->
                            </div>
                            <!-- End Col -->
                        </div>
                        <!-- End Grid -->
                    </div>
                    <!-- End Store Settings -->

                </form>
                <!-- End Form -->
            </div>
        </div>
    </main>
</template>
<script>
import navigation from '@/views/ecommerce/navigation.vue'
import ImageMixin from '@/mixins/ImageMixin'
import EcomMixin from '@/mixins/EcomMixin'

export default {
    data: () => {
        return {
            StoreName: '',
            StoreURL: '',
            ContactEmail: '',
            ContactPhone: '',
            Currency: '',
            StoreAddress: '',
            AddressApartmentSuite: '',
            AddressPostalCode: '',
            AddressCity: '',
            primaryColor: '',
        }
    },
    mixins: [ImageMixin, EcomMixin],
    components: {
        navigation
    },
    mounted() {
        this.GetStore()
    },
    methods: {

    }
}
</script>