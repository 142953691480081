<template>
    <div class="flex lgqw8 f0dty l66z3 d1k81 k3u76 dark:bg-neutral-800 dark:border-neutral-700">
        <div class="pt-2.5 px-4">
            <h2 class="font-semibold v7056 dark:text-white">
                Timesheet
            </h2>
        </div>

        <!-- Body -->
        <div class="p-4">
            <!-- Progress -->
            <div class="flex azaj8 items-center ukt6v zdxcz sm:py-2">
                <span class="font-semibold dtjcu v7056 dark:text-white">
                    Sep 4 - Sep 9
                </span>
                <span class="font-semibold dtjcu v7056 dark:text-white">
                    Total hours
                </span>
            </div>
            <!-- End Progress -->

            <!-- Progress -->
            <div class="grid q5jxk items-center uxqty zdxcz sm:py-2">
                <!-- Date -->
                <div class="lg:order-1 cef1k lg:col-span-3">
                    <h3 class="text-sm v7056 dark:text-white">Mon, 4/9</h3>
                </div>
                <!-- End Date -->

                <!-- Time -->
                <div class="lg:order-3 cef1k vgwbj text-end">
                    <div class="hs-tooltip uiqm5 [--placement:left] md:[--placement:right]">
                        <span class="hs-tooltip-toggle pqrvw items-center azej2 dtjcu v7056 dark:text-white">
                            8:27
                            <span
                                class="md:hidden drlfu grnyh f0dty l66z3 d1k81 fyxhw rvi38 iwtls dark:bg-neutral-800 dark:border-neutral-700">
                                <span class="text-red-500">-33m</span> under
                            </span>
                            <svg class="hidden lnkfu wlxy3 xtsb0 f5cx3 qkm2y dark:text-neutral-500"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <circle cx="12" cy="12" r="10"></circle>
                                <path d="M12 16v-4"></path>
                                <path d="M12 8h.01"></path>
                            </svg>
                            <span
                                class="hs-tooltip-content tnij2 hnhol opacity-0 l46pc uiqm5 absolute imt5j wlsmd npcr1 grnyh f0dty l66z3 d1k81 rvi38 eass7 v7056 iwtls tgfrq dark:bg-neutral-800 dark:border-neutral-700 dark:text-white"
                                role="tooltip" data-popper-placement="right"
                                style="position: fixed; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(825.5px, 112.5px, 0px);">
                                <span class="text-red-500">-33m</span> under limit
                            </span>
                        </span>
                    </div>
                </div>
                <!-- End Time -->

                <!-- Step Progress -->
                <div class="lg:order-2 t6yan tfeug q8w6j fpiqe w-full flex gd6ob items-center gap-x-1.5">
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <!-- End Step Progress -->
            </div>
            <!-- End Progress -->

            <!-- Progress -->
            <div class="grid q5jxk items-center uxqty zdxcz sm:py-2">
                <!-- Date -->
                <div class="lg:order-1 cef1k lg:col-span-3">
                    <h3 class="text-sm v7056 dark:text-white">Tue, 5/9</h3>
                </div>
                <!-- End Date -->

                <!-- Time -->
                <div class="lg:order-3 cef1k vgwbj text-end">
                    <div class="hs-tooltip uiqm5 [--placement:left] md:[--placement:right]">
                        <span class="hs-tooltip-toggle pqrvw items-center azej2 dtjcu v7056 dark:text-white">
                            4:50
                            <span
                                class="md:hidden drlfu grnyh f0dty l66z3 d1k81 fyxhw rvi38 iwtls dark:bg-neutral-800 dark:border-neutral-700">
                                <span class="text-red-500">-4h 10m</span> under
                            </span>
                            <svg class="hidden lnkfu wlxy3 xtsb0 f5cx3 qkm2y dark:text-neutral-500"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <circle cx="12" cy="12" r="10"></circle>
                                <path d="M12 16v-4"></path>
                                <path d="M12 8h.01"></path>
                            </svg>
                            <span
                                class="hs-tooltip-content tnij2 hnhol opacity-0 l46pc uiqm5 absolute imt5j wlsmd npcr1 grnyh f0dty l66z3 d1k81 rvi38 eass7 v7056 iwtls tgfrq dark:bg-neutral-800 dark:border-neutral-700 dark:text-white"
                                role="tooltip" data-popper-placement="left"
                                style="position: fixed; inset: 0px 0px auto auto; margin: 0px; transform: translate3d(-187.5px, 158.5px, 0px);">
                                <span class="text-red-500">-4h 10m</span> under limit
                            </span>
                        </span>
                    </div>
                </div>
                <!-- End Time -->

                <!-- Step Progress -->
                <div class="lg:order-2 t6yan tfeug q8w6j fpiqe w-full flex gd6ob items-center gap-x-1.5">
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <!-- End Step Progress -->
            </div>
            <!-- End Progress -->

            <!-- Progress -->
            <div class="grid q5jxk items-center uxqty zdxcz sm:py-2">
                <!-- Date -->
                <div class="lg:order-1 cef1k lg:col-span-3">
                    <h3 class="text-sm v7056 dark:text-white">Wed, 6/9</h3>
                </div>
                <!-- End Date -->

                <!-- Time -->
                <div class="lg:order-3 cef1k vgwbj text-end">
                    <div class="hs-tooltip uiqm5 [--placement:left] md:[--placement:right]">
                        <span class="hs-tooltip-toggle pqrvw items-center azej2 dtjcu v7056 dark:text-white">
                            7:43
                            <span
                                class="md:hidden drlfu grnyh f0dty l66z3 d1k81 fyxhw rvi38 iwtls dark:bg-neutral-800 dark:border-neutral-700">
                                <span class="text-red-500">-1h 17m</span> under
                            </span>
                            <svg class="hidden lnkfu wlxy3 xtsb0 f5cx3 qkm2y dark:text-neutral-500"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <circle cx="12" cy="12" r="10"></circle>
                                <path d="M12 16v-4"></path>
                                <path d="M12 8h.01"></path>
                            </svg>
                            <span
                                class="hs-tooltip-content tnij2 hnhol opacity-0 l46pc uiqm5 absolute imt5j wlsmd npcr1 grnyh f0dty l66z3 d1k81 rvi38 eass7 v7056 iwtls tgfrq dark:bg-neutral-800 dark:border-neutral-700 dark:text-white hidden"
                                role="tooltip" data-popper-placement="right"
                                style="position: fixed; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(825.5px, 204.5px, 0px);">
                                <span class="text-red-500">-1h 17m</span> under limit
                            </span>
                        </span>
                    </div>
                </div>
                <!-- End Time -->

                <!-- Step Progress -->
                <div class="lg:order-2 t6yan tfeug q8w6j fpiqe w-full flex gd6ob items-center gap-x-1.5">
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <!-- End Step Progress -->
            </div>
            <!-- End Progress -->

            <!-- Progress -->
            <div class="grid q5jxk items-center uxqty zdxcz sm:py-2">
                <!-- Date -->
                <div class="lg:order-1 cef1k lg:col-span-3">
                    <h3 class="text-sm v7056 dark:text-white">Thu, 7/9</h3>
                </div>
                <!-- End Date -->

                <!-- Time -->
                <div class="lg:order-3 cef1k vgwbj text-end">
                    <div class="hs-tooltip uiqm5 [--placement:left] md:[--placement:right]">
                        <span class="hs-tooltip-toggle pqrvw items-center azej2 dtjcu v7056 dark:text-white">
                            9:09
                            <span
                                class="md:hidden drlfu grnyh f0dty l66z3 d1k81 fyxhw rvi38 iwtls dark:bg-neutral-800 dark:border-neutral-700">
                                <span class="text-teal-500">+9m</span> over
                            </span>
                            <svg class="hidden lnkfu wlxy3 xtsb0 f5cx3 qkm2y dark:text-neutral-500"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <circle cx="12" cy="12" r="10"></circle>
                                <path d="M12 16v-4"></path>
                                <path d="M12 8h.01"></path>
                            </svg>
                            <span
                                class="hs-tooltip-content tnij2 hnhol opacity-0 l46pc uiqm5 absolute imt5j wlsmd npcr1 grnyh f0dty l66z3 d1k81 rvi38 eass7 v7056 iwtls tgfrq dark:bg-neutral-800 dark:border-neutral-700 dark:text-white"
                                role="tooltip" data-popper-placement="right"
                                style="position: fixed; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(825.5px, 250.5px, 0px);">
                                <span class="text-teal-500">+9m</span> over limit
                            </span>
                        </span>
                    </div>
                </div>
                <!-- End Time -->

                <!-- Step Progress -->
                <div class="lg:order-2 t6yan tfeug q8w6j fpiqe w-full flex gd6ob items-center gap-x-1.5">
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <!-- End Step Progress -->
            </div>
            <!-- End Progress -->

            <!-- Progress -->
            <div class="grid q5jxk items-center uxqty zdxcz sm:py-2">
                <!-- Date -->
                <div class="lg:order-1 cef1k lg:col-span-3">
                    <h3 class="text-sm v7056 dark:text-white">Fri, 8/9</h3>
                </div>
                <!-- End Date -->

                <!-- Time -->
                <div class="lg:order-3 cef1k vgwbj text-end">
                    <div class="hs-tooltip uiqm5 [--placement:left] md:[--placement:right]">
                        <span class="hs-tooltip-toggle pqrvw items-center azej2 dtjcu v7056 dark:text-white">
                            9:02
                            <span
                                class="md:hidden drlfu grnyh f0dty l66z3 d1k81 fyxhw rvi38 iwtls dark:bg-neutral-800 dark:border-neutral-700">
                                <span class="text-teal-500">+2m</span> over
                            </span>
                            <svg class="hidden lnkfu wlxy3 xtsb0 f5cx3 qkm2y dark:text-neutral-500"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <circle cx="12" cy="12" r="10"></circle>
                                <path d="M12 16v-4"></path>
                                <path d="M12 8h.01"></path>
                            </svg>
                            <span
                                class="hs-tooltip-content tnij2 hnhol opacity-0 l46pc uiqm5 absolute imt5j wlsmd npcr1 grnyh f0dty l66z3 d1k81 rvi38 eass7 v7056 iwtls tgfrq dark:bg-neutral-800 dark:border-neutral-700 dark:text-white"
                                role="tooltip" data-popper-placement="right"
                                style="position: fixed; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(825.5px, 296.5px, 0px);">
                                <span class="text-teal-500">+2m</span> over limit
                            </span>
                        </span>
                    </div>
                </div>
                <!-- End Time -->

                <!-- Step Progress -->
                <div class="lg:order-2 t6yan tfeug q8w6j fpiqe w-full flex gd6ob items-center gap-x-1.5">
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 ohynj rvi38 dafkk zpy54 mfkro iwtls transition duration-500"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <!-- End Step Progress -->
            </div>
            <!-- End Progress -->

            <!-- Progress -->
            <div class="grid q5jxk items-center uxqty zdxcz sm:py-2">
                <!-- Date -->
                <div class="lg:order-1 cef1k lg:col-span-3">
                    <h3 class="text-sm v7056 dark:text-white">Sat, 9/9</h3>
                </div>
                <!-- End Date -->

                <!-- Time -->
                <div class="lg:order-3 cef1k vgwbj text-end">
                    <div class="hs-tooltip uiqm5 [--placement:left] md:[--placement:right]">
                        <span class="hs-tooltip-toggle pqrvw items-center azej2 dtjcu v7056 dark:text-white">
                            0:00
                            <svg class="hidden lnkfu wlxy3 xtsb0 f5cx3 qkm2y dark:text-neutral-500"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <circle cx="12" cy="12" r="10"></circle>
                                <path d="M12 16v-4"></path>
                                <path d="M12 8h.01"></path>
                            </svg>
                            <span
                                class="hs-tooltip-content tnij2 hnhol opacity-0 l46pc uiqm5 absolute imt5j wlsmd npcr1 grnyh f0dty l66z3 d1k81 rvi38 eass7 v7056 iwtls tgfrq dark:bg-neutral-800 dark:border-neutral-700 dark:text-white"
                                role="tooltip" data-popper-placement="right"
                                style="position: fixed; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(825.5px, 342.5px, 0px);">
                                0s
                            </span>
                        </span>
                    </div>
                </div>
                <!-- End Time -->

                <!-- Step Progress -->
                <div class="lg:order-2 t6yan tfeug q8w6j fpiqe w-full flex gd6ob items-center gap-x-1.5">
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <!-- End Step Progress -->
            </div>
            <!-- End Progress -->

            <!-- Progress -->
            <div class="grid q5jxk items-center uxqty zdxcz sm:py-2">
                <!-- Date -->
                <div class="lg:order-1 cef1k lg:col-span-3">
                    <h3 class="text-sm v7056 dark:text-white">Sun, 10/9</h3>
                </div>
                <!-- End Date -->

                <!-- Time -->
                <div class="lg:order-3 cef1k vgwbj text-end">
                    <div class="hs-tooltip uiqm5 [--placement:left] md:[--placement:right]">
                        <span class="hs-tooltip-toggle pqrvw items-center azej2 dtjcu v7056 dark:text-white">
                            2:35
                            <span
                                class="md:hidden drlfu grnyh f0dty l66z3 d1k81 fyxhw rvi38 iwtls dark:bg-neutral-800 dark:border-neutral-700">
                                <span class="text-red-500">-6h 25m</span>
                            </span>
                            <svg class="hidden lnkfu wlxy3 xtsb0 f5cx3 qkm2y dark:text-neutral-500"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <circle cx="12" cy="12" r="10"></circle>
                                <path d="M12 16v-4"></path>
                                <path d="M12 8h.01"></path>
                            </svg>
                            <span
                                class="hs-tooltip-content tnij2 hnhol opacity-0 l46pc uiqm5 absolute imt5j wlsmd npcr1 grnyh f0dty l66z3 d1k81 rvi38 eass7 v7056 iwtls tgfrq dark:bg-neutral-800 dark:border-neutral-700 dark:text-white"
                                role="tooltip" data-popper-placement="left"
                                style="position: fixed; inset: 0px 0px auto auto; margin: 0px; transform: translate3d(-187px, 388.5px, 0px);">
                                <span class="text-red-500">-6h 25m</span> under limit
                            </span>
                        </span>
                    </div>
                </div>
                <!-- End Time -->

                <!-- Step Progress -->
                <div class="lg:order-2 t6yan tfeug q8w6j fpiqe w-full flex gd6ob items-center gap-x-1.5">
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 x9qsc rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-400"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="w-2.5 vzn8i flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro iwtls transition kt6fi dark:bg-neutral-700"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <!-- End Step Progress -->
            </div>
            <!-- End Progress -->
        </div>
        <!-- End Body -->
    </div>
</template>