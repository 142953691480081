<template>
  <div class="h-full flex lgqw8 f0dty l66z3 d1k81 tgfrq k3u76 dark:bg-neutral-800 dark:border-neutral-700">
    <!-- Header -->
    <div class="p-5 i38gt flex azaj8 items-center">
      <h2 class="ms-1 uiqm5 kxhcs v7056 dark:text-neutral-200">
        Browsers
      </h2>

      <span
        class="py-1 up4xi sfr0b pqrvw items-center azej2 rvi38 eass7 iwtls f0dty l66z3 d1k81 v7056 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200">
        <svg class="flex-shrink-0 pmkhf h-3" width="16" height="16" viewBox="0 0 16 16" fill="currentColor"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z">
          </path>
        </svg>
        Good
      </span>
    </div>
    <!-- End Header -->

    <!-- Body -->
    <div class="flex lgqw8 azaj8 raxhy d0r9n px-5">
      <div>
        <h4 class="text-5xl vnbrj eass7 m7g7n dark:text-blue-500">
          <span class="bg-clip-text meac2 ofze3 hy8cn text-transparent">
            85%
          </span>
        </h4>

        <p class="mt-5 fyxhw dark:text-neutral-500">
          Visitors are viewing website from the desktop device. 57% of all users are using MacOS
        </p>
      </div>

      <!-- Stats -->
      <div class="mt-5">
        <!-- Grid -->
        <div class="grid d18u6 gap-3">
          <!-- Card -->
          <div class="p-3 em545 nq4w8 dark:bg-neutral-700">
            <img class="flex-shrink-0 urc6e fnype mb-4" src="@/assets/chrome.svg" alt="Chrome Logo">
            <p class="text-sm v7056 dark:text-neutral-200">
              Chrome
            </p>
            <p class="font-semibold hbd69 v7056 dark:text-neutral-200">
              56%
            </p>
          </div>
          <!-- End Card -->

          <!-- Card -->
          <div class="p-3 em545 nq4w8 dark:bg-neutral-700">
            <img class="flex-shrink-0 urc6e fnype mb-4" src="@/assets/firefox.svg" alt="Firefox Logo">
            <p class="text-sm v7056 dark:text-neutral-200">
              Firefox
            </p>
            <p class="font-semibold hbd69 v7056 dark:text-neutral-200">
              24%
            </p>
          </div>
          <!-- End Card -->

          <!-- Card -->
          <div class="p-3 em545 nq4w8 dark:bg-neutral-700">
            <img class="flex-shrink-0 urc6e fnype mb-4" src="@/assets/safari.svg" alt="Safari Logo">
            <p class="text-sm v7056 dark:text-neutral-200">
              Safari
            </p>
            <p class="font-semibold hbd69 v7056 dark:text-neutral-200">
              17%
            </p>
          </div>
          <!-- End Card -->
        </div>
        <!-- End Grid -->
      </div>
      <!-- End Stats -->

      <!-- Alert -->
      <div class="mt-5 l66z3 d1k81 k3u76 tgfrq xxfsd vrf2y dark:border-neutral-700" role="alert">
        <div class="flex gap-x-3">
          <svg class="mt-1 wlxy3 hahf5 v834c v7056 dark:text-neutral-200" xmlns="http://www.w3.org/2000/svg" width="24"
            height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round">
            <path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9"></path>
            <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0"></path>
          </svg>
          <div class="grow">
            <h3 class="text-sm v7056 kxhcs dark:text-white">
              Push notifications
            </h3>
            <p class="text-sm trxwx dark:text-neutral-400">
              Automatically send me notifications
            </p>
          </div>

          <!-- Switch/Toggle -->
          <div class="flex azaj8 items-center">
            <label for="hs-pro-dbcpns" class="sr-only">On</label>
            <div class="relative inline-block">
              <input type="checkbox" id="hs-pro-dbcpns"
                class="relative xlbew fnype o5z6a em545 yj6bp tj0d4 iwtls cursor-pointer xgbpo le6lt kfd49 focus:ring-blue-600 kko9e tk4f7 checked:bg-none checked:text-blue-600 checked:border-blue-600 focus:checked:border-blue-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-neutral-900

                                    o707b mudve kjsto sll73 checked:before:bg-white uim48 checked:before:translate-x-full ud3ze odb7z hv39m dyuqh yr432 hjcrw fx7fi dark:before:bg-neutral-400 dark:checked:before:bg-white"
                checked="">
            </div>
          </div>
          <!-- End Switch/Toggle -->
        </div>
      </div>
      <!-- End Alert -->
    </div>
    <!-- End Body -->
  </div>
</template>
