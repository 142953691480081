<template>
    <!-- Sidebar -->
    <div dir="rtl"
        class="fixed z-infinity top-0 bg-black bottom-0 z-[60] right-0 border-e border-neutral-800 pt-7 pb-10 overflow-y-auto lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 ">
        <div class="relative w-screen max-w-xl px-4 py-8 lg:px-6 lg:px-8" aria-modal="true" role="dialog" tabindex="-1">
            <button class="absolute end-4 top-4 text-[${ project.store.primary_color }]-600 transition hover:scale-110"
                @click="$emit('close')">
                <span class="sr-only">Close cart</span>

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="h-5 w-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>

            <div class="mt-4 space-y-6">
                <div class="max-w-lg qazpp q2x60 flex lgqw8 pdrgo items-center zpy54 space-y-3" v-if="cart.length == 0">
                    <vue-feather type="shopping-cart" />
                    <p class="text-lg fyxhw dark:text-neutral-500">
                        Empty Cart
                    </p>

                </div>
                <ul class="space-y-4">
                    <li class="flex items-center gap-4" v-for="item in cart">

                        <img :src="item.images[0]" alt="" class="size-16 rounded object-cover" />

                        <div>
                            <h3 class="text-lg ">{{ item.name }}</h3>

                            <dl class="mt-0.5 space-y-px text-[10px]">
                                <div>
                                    <dt class="inline">Category:</dt>
                                    <dd class="inline">{{ item.category }}</dd>
                                </div>
                            </dl>
                        </div>

                        <div class="flex flex-1 items-center justify-end gap-2">
                            <form>
                                <label for="Line1Qty" class="sr-only"> Quantity </label>

                                <input type="number" min="1" value="1" id="Line1Qty"
                                    class="h-8 w-12 bg-transparent rounded p-0 text-center text-xs [-moz-appearance:_textfield] focus:outline-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none" />
                            </form>

                            <button class="transition hover:text-red-600">
                                <span class="sr-only">Remove item</span>

                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                    stroke="currentColor" class="h-4 w-4">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                            </button>
                        </div>
                    </li>
                </ul>

                <div class="space-y-4 text-center">
                    <a href="#" :class="`block rounded border px-5 py-3 text-lg transition hover:ring-1`">
                        Checkout ({{ cart.length }})
                    </a>

                    <a href="#" :class="`inline-block text-lg underline underline-offset-4 transition `">
                        Continue shopping
                    </a>
                </div>
            </div>
        </div>
    </div>
    <!-- End Sidebar -->
</template>
<script>
import { useCartStore } from "@/stores/CartStore"
import EcomMixin from "@/mixins/EcomMixin"


export default {
    props: ['project'],
    mixins: [EcomMixin],
    emits: ['close'],
    data: () => {
        return {
            cartStore: useCartStore()
        }
    },
    computed: {
        cart() {
            return this.cartStore.cart
        }
    }

}
</script>