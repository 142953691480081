<template>
    <navigation />
    <main id="content" role="main" class="bg-neutral-900">
        <div class="cl6bi pgrdw qbu9t r0x22 qazpp">
            <!-- Breadcrumb -->
            <ol class="bzhis qsf3i flex items-center mfkro" aria-label="Breadcrumb">
                <li class="flex items-center dtjcu v474b dark:text-neutral-500">
                    E-commerce
                    <svg class="wlxy3 lo4kb trvun qcpnt sfo8l qv2j8 dark:text-neutral-600"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path d="m9 18 6-6-6-6"></path>
                    </svg>
                </li>
                <li class="dtjcu kxhcs j4ei7 truncate dark:text-neutral-200" aria-current="page">
                    Overview
                </li>
            </ol>
            <!-- End Breadcrumb -->

            <div class="xxfsd ugq3y sly8f bcfme">
                <!-- Header -->
                <div>
                    <h1 class="hbd69 p1q58 kxhcs j4ei7 dark:text-neutral-200">
                        Good morning, James.
                    </h1>
                    <p class="dtjcu fj6be dark:text-neutral-400">
                        Here's what's happening with your store today.
                    </p>
                </div>
                <!-- End Header -->

                <!-- Stats Grid -->
                <div class="jjege zvyci drfhi l8bcf lwtpn jl2km">
                    <!-- Card -->
                    <div class="owx04 o3wmg f0dty l66z3 ffqht k3u76 tgfrq dark:bg-neutral-800 dark:border-neutral-700">
                        <div class="knnnf tlx5b">
                            <svg class="hhi2j tn07p ucn25 wlxy3 urc6e fnype qv2j8 dark:text-neutral-600"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                                <path d="m2 7 4.41-4.41A2 2 0 0 1 7.83 2h8.34a2 2 0 0 1 1.42.59L22 7"></path>
                                <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
                                <path d="M15 22v-4a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v4"></path>
                                <path d="M2 7h20"></path>
                                <path
                                    d="M22 7v3a2 2 0 0 1-2 2v0a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 16 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 12 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 8 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 4 12v0a2 2 0 0 1-2-2V7">
                                </path>
                            </svg>
                            <div class="fgyjv zfftf a3k9f">
                                <h2 class="z415c dtjcu fj6be dark:text-neutral-400">
                                    In-store sales
                                </h2>
                                <p class="hbd69 p1q58 kxhcs j4ei7 dark:text-neutral-200">
                                    $7,820.75
                                </p>
                            </div>
                        </div>

                        <div class="q8w6j flex items-center dqqs4">
                            <span class="dtjcu c8aaa fj6be dark:text-neutral-400">
                                5k orders
                            </span>
                            <span class="pqrvw items-center azej2 rvi38 eass7 jnh9x iwtls">
                                <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <polyline points="22 7 13.5 15.5 8.5 10.5 2 17"></polyline>
                                    <polyline points="16 7 22 7 22 13"></polyline>
                                </svg>
                                4.3%
                            </span>
                        </div>
                    </div>
                    <!-- End Card -->

                    <!-- Card -->
                    <div class="owx04 o3wmg f0dty l66z3 ffqht k3u76 tgfrq dark:bg-neutral-800 dark:border-neutral-700">
                        <div class="knnnf tlx5b">
                            <svg class="hhi2j tn07p ucn25 wlxy3 urc6e fnype qv2j8 dark:text-neutral-600"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="2" x2="22" y1="12" y2="12"></line>
                                <path
                                    d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z">
                                </path>
                            </svg>
                            <div class="fgyjv zfftf a3k9f">
                                <h2 class="z415c dtjcu fj6be dark:text-neutral-400">
                                    Website sales
                                </h2>
                                <p class="hbd69 p1q58 kxhcs j4ei7 dark:text-neutral-200">
                                    $985,937.45
                                </p>
                            </div>
                        </div>

                        <div class="q8w6j flex items-center dqqs4">
                            <span class="dtjcu c8aaa fj6be dark:text-neutral-400">
                                21k orders
                            </span>
                            <span class="pqrvw items-center azej2 rvi38 eass7 jnh9x iwtls">
                                <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <polyline points="22 7 13.5 15.5 8.5 10.5 2 17"></polyline>
                                    <polyline points="16 7 22 7 22 13"></polyline>
                                </svg>
                                12.5%
                            </span>
                        </div>
                    </div>
                    <!-- End Card -->

                    <!-- Card -->
                    <div class="owx04 o3wmg f0dty l66z3 ffqht k3u76 tgfrq dark:bg-neutral-800 dark:border-neutral-700">
                        <div class="knnnf tlx5b">
                            <svg class="hhi2j tn07p ucn25 wlxy3 urc6e fnype qv2j8 dark:text-neutral-600"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                                <path
                                    d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z">
                                </path>
                                <path d="m15 9-6 6"></path>
                                <path d="M9 9h.01"></path>
                                <path d="M15 15h.01"></path>
                            </svg>
                            <div class="fgyjv zfftf a3k9f">
                                <h2 class="z415c dtjcu fj6be dark:text-neutral-400">
                                    Discount
                                </h2>
                                <p class="hbd69 p1q58 kxhcs j4ei7 dark:text-neutral-200">
                                    $15,503.00
                                </p>
                            </div>
                        </div>

                        <div class="q8w6j flex items-center dqqs4">
                            <span class="dtjcu c8aaa fj6be dark:text-neutral-400">
                                6k orders
                            </span>
                        </div>
                    </div>
                    <!-- End Card -->

                    <!-- Card -->
                    <div class="owx04 o3wmg f0dty l66z3 ffqht k3u76 tgfrq dark:bg-neutral-800 dark:border-neutral-700">
                        <div class="knnnf tlx5b">
                            <svg class="hhi2j tn07p ucn25 wlxy3 urc6e fnype qv2j8 dark:text-neutral-600"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                                <path d="M4 10c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h4c1.1 0 2 .9 2 2"></path>
                                <path d="M10 16c-1.1 0-2-.9-2-2v-4c0-1.1.9-2 2-2h4c1.1 0 2 .9 2 2"></path>
                                <rect width="8" height="8" x="14" y="14" rx="2"></rect>
                            </svg>
                            <div class="fgyjv zfftf a3k9f">
                                <h2 class="z415c dtjcu fj6be dark:text-neutral-400">
                                    Affiliate
                                </h2>
                                <p class="hbd69 p1q58 kxhcs j4ei7 dark:text-neutral-200">
                                    $3,982.53
                                </p>
                            </div>
                        </div>

                        <div class="q8w6j flex items-center dqqs4">
                            <span class="dtjcu c8aaa fj6be dark:text-neutral-400">
                                2.4 orders
                            </span>
                            <span class="pqrvw items-center azej2 rvi38 eass7 dh17g iwtls">
                                <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <polyline points="22 17 13.5 8.5 8.5 13.5 2 7"></polyline>
                                    <polyline points="16 17 22 17 22 11"></polyline>
                                </svg>
                                4.4%
                            </span>
                        </div>
                    </div>
                    <!-- End Card -->
                </div>
                <!-- End Stats Grid -->

                <!-- Bar Chart in Card -->
                <div class="cmbec flex lgqw8 f0dty l66z3 ffqht tgfrq k3u76 dark:bg-neutral-800 dark:border-neutral-700">
                    <!-- Header -->
                    <div class="mvnfe qxuf9 flex flex-wrap azaj8 items-center l8bcf bg8gm ffqht dark:border-neutral-700">
                        <h2 class="uiqm5 kxhcs j4ei7 dark:text-neutral-200">
                            Orders
                        </h2>

                        <div class="flex prqc8 items-center dqqs4">
                            <!-- Calendar Dropdown -->
                            <div class="hs-dropdown pqrvw [--auto-close:inside] [--placement:top-right]">
                                <button id="hs-pro-dnic" type="button"
                                    class="xxfsd kdz1q pqrvw items-center pla0f rvi38 eass7 nq4w8 l66z3 ffqht f0dty j4ei7 tgfrq jonah kko9e tk4f7 focus:outline-none focus:bg-stone-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                                    25 Jul - 25 Aug
                                    <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path d="m6 9 6 6 6-6"></path>
                                    </svg>
                                </button>

                                <div class="hs-dropdown-menu muqsh xbvlq e7pgd transition-[opacity,margin] duration opacity-0 hidden s269j f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                                    aria-labelledby="hs-pro-dnic">
                                    <div class="knnnf">
                                        <!-- Calendar -->
                                        <div class="bqp78 xy3is">
                                            <!-- Months -->
                                            <div class="jjege wi6na items-center ukt6v bs16d i38gt">
                                                <!-- Prev Button -->
                                                <div class="dwnnx">
                                                    <button type="button"
                                                        class="kbkb7 gpe5b flex pdrgo items-center j4ei7 r1hp8 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-400 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                        <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg"
                                                            width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round">
                                                            <path d="m15 18-6-6 6-6"></path>
                                                        </svg>
                                                    </button>
                                                </div>
                                                <!-- End Prev Button -->

                                                <!-- Month / Year -->
                                                <div class="qv0l3 flex pdrgo items-center azej2">
                                                    <div class="relative">
                                                        <div class="hs-select relative"><select data-hs-select="{
                                &quot;placeholder&quot;: &quot;Select month&quot;,
                                &quot;toggleTag&quot;: &quot;<button type=\&quot;button\&quot;></button>&quot;,
                                &quot;toggleClasses&quot;: &quot;msicw n7tpi relative flex text-nowrap w-full cursor-pointer in9y1 eass7 j4ei7 romwo focus:outline-none focus:text-stone-600 kw6wr s920d bxof7 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300&quot;,
                                &quot;dropdownClasses&quot;: &quot;l2ej6 s269j pqpl9 yjsas dpnc4 xy3is f0dty l66z3 ffqht nq4w8 xrzt6 bx801 pk1vk mws46 szov1 nn2ns g3n6w dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700&quot;,
                                &quot;optionClasses&quot;: &quot;lfmff w-full dtjcu j4ei7 cursor-pointer r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800&quot;,
                                &quot;optionTemplate&quot;: &quot;<div class=\&quot;flex azaj8 items-center w-full\&quot;><span data-title></span><span class=\&quot;hidden hs-selected:block\&quot;><svg class=\&quot;wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200\&quot; xmlns=\&quot;http:.w3.org/2000/svg\&quot; width=\&quot;24\&quot; height=\&quot;24\&quot; viewBox=\&quot;0 0 24 24\&quot; fill=\&quot;none\&quot; stroke=\&quot;currentColor\&quot; stroke-width=\&quot;2\&quot; stroke-linecap=\&quot;round\&quot; stroke-linejoin=\&quot;round\&quot;><polyline points=\&quot;20 6 9 17 4 12\&quot;/></svg></span></div>&quot;
                              }" class="hidden" style="display: none;">
                                                                <option value="0">January</option>
                                                                <option value="1">February</option>
                                                                <option value="2">March</option>
                                                                <option value="3">April</option>
                                                                <option value="4">May</option>
                                                                <option value="5">June</option>
                                                                <option value="6" selected="">July</option>
                                                                <option value="7">August</option>
                                                                <option value="8">September</option>
                                                                <option value="9">October</option>
                                                                <option value="10">November</option>
                                                                <option value="11">December</option>
                                                            </select><button type="button"
                                                                class="msicw n7tpi relative flex text-nowrap w-full cursor-pointer in9y1 eass7 j4ei7 romwo focus:outline-none focus:text-stone-600 kw6wr s920d bxof7 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300"><span
                                                                    class="truncate">July</span></button>
                                                            <div
                                                                class="absolute top-full hidden l2ej6 s269j pqpl9 yjsas dpnc4 xy3is f0dty l66z3 ffqht nq4w8 xrzt6 bx801 pk1vk mws46 szov1 nn2ns g3n6w dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700">
                                                                <div data-value="0" data-title-value="January" tabindex="0"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">January</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="1" data-title-value="February" tabindex="1"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">February</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="2" data-title-value="March" tabindex="2"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">March</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="3" data-title-value="April" tabindex="3"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">April</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="4" data-title-value="May" tabindex="4"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">May</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="5" data-title-value="June" tabindex="5"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">June</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="6" data-title-value="July" tabindex="6"
                                                                    class="cursor-pointer selected lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">July</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="7" data-title-value="August" tabindex="7"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">August</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="8" data-title-value="September"
                                                                    tabindex="8"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">September</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="9" data-title-value="October" tabindex="9"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">October</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="10" data-title-value="November"
                                                                    tabindex="10"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">November</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="11" data-title-value="December"
                                                                    tabindex="11"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">December</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <span class="j4ei7 dark:text-neutral-200">/</span>

                                                    <div class="relative">
                                                        <div class="hs-select relative"><select data-hs-select="{
                                &quot;placeholder&quot;: &quot;Select year&quot;,
                                &quot;toggleTag&quot;: &quot;<button type=\&quot;button\&quot;></button>&quot;,
                                &quot;toggleClasses&quot;: &quot;msicw n7tpi relative flex text-nowrap w-full cursor-pointer in9y1 eass7 j4ei7 romwo focus:outline-none focus:text-stone-600 kw6wr s920d bxof7 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300&quot;,
                                &quot;dropdownClasses&quot;: &quot;l2ej6 s269j m9pyi yjsas dpnc4 xy3is f0dty l66z3 ffqht nq4w8 xrzt6 bx801 pk1vk mws46 szov1 nn2ns g3n6w dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700&quot;,
                                &quot;optionClasses&quot;: &quot;lfmff w-full dtjcu j4ei7 cursor-pointer r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800&quot;,
                                &quot;optionTemplate&quot;: &quot;<div class=\&quot;flex azaj8 items-center w-full\&quot;><span data-title></span><span class=\&quot;hidden hs-selected:block\&quot;><svg class=\&quot;wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200\&quot; xmlns=\&quot;http:.w3.org/2000/svg\&quot; width=\&quot;24\&quot; height=\&quot;24\&quot; viewBox=\&quot;0 0 24 24\&quot; fill=\&quot;none\&quot; stroke=\&quot;currentColor\&quot; stroke-width=\&quot;2\&quot; stroke-linecap=\&quot;round\&quot; stroke-linejoin=\&quot;round\&quot;><polyline points=\&quot;20 6 9 17 4 12\&quot;/></svg></span></div>&quot;
                              }" class="hidden" style="display: none;">
                                                                <option selected="">2023</option>
                                                                <option>2024</option>
                                                                <option>2025</option>
                                                                <option>2026</option>
                                                                <option>2027</option>
                                                            </select><button type="button"
                                                                class="msicw n7tpi relative flex text-nowrap w-full cursor-pointer in9y1 eass7 j4ei7 romwo focus:outline-none focus:text-stone-600 kw6wr s920d bxof7 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300"><span
                                                                    class="truncate">2023</span></button>
                                                            <div
                                                                class="absolute top-full hidden l2ej6 s269j m9pyi yjsas dpnc4 xy3is f0dty l66z3 ffqht nq4w8 xrzt6 bx801 pk1vk mws46 szov1 nn2ns g3n6w dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700">
                                                                <div data-value="2023" data-title-value="2023" tabindex="0"
                                                                    class="cursor-pointer selected lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">2023</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="2024" data-title-value="2024" tabindex="1"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">2024</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="2025" data-title-value="2025" tabindex="2"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">2025</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="2026" data-title-value="2026" tabindex="3"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">2026</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="2027" data-title-value="2027" tabindex="4"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">2027</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- End Month / Year -->

                                                <!-- Next Button -->
                                                <div class="dwnnx flex prqc8">
                                                    <button type="button"
                                                        class="opacity-0 kbkb7 gpe5b flex pdrgo items-center j4ei7 r1hp8 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-400 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                        <svg class="wlxy3 sfo8l qcpnt" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                            <path d="m9 18 6-6-6-6"></path>
                                                        </svg>
                                                    </button>
                                                </div>
                                                <!-- End Next Button -->
                                            </div>
                                            <!-- Months -->

                                            <!-- Weeks -->
                                            <div class="flex cgcp3">
                                                <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                                                    Mo
                                                </span>
                                                <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                                                    Tu
                                                </span>
                                                <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                                                    We
                                                </span>
                                                <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                                                    Th
                                                </span>
                                                <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                                                    Fr
                                                </span>
                                                <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                                                    Sa
                                                </span>
                                                <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                                                    Su
                                                </span>
                                            </div>
                                            <!-- Weeks -->

                                            <!-- Days -->
                                            <div class="flex">
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                                                        disabled="">
                                                        26
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                                                        disabled="">
                                                        27
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                                                        disabled="">
                                                        28
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                                                        disabled="">
                                                        29
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                                                        disabled="">
                                                        30
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        1
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        2
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- Days -->

                                            <!-- Days -->
                                            <div class="flex">
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        3
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        4
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        5
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        6
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        7
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        8
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        9
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- Days -->

                                            <!-- Days -->
                                            <div class="flex">
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        10
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        11
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        12
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        13
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        14
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        15
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        16
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- Days -->

                                            <!-- Days -->
                                            <div class="flex">
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        17
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        18
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        19
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        20
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        21
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        22
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        23
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- Days -->

                                            <!-- Days -->
                                            <div class="flex">
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        24
                                                    </button>
                                                </div>
                                                <div class="si6qh oxb4v dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center k6q5b l66z3 yj6bp dtjcu eass7 dafkk irakl iwtls dark:bg-green-500 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:hover:border-neutral-700">
                                                        25
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        26
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        27
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        28
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        29
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        30
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- Days -->

                                            <!-- Days -->
                                            <div class="flex">
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        31
                                                    </button>
                                                </div>
                                                <div class="kaxez k06lk dark:from-stone-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                                                        disabled="">
                                                        1
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                                                        disabled="">
                                                        2
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                                                        disabled="">
                                                        3
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                                                        disabled="">
                                                        4
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                                                        disabled="">
                                                        5
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                                                        disabled="">
                                                        6
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- Days -->
                                        </div>
                                        <!-- End Calendar -->

                                        <!-- Calendar -->
                                        <div class="bqp78 xy3is">
                                            <!-- Months -->
                                            <div class="jjege wi6na items-center ukt6v bs16d i38gt">
                                                <!-- Prev Button -->
                                                <div class="dwnnx">
                                                    <button type="button"
                                                        class="opacity-0 kbkb7 gpe5b flex pdrgo items-center j4ei7 r1hp8 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-400 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                        <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg"
                                                            width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round">
                                                            <path d="m15 18-6-6 6-6"></path>
                                                        </svg>
                                                    </button>
                                                </div>
                                                <!-- End Prev Button -->

                                                <!-- Month / Year -->
                                                <div class="qv0l3 flex pdrgo items-center azej2">
                                                    <div class="relative">
                                                        <div class="hs-select relative"><select data-hs-select="{
                                &quot;placeholder&quot;: &quot;Select month&quot;,
                                &quot;toggleTag&quot;: &quot;<button type=\&quot;button\&quot;></button>&quot;,
                                &quot;toggleClasses&quot;: &quot;msicw n7tpi relative flex text-nowrap w-full cursor-pointer in9y1 eass7 j4ei7 romwo focus:outline-none focus:text-stone-600 kw6wr s920d bxof7 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300&quot;,
                                &quot;dropdownClasses&quot;: &quot;l2ej6 s269j pqpl9 yjsas dpnc4 xy3is f0dty l66z3 ffqht nq4w8 xrzt6 bx801 pk1vk mws46 szov1 nn2ns g3n6w dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700&quot;,
                                &quot;optionClasses&quot;: &quot;lfmff w-full dtjcu j4ei7 cursor-pointer r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800&quot;,
                                &quot;optionTemplate&quot;: &quot;<div class=\&quot;flex azaj8 items-center w-full\&quot;><span data-title></span><span class=\&quot;hidden hs-selected:block\&quot;><svg class=\&quot;wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200\&quot; xmlns=\&quot;http:.w3.org/2000/svg\&quot; width=\&quot;24\&quot; height=\&quot;24\&quot; viewBox=\&quot;0 0 24 24\&quot; fill=\&quot;none\&quot; stroke=\&quot;currentColor\&quot; stroke-width=\&quot;2\&quot; stroke-linecap=\&quot;round\&quot; stroke-linejoin=\&quot;round\&quot;><polyline points=\&quot;20 6 9 17 4 12\&quot;/></svg></span></div>&quot;
                              }" class="hidden" style="display: none;">
                                                                <option value="0">January</option>
                                                                <option value="1">February</option>
                                                                <option value="2">March</option>
                                                                <option value="3">April</option>
                                                                <option value="4">May</option>
                                                                <option value="5">June</option>
                                                                <option value="6" selected="">July</option>
                                                                <option value="7">August</option>
                                                                <option value="8">September</option>
                                                                <option value="9">October</option>
                                                                <option value="10">November</option>
                                                                <option value="11">December</option>
                                                            </select><button type="button"
                                                                class="msicw n7tpi relative flex text-nowrap w-full cursor-pointer in9y1 eass7 j4ei7 romwo focus:outline-none focus:text-stone-600 kw6wr s920d bxof7 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300"><span
                                                                    class="truncate">July</span></button>
                                                            <div
                                                                class="absolute top-full hidden l2ej6 s269j pqpl9 yjsas dpnc4 xy3is f0dty l66z3 ffqht nq4w8 xrzt6 bx801 pk1vk mws46 szov1 nn2ns g3n6w dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700">
                                                                <div data-value="0" data-title-value="January" tabindex="0"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">January</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="1" data-title-value="February" tabindex="1"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">February</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="2" data-title-value="March" tabindex="2"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">March</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="3" data-title-value="April" tabindex="3"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">April</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="4" data-title-value="May" tabindex="4"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">May</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="5" data-title-value="June" tabindex="5"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">June</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="6" data-title-value="July" tabindex="6"
                                                                    class="cursor-pointer selected lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">July</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="7" data-title-value="August" tabindex="7"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">August</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="8" data-title-value="September"
                                                                    tabindex="8"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">September</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="9" data-title-value="October" tabindex="9"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">October</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="10" data-title-value="November"
                                                                    tabindex="10"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">November</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="11" data-title-value="December"
                                                                    tabindex="11"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">December</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <span class="j4ei7 dark:text-neutral-200">/</span>

                                                    <div class="relative">
                                                        <div class="hs-select relative"><select data-hs-select="{
                                &quot;placeholder&quot;: &quot;Select year&quot;,
                                &quot;toggleTag&quot;: &quot;<button type=\&quot;button\&quot;></button>&quot;,
                                &quot;toggleClasses&quot;: &quot;msicw n7tpi relative flex text-nowrap w-full cursor-pointer in9y1 eass7 j4ei7 romwo focus:outline-none focus:text-stone-600 kw6wr s920d bxof7 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300&quot;,
                                &quot;dropdownClasses&quot;: &quot;l2ej6 s269j m9pyi yjsas dpnc4 xy3is f0dty l66z3 ffqht nq4w8 xrzt6 bx801 pk1vk mws46 szov1 nn2ns g3n6w dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700&quot;,
                                &quot;optionClasses&quot;: &quot;lfmff w-full dtjcu j4ei7 cursor-pointer r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800&quot;,
                                &quot;optionTemplate&quot;: &quot;<div class=\&quot;flex azaj8 items-center w-full\&quot;><span data-title></span><span class=\&quot;hidden hs-selected:block\&quot;><svg class=\&quot;wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200\&quot; xmlns=\&quot;http:.w3.org/2000/svg\&quot; width=\&quot;24\&quot; height=\&quot;24\&quot; viewBox=\&quot;0 0 24 24\&quot; fill=\&quot;none\&quot; stroke=\&quot;currentColor\&quot; stroke-width=\&quot;2\&quot; stroke-linecap=\&quot;round\&quot; stroke-linejoin=\&quot;round\&quot;><polyline points=\&quot;20 6 9 17 4 12\&quot;/></svg></span></div>&quot;
                              }" class="hidden" style="display: none;">
                                                                <option selected="">2023</option>
                                                                <option>2024</option>
                                                                <option>2025</option>
                                                                <option>2026</option>
                                                                <option>2027</option>
                                                            </select><button type="button"
                                                                class="msicw n7tpi relative flex text-nowrap w-full cursor-pointer in9y1 eass7 j4ei7 romwo focus:outline-none focus:text-stone-600 kw6wr s920d bxof7 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300"><span
                                                                    class="truncate">2023</span></button>
                                                            <div
                                                                class="absolute top-full hidden l2ej6 s269j m9pyi yjsas dpnc4 xy3is f0dty l66z3 ffqht nq4w8 xrzt6 bx801 pk1vk mws46 szov1 nn2ns g3n6w dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700">
                                                                <div data-value="2023" data-title-value="2023" tabindex="0"
                                                                    class="cursor-pointer selected lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">2023</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="2024" data-title-value="2024" tabindex="1"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">2024</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="2025" data-title-value="2025" tabindex="2"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">2025</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="2026" data-title-value="2026" tabindex="3"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">2026</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                                <div data-value="2027" data-title-value="2027" tabindex="4"
                                                                    class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                                                    <div class="flex azaj8 items-center w-full"><span
                                                                            data-title="">2027</span><span
                                                                            class="hidden hs-selected:block"><svg
                                                                                class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                                                xmlns="http:.w3.org/2000/svg" width="24"
                                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round">
                                                                                <polyline points="20 6 9 17 4 12">
                                                                                </polyline>
                                                                            </svg></span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- End Month / Year -->

                                                <!-- Next Button -->
                                                <div class="dwnnx flex prqc8">
                                                    <button type="button"
                                                        class="kbkb7 gpe5b flex pdrgo items-center j4ei7 r1hp8 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-400 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                        <svg class="wlxy3 sfo8l qcpnt" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                            <path d="m9 18 6-6-6-6"></path>
                                                        </svg>
                                                    </button>
                                                </div>
                                                <!-- End Next Button -->
                                            </div>
                                            <!-- Months -->

                                            <!-- Weeks -->
                                            <div class="flex cgcp3">
                                                <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                                                    Mo
                                                </span>
                                                <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                                                    Tu
                                                </span>
                                                <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                                                    We
                                                </span>
                                                <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                                                    Th
                                                </span>
                                                <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                                                    Fr
                                                </span>
                                                <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                                                    Sa
                                                </span>
                                                <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                                                    Su
                                                </span>
                                            </div>
                                            <!-- Weeks -->

                                            <!-- Days -->
                                            <div class="flex">
                                                <div class="sefc0 k06lk dark:from-stone-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                                                        disabled="">
                                                        31
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700">
                                                        1
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700">
                                                        2
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700">
                                                        3
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700">
                                                        4
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        5
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        6
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- Days -->

                                            <!-- Days -->
                                            <div class="flex">
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        7
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        8
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        9
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        10
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        11
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        12
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        13
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- Days -->

                                            <!-- Days -->
                                            <div class="flex">
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        14
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        15
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        16
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        17
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        18
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        19
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        20
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- Days -->

                                            <!-- Days -->
                                            <div class="flex">
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        21
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        22
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        23
                                                    </button>
                                                </div>
                                                <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        24
                                                    </button>
                                                </div>
                                                <div class="si6qh ejkix dark:bg-neutral-800">
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center k6q5b l66z3 yj6bp dtjcu eass7 dafkk irakl iwtls dark:bg-green-500 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:hover:border-neutral-700">
                                                        25
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        26
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        27
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- Days -->

                                            <!-- Days -->
                                            <div class="flex">
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        28
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        29
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        30
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                                                        31
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                                                        disabled="">
                                                        1
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                                                        disabled="">
                                                        2
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                                                        disabled="">
                                                        3
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- Days -->

                                            <!-- Days -->
                                            <div class="flex">
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                                                        disabled="">
                                                        4
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                                                        disabled="">
                                                        5
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                                                        disabled="">
                                                        6
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                                                        disabled="">
                                                        7
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                                                        disabled="">
                                                        8
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                                                        disabled="">
                                                        9
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                                                        disabled="">
                                                        10
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- Days -->
                                        </div>
                                        <!-- End Calendar -->
                                    </div>
                                </div>
                            </div>
                            <!-- End Calendar Dropdown -->

                            <!-- Add Activity Dropdown -->
                            <div class="hs-dropdown relative pqrvw [--auto-close:inside] [--placement:bottom-right]">
                                <!-- Button -->
                                <button id="hs-pro-daaad" type="button"
                                    class="xxfsd kdz1q pqrvw items-center pla0f rvi38 eass7 nq4w8 l66z3 ffqht f0dty j4ei7 tgfrq jonah kko9e tk4f7 focus:outline-none focus:bg-stone-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                                    <svg class="hidden p6u16 wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg"
                                        width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path d="M5 12h14"></path>
                                        <path d="M12 5v14"></path>
                                    </svg>
                                    Add activity
                                </button>
                                <!-- End Button -->

                                <!-- Add Activity Dropdown -->
                                <div class="hs-dropdown-menu muqsh ccpye transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                                    aria-labelledby="hs-pro-daaad">
                                    <div class="dpnc4">
                                        <div
                                            class="flex azaj8 items-center zdxcz grnyh cursor-pointer nq4w8 r1hp8 dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                            <label for="hs-pro-dachdds1"
                                                class="flex azaj8 items-center ukt6v cursor-pointer ldcb4 j4ei7 dark:text-neutral-300">
                                                <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24"
                                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                    <polyline points="22 7 13.5 15.5 8.5 10.5 2 17"></polyline>
                                                    <polyline points="16 7 22 7 22 13"></polyline>
                                                </svg>
                                                Revenue
                                            </label>
                                            <input type="checkbox"
                                                class="n0yz9 xtsb0 f5cx3 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
                                                id="hs-pro-dachdds1" checked="">
                                        </div>

                                        <div
                                            class="flex azaj8 items-center zdxcz grnyh cursor-pointer nq4w8 r1hp8 dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                            <label for="hs-pro-dachdds2"
                                                class="flex azaj8 items-center ukt6v cursor-pointer ldcb4 j4ei7 dark:text-neutral-300">
                                                <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24"
                                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                    <path d="M14.5 22H18a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v4"></path>
                                                    <polyline points="14 2 14 8 20 8"></polyline>
                                                    <path
                                                        d="M2.97 13.12c-.6.36-.97 1.02-.97 1.74v3.28c0 .72.37 1.38.97 1.74l3 1.83c.63.39 1.43.39 2.06 0l3-1.83c.6-.36.97-1.02.97-1.74v-3.28c0-.72-.37-1.38-.97-1.74l-3-1.83a1.97 1.97 0 0 0-2.06 0l-3 1.83Z">
                                                    </path>
                                                    <path d="m7 17-4.74-2.85"></path>
                                                    <path d="m7 17 4.74-2.85"></path>
                                                    <path d="M7 17v5"></path>
                                                </svg>
                                                Orders
                                            </label>
                                            <input type="checkbox"
                                                class="n0yz9 xtsb0 f5cx3 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
                                                id="hs-pro-dachdds2" checked="">
                                        </div>

                                        <div
                                            class="flex azaj8 items-center zdxcz grnyh cursor-pointer nq4w8 r1hp8 dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                            <label for="hs-pro-dachdds3"
                                                class="flex azaj8 items-center ukt6v cursor-pointer ldcb4 j4ei7 dark:text-neutral-300">
                                                <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24"
                                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                    <polyline points="9 10 4 15 9 20"></polyline>
                                                    <path d="M20 4v7a4 4 0 0 1-4 4H4"></path>
                                                </svg>
                                                Refunds
                                            </label>
                                            <input type="checkbox"
                                                class="n0yz9 xtsb0 f5cx3 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
                                                id="hs-pro-dachdds3">
                                        </div>
                                    </div>
                                </div>
                                <!-- End Add Activity Dropdown -->
                            </div>
                            <!-- End Add Activity Dropdown -->
                        </div>
                    </div>
                    <!-- End Header -->

                    <!-- Body -->
                    <div class="fngso">
                        <div class="jjege cd9ge hl4p4 igot0 dark:divide-neutral-600">
                            <div class="fwdz8 pmbub pqx67">
                                <!-- Apex Line Chart -->
                                <div id="hs-orders-bar-chart" class="-mx-2 xgn8u" style="min-height: 315px;">
                                    <div id="apexchartsqubcg1cwk"
                                        class="apexcharts-canvas apexchartsqubcg1cwk apexcharts-theme-light"
                                        style="width: 399px; height: 300px;"><svg id="SvgjsSvg2104" width="399" height="300"
                                            xmlns="http://www.w3.org/2000/svg" version="1.1"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev"
                                            class="apexcharts-svg" xmlns:data="ApexChartsNS" transform="translate(0, 0)"
                                            style="background: transparent;">
                                            <foreignObject x="0" y="0" width="399" height="300">
                                                <div class="apexcharts-legend" xmlns="http://www.w3.org/1999/xhtml"
                                                    style="max-height: 150px;"></div>
                                            </foreignObject>
                                            <g id="SvgjsG2219" class="apexcharts-yaxis" rel="0"
                                                transform="translate(17.8125, 0)">
                                                <g id="SvgjsG2220" class="apexcharts-yaxis-texts-g"
                                                    transform="translate(-20.8125, 0)"><text id="SvgjsText2222"
                                                        font-family="Inter, ui-sans-serif" x="20" y="31.4"
                                                        text-anchor="start" dominant-baseline="auto" font-size="11px"
                                                        font-weight="400" fill="#a8a29e"
                                                        class="apexcharts-text apexcharts-yaxis-label "
                                                        style="font-family: Inter, ui-sans-serif;">
                                                        <tspan id="SvgjsTspan2223">400</tspan>
                                                        <title>400</title>
                                                    </text><text id="SvgjsText2225" font-family="Inter, ui-sans-serif"
                                                        x="20" y="89.08250000000001" text-anchor="start"
                                                        dominant-baseline="auto" font-size="11px" font-weight="400"
                                                        fill="#a8a29e" class="apexcharts-text apexcharts-yaxis-label "
                                                        style="font-family: Inter, ui-sans-serif;">
                                                        <tspan id="SvgjsTspan2226">300</tspan>
                                                        <title>300</title>
                                                    </text><text id="SvgjsText2228" font-family="Inter, ui-sans-serif"
                                                        x="20" y="146.76500000000001" text-anchor="start"
                                                        dominant-baseline="auto" font-size="11px" font-weight="400"
                                                        fill="#a8a29e" class="apexcharts-text apexcharts-yaxis-label "
                                                        style="font-family: Inter, ui-sans-serif;">
                                                        <tspan id="SvgjsTspan2229">200</tspan>
                                                        <title>200</title>
                                                    </text><text id="SvgjsText2231" font-family="Inter, ui-sans-serif"
                                                        x="20" y="204.44750000000002" text-anchor="start"
                                                        dominant-baseline="auto" font-size="11px" font-weight="400"
                                                        fill="#a8a29e" class="apexcharts-text apexcharts-yaxis-label "
                                                        style="font-family: Inter, ui-sans-serif;">
                                                        <tspan id="SvgjsTspan2232">100</tspan>
                                                        <title>100</title>
                                                    </text><text id="SvgjsText2234" font-family="Inter, ui-sans-serif"
                                                        x="20" y="262.13" text-anchor="start" dominant-baseline="auto"
                                                        font-size="11px" font-weight="400" fill="#a8a29e"
                                                        class="apexcharts-text apexcharts-yaxis-label "
                                                        style="font-family: Inter, ui-sans-serif;">
                                                        <tspan id="SvgjsTspan2235">0</tspan>
                                                        <title>0</title>
                                                    </text></g>
                                            </g>
                                            <g id="SvgjsG2106" class="apexcharts-inner apexcharts-graphical"
                                                transform="translate(47.8125, 30)">
                                                <defs id="SvgjsDefs2105">
                                                    <clipPath id="gridRectMaskqubcg1cwk">
                                                        <rect id="SvgjsRect2109" width="355.1875" height="274.73" x="-12"
                                                            y="-22" rx="0" ry="0" opacity="1" stroke-width="0" stroke="none"
                                                            stroke-dasharray="0" fill="#fff"></rect>
                                                    </clipPath>
                                                    <clipPath id="forecastMaskqubcg1cwk"></clipPath>
                                                    <clipPath id="nonForecastMaskqubcg1cwk"></clipPath>
                                                    <clipPath id="gridRectMarkerMaskqubcg1cwk">
                                                        <rect id="SvgjsRect2110" width="345.1875"
                                                            height="234.73000000000002" x="-2" y="-2" rx="0" ry="0"
                                                            opacity="1" stroke-width="0" stroke="none" stroke-dasharray="0"
                                                            fill="#fff"></rect>
                                                    </clipPath>
                                                </defs>
                                                <g id="SvgjsG2168" class="apexcharts-grid">
                                                    <g id="SvgjsG2169" class="apexcharts-gridlines-horizontal">
                                                        <line id="SvgjsLine2173" x1="0" y1="57.682500000000005"
                                                            x2="341.1875" y2="57.682500000000005" stroke="#404040"
                                                            stroke-dasharray="0" stroke-linecap="butt"
                                                            class="apexcharts-gridline"></line>
                                                        <line id="SvgjsLine2174" x1="0" y1="115.36500000000001"
                                                            x2="341.1875" y2="115.36500000000001" stroke="#404040"
                                                            stroke-dasharray="0" stroke-linecap="butt"
                                                            class="apexcharts-gridline"></line>
                                                        <line id="SvgjsLine2175" x1="0" y1="173.0475" x2="341.1875"
                                                            y2="173.0475" stroke="#404040" stroke-dasharray="0"
                                                            stroke-linecap="butt" class="apexcharts-gridline"></line>
                                                    </g>
                                                    <g id="SvgjsG2170" class="apexcharts-gridlines-vertical"></g>
                                                    <line id="SvgjsLine2178" x1="0" y1="230.73000000000002" x2="341.1875"
                                                        y2="230.73000000000002" stroke="transparent" stroke-dasharray="0"
                                                        stroke-linecap="butt"></line>
                                                    <line id="SvgjsLine2177" x1="0" y1="1" x2="0" y2="230.73000000000002"
                                                        stroke="transparent" stroke-dasharray="0" stroke-linecap="butt">
                                                    </line>
                                                </g>
                                                <g id="SvgjsG2171" class="apexcharts-grid-borders">
                                                    <line id="SvgjsLine2172" x1="0" y1="0" x2="341.1875" y2="0"
                                                        stroke="#404040" stroke-dasharray="0" stroke-linecap="butt"
                                                        class="apexcharts-gridline"></line>
                                                    <line id="SvgjsLine2176" x1="0" y1="230.73000000000002" x2="341.1875"
                                                        y2="230.73000000000002" stroke="#404040" stroke-dasharray="0"
                                                        stroke-linecap="butt" class="apexcharts-gridline"></line>
                                                </g>
                                                <g id="SvgjsG2111" class="apexcharts-bar-series apexcharts-plot-series">
                                                    <g id="SvgjsG2112" class="apexcharts-series" rel="1"
                                                        seriesName="In-store" data:realIndex="0">
                                                        <path id="SvgjsPath2117"
                                                            d="M 0.21614583333333393 230.73100000000002 L 0.21614583333333393 115.36600000000001 L 4.216145833333334 115.36600000000001 L 4.216145833333334 230.73100000000002 Z"
                                                            fill="rgba(34,197,94,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="0"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 0.21614583333333393 230.73100000000002 L 0.21614583333333393 115.36600000000001 L 4.216145833333334 115.36600000000001 L 4.216145833333334 230.73100000000002 Z"
                                                            pathFrom="M 0.21614583333333393 230.73100000000002 L 0.21614583333333393 230.73100000000002 L 4.216145833333334 230.73100000000002 L 4.216145833333334 230.73100000000002 L 4.216145833333334 230.73100000000002 L 4.216145833333334 230.73100000000002 L 4.216145833333334 230.73100000000002 L 0.21614583333333393 230.73100000000002 Z"
                                                            cy="115.36500000000001" cx="23.6484375" j="0" val="200"
                                                            barHeight="115.36500000000001" barWidth="14"></path>
                                                        <path id="SvgjsPath2119"
                                                            d="M 28.6484375 230.73100000000002 L 28.6484375 57.6835 L 32.6484375 57.6835 L 32.6484375 230.73100000000002 Z"
                                                            fill="rgba(34,197,94,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="0"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 28.6484375 230.73100000000002 L 28.6484375 57.6835 L 32.6484375 57.6835 L 32.6484375 230.73100000000002 Z"
                                                            pathFrom="M 28.6484375 230.73100000000002 L 28.6484375 230.73100000000002 L 32.6484375 230.73100000000002 L 32.6484375 230.73100000000002 L 32.6484375 230.73100000000002 L 32.6484375 230.73100000000002 L 32.6484375 230.73100000000002 L 28.6484375 230.73100000000002 Z"
                                                            cy="57.682500000000005" cx="52.08072916666667" j="1" val="300"
                                                            barHeight="173.0475" barWidth="14"></path>
                                                        <path id="SvgjsPath2121"
                                                            d="M 57.08072916666667 230.73100000000002 L 57.08072916666667 63.451750000000025 L 61.08072916666667 63.451750000000025 L 61.08072916666667 230.73100000000002 Z"
                                                            fill="rgba(34,197,94,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="0"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 57.08072916666667 230.73100000000002 L 57.08072916666667 63.451750000000025 L 61.08072916666667 63.451750000000025 L 61.08072916666667 230.73100000000002 Z"
                                                            pathFrom="M 57.08072916666667 230.73100000000002 L 57.08072916666667 230.73100000000002 L 61.08072916666667 230.73100000000002 L 61.08072916666667 230.73100000000002 L 61.08072916666667 230.73100000000002 L 61.08072916666667 230.73100000000002 L 61.08072916666667 230.73100000000002 L 57.08072916666667 230.73100000000002 Z"
                                                            cy="63.45075000000003" cx="80.51302083333334" j="2" val="290"
                                                            barHeight="167.27925" barWidth="14"></path>
                                                        <path id="SvgjsPath2123"
                                                            d="M 85.51302083333334 230.73100000000002 L 85.51302083333334 28.842250000000003 L 89.51302083333334 28.842250000000003 L 89.51302083333334 230.73100000000002 Z"
                                                            fill="rgba(34,197,94,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="0"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 85.51302083333334 230.73100000000002 L 85.51302083333334 28.842250000000003 L 89.51302083333334 28.842250000000003 L 89.51302083333334 230.73100000000002 Z"
                                                            pathFrom="M 85.51302083333334 230.73100000000002 L 85.51302083333334 230.73100000000002 L 89.51302083333334 230.73100000000002 L 89.51302083333334 230.73100000000002 L 89.51302083333334 230.73100000000002 L 89.51302083333334 230.73100000000002 L 89.51302083333334 230.73100000000002 L 85.51302083333334 230.73100000000002 Z"
                                                            cy="28.841250000000002" cx="108.94531250000001" j="3" val="350"
                                                            barHeight="201.88875000000002" barWidth="14"></path>
                                                        <path id="SvgjsPath2125"
                                                            d="M 113.94531250000001 230.73100000000002 L 113.94531250000001 144.20725000000002 L 117.94531250000001 144.20725000000002 L 117.94531250000001 230.73100000000002 Z"
                                                            fill="rgba(34,197,94,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="0"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 113.94531250000001 230.73100000000002 L 113.94531250000001 144.20725000000002 L 117.94531250000001 144.20725000000002 L 117.94531250000001 230.73100000000002 Z"
                                                            pathFrom="M 113.94531250000001 230.73100000000002 L 113.94531250000001 230.73100000000002 L 117.94531250000001 230.73100000000002 L 117.94531250000001 230.73100000000002 L 117.94531250000001 230.73100000000002 L 117.94531250000001 230.73100000000002 L 117.94531250000001 230.73100000000002 L 113.94531250000001 230.73100000000002 Z"
                                                            cy="144.20625" cx="137.37760416666669" j="4" val="150"
                                                            barHeight="86.52375" barWidth="14"></path>
                                                        <path id="SvgjsPath2127"
                                                            d="M 142.37760416666669 230.73100000000002 L 142.37760416666669 28.842250000000003 L 146.37760416666669 28.842250000000003 L 146.37760416666669 230.73100000000002 Z"
                                                            fill="rgba(34,197,94,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="0"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 142.37760416666669 230.73100000000002 L 142.37760416666669 28.842250000000003 L 146.37760416666669 28.842250000000003 L 146.37760416666669 230.73100000000002 Z"
                                                            pathFrom="M 142.37760416666669 230.73100000000002 L 142.37760416666669 230.73100000000002 L 146.37760416666669 230.73100000000002 L 146.37760416666669 230.73100000000002 L 146.37760416666669 230.73100000000002 L 146.37760416666669 230.73100000000002 L 146.37760416666669 230.73100000000002 L 142.37760416666669 230.73100000000002 Z"
                                                            cy="28.841250000000002" cx="165.80989583333334" j="5" val="350"
                                                            barHeight="201.88875000000002" barWidth="14"></path>
                                                        <path id="SvgjsPath2129"
                                                            d="M 170.80989583333334 230.73100000000002 L 170.80989583333334 57.6835 L 174.80989583333334 57.6835 L 174.80989583333334 230.73100000000002 Z"
                                                            fill="rgba(34,197,94,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="0"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 170.80989583333334 230.73100000000002 L 170.80989583333334 57.6835 L 174.80989583333334 57.6835 L 174.80989583333334 230.73100000000002 Z"
                                                            pathFrom="M 170.80989583333334 230.73100000000002 L 170.80989583333334 230.73100000000002 L 174.80989583333334 230.73100000000002 L 174.80989583333334 230.73100000000002 L 174.80989583333334 230.73100000000002 L 174.80989583333334 230.73100000000002 L 174.80989583333334 230.73100000000002 L 170.80989583333334 230.73100000000002 Z"
                                                            cy="57.682500000000005" cx="194.2421875" j="6" val="300"
                                                            barHeight="173.0475" barWidth="14"></path>
                                                        <path id="SvgjsPath2131"
                                                            d="M 199.2421875 230.73100000000002 L 199.2421875 173.04850000000002 L 203.2421875 173.04850000000002 L 203.2421875 230.73100000000002 Z"
                                                            fill="rgba(34,197,94,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="0"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 199.2421875 230.73100000000002 L 199.2421875 173.04850000000002 L 203.2421875 173.04850000000002 L 203.2421875 230.73100000000002 Z"
                                                            pathFrom="M 199.2421875 230.73100000000002 L 199.2421875 230.73100000000002 L 203.2421875 230.73100000000002 L 203.2421875 230.73100000000002 L 203.2421875 230.73100000000002 L 203.2421875 230.73100000000002 L 203.2421875 230.73100000000002 L 199.2421875 230.73100000000002 Z"
                                                            cy="173.0475" cx="222.67447916666666" j="7" val="100"
                                                            barHeight="57.682500000000005" barWidth="14"></path>
                                                        <path id="SvgjsPath2133"
                                                            d="M 227.67447916666666 230.73100000000002 L 227.67447916666666 158.62787500000002 L 231.67447916666666 158.62787500000002 L 231.67447916666666 230.73100000000002 Z"
                                                            fill="rgba(34,197,94,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="0"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 227.67447916666666 230.73100000000002 L 227.67447916666666 158.62787500000002 L 231.67447916666666 158.62787500000002 L 231.67447916666666 230.73100000000002 Z"
                                                            pathFrom="M 227.67447916666666 230.73100000000002 L 227.67447916666666 230.73100000000002 L 231.67447916666666 230.73100000000002 L 231.67447916666666 230.73100000000002 L 231.67447916666666 230.73100000000002 L 231.67447916666666 230.73100000000002 L 231.67447916666666 230.73100000000002 L 227.67447916666666 230.73100000000002 Z"
                                                            cy="158.626875" cx="251.10677083333331" j="8" val="125"
                                                            barHeight="72.103125" barWidth="14"></path>
                                                        <path id="SvgjsPath2135"
                                                            d="M 256.1067708333333 230.73100000000002 L 256.1067708333333 103.82950000000002 L 260.1067708333333 103.82950000000002 L 260.1067708333333 230.73100000000002 Z"
                                                            fill="rgba(34,197,94,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="0"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 256.1067708333333 230.73100000000002 L 256.1067708333333 103.82950000000002 L 260.1067708333333 103.82950000000002 L 260.1067708333333 230.73100000000002 Z"
                                                            pathFrom="M 256.1067708333333 230.73100000000002 L 256.1067708333333 230.73100000000002 L 260.1067708333333 230.73100000000002 L 260.1067708333333 230.73100000000002 L 260.1067708333333 230.73100000000002 L 260.1067708333333 230.73100000000002 L 260.1067708333333 230.73100000000002 L 256.1067708333333 230.73100000000002 Z"
                                                            cy="103.82850000000002" cx="279.5390625" j="9" val="220"
                                                            barHeight="126.9015" barWidth="14"></path>
                                                        <path id="SvgjsPath2137"
                                                            d="M 284.5390625 230.73100000000002 L 284.5390625 115.36600000000001 L 288.5390625 115.36600000000001 L 288.5390625 230.73100000000002 Z"
                                                            fill="rgba(34,197,94,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="0"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 284.5390625 230.73100000000002 L 284.5390625 115.36600000000001 L 288.5390625 115.36600000000001 L 288.5390625 230.73100000000002 Z"
                                                            pathFrom="M 284.5390625 230.73100000000002 L 284.5390625 230.73100000000002 L 288.5390625 230.73100000000002 L 288.5390625 230.73100000000002 L 288.5390625 230.73100000000002 L 288.5390625 230.73100000000002 L 288.5390625 230.73100000000002 L 284.5390625 230.73100000000002 Z"
                                                            cy="115.36500000000001" cx="307.9713541666667" j="10" val="200"
                                                            barHeight="115.36500000000001" barWidth="14"></path>
                                                        <path id="SvgjsPath2139"
                                                            d="M 312.9713541666667 230.73100000000002 L 312.9713541666667 57.6835 L 316.9713541666667 57.6835 L 316.9713541666667 230.73100000000002 Z"
                                                            fill="rgba(34,197,94,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="0"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 312.9713541666667 230.73100000000002 L 312.9713541666667 57.6835 L 316.9713541666667 57.6835 L 316.9713541666667 230.73100000000002 Z"
                                                            pathFrom="M 312.9713541666667 230.73100000000002 L 312.9713541666667 230.73100000000002 L 316.9713541666667 230.73100000000002 L 316.9713541666667 230.73100000000002 L 316.9713541666667 230.73100000000002 L 316.9713541666667 230.73100000000002 L 316.9713541666667 230.73100000000002 L 312.9713541666667 230.73100000000002 Z"
                                                            cy="57.682500000000005" cx="336.40364583333337" j="11" val="300"
                                                            barHeight="173.0475" barWidth="14"></path>
                                                        <g id="SvgjsG2114" class="apexcharts-bar-goals-markers">
                                                            <g id="SvgjsG2116" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                            <g id="SvgjsG2118" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                            <g id="SvgjsG2120" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                            <g id="SvgjsG2122" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                            <g id="SvgjsG2124" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                            <g id="SvgjsG2126" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                            <g id="SvgjsG2128" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                            <g id="SvgjsG2130" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                            <g id="SvgjsG2132" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                            <g id="SvgjsG2134" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                            <g id="SvgjsG2136" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                            <g id="SvgjsG2138" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                        </g>
                                                        <g id="SvgjsG2115"
                                                            class="apexcharts-bar-shadows apexcharts-hidden-element-shown">
                                                        </g>
                                                    </g>
                                                    <g id="SvgjsG2140" class="apexcharts-series" rel="2" seriesName="Online"
                                                        data:realIndex="1">
                                                        <path id="SvgjsPath2145"
                                                            d="M 14.216145833333334 230.73100000000002 L 14.216145833333334 144.20725000000002 L 18.216145833333336 144.20725000000002 L 18.216145833333336 230.73100000000002 Z"
                                                            fill="rgba(115,115,115,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="1"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 14.216145833333334 230.73100000000002 L 14.216145833333334 144.20725000000002 L 18.216145833333336 144.20725000000002 L 18.216145833333336 230.73100000000002 Z"
                                                            pathFrom="M 14.216145833333334 230.73100000000002 L 14.216145833333334 230.73100000000002 L 18.216145833333336 230.73100000000002 L 18.216145833333336 230.73100000000002 L 18.216145833333336 230.73100000000002 L 18.216145833333336 230.73100000000002 L 18.216145833333336 230.73100000000002 L 14.216145833333334 230.73100000000002 Z"
                                                            cy="144.20625" cx="37.6484375" j="0" val="150"
                                                            barHeight="86.52375" barWidth="14"></path>
                                                        <path id="SvgjsPath2147"
                                                            d="M 42.6484375 230.73100000000002 L 42.6484375 98.06125000000003 L 46.6484375 98.06125000000003 L 46.6484375 230.73100000000002 Z"
                                                            fill="rgba(115,115,115,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="1"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 42.6484375 230.73100000000002 L 42.6484375 98.06125000000003 L 46.6484375 98.06125000000003 L 46.6484375 230.73100000000002 Z"
                                                            pathFrom="M 42.6484375 230.73100000000002 L 42.6484375 230.73100000000002 L 46.6484375 230.73100000000002 L 46.6484375 230.73100000000002 L 46.6484375 230.73100000000002 L 46.6484375 230.73100000000002 L 46.6484375 230.73100000000002 L 42.6484375 230.73100000000002 Z"
                                                            cy="98.06025000000002" cx="66.08072916666667" j="1" val="230"
                                                            barHeight="132.66975" barWidth="14"></path>
                                                        <path id="SvgjsPath2149"
                                                            d="M 71.08072916666667 230.73100000000002 L 71.08072916666667 10.383850000000018 L 75.08072916666667 10.383850000000018 L 75.08072916666667 230.73100000000002 Z"
                                                            fill="rgba(115,115,115,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="1"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 71.08072916666667 230.73100000000002 L 71.08072916666667 10.383850000000018 L 75.08072916666667 10.383850000000018 L 75.08072916666667 230.73100000000002 Z"
                                                            pathFrom="M 71.08072916666667 230.73100000000002 L 71.08072916666667 230.73100000000002 L 75.08072916666667 230.73100000000002 L 75.08072916666667 230.73100000000002 L 75.08072916666667 230.73100000000002 L 75.08072916666667 230.73100000000002 L 75.08072916666667 230.73100000000002 L 71.08072916666667 230.73100000000002 Z"
                                                            cy="10.382850000000019" cx="94.51302083333334" j="2" val="382"
                                                            barHeight="220.34715" barWidth="14"></path>
                                                        <path id="SvgjsPath2151"
                                                            d="M 99.51302083333334 230.73100000000002 L 99.51302083333334 113.05870000000002 L 103.51302083333334 113.05870000000002 L 103.51302083333334 230.73100000000002 Z"
                                                            fill="rgba(115,115,115,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="1"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 99.51302083333334 230.73100000000002 L 99.51302083333334 113.05870000000002 L 103.51302083333334 113.05870000000002 L 103.51302083333334 230.73100000000002 Z"
                                                            pathFrom="M 99.51302083333334 230.73100000000002 L 99.51302083333334 230.73100000000002 L 103.51302083333334 230.73100000000002 L 103.51302083333334 230.73100000000002 L 103.51302083333334 230.73100000000002 L 103.51302083333334 230.73100000000002 L 103.51302083333334 230.73100000000002 L 99.51302083333334 230.73100000000002 Z"
                                                            cy="113.05770000000001" cx="122.94531250000001" j="3" val="204"
                                                            barHeight="117.6723" barWidth="14"></path>
                                                        <path id="SvgjsPath2153"
                                                            d="M 127.94531250000001 230.73100000000002 L 127.94531250000001 133.247575 L 131.9453125 133.247575 L 131.9453125 230.73100000000002 Z"
                                                            fill="rgba(115,115,115,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="1"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 127.94531250000001 230.73100000000002 L 127.94531250000001 133.247575 L 131.9453125 133.247575 L 131.9453125 230.73100000000002 Z"
                                                            pathFrom="M 127.94531250000001 230.73100000000002 L 127.94531250000001 230.73100000000002 L 131.9453125 230.73100000000002 L 131.9453125 230.73100000000002 L 131.9453125 230.73100000000002 L 131.9453125 230.73100000000002 L 131.9453125 230.73100000000002 L 127.94531250000001 230.73100000000002 Z"
                                                            cy="133.246575" cx="151.37760416666669" j="4" val="169"
                                                            barHeight="97.483425" barWidth="14"></path>
                                                        <path id="SvgjsPath2155"
                                                            d="M 156.37760416666669 230.73100000000002 L 156.37760416666669 63.451750000000025 L 160.37760416666669 63.451750000000025 L 160.37760416666669 230.73100000000002 Z"
                                                            fill="rgba(115,115,115,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="1"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 156.37760416666669 230.73100000000002 L 156.37760416666669 63.451750000000025 L 160.37760416666669 63.451750000000025 L 160.37760416666669 230.73100000000002 Z"
                                                            pathFrom="M 156.37760416666669 230.73100000000002 L 156.37760416666669 230.73100000000002 L 160.37760416666669 230.73100000000002 L 160.37760416666669 230.73100000000002 L 160.37760416666669 230.73100000000002 L 160.37760416666669 230.73100000000002 L 160.37760416666669 230.73100000000002 L 156.37760416666669 230.73100000000002 Z"
                                                            cy="63.45075000000003" cx="179.80989583333334" j="5" val="290"
                                                            barHeight="167.27925" barWidth="14"></path>
                                                        <path id="SvgjsPath2157"
                                                            d="M 184.80989583333334 230.73100000000002 L 184.80989583333334 57.6835 L 188.80989583333334 57.6835 L 188.80989583333334 230.73100000000002 Z"
                                                            fill="rgba(115,115,115,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="1"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 184.80989583333334 230.73100000000002 L 184.80989583333334 57.6835 L 188.80989583333334 57.6835 L 188.80989583333334 230.73100000000002 Z"
                                                            pathFrom="M 184.80989583333334 230.73100000000002 L 184.80989583333334 230.73100000000002 L 188.80989583333334 230.73100000000002 L 188.80989583333334 230.73100000000002 L 188.80989583333334 230.73100000000002 L 188.80989583333334 230.73100000000002 L 188.80989583333334 230.73100000000002 L 184.80989583333334 230.73100000000002 Z"
                                                            cy="57.682500000000005" cx="208.2421875" j="6" val="300"
                                                            barHeight="173.0475" barWidth="14"></path>
                                                        <path id="SvgjsPath2159"
                                                            d="M 213.2421875 230.73100000000002 L 213.2421875 173.04850000000002 L 217.2421875 173.04850000000002 L 217.2421875 230.73100000000002 Z"
                                                            fill="rgba(115,115,115,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="1"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 213.2421875 230.73100000000002 L 213.2421875 173.04850000000002 L 217.2421875 173.04850000000002 L 217.2421875 230.73100000000002 Z"
                                                            pathFrom="M 213.2421875 230.73100000000002 L 213.2421875 230.73100000000002 L 217.2421875 230.73100000000002 L 217.2421875 230.73100000000002 L 217.2421875 230.73100000000002 L 217.2421875 230.73100000000002 L 217.2421875 230.73100000000002 L 213.2421875 230.73100000000002 Z"
                                                            cy="173.0475" cx="236.67447916666666" j="7" val="100"
                                                            barHeight="57.682500000000005" barWidth="14"></path>
                                                        <path id="SvgjsPath2161"
                                                            d="M 241.67447916666666 230.73100000000002 L 241.67447916666666 57.6835 L 245.67447916666666 57.6835 L 245.67447916666666 230.73100000000002 Z"
                                                            fill="rgba(115,115,115,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="1"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 241.67447916666666 230.73100000000002 L 241.67447916666666 57.6835 L 245.67447916666666 57.6835 L 245.67447916666666 230.73100000000002 Z"
                                                            pathFrom="M 241.67447916666666 230.73100000000002 L 241.67447916666666 230.73100000000002 L 245.67447916666666 230.73100000000002 L 245.67447916666666 230.73100000000002 L 245.67447916666666 230.73100000000002 L 245.67447916666666 230.73100000000002 L 245.67447916666666 230.73100000000002 L 241.67447916666666 230.73100000000002 Z"
                                                            cy="57.682500000000005" cx="265.1067708333333" j="8" val="300"
                                                            barHeight="173.0475" barWidth="14"></path>
                                                        <path id="SvgjsPath2163"
                                                            d="M 270.1067708333333 230.73100000000002 L 270.1067708333333 100.94537500000001 L 274.1067708333333 100.94537500000001 L 274.1067708333333 230.73100000000002 Z"
                                                            fill="rgba(115,115,115,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="1"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 270.1067708333333 230.73100000000002 L 270.1067708333333 100.94537500000001 L 274.1067708333333 100.94537500000001 L 274.1067708333333 230.73100000000002 Z"
                                                            pathFrom="M 270.1067708333333 230.73100000000002 L 270.1067708333333 230.73100000000002 L 274.1067708333333 230.73100000000002 L 274.1067708333333 230.73100000000002 L 274.1067708333333 230.73100000000002 L 274.1067708333333 230.73100000000002 L 274.1067708333333 230.73100000000002 L 270.1067708333333 230.73100000000002 Z"
                                                            cy="100.94437500000001" cx="293.5390625" j="9" val="225"
                                                            barHeight="129.785625" barWidth="14"></path>
                                                        <path id="SvgjsPath2165"
                                                            d="M 298.5390625 230.73100000000002 L 298.5390625 161.51200000000003 L 302.5390625 161.51200000000003 L 302.5390625 230.73100000000002 Z"
                                                            fill="rgba(115,115,115,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="1"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 298.5390625 230.73100000000002 L 298.5390625 161.51200000000003 L 302.5390625 161.51200000000003 L 302.5390625 230.73100000000002 Z"
                                                            pathFrom="M 298.5390625 230.73100000000002 L 298.5390625 230.73100000000002 L 302.5390625 230.73100000000002 L 302.5390625 230.73100000000002 L 302.5390625 230.73100000000002 L 302.5390625 230.73100000000002 L 302.5390625 230.73100000000002 L 298.5390625 230.73100000000002 Z"
                                                            cy="161.51100000000002" cx="321.9713541666667" j="10" val="120"
                                                            barHeight="69.21900000000001" barWidth="14"></path>
                                                        <path id="SvgjsPath2167"
                                                            d="M 326.9713541666667 230.73100000000002 L 326.9713541666667 144.20725000000002 L 330.9713541666667 144.20725000000002 L 330.9713541666667 230.73100000000002 Z"
                                                            fill="rgba(115,115,115,0.85)" fill-opacity="1"
                                                            stroke="transparent" stroke-opacity="1" stroke-linecap="round"
                                                            stroke-width="10" stroke-dasharray="0"
                                                            class="apexcharts-bar-area" index="1"
                                                            clip-path="url(#gridRectMaskqubcg1cwk)"
                                                            pathTo="M 326.9713541666667 230.73100000000002 L 326.9713541666667 144.20725000000002 L 330.9713541666667 144.20725000000002 L 330.9713541666667 230.73100000000002 Z"
                                                            pathFrom="M 326.9713541666667 230.73100000000002 L 326.9713541666667 230.73100000000002 L 330.9713541666667 230.73100000000002 L 330.9713541666667 230.73100000000002 L 330.9713541666667 230.73100000000002 L 330.9713541666667 230.73100000000002 L 330.9713541666667 230.73100000000002 L 326.9713541666667 230.73100000000002 Z"
                                                            cy="144.20625" cx="350.40364583333337" j="11" val="150"
                                                            barHeight="86.52375" barWidth="14"></path>
                                                        <g id="SvgjsG2142" class="apexcharts-bar-goals-markers">
                                                            <g id="SvgjsG2144" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                            <g id="SvgjsG2146" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                            <g id="SvgjsG2148" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                            <g id="SvgjsG2150" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                            <g id="SvgjsG2152" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                            <g id="SvgjsG2154" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                            <g id="SvgjsG2156" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                            <g id="SvgjsG2158" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                            <g id="SvgjsG2160" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                            <g id="SvgjsG2162" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                            <g id="SvgjsG2164" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                            <g id="SvgjsG2166" className="apexcharts-bar-goals-groups"
                                                                class="apexcharts-hidden-element-shown"
                                                                clip-path="url(#gridRectMarkerMaskqubcg1cwk)"></g>
                                                        </g>
                                                        <g id="SvgjsG2143"
                                                            class="apexcharts-bar-shadows apexcharts-hidden-element-shown">
                                                        </g>
                                                    </g>
                                                    <g id="SvgjsG2113"
                                                        class="apexcharts-datalabels apexcharts-hidden-element-shown"
                                                        data:realIndex="0"></g>
                                                    <g id="SvgjsG2141"
                                                        class="apexcharts-datalabels apexcharts-hidden-element-shown"
                                                        data:realIndex="1"></g>
                                                </g>
                                                <line id="SvgjsLine2179" x1="0" y1="0" x2="341.1875" y2="0" stroke="#b6b6b6"
                                                    stroke-dasharray="0" stroke-width="1" stroke-linecap="butt"
                                                    class="apexcharts-ycrosshairs"></line>
                                                <line id="SvgjsLine2180" x1="0" y1="0" x2="341.1875" y2="0"
                                                    stroke-dasharray="0" stroke-width="0" stroke-linecap="butt"
                                                    class="apexcharts-ycrosshairs-hidden"></line>
                                                <g id="SvgjsG2181" class="apexcharts-xaxis" transform="translate(0, 0)">
                                                    <g id="SvgjsG2182" class="apexcharts-xaxis-texts-g"
                                                        transform="translate(-2, -4)"><text id="SvgjsText2184"
                                                            font-family="Inter, ui-sans-serif" x="10.216145833333334"
                                                            y="259.73" text-anchor="middle" dominant-baseline="auto"
                                                            font-size="13px" font-weight="400" fill="#a8a29e"
                                                            class="apexcharts-text apexcharts-xaxis-label "
                                                            style="font-family: Inter, ui-sans-serif;">
                                                            <tspan id="SvgjsTspan2185">Jan</tspan>
                                                            <title>Jan</title>
                                                        </text><text id="SvgjsText2187" font-family="Inter, ui-sans-serif"
                                                            x="38.6484375" y="259.73" text-anchor="middle"
                                                            dominant-baseline="auto" font-size="13px" font-weight="400"
                                                            fill="#a8a29e" class="apexcharts-text apexcharts-xaxis-label "
                                                            style="font-family: Inter, ui-sans-serif;">
                                                            <tspan id="SvgjsTspan2188">Feb</tspan>
                                                            <title>Feb</title>
                                                        </text><text id="SvgjsText2190" font-family="Inter, ui-sans-serif"
                                                            x="67.08072916666667" y="259.73" text-anchor="middle"
                                                            dominant-baseline="auto" font-size="13px" font-weight="400"
                                                            fill="#a8a29e" class="apexcharts-text apexcharts-xaxis-label "
                                                            style="font-family: Inter, ui-sans-serif;">
                                                            <tspan id="SvgjsTspan2191">Mar</tspan>
                                                            <title>Mar</title>
                                                        </text><text id="SvgjsText2193" font-family="Inter, ui-sans-serif"
                                                            x="95.51302083333334" y="259.73" text-anchor="middle"
                                                            dominant-baseline="auto" font-size="13px" font-weight="400"
                                                            fill="#a8a29e" class="apexcharts-text apexcharts-xaxis-label "
                                                            style="font-family: Inter, ui-sans-serif;">
                                                            <tspan id="SvgjsTspan2194">Apr</tspan>
                                                            <title>Apr</title>
                                                        </text><text id="SvgjsText2196" font-family="Inter, ui-sans-serif"
                                                            x="123.94531250000001" y="259.73" text-anchor="middle"
                                                            dominant-baseline="auto" font-size="13px" font-weight="400"
                                                            fill="#a8a29e" class="apexcharts-text apexcharts-xaxis-label "
                                                            style="font-family: Inter, ui-sans-serif;">
                                                            <tspan id="SvgjsTspan2197">May</tspan>
                                                            <title>May</title>
                                                        </text><text id="SvgjsText2199" font-family="Inter, ui-sans-serif"
                                                            x="152.37760416666666" y="259.73" text-anchor="middle"
                                                            dominant-baseline="auto" font-size="13px" font-weight="400"
                                                            fill="#a8a29e" class="apexcharts-text apexcharts-xaxis-label "
                                                            style="font-family: Inter, ui-sans-serif;">
                                                            <tspan id="SvgjsTspan2200">Jun</tspan>
                                                            <title>Jun</title>
                                                        </text><text id="SvgjsText2202" font-family="Inter, ui-sans-serif"
                                                            x="180.80989583333331" y="259.73" text-anchor="middle"
                                                            dominant-baseline="auto" font-size="13px" font-weight="400"
                                                            fill="#a8a29e" class="apexcharts-text apexcharts-xaxis-label "
                                                            style="font-family: Inter, ui-sans-serif;">
                                                            <tspan id="SvgjsTspan2203">Jul</tspan>
                                                            <title>Jul</title>
                                                        </text><text id="SvgjsText2205" font-family="Inter, ui-sans-serif"
                                                            x="209.24218749999997" y="259.73" text-anchor="middle"
                                                            dominant-baseline="auto" font-size="13px" font-weight="400"
                                                            fill="#a8a29e" class="apexcharts-text apexcharts-xaxis-label "
                                                            style="font-family: Inter, ui-sans-serif;">
                                                            <tspan id="SvgjsTspan2206">Aug</tspan>
                                                            <title>Aug</title>
                                                        </text><text id="SvgjsText2208" font-family="Inter, ui-sans-serif"
                                                            x="237.67447916666663" y="259.73" text-anchor="middle"
                                                            dominant-baseline="auto" font-size="13px" font-weight="400"
                                                            fill="#a8a29e" class="apexcharts-text apexcharts-xaxis-label "
                                                            style="font-family: Inter, ui-sans-serif;">
                                                            <tspan id="SvgjsTspan2209">Sep</tspan>
                                                            <title>Sep</title>
                                                        </text><text id="SvgjsText2211" font-family="Inter, ui-sans-serif"
                                                            x="266.1067708333333" y="259.73" text-anchor="middle"
                                                            dominant-baseline="auto" font-size="13px" font-weight="400"
                                                            fill="#a8a29e" class="apexcharts-text apexcharts-xaxis-label "
                                                            style="font-family: Inter, ui-sans-serif;">
                                                            <tspan id="SvgjsTspan2212">Oct</tspan>
                                                            <title>Oct</title>
                                                        </text><text id="SvgjsText2214" font-family="Inter, ui-sans-serif"
                                                            x="294.5390625" y="259.73" text-anchor="middle"
                                                            dominant-baseline="auto" font-size="13px" font-weight="400"
                                                            fill="#a8a29e" class="apexcharts-text apexcharts-xaxis-label "
                                                            style="font-family: Inter, ui-sans-serif;">
                                                            <tspan id="SvgjsTspan2215">Nov</tspan>
                                                            <title>Nov</title>
                                                        </text><text id="SvgjsText2217" font-family="Inter, ui-sans-serif"
                                                            x="322.9713541666667" y="259.73" text-anchor="middle"
                                                            dominant-baseline="auto" font-size="13px" font-weight="400"
                                                            fill="#a8a29e" class="apexcharts-text apexcharts-xaxis-label "
                                                            style="font-family: Inter, ui-sans-serif;">
                                                            <tspan id="SvgjsTspan2218">Dec</tspan>
                                                            <title>Dec</title>
                                                        </text></g>
                                                </g>
                                                <g id="SvgjsG2236" class="apexcharts-yaxis-annotations"></g>
                                                <g id="SvgjsG2237" class="apexcharts-xaxis-annotations"></g>
                                                <g id="SvgjsG2238" class="apexcharts-point-annotations"></g>
                                            </g>
                                        </svg>
                                        <div class="apexcharts-tooltip apexcharts-theme-light">
                                            <div class="apexcharts-tooltip-title"
                                                style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;"></div>
                                            <div class="apexcharts-tooltip-series-group" style="order: 1;"><span
                                                    class="apexcharts-tooltip-marker"
                                                    style="background-color: rgb(34, 197, 94);"></span>
                                                <div class="apexcharts-tooltip-text"
                                                    style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                                    <div class="apexcharts-tooltip-y-group"><span
                                                            class="apexcharts-tooltip-text-y-label"></span><span
                                                            class="apexcharts-tooltip-text-y-value"></span></div>
                                                    <div class="apexcharts-tooltip-goals-group"><span
                                                            class="apexcharts-tooltip-text-goals-label"></span><span
                                                            class="apexcharts-tooltip-text-goals-value"></span></div>
                                                    <div class="apexcharts-tooltip-z-group"><span
                                                            class="apexcharts-tooltip-text-z-label"></span><span
                                                            class="apexcharts-tooltip-text-z-value"></span></div>
                                                </div>
                                            </div>
                                            <div class="apexcharts-tooltip-series-group" style="order: 2;"><span
                                                    class="apexcharts-tooltip-marker"
                                                    style="background-color: rgb(115, 115, 115);"></span>
                                                <div class="apexcharts-tooltip-text"
                                                    style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                                    <div class="apexcharts-tooltip-y-group"><span
                                                            class="apexcharts-tooltip-text-y-label"></span><span
                                                            class="apexcharts-tooltip-text-y-value"></span></div>
                                                    <div class="apexcharts-tooltip-goals-group"><span
                                                            class="apexcharts-tooltip-text-goals-label"></span><span
                                                            class="apexcharts-tooltip-text-goals-value"></span></div>
                                                    <div class="apexcharts-tooltip-z-group"><span
                                                            class="apexcharts-tooltip-text-z-label"></span><span
                                                            class="apexcharts-tooltip-text-z-value"></span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="apexcharts-yaxistooltip apexcharts-yaxistooltip-0 apexcharts-yaxistooltip-left apexcharts-theme-light">
                                            <div class="apexcharts-yaxistooltip-text"></div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Legen Indicator -->
                                <div class="flex pdrgo items-center xrsjp">
                                    <div class="pqrvw items-center">
                                        <span class="w1ile vzn8i uiqm5 kfs72 ifon2 nhufc dark:bg-green-500"></span>
                                        <span class="ldcb4 v474b dark:text-neutral-400">
                                            In-store
                                        </span>
                                    </div>
                                    <div class="pqrvw items-center">
                                        <span class="w1ile vzn8i uiqm5 mgfyf ifon2 nhufc dark:bg-neutral-700"></span>
                                        <span class="ldcb4 v474b dark:text-neutral-400">
                                            Online
                                        </span>
                                    </div>
                                </div>
                                <!-- End Legen Indicator -->
                            </div>
                            <!-- End Col -->

                            <div class="pzbd5 ywi5g lfmff">
                                <!-- Card -->
                                <div class="f0dty lfmff dark:bg-neutral-800 dark:border-neutral-800">
                                    <!-- Nav Tab -->
                                    <nav class="relative flex e56at tau54 lwsuf a6pwg pqyi6 ywzje dark:after:border-neutral-700"
                                        aria-label="Tabs" role="tablist">
                                        <button type="button"
                                            class="cva40 lowss kdz1q zdxcz tn07p relative pqrvw items-center dqqs4 r1hp8 fj6be gzkir dtjcu nq4w8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 tau54 ztotz a6pwg orkl1 kcadt aaznu dark:hs-tab-active:text-neutral-200 dark:hs-tab-active:after:bg-neutral-400 dark:text-neutral-500 dark:hover:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 active"
                                            id="hs-pro-tabs-dtsch-item-revenue" data-hs-tab="#hs-pro-tabs-dtsch-revenue"
                                            aria-controls="hs-pro-tabs-dtsch-revenue" role="tab">
                                            Orders
                                        </button>
                                        <button type="button"
                                            class="cva40 lowss kdz1q zdxcz tn07p relative pqrvw items-center dqqs4 r1hp8 fj6be gzkir dtjcu nq4w8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 tau54 ztotz a6pwg orkl1 kcadt aaznu dark:hs-tab-active:text-neutral-200 dark:hs-tab-active:after:bg-neutral-400 dark:text-neutral-500 dark:hover:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                                            id="hs-pro-tabs-dtsch-item-orders" data-hs-tab="#hs-pro-tabs-dtsch-orders"
                                            aria-controls="hs-pro-tabs-dtsch-orders" role="tab">
                                            Sales
                                        </button>
                                    </nav>
                                    <!-- End Nav Tab -->

                                    <div>
                                        <!-- Tab Content -->
                                        <div id="hs-pro-tabs-dtsch-revenue" role="tabpanel"
                                            aria-labelledby="hs-pro-tabs-dtsch-item-revenue">
                                            <div class="tu5ez">
                                                <h4 class="kxhcs civh3 c7zhd j4ei7 dark:text-white">
                                                    125,090
                                                </h4>

                                                <!-- Progress -->
                                                <div class="relative vd2z8">
                                                    <div class="flex w-full codoq ejddh ifon2 bx801 dark:bg-neutral-700"
                                                        role="progressbar" aria-valuenow="2" aria-valuemin="0"
                                                        aria-valuemax="100">
                                                        <div class="flex lgqw8 pdrgo ifon2 bx801 k6q5b rvi38 dafkk zpy54 mfkro transition kt6fi"
                                                            style="width: 72%"></div>
                                                    </div>
                                                    <div
                                                        class="absolute ikyla k7b55 sy2mp v834c k6q5b u40k2 jm60s ifon2 fignd -translate-y-1/2 dark:border-neutral-800">
                                                    </div>
                                                </div>
                                                <!-- End Progress -->

                                                <!-- Progress Status -->
                                                <div class="vd2z8 flex azaj8 items-center">
                                                    <span class="rvi38 j4ei7 dark:text-white">
                                                        0.00
                                                    </span>
                                                    <span class="rvi38 j4ei7 dark:text-white">
                                                        200,000
                                                    </span>
                                                </div>
                                                <!-- End Progress Status -->

                                                <p class="bxao8 dtjcu v474b dark:text-neutral-400">
                                                    A project-wise breakdown of total orders complemented by detailed
                                                    insights.
                                                </p>
                                            </div>
                                        </div>
                                        <!-- End Tab Content -->

                                        <!-- Tab Content -->
                                        <div id="hs-pro-tabs-dtsch-orders" class="hidden" role="tabpanel"
                                            aria-labelledby="hs-pro-tabs-dtsch-item-orders">
                                            <div class="tu5ez">
                                                <h4 class="kxhcs civh3 c7zhd j4ei7 dark:text-white">
                                                    $993,758.20
                                                </h4>

                                                <!-- Progress -->
                                                <div class="relative vd2z8">
                                                    <div class="flex w-full codoq ejddh ifon2 bx801 dark:bg-neutral-700"
                                                        role="progressbar" aria-valuenow="2" aria-valuemin="0"
                                                        aria-valuemax="100">
                                                        <div class="flex lgqw8 pdrgo ifon2 bx801 k6q5b rvi38 dafkk zpy54 mfkro transition kt6fi"
                                                            style="width: 47%"></div>
                                                    </div>
                                                    <div
                                                        class="absolute ikyla r5orf sy2mp v834c k6q5b u40k2 jm60s ifon2 fignd -translate-y-1/2 dark:border-neutral-800">
                                                    </div>
                                                </div>
                                                <!-- End Progress -->

                                                <!-- Progress Status -->
                                                <div class="vd2z8 flex azaj8 items-center">
                                                    <span class="rvi38 j4ei7 dark:text-white">
                                                        0.00
                                                    </span>
                                                    <span class="rvi38 j4ei7 dark:text-white">
                                                        $2mln
                                                    </span>
                                                </div>
                                                <!-- End Progress Status -->

                                                <p class="bxao8 dtjcu v474b dark:text-neutral-400">
                                                    A project-wise breakdown of total orders complemented by detailed
                                                    insights.
                                                </p>
                                            </div>
                                        </div>
                                        <!-- End Tab Content -->
                                    </div>
                                </div>
                                <!-- End Card -->

                                <div>
                                    <!-- Link -->
                                    <a class="lfmff flex items-center dqqs4 dtjcu eass7 j4ei7 nq4w8 r1hp8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-800 dark:text-neutral-200 dark:hover:bg-neutral-700 dark:hover:text-green-500 dark:focus:bg-neutral-700"
                                        href="#">
                                        <span
                                            class="flex wlxy3 pdrgo items-center cslsl uunzg f0dty l66z3 ffqht nq4w8 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300">
                                            <svg class="wlxy3 xtsb0 f5cx3 mq8xz dark:text-green-500"
                                                xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" viewBox="0 0 16 16">
                                                <path
                                                    d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828l.645-1.937zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.734 1.734 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.734 1.734 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.734 1.734 0 0 0 3.407 2.31l.387-1.162zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L10.863.1z">
                                                </path>
                                            </svg>
                                        </span>
                                        <div class="zfftf">
                                            <p>
                                                Show all highlights
                                            </p>
                                        </div>
                                        <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24"
                                            height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="m9 18 6-6-6-6"></path>
                                        </svg>
                                    </a>
                                    <!-- End Link -->

                                    <!-- Link -->
                                    <a class="lfmff flex items-center dqqs4 dtjcu eass7 j4ei7 nq4w8 r1hp8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-800 dark:text-neutral-200 dark:hover:bg-neutral-700 dark:hover:text-green-500 dark:focus:bg-neutral-700"
                                        href="#">
                                        <span
                                            class="flex wlxy3 pdrgo items-center cslsl uunzg f0dty l66z3 ffqht nq4w8 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300">
                                            <svg class="wlxy3 xtsb0 f5cx3 mq8xz dark:text-green-500"
                                                xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" viewBox="0 0 16 16">
                                                <path
                                                    d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2z">
                                                </path>
                                            </svg>
                                        </span>
                                        <div class="zfftf">
                                            <p>
                                                Show all sales data
                                            </p>
                                        </div>
                                        <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24"
                                            height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="m9 18 6-6-6-6"></path>
                                        </svg>
                                    </a>
                                    <!-- End Link -->
                                </div>
                            </div>
                            <!-- End Col -->
                        </div>
                    </div>
                    <!-- End Body -->
                </div>
                <!-- End Bar Chart in Card -->

                <!-- Products Table Card -->
                <div class="pqx67 flex lgqw8 f0dty l66z3 ffqht tgfrq k3u76 dark:bg-neutral-800 dark:border-neutral-700">
                    <!-- Card Grid -->
                    <div
                        class="n8wpz qyf7u flex hl9jk azaj8 uda7p fmrhk cs0un szov1 nn2ns g3n6w dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500">
                        <!-- Card -->
                        <a class="k4w96 jj2ob k9ios owx04 flex lgqw8 pdrgo items-center zpy54 f0dty l66z3 ffqht k3u76 tgfrq ds6v9 focus:outline-none focus:shadow-md transition dark:bg-neutral-800 dark:border-neutral-700 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                            href="#">
                            <svg class="uvtvh n7sw3 fj6be dark:text-neutral-400" xmlns="http://www.w3.org/2000/svg"
                                width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
                                <path d="m3 11 18-5v12L3 14v-3z"></path>
                                <path d="M11.6 16.8a3 3 0 1 1-5.8-1.6"></path>
                            </svg>
                            <div class="vd2z8">
                                <h3
                                    class="dtjcu kxhcs j4ei7 btvdk g4ub0 dark:text-neutral-200 dark:group-hover:text-green-500 dark:group-focus:text-green-500">
                                    Product
                                </h3>
                                <p class="ldcb4 fj6be dark:text-neutral-500">
                                    We can help to turn your great idea into a w2fq0
                                </p>
                            </div>
                        </a>
                        <!-- End Card -->

                        <!-- Card -->
                        <a class="k4w96 jj2ob k9ios owx04 flex lgqw8 pdrgo items-center zpy54 f0dty l66z3 ffqht k3u76 tgfrq ds6v9 focus:outline-none focus:shadow-md transition dark:bg-neutral-800 dark:border-neutral-700 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                            href="#">
                            <svg class="uvtvh n7sw3 fj6be dark:text-neutral-400" xmlns="http://www.w3.org/2000/svg"
                                width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
                                <path
                                    d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z">
                                </path>
                                <path d="m15 9-6 6"></path>
                                <path d="M9 9h.01"></path>
                                <path d="M15 15h.01"></path>
                            </svg>
                            <div class="vd2z8">
                                <h3
                                    class="dtjcu kxhcs j4ei7 btvdk g4ub0 dark:text-neutral-200 dark:group-hover:text-green-500 dark:group-focus:text-green-500">
                                    Discount
                                </h3>
                                <p class="ldcb4 fj6be dark:text-neutral-500">
                                    Attract new customers or reward loyal customers
                                </p>
                            </div>
                        </a>
                        <!-- End Card -->

                        <!-- Card -->
                        <a class="k4w96 jj2ob k9ios owx04 flex lgqw8 pdrgo items-center zpy54 f0dty l66z3 ffqht k3u76 tgfrq ds6v9 focus:outline-none focus:shadow-md transition dark:bg-neutral-800 dark:border-neutral-700 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                            href="#">
                            <svg class="uvtvh n7sw3 fj6be dark:text-neutral-400" xmlns="http://www.w3.org/2000/svg"
                                width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
                                <path
                                    d="M2.97 12.92A2 2 0 0 0 2 14.63v3.24a2 2 0 0 0 .97 1.71l3 1.8a2 2 0 0 0 2.06 0L12 19v-5.5l-5-3-4.03 2.42Z">
                                </path>
                                <path d="m7 16.5-4.74-2.85"></path>
                                <path d="m7 16.5 5-3"></path>
                                <path d="M7 16.5v5.17"></path>
                                <path
                                    d="M12 13.5V19l3.97 2.38a2 2 0 0 0 2.06 0l3-1.8a2 2 0 0 0 .97-1.71v-3.24a2 2 0 0 0-.97-1.71L17 10.5l-5 3Z">
                                </path>
                                <path d="m17 16.5-5-3"></path>
                                <path d="m17 16.5 4.74-2.85"></path>
                                <path d="M17 16.5v5.17"></path>
                                <path
                                    d="M7.97 4.42A2 2 0 0 0 7 6.13v4.37l5 3 5-3V6.13a2 2 0 0 0-.97-1.71l-3-1.8a2 2 0 0 0-2.06 0l-3 1.8Z">
                                </path>
                                <path d="M12 8 7.26 5.15"></path>
                                <path d="m12 8 4.74-2.85"></path>
                                <path d="M12 13.5V8"></path>
                            </svg>
                            <div class="vd2z8">
                                <h3
                                    class="dtjcu kxhcs j4ei7 btvdk g4ub0 dark:text-neutral-200 dark:group-hover:text-green-500 dark:group-focus:text-green-500">
                                    Collection
                                </h3>
                                <p class="ldcb4 fj6be dark:text-neutral-500">
                                    Create a new collection of products
                                </p>
                            </div>
                        </a>
                        <!-- End Card -->

                        <!-- Card -->
                        <a class="k4w96 jj2ob k9ios owx04 flex lgqw8 pdrgo items-center zpy54 f0dty l66z3 ffqht k3u76 tgfrq ds6v9 focus:outline-none focus:shadow-md transition dark:bg-neutral-800 dark:border-neutral-700 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                            href="#">
                            <svg class="uvtvh n7sw3 fj6be dark:text-neutral-400" xmlns="http://www.w3.org/2000/svg"
                                width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
                                <rect width="20" height="14" x="2" y="5" rx="2"></rect>
                                <line x1="2" x2="22" y1="10" y2="10"></line>
                            </svg>
                            <div class="vd2z8">
                                <h3
                                    class="dtjcu kxhcs j4ei7 btvdk g4ub0 dark:text-neutral-200 dark:group-hover:text-green-500 dark:group-focus:text-green-500">
                                    Get paid
                                </h3>
                                <p class="ldcb4 fj6be dark:text-neutral-500">
                                    Receive money with Preline's fast and secure payment
                                </p>
                            </div>
                        </a>
                        <!-- End Card -->

                        <!-- Card -->
                        <a class="k4w96 jj2ob k9ios owx04 flex lgqw8 pdrgo items-center zpy54 f0dty l66z3 ffqht k3u76 tgfrq ds6v9 focus:outline-none focus:shadow-md transition dark:bg-neutral-800 dark:border-neutral-700 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                            href="#">
                            <svg class="uvtvh n7sw3 fj6be dark:text-neutral-400" xmlns="http://www.w3.org/2000/svg"
                                width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
                                <rect x="3" y="8" width="18" height="4" rx="1"></rect>
                                <path d="M12 8v13"></path>
                                <path d="M19 12v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7"></path>
                                <path d="M7.5 8a2.5 2.5 0 0 1 0-5A4.8 8 0 0 1 12 8a4.8 8 0 0 1 4.5-5 2.5 2.5 0 0 1 0 5">
                                </path>
                            </svg>
                            <div class="vd2z8">
                                <h3
                                    class="dtjcu kxhcs j4ei7 btvdk g4ub0 dark:text-neutral-200 dark:group-hover:text-green-500 dark:group-focus:text-green-500">
                                    Preline products
                                </h3>
                                <p class="ldcb4 fj6be dark:text-neutral-500">
                                    A collection of 100+ Preline products and more
                                </p>
                            </div>
                        </a>
                        <!-- End Card -->
                    </div>
                    <!-- End Card Grid -->

                    <!-- Filter Group -->
                    <div class="rbxtu flex azaj8 items-center flex-wrap l8bcf v82gf bg8gm ffqht dark:border-neutral-700">
                        <div>
                            <h2 class="uiqm5 kxhcs j4ei7 dark:text-neutral-200">
                                Top products
                            </h2>
                        </div>
                        <!-- End Col -->

                        <div>
                            <!-- Search Input -->
                            <div class="relative">
                                <div class="absolute vt6s4 atruh flex items-center i24sd ootlu srg7w">
                                    <svg class="wlxy3 sfo8l qcpnt fj6be dark:text-neutral-400"
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <path d="m21 21-4.3-4.3"></path>
                                    </svg>
                                </div>
                                <input type="text"
                                    class="rn86h vrf2y h996k block w-full qcnad si6qh yj6bp nq4w8 dtjcu mjqc8 focus:border-green-500 focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-700 dark:border-transparent dark:text-neutral-400 dark:placeholder:text-neutral-400 dark:focus:ring-neutral-600"
                                    placeholder="Search products">
                            </div>
                            <!-- End Search Input -->
                        </div>
                        <!-- End Col -->
                    </div>
                    <!-- End Filter Group -->

                    <div
                        class="fmrhk cs0un szov1 nn2ns g3n6w dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500">
                        <div class="si0ns uiqm5 ja4wp">
                            <!-- Table -->
                            <table class="si0ns phe4g igot0 dark:divide-neutral-700">
                                <thead>
                                    <tr>
                                        <th scope="col" class="c8qx6 in9y1">
                                            <input type="checkbox"
                                                class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800">
                                        </th>

                                        <th scope="col" class="qcnad b318j">
                                            <!-- Sort Dropdown -->
                                            <div class="hs-dropdown relative pqrvw w-full cursor-pointer">
                                                <button id="hs-pro-dutnms" type="button"
                                                    class="qxuf9 j9jou in9y1 w-full flex items-center azej2 rvi38 ouifk qfc33 fj6be focus:outline-none focus:bg-stone-100 dark:text-neutral-500 dark:focus:bg-neutral-700">
                                                    Item
                                                    <svg class="wlxy3 pmkhf eejlj" xmlns="http://www.w3.org/2000/svg"
                                                        width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round">
                                                        <path d="m7 15 5 5 5-5"></path>
                                                        <path d="m7 9 5-5 5 5"></path>
                                                    </svg>
                                                </button>

                                                <!-- Dropdown -->
                                                <div class="hs-dropdown-menu muqsh ccpye transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                                                    aria-labelledby="hs-pro-dutnms">
                                                    <div class="dpnc4">
                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="m5 12 7-7 7 7"></path>
                                                                <path d="M12 19V5"></path>
                                                            </svg>
                                                            Sort ascending
                                                        </button>
                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="M12 5v14"></path>
                                                                <path d="m19 12-7 7-7-7"></path>
                                                            </svg>
                                                            Sort descending
                                                        </button>
                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="m12 19-7-7 7-7"></path>
                                                                <path d="M19 12H5"></path>
                                                            </svg>
                                                            Move left
                                                        </button>
                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="M5 12h14"></path>
                                                                <path d="m12 5 7 7-7 7"></path>
                                                            </svg>
                                                            Move right
                                                        </button>

                                                        <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                                                <path
                                                                    d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68">
                                                                </path>
                                                                <path
                                                                    d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61">
                                                                </path>
                                                                <line x1="2" x2="22" y1="2" y2="22"></line>
                                                            </svg>
                                                            Hide in view
                                                        </button>
                                                    </div>
                                                </div>
                                                <!-- End Dropdown -->
                                            </div>
                                            <!-- End Sort Dropdown -->
                                        </th>

                                        <th scope="col">
                                            <!-- Sort Dropdown -->
                                            <div class="hs-dropdown relative pqrvw w-full cursor-pointer">
                                                <button id="hs-pro-dutads" type="button"
                                                    class="qxuf9 j9jou in9y1 w-full flex items-center azej2 rvi38 ouifk qfc33 fj6be focus:outline-none focus:bg-stone-100 dark:text-neutral-500 dark:focus:bg-neutral-700">
                                                    Change
                                                    <svg class="wlxy3 pmkhf eejlj" xmlns="http://www.w3.org/2000/svg"
                                                        width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round">
                                                        <path d="m7 15 5 5 5-5"></path>
                                                        <path d="m7 9 5-5 5 5"></path>
                                                    </svg>
                                                </button>

                                                <!-- Dropdown -->
                                                <div class="hs-dropdown-menu muqsh ccpye transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                                                    aria-labelledby="hs-pro-dutads">
                                                    <div class="dpnc4">
                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="m5 12 7-7 7 7"></path>
                                                                <path d="M12 19V5"></path>
                                                            </svg>
                                                            Sort ascending
                                                        </button>
                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="M12 5v14"></path>
                                                                <path d="m19 12-7 7-7-7"></path>
                                                            </svg>
                                                            Sort descending
                                                        </button>
                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="m12 19-7-7 7-7"></path>
                                                                <path d="M19 12H5"></path>
                                                            </svg>
                                                            Move left
                                                        </button>
                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="M5 12h14"></path>
                                                                <path d="m12 5 7 7-7 7"></path>
                                                            </svg>
                                                            Move right
                                                        </button>

                                                        <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                                                <path
                                                                    d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68">
                                                                </path>
                                                                <path
                                                                    d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61">
                                                                </path>
                                                                <line x1="2" x2="22" y1="2" y2="22"></line>
                                                            </svg>
                                                            Hide in view
                                                        </button>
                                                    </div>
                                                </div>
                                                <!-- End Dropdown -->
                                            </div>
                                            <!-- End Sort Dropdown -->
                                        </th>

                                        <th scope="col">
                                            <!-- Sort Dropdown -->
                                            <div class="hs-dropdown relative pqrvw w-full cursor-pointer">
                                                <button id="hs-pro-dutemails" type="button"
                                                    class="qxuf9 j9jou in9y1 w-full flex items-center azej2 rvi38 ouifk qfc33 fj6be focus:outline-none focus:bg-stone-100 dark:text-neutral-500 dark:focus:bg-neutral-700">
                                                    Price
                                                    <svg class="wlxy3 pmkhf eejlj" xmlns="http://www.w3.org/2000/svg"
                                                        width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round">
                                                        <path d="m7 15 5 5 5-5"></path>
                                                        <path d="m7 9 5-5 5 5"></path>
                                                    </svg>
                                                </button>

                                                <!-- Dropdown -->
                                                <div class="hs-dropdown-menu muqsh ccpye transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                                                    aria-labelledby="hs-pro-dutemails">
                                                    <div class="dpnc4">
                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="m5 12 7-7 7 7"></path>
                                                                <path d="M12 19V5"></path>
                                                            </svg>
                                                            Sort ascending
                                                        </button>
                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="M12 5v14"></path>
                                                                <path d="m19 12-7 7-7-7"></path>
                                                            </svg>
                                                            Sort descending
                                                        </button>
                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="m12 19-7-7 7-7"></path>
                                                                <path d="M19 12H5"></path>
                                                            </svg>
                                                            Move left
                                                        </button>
                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="M5 12h14"></path>
                                                                <path d="m12 5 7 7-7 7"></path>
                                                            </svg>
                                                            Move right
                                                        </button>

                                                        <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                                                <path
                                                                    d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68">
                                                                </path>
                                                                <path
                                                                    d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61">
                                                                </path>
                                                                <line x1="2" x2="22" y1="2" y2="22"></line>
                                                            </svg>
                                                            Hide in view
                                                        </button>
                                                    </div>
                                                </div>
                                                <!-- End Dropdown -->
                                            </div>
                                            <!-- End Sort Dropdown -->
                                        </th>

                                        <th scope="col">
                                            <!-- Sort Dropdown -->
                                            <div class="hs-dropdown relative pqrvw w-full cursor-pointer">
                                                <button id="hs-pro-dutphs" type="button"
                                                    class="qxuf9 j9jou in9y1 w-full flex items-center azej2 rvi38 ouifk qfc33 fj6be focus:outline-none focus:bg-stone-100 dark:text-neutral-500 dark:focus:bg-neutral-700">
                                                    Sold
                                                    <svg class="wlxy3 pmkhf eejlj" xmlns="http://www.w3.org/2000/svg"
                                                        width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round">
                                                        <path d="m7 15 5 5 5-5"></path>
                                                        <path d="m7 9 5-5 5 5"></path>
                                                    </svg>
                                                </button>

                                                <!-- Dropdown -->
                                                <div class="hs-dropdown-menu muqsh ccpye transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                                                    aria-labelledby="hs-pro-dutphs">
                                                    <div class="dpnc4">
                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="m5 12 7-7 7 7"></path>
                                                                <path d="M12 19V5"></path>
                                                            </svg>
                                                            Sort ascending
                                                        </button>
                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="M12 5v14"></path>
                                                                <path d="m19 12-7 7-7-7"></path>
                                                            </svg>
                                                            Sort descending
                                                        </button>
                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="m12 19-7-7 7-7"></path>
                                                                <path d="M19 12H5"></path>
                                                            </svg>
                                                            Move left
                                                        </button>
                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="M5 12h14"></path>
                                                                <path d="m12 5 7 7-7 7"></path>
                                                            </svg>
                                                            Move right
                                                        </button>

                                                        <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                                                <path
                                                                    d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68">
                                                                </path>
                                                                <path
                                                                    d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61">
                                                                </path>
                                                                <line x1="2" x2="22" y1="2" y2="22"></line>
                                                            </svg>
                                                            Hide in view
                                                        </button>
                                                    </div>
                                                </div>
                                                <!-- End Dropdown -->
                                            </div>
                                            <!-- End Sort Dropdown -->
                                        </th>

                                        <th scope="col">
                                            <!-- Sort Dropdown -->
                                            <div class="hs-dropdown relative pqrvw w-full cursor-pointer">
                                                <button id="hs-pro-dutphsl" type="button"
                                                    class="qxuf9 j9jou in9y1 w-full flex items-center azej2 rvi38 ouifk qfc33 fj6be focus:outline-none focus:bg-stone-100 dark:text-neutral-500 dark:focus:bg-neutral-700">
                                                    Sales
                                                    <svg class="wlxy3 pmkhf eejlj" xmlns="http://www.w3.org/2000/svg"
                                                        width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round">
                                                        <path d="m7 15 5 5 5-5"></path>
                                                        <path d="m7 9 5-5 5 5"></path>
                                                    </svg>
                                                </button>

                                                <!-- Dropdown -->
                                                <div class="hs-dropdown-menu muqsh ccpye transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                                                    aria-labelledby="hs-pro-dutphsl">
                                                    <div class="dpnc4">
                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="m5 12 7-7 7 7"></path>
                                                                <path d="M12 19V5"></path>
                                                            </svg>
                                                            Sort ascending
                                                        </button>
                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="M12 5v14"></path>
                                                                <path d="m19 12-7 7-7-7"></path>
                                                            </svg>
                                                            Sort descending
                                                        </button>
                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="m12 19-7-7 7-7"></path>
                                                                <path d="M19 12H5"></path>
                                                            </svg>
                                                            Move left
                                                        </button>
                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="M5 12h14"></path>
                                                                <path d="m12 5 7 7-7 7"></path>
                                                            </svg>
                                                            Move right
                                                        </button>

                                                        <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                                        <button type="button"
                                                            class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                                                <path
                                                                    d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68">
                                                                </path>
                                                                <path
                                                                    d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61">
                                                                </path>
                                                                <line x1="2" x2="22" y1="2" y2="22"></line>
                                                            </svg>
                                                            Hide in view
                                                        </button>
                                                    </div>
                                                </div>
                                                <!-- End Dropdown -->
                                            </div>
                                            <!-- End Sort Dropdown -->
                                        </th>
                                    </tr>
                                </thead>

                                <tbody class="phe4g igot0 dark:divide-neutral-700">
                                    <tr class="r1hp8 cursor-pointer dark:hover:bg-neutral-900">
                                        <td class="o65eo lnq7o mfkro c8qx6 mvnfe bxk7u">
                                            <input type="checkbox"
                                                class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800">
                                        </td>
                                        <td class="o65eo lnq7o mvnfe qxuf9 relative">
                                            <a class="kw6wr s920d ys3t0" href="#"></a>
                                            <div class="w-full flex items-center ukt6v">
                                                <img class="wlxy3 w13ko e4kj6 q038c"
                                                    src="https://images.unsplash.com/photo-1528310385748-dba09bf1657a?ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&amp;auto=format&amp;fit=crop&amp;w=320&amp;h=320&amp;q=80"
                                                    alt="Image Description">
                                                <div class="zfftf">
                                                    <span class="dtjcu eass7 j4ei7 dark:text-neutral-200">
                                                        Google Home
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="dtjcu v474b dark:text-neutral-400">
                                                72%
                                                <span class="uiqm5 dh17g">
                                                    3.1%
                                                    <svg class="uiqm5 ja4wp sfo8l qcpnt dh17g"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <polyline points="22 17 13.5 8.5 8.5 13.5 2 7"></polyline>
                                                        <polyline points="16 17 22 17 22 11"></polyline>
                                                    </svg>
                                                </span>
                                            </span>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="dtjcu v474b dark:text-neutral-400">
                                                $65
                                            </span>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="dtjcu v474b dark:text-neutral-400">
                                                7,545
                                            </span>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="kxhcs dtjcu j4ei7 dark:text-neutral-200">
                                                $15,302.00
                                            </span>
                                        </td>
                                    </tr>

                                    <tr class="r1hp8 cursor-pointer dark:hover:bg-neutral-900">
                                        <td class="o65eo lnq7o mfkro c8qx6 mvnfe bxk7u">
                                            <input type="checkbox"
                                                class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800">
                                        </td>
                                        <td class="o65eo lnq7o mvnfe qxuf9 relative">
                                            <a class="kw6wr s920d ys3t0" href="#"></a>
                                            <div class="w-full flex items-center ukt6v">
                                                <img class="wlxy3 w13ko e4kj6 q038c"
                                                    src="https://images.unsplash.com/photo-1613852348851-df1739db8201?ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&amp;auto=format&amp;fit=crop&amp;w=320&amp;h=320&amp;q=80"
                                                    alt="Image Description">
                                                <div class="zfftf">
                                                    <span class="dtjcu eass7 j4ei7 dark:text-neutral-200">
                                                        Calvin Klein T-shirts
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="dtjcu v474b dark:text-neutral-400">
                                                50%
                                                <span class="uiqm5 jnh9x">
                                                    47%
                                                    <svg class="uiqm5 ja4wp sfo8l qcpnt jnh9x"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <polyline points="22 7 13.5 15.5 8.5 10.5 2 17"></polyline>
                                                        <polyline points="16 7 22 7 22 13"></polyline>
                                                    </svg>
                                                </span>
                                            </span>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="dtjcu v474b dark:text-neutral-400">
                                                $89
                                            </span>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="dtjcu v474b dark:text-neutral-400">
                                                4,714
                                            </span>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="kxhcs dtjcu j4ei7 dark:text-neutral-200">
                                                $8,466.02
                                            </span>
                                        </td>
                                    </tr>

                                    <tr class="r1hp8 cursor-pointer dark:hover:bg-neutral-900">
                                        <td class="o65eo lnq7o mfkro c8qx6 mvnfe bxk7u">
                                            <input type="checkbox"
                                                class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800">
                                        </td>
                                        <td class="o65eo lnq7o mvnfe qxuf9 relative">
                                            <a class="kw6wr s920d ys3t0" href="#"></a>
                                            <div class="w-full flex items-center ukt6v">
                                                <img class="wlxy3 w13ko e4kj6 q038c"
                                                    src="https://images.unsplash.com/photo-1572635196237-14b3f281503f?ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&amp;auto=format&amp;fit=crop&amp;w=320&amp;h=320&amp;q=80"
                                                    alt="Image Description">
                                                <div class="zfftf">
                                                    <span class="dtjcu eass7 j4ei7 dark:text-neutral-200">
                                                        RayBan black sunglasses
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="dtjcu v474b dark:text-neutral-400">
                                                65%
                                                <span class="uiqm5 dh17g">
                                                    0.9%
                                                    <svg class="uiqm5 ja4wp sfo8l qcpnt dh17g"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <polyline points="22 17 13.5 8.5 8.5 13.5 2 7"></polyline>
                                                        <polyline points="16 17 22 17 22 11"></polyline>
                                                    </svg>
                                                </span>
                                            </span>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="dtjcu v474b dark:text-neutral-400">
                                                $37
                                            </span>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="dtjcu v474b dark:text-neutral-400">
                                                5,951
                                            </span>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="kxhcs dtjcu j4ei7 dark:text-neutral-200">
                                                $10,351.71
                                            </span>
                                        </td>
                                    </tr>

                                    <tr class="r1hp8 cursor-pointer dark:hover:bg-neutral-900">
                                        <td class="o65eo lnq7o mfkro c8qx6 mvnfe bxk7u">
                                            <input type="checkbox"
                                                class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800">
                                        </td>
                                        <td class="o65eo lnq7o mvnfe qxuf9 relative">
                                            <a class="kw6wr s920d ys3t0" href="#"></a>
                                            <div class="w-full flex items-center ukt6v">
                                                <img class="wlxy3 w13ko e4kj6 q038c"
                                                    src="https://images.unsplash.com/photo-1610398752800-146f269dfcc8?ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&amp;auto=format&amp;fit=crop&amp;w=320&amp;h=320&amp;q=80"
                                                    alt="Image Description">
                                                <div class="zfftf">
                                                    <span class="dtjcu eass7 j4ei7 dark:text-neutral-200">
                                                        Mango Women's shoe
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="dtjcu v474b dark:text-neutral-400">
                                                53%
                                                <span class="uiqm5 dh17g">
                                                    0.1%
                                                    <svg class="uiqm5 ja4wp sfo8l qcpnt dh17g"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <polyline points="22 17 13.5 8.5 8.5 13.5 2 7"></polyline>
                                                        <polyline points="16 17 22 17 22 11"></polyline>
                                                    </svg>
                                                </span>
                                            </span>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="dtjcu v474b dark:text-neutral-400">
                                                $65
                                            </span>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="dtjcu v474b dark:text-neutral-400">
                                                5,002
                                            </span>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="kxhcs dtjcu j4ei7 dark:text-neutral-200">
                                                $9,917.45
                                            </span>
                                        </td>
                                    </tr>

                                    <tr class="r1hp8 cursor-pointer dark:hover:bg-neutral-900">
                                        <td class="o65eo lnq7o mfkro c8qx6 mvnfe bxk7u">
                                            <input type="checkbox"
                                                class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800">
                                        </td>
                                        <td class="o65eo lnq7o mvnfe qxuf9 relative">
                                            <a class="kw6wr s920d ys3t0" href="#"></a>
                                            <div class="w-full flex items-center ukt6v">
                                                <img class="wlxy3 w13ko e4kj6 q038c"
                                                    src="https://images.unsplash.com/photo-1620799140408-edc6dcb6d633?ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&amp;auto=format&amp;fit=crop&amp;w=320&amp;h=320&amp;q=80"
                                                    alt="Image Description">
                                                <div class="zfftf">
                                                    <span class="dtjcu eass7 j4ei7 dark:text-neutral-200">
                                                        Plain white sweater
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="dtjcu v474b dark:text-neutral-400">
                                                69%
                                                <span class="uiqm5 jnh9x">
                                                    14.2%
                                                    <svg class="uiqm5 ja4wp sfo8l qcpnt jnh9x"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <polyline points="22 7 13.5 15.5 8.5 10.5 2 17"></polyline>
                                                        <polyline points="16 7 22 7 22 13"></polyline>
                                                    </svg>
                                                </span>
                                            </span>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="dtjcu v474b dark:text-neutral-400">
                                                $21
                                            </span>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="dtjcu v474b dark:text-neutral-400">
                                                6,643
                                            </span>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="kxhcs dtjcu j4ei7 dark:text-neutral-200">
                                                $12,492.21
                                            </span>
                                        </td>
                                    </tr>

                                    <tr class="r1hp8 cursor-pointer dark:hover:bg-neutral-900">
                                        <td class="o65eo lnq7o mfkro c8qx6 mvnfe bxk7u">
                                            <input type="checkbox"
                                                class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800">
                                        </td>
                                        <td class="o65eo lnq7o mvnfe qxuf9 relative">
                                            <a class="kw6wr s920d ys3t0" href="#"></a>
                                            <div class="w-full flex items-center ukt6v">
                                                <img class="wlxy3 w13ko e4kj6 q038c"
                                                    src="https://images.unsplash.com/photo-1611911813383-67769b37a149?ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&amp;auto=format&amp;fit=crop&amp;w=320&amp;h=320&amp;q=80"
                                                    alt="Image Description">
                                                <div class="zfftf">
                                                    <a class="dtjcu eass7 j4ei7 z9fa3 cmffi cdcib focus:outline-none focus:underline focus:text-green-600 dark:text-neutral-200 dark:hover:text-green-500 dark:focus:text-green-500"
                                                        href="../../pro/ecommerce/product-details.html">
                                                        Pattern Winter Sweater
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="dtjcu v474b dark:text-neutral-400">
                                                42%
                                                <span class="uiqm5 jnh9x">
                                                    8.1%
                                                    <svg class="uiqm5 ja4wp sfo8l qcpnt jnh9x"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <polyline points="22 7 13.5 15.5 8.5 10.5 2 17"></polyline>
                                                        <polyline points="16 7 22 7 22 13"></polyline>
                                                    </svg>
                                                </span>
                                            </span>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="dtjcu v474b dark:text-neutral-400">
                                                $47
                                            </span>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="dtjcu v474b dark:text-neutral-400">
                                                3,391
                                            </span>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="kxhcs dtjcu j4ei7 dark:text-neutral-200">
                                                $7,089.10
                                            </span>
                                        </td>
                                    </tr>

                                    <tr class="r1hp8 cursor-pointer dark:hover:bg-neutral-900">
                                        <td class="o65eo lnq7o mfkro c8qx6 mvnfe bxk7u">
                                            <input type="checkbox"
                                                class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800">
                                        </td>
                                        <td class="o65eo lnq7o mvnfe qxuf9 relative">
                                            <a class="kw6wr s920d ys3t0" href="#"></a>
                                            <div class="w-full flex items-center ukt6v">
                                                <img class="wlxy3 w13ko e4kj6 q038c"
                                                    src="https://images.unsplash.com/photo-1616969899621-0ea269426a21?ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&amp;auto=format&amp;fit=crop&amp;w=320&amp;h=320&amp;q=80"
                                                    alt="Image Description">
                                                <div class="zfftf">
                                                    <span class="dtjcu eass7 j4ei7 dark:text-neutral-200">
                                                        White Blazer by Armani
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="dtjcu v474b dark:text-neutral-400">
                                                11%
                                                <span class="uiqm5 dh17g">
                                                    0.3%
                                                    <svg class="uiqm5 ja4wp sfo8l qcpnt dh17g"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <polyline points="22 17 13.5 8.5 8.5 13.5 2 7"></polyline>
                                                        <polyline points="16 17 22 17 22 11"></polyline>
                                                    </svg>
                                                </span>
                                            </span>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="dtjcu v474b dark:text-neutral-400">
                                                $17
                                            </span>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="dtjcu v474b dark:text-neutral-400">
                                                4,191
                                            </span>
                                        </td>
                                        <td class="o65eo lnq7o mfkro mvnfe qxuf9">
                                            <span class="kxhcs dtjcu j4ei7 dark:text-neutral-200">
                                                $8,610
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <!-- End Table -->
                        </div>
                    </div>

                    <!-- Footer -->
                    <div class="i3acl flex azaj8 items-center l8bcf">
                        <!-- Select -->
                        <div class="relative uiqm5">
                            <div class="hs-select relative"><select data-hs-select="{
                  &quot;placeholder&quot;: &quot;Select option...&quot;,
                  &quot;toggleTag&quot;: &quot;<button type=\&quot;button\&quot;></button>&quot;,
                  &quot;toggleClasses&quot;: &quot;msicw n7tpi relative xxfsd c8qx6 zam4o flex dqqs4 text-nowrap w-full cursor-pointer f0dty l66z3 ffqht nq4w8 in9y1 dtjcu focus:outline-none focus:ring-2 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600&quot;,
                  &quot;dropdownClasses&quot;: &quot;l2ej6 s269j stjsq yjsas dpnc4 xy3is bx801 pk1vk f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] mws46 szov1 nn2ns g3n6w dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900&quot;,
                  &quot;optionClasses&quot;: &quot;xhxgq dark:hs-selected:bg-neutral-800 zdxcz grnyh w-full ldcb4 j4ei7 cursor-pointer r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700&quot;,
                  &quot;optionTemplate&quot;: &quot;<div class=\&quot;flex azaj8 items-center w-full\&quot;><span data-title></span><span class=\&quot;hidden hs-selected:block\&quot;><svg class=\&quot;wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200\&quot; xmlns=\&quot;http:.w3.org/2000/svg\&quot; width=\&quot;24\&quot; height=\&quot;24\&quot; viewBox=\&quot;0 0 24 24\&quot; fill=\&quot;none\&quot; stroke=\&quot;currentColor\&quot; stroke-width=\&quot;2\&quot; stroke-linecap=\&quot;round\&quot; stroke-linejoin=\&quot;round\&quot;><polyline points=\&quot;20 6 9 17 4 12\&quot;/></svg></span></div>&quot;
                }" class="hidden" style="display: none;">
                                    <option value="">Choose</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option selected="">5</option>
                                    <option>6</option>
                                    <option>7</option>
                                </select><button type="button"
                                    class="msicw n7tpi relative xxfsd c8qx6 zam4o flex dqqs4 text-nowrap w-full cursor-pointer f0dty l66z3 ffqht nq4w8 in9y1 dtjcu focus:outline-none focus:ring-2 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"><span
                                        class="truncate">5</span></button>
                                <div
                                    class="absolute top-full hidden l2ej6 s269j stjsq yjsas dpnc4 xy3is bx801 pk1vk f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] mws46 szov1 nn2ns g3n6w dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900">
                                    <div data-value="1" data-title-value="1" tabindex="0"
                                        class="cursor-pointer xhxgq dark:hs-selected:bg-neutral-800 zdxcz grnyh w-full ldcb4 j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                                        <div class="flex azaj8 items-center w-full"><span data-title="">1</span><span
                                                class="hidden hs-selected:block"><svg
                                                    class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                    xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round">
                                                    <polyline points="20 6 9 17 4 12"></polyline>
                                                </svg></span></div>
                                    </div>
                                    <div data-value="2" data-title-value="2" tabindex="1"
                                        class="cursor-pointer xhxgq dark:hs-selected:bg-neutral-800 zdxcz grnyh w-full ldcb4 j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                                        <div class="flex azaj8 items-center w-full"><span data-title="">2</span><span
                                                class="hidden hs-selected:block"><svg
                                                    class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                    xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round">
                                                    <polyline points="20 6 9 17 4 12"></polyline>
                                                </svg></span></div>
                                    </div>
                                    <div data-value="3" data-title-value="3" tabindex="2"
                                        class="cursor-pointer xhxgq dark:hs-selected:bg-neutral-800 zdxcz grnyh w-full ldcb4 j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                                        <div class="flex azaj8 items-center w-full"><span data-title="">3</span><span
                                                class="hidden hs-selected:block"><svg
                                                    class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                    xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round">
                                                    <polyline points="20 6 9 17 4 12"></polyline>
                                                </svg></span></div>
                                    </div>
                                    <div data-value="4" data-title-value="4" tabindex="3"
                                        class="cursor-pointer xhxgq dark:hs-selected:bg-neutral-800 zdxcz grnyh w-full ldcb4 j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                                        <div class="flex azaj8 items-center w-full"><span data-title="">4</span><span
                                                class="hidden hs-selected:block"><svg
                                                    class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                    xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round">
                                                    <polyline points="20 6 9 17 4 12"></polyline>
                                                </svg></span></div>
                                    </div>
                                    <div data-value="5" data-title-value="5" tabindex="4"
                                        class="cursor-pointer selected xhxgq dark:hs-selected:bg-neutral-800 zdxcz grnyh w-full ldcb4 j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                                        <div class="flex azaj8 items-center w-full"><span data-title="">5</span><span
                                                class="hidden hs-selected:block"><svg
                                                    class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                    xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round">
                                                    <polyline points="20 6 9 17 4 12"></polyline>
                                                </svg></span></div>
                                    </div>
                                    <div data-value="6" data-title-value="6" tabindex="5"
                                        class="cursor-pointer xhxgq dark:hs-selected:bg-neutral-800 zdxcz grnyh w-full ldcb4 j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                                        <div class="flex azaj8 items-center w-full"><span data-title="">6</span><span
                                                class="hidden hs-selected:block"><svg
                                                    class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                    xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round">
                                                    <polyline points="20 6 9 17 4 12"></polyline>
                                                </svg></span></div>
                                    </div>
                                    <div data-value="7" data-title-value="7" tabindex="6"
                                        class="cursor-pointer xhxgq dark:hs-selected:bg-neutral-800 zdxcz grnyh w-full ldcb4 j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                                        <div class="flex azaj8 items-center w-full"><span data-title="">7</span><span
                                                class="hidden hs-selected:block"><svg
                                                    class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                                    xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round">
                                                    <polyline points="20 6 9 17 4 12"></polyline>
                                                </svg></span></div>
                                    </div>
                                </div>
                            </div>

                            <div class="absolute ikyla cs373 -translate-y-1/2">
                                <svg class="wlxy3 xtsb0 f5cx3 fj6be" xmlns="http://www.w3.org/2000/svg" width="24"
                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="m7 15 5 5 5-5"></path>
                                    <path d="m7 9 5-5 5 5"></path>
                                </svg>
                            </div>
                        </div>
                        <!-- End Select -->

                        <a class="pqrvw items-center azej2 dtjcu mq8xz cmffi cdcib focus:outline-none focus:underline dark:text-green-400 dark:hover:text-green-500"
                            href="#">
                            All top products
                            <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path d="m9 18 6-6-6-6"></path>
                            </svg>
                        </a>
                    </div>
                    <!-- End Footer -->
                </div>
                <!-- End Products Table Card -->

                <!-- Charts Grid -->
                <div class="jjege oh4rk no4dk l8bcf lwtpn jl2km">
                    <!-- Double Line Chart in Card -->
                    <div class="pqx67 flex lgqw8 f0dty l66z3 ffqht tgfrq k3u76 dark:bg-neutral-800 dark:border-neutral-700">
                        <h2 class="uiqm5 kxhcs j4ei7 dark:text-neutral-200">
                            Total sales
                        </h2>

                        <!-- Subheader -->
                        <div class="jjege dr94e items-center pw4gn okw5h q7u6r">
                            <div>
                                <h4 class="hbd69 j4ei7 dark:text-neutral-200">
                                    $1,597,820.75
                                    <span class="pqrvw items-center azej2 dtjcu jnh9x">
                                        <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24"
                                            height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="22 7 13.5 15.5 8.5 10.5 2 17"></polyline>
                                            <polyline points="16 7 22 7 22 13"></polyline>
                                        </svg>
                                        35.8%
                                    </span>
                                </h4>
                            </div>

                            <div class="w63ac">
                                <p class="dtjcu fj6be dark:text-neutral-400">
                                    1,347,935 orders
                                </p>
                            </div>
                        </div>
                        <!-- End Subheader -->

                        <!-- Apex Line Chart -->
                        <div id="hs-total-sales-line-chart" class="ul66i bep17" style="min-height: 165px;">
                            <div id="apexcharts3mbicg9g" class="apexcharts-canvas apexcharts3mbicg9g apexcharts-theme-light"
                                style="width: 383px; height: 150px;"><svg id="SvgjsSvg1826" width="383" height="150"
                                    xmlns="http://www.w3.org/2000/svg" version="1.1"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev"
                                    class="apexcharts-svg" xmlns:data="ApexChartsNS" transform="translate(0, 0)"
                                    style="background: transparent;">
                                    <foreignObject x="0" y="0" width="383" height="150">
                                        <div class="apexcharts-legend" xmlns="http://www.w3.org/1999/xhtml"
                                            style="max-height: 75px;"></div>
                                    </foreignObject>
                                    <rect id="SvgjsRect1831" width="0" height="0" x="0" y="0" rx="0" ry="0" opacity="1"
                                        stroke-width="0" stroke="none" stroke-dasharray="0" fill="#fefefe"></rect>
                                    <g id="SvgjsG1870" class="apexcharts-yaxis" rel="0" transform="translate(17.59375, 0)">
                                        <g id="SvgjsG1871" class="apexcharts-yaxis-texts-g"
                                            transform="translate(-20.75, 0)"><text id="SvgjsText1873"
                                                font-family="Inter, ui-sans-serif" x="20" y="31.5" text-anchor="start"
                                                dominant-baseline="auto" font-size="11px" font-weight="400" fill="#a8a29e"
                                                class="apexcharts-text apexcharts-yaxis-label "
                                                style="font-family: Inter, ui-sans-serif;">
                                                <tspan id="SvgjsTspan1874">420</tspan>
                                                <title>420</title>
                                            </text><text id="SvgjsText1876" font-family="Inter, ui-sans-serif" x="20"
                                                y="54.5" text-anchor="start" dominant-baseline="auto" font-size="11px"
                                                font-weight="400" fill="#a8a29e"
                                                class="apexcharts-text apexcharts-yaxis-label "
                                                style="font-family: Inter, ui-sans-serif;">
                                                <tspan id="SvgjsTspan1877">360</tspan>
                                                <title>360</title>
                                            </text><text id="SvgjsText1879" font-family="Inter, ui-sans-serif" x="20"
                                                y="77.5" text-anchor="start" dominant-baseline="auto" font-size="11px"
                                                font-weight="400" fill="#a8a29e"
                                                class="apexcharts-text apexcharts-yaxis-label "
                                                style="font-family: Inter, ui-sans-serif;">
                                                <tspan id="SvgjsTspan1880">300</tspan>
                                                <title>300</title>
                                            </text><text id="SvgjsText1882" font-family="Inter, ui-sans-serif" x="20"
                                                y="100.5" text-anchor="start" dominant-baseline="auto" font-size="11px"
                                                font-weight="400" fill="#a8a29e"
                                                class="apexcharts-text apexcharts-yaxis-label "
                                                style="font-family: Inter, ui-sans-serif;">
                                                <tspan id="SvgjsTspan1883">240</tspan>
                                                <title>240</title>
                                            </text><text id="SvgjsText1885" font-family="Inter, ui-sans-serif" x="20"
                                                y="123.5" text-anchor="start" dominant-baseline="auto" font-size="11px"
                                                font-weight="400" fill="#a8a29e"
                                                class="apexcharts-text apexcharts-yaxis-label "
                                                style="font-family: Inter, ui-sans-serif;">
                                                <tspan id="SvgjsTspan1886">180</tspan>
                                                <title>180</title>
                                            </text><text id="SvgjsText1888" font-family="Inter, ui-sans-serif" x="20"
                                                y="146.5" text-anchor="start" dominant-baseline="auto" font-size="11px"
                                                font-weight="400" fill="#a8a29e"
                                                class="apexcharts-text apexcharts-yaxis-label "
                                                style="font-family: Inter, ui-sans-serif;">
                                                <tspan id="SvgjsTspan1889">120</tspan>
                                                <title>120</title>
                                            </text></g>
                                    </g>
                                    <g id="SvgjsG1828" class="apexcharts-inner apexcharts-graphical"
                                        transform="translate(47.59375, 30)">
                                        <defs id="SvgjsDefs1827">
                                            <clipPath id="gridRectMask3mbicg9g">
                                                <rect id="SvgjsRect1833" width="342.40625" height="131" x="-5" y="-8" rx="0"
                                                    ry="0" opacity="1" stroke-width="0" stroke="none" stroke-dasharray="0"
                                                    fill="#fff"></rect>
                                            </clipPath>
                                            <clipPath id="forecastMask3mbicg9g"></clipPath>
                                            <clipPath id="nonForecastMask3mbicg9g"></clipPath>
                                            <clipPath id="gridRectMarkerMask3mbicg9g">
                                                <rect id="SvgjsRect1834" width="339.40625" height="119" x="-2" y="-2" rx="0"
                                                    ry="0" opacity="1" stroke-width="0" stroke="none" stroke-dasharray="0"
                                                    fill="#fff"></rect>
                                            </clipPath>
                                        </defs>
                                        <line id="SvgjsLine1832" x1="0" y1="0" x2="0" y2="115" stroke="#b6b6b6"
                                            stroke-dasharray="3" stroke-linecap="butt" class="apexcharts-xcrosshairs" x="0"
                                            y="0" width="1" height="115" fill="#b1b9c4" filter="none" fill-opacity="0.9"
                                            stroke-width="1"></line>
                                        <g id="SvgjsG1844" class="apexcharts-grid">
                                            <g id="SvgjsG1845" class="apexcharts-gridlines-horizontal">
                                                <line id="SvgjsLine1849" x1="0" y1="23" x2="335.40625" y2="23"
                                                    stroke="#404040" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline"></line>
                                                <line id="SvgjsLine1850" x1="0" y1="46" x2="335.40625" y2="46"
                                                    stroke="#404040" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline"></line>
                                                <line id="SvgjsLine1851" x1="0" y1="69" x2="335.40625" y2="69"
                                                    stroke="#404040" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline"></line>
                                                <line id="SvgjsLine1852" x1="0" y1="92" x2="335.40625" y2="92"
                                                    stroke="#404040" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline"></line>
                                            </g>
                                            <g id="SvgjsG1846" class="apexcharts-gridlines-vertical"></g>
                                            <line id="SvgjsLine1855" x1="0" y1="115" x2="335.40625" y2="115"
                                                stroke="transparent" stroke-dasharray="0" stroke-linecap="butt"></line>
                                            <line id="SvgjsLine1854" x1="0" y1="1" x2="0" y2="115" stroke="transparent"
                                                stroke-dasharray="0" stroke-linecap="butt"></line>
                                        </g>
                                        <g id="SvgjsG1847" class="apexcharts-grid-borders">
                                            <line id="SvgjsLine1848" x1="0" y1="0" x2="335.40625" y2="0" stroke="#404040"
                                                stroke-dasharray="0" stroke-linecap="butt" class="apexcharts-gridline">
                                            </line>
                                            <line id="SvgjsLine1853" x1="0" y1="115" x2="335.40625" y2="115"
                                                stroke="#404040" stroke-dasharray="0" stroke-linecap="butt"
                                                class="apexcharts-gridline"></line>
                                        </g>
                                        <g id="SvgjsG1835" class="apexcharts-line-series apexcharts-plot-series">
                                            <g id="SvgjsG1836" class="apexcharts-series" zIndex="0" seriesName="Thisxmonth"
                                                data:longestSeries="true" rel="1" data:realIndex="0">
                                                <path id="SvgjsPath1839"
                                                    d="M 0 84.33333333333333C 13.043576388888889 84.33333333333333 24.223784722222227 84.33333333333333 37.267361111111114 84.33333333333333C 50.3109375 84.33333333333333 61.49114583333334 69 74.53472222222223 69C 87.57829861111111 69 98.75850694444445 26.833333333333343 111.80208333333333 26.833333333333343C 124.84565972222222 26.833333333333343 136.02586805555558 84.33333333333333 149.06944444444446 84.33333333333333C 162.11302083333334 84.33333333333333 173.2932291666667 26.833333333333343 186.33680555555557 26.833333333333343C 199.38038194444445 26.833333333333343 210.56059027777778 84.33333333333333 223.60416666666666 84.33333333333333C 236.64774305555554 84.33333333333333 247.8279513888889 65.16666666666667 260.87152777777777 65.16666666666667C 273.91510416666665 65.16666666666667 285.09531250000003 51.75 298.1388888888889 51.75C 311.1824652777778 51.75 322.3626736111111 76.66666666666667 335.40625 76.66666666666667"
                                                    fill="none" fill-opacity="1" stroke="rgba(34,197,94,0.85)"
                                                    stroke-opacity="1" stroke-linecap="butt" stroke-width="3"
                                                    stroke-dasharray="0" class="apexcharts-line" index="0"
                                                    clip-path="url(#gridRectMask3mbicg9g)"
                                                    pathTo="M 0 84.33333333333333C 13.043576388888889 84.33333333333333 24.223784722222227 84.33333333333333 37.267361111111114 84.33333333333333C 50.3109375 84.33333333333333 61.49114583333334 69 74.53472222222223 69C 87.57829861111111 69 98.75850694444445 26.833333333333343 111.80208333333333 26.833333333333343C 124.84565972222222 26.833333333333343 136.02586805555558 84.33333333333333 149.06944444444446 84.33333333333333C 162.11302083333334 84.33333333333333 173.2932291666667 26.833333333333343 186.33680555555557 26.833333333333343C 199.38038194444445 26.833333333333343 210.56059027777778 84.33333333333333 223.60416666666666 84.33333333333333C 236.64774305555554 84.33333333333333 247.8279513888889 65.16666666666667 260.87152777777777 65.16666666666667C 273.91510416666665 65.16666666666667 285.09531250000003 51.75 298.1388888888889 51.75C 311.1824652777778 51.75 322.3626736111111 76.66666666666667 335.40625 76.66666666666667"
                                                    pathFrom="M -1 161 L -1 161 L 37.267361111111114 161 L 74.53472222222223 161 L 111.80208333333333 161 L 149.06944444444446 161 L 186.33680555555557 161 L 223.60416666666666 161 L 260.87152777777777 161 L 298.1388888888889 161 L 335.40625 161"
                                                    fill-rule="evenodd"></path>
                                                <g id="SvgjsG1837"
                                                    class="apexcharts-series-markers-wrap apexcharts-hidden-element-shown"
                                                    data:realIndex="0">
                                                    <g class="apexcharts-series-markers">
                                                        <circle id="SvgjsCircle1893" r="0" cx="0" cy="0"
                                                            class="apexcharts-marker w1xaenewm no-pointer-events"
                                                            stroke="#ffffff" fill="#22c55e" fill-opacity="1"
                                                            stroke-width="2" stroke-opacity="0.9" default-marker-size="0">
                                                        </circle>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="SvgjsG1840" class="apexcharts-series" zIndex="1" seriesName="Lastxmonth"
                                                data:longestSeries="true" rel="2" data:realIndex="1">
                                                <path id="SvgjsPath1843"
                                                    d="M 0 103.5C 13.043576388888889 103.5 24.223784722222227 72.83333333333333 37.267361111111114 72.83333333333333C 50.3109375 72.83333333333333 61.49114583333334 14.566666666666663 74.53472222222223 14.566666666666663C 87.57829861111111 14.566666666666663 98.75850694444445 82.8 111.80208333333333 82.8C 124.84565972222222 82.8 136.02586805555558 57.88333333333334 149.06944444444446 57.88333333333334C 162.11302083333334 57.88333333333334 173.2932291666667 49.83333333333333 186.33680555555557 49.83333333333333C 199.38038194444445 49.83333333333333 210.56059027777778 84.33333333333333 223.60416666666666 84.33333333333333C 236.64774305555554 84.33333333333333 247.8279513888889 65.16666666666667 260.87152777777777 65.16666666666667C 273.91510416666665 65.16666666666667 285.09531250000003 84.33333333333333 298.1388888888889 84.33333333333333C 311.1824652777778 84.33333333333333 322.3626736111111 74.75 335.40625 74.75"
                                                    fill="none" fill-opacity="1" stroke="rgba(115,115,115,0.85)"
                                                    stroke-opacity="1" stroke-linecap="butt" stroke-width="3"
                                                    stroke-dasharray="0" class="apexcharts-line" index="1"
                                                    clip-path="url(#gridRectMask3mbicg9g)"
                                                    pathTo="M 0 103.5C 13.043576388888889 103.5 24.223784722222227 72.83333333333333 37.267361111111114 72.83333333333333C 50.3109375 72.83333333333333 61.49114583333334 14.566666666666663 74.53472222222223 14.566666666666663C 87.57829861111111 14.566666666666663 98.75850694444445 82.8 111.80208333333333 82.8C 124.84565972222222 82.8 136.02586805555558 57.88333333333334 149.06944444444446 57.88333333333334C 162.11302083333334 57.88333333333334 173.2932291666667 49.83333333333333 186.33680555555557 49.83333333333333C 199.38038194444445 49.83333333333333 210.56059027777778 84.33333333333333 223.60416666666666 84.33333333333333C 236.64774305555554 84.33333333333333 247.8279513888889 65.16666666666667 260.87152777777777 65.16666666666667C 273.91510416666665 65.16666666666667 285.09531250000003 84.33333333333333 298.1388888888889 84.33333333333333C 311.1824652777778 84.33333333333333 322.3626736111111 74.75 335.40625 74.75"
                                                    pathFrom="M -1 161 L -1 161 L 37.267361111111114 161 L 74.53472222222223 161 L 111.80208333333333 161 L 149.06944444444446 161 L 186.33680555555557 161 L 223.60416666666666 161 L 260.87152777777777 161 L 298.1388888888889 161 L 335.40625 161"
                                                    fill-rule="evenodd"></path>
                                                <g id="SvgjsG1841"
                                                    class="apexcharts-series-markers-wrap apexcharts-hidden-element-shown"
                                                    data:realIndex="1">
                                                    <g class="apexcharts-series-markers">
                                                        <circle id="SvgjsCircle1894" r="0" cx="0" cy="0"
                                                            class="apexcharts-marker w46o432eu no-pointer-events"
                                                            stroke="#ffffff" fill="#737373" fill-opacity="1"
                                                            stroke-width="2" stroke-opacity="0.9" default-marker-size="0">
                                                        </circle>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="SvgjsG1838" class="apexcharts-datalabels" data:realIndex="0"></g>
                                            <g id="SvgjsG1842" class="apexcharts-datalabels" data:realIndex="1"></g>
                                        </g>
                                        <line id="SvgjsLine1856" x1="0" y1="0" x2="335.40625" y2="0" stroke="#b6b6b6"
                                            stroke-dasharray="0" stroke-width="1" stroke-linecap="butt"
                                            class="apexcharts-ycrosshairs"></line>
                                        <line id="SvgjsLine1857" x1="0" y1="0" x2="335.40625" y2="0" stroke-dasharray="0"
                                            stroke-width="0" stroke-linecap="butt" class="apexcharts-ycrosshairs-hidden">
                                        </line>
                                        <g id="SvgjsG1858" class="apexcharts-xaxis" transform="translate(0, 0)">
                                            <g id="SvgjsG1859" class="apexcharts-xaxis-texts-g"
                                                transform="translate(0, -4)"></g>
                                        </g>
                                        <g id="SvgjsG1890" class="apexcharts-yaxis-annotations"></g>
                                        <g id="SvgjsG1891" class="apexcharts-xaxis-annotations"></g>
                                        <g id="SvgjsG1892" class="apexcharts-point-annotations"></g>
                                    </g>
                                </svg>
                                <div class="apexcharts-tooltip apexcharts-theme-light">
                                    <div class="apexcharts-tooltip-title"
                                        style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;"></div>
                                    <div class="apexcharts-tooltip-series-group" style="order: 1;"><span
                                            class="apexcharts-tooltip-marker"
                                            style="background-color: rgb(34, 197, 94);"></span>
                                        <div class="apexcharts-tooltip-text"
                                            style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                            <div class="apexcharts-tooltip-y-group"><span
                                                    class="apexcharts-tooltip-text-y-label"></span><span
                                                    class="apexcharts-tooltip-text-y-value"></span></div>
                                            <div class="apexcharts-tooltip-goals-group"><span
                                                    class="apexcharts-tooltip-text-goals-label"></span><span
                                                    class="apexcharts-tooltip-text-goals-value"></span></div>
                                            <div class="apexcharts-tooltip-z-group"><span
                                                    class="apexcharts-tooltip-text-z-label"></span><span
                                                    class="apexcharts-tooltip-text-z-value"></span></div>
                                        </div>
                                    </div>
                                    <div class="apexcharts-tooltip-series-group" style="order: 2;"><span
                                            class="apexcharts-tooltip-marker"
                                            style="background-color: rgb(115, 115, 115);"></span>
                                        <div class="apexcharts-tooltip-text"
                                            style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                            <div class="apexcharts-tooltip-y-group"><span
                                                    class="apexcharts-tooltip-text-y-label"></span><span
                                                    class="apexcharts-tooltip-text-y-value"></span></div>
                                            <div class="apexcharts-tooltip-goals-group"><span
                                                    class="apexcharts-tooltip-text-goals-label"></span><span
                                                    class="apexcharts-tooltip-text-goals-value"></span></div>
                                            <div class="apexcharts-tooltip-z-group"><span
                                                    class="apexcharts-tooltip-text-z-label"></span><span
                                                    class="apexcharts-tooltip-text-z-value"></span></div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="apexcharts-yaxistooltip apexcharts-yaxistooltip-0 apexcharts-yaxistooltip-left apexcharts-theme-light">
                                    <div class="apexcharts-yaxistooltip-text"></div>
                                </div>
                            </div>
                        </div>

                        <!-- Legend Indicator -->
                        <div class="flex pdrgo items-center xrsjp i3acl">
                            <div class="pqrvw items-center">
                                <span class="w1ile vzn8i uiqm5 k6q5b ifon2 nhufc"></span>
                                <span class="ldcb4 v474b dark:text-neutral-400">
                                    This month
                                </span>
                            </div>
                            <div class="pqrvw items-center">
                                <span class="w1ile vzn8i uiqm5 g8qb9 ifon2 nhufc dark:bg-neutral-700"></span>
                                <span class="ldcb4 v474b dark:text-neutral-400">
                                    Last month
                                </span>
                            </div>
                        </div>
                        <!-- End Legend Indicator -->
                    </div>
                    <!-- End Double Line Chart in Card -->

                    <!-- Double Line Chart in Card -->
                    <div class="pqx67 flex lgqw8 f0dty l66z3 ffqht tgfrq k3u76 dark:bg-neutral-800 dark:border-neutral-700">
                        <h2 class="uiqm5 kxhcs j4ei7 dark:text-neutral-200">
                            Visitors
                        </h2>

                        <!-- Subheader -->
                        <div class="jjege dr94e items-center pw4gn okw5h q7u6r">
                            <div>
                                <h4 class="hbd69 j4ei7 dark:text-neutral-200">
                                    831,071
                                    <span class="pqrvw items-center azej2 dtjcu dh17g">
                                        <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24"
                                            height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="22 17 13.5 8.5 8.5 13.5 2 7"></polyline>
                                            <polyline points="16 17 22 17 22 11"></polyline>
                                        </svg>
                                        18%
                                    </span>
                                </h4>
                            </div>

                            <div class="w63ac">
                                <p class="dtjcu fj6be dark:text-neutral-400">
                                    476,001 orders
                                </p>
                            </div>
                        </div>
                        <!-- End Subheader -->

                        <!-- Apex Line Chart -->
                        <div id="hs-total-visitors-line-chart" class="ul66i bep17" style="min-height: 165px;">
                            <div id="apexchartsxziep4i2" class="apexcharts-canvas apexchartsxziep4i2 apexcharts-theme-light"
                                style="width: 383px; height: 150px;"><svg id="SvgjsSvg1897" width="383" height="150"
                                    xmlns="http://www.w3.org/2000/svg" version="1.1"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev"
                                    class="apexcharts-svg" xmlns:data="ApexChartsNS" transform="translate(0, 0)"
                                    style="background: transparent;">
                                    <foreignObject x="0" y="0" width="383" height="150">
                                        <div class="apexcharts-legend" xmlns="http://www.w3.org/1999/xhtml"
                                            style="max-height: 75px;"></div>
                                    </foreignObject>
                                    <rect id="SvgjsRect1902" width="0" height="0" x="0" y="0" rx="0" ry="0" opacity="1"
                                        stroke-width="0" stroke="none" stroke-dasharray="0" fill="#fefefe"></rect>
                                    <g id="SvgjsG1941" class="apexcharts-yaxis" rel="0" transform="translate(10.71875, 0)">
                                        <g id="SvgjsG1942" class="apexcharts-yaxis-texts-g"
                                            transform="translate(-13.875, 0)"><text id="SvgjsText1944"
                                                font-family="Inter, ui-sans-serif" x="20" y="31.5" text-anchor="start"
                                                dominant-baseline="auto" font-size="11px" font-weight="400" fill="#a8a29e"
                                                class="apexcharts-text apexcharts-yaxis-label "
                                                style="font-family: Inter, ui-sans-serif;">
                                                <tspan id="SvgjsTspan1945">42</tspan>
                                                <title>42</title>
                                            </text><text id="SvgjsText1947" font-family="Inter, ui-sans-serif" x="20"
                                                y="54.5" text-anchor="start" dominant-baseline="auto" font-size="11px"
                                                font-weight="400" fill="#a8a29e"
                                                class="apexcharts-text apexcharts-yaxis-label "
                                                style="font-family: Inter, ui-sans-serif;">
                                                <tspan id="SvgjsTspan1948">36</tspan>
                                                <title>36</title>
                                            </text><text id="SvgjsText1950" font-family="Inter, ui-sans-serif" x="20"
                                                y="77.5" text-anchor="start" dominant-baseline="auto" font-size="11px"
                                                font-weight="400" fill="#a8a29e"
                                                class="apexcharts-text apexcharts-yaxis-label "
                                                style="font-family: Inter, ui-sans-serif;">
                                                <tspan id="SvgjsTspan1951">30</tspan>
                                                <title>30</title>
                                            </text><text id="SvgjsText1953" font-family="Inter, ui-sans-serif" x="20"
                                                y="100.5" text-anchor="start" dominant-baseline="auto" font-size="11px"
                                                font-weight="400" fill="#a8a29e"
                                                class="apexcharts-text apexcharts-yaxis-label "
                                                style="font-family: Inter, ui-sans-serif;">
                                                <tspan id="SvgjsTspan1954">24</tspan>
                                                <title>24</title>
                                            </text><text id="SvgjsText1956" font-family="Inter, ui-sans-serif" x="20"
                                                y="123.5" text-anchor="start" dominant-baseline="auto" font-size="11px"
                                                font-weight="400" fill="#a8a29e"
                                                class="apexcharts-text apexcharts-yaxis-label "
                                                style="font-family: Inter, ui-sans-serif;">
                                                <tspan id="SvgjsTspan1957">18</tspan>
                                                <title>18</title>
                                            </text><text id="SvgjsText1959" font-family="Inter, ui-sans-serif" x="20"
                                                y="146.5" text-anchor="start" dominant-baseline="auto" font-size="11px"
                                                font-weight="400" fill="#a8a29e"
                                                class="apexcharts-text apexcharts-yaxis-label "
                                                style="font-family: Inter, ui-sans-serif;">
                                                <tspan id="SvgjsTspan1960">12</tspan>
                                                <title>12</title>
                                            </text></g>
                                    </g>
                                    <g id="SvgjsG1899" class="apexcharts-inner apexcharts-graphical"
                                        transform="translate(40.71875, 30)">
                                        <defs id="SvgjsDefs1898">
                                            <clipPath id="gridRectMaskxziep4i2">
                                                <rect id="SvgjsRect1904" width="349.28125" height="131" x="-5" y="-8" rx="0"
                                                    ry="0" opacity="1" stroke-width="0" stroke="none" stroke-dasharray="0"
                                                    fill="#fff"></rect>
                                            </clipPath>
                                            <clipPath id="forecastMaskxziep4i2"></clipPath>
                                            <clipPath id="nonForecastMaskxziep4i2"></clipPath>
                                            <clipPath id="gridRectMarkerMaskxziep4i2">
                                                <rect id="SvgjsRect1905" width="346.28125" height="119" x="-2" y="-2" rx="0"
                                                    ry="0" opacity="1" stroke-width="0" stroke="none" stroke-dasharray="0"
                                                    fill="#fff"></rect>
                                            </clipPath>
                                        </defs>
                                        <line id="SvgjsLine1903" x1="0" y1="0" x2="0" y2="115" stroke="#b6b6b6"
                                            stroke-dasharray="3" stroke-linecap="butt" class="apexcharts-xcrosshairs" x="0"
                                            y="0" width="1" height="115" fill="#b1b9c4" filter="none" fill-opacity="0.9"
                                            stroke-width="1"></line>
                                        <g id="SvgjsG1915" class="apexcharts-grid">
                                            <g id="SvgjsG1916" class="apexcharts-gridlines-horizontal">
                                                <line id="SvgjsLine1920" x1="0" y1="23" x2="342.28125" y2="23"
                                                    stroke="#404040" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline"></line>
                                                <line id="SvgjsLine1921" x1="0" y1="46" x2="342.28125" y2="46"
                                                    stroke="#404040" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline"></line>
                                                <line id="SvgjsLine1922" x1="0" y1="69" x2="342.28125" y2="69"
                                                    stroke="#404040" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline"></line>
                                                <line id="SvgjsLine1923" x1="0" y1="92" x2="342.28125" y2="92"
                                                    stroke="#404040" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline"></line>
                                            </g>
                                            <g id="SvgjsG1917" class="apexcharts-gridlines-vertical"></g>
                                            <line id="SvgjsLine1926" x1="0" y1="115" x2="342.28125" y2="115"
                                                stroke="transparent" stroke-dasharray="0" stroke-linecap="butt"></line>
                                            <line id="SvgjsLine1925" x1="0" y1="1" x2="0" y2="115" stroke="transparent"
                                                stroke-dasharray="0" stroke-linecap="butt"></line>
                                        </g>
                                        <g id="SvgjsG1918" class="apexcharts-grid-borders">
                                            <line id="SvgjsLine1919" x1="0" y1="0" x2="342.28125" y2="0" stroke="#404040"
                                                stroke-dasharray="0" stroke-linecap="butt" class="apexcharts-gridline">
                                            </line>
                                            <line id="SvgjsLine1924" x1="0" y1="115" x2="342.28125" y2="115"
                                                stroke="#404040" stroke-dasharray="0" stroke-linecap="butt"
                                                class="apexcharts-gridline"></line>
                                        </g>
                                        <g id="SvgjsG1906" class="apexcharts-line-series apexcharts-plot-series">
                                            <g id="SvgjsG1907" class="apexcharts-series" zIndex="0" seriesName="Thisxmonth"
                                                data:longestSeries="true" rel="1" data:realIndex="0">
                                                <path id="SvgjsPath1910"
                                                    d="M 0 84.33333333333333C 13.3109375 84.33333333333333 24.7203125 84.33333333333333 38.03125 84.33333333333333C 51.3421875 84.33333333333333 62.7515625 69 76.0625 69C 89.3734375 69 100.7828125 103.5 114.09375 103.5C 127.4046875 103.5 138.8140625 46 152.125 46C 165.4359375 46 176.8453125 26.833333333333343 190.15625 26.833333333333343C 203.4671875 26.833333333333343 214.8765625 84.33333333333333 228.1875 84.33333333333333C 241.4984375 84.33333333333333 252.9078125 53.66666666666666 266.21875 53.66666666666666C 279.5296875 53.66666666666666 290.9390625 92 304.25 92C 317.5609375 92 328.9703125 99.66666666666666 342.28125 99.66666666666666"
                                                    fill="none" fill-opacity="1" stroke="rgba(34,197,94,0.85)"
                                                    stroke-opacity="1" stroke-linecap="butt" stroke-width="3"
                                                    stroke-dasharray="0" class="apexcharts-line" index="0"
                                                    clip-path="url(#gridRectMaskxziep4i2)"
                                                    pathTo="M 0 84.33333333333333C 13.3109375 84.33333333333333 24.7203125 84.33333333333333 38.03125 84.33333333333333C 51.3421875 84.33333333333333 62.7515625 69 76.0625 69C 89.3734375 69 100.7828125 103.5 114.09375 103.5C 127.4046875 103.5 138.8140625 46 152.125 46C 165.4359375 46 176.8453125 26.833333333333343 190.15625 26.833333333333343C 203.4671875 26.833333333333343 214.8765625 84.33333333333333 228.1875 84.33333333333333C 241.4984375 84.33333333333333 252.9078125 53.66666666666666 266.21875 53.66666666666666C 279.5296875 53.66666666666666 290.9390625 92 304.25 92C 317.5609375 92 328.9703125 99.66666666666666 342.28125 99.66666666666666"
                                                    pathFrom="M -1 161 L -1 161 L 38.03125 161 L 76.0625 161 L 114.09375 161 L 152.125 161 L 190.15625 161 L 228.1875 161 L 266.21875 161 L 304.25 161 L 342.28125 161"
                                                    fill-rule="evenodd"></path>
                                                <g id="SvgjsG1908"
                                                    class="apexcharts-series-markers-wrap apexcharts-hidden-element-shown"
                                                    data:realIndex="0">
                                                    <g class="apexcharts-series-markers">
                                                        <circle id="SvgjsCircle1964" r="0" cx="0" cy="0"
                                                            class="apexcharts-marker w0wfrueqs no-pointer-events"
                                                            stroke="#ffffff" fill="#22c55e" fill-opacity="1"
                                                            stroke-width="2" stroke-opacity="0.9" default-marker-size="0">
                                                        </circle>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="SvgjsG1911" class="apexcharts-series" zIndex="1" seriesName="Lastxmonth"
                                                data:longestSeries="true" rel="2" data:realIndex="1">
                                                <path id="SvgjsPath1914"
                                                    d="M 0 103.5C 13.3109375 103.5 24.7203125 72.83333333333333 38.03125 72.83333333333333C 51.3421875 72.83333333333333 62.7515625 92 76.0625 92C 89.3734375 92 100.7828125 84.33333333333333 114.09375 84.33333333333333C 127.4046875 84.33333333333333 138.8140625 23 152.125 23C 165.4359375 23 176.8453125 49.83333333333333 190.15625 49.83333333333333C 203.4671875 49.83333333333333 214.8765625 84.33333333333333 228.1875 84.33333333333333C 241.4984375 84.33333333333333 252.9078125 76.66666666666667 266.21875 76.66666666666667C 279.5296875 76.66666666666667 290.9390625 84.33333333333333 304.25 84.33333333333333C 317.5609375 84.33333333333333 328.9703125 76.66666666666667 342.28125 76.66666666666667"
                                                    fill="none" fill-opacity="1" stroke="rgba(115,115,115,0.85)"
                                                    stroke-opacity="1" stroke-linecap="butt" stroke-width="3"
                                                    stroke-dasharray="0" class="apexcharts-line" index="1"
                                                    clip-path="url(#gridRectMaskxziep4i2)"
                                                    pathTo="M 0 103.5C 13.3109375 103.5 24.7203125 72.83333333333333 38.03125 72.83333333333333C 51.3421875 72.83333333333333 62.7515625 92 76.0625 92C 89.3734375 92 100.7828125 84.33333333333333 114.09375 84.33333333333333C 127.4046875 84.33333333333333 138.8140625 23 152.125 23C 165.4359375 23 176.8453125 49.83333333333333 190.15625 49.83333333333333C 203.4671875 49.83333333333333 214.8765625 84.33333333333333 228.1875 84.33333333333333C 241.4984375 84.33333333333333 252.9078125 76.66666666666667 266.21875 76.66666666666667C 279.5296875 76.66666666666667 290.9390625 84.33333333333333 304.25 84.33333333333333C 317.5609375 84.33333333333333 328.9703125 76.66666666666667 342.28125 76.66666666666667"
                                                    pathFrom="M -1 161 L -1 161 L 38.03125 161 L 76.0625 161 L 114.09375 161 L 152.125 161 L 190.15625 161 L 228.1875 161 L 266.21875 161 L 304.25 161 L 342.28125 161"
                                                    fill-rule="evenodd"></path>
                                                <g id="SvgjsG1912"
                                                    class="apexcharts-series-markers-wrap apexcharts-hidden-element-shown"
                                                    data:realIndex="1">
                                                    <g class="apexcharts-series-markers">
                                                        <circle id="SvgjsCircle1965" r="0" cx="0" cy="0"
                                                            class="apexcharts-marker wn1pptnjq no-pointer-events"
                                                            stroke="#ffffff" fill="#737373" fill-opacity="1"
                                                            stroke-width="2" stroke-opacity="0.9" default-marker-size="0">
                                                        </circle>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="SvgjsG1909" class="apexcharts-datalabels" data:realIndex="0"></g>
                                            <g id="SvgjsG1913" class="apexcharts-datalabels" data:realIndex="1"></g>
                                        </g>
                                        <line id="SvgjsLine1927" x1="0" y1="0" x2="342.28125" y2="0" stroke="#b6b6b6"
                                            stroke-dasharray="0" stroke-width="1" stroke-linecap="butt"
                                            class="apexcharts-ycrosshairs"></line>
                                        <line id="SvgjsLine1928" x1="0" y1="0" x2="342.28125" y2="0" stroke-dasharray="0"
                                            stroke-width="0" stroke-linecap="butt" class="apexcharts-ycrosshairs-hidden">
                                        </line>
                                        <g id="SvgjsG1929" class="apexcharts-xaxis" transform="translate(0, 0)">
                                            <g id="SvgjsG1930" class="apexcharts-xaxis-texts-g"
                                                transform="translate(0, -4)"></g>
                                        </g>
                                        <g id="SvgjsG1961" class="apexcharts-yaxis-annotations"></g>
                                        <g id="SvgjsG1962" class="apexcharts-xaxis-annotations"></g>
                                        <g id="SvgjsG1963" class="apexcharts-point-annotations"></g>
                                    </g>
                                </svg>
                                <div class="apexcharts-tooltip apexcharts-theme-light">
                                    <div class="apexcharts-tooltip-title"
                                        style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;"></div>
                                    <div class="apexcharts-tooltip-series-group" style="order: 1;"><span
                                            class="apexcharts-tooltip-marker"
                                            style="background-color: rgb(34, 197, 94);"></span>
                                        <div class="apexcharts-tooltip-text"
                                            style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                            <div class="apexcharts-tooltip-y-group"><span
                                                    class="apexcharts-tooltip-text-y-label"></span><span
                                                    class="apexcharts-tooltip-text-y-value"></span></div>
                                            <div class="apexcharts-tooltip-goals-group"><span
                                                    class="apexcharts-tooltip-text-goals-label"></span><span
                                                    class="apexcharts-tooltip-text-goals-value"></span></div>
                                            <div class="apexcharts-tooltip-z-group"><span
                                                    class="apexcharts-tooltip-text-z-label"></span><span
                                                    class="apexcharts-tooltip-text-z-value"></span></div>
                                        </div>
                                    </div>
                                    <div class="apexcharts-tooltip-series-group" style="order: 2;"><span
                                            class="apexcharts-tooltip-marker"
                                            style="background-color: rgb(115, 115, 115);"></span>
                                        <div class="apexcharts-tooltip-text"
                                            style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                            <div class="apexcharts-tooltip-y-group"><span
                                                    class="apexcharts-tooltip-text-y-label"></span><span
                                                    class="apexcharts-tooltip-text-y-value"></span></div>
                                            <div class="apexcharts-tooltip-goals-group"><span
                                                    class="apexcharts-tooltip-text-goals-label"></span><span
                                                    class="apexcharts-tooltip-text-goals-value"></span></div>
                                            <div class="apexcharts-tooltip-z-group"><span
                                                    class="apexcharts-tooltip-text-z-label"></span><span
                                                    class="apexcharts-tooltip-text-z-value"></span></div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="apexcharts-yaxistooltip apexcharts-yaxistooltip-0 apexcharts-yaxistooltip-left apexcharts-theme-light">
                                    <div class="apexcharts-yaxistooltip-text"></div>
                                </div>
                            </div>
                        </div>

                        <!-- Legend Indicator -->
                        <div class="flex pdrgo items-center xrsjp i3acl">
                            <div class="pqrvw items-center">
                                <span class="w1ile vzn8i uiqm5 k6q5b ifon2 nhufc"></span>
                                <span class="ldcb4 v474b dark:text-neutral-400">
                                    This month
                                </span>
                            </div>
                            <div class="pqrvw items-center">
                                <span class="w1ile vzn8i uiqm5 g8qb9 ifon2 nhufc dark:bg-neutral-700"></span>
                                <span class="ldcb4 v474b dark:text-neutral-400">
                                    Last month
                                </span>
                            </div>
                        </div>
                        <!-- End Legend Indicator -->
                    </div>
                    <!-- End Double Line Chart in Card -->

                    <!-- Double Line Chart in Card -->
                    <div class="pqx67 flex lgqw8 f0dty l66z3 ffqht tgfrq k3u76 dark:bg-neutral-800 dark:border-neutral-700">
                        <h2 class="uiqm5 kxhcs j4ei7 dark:text-neutral-200">
                            Total orders
                        </h2>

                        <!-- Subheader -->
                        <div class="jjege dr94e items-center pw4gn okw5h q7u6r">
                            <div>
                                <h4 class="hbd69 j4ei7 dark:text-neutral-200">
                                    55,935
                                    <span class="pqrvw items-center azej2 dtjcu jnh9x">
                                        <svg class="wlxy3 sfo8l qcpnt" width="16" height="16" viewBox="0 0 16 16"
                                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M9.39508 3.55649C9.39508 3.4089 9.45413 3.26735 9.55924 3.16299C9.66436 3.05863 9.80692 3 9.95557 3H14.4395C14.5882 3 14.7307 3.05863 14.8358 3.16299C14.9409 3.26735 15 3.4089 15 3.55649V8.00839C15 8.15598 14.9409 8.29753 14.8358 8.40189C14.7307 8.50625 14.5882 8.56488 14.4395 8.56488C14.2909 8.56488 14.1483 8.50625 14.0432 8.40189C13.9381 8.29753 13.879 8.15598 13.879 8.00839V5.11465L9.8289 10.0307C9.77932 10.0908 9.71762 10.1399 9.64777 10.1749C9.57792 10.2098 9.50146 10.2299 9.42333 10.2338C9.34519 10.2377 9.2671 10.2253 9.19409 10.1974C9.12107 10.1695 9.05474 10.1267 8.99937 10.0719L6.09939 7.19258L2.00107 12.7875C1.91141 12.9007 1.78106 12.975 1.6374 12.9947C1.49374 13.0145 1.34796 12.9782 1.2307 12.8935C1.11343 12.8087 1.03381 12.6822 1.00856 12.5404C0.983313 12.3986 1.01441 12.2526 1.09531 12.1331L5.57925 6.01171C5.62684 5.94662 5.68808 5.89254 5.75875 5.85321C5.82942 5.81388 5.90784 5.79022 5.9886 5.78388C6.06936 5.77753 6.15055 5.78864 6.22657 5.81645C6.30259 5.84425 6.37164 5.88809 6.42895 5.94493L9.3536 8.8498L13.2569 4.11298H9.95557C9.80692 4.11298 9.66436 4.05435 9.55924 3.94998C9.45413 3.84562 9.39508 3.70408 9.39508 3.55649Z">
                                            </path>
                                        </svg>
                                        4.7%
                                    </span>
                                </h4>
                            </div>

                            <div class="w63ac">
                                <p class="dtjcu fj6be dark:text-neutral-400">
                                    78,935 orders
                                </p>
                            </div>
                        </div>
                        <!-- End Subheader -->

                        <!-- Apex Line Chart -->
                        <div id="hs-total-orders-line-chart" class="ul66i bep17" style="min-height: 165px;">
                            <div id="apexchartszb1r1qgq" class="apexcharts-canvas apexchartszb1r1qgq apexcharts-theme-light"
                                style="width: 383px; height: 150px;"><svg id="SvgjsSvg1968" width="383" height="150"
                                    xmlns="http://www.w3.org/2000/svg" version="1.1"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev"
                                    class="apexcharts-svg" xmlns:data="ApexChartsNS" transform="translate(0, 0)"
                                    style="background: transparent;">
                                    <foreignObject x="0" y="0" width="383" height="150">
                                        <div class="apexcharts-legend" xmlns="http://www.w3.org/1999/xhtml"
                                            style="max-height: 75px;"></div>
                                    </foreignObject>
                                    <rect id="SvgjsRect1973" width="0" height="0" x="0" y="0" rx="0" ry="0" opacity="1"
                                        stroke-width="0" stroke="none" stroke-dasharray="0" fill="#fefefe"></rect>
                                    <g id="SvgjsG2012" class="apexcharts-yaxis" rel="0" transform="translate(10.71875, 0)">
                                        <g id="SvgjsG2013" class="apexcharts-yaxis-texts-g"
                                            transform="translate(-13.875, 0)"><text id="SvgjsText2015"
                                                font-family="Inter, ui-sans-serif" x="20" y="31.5" text-anchor="start"
                                                dominant-baseline="auto" font-size="11px" font-weight="400" fill="#a8a29e"
                                                class="apexcharts-text apexcharts-yaxis-label "
                                                style="font-family: Inter, ui-sans-serif;">
                                                <tspan id="SvgjsTspan2016">42</tspan>
                                                <title>42</title>
                                            </text><text id="SvgjsText2018" font-family="Inter, ui-sans-serif" x="20"
                                                y="54.5" text-anchor="start" dominant-baseline="auto" font-size="11px"
                                                font-weight="400" fill="#a8a29e"
                                                class="apexcharts-text apexcharts-yaxis-label "
                                                style="font-family: Inter, ui-sans-serif;">
                                                <tspan id="SvgjsTspan2019">36</tspan>
                                                <title>36</title>
                                            </text><text id="SvgjsText2021" font-family="Inter, ui-sans-serif" x="20"
                                                y="77.5" text-anchor="start" dominant-baseline="auto" font-size="11px"
                                                font-weight="400" fill="#a8a29e"
                                                class="apexcharts-text apexcharts-yaxis-label "
                                                style="font-family: Inter, ui-sans-serif;">
                                                <tspan id="SvgjsTspan2022">30</tspan>
                                                <title>30</title>
                                            </text><text id="SvgjsText2024" font-family="Inter, ui-sans-serif" x="20"
                                                y="100.5" text-anchor="start" dominant-baseline="auto" font-size="11px"
                                                font-weight="400" fill="#a8a29e"
                                                class="apexcharts-text apexcharts-yaxis-label "
                                                style="font-family: Inter, ui-sans-serif;">
                                                <tspan id="SvgjsTspan2025">24</tspan>
                                                <title>24</title>
                                            </text><text id="SvgjsText2027" font-family="Inter, ui-sans-serif" x="20"
                                                y="123.5" text-anchor="start" dominant-baseline="auto" font-size="11px"
                                                font-weight="400" fill="#a8a29e"
                                                class="apexcharts-text apexcharts-yaxis-label "
                                                style="font-family: Inter, ui-sans-serif;">
                                                <tspan id="SvgjsTspan2028">18</tspan>
                                                <title>18</title>
                                            </text><text id="SvgjsText2030" font-family="Inter, ui-sans-serif" x="20"
                                                y="146.5" text-anchor="start" dominant-baseline="auto" font-size="11px"
                                                font-weight="400" fill="#a8a29e"
                                                class="apexcharts-text apexcharts-yaxis-label "
                                                style="font-family: Inter, ui-sans-serif;">
                                                <tspan id="SvgjsTspan2031">12</tspan>
                                                <title>12</title>
                                            </text></g>
                                    </g>
                                    <g id="SvgjsG1970" class="apexcharts-inner apexcharts-graphical"
                                        transform="translate(40.71875, 30)">
                                        <defs id="SvgjsDefs1969">
                                            <clipPath id="gridRectMaskzb1r1qgq">
                                                <rect id="SvgjsRect1975" width="349.28125" height="131" x="-5" y="-8" rx="0"
                                                    ry="0" opacity="1" stroke-width="0" stroke="none" stroke-dasharray="0"
                                                    fill="#fff"></rect>
                                            </clipPath>
                                            <clipPath id="forecastMaskzb1r1qgq"></clipPath>
                                            <clipPath id="nonForecastMaskzb1r1qgq"></clipPath>
                                            <clipPath id="gridRectMarkerMaskzb1r1qgq">
                                                <rect id="SvgjsRect1976" width="346.28125" height="119" x="-2" y="-2" rx="0"
                                                    ry="0" opacity="1" stroke-width="0" stroke="none" stroke-dasharray="0"
                                                    fill="#fff"></rect>
                                            </clipPath>
                                        </defs>
                                        <line id="SvgjsLine1974" x1="0" y1="0" x2="0" y2="115" stroke="#b6b6b6"
                                            stroke-dasharray="3" stroke-linecap="butt" class="apexcharts-xcrosshairs" x="0"
                                            y="0" width="1" height="115" fill="#b1b9c4" filter="none" fill-opacity="0.9"
                                            stroke-width="1"></line>
                                        <g id="SvgjsG1986" class="apexcharts-grid">
                                            <g id="SvgjsG1987" class="apexcharts-gridlines-horizontal">
                                                <line id="SvgjsLine1991" x1="0" y1="23" x2="342.28125" y2="23"
                                                    stroke="#404040" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline"></line>
                                                <line id="SvgjsLine1992" x1="0" y1="46" x2="342.28125" y2="46"
                                                    stroke="#404040" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline"></line>
                                                <line id="SvgjsLine1993" x1="0" y1="69" x2="342.28125" y2="69"
                                                    stroke="#404040" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline"></line>
                                                <line id="SvgjsLine1994" x1="0" y1="92" x2="342.28125" y2="92"
                                                    stroke="#404040" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline"></line>
                                            </g>
                                            <g id="SvgjsG1988" class="apexcharts-gridlines-vertical"></g>
                                            <line id="SvgjsLine1997" x1="0" y1="115" x2="342.28125" y2="115"
                                                stroke="transparent" stroke-dasharray="0" stroke-linecap="butt"></line>
                                            <line id="SvgjsLine1996" x1="0" y1="1" x2="0" y2="115" stroke="transparent"
                                                stroke-dasharray="0" stroke-linecap="butt"></line>
                                        </g>
                                        <g id="SvgjsG1989" class="apexcharts-grid-borders">
                                            <line id="SvgjsLine1990" x1="0" y1="0" x2="342.28125" y2="0" stroke="#404040"
                                                stroke-dasharray="0" stroke-linecap="butt" class="apexcharts-gridline">
                                            </line>
                                            <line id="SvgjsLine1995" x1="0" y1="115" x2="342.28125" y2="115"
                                                stroke="#404040" stroke-dasharray="0" stroke-linecap="butt"
                                                class="apexcharts-gridline"></line>
                                        </g>
                                        <g id="SvgjsG1977" class="apexcharts-line-series apexcharts-plot-series">
                                            <g id="SvgjsG1978" class="apexcharts-series" zIndex="0" seriesName="Thisxmonth"
                                                data:longestSeries="true" rel="1" data:realIndex="0">
                                                <path id="SvgjsPath1981"
                                                    d="M 0 103.5C 13.3109375 103.5 24.7203125 61.33333333333333 38.03125 61.33333333333333C 51.3421875 61.33333333333333 62.7515625 49.83333333333333 76.0625 49.83333333333333C 89.3734375 49.83333333333333 100.7828125 84.33333333333333 114.09375 84.33333333333333C 127.4046875 84.33333333333333 138.8140625 72.83333333333333 152.125 72.83333333333333C 165.4359375 72.83333333333333 176.8453125 15.333333333333343 190.15625 15.333333333333343C 203.4671875 15.333333333333343 214.8765625 84.33333333333333 228.1875 84.33333333333333C 241.4984375 84.33333333333333 252.9078125 46 266.21875 46C 279.5296875 46 290.9390625 84.33333333333333 304.25 84.33333333333333C 317.5609375 84.33333333333333 328.9703125 76.66666666666667 342.28125 76.66666666666667"
                                                    fill="none" fill-opacity="1" stroke="rgba(34,197,94,0.85)"
                                                    stroke-opacity="1" stroke-linecap="butt" stroke-width="3"
                                                    stroke-dasharray="0" class="apexcharts-line" index="0"
                                                    clip-path="url(#gridRectMaskzb1r1qgq)"
                                                    pathTo="M 0 103.5C 13.3109375 103.5 24.7203125 61.33333333333333 38.03125 61.33333333333333C 51.3421875 61.33333333333333 62.7515625 49.83333333333333 76.0625 49.83333333333333C 89.3734375 49.83333333333333 100.7828125 84.33333333333333 114.09375 84.33333333333333C 127.4046875 84.33333333333333 138.8140625 72.83333333333333 152.125 72.83333333333333C 165.4359375 72.83333333333333 176.8453125 15.333333333333343 190.15625 15.333333333333343C 203.4671875 15.333333333333343 214.8765625 84.33333333333333 228.1875 84.33333333333333C 241.4984375 84.33333333333333 252.9078125 46 266.21875 46C 279.5296875 46 290.9390625 84.33333333333333 304.25 84.33333333333333C 317.5609375 84.33333333333333 328.9703125 76.66666666666667 342.28125 76.66666666666667"
                                                    pathFrom="M -1 161 L -1 161 L 38.03125 161 L 76.0625 161 L 114.09375 161 L 152.125 161 L 190.15625 161 L 228.1875 161 L 266.21875 161 L 304.25 161 L 342.28125 161"
                                                    fill-rule="evenodd"></path>
                                                <g id="SvgjsG1979"
                                                    class="apexcharts-series-markers-wrap apexcharts-hidden-element-shown"
                                                    data:realIndex="0">
                                                    <g class="apexcharts-series-markers">
                                                        <circle id="SvgjsCircle2035" r="0" cx="0" cy="0"
                                                            class="apexcharts-marker ww30c7qcs no-pointer-events"
                                                            stroke="#ffffff" fill="#22c55e" fill-opacity="1"
                                                            stroke-width="2" stroke-opacity="0.9" default-marker-size="0">
                                                        </circle>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="SvgjsG1982" class="apexcharts-series" zIndex="1" seriesName="Lastxmonth"
                                                data:longestSeries="true" rel="2" data:realIndex="1">
                                                <path id="SvgjsPath1985"
                                                    d="M 0 84.33333333333333C 13.3109375 84.33333333333333 24.7203125 84.33333333333333 38.03125 84.33333333333333C 51.3421875 84.33333333333333 62.7515625 103.5 76.0625 103.5C 89.3734375 103.5 100.7828125 92 114.09375 92C 127.4046875 92 138.8140625 84.33333333333333 152.125 84.33333333333333C 165.4359375 84.33333333333333 176.8453125 69 190.15625 69C 203.4671875 69 214.8765625 26.833333333333343 228.1875 26.833333333333343C 241.4984375 26.833333333333343 252.9078125 84.33333333333333 266.21875 84.33333333333333C 279.5296875 84.33333333333333 290.9390625 26.833333333333343 304.25 26.833333333333343C 317.5609375 26.833333333333343 328.9703125 76.66666666666667 342.28125 76.66666666666667"
                                                    fill="none" fill-opacity="1" stroke="rgba(115,115,115,0.85)"
                                                    stroke-opacity="1" stroke-linecap="butt" stroke-width="3"
                                                    stroke-dasharray="0" class="apexcharts-line" index="1"
                                                    clip-path="url(#gridRectMaskzb1r1qgq)"
                                                    pathTo="M 0 84.33333333333333C 13.3109375 84.33333333333333 24.7203125 84.33333333333333 38.03125 84.33333333333333C 51.3421875 84.33333333333333 62.7515625 103.5 76.0625 103.5C 89.3734375 103.5 100.7828125 92 114.09375 92C 127.4046875 92 138.8140625 84.33333333333333 152.125 84.33333333333333C 165.4359375 84.33333333333333 176.8453125 69 190.15625 69C 203.4671875 69 214.8765625 26.833333333333343 228.1875 26.833333333333343C 241.4984375 26.833333333333343 252.9078125 84.33333333333333 266.21875 84.33333333333333C 279.5296875 84.33333333333333 290.9390625 26.833333333333343 304.25 26.833333333333343C 317.5609375 26.833333333333343 328.9703125 76.66666666666667 342.28125 76.66666666666667"
                                                    pathFrom="M -1 161 L -1 161 L 38.03125 161 L 76.0625 161 L 114.09375 161 L 152.125 161 L 190.15625 161 L 228.1875 161 L 266.21875 161 L 304.25 161 L 342.28125 161"
                                                    fill-rule="evenodd"></path>
                                                <g id="SvgjsG1983"
                                                    class="apexcharts-series-markers-wrap apexcharts-hidden-element-shown"
                                                    data:realIndex="1">
                                                    <g class="apexcharts-series-markers">
                                                        <circle id="SvgjsCircle2036" r="0" cx="0" cy="0"
                                                            class="apexcharts-marker wdcyjvzu6 no-pointer-events"
                                                            stroke="#ffffff" fill="#737373" fill-opacity="1"
                                                            stroke-width="2" stroke-opacity="0.9" default-marker-size="0">
                                                        </circle>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="SvgjsG1980" class="apexcharts-datalabels" data:realIndex="0"></g>
                                            <g id="SvgjsG1984" class="apexcharts-datalabels" data:realIndex="1"></g>
                                        </g>
                                        <line id="SvgjsLine1998" x1="0" y1="0" x2="342.28125" y2="0" stroke="#b6b6b6"
                                            stroke-dasharray="0" stroke-width="1" stroke-linecap="butt"
                                            class="apexcharts-ycrosshairs"></line>
                                        <line id="SvgjsLine1999" x1="0" y1="0" x2="342.28125" y2="0" stroke-dasharray="0"
                                            stroke-width="0" stroke-linecap="butt" class="apexcharts-ycrosshairs-hidden">
                                        </line>
                                        <g id="SvgjsG2000" class="apexcharts-xaxis" transform="translate(0, 0)">
                                            <g id="SvgjsG2001" class="apexcharts-xaxis-texts-g"
                                                transform="translate(0, -4)"></g>
                                        </g>
                                        <g id="SvgjsG2032" class="apexcharts-yaxis-annotations"></g>
                                        <g id="SvgjsG2033" class="apexcharts-xaxis-annotations"></g>
                                        <g id="SvgjsG2034" class="apexcharts-point-annotations"></g>
                                    </g>
                                </svg>
                                <div class="apexcharts-tooltip apexcharts-theme-light">
                                    <div class="apexcharts-tooltip-title"
                                        style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;"></div>
                                    <div class="apexcharts-tooltip-series-group" style="order: 1;"><span
                                            class="apexcharts-tooltip-marker"
                                            style="background-color: rgb(34, 197, 94);"></span>
                                        <div class="apexcharts-tooltip-text"
                                            style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                            <div class="apexcharts-tooltip-y-group"><span
                                                    class="apexcharts-tooltip-text-y-label"></span><span
                                                    class="apexcharts-tooltip-text-y-value"></span></div>
                                            <div class="apexcharts-tooltip-goals-group"><span
                                                    class="apexcharts-tooltip-text-goals-label"></span><span
                                                    class="apexcharts-tooltip-text-goals-value"></span></div>
                                            <div class="apexcharts-tooltip-z-group"><span
                                                    class="apexcharts-tooltip-text-z-label"></span><span
                                                    class="apexcharts-tooltip-text-z-value"></span></div>
                                        </div>
                                    </div>
                                    <div class="apexcharts-tooltip-series-group" style="order: 2;"><span
                                            class="apexcharts-tooltip-marker"
                                            style="background-color: rgb(115, 115, 115);"></span>
                                        <div class="apexcharts-tooltip-text"
                                            style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                            <div class="apexcharts-tooltip-y-group"><span
                                                    class="apexcharts-tooltip-text-y-label"></span><span
                                                    class="apexcharts-tooltip-text-y-value"></span></div>
                                            <div class="apexcharts-tooltip-goals-group"><span
                                                    class="apexcharts-tooltip-text-goals-label"></span><span
                                                    class="apexcharts-tooltip-text-goals-value"></span></div>
                                            <div class="apexcharts-tooltip-z-group"><span
                                                    class="apexcharts-tooltip-text-z-label"></span><span
                                                    class="apexcharts-tooltip-text-z-value"></span></div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="apexcharts-yaxistooltip apexcharts-yaxistooltip-0 apexcharts-yaxistooltip-left apexcharts-theme-light">
                                    <div class="apexcharts-yaxistooltip-text"></div>
                                </div>
                            </div>
                        </div>

                        <!-- Legend Indicator -->
                        <div class="flex pdrgo items-center xrsjp i3acl">
                            <div class="pqrvw items-center">
                                <span class="w1ile vzn8i uiqm5 k6q5b ifon2 nhufc"></span>
                                <span class="ldcb4 v474b dark:text-neutral-400">
                                    This month
                                </span>
                            </div>
                            <div class="pqrvw items-center">
                                <span class="w1ile vzn8i uiqm5 g8qb9 ifon2 nhufc dark:bg-neutral-700"></span>
                                <span class="ldcb4 v474b dark:text-neutral-400">
                                    Last month
                                </span>
                            </div>
                        </div>
                        <!-- End Legend Indicator -->
                    </div>
                    <!-- End Double Line Chart in Card -->

                    <!-- Double Line Chart in Card -->
                    <div class="pqx67 flex lgqw8 f0dty l66z3 ffqht tgfrq k3u76 dark:bg-neutral-800 dark:border-neutral-700">
                        <h2 class="uiqm5 kxhcs j4ei7 dark:text-neutral-200">
                            Refunded
                        </h2>

                        <!-- Subheader -->
                        <div class="jjege dr94e items-center pw4gn okw5h q7u6r">
                            <div>
                                <h4 class="hbd69 j4ei7 dark:text-neutral-200">
                                    52,441
                                    <span class="pqrvw items-center azej2 dtjcu jnh9x">
                                        <svg class="wlxy3 sfo8l qcpnt" width="16" height="16" viewBox="0 0 16 16"
                                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M9.39508 3.55649C9.39508 3.4089 9.45413 3.26735 9.55924 3.16299C9.66436 3.05863 9.80692 3 9.95557 3H14.4395C14.5882 3 14.7307 3.05863 14.8358 3.16299C14.9409 3.26735 15 3.4089 15 3.55649V8.00839C15 8.15598 14.9409 8.29753 14.8358 8.40189C14.7307 8.50625 14.5882 8.56488 14.4395 8.56488C14.2909 8.56488 14.1483 8.50625 14.0432 8.40189C13.9381 8.29753 13.879 8.15598 13.879 8.00839V5.11465L9.8289 10.0307C9.77932 10.0908 9.71762 10.1399 9.64777 10.1749C9.57792 10.2098 9.50146 10.2299 9.42333 10.2338C9.34519 10.2377 9.2671 10.2253 9.19409 10.1974C9.12107 10.1695 9.05474 10.1267 8.99937 10.0719L6.09939 7.19258L2.00107 12.7875C1.91141 12.9007 1.78106 12.975 1.6374 12.9947C1.49374 13.0145 1.34796 12.9782 1.2307 12.8935C1.11343 12.8087 1.03381 12.6822 1.00856 12.5404C0.983313 12.3986 1.01441 12.2526 1.09531 12.1331L5.57925 6.01171C5.62684 5.94662 5.68808 5.89254 5.75875 5.85321C5.82942 5.81388 5.90784 5.79022 5.9886 5.78388C6.06936 5.77753 6.15055 5.78864 6.22657 5.81645C6.30259 5.84425 6.37164 5.88809 6.42895 5.94493L9.3536 8.8498L13.2569 4.11298H9.95557C9.80692 4.11298 9.66436 4.05435 9.55924 3.94998C9.45413 3.84562 9.39508 3.70408 9.39508 3.55649Z">
                                            </path>
                                        </svg>
                                        11%
                                    </span>
                                </h4>
                            </div>

                            <div class="w63ac">
                                <p class="dtjcu fj6be dark:text-neutral-400">
                                    2,289 refunds
                                </p>
                            </div>
                        </div>
                        <!-- End Subheader -->

                        <!-- Apex Line Chart -->
                        <div id="hs-total-refunded-line-chart" class="ul66i bep17" style="min-height: 165px;">
                            <div id="apexchartsr1h0lvfuj"
                                class="apexcharts-canvas apexchartsr1h0lvfuj apexcharts-theme-light"
                                style="width: 383px; height: 150px;"><svg id="SvgjsSvg2039" width="383" height="150"
                                    xmlns="http://www.w3.org/2000/svg" version="1.1"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev"
                                    class="apexcharts-svg" xmlns:data="ApexChartsNS" transform="translate(0, 0)"
                                    style="background: transparent;">
                                    <foreignObject x="0" y="0" width="383" height="150">
                                        <div class="apexcharts-legend" xmlns="http://www.w3.org/1999/xhtml"
                                            style="max-height: 75px;"></div>
                                    </foreignObject>
                                    <rect id="SvgjsRect2044" width="0" height="0" x="0" y="0" rx="0" ry="0" opacity="1"
                                        stroke-width="0" stroke="none" stroke-dasharray="0" fill="#fefefe"></rect>
                                    <g id="SvgjsG2082" class="apexcharts-yaxis" rel="0" transform="translate(10.4375, 0)">
                                        <g id="SvgjsG2083" class="apexcharts-yaxis-texts-g"
                                            transform="translate(-13.4375, 0)"><text id="SvgjsText2085"
                                                font-family="Inter, ui-sans-serif" x="20" y="31.4" text-anchor="start"
                                                dominant-baseline="auto" font-size="11px" font-weight="400" fill="#a8a29e"
                                                class="apexcharts-text apexcharts-yaxis-label "
                                                style="font-family: Inter, ui-sans-serif;">
                                                <tspan id="SvgjsTspan2086">28</tspan>
                                                <title>28</title>
                                            </text><text id="SvgjsText2088" font-family="Inter, ui-sans-serif" x="20"
                                                y="60.15" text-anchor="start" dominant-baseline="auto" font-size="11px"
                                                font-weight="400" fill="#a8a29e"
                                                class="apexcharts-text apexcharts-yaxis-label "
                                                style="font-family: Inter, ui-sans-serif;">
                                                <tspan id="SvgjsTspan2089">21</tspan>
                                                <title>21</title>
                                            </text><text id="SvgjsText2091" font-family="Inter, ui-sans-serif" x="20"
                                                y="88.9" text-anchor="start" dominant-baseline="auto" font-size="11px"
                                                font-weight="400" fill="#a8a29e"
                                                class="apexcharts-text apexcharts-yaxis-label "
                                                style="font-family: Inter, ui-sans-serif;">
                                                <tspan id="SvgjsTspan2092">14</tspan>
                                                <title>14</title>
                                            </text><text id="SvgjsText2094" font-family="Inter, ui-sans-serif" x="20"
                                                y="117.65" text-anchor="start" dominant-baseline="auto" font-size="11px"
                                                font-weight="400" fill="#a8a29e"
                                                class="apexcharts-text apexcharts-yaxis-label "
                                                style="font-family: Inter, ui-sans-serif;">
                                                <tspan id="SvgjsTspan2095">7</tspan>
                                                <title>7</title>
                                            </text><text id="SvgjsText2097" font-family="Inter, ui-sans-serif" x="20"
                                                y="146.4" text-anchor="start" dominant-baseline="auto" font-size="11px"
                                                font-weight="400" fill="#a8a29e"
                                                class="apexcharts-text apexcharts-yaxis-label "
                                                style="font-family: Inter, ui-sans-serif;">
                                                <tspan id="SvgjsTspan2098">0</tspan>
                                                <title>0</title>
                                            </text></g>
                                    </g>
                                    <g id="SvgjsG2041" class="apexcharts-inner apexcharts-graphical"
                                        transform="translate(40.4375, 30)">
                                        <defs id="SvgjsDefs2040">
                                            <clipPath id="gridRectMaskr1h0lvfuj">
                                                <rect id="SvgjsRect2046" width="349.5625" height="131" x="-5" y="-8" rx="0"
                                                    ry="0" opacity="1" stroke-width="0" stroke="none" stroke-dasharray="0"
                                                    fill="#fff"></rect>
                                            </clipPath>
                                            <clipPath id="forecastMaskr1h0lvfuj"></clipPath>
                                            <clipPath id="nonForecastMaskr1h0lvfuj"></clipPath>
                                            <clipPath id="gridRectMarkerMaskr1h0lvfuj">
                                                <rect id="SvgjsRect2047" width="346.5625" height="119" x="-2" y="-2" rx="0"
                                                    ry="0" opacity="1" stroke-width="0" stroke="none" stroke-dasharray="0"
                                                    fill="#fff"></rect>
                                            </clipPath>
                                        </defs>
                                        <line id="SvgjsLine2045" x1="0" y1="0" x2="0" y2="115" stroke="#b6b6b6"
                                            stroke-dasharray="3" stroke-linecap="butt" class="apexcharts-xcrosshairs" x="0"
                                            y="0" width="1" height="115" fill="#b1b9c4" filter="none" fill-opacity="0.9"
                                            stroke-width="1"></line>
                                        <g id="SvgjsG2057" class="apexcharts-grid">
                                            <g id="SvgjsG2058" class="apexcharts-gridlines-horizontal">
                                                <line id="SvgjsLine2062" x1="0" y1="28.75" x2="342.5625" y2="28.75"
                                                    stroke="#404040" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline"></line>
                                                <line id="SvgjsLine2063" x1="0" y1="57.5" x2="342.5625" y2="57.5"
                                                    stroke="#404040" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline"></line>
                                                <line id="SvgjsLine2064" x1="0" y1="86.25" x2="342.5625" y2="86.25"
                                                    stroke="#404040" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline"></line>
                                            </g>
                                            <g id="SvgjsG2059" class="apexcharts-gridlines-vertical"></g>
                                            <line id="SvgjsLine2067" x1="0" y1="115" x2="342.5625" y2="115"
                                                stroke="transparent" stroke-dasharray="0" stroke-linecap="butt"></line>
                                            <line id="SvgjsLine2066" x1="0" y1="1" x2="0" y2="115" stroke="transparent"
                                                stroke-dasharray="0" stroke-linecap="butt"></line>
                                        </g>
                                        <g id="SvgjsG2060" class="apexcharts-grid-borders">
                                            <line id="SvgjsLine2061" x1="0" y1="0" x2="342.5625" y2="0" stroke="#404040"
                                                stroke-dasharray="0" stroke-linecap="butt" class="apexcharts-gridline">
                                            </line>
                                            <line id="SvgjsLine2065" x1="0" y1="115" x2="342.5625" y2="115" stroke="#404040"
                                                stroke-dasharray="0" stroke-linecap="butt" class="apexcharts-gridline">
                                            </line>
                                        </g>
                                        <g id="SvgjsG2048" class="apexcharts-line-series apexcharts-plot-series">
                                            <g id="SvgjsG2049" class="apexcharts-series" zIndex="0" seriesName="Thisxmonth"
                                                data:longestSeries="true" rel="1" data:realIndex="0">
                                                <path id="SvgjsPath2052"
                                                    d="M 0 115C 13.321874999999999 115 24.740625 32.85714285714286 38.0625 32.85714285714286C 51.384375 32.85714285714286 62.803125 24.642857142857153 76.125 24.642857142857153C 89.446875 24.642857142857153 100.865625 53.392857142857146 114.1875 53.392857142857146C 127.509375 53.392857142857146 138.928125 32.85714285714286 152.25 32.85714285714286C 165.571875 32.85714285714286 176.990625 53.392857142857146 190.3125 53.392857142857146C 203.634375 53.392857142857146 215.053125 32.85714285714286 228.375 32.85714285714286C 241.696875 32.85714285714286 253.115625 36.96428571428572 266.4375 36.96428571428572C 279.759375 36.96428571428572 291.178125 57.5 304.5 57.5C 317.821875 57.5 329.240625 53.392857142857146 342.5625 53.392857142857146"
                                                    fill="none" fill-opacity="1" stroke="rgba(34,197,94,0.85)"
                                                    stroke-opacity="1" stroke-linecap="butt" stroke-width="3"
                                                    stroke-dasharray="0" class="apexcharts-line" index="0"
                                                    clip-path="url(#gridRectMaskr1h0lvfuj)"
                                                    pathTo="M 0 115C 13.321874999999999 115 24.740625 32.85714285714286 38.0625 32.85714285714286C 51.384375 32.85714285714286 62.803125 24.642857142857153 76.125 24.642857142857153C 89.446875 24.642857142857153 100.865625 53.392857142857146 114.1875 53.392857142857146C 127.509375 53.392857142857146 138.928125 32.85714285714286 152.25 32.85714285714286C 165.571875 32.85714285714286 176.990625 53.392857142857146 190.3125 53.392857142857146C 203.634375 53.392857142857146 215.053125 32.85714285714286 228.375 32.85714285714286C 241.696875 32.85714285714286 253.115625 36.96428571428572 266.4375 36.96428571428572C 279.759375 36.96428571428572 291.178125 57.5 304.5 57.5C 317.821875 57.5 329.240625 53.392857142857146 342.5625 53.392857142857146"
                                                    pathFrom="M -1 115 L -1 115 L 38.0625 115 L 76.125 115 L 114.1875 115 L 152.25 115 L 190.3125 115 L 228.375 115 L 266.4375 115 L 304.5 115 L 342.5625 115"
                                                    fill-rule="evenodd"></path>
                                                <g id="SvgjsG2050"
                                                    class="apexcharts-series-markers-wrap apexcharts-hidden-element-shown"
                                                    data:realIndex="0">
                                                    <g class="apexcharts-series-markers">
                                                        <circle id="SvgjsCircle2102" r="0" cx="0" cy="0"
                                                            class="apexcharts-marker wajxmy38y no-pointer-events"
                                                            stroke="#ffffff" fill="#22c55e" fill-opacity="1"
                                                            stroke-width="2" stroke-opacity="0.9" default-marker-size="0">
                                                        </circle>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="SvgjsG2053" class="apexcharts-series" zIndex="1" seriesName="Lastxmonth"
                                                data:longestSeries="true" rel="2" data:realIndex="1">
                                                <path id="SvgjsPath2056"
                                                    d="M 0 53.392857142857146C 13.321874999999999 53.392857142857146 24.740625 61.60714285714286 38.0625 61.60714285714286C 51.384375 61.60714285714286 62.803125 41.07142857142857 76.125 41.07142857142857C 89.446875 41.07142857142857 100.865625 73.92857142857143 114.1875 73.92857142857143C 127.509375 73.92857142857143 138.928125 49.28571428571429 152.25 49.28571428571429C 165.571875 49.28571428571429 176.990625 36.96428571428572 190.3125 36.96428571428572C 203.634375 36.96428571428572 215.053125 53.392857142857146 228.375 53.392857142857146C 241.696875 53.392857142857146 253.115625 57.5 266.4375 57.5C 279.759375 57.5 291.178125 73.92857142857143 304.5 73.92857142857143C 317.821875 73.92857142857143 329.240625 8.214285714285722 342.5625 8.214285714285722"
                                                    fill="none" fill-opacity="1" stroke="rgba(115,115,115,0.85)"
                                                    stroke-opacity="1" stroke-linecap="butt" stroke-width="3"
                                                    stroke-dasharray="0" class="apexcharts-line" index="1"
                                                    clip-path="url(#gridRectMaskr1h0lvfuj)"
                                                    pathTo="M 0 53.392857142857146C 13.321874999999999 53.392857142857146 24.740625 61.60714285714286 38.0625 61.60714285714286C 51.384375 61.60714285714286 62.803125 41.07142857142857 76.125 41.07142857142857C 89.446875 41.07142857142857 100.865625 73.92857142857143 114.1875 73.92857142857143C 127.509375 73.92857142857143 138.928125 49.28571428571429 152.25 49.28571428571429C 165.571875 49.28571428571429 176.990625 36.96428571428572 190.3125 36.96428571428572C 203.634375 36.96428571428572 215.053125 53.392857142857146 228.375 53.392857142857146C 241.696875 53.392857142857146 253.115625 57.5 266.4375 57.5C 279.759375 57.5 291.178125 73.92857142857143 304.5 73.92857142857143C 317.821875 73.92857142857143 329.240625 8.214285714285722 342.5625 8.214285714285722"
                                                    pathFrom="M -1 115 L -1 115 L 38.0625 115 L 76.125 115 L 114.1875 115 L 152.25 115 L 190.3125 115 L 228.375 115 L 266.4375 115 L 304.5 115 L 342.5625 115"
                                                    fill-rule="evenodd"></path>
                                                <g id="SvgjsG2054"
                                                    class="apexcharts-series-markers-wrap apexcharts-hidden-element-shown"
                                                    data:realIndex="1">
                                                    <g class="apexcharts-series-markers">
                                                        <circle id="SvgjsCircle2103" r="0" cx="0" cy="0"
                                                            class="apexcharts-marker ws92cut12 no-pointer-events"
                                                            stroke="#ffffff" fill="#737373" fill-opacity="1"
                                                            stroke-width="2" stroke-opacity="0.9" default-marker-size="0">
                                                        </circle>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="SvgjsG2051" class="apexcharts-datalabels" data:realIndex="0"></g>
                                            <g id="SvgjsG2055" class="apexcharts-datalabels" data:realIndex="1"></g>
                                        </g>
                                        <line id="SvgjsLine2068" x1="0" y1="0" x2="342.5625" y2="0" stroke="#b6b6b6"
                                            stroke-dasharray="0" stroke-width="1" stroke-linecap="butt"
                                            class="apexcharts-ycrosshairs"></line>
                                        <line id="SvgjsLine2069" x1="0" y1="0" x2="342.5625" y2="0" stroke-dasharray="0"
                                            stroke-width="0" stroke-linecap="butt" class="apexcharts-ycrosshairs-hidden">
                                        </line>
                                        <g id="SvgjsG2070" class="apexcharts-xaxis" transform="translate(0, 0)">
                                            <g id="SvgjsG2071" class="apexcharts-xaxis-texts-g"
                                                transform="translate(0, -4)"></g>
                                        </g>
                                        <g id="SvgjsG2099" class="apexcharts-yaxis-annotations"></g>
                                        <g id="SvgjsG2100" class="apexcharts-xaxis-annotations"></g>
                                        <g id="SvgjsG2101" class="apexcharts-point-annotations"></g>
                                    </g>
                                </svg>
                                <div class="apexcharts-tooltip apexcharts-theme-light">
                                    <div class="apexcharts-tooltip-title"
                                        style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;"></div>
                                    <div class="apexcharts-tooltip-series-group" style="order: 1;"><span
                                            class="apexcharts-tooltip-marker"
                                            style="background-color: rgb(34, 197, 94);"></span>
                                        <div class="apexcharts-tooltip-text"
                                            style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                            <div class="apexcharts-tooltip-y-group"><span
                                                    class="apexcharts-tooltip-text-y-label"></span><span
                                                    class="apexcharts-tooltip-text-y-value"></span></div>
                                            <div class="apexcharts-tooltip-goals-group"><span
                                                    class="apexcharts-tooltip-text-goals-label"></span><span
                                                    class="apexcharts-tooltip-text-goals-value"></span></div>
                                            <div class="apexcharts-tooltip-z-group"><span
                                                    class="apexcharts-tooltip-text-z-label"></span><span
                                                    class="apexcharts-tooltip-text-z-value"></span></div>
                                        </div>
                                    </div>
                                    <div class="apexcharts-tooltip-series-group" style="order: 2;"><span
                                            class="apexcharts-tooltip-marker"
                                            style="background-color: rgb(115, 115, 115);"></span>
                                        <div class="apexcharts-tooltip-text"
                                            style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                            <div class="apexcharts-tooltip-y-group"><span
                                                    class="apexcharts-tooltip-text-y-label"></span><span
                                                    class="apexcharts-tooltip-text-y-value"></span></div>
                                            <div class="apexcharts-tooltip-goals-group"><span
                                                    class="apexcharts-tooltip-text-goals-label"></span><span
                                                    class="apexcharts-tooltip-text-goals-value"></span></div>
                                            <div class="apexcharts-tooltip-z-group"><span
                                                    class="apexcharts-tooltip-text-z-label"></span><span
                                                    class="apexcharts-tooltip-text-z-value"></span></div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="apexcharts-yaxistooltip apexcharts-yaxistooltip-0 apexcharts-yaxistooltip-left apexcharts-theme-light">
                                    <div class="apexcharts-yaxistooltip-text"></div>
                                </div>
                            </div>
                        </div>

                        <!-- Legend Indicator -->
                        <div class="flex pdrgo items-center xrsjp i3acl">
                            <div class="pqrvw items-center">
                                <span class="w1ile vzn8i uiqm5 k6q5b ifon2 nhufc"></span>
                                <span class="ldcb4 v474b dark:text-neutral-400">
                                    This month
                                </span>
                            </div>
                            <div class="pqrvw items-center">
                                <span class="w1ile vzn8i uiqm5 g8qb9 ifon2 nhufc dark:bg-neutral-700"></span>
                                <span class="ldcb4 v474b dark:text-neutral-400">
                                    Last month
                                </span>
                            </div>
                        </div>
                        <!-- End Legend Indicator -->
                    </div>
                    <!-- End Double Line Chart in Card -->
                </div>
                <!-- End Charts Grid -->
            </div>
        </div>
    </main>
</template>
<script>
import navigation from '@/views/ecommerce/navigation.vue'
export default {
    components: {
        navigation
    }
}
</script>