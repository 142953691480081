<template>
  <div>
    <audienceAnalytics />
  </div>
  <div class="grid sm:grid-cols-2 lg:grid-cols-3 overflow-scroll">
    <div>
      <browserList />
    </div>
    <div>
      <countryPodium />
    </div>
    <div>
      <timesheet />
    </div>
    <!-- <div>
      <timesheet />
    </div>
    <div>
      <goalConversions />
    </div>
    <div>
      <progressbar />
    </div> -->
  </div>
</template>
<script>
import browserList from './components/browser-list.vue';
import countryPodium from './components/country-podium.vue'
import referralTraffic from './components/referral-traffic.vue'
import timesheet from './components/timesheet.vue'
import goalConversions from './components/goal-conversions'
import progressbar from './components/progressbar.vue'
import audienceAnalytics from './components/audience-analytics.vue'

export default {
  components: {
    audienceAnalytics,
    browserList,
    countryPodium,
    referralTraffic,
    timesheet,
    goalConversions,
    progressbar

  }
}
</script>