import { useCloneStore } from "@/stores/CloneStore"
import {
    useHostStore
} from "@/stores/HostStore"
import api from "@/services/api"
const LeaderboardMixin = {
    data: () => {
        return {
            cloneStore: useCloneStore(),
            hostStore: useHostStore(),
            leaderboard: []
        }
    },
    mounted() {
        this.GetLeaderboard()
    },
    computed: {
        hostname() {
            return this.hostStore.hostname
        },
        isLeader() {
            const exists = this.leaderboard.some(element => element['username'] === JSON.parse(localStorage.getItem("user")).data.username)
            return exists
        }
    },
    methods: {
        async GetLeaderboard() {
            try {
                const response = await api.get(
                    `${this.hostname}/api/v1/get/leaderboard`
                )
                console.log("Response:", response.data)
                this.leaderboard = response.data.data
            } catch (e) {
                console.log("Status code:", e.response.status)
            }
        },


    },
}

export default LeaderboardMixin