import './assets/main.css'
import './input.css'
import 'preline'
import './assets/fonts.scss';
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createHead } from '@vueuse/head'


import App from './App.vue'
import router from './router'
import Theme from '@/components/theme.vue'
import Loader from '@/components/loader.vue'
import Error from '@/components/error.vue'
import Success from '@/components/success.vue'
import Hint from '@/components/hint.vue'
import Upload from '@/components/upload.vue'
import Popover from '@/components/popover.vue'
import Subscription from '@/components/subscription.vue'
import Share from "@/components/share.vue"
import VueFeather from 'vue-feather'
import VueCountdown from '@chenfengyuan/vue-countdown';
import jQuery from 'jquery'
window.$ = window.jQuery = jQuery

import Flicking from "@egjs/vue3-flicking/dist/flicking.esm";
import "@egjs/vue3-flicking/dist/flicking.css";
// Or, if you have to support IE9
import "@egjs/vue3-flicking/dist/flicking-inline.css";
import DeleteModal from '@/components/DeleteModal'
import VueSocialSharing from "vue-social-sharing"


const app = createApp(App)
const head = createHead()


app.component('Theme', Theme)
app.component('Loader', Loader)
app.component('Error', Error)
app.component('Subscription', Subscription)
app.component('Success', Success)
app.component('Hint', Hint)
app.component('Upload', Upload)
app.component('Popover', Popover)
app.component(VueFeather.name, VueFeather)
app.component(VueCountdown.name, VueCountdown)
app.component('DeleteModal', DeleteModal)
app.component("Flicking", Flicking)
app.component("Share", Share)


app.use(createPinia())
app.use(router)
app.use(head)
app.use(VueSocialSharing)


app.mount('#app')
