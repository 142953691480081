import api from "@/services/api"
import { userStore } from "@/stores/UserStore"
import { useHostStore } from "@/stores/HostStore"
import { useProjectStore } from "@/stores/ProjectStore"

const ProjectMixin = {
    data: () => {
        return {
            hostStore: useHostStore(),
            projectStore: useProjectStore(),
            loading: true,
            cProject: null,
            hasSuccess: false,
            deleteModal: false,
            message: "Project was deleted successfully",
        }
    },
    computed: {
        hostname() {
            return this.hostStore.hostname
        },
        user() {
            return JSON.parse(localStorage.getItem("user"))
        },
        results: {
            get() {
                return this.projectStore.results
            },
            set(e) {
                this.projectStore.setResults(e)
            }
        }
    },
    methods: {
        async GetProjects() {
            const user = JSON.parse(localStorage.getItem("user"))
            // if (user == null) {
            //   window.location.replace(`${this.hostname}/api/v1/login`);
            // }
            this.loading = true
            console.log(user)
            console.log(user.data.id)
            try {
                const response = await api.get(
                    `${this.hostname}/api/v1/projects/user/get/${user.data.id}`
                )
                console.log("Response:", response.data)
                this.projectStore.setProjects(response.data.projects)
                this.results = response.data.projects
                this.loading = false
                if (response.data.projects.length == 0) {
                    this.$router.push('/onboarding')
                }
            } catch (error) {
                console.error("Error:", error)
            }
        },
        handleDelete(p) {
            this.cProject = p
            this.deleteModal = true
        },
        async deleteProject() {
            const data = {
                uid: this.user.data.id,
            }
            this.loading = true
            this.deleteModal = false
            try {
                const response = await api.post(
                    `${this.hostname}/api/v1/project/delete/${this.cProject._id}`,
                    data
                )
                console.log(response)
                this.hasSuccess = true
                this.GetProjects()
            } catch (error) {
                console.error("Error:", error)
                this.message = error
                this.hasError = true
            }
        },

        SetProject(e) {
            this.projectStore.setResponse(e)
            this.$router.push("/project/configure")
        },
    }

}

export default ProjectMixin