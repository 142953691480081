<template>
	<!-- Start block -->
	<section class="dark:bg-neutral-800" id="kpis">
		<div
			class="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6"
		>
			<div class="col-span-2 mb-8">
				<p
					class="text-lg font-medium text-gray-300 dark:bg-clip-text text-transparent bg-[conic-gradient(at_top_left,_var(--tw-gradient-stops))] from-blue-700 via-gray-300 to-fuchsia-200"
				>
					How it works
				</p>
				<h2
					class="mt-3 mb-4 text-3xl font-extrabold tracking-tight text-white md:text-3xl"
				>
				It adjusts layout, color schemes, fonts, and other design elements to seamlessly fit into the chosen template or design preferences.
				</h2>
				<p class="font-light text-white sm:text-xl">
					Welcome to Zookish – where innovation meets simplicity! Our groundbreaking website cloner powered by cutting-edge artificial intelligence technology is here to revolutionize the way you create and replicate websites. Let's delve into how it works:
				</p>
				<div
					class="pt-6 mt-6 space-y-4 border-t border-neutral-200 dark:border-neutral-700"
				>
					<div>
						<div
							class="max-w-2xl mx-auto w-full sm:flex sm:space-y-0 sm:space-x-4"
						>
							<div class="gap-3 flex" v-if="isUser">
								<router-link
									class="inline-flex justify-center items-center gap-x-3 text-center bg-indigo-500 shadow-lg shadow-transparent hover:shadow-blue-700/50 border border-transparent text-white text-sm font-medium rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white py-3 px-6 dark:focus:ring-offset-amber-600"
									to="/projects"
								>
									Projects
									<svg
										class="flex-shrink-0 w-4 h-4"
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										stroke="currentColor"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									>
										<path d="m9 18 6-6-6-6" />
									</svg>
								</router-link>
							</div>
						</div>
					</div>
					<div>
						<img src="@/assets/clip5.gif" class="mx-auto" alt="">
					</div>
					<div>
						<router-link
							to="/leaderboard"
							class="inline-flex items-center text-base font-medium text-gray-300 hover:text-blue-800 dark:bg-clip-text text-transparent bg-[conic-gradient(at_top_left,_var(--tw-gradient-stops))] from-blue-700 via-gray-300 to-fuchsia-200 dark:hover:text-blue-700"
						>
							Visit our Share Leaderboard
							<svg
								class="w-5 h-5 ml-1"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
									clip-rule="evenodd"
								></path>
							</svg>
						</router-link>
					</div>
				</div>
			</div>
			<div
				class="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0"
			>
				<div>
					<h3 class="mb-2 text-2xl font-bold dark:text-white">Smart Site Analysis</h3>
					<p class="font-light text-white">
						Our AI website builder begins by conducting a comprehensive analysis of the target website. Through advanced algorithms, it dissects the structure, layout, and content, ensuring a deep understanding of the design elements.
					</p>
				</div>
				<div>
					<h3 class="mb-2 text-2xl font-bold dark:text-white">Content Extraction</h3>
					<p class="font-light text-white">
						The AI meticulously extracts text, images, videos, and other multimedia content from the source website. It doesn't just copy-paste; it intelligently adapts the content for optimal performance and responsiveness on various devices.
					</p>
				</div>
				<div>
					<h3 class="mb-2 text-2xl font-bold dark:text-white">
						Responsive Design Optimization
					</h3>
					<p class="font-light text-white">
						Our website cloner ensures that the cloned site is fully responsive. Whether viewed on a desktop, tablet, or smartphone, your website will maintain its visual appeal and functionality across all devices.
					</p>
				</div>
				<div>
					<h3 class="mb-2 text-2xl font-bold dark:text-white">
						SEO-Friendly Structure
					</h3>
					<p class="font-light text-white">
						Recognizing the importance of search engine visibility, our AI incorporates SEO-friendly practices during the cloning process. This includes optimized meta tags, clean URL structures, and other elements to enhance your website's search engine ranking.
					</p>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
	export default {
		computed: {
			isUser() {
				if (localStorage.getItem("user") == null) {
					return false
				} else {
					return true
				}
			},
		},
	}
</script>
