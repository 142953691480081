// Define the mixin
import api from "@/services/api";
import axios from "axios";

const PageAnalyticsMixin = {
    data() {
        return {
            project: null,
            page: null,
            startTime: null,
            elapsedTime: 0,
            intervalId: null,
            uniqueVisitor: true,
            userAgent: null,
            os: null,
            browser: null,
            isMobile: false,
            referrer: null,
            scrollDepth: 0,
            pageLoadTime: null,
            ip: null,
            country: null,
            countryCode: null,
            Interactions: null,
            formAbandoned: null,
            clickDepth: null,
            singlePageVisit: false,

        }
    },
    mounted() {
        this.startTimer();

        window.addEventListener('beforeunload', this.stopTimer());
        window.addEventListener("load", this.measurePageLoadTime());
        window.addEventListener("load", this.trackSinglePageVisit());
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'hidden') {
                this.stopTimer();
            } else if (document.visibilityState === 'visible') {
                this.startTimer();
            }
        });
        this.getUA()
        this.getReferrer()

        this.checkUV()
        this.getIP()
        this.stopTimer();
        this.trackScrollDepth();
        this.LogData();
    },
    beforeDestroy() {
        this.stopTimer();
        this.trackScrollDepth();
        this.LogData();
    },
    computed: {
        formattedElapsedTime() {
            const seconds = Math.round(this.elapsedTime / 1000);
            return `${seconds} seconds`;
        },
    },
    methods: {
        startTimer() {
            this.startTime = new Date();
            this.intervalId = setInterval(this.updateElapsedTime, 1000);
        },
        stopTimer() {
            if (this.startTime) {
                clearInterval(this.intervalId);
                const endTime = new Date();
                const timeDiff = endTime - this.startTime;
                this.elapsedTime += timeDiff;
                this.startTime = null;
                console.log(`Time spent on page: ${this.formattedElapsedTime}`);
                // Store visit 
            }
        },
        updateElapsedTime() {
            if (this.startTime) {
                const currentTime = new Date();
                const timeDiff = currentTime - this.startTime;
                this.elapsedTime += timeDiff;
                this.startTime = currentTime;
            }
        },
        // Get User Agent
        getUA() {
            this.userAgent = navigator.userAgent;
            // Example: Check if the user is using a mobile device
            if (/Mobi/.test(this.userAgent)) {
                console.log("User is using a mobile device");
                this.isMobile = true
            } else {
                console.log("User is not using a mobile device");
            }
            var os, browser;

            var browserRegex = /(Edge\/([\d.]+)|Chrome\/([\d.]+)|Safari\/([\d.]+)|Firefox\/([\d.]+)|MSIE ([\d.]+)|Trident\/[\d.]+(?:\.[\d.]+)?(?:; Touch)?)/i;

            // Regular expression to match OS information in the User-Agent string
            var osRegex = /(Windows NT \d+\.\d+|Mac OS X \d+(?:_\d+)?|Linux|Android \d+(\.\d+)?|iOS \d+(?:_\d+)?|Chrome OS)*/i;

            // Execute the regular expression against the User-Agent string
            var osMatch = osRegex.exec(this.userAgent);

            // Variable to store the detected OS
            var os = "Unknown";

            // Check if the OS information was successfully matched
            if (osMatch) {
                // Matched groups:
                // 1: Windows
                // 2: Mac OS X
                // 3: Linux
                // 4: Android
                // 5: iOS
                // 6: Chrome OS

                if (osMatch[1]) {
                    os = osMatch[1];
                } else if (osMatch[2]) {
                    os = osMatch[2].replace(/_/g, ".");
                } else if (osMatch[3]) {
                    os = "Linux";
                } else if (osMatch[4]) {
                    os = osMatch[4];
                } else if (osMatch[5]) {
                    os = osMatch[5].replace(/_/g, ".");
                } else if (osMatch[6]) {
                    os = osMatch[6];
                }
            }

            // Match browser information
            var browserMatch = browserRegex.exec(this.userAgent);
            if (browserMatch) {
                if (browserMatch[2]) {
                    browser = "Edge " + browserMatch[2];
                } else if (browserMatch[3]) {
                    browser = "Chrome " + browserMatch[3];
                } else if (browserMatch[4]) {
                    browser = "Safari " + browserMatch[4];
                } else if (browserMatch[5]) {
                    browser = "Firefox " + browserMatch[5];
                } else if (browserMatch[6]) {
                    browser = "Internet Explorer " + browserMatch[6];
                }
            }

            this.os = os
            this.browser = browser
        },

        getReferrer() {
            var referrer = document.referrer;

            // Check if referrer is empty or if it's from the same origin
            if (referrer && referrer !== "") {
                console.log("Referrer:", referrer);
                this.referrer = referrer
            } else {
                console.log("No referrer or same origin");
            }
        },
        trackScrollDepth() {
            // Calculate the percentage of the page scrolled
            var scrollDepth = (window.scrollY / (document.body.scrollHeight - window.innerHeight)) * 100;

            // Log the scroll depth to the console
            this.scrollDepth = Math.round(scrollDepth)


            // You can send this information to your analytics server for tracking
        },
        // Check if unique visitor
        checkUV() {
            // Check if the visitor has a unique identifier stored in cookies
            if (!document.cookie.includes("visitor_id")) {
                // Generate a unique identifier for the visitor
                var visitorId = this.generateUniqueId(); // You need to implement this function

                // Set the unique identifier in a cookie with an expiration date
                var expiryDate = new Date();
                expiryDate.setFullYear(expiryDate.getFullYear() + 1); // Expires after 1 year
                document.cookie = "visitor_id=" + visitorId + "; expires=" + expiryDate.toUTCString() + "; path=/";

                console.log("New unique visitor identified with ID: " + visitorId);
            } else {
                console.log("Returning visitor");
                this.uniqueVisitor = false
            }
        },
        generateUniqueId() {
            // Generate a unique identifier (e.g., UUID)
            // You can use various methods like timestamp + random numbers or UUID generation libraries
            // For simplicity, let's use a basic timestamp + random number approach
            var timestamp = new Date().getTime();
            var randomNumber = Math.floor(Math.random() * 1000000); // Generate a random number between 0 and 999999
            return "visitor_" + timestamp + "_" + randomNumber;
        },

        async getIP() {
            try {
                const response = await axios.get('https://api.ipify.org?format=json')

                // Extract the IP address from the response data
                const ipAddress = response.data.ip;
                this.ip = ipAddress
                console.log('Your IP address is:', ipAddress);

                this.GetCountry(ipAddress)
            } catch (error) {
                console.error('Error fetching IP address:', error);
                throw error; // Propagate the error
            }
        },

        async GetCountry(ip) {
            try {
                const response = await api.get(
                    `${this.hostname}/api/v1/ip/country/${ip}`
                );
                console.log(response)
                this.country = JSON.parse(response.data).country_name
                this.countryCode = JSON.parse(response.data).country_code2
            } catch (error) {
                console.log(error)
            }
        },

        measurePageLoadTime() {
            // Check if the Performance API is supported by the browser
            if (window.performance && typeof window.performance.getEntriesByType === 'function') {
                // Get navigation entries from the Performance API
                var navigationEntries = window.performance.getEntriesByType('navigation');

                // Ensure there is at least one navigation entry
                if (navigationEntries.length > 0) {
                    // Get the first navigation entry
                    var navigationEntry = navigationEntries[0];

                    // Get the navigation start time from the navigation entry
                    var navigationStart = navigationEntry.startTime;

                    // Get the load event end time from the navigation entry
                    var loadEventEnd = navigationEntry.loadEventEnd;

                    // Calculate the page load time in milliseconds
                    this.pageLoadTime = loadEventEnd - navigationStart;

                    // Log the page load time to the console
                    console.log("Page Load Time: " + this.pageLoadTime + " milliseconds");

                    // You can send this information to your analytics server for tracking
                } else {
                    console.log("No navigation entries found");
                }
            } else {
                console.log("Performance API is not supported");
            }
        },
        trackSinglePageVisit() {
            // Check if the Performance API is supported by the browser
            if (window.performance && window.performance.getEntriesByType) {
                // Get navigation entries from the Performance API
                var navigationEntries = window.performance.getEntriesByType('navigation');

                // Ensure there is at least one navigation entry
                if (navigationEntries.length > 0) {
                    // Get the first navigation entry
                    var navigationEntry = navigationEntries[0];

                    // Check if it's a single-page visit (bounce)
                    if (navigationEntry.type === 'navigate') {
                        console.log("Bounce detected");
                        this.singlePageVisit = true
                        // You can send this information to your analytics server for tracking
                    } else {
                        console.log("Not a bounce");
                    }
                } else {
                    console.log("No navigation entries found");
                }
            } else {
                console.log("Performance API is not supported");
            }
        },
        async LogData() {
            const data = {
                project: this.project,
                page: this.$route.params.id,
                uniqueVisitor: this.uniqueVisitor,
                os: this.os,
                browser: this.browser,
                ip: this.ip,
                country: this.country,
                countryCode: this.countryCode,
                userAgent: this.userAgent,
                referrer: this.referrer,
                timeOnPage: this.elapsedTime,
                scrollDepth: this.scrollDepth,
                networkType: navigator.connection.effectiveType,
                isMobile: this.isMobile,
                pageLoadTime: this.pageLoadTime,
                userLanguage: navigator.language || navigator.userLanguage,
                Interactions: this.interactions,
                formAbandoned: this.formAbandoned,
                clickDepth: this.clickDepth,
                bounceRate: this.bounceRate,
                IsSPV: this.singlePageVisit
            }
            console.log(data)
        }

    }

}

export default PageAnalyticsMixin