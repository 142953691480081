<template>
    <div id="toast-success"
        class="flex fixed bg-white bg-opacity-60 items-center w-full z-50 left-1/2 -translate-x-1/2 bottom-5 max-w-xs p-4 mb-4 text-neutral-50 rounded-lg shadow dark:text-neutral-400 dark:bg-red-600 "
        role="alert">
        <div class="ml-3 text-sm font-normal text-neutral-800 dark:text-gray-200">
            You are offline or have a slow connection.
        </div>
        <button @click="$emit('close')">
            <vue-feather type="x" class="text-white" />
        </button>
    </div>
</template>
<script>
export default {
    emits: ['close'],
    props: {
        message: String,
    },
}
</script>
