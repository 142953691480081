// Define the mixin
import api from "@/services/api";
import Cookies from "js-cookie";
import { useHostStore } from "@/stores/HostStore";
import axios from "axios";

const GeolocationMixin = {
  data: () => {
    return {
      hostStore: useHostStore(),
    }
  },
  computed: {
    ip() {
      return localStorage.getItem('IP')
    },
    hasGI() {
      if (localStorage.getItem('GI') == null) {
        return false
      } else {
        return true
      }
    },
    hasIP() {
      if (this.ip == null) {
        return false
      } else {
        return true
      }
    },
    hostname() {
      return this.hostStore.hostname;
    },
  },
  methods: {
    async reverseIP() {
      if (!this.hasIP) {
        await this.getIP()

        try {
          const response = await api.get(
            `${this.hostname}/api/v1/reverse/ip/${Cookies.get('ip')}`
          );
          console.log(response.data);
          if (JSON.parse(response.data).hasOwnProperty(`message`)) {
            console.log('has message')
          } else {
            localStorage.setItem(`IP`, response.data)
            // window.location.reload()
            console.log('Does not have messsage')
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(JSON.parse(localStorage.getItem(`IP`)))
      }


    },


    async getIP() {
      try {
        const response = await axios.get('https://api.ipify.org?format=json')

        // Extract the IP address from the response data
        const ipAddress = response.data.ip;
        console.log('Your IP address is:', ipAddress);

        this.GetCountry(ipAddress)
      } catch (error) {
        console.error('Error fetching IP address:', error);
        throw error; // Propagate the error
      }
    },

    async GetCountry(ip) {
      try {
        const response = await api.get(
          `${this.hostname}/api/v1/ip/country/${ip}`
        );
        console.log(response)
      } catch (error) {
        console.log(error)
      }
    }
  },
}

export default GeolocationMixin