import { useCloneStore } from "@/stores/CloneStore"
import {
    useHostStore
} from "@/stores/HostStore"
import api from "@/services/api"
const Aimixin = {
    data: () => {
        return {
            cloneStore: useCloneStore(),
            hostStore: useHostStore()
        }
    },
    computed: {
        hostname() {
            return this.hostStore.hostname
        }
    },
    methods: {
        async replaceTextInHtmlExcludingLinks(htmlString) {
            // Parse the HTML string into a DOM
            var parser = new DOMParser()
            var doc = parser.parseFromString(htmlString, "text/html")

            // Start replacing text from the body of the parsed document
            await this.replaceTextNodes(doc.body)

            console.log(new XMLSerializer().serializeToString(doc))
            // Serialize the document back to an HTML string
            return new XMLSerializer().serializeToString(doc)
        },
        async replaceTextNodes(node) {
            if (node.tagName === "A") {
                // Skip <a> elements
                return
            }

            for (const child of node.childNodes) {
                if (child.nodeType === Node.TEXT_NODE) {
                    child.nodeValue = await this.GenText(child.nodeValue)
                } else if (child.nodeType === Node.ELEMENT_NODE) {
                    await this.replaceTextNodes(child) // Recurse into child elements
                }
            }
        },
        async GenText(e) {
            const data = {
                name: this.name,
                prompt: this.prompt
            }
            try {
                const response = await api.post(`${this.hostname}/api/v1/gentext/${e}`, data)
                this.message = this.cloneStore.setEv(response.data.message.content)
                return response.data.message.content
            } catch (error) {
                console.log("Error:", error)
            }
        },
    },
}

export default Aimixin