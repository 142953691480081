<template>
	<div
		class="fixed top-0 z-infinity left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-neutral-900 flex flex-col items-center justify-center">
		<div
			class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
			<div class="mx-auto justify-center">
				<a class=" text-white " @click="$emit('exit')">
					<vue-feather type="x" size="36" />
				</a>
			</div>

			<div class="mx-auto max-w-3xl">

				<dns />
			</div>
		</div>
		<div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
	</div>
</template>
<script>
import dns from "@/views/project/dns.vue"

export default {
	data() {
		return {}
	},
	emits: ["exit"],
	components: {
		dns,
	},
}
</script>
