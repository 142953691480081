<template>
	<div
		id="toast-success"
		class="flex fixed bg-white bg-opacity-60 items-center w-full z-50 left-1/2 -translate-x-1/2 bottom-5 max-w-xs p-4 mb-4 text-neutral-50 rounded-lg shadow dark:text-neutral-400 dark:bg-black dark:bg-opacity-70"
		role="alert"
	>
		<div class="ml-3 text-sm font-normal text-neutral-800 dark:text-gray-200">
			{{ message }}
		</div>
		<button
			type="button"
			@click="$emit('ack')"
			class="ml-auto -mx-1.5 -my-1.5 bg-white text-neutral-400 hover:text-neutral-900 rounded-lg focus:ring-2 focus:ring-neutral-300 p-1.5 hover:bg-neutral-100 inline-flex items-center justify-center h-8 w-8 dark:text-neutral-500 dark:hover:text-white dark:bg-neutral-800 dark:hover:bg-neutral-700"
			data-dismiss-target="#toast-success"
			aria-label="Close"
		>
			<span class="sr-only">Close</span>
			<svg
				class="w-3 h-3"
				aria-hidden="true"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 14 14"
			>
				<path
					stroke="currentColor"
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
				/>
			</svg>
		</button>
	</div>
</template>
<script>
	export default {
		props: {
			message: String,
		},
	}
</script>
