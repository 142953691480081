<template>
	<div class="mb-8 gap-3 flex justify-end mx-auto">
		<button
			v-if="!noskip"
			@click="$emit('skip')"
			type="button"
			class="px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:text-white disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
		>
			skip
		</button>
		<a
			class="inline-flex justify-end items-center gap-x-3 text-center bg-gradient-to-tl from-blue-600 to-violet-600 shadow-lg shadow-transparent hover:shadow-blue-700/50 border border-transparent text-white text-sm font-medium rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white py-3 px-6 dark:focus:ring-offset-amber-600"
			@click="$emit('continue')"
		>
			Continue
			<svg
				class="flex-shrink-0 w-4 h-4"
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			>
				<path d="m9 18 6-6-6-6" />
			</svg>
		</a>
	</div>
</template>
<script>
	export default {
		emits: ["continue", "skip"],
		props: ["noskip"],
	}
</script>
