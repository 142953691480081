<template>
    <!-- features -->
    <section class="bg-neutral-50 dark:bg-neutral-800" id="features">
        <div class="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
            <!-- Row -->
            <div class="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                <img class="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex" src="@/assets/clip6.gif" alt="feature image 2">
                <div class="text-neutral-500 sm:text-lg dark:text-neutral-400">
                    <h2 class="mb-4 text-3xl font-extrabold tracking-tight text-neutral-900 dark:text-white">We invest in
                        the world’s potential</h2>
                    <p class="mb-8 font-light lg:text-xl">Our cutting-edge technology allows us to replicate websites with
                        precision, maintaining their original look and functionality. Say goodbye to the hassle of starting
                        from scratch.</p>
                    <!-- List -->
                    <ul role="list" class="pt-8 space-y-5 border-t border-neutral-200 my-7 dark:border-neutral-700">
                        <li class="flex space-x-3" v-for="feature in featureListA">
                            <!-- Icon -->
                            <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clip-rule="evenodd"></path>
                            </svg>
                            <span class="text-base font-medium leading-tight text-neutral-900 dark:text-white">{{
                                feature.title }}</span>
                        </li>
                    </ul>
                    <p class="font-light lg:text-xl">Launch your website faster than ever before. Speed up your
                        time-to-market and start attracting visitors and customers.</p>
                </div>
            </div>
            <!-- Row -->
            <div class="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                <div class="text-neutral-500 sm:text-lg dark:text-neutral-400">
                    <h2 class="mb-4 text-3xl font-extrabold tracking-tight text-neutral-900 dark:text-white">Work with tools
                        you
                        already use</h2>
                    <p class="mb-8 font-light lg:text-xl">We don't just clone websites; we adapt them to your specific
                        goals. Customize and personalize to suit your brand and audience.</p>
                    <!-- List -->
                    <ul role="list" class="pt-8 space-y-5 border-t border-neutral-200 my-7 dark:border-neutral-700">
                        <li class="flex space-x-3" v-for="feature in featureList">
                            <!-- Icon -->
                            <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clip-rule="evenodd"></path>
                            </svg>
                            <span class="text-base font-medium leading-tight text-neutral-900 dark:text-white">{{
                                feature.title
                            }}</span>
                        </li>
                    </ul>
                    <p class="mb-8 font-light lg:text-xl"></p>
                </div>
                <img class="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex" src="@/assets/clip4.gif"
                    alt="dashboard feature image">
            </div>

        </div>
    </section>
</template>
<script>
export default {
    data: () => {
        return {
            featureList: [
                {
                    icon: "🚀",
                    title: "Effortless Replication",
                    content: "Our advanced technology allows you to clone any website with just a few clicks. Say goodbye to complex coding and design headaches!"
                },
                {
                    icon: "🎨",
                    title: "Customization Freedom",
                    content: "Once you've cloned a site, our intuitive website builder lets you customize every element to match your unique style and branding."
                },
                {
                    icon: "💼",
                    title: "Perfect for Business",
                    content: "Create a professional online presence that rivals your competitors without the high costs of hiring a web designer."
                },
                {
                    icon: "🌐",
                    title: "Endless Possibilities",
                    content: "Whether you're a blogger, entrepreneur, or e-commerce enthusiast, Website Cloner empowers you to build the website of your dreams."
                },
                {
                    icon: "📈",
                    title: "SEO Optimized",
                    content: "Our websites are SEO-friendly right out of the box, ensuring you're discoverable by your target audience."
                },
                {
                    icon: "🔒",
                    title: "Security and Reliability",
                    content: "Rest easy knowing your website is secure and hosted on a reliable platform, backed by industry-standard security measures."
                },
                {
                    icon: "📱",
                    title: "Mobile-Responsive",
                    content: "Your site will look and perform beautifully on any device, from smartphones to desktops."
                }
            ],
            featureListA: [
                { title: 'Fast' },
                { title: 'Be The Gorilla in the marketing zoo' },
                { title: 'Turbo charge your conversion' },
                { title: '10x your campaigns' },
                { title: 'No more thousands on designers' },
            ]
        }
    }
}
</script>