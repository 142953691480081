<template>
	<loader />
</template>

<script>
	import api from "@/services/api"
	import { userStore } from "@/stores/UserStore"
	import { useHostStore } from "@/stores/HostStore"
	import Cookies from "js-cookie"
	import { v4 as uuidv4 } from "uuid"
	export default {
		data() {
			return {
				code: null,
				state: null,
				profile: null,
				userStore: userStore(),
				hostStore: useHostStore(),
			}
		},
		mounted() {
			this.extractQueryParams()
			this.handleCallback()
			this.AddSharePoints()
		},
		computed: {
			hostname() {
				return this.hostStore.hostname
			},
		},
		methods: {
			extractQueryParams() {
				const url = new URL(window.location.href)
				const params = new URLSearchParams(url.search)
				this.code = params.get("code")
				this.state = params.get("state")
			},
			// check if authentication code matches
			async AddSharePoints() {
				if (localStorage.getItem("referrer") != null) {
					try {
						const data = {
							points: 1,
							username: localStorage.getItem("referrer"),
						}

						const response = await api.post(
							`${this.hostname}/api/v1/add/sharepoints/`,
							data
						)
						console.log("Response:", response.data)
					} catch (error) {
						console.log("Status code:", error.response.status)
					}
				}
			},
			handleCallback() {
				const data = {
					code: this.code,
					state: this.state,
				}
				api.post(`${this.hostname}/api/v1/login/callback`, data).then(r => {
					console.log(r)
					this.profile = r.data
					//create profile store to persist profile data
					this.cUser()
					// pass username
				})
			},
			// getorCreateUser
			cUser() {
				const user = {
					id: uuidv4(),
					username: this.profile.nickname,
					email: this.profile.email,
					customerID: "",
					projects: [],
					created_at: new Date().toISOString(),
					updated_at: new Date().toISOString(),
				}
				api.post(`${this.hostname}/api/v1/create/user`, user).then(r => {
					console.log(r.data)
					this.userStore.setResponse(r.data)
					localStorage.setItem("user", JSON.stringify(r.data))
					const a = localStorage.getItem("next")
					if (a != null) {
						window.location.replace(a)
					} else {
						this.$router.push("/create/project/clone")
					}

					// pass username
				})
			},
			// create middleware for account routes
		},
	}
</script>
