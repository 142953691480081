<template>
	<!-- Start block -->
	<section class="bg-white dark:bg-neutral-900 mt-12" id="faq">
		<div class="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-24 lg:px-6">
			<h2
				class="mb-6 text-3xl font-extrabold tracking-tight text-center lg:mb-8 lg:text-3xl dark:text-white"
			>
				Frequently asked questions
			</h2>
			<div class="max-w-screen-md mx-auto">
				<div
					id="accordion-flush"
					data-accordion="collapse"
					data-active-classes="dark:bg-transparent text-white dark:text-white"
					data-inactive-classes="text-white dark:text-white"
				>
					<div v-for="i in 10">
						<h3 :id="`accordion-flush-heading-${i}`">
							<button
								type="button"
								class="flex items-center justify-between w-full py-5 font-medium text-left border-b border-neutral-200 dark:border-neutral-700 dark:bg-transparent dark:text-white"
								:data-accordion-target="`#accordion-flush-body-${i}`"
								aria-expanded="true"
								:aria-controls="`accordion-flush-body-${i}`"
							>
								<span class="text-white">{{ questions[i] }}</span>
								<svg
									data-accordion-icon=""
									class="w-6 h-6 rotate-180 shrink-0"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fill-rule="evenodd"
										d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
										clip-rule="evenodd"
									></path>
								</svg>
							</button>
						</h3>
						<div
							:id="`accordion-flush-body-${i}`"
							class=""
							:aria-labelledby="`accordion-flush-heading-${i}`"
						>
							<div
								class="py-5 border-b border-neutral-200 dark:border-neutral-700"
							>
								<p class="mb-2 text-white dark:text-white">
									{{ answers[i] }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
	export default {
		data: () => {
			return {
				questions: [
					"How does the AI Website building process work?",
					"Is AI Website building legal and ethical?",
					"Can I customize the migrated website?",
					"What happens to the content from the original website?",
					"Do I need coding skills to use the AI Website building SaaS?",
					"Is the migrated website responsive and mobile-friendly?",
					"Can I migrate any website I want?",
					"What happens if the original website undergoes changes?",
					"Can I use the migrated website for commercial purposes?",
					"Can I collaborate with a team on a migrated website project?",
					"How do I get started with the AI Website building SaaS?",
				],
				answers: [
					"The process is simple. You provide the URL of the website you want to migrate, and our SaaS platform recreates the design and structure. You can then customize and add your content to make it unique.",
					"AI Website building for personal use or commercial purposes is generally acceptable. However, it's important to respect copyright and intellectual property rights of the site. Our service encourages innovation while discouraging direct copying with malicious intent. An editor is provided to ensure you have all the tools to make the website yours.",
					"Absolutely. Once the website is migrated, you can customize it extensively. You can modify colors, images, text, and add your branding to make the migrated website your own.",
					"The content from the original website is used as a placeholder. You'll need to replace it with your own content. This ensures your website is unique and tailored to your needs.",
					"No coding skills are required. Our SaaS platform is designed for users with varying levels of technical expertise. It's a user-friendly solution that simplifies the AI Website building process.",
					"Yes, the migrated website will retain its responsiveness and mobile-friendliness. The design and layout adjustments ensure that your website looks and functions well across various devices.",
					"In most cases, yes. However, some websites might have complex functionalities that are challenging to replicate accurately. Our platform works best with standard website layouts.",
					"If the original website is updated, it won't affect your migrated website. Your migrated version is a snapshot taken at a specific point in time.",
					"Yes, you can use the migrated website for commercial purposes, provided you've replaced the placeholder content with your own and have adhered to copyright and legal considerations.",
					"Yes, you can invite team members to collaborate on your migrated website project. Collaboration features are available to streamline teamwork.",
					"Getting started is easy! Sign up for an account, provide the URL of the website you want to migrate, and follow the guided steps to customize and launch your migrated website.Feel free to adapt and modify these FAQ questions to suit your specific AI Website building SaaS and the questions your potential customers might have.",
				],
			}
		},
	}
</script>
