<template>
	<div class="flex-1 sm:px-0 min-h-screen">
		<delete-Modal v-if="deleteModal" @deleteConfirmed="deleteProject" />
		<!-- Projects Card -->
		<div class="flex lgqw8 f0dty l66z3 d1k81 k3u76 tgfrq gl9vb dark:bg-neutral-800 dark:border-neutral-700">
			<!-- Header -->
			<div class="p-5 qyf7u jjege knnnf ftz41 s9mcu gap-2">
				<h2 class="inline-block kxhcs v7056 dark:text-neutral-200">
					Projects
				</h2>

				<!-- Form Group -->
				<div class="flex hzyrh items-center gap-x-2">
					<!-- Search Input -->
					<div class="relative">
						<div class="absolute vt6s4 atruh flex items-center i24sd ootlu ps-3">
							<svg class="flex-shrink-0 sfo8l qcpnt fyxhw dark:text-neutral-400"
								xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
								stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
								<circle cx="11" cy="11" r="8"></circle>
								<path d="m21 21-4.3-4.3"></path>
							</svg>
						</div>
						<input type="text"
							class="py-1.5 vrf2y bdml7 ccpye mn7of block em545 yj6bp nq4w8 dtjcu focus:border-blue-500 focus:ring-blue-500 kko9e tk4f7 dark:bg-neutral-700 dark:border-transparent dark:text-neutral-400 dark:placeholder:text-neutral-400 dark:focus:ring-neutral-600"
							placeholder="Search">
					</div>
					<!-- End Search Input -->

				</div>
				<!-- End Form Group -->
			</div>
			<!-- End Header -->

			<!-- Nav Tab -->
			<div class="px-5">
				<nav class="relative flex e56at tau54 lwsuf a6pwg tjqz1 zsc42 dark:after:border-neutral-700"
					aria-label="Tabs" role="tablist">
					<button type="button"
						class="hs-tab-active:after:bg-gray-800 qpfd1 kdz1q zdxcz tn07p relative pqrvw items-center dqqs4 zvbcs fyxhw p5a84 dtjcu nq4w8 kko9e tk4f7 focus:outline-none focus:bg-gray-100 tau54 ztotz a6pwg orkl1 kcadt aaznu dark:hs-tab-active:text-neutral-200 dark:hs-tab-active:after:bg-neutral-400 dark:text-neutral-500 dark:hover:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 active"
						id="hs-pro-tabs-dupmp-item-open" data-hs-tab="#hs-pro-tabs-dupmp-open"
						aria-controls="hs-pro-tabs-dupmp-open" role="tab">
						<svg class="flex-shrink-0 sfo8l h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
							viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
							stroke-linejoin="round">
							<rect width="20" height="14" x="2" y="7" rx="2" ry="2"></rect>
							<path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
						</svg>
						4 Open
					</button>
					<button type="button"
						class="hs-tab-active:after:bg-gray-800 qpfd1 kdz1q zdxcz tn07p relative pqrvw items-center dqqs4 zvbcs fyxhw p5a84 dtjcu nq4w8 kko9e tk4f7 focus:outline-none focus:bg-gray-100 tau54 ztotz a6pwg orkl1 kcadt aaznu dark:hs-tab-active:text-neutral-200 dark:hs-tab-active:after:bg-neutral-400 dark:text-neutral-500 dark:hover:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
						id="hs-pro-tabs-dupmp-item-closed" data-hs-tab="#hs-pro-tabs-dupmp-closed"
						aria-controls="hs-pro-tabs-dupmp-closed" role="tab">
						<svg class="flex-shrink-0 sfo8l h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
							viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
							stroke-linejoin="round">
							<rect width="20" height="5" x="2" y="4" rx="2"></rect>
							<path d="M4 9v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9"></path>
							<path d="M10 13h4"></path>
						</svg>
						0 Closed
					</button>
				</nav>
			</div>
			<!-- End Nav Tab -->

			<!-- Tab Content -->
			<div>
				<!-- Tab Content -->
				<div id="hs-pro-tabs-dupmp-open" role="tabpanel" aria-labelledby="hs-pro-tabs-dupmp-item-open" class="">
					<!-- Projects List Group -->
					<ul class="py-2 px-5">
						<!-- List Item -->
						<li class="py-3 bg8gm ih1jz d1k81 dark:border-neutral-700" v-for="result in results">
							<div class="flex gap-x-3">
								<span
									class="mt-1 flex wlxy3 pdrgo items-center w13ko e4kj6 f0dty l66z3 d1k81 nq4w8 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300">
									<vue-feather type="file" />
								</span>
								<div class="grow">
									<router-link
										class="font-medium v7056 amv69 focus:outline-none focus:text-blue-600 dark:text-neutral-200 dark:hover:text-blue-500 dark:focus:text-blue-500"
										:to="`/editor/${result.pages[0]}`">
										{{ result.title }}
									</router-link>
									<p class="text-xs fyxhw dark:text-neutral-500">
										Created {{ formattedDate(result.UpdatedAt) }}
									</p>
								</div>

								<!-- Button Group -->
								<div>
									<div
										class="flex l66z3 d1k81 hl4p4 gdqph nq4w8 -space-x-px dark:border-neutral-700 dark:divide-neutral-700">
										<!-- Copy Project Button Tooltip -->
										<div class="hs-tooltip inline-block">
											<button type="button" @click="handleDelete(result)"
												data-hs-overlay="#hs-danger-alert"
												class="hs-tooltip-toggle w13ko e4kj6 pqrvw pdrgo items-center dqqs4 xw6oq f0dty fyxhw tgfrq cjy9h kko9e tk4f7 focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:text-neutral-500 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
												<vue-feather type="trash" size="16" />
											</button>
											<span
												class="hs-tooltip-content tnij2 hnhol opacity-0 uiqm5 absolute imt5j ootlu zdxcz kdz1q bg-gray-900 rvi38 dafkk nq4w8 dark:bg-neutral-700 hidden"
												role="tooltip" data-popper-placement="top"
												style="position: fixed; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(921px, -353px, 0px);">
												Delete project
											</span>
										</div>
										<!-- End Copy Project Button Tooltip -->

										<!-- Close Project Button Tooltip -->
										<div class="hs-tooltip inline-block">
											<button type="button" @click="SetProject(result)"
												class="hs-tooltip-toggle w13ko e4kj6 pqrvw pdrgo items-center dqqs4 rq8v2 f0dty fyxhw tgfrq cjy9h kko9e tk4f7 focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:text-neutral-500 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
												<vue-feather type="settings" size="16" />
											</button>
											<span
												class="hs-tooltip-content tnij2 hnhol opacity-0 uiqm5 absolute imt5j ootlu zdxcz kdz1q bg-gray-900 rvi38 dafkk nq4w8 dark:bg-neutral-700 hidden"
												role="tooltip" data-popper-placement="top"
												style="position: fixed; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(959px, -353px, 0px);">
												Project Settings
											</span>
										</div>
										<!-- End Close Project Button Tooltip -->
									</div>
								</div>
								<!-- End Button Group -->
							</div>
						</li>
						<!-- End List Item -->

					</ul>
					<!-- End Projects List Group -->
				</div>
				<!-- Tab Content -->

				<!-- Tab Content -->
				<div id="hs-pro-tabs-dupmp-closed" role="tabpanel" class="hidden"
					aria-labelledby="hs-pro-tabs-dupmp-item-closed">
					<!-- Empty State -->
					<div class="max-w-sm qazpp q2x60 flex lgqw8 pdrgo items-center zpy54 space-y-3">
						<span
							class="flex wlxy3 pdrgo items-center w13ko e4kj6 f0dty l66z3 d1k81 iwtls dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300">
							<svg class="flex-shrink-0 sfo8l h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
								viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
								stroke-linecap="round" stroke-linejoin="round">
								<rect width="20" height="14" x="2" y="7" rx="2" ry="2"></rect>
								<path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
							</svg>
						</span>
						<p class="text-sm fyxhw dark:text-neutral-500">
							No closed projects
						</p>

					</div>
					<!-- End Empty State -->
				</div>
				<!-- Tab Content -->
			</div>
			<!-- Tab Content -->

			<!-- Footer -->
			<div class="text-center lz37y d1k81 dark:border-neutral-700">
				<a class="p-3 flex pdrgo items-center azej2 dtjcu m7g7n eass7 a9vfa qhplw focus:outline-none focus:bg-gray-100 dark:text-blue-500 dark:hover:text-blue-600 dark:focus:bg-neutral-700"
					href="#">
					View all projects
					<svg class="flex-shrink-0 sfo8l h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
						viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
						stroke-linejoin="round">
						<path d="m9 18 6-6-6-6"></path>
					</svg>
				</a>
			</div>
			<!-- End Footer -->
		</div>
		<!-- End Projects Card -->



	</div>

	<success v-if="hasSuccess" :message="message" @ack="hasSuccess = false" />
</template>

<script>
import templates from "./templates.vue"

import listHeader from "./list-header.vue"
import ProjectMixin from "@/mixins/ProjectMixin"
import FormatDatesMixin from '@/mixins/FormatDatesMixin'
export default {
	components: {
		templates,
		listHeader,
	},
	mixins: [ProjectMixin, FormatDatesMixin],
	mounted() {
		this.GetProjects()
	},
}
</script>
