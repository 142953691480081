<template>
	<div class="grid grid-cols-2 sm:grid-cols-4 gap-2">
		<div v-if="columns.length != 0" v-for="(column, columnIndex) in columns" :key="columnIndex" class="space-y-2">
			<div class="group relative container" v-for="(template, urlIndex) in column" :key="urlIndex">
				<img :src="getProperImgSrc(template.image)"
					class="w-full h-auto object-cover group-hover:opacity-10 group-hover:scale-10 transition-transform duration-500 ease-in-out"
					alt="Image Description" />

				<div class="absolute top-0 start-0 end-0 opacity-0 group-hover:opacity-100">
					<div class="p-4 md:p-5">
						<router-link
							:to="($route.name == `projects` || $route.name == `dashboard`) ? `/editor/${template.object.pages[0]}` : `/render/${template.object.pages[0]}`"
							class="text-lg font-bold text-neutral-900 dark:text-white">
							{{ template.object.title }}
						</router-link>
						<br>
						<!-- <div class="flex inline-flex" v-if="!isTemplate">
							<router-link
								:to="`/editor/${template.object.pages[0]}`"
								type="button"
								class="p-2 flex justify-center items-center h-[2.875rem] w-[2.875rem] text-sm font-semibold rounded-lg border border-transparent  text-white  disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
							>
								<vue-feather type="edit" size="14" />
							</router-link>
							<button
								@click="SetProject(template.object)"
								type="button"
								class="p-2 flex justify-center items-center h-[2.875rem] w-[2.875rem] text-sm font-semibold rounded-lg border border-transparent  text-white  disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
							>
								<vue-feather type="settings" size="14" />
							</button>
							<button
								@click="handleDelete(template.object)"
								type="button"
								data-hs-overlay="#hs-danger-alert"
								class="p-2 flex justify-center items-center h-[2.875rem] w-[2.875rem] text-sm font-semibold rounded-lg border border-transparent text-white hover: disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
							>
								<vue-feather type="trash" size="14" />
							</button>
						</div> -->

						<p class="mt-2 text-xs text-neutral-700 dark:text-gray-200">
							Last updated {{ formattedDate(template.object.UpdatedAt) }}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- <loader v-if="loading" /> -->
	<delete-modal @deleteConfirmed="deleteProject" />

	<div class="text-center mx-auto justify-center align-center" v-if="loading">
		<vue-feather type="loader" class="spin dark:text-white" />
	</div>
</template>
<script>
import { useHostStore } from "@/stores/HostStore"
import api from "@/services/api"
import FormatDatesMixin from "@/mixins/FormatDatesMixin"
import ProjectMixin from "@/mixins/ProjectMixin"
import { useProjectStore } from "@/stores/ProjectStore"
import ImageMixin from "@/mixins/ImageMixin"
export default {
	props: {},
	mixins: [FormatDatesMixin, ProjectMixin, ImageMixin],
	data() {
		return {
			projectStore: useProjectStore(),
			hostStore: useHostStore(),
			templates: [],
			loading: true,
			columns: [],
			isTemplate: false,
		}
	},
	computed: {
		hostname() {
			return this.hostStore.hostname
		},
		projects() {
			return this.projectStore.results
		},
	},
	watch: {
		projects() {
			this.templates = this.projects
			this.createMasonry()
		},
	},
	mounted() {
		if (this.$route.name == "projects" || this.$route.name == "dashboard") {
			this.templates = this.projects
			this.createMasonry()
			this.loading = false
		} else {
			this.GetTemplates()
			this.isTemplate = true
		}
	},
	methods: {
		hasImage(e) {
			if (e == "") {
				return `https://preline.co/docs/assets/img/1618x1010/img1.jpg`
			} else {
				try {
					var f = JSON.parse(e)
					if (`template_image` in f) {
						return f.template_image
					} else {
						return `https://preline.co/docs/assets/img/1618x1010/img1.jpg`
					}
				} catch (err) {
					return `https://preline.co/docs/assets/img/1618x1010/img1.jpg`
				}
			}
		},
		async GetTemplates() {
			try {
				const response = await api.get(
					`${this.hostname}/api/v1/projects/templates/get`
				)
				console.log("Response:", response.data)
				this.templates = response.data.data
				this.createMasonry()
				this.loading = false
			} catch (error) {
				console.error("Error:", error)
			}
		},
		createMasonry() {
			const columnCount = 4
			this.columns = Array.from({ length: columnCount }, () => [])

			this.templates.forEach((templateObj, index) => {
				const columnIndex = index % columnCount
				const imageUrl = this.getTemplateImageUrl(templateObj.metadata)
				this.columns[columnIndex].push({
					image: imageUrl,
					object: templateObj,
				})
			})
		},

		getTemplateImageUrl(metadata) {
			try {
				const parsedMetadata = JSON.parse(metadata)
				return parsedMetadata.template_image || ""
			} catch (e) {
				return `https://res.cloudinary.com/dxwy9kwne/image/upload/v1702580379/default-2_csqtiy.png`
			}
		},
	},
}
</script>
