// Define the mixin
const CategorizeLinksMixin = {
  methods: {
    categorizeLinks(jsonData) {
      const categorizedData = {
        html: [],
        js: [],
        css: [],
        other: [],
      };

      // Loop through the JSON data and categorize links based on extensions
      for (let i = 0; i < jsonData.length; i++) {
        const link = jsonData[i];
        const extension = link.split(".").pop(); // Get the file extension
        switch (extension) {
          case "html":
            categorizedData.html.push(link);
            break;
          case "js":
            categorizedData.js.push(link);
            break;
          case "css":
            categorizedData.css.push(link);
            break;
          default:
            categorizedData.other.push(link);
            break;
        }
      }

      return categorizedData;
    },
  },
}

export default CategorizeLinksMixin