<template>
  <div class="flex-1 px-2 sm:px-0">
    <div class="mb-10 sm:mb-0 mt-10 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      <!-- <router-link
        to="/create/project"
        class="group dark:bg-neutral-900/30 hover:shadow-xl py-20 px-4 flex flex-col space-y-2 items-center cursor-pointer rounded-md hover:smooth-hover">
        <div
          class="bg-white dark:bg-neutral-900/70 dark:text-white/50 dark:group-hover:text-white smooth-hover flex w-20 h-20 rounded-full items-center justify-center">
          <vue-feather
            type="plus"
            fill
            size="50"></vue-feather>
        </div>
        <div
          class="dark:text-white/50 dark:group-hover:text-white smooth-hover text-center">
          Create page
        </div>
      </router-link> --> 
      <div v-if="pages.length > 0" v-for="page in pages" class="group relative w-[300px] block h-44 sm:h-80 lg:h-46">
        <span class="absolute inset-0 border-2 border-dashed border-black dark:border-white"></span>

        <div
          class="relative flex h-full transform items-end border-2 border-black dark:border-white bg-white dark:bg-neutral-900 transition-transform group-hover:-translate-x-2 group-hover:-translate-y-2">
          <div class="p-4 !pt-0 transition-opacity group-hover:absolute group-hover:opacity-0 sm:p-6 lg:p-8">

            <h2 class="mt-4 text-xl font-medium sm:text-2xl  dark:text-white"> {{ page.title }}</h2>
          </div>

          <div
            class="absolute p-4 opacity-0 transition-opacity group-hover:relative group-hover:opacity-100 sm:p-6 lg:p-8">
            <h3 class="mt-4 text-xl font-medium sm:text-2xl dark:text-white"> {{ page.title }}</h3>

            <p class=" text-sm sm:text-base  dark:text-white">
             No description
            </p>

            <router-link class="mt-8 font-bold mr-4  dark:text-white" :to="`/editor/${page._id}`">Edit</router-link>
            <router-link class="mt-8 font-bold mr-4  dark:text-white" :to="`/page/data/${page._id}`">View Data </router-link>
          </div>
        </div>
      </div>
      
      <!-- <router-link v-if="pages.length > 0" v-for="page in pages" :to="`/editor/${page._id}`"
        class="group dark:bg-neutral-900/30 hover:shadow-xl py-20 px-4 flex flex-col space-y-2 items-center cursor-pointer rounded-md hover:smooth-hover">
        <div
          class="bg-white dark:bg-neutral-900/70 dark:text-white/50 dark:group-hover:text-white smooth-hover flex w-20 h-20 rounded-full items-center justify-center">
          <vue-feather type="layers" size="50" />
        </div>
        <div class="dark:text-white/50 dark:group-hover:text-white smooth-hover text-center">
          {{ page.title }}
        </div>
      </router-link> -->
    </div>
  </div>
  <loader v-if="loading" />
</template>

<script>
import api from "@/services/api";
import { userStore } from "@/stores/UserStore";
import { useHostStore } from "@/stores/HostStore";

export default {
  data: () => {
    return {
      user: userStore(),
      hostStore: useHostStore(),
      pages: [],
      loading: false,
    };
  },
  mounted() {
    this.GetPages();
  },
  computed: {
    userID() {
      return this.user.user.id;
    },
    hostname() {
      return this.hostStore.hostname;
    },
  },
  methods: {
    async GetPages() {
      this.loading = true;
      try {
        const response = await api.get(
          `${this.hostname}/api/v1/projects/pages/get/${this.$route.params.id}`
        );
        console.log("Response:", response.data);
        this.pages = response.data.pages;
      } catch (error) {
        console.error("Error:", error);
      }
      this.loading = false;
    },
  },
};
</script>
