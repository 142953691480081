// Define the mixin
const ParsableMixin = {
  methods: {
    Parsable(data) {
        if (data == "" || data == undefined){
          return false;
        }else{
          return true
        }
    },
  },
}

export default ParsableMixin