<template>
    <navigation />
    <main class="bg-neutral-900 min-h-screen" id="content" role="main">
        <div class="cl6bi pgrdw qbu9t r0x22 qazpp">
            <!-- Breadcrumb -->
            <ol class="bzhis qsf3i flex items-center mfkro" aria-label="Breadcrumb">
                <li class="flex items-center dtjcu v474b dark:text-neutral-500">
                    Products
                    <svg class="wlxy3 lo4kb trvun qcpnt sfo8l qv2j8 dark:text-neutral-600"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path d="m9 18 6-6-6-6"></path>
                    </svg>
                </li>
                <li class="dtjcu kxhcs j4ei7 truncate dark:text-neutral-200" aria-current="page">
                    Overview
                </li>
            </ol>
            <!-- End Breadcrumb -->
            <!-- Empty State -->

            <div class="xxfsd ugq3y sly8f bcfme">
                <!-- Page Header -->
                <div class="flex azaj8 items-center zn9hg">
                    <h2 class="hbd69 p1q58 kxhcs j4ei7 dark:text-neutral-200">
                        Products
                    </h2>

                    <div class="flex prqc8 items-center dqqs4">

                        <router-link
                            class="xxfsd vrf2y pqrvw items-center dqqs4 dtjcu kxhcs nq4w8 l66z3 yj6bp k6q5b dafkk zwj7f kko9e tk4f7 focus:outline-none focus:ring-2 focus:ring-green-500"
                            :to="`/add/product/${$route.params.id}`">
                            <svg class="hidden p6u16 wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24"
                                height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path d="M5 12h14"></path>
                                <path d="M12 5v14"></path>
                            </svg>
                            Add product
                        </router-link>
                        <!-- End Button -->
                    </div>
                </div>
                <!-- End Page Header -->
                <!-- Products -->
                <div>
                    <!-- Tab Content -->
                    <div id="hs-pro-tabs-dut-all" role="tabpanel" aria-labelledby="hs-pro-tabs-dut-item-all">
                        <!-- Table Section -->
                        <div
                            class="fmrhk cs0un szov1 nn2ns g3n6w dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500">
                            <div class="si0ns uiqm5 ja4wp">
                                <!-- Table -->
                                <table class="si0ns phe4g igot0 dark:divide-neutral-700">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="c8qx6 in9y1">
                                                <input type="checkbox"
                                                    class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800">
                                            </th>

                                            <th scope="col" class="imiwy">
                                                <!-- Sort Dropdown -->
                                                <div class="hs-dropdown relative pqrvw w-full cursor-pointer">
                                                    <button id="hs-pro-dutnms" type="button"
                                                        class="jmd74 j9jou in9y1 w-full flex items-center azej2 rvi38 ouifk qfc33 fj6be focus:outline-none focus:bg-stone-100 dark:text-neutral-500 dark:focus:bg-neutral-700">
                                                        Item
                                                        <svg class="wlxy3 pmkhf eejlj" xmlns="http://www.w3.org/2000/svg"
                                                            width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round">
                                                            <path d="m7 15 5 5 5-5"></path>
                                                            <path d="m7 9 5-5 5 5"></path>
                                                        </svg>
                                                    </button>

                                                    <!-- Dropdown -->
                                                    <div class="hs-dropdown-menu muqsh ccpye transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                                                        aria-labelledby="hs-pro-dutnms">
                                                        <div class="dpnc4">
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="m5 12 7-7 7 7"></path>
                                                                    <path d="M12 19V5"></path>
                                                                </svg>
                                                                Sort ascending
                                                            </button>
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="M12 5v14"></path>
                                                                    <path d="m19 12-7 7-7-7"></path>
                                                                </svg>
                                                                Sort descending
                                                            </button>
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="m12 19-7-7 7-7"></path>
                                                                    <path d="M19 12H5"></path>
                                                                </svg>
                                                                Move left
                                                            </button>
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="M5 12h14"></path>
                                                                    <path d="m12 5 7 7-7 7"></path>
                                                                </svg>
                                                                Move right
                                                            </button>

                                                            <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                                                    <path
                                                                        d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68">
                                                                    </path>
                                                                    <path
                                                                        d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61">
                                                                    </path>
                                                                    <line x1="2" x2="22" y1="2" y2="22"></line>
                                                                </svg>
                                                                Hide in view
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <!-- End Dropdown -->
                                                </div>
                                                <!-- End Sort Dropdown -->
                                            </th>

                                            <th scope="col" class="p90ec">
                                                <!-- Sort Dropdown -->
                                                <div class="hs-dropdown relative pqrvw w-full cursor-pointer">
                                                    <button id="hs-pro-dutads" type="button"
                                                        class="jmd74 j9jou in9y1 w-full flex items-center azej2 rvi38 ouifk qfc33 fj6be focus:outline-none focus:bg-stone-100 dark:text-neutral-500 dark:focus:bg-neutral-700">
                                                        Type
                                                        <svg class="wlxy3 pmkhf eejlj" xmlns="http://www.w3.org/2000/svg"
                                                            width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round">
                                                            <path d="m7 15 5 5 5-5"></path>
                                                            <path d="m7 9 5-5 5 5"></path>
                                                        </svg>
                                                    </button>

                                                    <!-- Dropdown -->
                                                    <div class="hs-dropdown-menu muqsh ccpye transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                                                        aria-labelledby="hs-pro-dutads">
                                                        <div class="dpnc4">
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="m5 12 7-7 7 7"></path>
                                                                    <path d="M12 19V5"></path>
                                                                </svg>
                                                                Sort ascending
                                                            </button>
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="M12 5v14"></path>
                                                                    <path d="m19 12-7 7-7-7"></path>
                                                                </svg>
                                                                Sort descending
                                                            </button>
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="m12 19-7-7 7-7"></path>
                                                                    <path d="M19 12H5"></path>
                                                                </svg>
                                                                Move left
                                                            </button>
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="M5 12h14"></path>
                                                                    <path d="m12 5 7 7-7 7"></path>
                                                                </svg>
                                                                Move right
                                                            </button>

                                                            <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                                                    <path
                                                                        d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68">
                                                                    </path>
                                                                    <path
                                                                        d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61">
                                                                    </path>
                                                                    <line x1="2" x2="22" y1="2" y2="22"></line>
                                                                </svg>
                                                                Hide in view
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <!-- End Dropdown -->
                                                </div>
                                                <!-- End Sort Dropdown -->
                                            </th>

                                            <th scope="col">
                                                <!-- Sort Dropdown -->
                                                <div class="hs-dropdown relative pqrvw w-full cursor-pointer">
                                                    <button id="hs-pro-dutemails" type="button"
                                                        class="jmd74 j9jou in9y1 w-full flex items-center azej2 rvi38 ouifk qfc33 fj6be focus:outline-none focus:bg-stone-100 dark:text-neutral-500 dark:focus:bg-neutral-700">
                                                        Stocks
                                                        <svg class="wlxy3 pmkhf eejlj" xmlns="http://www.w3.org/2000/svg"
                                                            width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round">
                                                            <path d="m7 15 5 5 5-5"></path>
                                                            <path d="m7 9 5-5 5 5"></path>
                                                        </svg>
                                                    </button>

                                                    <!-- Dropdown -->
                                                    <div class="hs-dropdown-menu muqsh ccpye transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                                                        aria-labelledby="hs-pro-dutemails">
                                                        <div class="dpnc4">
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="m5 12 7-7 7 7"></path>
                                                                    <path d="M12 19V5"></path>
                                                                </svg>
                                                                Sort ascending
                                                            </button>
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="M12 5v14"></path>
                                                                    <path d="m19 12-7 7-7-7"></path>
                                                                </svg>
                                                                Sort descending
                                                            </button>
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="m12 19-7-7 7-7"></path>
                                                                    <path d="M19 12H5"></path>
                                                                </svg>
                                                                Move left
                                                            </button>
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="M5 12h14"></path>
                                                                    <path d="m12 5 7 7-7 7"></path>
                                                                </svg>
                                                                Move right
                                                            </button>

                                                            <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                                                    <path
                                                                        d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68">
                                                                    </path>
                                                                    <path
                                                                        d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61">
                                                                    </path>
                                                                    <line x1="2" x2="22" y1="2" y2="22"></line>
                                                                </svg>
                                                                Hide in view
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <!-- End Dropdown -->
                                                </div>
                                                <!-- End Sort Dropdown -->
                                            </th>

                                            <th scope="col">
                                                <!-- Sort Dropdown -->
                                                <div class="hs-dropdown relative pqrvw w-full cursor-pointer">
                                                    <button id="hs-pro-dutphs" type="button"
                                                        class="jmd74 j9jou in9y1 w-full flex items-center azej2 rvi38 ouifk qfc33 fj6be focus:outline-none focus:bg-stone-100 dark:text-neutral-500 dark:focus:bg-neutral-700">
                                                        SKU
                                                        <svg class="wlxy3 pmkhf eejlj" xmlns="http://www.w3.org/2000/svg"
                                                            width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round">
                                                            <path d="m7 15 5 5 5-5"></path>
                                                            <path d="m7 9 5-5 5 5"></path>
                                                        </svg>
                                                    </button>

                                                    <!-- Dropdown -->
                                                    <div class="hs-dropdown-menu muqsh ccpye transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                                                        aria-labelledby="hs-pro-dutphs">
                                                        <div class="dpnc4">
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="m5 12 7-7 7 7"></path>
                                                                    <path d="M12 19V5"></path>
                                                                </svg>
                                                                Sort ascending
                                                            </button>
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="M12 5v14"></path>
                                                                    <path d="m19 12-7 7-7-7"></path>
                                                                </svg>
                                                                Sort descending
                                                            </button>
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="m12 19-7-7 7-7"></path>
                                                                    <path d="M19 12H5"></path>
                                                                </svg>
                                                                Move left
                                                            </button>
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="M5 12h14"></path>
                                                                    <path d="m12 5 7 7-7 7"></path>
                                                                </svg>
                                                                Move right
                                                            </button>

                                                            <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                                                    <path
                                                                        d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68">
                                                                    </path>
                                                                    <path
                                                                        d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61">
                                                                    </path>
                                                                    <line x1="2" x2="22" y1="2" y2="22"></line>
                                                                </svg>
                                                                Hide in view
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <!-- End Dropdown -->
                                                </div>
                                                <!-- End Sort Dropdown -->
                                            </th>

                                            <th scope="col">
                                                <!-- Sort Dropdown -->
                                                <div class="hs-dropdown relative pqrvw w-full cursor-pointer">
                                                    <button id="hs-pro-dutphsl" type="button"
                                                        class="jmd74 j9jou in9y1 w-full flex items-center azej2 rvi38 ouifk qfc33 fj6be focus:outline-none focus:bg-stone-100 dark:text-neutral-500 dark:focus:bg-neutral-700">
                                                        Price
                                                        <svg class="wlxy3 pmkhf eejlj" xmlns="http://www.w3.org/2000/svg"
                                                            width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round">
                                                            <path d="m7 15 5 5 5-5"></path>
                                                            <path d="m7 9 5-5 5 5"></path>
                                                        </svg>
                                                    </button>

                                                    <!-- Dropdown -->
                                                    <div class="hs-dropdown-menu muqsh ccpye transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                                                        aria-labelledby="hs-pro-dutphsl">
                                                        <div class="dpnc4">
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="m5 12 7-7 7 7"></path>
                                                                    <path d="M12 19V5"></path>
                                                                </svg>
                                                                Sort ascending
                                                            </button>
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="M12 5v14"></path>
                                                                    <path d="m19 12-7 7-7-7"></path>
                                                                </svg>
                                                                Sort descending
                                                            </button>
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="m12 19-7-7 7-7"></path>
                                                                    <path d="M19 12H5"></path>
                                                                </svg>
                                                                Move left
                                                            </button>
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="M5 12h14"></path>
                                                                    <path d="m12 5 7 7-7 7"></path>
                                                                </svg>
                                                                Move right
                                                            </button>

                                                            <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                                                    <path
                                                                        d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68">
                                                                    </path>
                                                                    <path
                                                                        d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61">
                                                                    </path>
                                                                    <line x1="2" x2="22" y1="2" y2="22"></line>
                                                                </svg>
                                                                Hide in view
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <!-- End Dropdown -->
                                                </div>
                                                <!-- End Sort Dropdown -->
                                            </th>

                                            <th scope="col" class="bvmpp">
                                                <!-- Sort Dropdown -->
                                                <div class="hs-dropdown relative pqrvw w-full cursor-pointer">
                                                    <button id="hs-pro-dutphvr" type="button"
                                                        class="jmd74 j9jou in9y1 w-full flex items-center azej2 rvi38 ouifk qfc33 fj6be focus:outline-none focus:bg-stone-100 dark:text-neutral-500 dark:focus:bg-neutral-700">
                                                        Available in
                                                        <svg class="wlxy3 pmkhf eejlj" xmlns="http://www.w3.org/2000/svg"
                                                            width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round">
                                                            <path d="m7 15 5 5 5-5"></path>
                                                            <path d="m7 9 5-5 5 5"></path>
                                                        </svg>
                                                    </button>

                                                    <!-- Dropdown -->
                                                    <div class="hs-dropdown-menu muqsh ccpye transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                                                        aria-labelledby="hs-pro-dutphvr">
                                                        <div class="dpnc4">
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="m5 12 7-7 7 7"></path>
                                                                    <path d="M12 19V5"></path>
                                                                </svg>
                                                                Sort ascending
                                                            </button>
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="M12 5v14"></path>
                                                                    <path d="m19 12-7 7-7-7"></path>
                                                                </svg>
                                                                Sort descending
                                                            </button>
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="m12 19-7-7 7-7"></path>
                                                                    <path d="M19 12H5"></path>
                                                                </svg>
                                                                Move left
                                                            </button>
                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="M5 12h14"></path>
                                                                    <path d="m12 5 7 7-7 7"></path>
                                                                </svg>
                                                                Move right
                                                            </button>

                                                            <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                                            <button type="button"
                                                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                                <svg class="wlxy3 xtsb0 f5cx3"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round">
                                                                    <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                                                    <path
                                                                        d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68">
                                                                    </path>
                                                                    <path
                                                                        d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61">
                                                                    </path>
                                                                    <line x1="2" x2="22" y1="2" y2="22"></line>
                                                                </svg>
                                                                Hide in view
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <!-- End Dropdown -->
                                                </div>
                                                <!-- End Sort Dropdown -->
                                            </th>

                                            <th scope="col"></th>
                                        </tr>
                                    </thead>

                                    <tbody class="phe4g igot0 dark:divide-neutral-700">
                                        <tr v-for="product in storeProducts">
                                            <td class="o65eo lnq7o mfkro c8qx6 tu5ez">
                                                <input type="checkbox"
                                                    class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800">
                                            </td>
                                            <td class="o65eo lnq7o mfkro pgrdw mvnfe">
                                                <div class="w-full flex items-center ukt6v">
                                                    <img class="wlxy3 y3buf h75us q038c" :src="product.images[0]"
                                                        alt="Image Description">
                                                    <div class="zfftf">
                                                        <router-link :to="`/edit/product/${product._id}`"
                                                            class="dtjcu eass7 j4ei7 z9fa3 cmffi cdcib focus:outline-none focus:underline focus:text-green-600 dark:text-neutral-200 dark:hover:text-green-500 dark:focus:text-green-500">
                                                            {{ product.name }}
                                                        </router-link>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="o65eo lnq7o mfkro pgrdw mvnfe">
                                                <span class="dtjcu v474b dark:text-neutral-400">
                                                    {{ product.category }}
                                                </span>
                                            </td>
                                            <td class="o65eo lnq7o mfkro pgrdw mvnfe">
                                                <div class="flex azaj8 items-center">
                                                    <div class="relative uiqm5">
                                                        <input type="checkbox" id="hs-pro-epts1"
                                                            class="relative wjffe uneb9 si6qh yj6bp tj0d4 iwtls cursor-pointer xgbpo le6lt kfd49 focus:ring-green-600 kko9e tk4f7 checked:bg-none checked:text-green-600 checked:border-green-600 focus:checked:border-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-900

                              o707b pjsxf u5yzy sll73 checked:before:bg-white uim48 checked:before:translate-x-full ud3ze odb7z hv39m dyuqh yr432 hjcrw fx7fi dark:before:bg-neutral-400 dark:checked:before:bg-white">
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="o65eo lnq7o mfkro pgrdw mvnfe">
                                                <span class="dtjcu v474b dark:text-neutral-400">
                                                    2384741241
                                                </span>
                                            </td>
                                            <td class="o65eo lnq7o mfkro pgrdw mvnfe">
                                                <span class="dtjcu v474b dark:text-neutral-400">
                                                    $ {{ product.price }}
                                                </span>
                                            </td>
                                            <td class="o65eo lnq7o mfkro pgrdw mvnfe">
                                                <div class="flex flex-wrap nx6y7">
                                                    <span
                                                        class="lfmff si6qh j4ei7 rvi38 q038c dark:bg-neutral-700 dark:text-neutral-200">
                                                        🌐 Online
                                                    </span>
                                                </div>
                                            </td>
                                            <td class="o65eo lnq7o mfkro pgrdw mvnfe">
                                                <div class="pqrvw items-center -space-x-px">
                                                    <router-link
                                                        class="kbkb7 gpe5b pqrvw pdrgo items-center dqqs4 eass7 ka7ik l66z3 ffqht f0dty j4ei7 tgfrq jonah kko9e tk4f7 focus:outline-none focus:bg-stone-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                                                        :to="`/edit/product/${product._id}`">
                                                        <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg"
                                                            width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round">
                                                            <path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z">
                                                            </path>
                                                            <path d="m15 5 4 4"></path>
                                                        </svg>
                                                    </router-link>

                                                    <div
                                                        class="hs-dropdown relative pqrvw [--auto-close:inside] [--placement:top-right]">
                                                        <button id="hs-pro-dutdm1" type="button"
                                                            class="kbkb7 gpe5b pqrvw pdrgo items-center dqqs4 he2w9 l66z3 ffqht f0dty j4ei7 tgfrq jonah kko9e tk4f7 focus:outline-none focus:bg-stone-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                                                            <svg class="wlxy3 xtsb0 f5cx3"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                                <polyline points="7 10 12 15 17 10"></polyline>
                                                                <line x1="12" x2="12" y1="15" y2="3"></line>
                                                            </svg>
                                                        </button>

                                                        <div class="hs-dropdown-menu muqsh idxal transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                                                            aria-labelledby="hs-pro-dutdm1">
                                                            <div class="dpnc4">
                                                                <div class="xxfsd vrf2y">
                                                                    <span class="block kxhcs j4ei7 dark:text-neutral-200">
                                                                        Download Report
                                                                    </span>
                                                                    <span class="block rvi38 fj6be dark:text-neutral-500">
                                                                        Select Options
                                                                    </span>
                                                                </div>

                                                                <div class="jyfnq lz37y ffqht dark:border-neutral-700">
                                                                </div>

                                                                <div class="npcr1 grnyh">
                                                                    <div
                                                                        class="flex items-center si6qh k3u76 dpnc4 dark:bg-neutral-800">
                                                                        <label for="hs-pro-dutdm1ts1"
                                                                            class="relative zdxcz vrf2y w-full cursor-pointer zpy54 dtjcu j4ei7 nq4w8 dark:text-neutral-200">
                                                                            <svg class="relative wlsmd steqe uiqm5 ja4wp wlxy3 sfo8l qcpnt"
                                                                                width="32" height="32" viewBox="0 0 32 32"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    d="M20.0324 1.91994H9.45071C9.09999 1.91994 8.76367 2.05926 8.51567 2.30725C8.26767 2.55523 8.12839 2.89158 8.12839 3.24228V8.86395L20.0324 15.8079L25.9844 18.3197L31.9364 15.8079V8.86395L20.0324 1.91994Z"
                                                                                    fill="#21A366"></path>
                                                                                <path
                                                                                    d="M8.12839 8.86395H20.0324V15.8079H8.12839V8.86395Z"
                                                                                    fill="#107C41"></path>
                                                                                <path
                                                                                    d="M30.614 1.91994H20.0324V8.86395H31.9364V3.24228C31.9364 2.89158 31.7971 2.55523 31.5491 2.30725C31.3011 2.05926 30.9647 1.91994 30.614 1.91994Z"
                                                                                    fill="#33C481"></path>
                                                                                <path
                                                                                    d="M20.0324 15.8079H8.12839V28.3736C8.12839 28.7243 8.26767 29.0607 8.51567 29.3087C8.76367 29.5567 9.09999 29.6959 9.45071 29.6959H30.6141C30.9647 29.6959 31.3011 29.5567 31.549 29.3087C31.797 29.0607 31.9364 28.7243 31.9364 28.3736V22.7519L20.0324 15.8079Z"
                                                                                    fill="#185C37"></path>
                                                                                <path
                                                                                    d="M20.0324 15.8079H31.9364V22.7519H20.0324V15.8079Z"
                                                                                    fill="#107C41"></path>
                                                                                <path opacity="0.1"
                                                                                    d="M16.7261 6.87994H8.12839V25.7279H16.7261C17.0764 25.7269 17.4121 25.5872 17.6599 25.3395C17.9077 25.0917 18.0473 24.756 18.0484 24.4056V8.20226C18.0473 7.8519 17.9077 7.51616 17.6599 7.2684C17.4121 7.02064 17.0764 6.88099 16.7261 6.87994Z"
                                                                                    class="uvbqf dark:fill-neutral-200"
                                                                                    fill="currentColor"></path>
                                                                                <path opacity="0.2"
                                                                                    d="M15.7341 7.87194H8.12839V26.7199H15.7341C16.0844 26.7189 16.4201 26.5792 16.6679 26.3315C16.9157 26.0837 17.0553 25.748 17.0564 25.3976V9.19426C17.0553 8.84386 16.9157 8.50818 16.6679 8.26042C16.4201 8.01266 16.0844 7.87299 15.7341 7.87194Z"
                                                                                    class="uvbqf dark:fill-neutral-200"
                                                                                    fill="currentColor"></path>
                                                                                <path opacity="0.2"
                                                                                    d="M15.7341 7.87194H8.12839V24.7359H15.7341C16.0844 24.7349 16.4201 24.5952 16.6679 24.3475C16.9157 24.0997 17.0553 23.764 17.0564 23.4136V9.19426C17.0553 8.84386 16.9157 8.50818 16.6679 8.26042C16.4201 8.01266 16.0844 7.87299 15.7341 7.87194Z"
                                                                                    class="uvbqf dark:fill-neutral-200"
                                                                                    fill="currentColor"></path>
                                                                                <path opacity="0.2"
                                                                                    d="M14.7421 7.87194H8.12839V24.7359H14.7421C15.0924 24.7349 15.4281 24.5952 15.6759 24.3475C15.9237 24.0997 16.0633 23.764 16.0644 23.4136V9.19426C16.0633 8.84386 15.9237 8.50818 15.6759 8.26042C15.4281 8.01266 15.0924 7.87299 14.7421 7.87194Z"
                                                                                    class="uvbqf dark:fill-neutral-200"
                                                                                    fill="currentColor"></path>
                                                                                <path
                                                                                    d="M1.51472 7.87194H14.7421C15.0927 7.87194 15.4291 8.01122 15.6771 8.25922C15.925 8.50722 16.0644 8.84354 16.0644 9.19426V22.4216C16.0644 22.7723 15.925 23.1087 15.6771 23.3567C15.4291 23.6047 15.0927 23.7439 14.7421 23.7439H1.51472C1.16402 23.7439 0.827672 23.6047 0.579686 23.3567C0.3317 23.1087 0.192383 22.7723 0.192383 22.4216V9.19426C0.192383 8.84354 0.3317 8.50722 0.579686 8.25922C0.827672 8.01122 1.16402 7.87194 1.51472 7.87194Z"
                                                                                    fill="#107C41"></path>
                                                                                <path
                                                                                    d="M3.69711 20.7679L6.90722 15.794L3.96694 10.8479H6.33286L7.93791 14.0095C8.08536 14.3091 8.18688 14.5326 8.24248 14.68H8.26328C8.36912 14.4407 8.47984 14.2079 8.5956 13.9817L10.3108 10.8479H12.4822L9.46656 15.7663L12.5586 20.7679H10.2473L8.3932 17.2959C8.30592 17.148 8.23184 16.9927 8.172 16.8317H8.14424C8.09016 16.9891 8.01824 17.1399 7.92998 17.2811L6.02236 20.7679H3.69711Z"
                                                                                    fill="white"></path>
                                                                            </svg>
                                                                            <span class="relative wlsmd ja4wp">
                                                                                Excel
                                                                            </span>
                                                                            <input type="radio" name="hs-pro-dutdm1ts"
                                                                                class="n0yz9 i8ytw f6gtg jalbw erlgg tj0d4 cursor-pointer kw6wr s920d u1sov checked:bg-none checked:before:bg-white checked:before:shadow-sm focus:ring-0 focus:before:opacity-80 dark:checked:before:bg-neutral-900 dark:focus:before:bg-neutral-950"
                                                                                id="hs-pro-dutdm1ts1" checked="">
                                                                        </label>

                                                                        <label for="hs-pro-dutdm1ts2"
                                                                            class="relative zdxcz vrf2y w-full cursor-pointer zpy54 dtjcu j4ei7 nq4w8 dark:text-neutral-200">
                                                                            <svg class="relative wlsmd steqe uiqm5 ja4wp wlxy3 sfo8l qcpnt"
                                                                                width="32" height="32" viewBox="0 0 32 32"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    d="M30.6141 1.91994H9.45071C9.09999 1.91994 8.76367 2.05926 8.51567 2.30725C8.26767 2.55523 8.12839 2.89158 8.12839 3.24228V8.86395L20.0324 12.3359L31.9364 8.86395V3.24228C31.9364 2.89158 31.797 2.55523 31.549 2.30725C31.3011 2.05926 30.9647 1.91994 30.6141 1.91994Z"
                                                                                    fill="#41A5EE"></path>
                                                                                <path
                                                                                    d="M31.9364 8.86395H8.12839V15.8079L20.0324 19.2799L31.9364 15.8079V8.86395Z"
                                                                                    fill="#2B7CD3"></path>
                                                                                <path
                                                                                    d="M31.9364 15.8079H8.12839V22.7519L20.0324 26.2239L31.9364 22.7519V15.8079Z"
                                                                                    fill="#185ABD"></path>
                                                                                <path
                                                                                    d="M31.9364 22.752H8.12839V28.3736C8.12839 28.7244 8.26767 29.0607 8.51567 29.3087C8.76367 29.5567 9.09999 29.696 9.45071 29.696H30.6141C30.9647 29.696 31.3011 29.5567 31.549 29.3087C31.797 29.0607 31.9364 28.7244 31.9364 28.3736V22.752Z"
                                                                                    fill="#103F91"></path>
                                                                                <path opacity="0.1"
                                                                                    d="M16.7261 6.87994H8.12839V25.7279H16.7261C17.0764 25.7269 17.4121 25.5872 17.6599 25.3395C17.9077 25.0917 18.0473 24.756 18.0484 24.4056V8.20226C18.0473 7.8519 17.9077 7.51616 17.6599 7.2684C17.4121 7.02064 17.0764 6.88099 16.7261 6.87994Z"
                                                                                    class="uvbqf dark:fill-neutral-200"
                                                                                    fill="currentColor"></path>
                                                                                <path opacity="0.2"
                                                                                    d="M15.7341 7.87194H8.12839V26.7199H15.7341C16.0844 26.7189 16.4201 26.5792 16.6679 26.3315C16.9157 26.0837 17.0553 25.748 17.0564 25.3976V9.19426C17.0553 8.84386 16.9157 8.50818 16.6679 8.26042C16.4201 8.01266 16.0844 7.87299 15.7341 7.87194Z"
                                                                                    class="uvbqf dark:fill-neutral-200"
                                                                                    fill="currentColor"></path>
                                                                                <path opacity="0.2"
                                                                                    d="M15.7341 7.87194H8.12839V24.7359H15.7341C16.0844 24.7349 16.4201 24.5952 16.6679 24.3475C16.9157 24.0997 17.0553 23.764 17.0564 23.4136V9.19426C17.0553 8.84386 16.9157 8.50818 16.6679 8.26042C16.4201 8.01266 16.0844 7.87299 15.7341 7.87194Z"
                                                                                    class="uvbqf dark:fill-neutral-200"
                                                                                    fill="currentColor"></path>
                                                                                <path opacity="0.2"
                                                                                    d="M14.7421 7.87194H8.12839V24.7359H14.7421C15.0924 24.7349 15.4281 24.5952 15.6759 24.3475C15.9237 24.0997 16.0633 23.764 16.0644 23.4136V9.19426C16.0633 8.84386 15.9237 8.50818 15.6759 8.26042C15.4281 8.01266 15.0924 7.87299 14.7421 7.87194Z"
                                                                                    class="uvbqf dark:fill-neutral-200"
                                                                                    fill="currentColor"></path>
                                                                                <path
                                                                                    d="M1.51472 7.87194H14.7421C15.0927 7.87194 15.4291 8.01122 15.6771 8.25922C15.925 8.50722 16.0644 8.84354 16.0644 9.19426V22.4216C16.0644 22.7723 15.925 23.1087 15.6771 23.3567C15.4291 23.6047 15.0927 23.7439 14.7421 23.7439H1.51472C1.16401 23.7439 0.827669 23.6047 0.579687 23.3567C0.3317 23.1087 0.192383 22.7723 0.192383 22.4216V9.19426C0.192383 8.84354 0.3317 8.50722 0.579687 8.25922C0.827669 8.01122 1.16401 7.87194 1.51472 7.87194Z"
                                                                                    fill="#185ABD"></path>
                                                                                <path
                                                                                    d="M12.0468 20.7679H10.2612L8.17801 13.9231L5.99558 20.7679H4.20998L2.22598 10.8479H4.01158L5.40038 17.7919L7.48358 11.0463H8.97161L10.9556 17.7919L12.3444 10.8479H14.0308L12.0468 20.7679Z"
                                                                                    fill="white"></path>
                                                                            </svg>
                                                                            <span class="relative wlsmd ja4wp">
                                                                                Word
                                                                            </span>
                                                                            <input type="radio" name="hs-pro-dutdm1ts"
                                                                                class="n0yz9 i8ytw f6gtg jalbw erlgg tj0d4 cursor-pointer kw6wr s920d u1sov checked:bg-none checked:before:bg-white checked:before:shadow-sm focus:ring-0 focus:before:opacity-80 dark:checked:before:bg-neutral-900 dark:focus:before:bg-neutral-950"
                                                                                id="hs-pro-dutdm1ts2">
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                <div class="jyfnq lz37y ffqht dark:border-neutral-700">
                                                                </div>

                                                                <div
                                                                    class="flex azaj8 items-center xxfsd vrf2y cursor-pointer nq4w8 r1hp8 dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                                                    <label for="hs-pro-dutdm1s1"
                                                                        class="flex yssyn items-center ukt6v cursor-pointer dtjcu j4ei7 dark:text-neutral-300">
                                                                        Item
                                                                    </label>
                                                                    <input type="checkbox"
                                                                        class="n0yz9 xtsb0 f5cx3 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
                                                                        id="hs-pro-dutdm1s1" checked="">
                                                                </div>

                                                                <div
                                                                    class="flex azaj8 items-center xxfsd vrf2y cursor-pointer nq4w8 r1hp8 dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                                                    <label for="hs-pro-dutdm1s2"
                                                                        class="flex yssyn items-center ukt6v cursor-pointer dtjcu j4ei7 dark:text-neutral-300">
                                                                        Type
                                                                    </label>
                                                                    <input type="checkbox"
                                                                        class="n0yz9 xtsb0 f5cx3 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
                                                                        id="hs-pro-dutdm1s2" checked="">
                                                                </div>

                                                                <div
                                                                    class="flex azaj8 items-center xxfsd vrf2y cursor-pointer nq4w8 r1hp8 dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                                                    <label for="hs-pro-dutdm1s3"
                                                                        class="flex yssyn items-center ukt6v cursor-pointer dtjcu j4ei7 dark:text-neutral-300">
                                                                        Stocks
                                                                    </label>
                                                                    <input type="checkbox"
                                                                        class="n0yz9 xtsb0 f5cx3 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
                                                                        id="hs-pro-dutdm1s3" checked="">
                                                                </div>

                                                                <div
                                                                    class="flex azaj8 items-center xxfsd vrf2y cursor-pointer nq4w8 r1hp8 dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                                                    <label for="hs-pro-dutdm1s4"
                                                                        class="flex yssyn items-center ukt6v cursor-pointer dtjcu j4ei7 dark:text-neutral-300">
                                                                        SKU
                                                                    </label>
                                                                    <input type="checkbox"
                                                                        class="n0yz9 xtsb0 f5cx3 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
                                                                        id="hs-pro-dutdm1s4" checked="">
                                                                </div>

                                                                <div
                                                                    class="flex azaj8 items-center xxfsd vrf2y cursor-pointer nq4w8 r1hp8 dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                                                    <label for="hs-pro-dutdm1s5"
                                                                        class="flex yssyn items-center ukt6v cursor-pointer dtjcu j4ei7 dark:text-neutral-300">
                                                                        Price
                                                                    </label>
                                                                    <input type="checkbox"
                                                                        class="n0yz9 xtsb0 f5cx3 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
                                                                        id="hs-pro-dutdm1s5" checked="">
                                                                </div>

                                                                <div
                                                                    class="flex azaj8 items-center xxfsd vrf2y cursor-pointer nq4w8 r1hp8 dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                                                    <label for="hs-pro-dutdm1s6"
                                                                        class="flex yssyn items-center ukt6v cursor-pointer dtjcu j4ei7 dark:text-neutral-300">
                                                                        Variants
                                                                    </label>
                                                                    <input type="checkbox"
                                                                        class="n0yz9 xtsb0 f5cx3 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
                                                                        id="hs-pro-dutdm1s6" checked="">
                                                                </div>

                                                                <div class="l5ax2 lz37y ffqht dark:border-neutral-800">
                                                                </div>

                                                                <button type="button"
                                                                    class="w-full xxfsd vrf2y pqrvw pdrgo items-center dqqs4 dtjcu kxhcs nq4w8 l66z3 yj6bp k6q5b dafkk zwj7f kko9e tk4f7 focus:outline-none focus:ring-2 focus:ring-green-500">
                                                                    Download
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- End Table -->
                            </div>
                        </div>
                        <!-- End Table Section -->

                        <!-- Footer -->
                        <div class="i3acl jjege zvyci items-center vj9gl orkeq cc3vv">
                            <p class="dtjcu j4ei7 dark:text-neutral-200">
                                <!-- <span class="eass7">{{ storeProducts.length }}</span> -->
                                <span class="fj6be dark:text-neutral-500"> results</span>
                            </p>

                            <!-- Pagination -->
                            <nav class="flex prqc8 items-center azej2">
                                <button type="button"
                                    class="oa2y3 zaf61 xxfsd kdz1q pqrvw pdrgo items-center dqqs4 dtjcu nq4w8 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-white dark:hover:bg-white/10 dark:focus:bg-neutral-700">
                                    <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path d="m15 18-6-6 6-6"></path>
                                    </svg>
                                    <span aria-hidden="true" class="j9hz5">Previous</span>
                                </button>
                                <div class="flex items-center azej2">
                                    <span
                                        class="oa2y3 zaf61 flex pdrgo items-center si6qh j4ei7 xxfsd vrf2y dtjcu nq4w8 kko9e tk4f7 dark:bg-neutral-700 dark:text-white">1</span>
                                    <span
                                        class="oa2y3 flex pdrgo items-center fj6be xxfsd zfqg6 dtjcu dark:text-neutral-500">of</span>
                                    <span
                                        class="oa2y3 flex pdrgo items-center fj6be xxfsd zfqg6 dtjcu dark:text-neutral-500">3</span>
                                </div>
                                <button type="button"
                                    class="oa2y3 zaf61 xxfsd kdz1q pqrvw pdrgo items-center dqqs4 dtjcu nq4w8 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-white dark:hover:bg-white/10 dark:focus:bg-neutral-700">
                                    <span aria-hidden="true" class="j9hz5">Next</span>
                                    <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path d="m9 18 6-6-6-6"></path>
                                    </svg>
                                </button>
                            </nav>
                            <!-- End Pagination -->
                        </div>
                        <!-- End Footer -->
                    </div>
                    <!-- End Tab Content -->

                    <!-- Tab Content -->
                    <div id="hs-pro-tabs-dut-archived" class="hidden" role="tabpanel"
                        aria-labelledby="hs-pro-tabs-dut-item-archived">
                        <!-- Empty State -->
                        <div class="gcrxx qazpp p3pc1 2xl:min-h-[397px] flex lgqw8 pdrgo items-center zpy54">
                            <svg class="wlxy3 y3buf h75us kufja fj6be" width="16" height="16" viewBox="0 0 16 16"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.328 0.12197C11.3403 0.0863508 11.3635 0.0554647 11.3942 0.0336055C11.4249 0.0117462 11.4617 0 11.4994 0C11.5371 0 11.5739 0.0117462 11.6046 0.0336055C11.6353 0.0554647 11.6584 0.0863508 11.6708 0.12197L11.9936 1.09019C12.1375 1.52302 12.4766 1.86202 12.9095 2.00588L13.878 2.32862C13.9136 2.34097 13.9445 2.36411 13.9664 2.39482C13.9883 2.42554 14 2.4623 14 2.5C14 2.5377 13.9883 2.57446 13.9664 2.60518C13.9445 2.63589 13.9136 2.65903 13.878 2.67138L12.9095 2.99412C12.6962 3.06508 12.5023 3.18478 12.3433 3.34373C12.1843 3.50269 12.0646 3.69651 11.9936 3.90981L11.6708 4.87803C11.6584 4.91365 11.6353 4.94454 11.6046 4.96639C11.5739 4.98825 11.5371 5 11.4994 5C11.4617 5 11.4249 4.98825 11.3942 4.96639C11.3635 4.94454 11.3403 4.91365 11.328 4.87803L11.0051 3.90981C10.9342 3.69651 10.8144 3.50269 10.6554 3.34373C10.4964 3.18478 10.3026 3.06508 10.0892 2.99412L9.122 2.67138C9.08637 2.65903 9.05548 2.63589 9.03361 2.60518C9.01175 2.57446 9 2.5377 9 2.5C9 2.4623 9.01175 2.42554 9.03361 2.39482C9.05548 2.36411 9.08637 2.34097 9.122 2.32862L10.0905 2.00588C10.5234 1.86202 10.8625 1.52302 11.0064 1.09019L11.328 0.12197Z">
                                </path>
                                <path
                                    d="M2.86236 4.09758C2.87224 4.06908 2.89076 4.04437 2.91534 4.02688C2.93992 4.0094 2.96933 4 2.9995 4C3.02967 4 3.05908 4.0094 3.08366 4.02688C3.10824 4.04437 3.12676 4.06908 3.13664 4.09758L3.3949 4.87216C3.51001 5.21841 3.78128 5.48962 4.12763 5.6047L4.9024 5.8629C4.9309 5.87278 4.95562 5.89129 4.97311 5.91586C4.9906 5.94043 5 5.96984 5 6C5 6.03016 4.9906 6.05957 4.97311 6.08414C4.95562 6.10871 4.9309 6.12722 4.9024 6.1371L4.12763 6.3953C3.95694 6.45206 3.80185 6.54783 3.67466 6.67499C3.54746 6.80215 3.45167 6.95721 3.3949 7.12784L3.13664 7.90242C3.12676 7.93092 3.10824 7.95563 3.08366 7.97312C3.05908 7.9906 3.02967 8 2.9995 8C2.96933 8 2.93992 7.9906 2.91534 7.97312C2.89076 7.95563 2.87224 7.93092 2.86236 7.90242L2.6041 7.12784C2.54732 6.95721 2.45154 6.80215 2.32434 6.67499C2.19715 6.54783 2.04205 6.45206 1.87137 6.3953L1.0976 6.1371C1.0691 6.12722 1.04438 6.10871 1.02689 6.08414C1.0094 6.05957 1 6.03016 1 6C1 5.96984 1.0094 5.94043 1.02689 5.91586C1.04438 5.89129 1.0691 5.87278 1.0976 5.8629L1.87237 5.6047C2.21872 5.48962 2.48999 5.21841 2.6051 4.87216L2.86236 4.09758Z">
                                </path>
                                <path
                                    d="M7.96129 9.43888C7.98654 9.34447 8 9.24523 8 9.14286C8 8.51168 7.48833 8 6.85714 8C6.22596 8 5.71429 8.51168 5.71429 9.14286C5.71429 9.24523 5.72775 9.34447 5.753 9.43888L1.43888 13.753C1.34447 13.7277 1.24523 13.7143 1.14286 13.7143C0.511675 13.7143 0 14.226 0 14.8571C0 15.4883 0.511675 16 1.14286 16C1.77404 16 2.28571 15.4883 2.28571 14.8571C2.28571 14.7548 2.27225 14.6555 2.247 14.5611L6.56112 10.247C6.65553 10.2723 6.75477 10.2857 6.85714 10.2857C6.95952 10.2857 7.05875 10.2723 7.15317 10.247L9.753 12.8468C9.72775 12.9412 9.71429 13.0405 9.71429 13.1429C9.71429 13.774 10.226 14.2857 10.8571 14.2857C11.4883 14.2857 12 13.774 12 13.1429C12 13.0405 11.9865 12.9412 11.9613 12.8468L14.5611 10.247C14.6555 10.2723 14.7548 10.2857 14.8571 10.2857C15.4883 10.2857 16 9.77404 16 9.14286C16 8.51168 15.4883 8 14.8571 8C14.226 8 13.7143 8.51168 13.7143 9.14286C13.7143 9.24523 13.7277 9.34447 13.753 9.43888L11.1532 12.0387C11.0588 12.0135 10.9595 12 10.8571 12C10.7548 12 10.6555 12.0135 10.5611 12.0387L7.96129 9.43888Z">
                                </path>
                            </svg>
                            <h4 class="iwr8m eass7 j4ei7 dark:text-neutral-200">
                                Your data will appear here soon.
                            </h4>
                            <p class="q8w6j diql8 dtjcu fj6be dark:text-neutral-500">
                                In the meantime, you can create new custom insights to monitor your most oypae metrics.
                                <a class="pqrvw items-center pla0f dtjcu mq8xz cmffi cdcib eass7 focus:outline-none focus:underline dark:text-green-400 dark:hover:text-green-500"
                                    href="#">
                                    Learn more
                                    <svg class="wlxy3 sfo8l qcpnt transition le6lt b24uu" xmlns="http://www.w3.org/2000/svg"
                                        width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path d="m9 18 6-6-6-6"></path>
                                    </svg>
                                </a>
                            </p>
                        </div>
                        <!-- End Empty State -->
                    </div>
                    <!-- End Tab Content -->

                    <!-- Tab Content -->
                    <div id="hs-pro-tabs-dut-publish" class="hidden" role="tabpanel"
                        aria-labelledby="hs-pro-tabs-dut-item-publish">
                        <!-- Empty State -->
                        <div class="gcrxx qazpp p3pc1 2xl:min-h-[397px] flex lgqw8 pdrgo items-center zpy54">
                            <svg class="wlxy3 y3buf h75us kufja fj6be" width="16" height="16" viewBox="0 0 16 16"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.328 0.12197C11.3403 0.0863508 11.3635 0.0554647 11.3942 0.0336055C11.4249 0.0117462 11.4617 0 11.4994 0C11.5371 0 11.5739 0.0117462 11.6046 0.0336055C11.6353 0.0554647 11.6584 0.0863508 11.6708 0.12197L11.9936 1.09019C12.1375 1.52302 12.4766 1.86202 12.9095 2.00588L13.878 2.32862C13.9136 2.34097 13.9445 2.36411 13.9664 2.39482C13.9883 2.42554 14 2.4623 14 2.5C14 2.5377 13.9883 2.57446 13.9664 2.60518C13.9445 2.63589 13.9136 2.65903 13.878 2.67138L12.9095 2.99412C12.6962 3.06508 12.5023 3.18478 12.3433 3.34373C12.1843 3.50269 12.0646 3.69651 11.9936 3.90981L11.6708 4.87803C11.6584 4.91365 11.6353 4.94454 11.6046 4.96639C11.5739 4.98825 11.5371 5 11.4994 5C11.4617 5 11.4249 4.98825 11.3942 4.96639C11.3635 4.94454 11.3403 4.91365 11.328 4.87803L11.0051 3.90981C10.9342 3.69651 10.8144 3.50269 10.6554 3.34373C10.4964 3.18478 10.3026 3.06508 10.0892 2.99412L9.122 2.67138C9.08637 2.65903 9.05548 2.63589 9.03361 2.60518C9.01175 2.57446 9 2.5377 9 2.5C9 2.4623 9.01175 2.42554 9.03361 2.39482C9.05548 2.36411 9.08637 2.34097 9.122 2.32862L10.0905 2.00588C10.5234 1.86202 10.8625 1.52302 11.0064 1.09019L11.328 0.12197Z">
                                </path>
                                <path
                                    d="M2.86236 4.09758C2.87224 4.06908 2.89076 4.04437 2.91534 4.02688C2.93992 4.0094 2.96933 4 2.9995 4C3.02967 4 3.05908 4.0094 3.08366 4.02688C3.10824 4.04437 3.12676 4.06908 3.13664 4.09758L3.3949 4.87216C3.51001 5.21841 3.78128 5.48962 4.12763 5.6047L4.9024 5.8629C4.9309 5.87278 4.95562 5.89129 4.97311 5.91586C4.9906 5.94043 5 5.96984 5 6C5 6.03016 4.9906 6.05957 4.97311 6.08414C4.95562 6.10871 4.9309 6.12722 4.9024 6.1371L4.12763 6.3953C3.95694 6.45206 3.80185 6.54783 3.67466 6.67499C3.54746 6.80215 3.45167 6.95721 3.3949 7.12784L3.13664 7.90242C3.12676 7.93092 3.10824 7.95563 3.08366 7.97312C3.05908 7.9906 3.02967 8 2.9995 8C2.96933 8 2.93992 7.9906 2.91534 7.97312C2.89076 7.95563 2.87224 7.93092 2.86236 7.90242L2.6041 7.12784C2.54732 6.95721 2.45154 6.80215 2.32434 6.67499C2.19715 6.54783 2.04205 6.45206 1.87137 6.3953L1.0976 6.1371C1.0691 6.12722 1.04438 6.10871 1.02689 6.08414C1.0094 6.05957 1 6.03016 1 6C1 5.96984 1.0094 5.94043 1.02689 5.91586C1.04438 5.89129 1.0691 5.87278 1.0976 5.8629L1.87237 5.6047C2.21872 5.48962 2.48999 5.21841 2.6051 4.87216L2.86236 4.09758Z">
                                </path>
                                <path
                                    d="M7.96129 9.43888C7.98654 9.34447 8 9.24523 8 9.14286C8 8.51168 7.48833 8 6.85714 8C6.22596 8 5.71429 8.51168 5.71429 9.14286C5.71429 9.24523 5.72775 9.34447 5.753 9.43888L1.43888 13.753C1.34447 13.7277 1.24523 13.7143 1.14286 13.7143C0.511675 13.7143 0 14.226 0 14.8571C0 15.4883 0.511675 16 1.14286 16C1.77404 16 2.28571 15.4883 2.28571 14.8571C2.28571 14.7548 2.27225 14.6555 2.247 14.5611L6.56112 10.247C6.65553 10.2723 6.75477 10.2857 6.85714 10.2857C6.95952 10.2857 7.05875 10.2723 7.15317 10.247L9.753 12.8468C9.72775 12.9412 9.71429 13.0405 9.71429 13.1429C9.71429 13.774 10.226 14.2857 10.8571 14.2857C11.4883 14.2857 12 13.774 12 13.1429C12 13.0405 11.9865 12.9412 11.9613 12.8468L14.5611 10.247C14.6555 10.2723 14.7548 10.2857 14.8571 10.2857C15.4883 10.2857 16 9.77404 16 9.14286C16 8.51168 15.4883 8 14.8571 8C14.226 8 13.7143 8.51168 13.7143 9.14286C13.7143 9.24523 13.7277 9.34447 13.753 9.43888L11.1532 12.0387C11.0588 12.0135 10.9595 12 10.8571 12C10.7548 12 10.6555 12.0135 10.5611 12.0387L7.96129 9.43888Z">
                                </path>
                            </svg>
                            <h4 class="iwr8m eass7 j4ei7 dark:text-neutral-200">
                                Your data will appear here soon.
                            </h4>
                            <p class="q8w6j diql8 dtjcu fj6be dark:text-neutral-500">
                                In the meantime, you can create new custom insights to monitor your most oypae metrics.
                                <a class="pqrvw items-center pla0f dtjcu mq8xz cmffi cdcib eass7 focus:outline-none focus:underline dark:text-green-400 dark:hover:text-green-500"
                                    href="#">
                                    Learn more
                                    <svg class="wlxy3 sfo8l qcpnt transition le6lt b24uu" xmlns="http://www.w3.org/2000/svg"
                                        width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path d="m9 18 6-6-6-6"></path>
                                    </svg>
                                </a>
                            </p>
                        </div>
                        <!-- End Empty State -->
                    </div>
                    <!-- End Tab Content -->

                    <!-- Tab Content -->
                    <div id="hs-pro-tabs-dut-unpublish" class="hidden" role="tabpanel"
                        aria-labelledby="hs-pro-tabs-dut-item-unpublish">
                        <!-- Empty State -->
                        <div class="gcrxx qazpp p3pc1 2xl:min-h-[397px] flex lgqw8 pdrgo items-center zpy54">
                            <svg class="wlxy3 y3buf h75us kufja fj6be" width="16" height="16" viewBox="0 0 16 16"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.328 0.12197C11.3403 0.0863508 11.3635 0.0554647 11.3942 0.0336055C11.4249 0.0117462 11.4617 0 11.4994 0C11.5371 0 11.5739 0.0117462 11.6046 0.0336055C11.6353 0.0554647 11.6584 0.0863508 11.6708 0.12197L11.9936 1.09019C12.1375 1.52302 12.4766 1.86202 12.9095 2.00588L13.878 2.32862C13.9136 2.34097 13.9445 2.36411 13.9664 2.39482C13.9883 2.42554 14 2.4623 14 2.5C14 2.5377 13.9883 2.57446 13.9664 2.60518C13.9445 2.63589 13.9136 2.65903 13.878 2.67138L12.9095 2.99412C12.6962 3.06508 12.5023 3.18478 12.3433 3.34373C12.1843 3.50269 12.0646 3.69651 11.9936 3.90981L11.6708 4.87803C11.6584 4.91365 11.6353 4.94454 11.6046 4.96639C11.5739 4.98825 11.5371 5 11.4994 5C11.4617 5 11.4249 4.98825 11.3942 4.96639C11.3635 4.94454 11.3403 4.91365 11.328 4.87803L11.0051 3.90981C10.9342 3.69651 10.8144 3.50269 10.6554 3.34373C10.4964 3.18478 10.3026 3.06508 10.0892 2.99412L9.122 2.67138C9.08637 2.65903 9.05548 2.63589 9.03361 2.60518C9.01175 2.57446 9 2.5377 9 2.5C9 2.4623 9.01175 2.42554 9.03361 2.39482C9.05548 2.36411 9.08637 2.34097 9.122 2.32862L10.0905 2.00588C10.5234 1.86202 10.8625 1.52302 11.0064 1.09019L11.328 0.12197Z">
                                </path>
                                <path
                                    d="M2.86236 4.09758C2.87224 4.06908 2.89076 4.04437 2.91534 4.02688C2.93992 4.0094 2.96933 4 2.9995 4C3.02967 4 3.05908 4.0094 3.08366 4.02688C3.10824 4.04437 3.12676 4.06908 3.13664 4.09758L3.3949 4.87216C3.51001 5.21841 3.78128 5.48962 4.12763 5.6047L4.9024 5.8629C4.9309 5.87278 4.95562 5.89129 4.97311 5.91586C4.9906 5.94043 5 5.96984 5 6C5 6.03016 4.9906 6.05957 4.97311 6.08414C4.95562 6.10871 4.9309 6.12722 4.9024 6.1371L4.12763 6.3953C3.95694 6.45206 3.80185 6.54783 3.67466 6.67499C3.54746 6.80215 3.45167 6.95721 3.3949 7.12784L3.13664 7.90242C3.12676 7.93092 3.10824 7.95563 3.08366 7.97312C3.05908 7.9906 3.02967 8 2.9995 8C2.96933 8 2.93992 7.9906 2.91534 7.97312C2.89076 7.95563 2.87224 7.93092 2.86236 7.90242L2.6041 7.12784C2.54732 6.95721 2.45154 6.80215 2.32434 6.67499C2.19715 6.54783 2.04205 6.45206 1.87137 6.3953L1.0976 6.1371C1.0691 6.12722 1.04438 6.10871 1.02689 6.08414C1.0094 6.05957 1 6.03016 1 6C1 5.96984 1.0094 5.94043 1.02689 5.91586C1.04438 5.89129 1.0691 5.87278 1.0976 5.8629L1.87237 5.6047C2.21872 5.48962 2.48999 5.21841 2.6051 4.87216L2.86236 4.09758Z">
                                </path>
                                <path
                                    d="M7.96129 9.43888C7.98654 9.34447 8 9.24523 8 9.14286C8 8.51168 7.48833 8 6.85714 8C6.22596 8 5.71429 8.51168 5.71429 9.14286C5.71429 9.24523 5.72775 9.34447 5.753 9.43888L1.43888 13.753C1.34447 13.7277 1.24523 13.7143 1.14286 13.7143C0.511675 13.7143 0 14.226 0 14.8571C0 15.4883 0.511675 16 1.14286 16C1.77404 16 2.28571 15.4883 2.28571 14.8571C2.28571 14.7548 2.27225 14.6555 2.247 14.5611L6.56112 10.247C6.65553 10.2723 6.75477 10.2857 6.85714 10.2857C6.95952 10.2857 7.05875 10.2723 7.15317 10.247L9.753 12.8468C9.72775 12.9412 9.71429 13.0405 9.71429 13.1429C9.71429 13.774 10.226 14.2857 10.8571 14.2857C11.4883 14.2857 12 13.774 12 13.1429C12 13.0405 11.9865 12.9412 11.9613 12.8468L14.5611 10.247C14.6555 10.2723 14.7548 10.2857 14.8571 10.2857C15.4883 10.2857 16 9.77404 16 9.14286C16 8.51168 15.4883 8 14.8571 8C14.226 8 13.7143 8.51168 13.7143 9.14286C13.7143 9.24523 13.7277 9.34447 13.753 9.43888L11.1532 12.0387C11.0588 12.0135 10.9595 12 10.8571 12C10.7548 12 10.6555 12.0135 10.5611 12.0387L7.96129 9.43888Z">
                                </path>
                            </svg>
                            <h4 class="iwr8m eass7 j4ei7 dark:text-neutral-200">
                                Your data will appear here soon.
                            </h4>
                            <p class="q8w6j diql8 dtjcu fj6be dark:text-neutral-500">
                                In the meantime, you can create new custom insights to monitor your most oypae metrics.
                                <a class="pqrvw items-center pla0f dtjcu mq8xz cmffi cdcib eass7 focus:outline-none focus:underline dark:text-green-400 dark:hover:text-green-500"
                                    href="#">
                                    Learn more
                                    <svg class="wlxy3 sfo8l qcpnt transition le6lt b24uu" xmlns="http://www.w3.org/2000/svg"
                                        width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path d="m9 18 6-6-6-6"></path>
                                    </svg>
                                </a>
                            </p>
                        </div>
                        <!-- End Empty State -->
                    </div>
                    <!-- End Tab Content -->
                </div>

                <div class="max-w-sm qazpp p3pc1 2xl:min-h-[397px] flex lgqw8 pdrgo items-center text-center">
                    <svg class="flex-shrink-0 y3buf h75us kufja fyxhw dark:text-neutral-500" width="16" height="16"
                        viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11.328 0.12197C11.3403 0.0863508 11.3635 0.0554647 11.3942 0.0336055C11.4249 0.0117462 11.4617 0 11.4994 0C11.5371 0 11.5739 0.0117462 11.6046 0.0336055C11.6353 0.0554647 11.6584 0.0863508 11.6708 0.12197L11.9936 1.09019C12.1375 1.52302 12.4766 1.86202 12.9095 2.00588L13.878 2.32862C13.9136 2.34097 13.9445 2.36411 13.9664 2.39482C13.9883 2.42554 14 2.4623 14 2.5C14 2.5377 13.9883 2.57446 13.9664 2.60518C13.9445 2.63589 13.9136 2.65903 13.878 2.67138L12.9095 2.99412C12.6962 3.06508 12.5023 3.18478 12.3433 3.34373C12.1843 3.50269 12.0646 3.69651 11.9936 3.90981L11.6708 4.87803C11.6584 4.91365 11.6353 4.94454 11.6046 4.96639C11.5739 4.98825 11.5371 5 11.4994 5C11.4617 5 11.4249 4.98825 11.3942 4.96639C11.3635 4.94454 11.3403 4.91365 11.328 4.87803L11.0051 3.90981C10.9342 3.69651 10.8144 3.50269 10.6554 3.34373C10.4964 3.18478 10.3026 3.06508 10.0892 2.99412L9.122 2.67138C9.08637 2.65903 9.05548 2.63589 9.03361 2.60518C9.01175 2.57446 9 2.5377 9 2.5C9 2.4623 9.01175 2.42554 9.03361 2.39482C9.05548 2.36411 9.08637 2.34097 9.122 2.32862L10.0905 2.00588C10.5234 1.86202 10.8625 1.52302 11.0064 1.09019L11.328 0.12197Z">
                        </path>
                        <path
                            d="M2.86236 4.09758C2.87224 4.06908 2.89076 4.04437 2.91534 4.02688C2.93992 4.0094 2.96933 4 2.9995 4C3.02967 4 3.05908 4.0094 3.08366 4.02688C3.10824 4.04437 3.12676 4.06908 3.13664 4.09758L3.3949 4.87216C3.51001 5.21841 3.78128 5.48962 4.12763 5.6047L4.9024 5.8629C4.9309 5.87278 4.95562 5.89129 4.97311 5.91586C4.9906 5.94043 5 5.96984 5 6C5 6.03016 4.9906 6.05957 4.97311 6.08414C4.95562 6.10871 4.9309 6.12722 4.9024 6.1371L4.12763 6.3953C3.95694 6.45206 3.80185 6.54783 3.67466 6.67499C3.54746 6.80215 3.45167 6.95721 3.3949 7.12784L3.13664 7.90242C3.12676 7.93092 3.10824 7.95563 3.08366 7.97312C3.05908 7.9906 3.02967 8 2.9995 8C2.96933 8 2.93992 7.9906 2.91534 7.97312C2.89076 7.95563 2.87224 7.93092 2.86236 7.90242L2.6041 7.12784C2.54732 6.95721 2.45154 6.80215 2.32434 6.67499C2.19715 6.54783 2.04205 6.45206 1.87137 6.3953L1.0976 6.1371C1.0691 6.12722 1.04438 6.10871 1.02689 6.08414C1.0094 6.05957 1 6.03016 1 6C1 5.96984 1.0094 5.94043 1.02689 5.91586C1.04438 5.89129 1.0691 5.87278 1.0976 5.8629L1.87237 5.6047C2.21872 5.48962 2.48999 5.21841 2.6051 4.87216L2.86236 4.09758Z">
                        </path>
                        <path
                            d="M7.96129 9.43888C7.98654 9.34447 8 9.24523 8 9.14286C8 8.51168 7.48833 8 6.85714 8C6.22596 8 5.71429 8.51168 5.71429 9.14286C5.71429 9.24523 5.72775 9.34447 5.753 9.43888L1.43888 13.753C1.34447 13.7277 1.24523 13.7143 1.14286 13.7143C0.511675 13.7143 0 14.226 0 14.8571C0 15.4883 0.511675 16 1.14286 16C1.77404 16 2.28571 15.4883 2.28571 14.8571C2.28571 14.7548 2.27225 14.6555 2.247 14.5611L6.56112 10.247C6.65553 10.2723 6.75477 10.2857 6.85714 10.2857C6.95952 10.2857 7.05875 10.2723 7.15317 10.247L9.753 12.8468C9.72775 12.9412 9.71429 13.0405 9.71429 13.1429C9.71429 13.774 10.226 14.2857 10.8571 14.2857C11.4883 14.2857 12 13.774 12 13.1429C12 13.0405 11.9865 12.9412 11.9613 12.8468L14.5611 10.247C14.6555 10.2723 14.7548 10.2857 14.8571 10.2857C15.4883 10.2857 16 9.77404 16 9.14286C16 8.51168 15.4883 8 14.8571 8C14.226 8 13.7143 8.51168 13.7143 9.14286C13.7143 9.24523 13.7277 9.34447 13.753 9.43888L11.1532 12.0387C11.0588 12.0135 10.9595 12 10.8571 12C10.7548 12 10.6555 12.0135 10.5611 12.0387L7.96129 9.43888Z">
                        </path>
                    </svg>
                    <h4 class="md:text-lg eass7 v7056 dark:text-neutral-200">
                        Your data will appear here soon.
                    </h4>
                    <p class="mt-1 diql8 dtjcu fyxhw dark:text-neutral-500">
                        In the meantime, you can create new custom insights to monitor your most oypae metrics.
                        <a class="inline-flex items-center pla0f dtjcu m7g7n cmffi cdcib eass7 focus:outline-none focus:underline dark:text-blue-400 dark:hover:text-blue-500"
                            href="#">
                            Learn more
                            <svg class="flex-shrink-0 sfo8l qcpnt transition le6lt group-hover:tranneutral-x-1"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="m9 18 6-6-6-6"></path>
                            </svg>
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import navigation from '@/views/ecommerce/navigation.vue'
import EcomMixin from '@/mixins/EcomMixin'
export default {
    mixins: [EcomMixin],
    mounted() {
        this.GetStore()
        this.GetStoreProducts(this.$route.params.id)
    },
    components: {
        navigation
    }
}
</script>