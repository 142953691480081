<template>
  <navigation />
  <main id="content" role="main" class="bg-neutral-900  min-h-screen">
    <div class="cl6bi pgrdw qbu9t r0x22 qazpp">
      <!-- Breadcrumb -->
      <ol class="bzhis qsf3i flex items-center mfkro" aria-label="Breadcrumb">
        <li class="flex items-center dtjcu v474b dark:text-neutral-500">
          Others
          <svg class="wlxy3 lo4kb trvun qcpnt sfo8l qv2j8 dark:text-neutral-600" xmlns="http://www.w3.org/2000/svg"
            width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round">
            <path d="m9 18 6-6-6-6"></path>
          </svg>
        </li>
        <li class="dtjcu kxhcs j4ei7 truncate dark:text-neutral-200" aria-current="page">
          Orders
        </li>
      </ol>
      <!-- End Breadcrumb -->

      <div class="xxfsd ugq3y sly8f bcfme">
        <!-- Page Header -->
        <h1 class="hbd69 p1q58 kxhcs j4ei7 dark:text-neutral-200">
          Orders (34,405)
        </h1>
        <!-- End Page Header -->

        <!-- Orders Table Card -->
        <div class="pqx67 fngso flex lgqw8 f0dty l66z3 ffqht tgfrq k3u76 dark:bg-neutral-800 dark:border-neutral-700">
          <!-- Nav Tab -->
          <nav class="relative flex e56at tau54 lwsuf a6pwg tjqz1 ywzje dark:after:border-neutral-700" aria-label="Tabs"
            role="tablist">
            <button type="button"
              class="cva40 lowss kdz1q zdxcz tn07p relative pqrvw items-center dqqs4 r1hp8 fj6be gzkir dtjcu nq4w8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 tau54 ztotz a6pwg orkl1 kcadt aaznu dark:hs-tab-active:text-neutral-200 dark:hs-tab-active:after:bg-neutral-400 dark:text-neutral-500 dark:hover:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 active"
              id="hs-pro-tabs-dut-item-all" data-hs-tab="#hs-pro-tabs-dut-all" aria-controls="hs-pro-tabs-dut-all"
              role="tab">
              All
            </button>
            <button type="button"
              class="cva40 lowss kdz1q zdxcz tn07p relative pqrvw items-center dqqs4 r1hp8 fj6be gzkir dtjcu nq4w8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 tau54 ztotz a6pwg orkl1 kcadt aaznu dark:hs-tab-active:text-neutral-200 dark:hs-tab-active:after:bg-neutral-400 dark:text-neutral-500 dark:hover:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
              id="hs-pro-tabs-dut-item-open" data-hs-tab="#hs-pro-tabs-dut-open" aria-controls="hs-pro-tabs-dut-open"
              role="tab">
              Open
            </button>
            <button type="button"
              class="cva40 lowss kdz1q zdxcz tn07p relative pqrvw items-center dqqs4 r1hp8 fj6be gzkir dtjcu nq4w8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 tau54 ztotz a6pwg orkl1 kcadt aaznu dark:hs-tab-active:text-neutral-200 dark:hs-tab-active:after:bg-neutral-400 dark:text-neutral-500 dark:hover:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
              id="hs-pro-tabs-dut-item-unfulfilled" data-hs-tab="#hs-pro-tabs-dut-unfulfilled"
              aria-controls="hs-pro-tabs-dut-unfulfilled" role="tab">
              Unfulfilled
            </button>
            <button type="button"
              class="cva40 lowss kdz1q zdxcz tn07p relative pqrvw items-center dqqs4 r1hp8 fj6be gzkir dtjcu nq4w8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 tau54 ztotz a6pwg orkl1 kcadt aaznu dark:hs-tab-active:text-neutral-200 dark:hs-tab-active:after:bg-neutral-400 dark:text-neutral-500 dark:hover:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
              id="hs-pro-tabs-dut-item-unpaid" data-hs-tab="#hs-pro-tabs-dut-unpaid"
              aria-controls="hs-pro-tabs-dut-unpaid" role="tab">
              Unpaid
            </button>
            <button type="button"
              class="grnyh zdxcz tn07p relative pqrvw items-center dqqs4 r1hp8 fj6be gzkir dtjcu nq4w8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 tau54 ztotz a6pwg orkl1 kcadt aaznu dark:text-neutral-500 dark:hover:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-700">
              <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
            </button>
          </nav>
          <!-- End Nav Tab -->

          <!-- Filter Group -->
          <div class="jjege dr94e vj9gl okw5h yumkl">
            <div>
              <!-- Search Input -->
              <div class="relative">
                <div class="absolute vt6s4 atruh flex items-center i24sd ootlu srg7w">
                  <svg class="wlxy3 sfo8l qcpnt fj6be dark:text-neutral-400" xmlns="http://www.w3.org/2000/svg" width="24"
                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round">
                    <circle cx="11" cy="11" r="8"></circle>
                    <path d="m21 21-4.3-4.3"></path>
                  </svg>
                </div>
                <input type="text"
                  class="rn86h vrf2y h996k block w-full si6qh yj6bp nq4w8 dtjcu mjqc8 focus:border-green-500 focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-700 dark:border-transparent dark:text-neutral-400 dark:placeholder:text-neutral-400 dark:focus:ring-neutral-600"
                  placeholder="Search orders">
              </div>
              <!-- End Search Input -->
            </div>
            <!-- End Col -->

            <div class="flex co3v4 items-center dqqs4">
              <!-- Calendar Dropdown -->
              <div class="hs-dropdown pqrvw [--auto-close:inside]">
                <button id="hs-pro-dnic" type="button"
                  class="xxfsd kdz1q pqrvw items-center pla0f rvi38 eass7 nq4w8 l66z3 ffqht f0dty j4ei7 tgfrq jonah kko9e tk4f7 focus:outline-none focus:bg-stone-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                  25 Jul - 25 Aug
                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round">
                    <path d="m6 9 6 6 6-6"></path>
                  </svg>
                </button>

                <div
                  class="hs-dropdown-menu muqsh xbvlq e7pgd transition-[opacity,margin] duration opacity-0 hidden s269j f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                  aria-labelledby="hs-pro-dnic">
                  <div class="knnnf">
                    <!-- Calendar -->
                    <div class="bqp78 xy3is">
                      <!-- Months -->
                      <div class="jjege wi6na items-center ukt6v bs16d i38gt">
                        <!-- Prev Button -->
                        <div class="dwnnx">
                          <button type="button"
                            class="kbkb7 gpe5b flex pdrgo items-center j4ei7 r1hp8 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-400 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                            <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round">
                              <path d="m15 18-6-6 6-6"></path>
                            </svg>
                          </button>
                        </div>
                        <!-- End Prev Button -->

                        <!-- Month / Year -->
                        <div class="qv0l3 flex pdrgo items-center azej2">
                          <div class="relative">
                            <div class="hs-select relative"><select data-hs-select="{
                                &quot;placeholder&quot;: &quot;Select month&quot;,
                                &quot;toggleTag&quot;: &quot;<button type=\&quot;button\&quot;></button>&quot;,
                                &quot;toggleClasses&quot;: &quot;msicw n7tpi relative flex text-nowrap w-full cursor-pointer in9y1 eass7 j4ei7 romwo focus:outline-none focus:text-stone-600 kw6wr s920d bxof7 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300&quot;,
                                &quot;dropdownClasses&quot;: &quot;l2ej6 s269j pqpl9 yjsas dpnc4 xy3is f0dty l66z3 ffqht nq4w8 xrzt6 bx801 pk1vk mws46 szov1 nn2ns g3n6w dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700&quot;,
                                &quot;optionClasses&quot;: &quot;lfmff w-full dtjcu j4ei7 cursor-pointer r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800&quot;,
                                &quot;optionTemplate&quot;: &quot;<div class=\&quot;flex azaj8 items-center w-full\&quot;><span data-title></span><span class=\&quot;hidden hs-selected:block\&quot;><svg class=\&quot;wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200\&quot; xmlns=\&quot;http:.w3.org/2000/svg\&quot; width=\&quot;24\&quot; height=\&quot;24\&quot; viewBox=\&quot;0 0 24 24\&quot; fill=\&quot;none\&quot; stroke=\&quot;currentColor\&quot; stroke-width=\&quot;2\&quot; stroke-linecap=\&quot;round\&quot; stroke-linejoin=\&quot;round\&quot;><polyline points=\&quot;20 6 9 17 4 12\&quot;/></svg></span></div>&quot;
                              }" class="hidden" style="display: none;">
                                <option value="0">January</option>
                                <option value="1">February</option>
                                <option value="2">March</option>
                                <option value="3">April</option>
                                <option value="4">May</option>
                                <option value="5">June</option>
                                <option value="6" selected="">July</option>
                                <option value="7">August</option>
                                <option value="8">September</option>
                                <option value="9">October</option>
                                <option value="10">November</option>
                                <option value="11">December</option>
                              </select><button type="button"
                                class="msicw n7tpi relative flex text-nowrap w-full cursor-pointer in9y1 eass7 j4ei7 romwo focus:outline-none focus:text-stone-600 kw6wr s920d bxof7 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300"><span
                                  class="truncate">July</span></button>
                              <div
                                class="absolute top-full hidden l2ej6 s269j pqpl9 yjsas dpnc4 xy3is f0dty l66z3 ffqht nq4w8 xrzt6 bx801 pk1vk mws46 szov1 nn2ns g3n6w dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700">
                                <div data-value="0" data-title-value="January" tabindex="0"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">January</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="1" data-title-value="February" tabindex="1"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">February</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="2" data-title-value="March" tabindex="2"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">March</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="3" data-title-value="April" tabindex="3"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">April</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="4" data-title-value="May" tabindex="4"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">May</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="5" data-title-value="June" tabindex="5"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">June</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="6" data-title-value="July" tabindex="6"
                                  class="cursor-pointer selected lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">July</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="7" data-title-value="August" tabindex="7"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">August</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="8" data-title-value="September" tabindex="8"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">September</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="9" data-title-value="October" tabindex="9"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">October</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="10" data-title-value="November" tabindex="10"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">November</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="11" data-title-value="December" tabindex="11"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">December</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <span class="j4ei7 dark:text-neutral-200">/</span>

                          <div class="relative">
                            <div class="hs-select relative"><select data-hs-select="{
                                &quot;placeholder&quot;: &quot;Select year&quot;,
                                &quot;toggleTag&quot;: &quot;<button type=\&quot;button\&quot;></button>&quot;,
                                &quot;toggleClasses&quot;: &quot;msicw n7tpi relative flex text-nowrap w-full cursor-pointer in9y1 eass7 j4ei7 romwo focus:outline-none focus:text-stone-600 kw6wr s920d bxof7 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300&quot;,
                                &quot;dropdownClasses&quot;: &quot;l2ej6 s269j m9pyi yjsas dpnc4 xy3is f0dty l66z3 ffqht nq4w8 xrzt6 bx801 pk1vk mws46 szov1 nn2ns g3n6w dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700&quot;,
                                &quot;optionClasses&quot;: &quot;lfmff w-full dtjcu j4ei7 cursor-pointer r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800&quot;,
                                &quot;optionTemplate&quot;: &quot;<div class=\&quot;flex azaj8 items-center w-full\&quot;><span data-title></span><span class=\&quot;hidden hs-selected:block\&quot;><svg class=\&quot;wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200\&quot; xmlns=\&quot;http:.w3.org/2000/svg\&quot; width=\&quot;24\&quot; height=\&quot;24\&quot; viewBox=\&quot;0 0 24 24\&quot; fill=\&quot;none\&quot; stroke=\&quot;currentColor\&quot; stroke-width=\&quot;2\&quot; stroke-linecap=\&quot;round\&quot; stroke-linejoin=\&quot;round\&quot;><polyline points=\&quot;20 6 9 17 4 12\&quot;/></svg></span></div>&quot;
                              }" class="hidden" style="display: none;">
                                <option selected="">2023</option>
                                <option>2024</option>
                                <option>2025</option>
                                <option>2026</option>
                                <option>2027</option>
                              </select><button type="button"
                                class="msicw n7tpi relative flex text-nowrap w-full cursor-pointer in9y1 eass7 j4ei7 romwo focus:outline-none focus:text-stone-600 kw6wr s920d bxof7 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300"><span
                                  class="truncate">2023</span></button>
                              <div
                                class="absolute top-full hidden l2ej6 s269j m9pyi yjsas dpnc4 xy3is f0dty l66z3 ffqht nq4w8 xrzt6 bx801 pk1vk mws46 szov1 nn2ns g3n6w dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700">
                                <div data-value="2023" data-title-value="2023" tabindex="0"
                                  class="cursor-pointer selected lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">2023</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="2024" data-title-value="2024" tabindex="1"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">2024</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="2025" data-title-value="2025" tabindex="2"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">2025</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="2026" data-title-value="2026" tabindex="3"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">2026</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="2027" data-title-value="2027" tabindex="4"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">2027</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- End Month / Year -->

                        <!-- Next Button -->
                        <div class="dwnnx flex prqc8">
                          <button type="button"
                            class="opacity-0 kbkb7 gpe5b flex pdrgo items-center j4ei7 r1hp8 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-400 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                            <svg class="wlxy3 sfo8l qcpnt" width="24" height="24" viewBox="0 0 24 24" fill="none"
                              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                              <path d="m9 18 6-6-6-6"></path>
                            </svg>
                          </button>
                        </div>
                        <!-- End Next Button -->
                      </div>
                      <!-- Months -->

                      <!-- Weeks -->
                      <div class="flex cgcp3">
                        <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                          Mo
                        </span>
                        <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                          Tu
                        </span>
                        <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                          We
                        </span>
                        <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                          Th
                        </span>
                        <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                          Fr
                        </span>
                        <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                          Sa
                        </span>
                        <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                          Su
                        </span>
                      </div>
                      <!-- Weeks -->

                      <!-- Days -->
                      <div class="flex">
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                            disabled="">
                            26
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                            disabled="">
                            27
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                            disabled="">
                            28
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                            disabled="">
                            29
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                            disabled="">
                            30
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            1
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            2
                          </button>
                        </div>
                      </div>
                      <!-- Days -->

                      <!-- Days -->
                      <div class="flex">
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            3
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            4
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            5
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            6
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            7
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            8
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            9
                          </button>
                        </div>
                      </div>
                      <!-- Days -->

                      <!-- Days -->
                      <div class="flex">
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            10
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            11
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            12
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            13
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            14
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            15
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            16
                          </button>
                        </div>
                      </div>
                      <!-- Days -->

                      <!-- Days -->
                      <div class="flex">
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            17
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            18
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            19
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            20
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            21
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            22
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            23
                          </button>
                        </div>
                      </div>
                      <!-- Days -->

                      <!-- Days -->
                      <div class="flex">
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            24
                          </button>
                        </div>
                        <div class="si6qh oxb4v dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center k6q5b l66z3 yj6bp dtjcu eass7 dafkk irakl iwtls dark:bg-green-500 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:hover:border-neutral-700">
                            25
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            26
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            27
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            28
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            29
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            30
                          </button>
                        </div>
                      </div>
                      <!-- Days -->

                      <!-- Days -->
                      <div class="flex">
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            31
                          </button>
                        </div>
                        <div class="kaxez k06lk dark:from-stone-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                            disabled="">
                            1
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                            disabled="">
                            2
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                            disabled="">
                            3
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                            disabled="">
                            4
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                            disabled="">
                            5
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                            disabled="">
                            6
                          </button>
                        </div>
                      </div>
                      <!-- Days -->
                    </div>
                    <!-- End Calendar -->

                    <!-- Calendar -->
                    <div class="bqp78 xy3is">
                      <!-- Months -->
                      <div class="jjege wi6na items-center ukt6v bs16d i38gt">
                        <!-- Prev Button -->
                        <div class="dwnnx">
                          <button type="button"
                            class="opacity-0 kbkb7 gpe5b flex pdrgo items-center j4ei7 r1hp8 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-400 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                            <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round">
                              <path d="m15 18-6-6 6-6"></path>
                            </svg>
                          </button>
                        </div>
                        <!-- End Prev Button -->

                        <!-- Month / Year -->
                        <div class="qv0l3 flex pdrgo items-center azej2">
                          <div class="relative">
                            <div class="hs-select relative"><select data-hs-select="{
                                &quot;placeholder&quot;: &quot;Select month&quot;,
                                &quot;toggleTag&quot;: &quot;<button type=\&quot;button\&quot;></button>&quot;,
                                &quot;toggleClasses&quot;: &quot;msicw n7tpi relative flex text-nowrap w-full cursor-pointer in9y1 eass7 j4ei7 romwo focus:outline-none focus:text-stone-600 kw6wr s920d bxof7 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300&quot;,
                                &quot;dropdownClasses&quot;: &quot;l2ej6 s269j pqpl9 yjsas dpnc4 xy3is f0dty l66z3 ffqht nq4w8 xrzt6 bx801 pk1vk mws46 szov1 nn2ns g3n6w dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700&quot;,
                                &quot;optionClasses&quot;: &quot;lfmff w-full dtjcu j4ei7 cursor-pointer r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800&quot;,
                                &quot;optionTemplate&quot;: &quot;<div class=\&quot;flex azaj8 items-center w-full\&quot;><span data-title></span><span class=\&quot;hidden hs-selected:block\&quot;><svg class=\&quot;wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200\&quot; xmlns=\&quot;http:.w3.org/2000/svg\&quot; width=\&quot;24\&quot; height=\&quot;24\&quot; viewBox=\&quot;0 0 24 24\&quot; fill=\&quot;none\&quot; stroke=\&quot;currentColor\&quot; stroke-width=\&quot;2\&quot; stroke-linecap=\&quot;round\&quot; stroke-linejoin=\&quot;round\&quot;><polyline points=\&quot;20 6 9 17 4 12\&quot;/></svg></span></div>&quot;
                              }" class="hidden" style="display: none;">
                                <option value="0">January</option>
                                <option value="1">February</option>
                                <option value="2">March</option>
                                <option value="3">April</option>
                                <option value="4">May</option>
                                <option value="5">June</option>
                                <option value="6" selected="">July</option>
                                <option value="7">August</option>
                                <option value="8">September</option>
                                <option value="9">October</option>
                                <option value="10">November</option>
                                <option value="11">December</option>
                              </select><button type="button"
                                class="msicw n7tpi relative flex text-nowrap w-full cursor-pointer in9y1 eass7 j4ei7 romwo focus:outline-none focus:text-stone-600 kw6wr s920d bxof7 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300"><span
                                  class="truncate">July</span></button>
                              <div
                                class="absolute top-full hidden l2ej6 s269j pqpl9 yjsas dpnc4 xy3is f0dty l66z3 ffqht nq4w8 xrzt6 bx801 pk1vk mws46 szov1 nn2ns g3n6w dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700">
                                <div data-value="0" data-title-value="January" tabindex="0"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">January</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="1" data-title-value="February" tabindex="1"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">February</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="2" data-title-value="March" tabindex="2"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">March</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="3" data-title-value="April" tabindex="3"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">April</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="4" data-title-value="May" tabindex="4"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">May</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="5" data-title-value="June" tabindex="5"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">June</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="6" data-title-value="July" tabindex="6"
                                  class="cursor-pointer selected lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">July</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="7" data-title-value="August" tabindex="7"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">August</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="8" data-title-value="September" tabindex="8"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">September</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="9" data-title-value="October" tabindex="9"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">October</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="10" data-title-value="November" tabindex="10"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">November</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="11" data-title-value="December" tabindex="11"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">December</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <span class="j4ei7 dark:text-neutral-200">/</span>

                          <div class="relative">
                            <div class="hs-select relative"><select data-hs-select="{
                                &quot;placeholder&quot;: &quot;Select year&quot;,
                                &quot;toggleTag&quot;: &quot;<button type=\&quot;button\&quot;></button>&quot;,
                                &quot;toggleClasses&quot;: &quot;msicw n7tpi relative flex text-nowrap w-full cursor-pointer in9y1 eass7 j4ei7 romwo focus:outline-none focus:text-stone-600 kw6wr s920d bxof7 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300&quot;,
                                &quot;dropdownClasses&quot;: &quot;l2ej6 s269j m9pyi yjsas dpnc4 xy3is f0dty l66z3 ffqht nq4w8 xrzt6 bx801 pk1vk mws46 szov1 nn2ns g3n6w dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700&quot;,
                                &quot;optionClasses&quot;: &quot;lfmff w-full dtjcu j4ei7 cursor-pointer r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800&quot;,
                                &quot;optionTemplate&quot;: &quot;<div class=\&quot;flex azaj8 items-center w-full\&quot;><span data-title></span><span class=\&quot;hidden hs-selected:block\&quot;><svg class=\&quot;wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200\&quot; xmlns=\&quot;http:.w3.org/2000/svg\&quot; width=\&quot;24\&quot; height=\&quot;24\&quot; viewBox=\&quot;0 0 24 24\&quot; fill=\&quot;none\&quot; stroke=\&quot;currentColor\&quot; stroke-width=\&quot;2\&quot; stroke-linecap=\&quot;round\&quot; stroke-linejoin=\&quot;round\&quot;><polyline points=\&quot;20 6 9 17 4 12\&quot;/></svg></span></div>&quot;
                              }" class="hidden" style="display: none;">
                                <option selected="">2023</option>
                                <option>2024</option>
                                <option>2025</option>
                                <option>2026</option>
                                <option>2027</option>
                              </select><button type="button"
                                class="msicw n7tpi relative flex text-nowrap w-full cursor-pointer in9y1 eass7 j4ei7 romwo focus:outline-none focus:text-stone-600 kw6wr s920d bxof7 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300"><span
                                  class="truncate">2023</span></button>
                              <div
                                class="absolute top-full hidden l2ej6 s269j m9pyi yjsas dpnc4 xy3is f0dty l66z3 ffqht nq4w8 xrzt6 bx801 pk1vk mws46 szov1 nn2ns g3n6w dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700">
                                <div data-value="2023" data-title-value="2023" tabindex="0"
                                  class="cursor-pointer selected lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">2023</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="2024" data-title-value="2024" tabindex="1"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">2024</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="2025" data-title-value="2025" tabindex="2"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">2025</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="2026" data-title-value="2026" tabindex="3"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">2026</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                                <div data-value="2027" data-title-value="2027" tabindex="4"
                                  class="cursor-pointer lfmff w-full dtjcu j4ei7 r1hp8 nq4w8 focus:outline-none focus:bg-stone-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800">
                                  <div class="flex azaj8 items-center w-full"><span data-title="">2027</span><span
                                      class="hidden hs-selected:block"><svg
                                        class="wlxy3 xtsb0 f5cx3 j4ei7 dark:text-neutral-200"
                                        xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                      </svg></span></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- End Month / Year -->

                        <!-- Next Button -->
                        <div class="dwnnx flex prqc8">
                          <button type="button"
                            class="kbkb7 gpe5b flex pdrgo items-center j4ei7 r1hp8 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-400 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                            <svg class="wlxy3 sfo8l qcpnt" width="24" height="24" viewBox="0 0 24 24" fill="none"
                              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                              <path d="m9 18 6-6-6-6"></path>
                            </svg>
                          </button>
                        </div>
                        <!-- End Next Button -->
                      </div>
                      <!-- Months -->

                      <!-- Weeks -->
                      <div class="flex cgcp3">
                        <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                          Mo
                        </span>
                        <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                          Tu
                        </span>
                        <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                          We
                        </span>
                        <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                          Th
                        </span>
                        <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                          Fr
                        </span>
                        <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                          Sa
                        </span>
                        <span class="vicgz h8i1i block zpy54 dtjcu fj6be dark:text-neutral-500">
                          Su
                        </span>
                      </div>
                      <!-- Weeks -->

                      <!-- Days -->
                      <div class="flex">
                        <div class="sefc0 k06lk dark:from-stone-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                            disabled="">
                            31
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700">
                            1
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700">
                            2
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700">
                            3
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700">
                            4
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            5
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            6
                          </button>
                        </div>
                      </div>
                      <!-- Days -->

                      <!-- Days -->
                      <div class="flex">
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            7
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            8
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            9
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            10
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            11
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            12
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            13
                          </button>
                        </div>
                      </div>
                      <!-- Days -->

                      <!-- Days -->
                      <div class="flex">
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            14
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            15
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            16
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            17
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            18
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            19
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            20
                          </button>
                        </div>
                      </div>
                      <!-- Days -->

                      <!-- Days -->
                      <div class="flex">
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            21
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            22
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            23
                          </button>
                        </div>
                        <div class="si6qh l82kd xkzee dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            24
                          </button>
                        </div>
                        <div class="si6qh ejkix dark:bg-neutral-800">
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center k6q5b l66z3 yj6bp dtjcu eass7 dafkk irakl iwtls dark:bg-green-500 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:hover:border-neutral-700">
                            25
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            26
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            27
                          </button>
                        </div>
                      </div>
                      <!-- Days -->

                      <!-- Days -->
                      <div class="flex">
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            28
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            29
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            30
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 iwtls irakl z9fa3 kko9e tk4f7 focus:outline-none focus:border-green-600 focus:text-green-600 dark:text-neutral-200">
                            31
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                            disabled="">
                            1
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                            disabled="">
                            2
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                            disabled="">
                            3
                          </button>
                        </div>
                      </div>
                      <!-- Days -->

                      <!-- Days -->
                      <div class="flex">
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                            disabled="">
                            4
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                            disabled="">
                            5
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                            disabled="">
                            6
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                            disabled="">
                            7
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                            disabled="">
                            8
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                            disabled="">
                            9
                          </button>
                        </div>
                        <div>
                          <button type="button"
                            class="vicgz h8i1i mfm8t flex pdrgo items-center l66z3 yj6bp dtjcu j4ei7 irakl z9fa3 iwtls kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:border-neutral-500 dark:focus:bg-neutral-700"
                            disabled="">
                            10
                          </button>
                        </div>
                      </div>
                      <!-- Days -->
                    </div>
                    <!-- End Calendar -->
                  </div>
                </div>
              </div>
              <!-- End Calendar Dropdown -->

              <!-- Filter Dropdown -->
              <div class="hs-dropdown relative pqrvw [--auto-close:inside] [--placement:bottom-right]">
                <!-- Filter Button -->
                <button id="hs-pro-dupfind" type="button"
                  class="xxfsd kdz1q pqrvw items-center pla0f rvi38 nq4w8 l66z3 ffqht f0dty j4ei7 tgfrq jonah kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round">
                    <line x1="21" x2="14" y1="4" y2="4"></line>
                    <line x1="10" x2="3" y1="4" y2="4"></line>
                    <line x1="21" x2="12" y1="12" y2="12"></line>
                    <line x1="8" x2="3" y1="12" y2="12"></line>
                    <line x1="21" x2="16" y1="20" y2="20"></line>
                    <line x1="12" x2="3" y1="20" y2="20"></line>
                    <line x1="14" x2="14" y1="2" y2="6"></line>
                    <line x1="8" x2="8" y1="10" y2="14"></line>
                    <line x1="16" x2="16" y1="18" y2="22"></line>
                  </svg>
                  Filter
                  <span class="eass7 qpm9v drlfu ha5qv o49ku dafkk smttb iwtls dark:bg-neutral-500">
                    7
                  </span>
                </button>
                <!-- End Filter Button -->

                <!-- Dropdown -->
                <div
                  class="hs-dropdown-menu muqsh sk3zy transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                  aria-labelledby="hs-pro-dupfind">
                  <div class="dpnc4">
                    <div
                      class="flex items-center ukt6v zdxcz grnyh cursor-pointer nq4w8 r1hp8 dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                      <input type="checkbox"
                        class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
                        id="hs-pro-dupfindch2" checked="">
                      <label for="hs-pro-dupfindch2"
                        class="flex yssyn items-center ukt6v cursor-pointer ldcb4 j4ei7 dark:text-neutral-300">
                        Order
                      </label>
                    </div>

                    <div
                      class="flex items-center ukt6v zdxcz grnyh cursor-pointer nq4w8 r1hp8 dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                      <input type="checkbox"
                        class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
                        id="hs-pro-dupfindch3" checked="">
                      <label for="hs-pro-dupfindch3"
                        class="flex yssyn items-center ukt6v cursor-pointer ldcb4 j4ei7 dark:text-neutral-300">
                        Purchased
                      </label>
                    </div>

                    <div
                      class="flex items-center ukt6v zdxcz grnyh cursor-pointer nq4w8 r1hp8 dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                      <input type="checkbox"
                        class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
                        id="hs-pro-dupfindch4" checked="">
                      <label for="hs-pro-dupfindch4"
                        class="flex yssyn items-center ukt6v cursor-pointer ldcb4 j4ei7 dark:text-neutral-300">
                        Status
                      </label>
                    </div>

                    <div
                      class="flex items-center ukt6v zdxcz grnyh cursor-pointer nq4w8 r1hp8 dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                      <input type="checkbox"
                        class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
                        id="hs-pro-dupfindch5" checked="">
                      <label for="hs-pro-dupfindch5"
                        class="flex yssyn items-center ukt6v cursor-pointer ldcb4 j4ei7 dark:text-neutral-300">
                        Customer
                      </label>
                    </div>

                    <div
                      class="flex items-center ukt6v zdxcz grnyh cursor-pointer nq4w8 r1hp8 dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                      <input type="checkbox"
                        class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
                        id="hs-pro-dupfindch6" checked="">
                      <label for="hs-pro-dupfindch6"
                        class="flex yssyn items-center ukt6v cursor-pointer ldcb4 j4ei7 dark:text-neutral-300">
                        Payment Method
                      </label>
                    </div>

                    <div
                      class="flex items-center ukt6v zdxcz grnyh cursor-pointer nq4w8 r1hp8 dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                      <input type="checkbox"
                        class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
                        id="hs-pro-dupfindch7" checked="">
                      <label for="hs-pro-dupfindch7"
                        class="flex yssyn items-center ukt6v cursor-pointer ldcb4 j4ei7 dark:text-neutral-300">
                        Payment Status
                      </label>
                    </div>

                    <div
                      class="flex items-center ukt6v zdxcz grnyh cursor-pointer nq4w8 r1hp8 dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                      <input type="checkbox"
                        class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
                        id="hs-pro-dupfindch8" checked="">
                      <label for="hs-pro-dupfindch8"
                        class="flex yssyn items-center ukt6v cursor-pointer ldcb4 j4ei7 dark:text-neutral-300">
                        Items
                      </label>
                    </div>
                  </div>
                </div>
                <!-- End Dropdown -->
              </div>
              <!-- End Filter Dropdown -->
            </div>
            <!-- End Col -->
          </div>
          <!-- End Filter Group -->

          <div>
            <!-- Tab Content -->
            <div id="hs-pro-tabs-dut-all" role="tabpanel" aria-labelledby="hs-pro-tabs-dut-item-all" class="">
              <!-- Table Section -->
              <div
                class="fmrhk cs0un szov1 nn2ns g3n6w dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500">
                <div class="si0ns uiqm5 ja4wp">
                  <!-- Table -->
                  <table class="si0ns phe4g igot0 dark:divide-neutral-700">
                    <thead>
                      <tr class="lz37y ffqht dark:border-neutral-700">
                        <th scope="col" class="vrf2y j9jou in9y1">
                          <input type="checkbox"
                            class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800">
                        </th>

                        <th scope="col">
                          <!-- Sort Dropdown -->
                          <div class="hs-dropdown relative pqrvw w-full cursor-pointer">
                            <button id="hs-pro-dutnms" type="button"
                              class="pgrdw j9jou in9y1 w-full flex items-center azej2 rvi38 ouifk qfc33 fj6be focus:outline-none focus:bg-stone-100 dark:text-neutral-500 dark:focus:bg-neutral-700">
                              Order
                              <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path d="m7 15 5 5 5-5"></path>
                                <path d="m7 9 5-5 5 5"></path>
                              </svg>
                            </button>

                            <!-- Dropdown -->
                            <div
                              class="hs-dropdown-menu muqsh ccpye transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                              aria-labelledby="hs-pro-dutnms">
                              <div class="dpnc4">
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="m5 12 7-7 7 7"></path>
                                    <path d="M12 19V5"></path>
                                  </svg>
                                  Sort ascending
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M12 5v14"></path>
                                    <path d="m19 12-7 7-7-7"></path>
                                  </svg>
                                  Sort descending
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="m12 19-7-7 7-7"></path>
                                    <path d="M19 12H5"></path>
                                  </svg>
                                  Move left
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M5 12h14"></path>
                                    <path d="m12 5 7 7-7 7"></path>
                                  </svg>
                                  Move right
                                </button>

                                <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                    <path
                                      d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68">
                                    </path>
                                    <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61">
                                    </path>
                                    <line x1="2" x2="22" y1="2" y2="22"></line>
                                  </svg>
                                  Hide in view
                                </button>
                              </div>
                            </div>
                            <!-- End Dropdown -->
                          </div>
                          <!-- End Sort Dropdown -->
                        </th>

                        <th scope="col" class="k4w96">
                          <!-- Sort Dropdown -->
                          <div class="hs-dropdown relative pqrvw w-full cursor-pointer">
                            <button id="hs-pro-dutsus" type="button"
                              class="pgrdw j9jou in9y1 w-full flex items-center azej2 rvi38 ouifk qfc33 fj6be focus:outline-none focus:bg-stone-100 dark:text-neutral-500 dark:focus:bg-neutral-700">
                              Purchased
                              <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path d="m7 15 5 5 5-5"></path>
                                <path d="m7 9 5-5 5 5"></path>
                              </svg>
                            </button>

                            <!-- Dropdown -->
                            <div
                              class="hs-dropdown-menu muqsh ccpye transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                              aria-labelledby="hs-pro-dutsus">
                              <div class="dpnc4">
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="m5 12 7-7 7 7"></path>
                                    <path d="M12 19V5"></path>
                                  </svg>
                                  Sort ascending
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M12 5v14"></path>
                                    <path d="m19 12-7 7-7-7"></path>
                                  </svg>
                                  Sort descending
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="m12 19-7-7 7-7"></path>
                                    <path d="M19 12H5"></path>
                                  </svg>
                                  Move left
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M5 12h14"></path>
                                    <path d="m12 5 7 7-7 7"></path>
                                  </svg>
                                  Move right
                                </button>

                                <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                    <path
                                      d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68">
                                    </path>
                                    <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61">
                                    </path>
                                    <line x1="2" x2="22" y1="2" y2="22"></line>
                                  </svg>
                                  Hide in view
                                </button>
                              </div>
                            </div>
                            <!-- End Dropdown -->
                          </div>
                          <!-- End Sort Dropdown -->
                        </th>

                        <th scope="col" class="pyzhq">
                          <!-- Sort Dropdown -->
                          <div class="hs-dropdown relative pqrvw w-full cursor-pointer">
                            <button id="hs-pro-dutads" type="button"
                              class="pgrdw j9jou in9y1 w-full flex items-center azej2 rvi38 ouifk qfc33 fj6be focus:outline-none focus:bg-stone-100 dark:text-neutral-500 dark:focus:bg-neutral-700">
                              Status
                              <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path d="m7 15 5 5 5-5"></path>
                                <path d="m7 9 5-5 5 5"></path>
                              </svg>
                            </button>

                            <!-- Dropdown -->
                            <div
                              class="hs-dropdown-menu muqsh ccpye transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                              aria-labelledby="hs-pro-dutads">
                              <div class="dpnc4">
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="m5 12 7-7 7 7"></path>
                                    <path d="M12 19V5"></path>
                                  </svg>
                                  Sort ascending
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M12 5v14"></path>
                                    <path d="m19 12-7 7-7-7"></path>
                                  </svg>
                                  Sort descending
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="m12 19-7-7 7-7"></path>
                                    <path d="M19 12H5"></path>
                                  </svg>
                                  Move left
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M5 12h14"></path>
                                    <path d="m12 5 7 7-7 7"></path>
                                  </svg>
                                  Move right
                                </button>

                                <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                    <path
                                      d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68">
                                    </path>
                                    <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61">
                                    </path>
                                    <line x1="2" x2="22" y1="2" y2="22"></line>
                                  </svg>
                                  Hide in view
                                </button>
                              </div>
                            </div>
                            <!-- End Dropdown -->
                          </div>
                          <!-- End Sort Dropdown -->
                        </th>

                        <th scope="col" class="p90ec">
                          <!-- Sort Dropdown -->
                          <div class="hs-dropdown relative pqrvw w-full cursor-pointer">
                            <button id="hs-pro-dutphs" type="button"
                              class="pgrdw j9jou in9y1 w-full flex items-center azej2 rvi38 ouifk qfc33 fj6be focus:outline-none focus:bg-stone-100 dark:text-neutral-500 dark:focus:bg-neutral-700">
                              Customer
                              <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path d="m7 15 5 5 5-5"></path>
                                <path d="m7 9 5-5 5 5"></path>
                              </svg>
                            </button>

                            <!-- Dropdown -->
                            <div
                              class="hs-dropdown-menu muqsh ccpye transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                              aria-labelledby="hs-pro-dutphs">
                              <div class="dpnc4">
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="m5 12 7-7 7 7"></path>
                                    <path d="M12 19V5"></path>
                                  </svg>
                                  Sort ascending
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M12 5v14"></path>
                                    <path d="m19 12-7 7-7-7"></path>
                                  </svg>
                                  Sort descending
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="m12 19-7-7 7-7"></path>
                                    <path d="M19 12H5"></path>
                                  </svg>
                                  Move left
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M5 12h14"></path>
                                    <path d="m12 5 7 7-7 7"></path>
                                  </svg>
                                  Move right
                                </button>

                                <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                    <path
                                      d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68">
                                    </path>
                                    <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61">
                                    </path>
                                    <line x1="2" x2="22" y1="2" y2="22"></line>
                                  </svg>
                                  Hide in view
                                </button>
                              </div>
                            </div>
                            <!-- End Dropdown -->
                          </div>
                          <!-- End Sort Dropdown -->
                        </th>

                        <th scope="col" class="c6hd9">
                          <!-- Sort Dropdown -->
                          <div class="hs-dropdown relative pqrvw cursor-pointer">
                            <button id="hs-pro-dutemaird" type="button"
                              class="pgrdw j9jou in9y1 w-full flex items-center azej2 rvi38 ouifk qfc33 fj6be focus:outline-none focus:bg-stone-100 dark:text-neutral-500 dark:focus:bg-neutral-700">
                              Payment method
                              <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path d="m7 15 5 5 5-5"></path>
                                <path d="m7 9 5-5 5 5"></path>
                              </svg>
                            </button>

                            <!-- Dropdown -->
                            <div
                              class="hs-dropdown-menu muqsh ccpye transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                              aria-labelledby="hs-pro-dutemaird">
                              <div class="dpnc4">
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="m5 12 7-7 7 7"></path>
                                    <path d="M12 19V5"></path>
                                  </svg>
                                  Sort ascending
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M12 5v14"></path>
                                    <path d="m19 12-7 7-7-7"></path>
                                  </svg>
                                  Sort descending
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="m12 19-7-7 7-7"></path>
                                    <path d="M19 12H5"></path>
                                  </svg>
                                  Move left
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M5 12h14"></path>
                                    <path d="m12 5 7 7-7 7"></path>
                                  </svg>
                                  Move right
                                </button>

                                <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                    <path
                                      d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68">
                                    </path>
                                    <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61">
                                    </path>
                                    <line x1="2" x2="22" y1="2" y2="22"></line>
                                  </svg>
                                  Hide in view
                                </button>
                              </div>
                            </div>
                            <!-- End Dropdown -->
                          </div>
                          <!-- End Sort Dropdown -->
                        </th>

                        <th scope="col" class="jxrrg">
                          <!-- Sort Dropdown -->
                          <div class="hs-dropdown relative pqrvw w-full cursor-pointer">
                            <button id="hs-pro-dutemails" type="button"
                              class="pgrdw j9jou in9y1 w-full flex items-center azej2 rvi38 ouifk qfc33 fj6be focus:outline-none focus:bg-stone-100 dark:text-neutral-500 dark:focus:bg-neutral-700">
                              Payment status
                              <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path d="m7 15 5 5 5-5"></path>
                                <path d="m7 9 5-5 5 5"></path>
                              </svg>
                            </button>

                            <!-- Dropdown -->
                            <div
                              class="hs-dropdown-menu muqsh ccpye transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                              aria-labelledby="hs-pro-dutemails">
                              <div class="dpnc4">
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="m5 12 7-7 7 7"></path>
                                    <path d="M12 19V5"></path>
                                  </svg>
                                  Sort ascending
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M12 5v14"></path>
                                    <path d="m19 12-7 7-7-7"></path>
                                  </svg>
                                  Sort descending
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="m12 19-7-7 7-7"></path>
                                    <path d="M19 12H5"></path>
                                  </svg>
                                  Move left
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M5 12h14"></path>
                                    <path d="m12 5 7 7-7 7"></path>
                                  </svg>
                                  Move right
                                </button>

                                <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                    <path
                                      d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68">
                                    </path>
                                    <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61">
                                    </path>
                                    <line x1="2" x2="22" y1="2" y2="22"></line>
                                  </svg>
                                  Hide in view
                                </button>
                              </div>
                            </div>
                            <!-- End Dropdown -->
                          </div>
                          <!-- End Sort Dropdown -->
                        </th>

                        <th scope="col">
                          <!-- Sort Dropdown -->
                          <div class="hs-dropdown relative pqrvw w-full cursor-pointer">
                            <button id="hs-pro-duteitems" type="button"
                              class="pgrdw j9jou in9y1 w-full flex items-center azej2 rvi38 ouifk qfc33 fj6be focus:outline-none focus:bg-stone-100 dark:text-neutral-500 dark:focus:bg-neutral-700">
                              Items
                              <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path d="m7 15 5 5 5-5"></path>
                                <path d="m7 9 5-5 5 5"></path>
                              </svg>
                            </button>

                            <!-- Dropdown -->
                            <div
                              class="hs-dropdown-menu muqsh ccpye transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                              aria-labelledby="hs-pro-duteitems">
                              <div class="dpnc4">
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="m5 12 7-7 7 7"></path>
                                    <path d="M12 19V5"></path>
                                  </svg>
                                  Sort ascending
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M12 5v14"></path>
                                    <path d="m19 12-7 7-7-7"></path>
                                  </svg>
                                  Sort descending
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="m12 19-7-7 7-7"></path>
                                    <path d="M19 12H5"></path>
                                  </svg>
                                  Move left
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M5 12h14"></path>
                                    <path d="m12 5 7 7-7 7"></path>
                                  </svg>
                                  Move right
                                </button>

                                <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 qfc33 j4ei7 r1hp8 kko9e tk4f7 dark:text-neutral-300 focus:outline-none focus:bg-stone-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                    <path
                                      d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68">
                                    </path>
                                    <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61">
                                    </path>
                                    <line x1="2" x2="22" y1="2" y2="22"></line>
                                  </svg>
                                  Hide in view
                                </button>
                              </div>
                            </div>
                            <!-- End Dropdown -->
                          </div>
                          <!-- End Sort Dropdown -->
                        </th>

                        <th scope="col"></th>
                      </tr>
                    </thead>

                    <tbody class="phe4g igot0 dark:divide-neutral-700">
                      <tr>
                        <td class="o65eo lnq7o mfkro vrf2y tu5ez">
                          <input type="checkbox"
                            class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800">
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <a class="dtjcu mq8xz cmffi cdcib eass7 focus:outline-none focus:underline dark:text-green-400 dark:hover:text-green-500"
                            href="../../pro/ecommerce/order-details.html">
                            #235325
                          </a>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            Calvin Klein T-shirts
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 zsgcl z7i7w iwtls dark:bg-green-500/10 dark:text-green-500">
                            <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round">
                              <circle cx="12" cy="12" r="10"></circle>
                              <polyline points="12 6 12 12 16 14"></polyline>
                            </svg>
                            Ready for pickup
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            Jase Marley
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="pqrvw items-center azej2 dtjcu v474b dark:text-neutral-400">
                            <svg class="wlxy3 sfo8l qcpnt" width="32" height="20" viewBox="0 0 32 20" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_1704_233ade21312)">
                                <mask id="qadff23" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0"
                                  width="32" height="20">
                                  <path d="M32 0H0V19.776H32V0Z" fill="white"></path>
                                </mask>
                                <g mask="url(#qadff23)">
                                  <path d="M20.32 17.6641H11.68V2.11206H20.32V17.6641Z" fill="#FF5F00"></path>
                                  <path
                                    d="M12.224 9.888C12.224 6.736 13.696 3.92 16 2.112C14.256 0.736 12.112 0 9.888 0C4.432 0 0 4.432 0 9.888C0 15.344 4.432 19.776 9.888 19.776C12.112 19.776 14.256 19.04 16 17.664C13.696 15.856 12.224 13.04 12.224 9.888Z"
                                    fill="#EB001B"></path>
                                  <path
                                    d="M32 9.888C32 15.344 27.568 19.776 22.112 19.776C19.888 19.776 17.744 19.04 16 17.664C18.304 15.856 19.776 13.04 19.776 9.888C19.776 6.736 18.304 3.92 16 2.112C17.744 0.736 19.888 0 22.112 0C27.568 0 32 4.432 32 9.888Z"
                                    fill="#F79E1B"></path>
                                </g>
                              </g>
                              <defs>
                                <clipPath id="clip0_1704_233ade21312">
                                  <rect width="32" height="19.84" fill="white"></rect>
                                </clipPath>
                              </defs>
                            </svg>
                            **** 1898
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 si6qh j4ei7 iwtls dark:bg-neutral-700 dark:text-neutral-200">
                            <span class="phtd0 r4wnm uiqm5 o49ku iwtls dark:bg-neutral-200"></span>
                            Paid
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            2
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1 k08z1">
                          <div class="hs-dropdown relative pqrvw [--auto-close:inside] [--placement:bottom-right]">
                            <button id="hs-pro-errtmd1" type="button"
                              class="cslsl uunzg pqrvw pdrgo items-center dqqs4 nq4w8 l66z3 ffqht f0dty j4ei7 tgfrq jonah kko9e tk4f7 focus:outline-none focus:bg-stone-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                              <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <circle cx="12" cy="12" r="1"></circle>
                                <circle cx="12" cy="5" r="1"></circle>
                                <circle cx="12" cy="19" r="1"></circle>
                              </svg>
                            </button>

                            <div
                              class="hs-dropdown-menu muqsh c974i transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                              aria-labelledby="hs-pro-errtmd1">
                              <div class="dpnc4">
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Edite
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Duplicate
                                </button>

                                <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td class="o65eo lnq7o mfkro vrf2y tu5ez">
                          <input type="checkbox"
                            class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800">
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <a class="dtjcu mq8xz cmffi cdcib eass7 focus:outline-none focus:underline dark:text-green-400 dark:hover:text-green-500"
                            href="../../pro/ecommerce/order-details.html">
                            #646344
                          </a>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            Maroon Wedges
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 si6qh j4ei7 iwtls dark:bg-neutral-700 dark:text-neutral-200">
                            <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round">
                              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"></path>
                              <path d="m9 12 2 2 4-4"></path>
                            </svg>
                            Fulfilled
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            Mathew Gustaffson
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="pqrvw items-center azej2 dtjcu v474b dark:text-neutral-400">
                            <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round">
                              <line x1="3" x2="21" y1="22" y2="22"></line>
                              <line x1="6" x2="6" y1="18" y2="11"></line>
                              <line x1="10" x2="10" y1="18" y2="11"></line>
                              <line x1="14" x2="14" y1="18" y2="11"></line>
                              <line x1="18" x2="18" y1="18" y2="11"></line>
                              <polygon points="12 2 20 7 4 7"></polygon>
                            </svg>
                            **** 5238
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 si6qh j4ei7 iwtls dark:bg-neutral-700 dark:text-neutral-200">
                            <span class="phtd0 r4wnm uiqm5 o49ku iwtls dark:bg-neutral-200"></span>
                            Paid
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            1
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1 k08z1">
                          <div class="hs-dropdown relative pqrvw [--auto-close:inside] [--placement:bottom-right]">
                            <button id="hs-pro-errtmd2" type="button"
                              class="cslsl uunzg pqrvw pdrgo items-center dqqs4 nq4w8 l66z3 ffqht f0dty j4ei7 tgfrq jonah kko9e tk4f7 focus:outline-none focus:bg-stone-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                              <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <circle cx="12" cy="12" r="1"></circle>
                                <circle cx="12" cy="5" r="1"></circle>
                                <circle cx="12" cy="19" r="1"></circle>
                              </svg>
                            </button>

                            <div
                              class="hs-dropdown-menu muqsh c974i transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                              aria-labelledby="hs-pro-errtmd2">
                              <div class="dpnc4">
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Edite
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Duplicate
                                </button>

                                <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td class="o65eo lnq7o mfkro vrf2y tu5ez">
                          <input type="checkbox"
                            class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800">
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <a class="dtjcu mq8xz cmffi cdcib eass7 focus:outline-none focus:underline dark:text-green-400 dark:hover:text-green-500"
                            href="../../pro/ecommerce/order-details.html">
                            #547432
                          </a>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            Maroon Wedges
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 si6qh j4ei7 iwtls dark:bg-neutral-700 dark:text-neutral-200">
                            <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round">
                              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"></path>
                              <path d="m9 12 2 2 4-4"></path>
                            </svg>
                            Fulfilled
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            Mathew Gustaffson
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="pqrvw items-center azej2 dtjcu v474b dark:text-neutral-400">
                            <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round">
                              <line x1="3" x2="21" y1="22" y2="22"></line>
                              <line x1="6" x2="6" y1="18" y2="11"></line>
                              <line x1="10" x2="10" y1="18" y2="11"></line>
                              <line x1="14" x2="14" y1="18" y2="11"></line>
                              <line x1="18" x2="18" y1="18" y2="11"></line>
                              <polygon points="12 2 20 7 4 7"></polygon>
                            </svg>
                            **** 8542
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 wuk4n midc9 iwtls dark:bg-yellow-500/10 dark:text-yellow-500">
                            <span class="phtd0 r4wnm uiqm5 bbzv3 iwtls dark:bg-yellow-500"></span>
                            Pending
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            5
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1 k08z1">
                          <div class="hs-dropdown relative pqrvw [--auto-close:inside] [--placement:bottom-right]">
                            <button id="hs-pro-errtmd3" type="button"
                              class="cslsl uunzg pqrvw pdrgo items-center dqqs4 nq4w8 l66z3 ffqht f0dty j4ei7 tgfrq jonah kko9e tk4f7 focus:outline-none focus:bg-stone-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                              <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <circle cx="12" cy="12" r="1"></circle>
                                <circle cx="12" cy="5" r="1"></circle>
                                <circle cx="12" cy="19" r="1"></circle>
                              </svg>
                            </button>

                            <div
                              class="hs-dropdown-menu muqsh c974i transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                              aria-labelledby="hs-pro-errtmd3">
                              <div class="dpnc4">
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Edite
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Duplicate
                                </button>

                                <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td class="o65eo lnq7o mfkro vrf2y tu5ez">
                          <input type="checkbox"
                            class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800">
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <a class="dtjcu mq8xz cmffi cdcib eass7 focus:outline-none focus:underline dark:text-green-400 dark:hover:text-green-500"
                            href="../../pro/ecommerce/order-details.html">
                            #624363
                          </a>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            Pattern Winter Sweater
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 si6qh j4ei7 iwtls dark:bg-neutral-700 dark:text-neutral-200">
                            <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round">
                              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"></path>
                              <path d="m9 12 2 2 4-4"></path>
                            </svg>
                            Fulfilled
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            Nicky Olvsson
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="pqrvw items-center azej2 dtjcu v474b dark:text-neutral-400">
                            <svg class="wlxy3 sfo8l qcpnt" width="33" height="32" viewBox="0 0 33 32" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M10.6424 30.9152L11.197 27.3925L9.96162 27.3639H4.06256L8.16211 1.37005C8.17431 1.29063 8.21465 1.21823 8.27577 1.16607C8.33689 1.1139 8.41472 1.08543 8.49508 1.08586H18.4417C21.7438 1.08586 24.0227 1.773 25.2124 3.12927C25.7702 3.76551 26.1255 4.43039 26.2972 5.16208C26.4775 5.92981 26.4807 6.84707 26.3047 7.9658L26.2919 8.04745V8.76429L26.8497 9.08029C27.2755 9.29602 27.6583 9.58784 27.979 9.94134C28.4562 10.4853 28.7648 11.1767 28.8952 11.9964C29.0299 12.8394 28.9854 13.8426 28.7648 14.9783C28.5103 16.2847 28.0989 17.4225 27.5432 18.3536C27.053 19.1876 26.3938 19.91 25.608 20.4744C24.8699 20.9982 23.993 21.3959 23.0015 21.6504C22.0408 21.9007 20.9454 22.0268 19.7439 22.0268H18.9698C18.4163 22.0268 17.8786 22.2262 17.4566 22.5836C17.0352 22.9447 16.7556 23.4438 16.6677 23.9918L16.6093 24.3088L15.6295 30.5175L15.585 30.7455C15.5733 30.8176 15.5532 30.8537 15.5235 30.8781C15.4947 30.9016 15.4588 30.9147 15.4217 30.9152H10.6424Z"
                                fill="#253B80"></path>
                              <path
                                d="M27.3778 8.13017C27.3481 8.31998 27.3142 8.51403 27.276 8.71339C25.9643 15.4481 21.4766 17.7746 15.7451 17.7746H12.8268C12.1259 17.7746 11.5352 18.2836 11.426 18.975L9.93191 28.4508L9.5088 31.1368C9.49195 31.2434 9.49838 31.3523 9.52767 31.4561C9.55695 31.5599 9.60838 31.6562 9.67842 31.7382C9.74846 31.8202 9.83545 31.8861 9.9334 31.9313C10.0313 31.9765 10.1379 31.9999 10.2458 32H15.4217C16.0346 32 16.5552 31.5546 16.6517 30.9502L16.7026 30.6872L17.6771 24.5029L17.7397 24.1636C17.8351 23.557 18.3569 23.1116 18.9698 23.1116H19.7439C24.7586 23.1116 28.6842 21.0757 29.8316 15.184C30.3109 12.7228 30.0627 10.6677 28.7945 9.22239C28.3925 8.77528 27.9124 8.40516 27.3778 8.13017Z"
                                fill="#179BD7"></path>
                              <path
                                d="M26.0056 7.58299C25.5868 7.46183 25.1607 7.36727 24.7299 7.29986C23.8788 7.16905 23.0185 7.10629 22.1574 7.11217H14.3612C14.0645 7.11194 13.7776 7.21787 13.5521 7.41079C13.3267 7.60372 13.1778 7.87092 13.1322 8.1641L11.4737 18.6685L11.426 18.975C11.4777 18.6406 11.6473 18.3357 11.9043 18.1155C12.1612 17.8954 12.4885 17.7744 12.8268 17.7746H15.7451C21.4766 17.7746 25.9642 15.447 27.276 8.71339C27.3152 8.51403 27.3481 8.31998 27.3778 8.13017C27.0316 7.94865 26.6709 7.79648 26.2993 7.67525C26.2019 7.64293 26.104 7.61217 26.0056 7.58299Z"
                                fill="#222D65"></path>
                              <path
                                d="M13.1321 8.1641C13.1773 7.87084 13.3262 7.60351 13.5517 7.41068C13.7772 7.21785 14.0644 7.11231 14.3611 7.11323H22.1573C23.0809 7.11323 23.943 7.17368 24.7298 7.30092C25.2623 7.3846 25.7874 7.50976 26.3003 7.67525C26.6874 7.80356 27.0468 7.9552 27.3787 8.13017C27.769 5.64138 27.3756 3.94685 26.0299 2.41243C24.5464 0.7232 21.8688 0 18.4427 0H8.49601C7.79614 0 7.19913 0.508997 7.09097 1.20144L2.94795 27.4625C2.92865 27.5844 2.93599 27.7091 2.96947 27.8279C3.00294 27.9467 3.06176 28.0569 3.14187 28.1508C3.22198 28.2447 3.32149 28.3202 3.43354 28.3719C3.54559 28.4237 3.66753 28.4506 3.79097 28.4508H9.93181L11.4736 18.6685L13.1321 8.1641Z"
                                fill="#253B80"></path>
                            </svg>
                            **** @site.so
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 si6qh j4ei7 iwtls dark:bg-neutral-700 dark:text-neutral-200">
                            <span class="phtd0 r4wnm uiqm5 o49ku iwtls dark:bg-neutral-200"></span>
                            Paid
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            2
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1 k08z1">
                          <div class="hs-dropdown relative pqrvw [--auto-close:inside] [--placement:bottom-right]">
                            <button id="hs-pro-errtmd4" type="button"
                              class="cslsl uunzg pqrvw pdrgo items-center dqqs4 nq4w8 l66z3 ffqht f0dty j4ei7 tgfrq jonah kko9e tk4f7 focus:outline-none focus:bg-stone-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                              <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <circle cx="12" cy="12" r="1"></circle>
                                <circle cx="12" cy="5" r="1"></circle>
                                <circle cx="12" cy="19" r="1"></circle>
                              </svg>
                            </button>

                            <div
                              class="hs-dropdown-menu muqsh c974i transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                              aria-labelledby="hs-pro-errtmd4">
                              <div class="dpnc4">
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Edite
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Duplicate
                                </button>

                                <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td class="o65eo lnq7o mfkro vrf2y tu5ez">
                          <input type="checkbox"
                            class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800">
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <a class="dtjcu mq8xz cmffi cdcib eass7 focus:outline-none focus:underline dark:text-green-400 dark:hover:text-green-500"
                            href="../../pro/ecommerce/order-details.html">
                            #989011
                          </a>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            White Blazer by Armani
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 sgwuf sh246 iwtls dark:bg-red-500/10 dark:text-red-500">
                            <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round">
                              <circle cx="12" cy="12" r="10"></circle>
                              <path d="m15 9-6 6"></path>
                              <path d="m9 9 6 6"></path>
                            </svg>
                            Unfulfilled
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            David Nunez
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="pqrvw items-center azej2 dtjcu v474b dark:text-neutral-400">
                            <svg class="wlxy3 sfo8l qcpnt" width="32" height="20" viewBox="0 0 32 20" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_1704_2332e3n1dl23)">
                                <mask id="213r1r31112" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0"
                                  width="32" height="20">
                                  <path d="M32 0H0V19.776H32V0Z" fill="white"></path>
                                </mask>
                                <g mask="url(#213r1r31112)">
                                  <path d="M20.32 17.6641H11.68V2.11206H20.32V17.6641Z" fill="#FF5F00"></path>
                                  <path
                                    d="M12.224 9.888C12.224 6.736 13.696 3.92 16 2.112C14.256 0.736 12.112 0 9.888 0C4.432 0 0 4.432 0 9.888C0 15.344 4.432 19.776 9.888 19.776C12.112 19.776 14.256 19.04 16 17.664C13.696 15.856 12.224 13.04 12.224 9.888Z"
                                    fill="#EB001B"></path>
                                  <path
                                    d="M32 9.888C32 15.344 27.568 19.776 22.112 19.776C19.888 19.776 17.744 19.04 16 17.664C18.304 15.856 19.776 13.04 19.776 9.888C19.776 6.736 18.304 3.92 16 2.112C17.744 0.736 19.888 0 22.112 0C27.568 0 32 4.432 32 9.888Z"
                                    fill="#F79E1B"></path>
                                </g>
                              </g>
                              <defs>
                                <clipPath id="clip0_1704_2332e3n1dl23">
                                  <rect width="32" height="19.84" fill="white"></rect>
                                </clipPath>
                              </defs>
                            </svg>
                            **** 1284
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 wuk4n midc9 iwtls dark:bg-yellow-500/10 dark:text-yellow-500">
                            <span class="phtd0 r4wnm uiqm5 bbzv3 iwtls dark:bg-yellow-500"></span>
                            Pending
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            1
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1 k08z1">
                          <div class="hs-dropdown relative pqrvw [--auto-close:inside] [--placement:bottom-right]">
                            <button id="hs-pro-errtmd5" type="button"
                              class="cslsl uunzg pqrvw pdrgo items-center dqqs4 nq4w8 l66z3 ffqht f0dty j4ei7 tgfrq jonah kko9e tk4f7 focus:outline-none focus:bg-stone-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                              <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <circle cx="12" cy="12" r="1"></circle>
                                <circle cx="12" cy="5" r="1"></circle>
                                <circle cx="12" cy="19" r="1"></circle>
                              </svg>
                            </button>

                            <div
                              class="hs-dropdown-menu muqsh c974i transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                              aria-labelledby="hs-pro-errtmd5">
                              <div class="dpnc4">
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Edite
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Duplicate
                                </button>

                                <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td class="o65eo lnq7o mfkro vrf2y tu5ez">
                          <input type="checkbox"
                            class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800">
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <a class="dtjcu mq8xz cmffi cdcib eass7 focus:outline-none focus:underline dark:text-green-400 dark:hover:text-green-500"
                            href="../../pro/ecommerce/order-details.html">
                            #783109
                          </a>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            Watch
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 si6qh j4ei7 iwtls dark:bg-neutral-700 dark:text-neutral-200">
                            <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round">
                              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"></path>
                              <path d="m9 12 2 2 4-4"></path>
                            </svg>
                            Fulfilled
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            Brian Jackson
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="pqrvw items-center azej2 dtjcu v474b dark:text-neutral-400">
                            <svg class="wlxy3 sfo8l qcpnt" width="32" height="20" viewBox="0 0 32 20" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_1704_233qe12)">
                                <mask id="mask0_1704_2332fdkm12" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                  x="0" y="0" width="32" height="20">
                                  <path d="M32 0H0V19.776H32V0Z" fill="white"></path>
                                </mask>
                                <g mask="url(#mask0_1704_2332fdkm12)">
                                  <path d="M20.32 17.6641H11.68V2.11206H20.32V17.6641Z" fill="#FF5F00"></path>
                                  <path
                                    d="M12.224 9.888C12.224 6.736 13.696 3.92 16 2.112C14.256 0.736 12.112 0 9.888 0C4.432 0 0 4.432 0 9.888C0 15.344 4.432 19.776 9.888 19.776C12.112 19.776 14.256 19.04 16 17.664C13.696 15.856 12.224 13.04 12.224 9.888Z"
                                    fill="#EB001B"></path>
                                  <path
                                    d="M32 9.888C32 15.344 27.568 19.776 22.112 19.776C19.888 19.776 17.744 19.04 16 17.664C18.304 15.856 19.776 13.04 19.776 9.888C19.776 6.736 18.304 3.92 16 2.112C17.744 0.736 19.888 0 22.112 0C27.568 0 32 4.432 32 9.888Z"
                                    fill="#F79E1B"></path>
                                </g>
                              </g>
                              <defs>
                                <clipPath id="clip0_1704_233qe12">
                                  <rect width="32" height="19.84" fill="white"></rect>
                                </clipPath>
                              </defs>
                            </svg>
                            **** 5522
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 si6qh j4ei7 iwtls dark:bg-neutral-700 dark:text-neutral-200">
                            <span class="phtd0 r4wnm uiqm5 o49ku iwtls dark:bg-neutral-200"></span>
                            Partially refunded
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            1
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1 k08z1">
                          <div class="hs-dropdown relative pqrvw [--auto-close:inside] [--placement:bottom-right]">
                            <button id="hs-pro-errtmd6" type="button"
                              class="cslsl uunzg pqrvw pdrgo items-center dqqs4 nq4w8 l66z3 ffqht f0dty j4ei7 tgfrq jonah kko9e tk4f7 focus:outline-none focus:bg-stone-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                              <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <circle cx="12" cy="12" r="1"></circle>
                                <circle cx="12" cy="5" r="1"></circle>
                                <circle cx="12" cy="19" r="1"></circle>
                              </svg>
                            </button>

                            <div
                              class="hs-dropdown-menu muqsh c974i transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                              aria-labelledby="hs-pro-errtmd6">
                              <div class="dpnc4">
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Edite
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Duplicate
                                </button>

                                <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td class="o65eo lnq7o mfkro vrf2y tu5ez">
                          <input type="checkbox"
                            class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800">
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <a class="dtjcu mq8xz cmffi cdcib eass7 focus:outline-none focus:underline dark:text-green-400 dark:hover:text-green-500"
                            href="../../pro/ecommerce/order-details.html">
                            #823904
                          </a>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            Keyboard Matt
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 zsgcl z7i7w iwtls dark:bg-green-500/10 dark:text-green-500">
                            <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round">
                              <circle cx="12" cy="12" r="10"></circle>
                              <polyline points="12 6 12 12 16 14"></polyline>
                            </svg>
                            Ready for pickup
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            Jacky Ferguson
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="pqrvw items-center azej2 dtjcu v474b dark:text-neutral-400">
                            <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round">
                              <line x1="3" x2="21" y1="22" y2="22"></line>
                              <line x1="6" x2="6" y1="18" y2="11"></line>
                              <line x1="10" x2="10" y1="18" y2="11"></line>
                              <line x1="14" x2="14" y1="18" y2="11"></line>
                              <line x1="18" x2="18" y1="18" y2="11"></line>
                              <polygon points="12 2 20 7 4 7"></polygon>
                            </svg>
                            **** 9832
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 wuk4n midc9 iwtls dark:bg-yellow-500/10 dark:text-yellow-500">
                            <span class="phtd0 r4wnm uiqm5 bbzv3 iwtls dark:bg-yellow-500"></span>
                            Pending
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            9
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1 k08z1">
                          <div class="hs-dropdown relative pqrvw [--auto-close:inside] [--placement:bottom-right]">
                            <button id="hs-pro-errtmd7" type="button"
                              class="cslsl uunzg pqrvw pdrgo items-center dqqs4 nq4w8 l66z3 ffqht f0dty j4ei7 tgfrq jonah kko9e tk4f7 focus:outline-none focus:bg-stone-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                              <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <circle cx="12" cy="12" r="1"></circle>
                                <circle cx="12" cy="5" r="1"></circle>
                                <circle cx="12" cy="19" r="1"></circle>
                              </svg>
                            </button>

                            <div
                              class="hs-dropdown-menu muqsh c974i transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                              aria-labelledby="hs-pro-errtmd7">
                              <div class="dpnc4">
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Edite
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Duplicate
                                </button>

                                <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td class="o65eo lnq7o mfkro vrf2y tu5ez">
                          <input type="checkbox"
                            class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800">
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <a class="dtjcu mq8xz cmffi cdcib eass7 focus:outline-none focus:underline dark:text-green-400 dark:hover:text-green-500"
                            href="../../pro/ecommerce/order-details.html">
                            #490454
                          </a>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            Keyboard Matt
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 zsgcl z7i7w iwtls dark:bg-green-500/10 dark:text-green-500">
                            <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round">
                              <circle cx="12" cy="12" r="10"></circle>
                              <polyline points="12 6 12 12 16 14"></polyline>
                            </svg>
                            Ready for pickup
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            Karla Verdy
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="pqrvw items-center azej2 dtjcu v474b dark:text-neutral-400">
                            <svg class="wlxy3 sfo8l qcpnt" width="33" height="32" viewBox="0 0 33 32" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M10.6424 30.9152L11.197 27.3925L9.96162 27.3639H4.06256L8.16211 1.37005C8.17431 1.29063 8.21465 1.21823 8.27577 1.16607C8.33689 1.1139 8.41472 1.08543 8.49508 1.08586H18.4417C21.7438 1.08586 24.0227 1.773 25.2124 3.12927C25.7702 3.76551 26.1255 4.43039 26.2972 5.16208C26.4775 5.92981 26.4807 6.84707 26.3047 7.9658L26.2919 8.04745V8.76429L26.8497 9.08029C27.2755 9.29602 27.6583 9.58784 27.979 9.94134C28.4562 10.4853 28.7648 11.1767 28.8952 11.9964C29.0299 12.8394 28.9854 13.8426 28.7648 14.9783C28.5103 16.2847 28.0989 17.4225 27.5432 18.3536C27.053 19.1876 26.3938 19.91 25.608 20.4744C24.8699 20.9982 23.993 21.3959 23.0015 21.6504C22.0408 21.9007 20.9454 22.0268 19.7439 22.0268H18.9698C18.4163 22.0268 17.8786 22.2262 17.4566 22.5836C17.0352 22.9447 16.7556 23.4438 16.6677 23.9918L16.6093 24.3088L15.6295 30.5175L15.585 30.7455C15.5733 30.8176 15.5532 30.8537 15.5235 30.8781C15.4947 30.9016 15.4588 30.9147 15.4217 30.9152H10.6424Z"
                                fill="#253B80"></path>
                              <path
                                d="M27.3778 8.13017C27.3481 8.31998 27.3142 8.51403 27.276 8.71339C25.9643 15.4481 21.4766 17.7746 15.7451 17.7746H12.8268C12.1259 17.7746 11.5352 18.2836 11.426 18.975L9.93191 28.4508L9.5088 31.1368C9.49195 31.2434 9.49838 31.3523 9.52767 31.4561C9.55695 31.5599 9.60838 31.6562 9.67842 31.7382C9.74846 31.8202 9.83545 31.8861 9.9334 31.9313C10.0313 31.9765 10.1379 31.9999 10.2458 32H15.4217C16.0346 32 16.5552 31.5546 16.6517 30.9502L16.7026 30.6872L17.6771 24.5029L17.7397 24.1636C17.8351 23.557 18.3569 23.1116 18.9698 23.1116H19.7439C24.7586 23.1116 28.6842 21.0757 29.8316 15.184C30.3109 12.7228 30.0627 10.6677 28.7945 9.22239C28.3925 8.77528 27.9124 8.40516 27.3778 8.13017Z"
                                fill="#179BD7"></path>
                              <path
                                d="M26.0056 7.58299C25.5868 7.46183 25.1607 7.36727 24.7299 7.29986C23.8788 7.16905 23.0185 7.10629 22.1574 7.11217H14.3612C14.0645 7.11194 13.7776 7.21787 13.5521 7.41079C13.3267 7.60372 13.1778 7.87092 13.1322 8.1641L11.4737 18.6685L11.426 18.975C11.4777 18.6406 11.6473 18.3357 11.9043 18.1155C12.1612 17.8954 12.4885 17.7744 12.8268 17.7746H15.7451C21.4766 17.7746 25.9642 15.447 27.276 8.71339C27.3152 8.51403 27.3481 8.31998 27.3778 8.13017C27.0316 7.94865 26.6709 7.79648 26.2993 7.67525C26.2019 7.64293 26.104 7.61217 26.0056 7.58299Z"
                                fill="#222D65"></path>
                              <path
                                d="M13.1321 8.1641C13.1773 7.87084 13.3262 7.60351 13.5517 7.41068C13.7772 7.21785 14.0644 7.11231 14.3611 7.11323H22.1573C23.0809 7.11323 23.943 7.17368 24.7298 7.30092C25.2623 7.3846 25.7874 7.50976 26.3003 7.67525C26.6874 7.80356 27.0468 7.9552 27.3787 8.13017C27.769 5.64138 27.3756 3.94685 26.0299 2.41243C24.5464 0.7232 21.8688 0 18.4427 0H8.49601C7.79614 0 7.19913 0.508997 7.09097 1.20144L2.94795 27.4625C2.92865 27.5844 2.93599 27.7091 2.96947 27.8279C3.00294 27.9467 3.06176 28.0569 3.14187 28.1508C3.22198 28.2447 3.32149 28.3202 3.43354 28.3719C3.54559 28.4237 3.66753 28.4506 3.79097 28.4508H9.93181L11.4736 18.6685L13.1321 8.1641Z"
                                fill="#253B80"></path>
                            </svg>
                            **** @site.so
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 si6qh j4ei7 iwtls dark:bg-neutral-700 dark:text-neutral-200">
                            <span class="phtd0 r4wnm uiqm5 o49ku iwtls dark:bg-neutral-200"></span>
                            Refunded
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            5
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1 k08z1">
                          <div class="hs-dropdown relative pqrvw [--auto-close:inside] [--placement:bottom-right]">
                            <button id="hs-pro-errtmd8" type="button"
                              class="cslsl uunzg pqrvw pdrgo items-center dqqs4 nq4w8 l66z3 ffqht f0dty j4ei7 tgfrq jonah kko9e tk4f7 focus:outline-none focus:bg-stone-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                              <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <circle cx="12" cy="12" r="1"></circle>
                                <circle cx="12" cy="5" r="1"></circle>
                                <circle cx="12" cy="19" r="1"></circle>
                              </svg>
                            </button>

                            <div
                              class="hs-dropdown-menu muqsh c974i transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                              aria-labelledby="hs-pro-errtmd8">
                              <div class="dpnc4">
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Edite
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Duplicate
                                </button>

                                <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td class="o65eo lnq7o mfkro vrf2y tu5ez">
                          <input type="checkbox"
                            class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800">
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <a class="dtjcu mq8xz cmffi cdcib eass7 focus:outline-none focus:underline dark:text-green-400 dark:hover:text-green-500"
                            href="../../pro/ecommerce/order-details.html">
                            #190931
                          </a>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            Nike Air Jordan 1 Yellow
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 si6qh j4ei7 iwtls dark:bg-neutral-700 dark:text-neutral-200">
                            <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round">
                              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"></path>
                              <path d="m9 12 2 2 4-4"></path>
                            </svg>
                            Fulfilled
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            Karla Verdy
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="pqrvw items-center azej2 dtjcu v474b dark:text-neutral-400">
                            <svg class="wlxy3 sfo8l qcpnt" width="33" height="32" viewBox="0 0 33 32" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M10.6424 30.9152L11.197 27.3925L9.96162 27.3639H4.06256L8.16211 1.37005C8.17431 1.29063 8.21465 1.21823 8.27577 1.16607C8.33689 1.1139 8.41472 1.08543 8.49508 1.08586H18.4417C21.7438 1.08586 24.0227 1.773 25.2124 3.12927C25.7702 3.76551 26.1255 4.43039 26.2972 5.16208C26.4775 5.92981 26.4807 6.84707 26.3047 7.9658L26.2919 8.04745V8.76429L26.8497 9.08029C27.2755 9.29602 27.6583 9.58784 27.979 9.94134C28.4562 10.4853 28.7648 11.1767 28.8952 11.9964C29.0299 12.8394 28.9854 13.8426 28.7648 14.9783C28.5103 16.2847 28.0989 17.4225 27.5432 18.3536C27.053 19.1876 26.3938 19.91 25.608 20.4744C24.8699 20.9982 23.993 21.3959 23.0015 21.6504C22.0408 21.9007 20.9454 22.0268 19.7439 22.0268H18.9698C18.4163 22.0268 17.8786 22.2262 17.4566 22.5836C17.0352 22.9447 16.7556 23.4438 16.6677 23.9918L16.6093 24.3088L15.6295 30.5175L15.585 30.7455C15.5733 30.8176 15.5532 30.8537 15.5235 30.8781C15.4947 30.9016 15.4588 30.9147 15.4217 30.9152H10.6424Z"
                                fill="#253B80"></path>
                              <path
                                d="M27.3778 8.13017C27.3481 8.31998 27.3142 8.51403 27.276 8.71339C25.9643 15.4481 21.4766 17.7746 15.7451 17.7746H12.8268C12.1259 17.7746 11.5352 18.2836 11.426 18.975L9.93191 28.4508L9.5088 31.1368C9.49195 31.2434 9.49838 31.3523 9.52767 31.4561C9.55695 31.5599 9.60838 31.6562 9.67842 31.7382C9.74846 31.8202 9.83545 31.8861 9.9334 31.9313C10.0313 31.9765 10.1379 31.9999 10.2458 32H15.4217C16.0346 32 16.5552 31.5546 16.6517 30.9502L16.7026 30.6872L17.6771 24.5029L17.7397 24.1636C17.8351 23.557 18.3569 23.1116 18.9698 23.1116H19.7439C24.7586 23.1116 28.6842 21.0757 29.8316 15.184C30.3109 12.7228 30.0627 10.6677 28.7945 9.22239C28.3925 8.77528 27.9124 8.40516 27.3778 8.13017Z"
                                fill="#179BD7"></path>
                              <path
                                d="M26.0056 7.58299C25.5868 7.46183 25.1607 7.36727 24.7299 7.29986C23.8788 7.16905 23.0185 7.10629 22.1574 7.11217H14.3612C14.0645 7.11194 13.7776 7.21787 13.5521 7.41079C13.3267 7.60372 13.1778 7.87092 13.1322 8.1641L11.4737 18.6685L11.426 18.975C11.4777 18.6406 11.6473 18.3357 11.9043 18.1155C12.1612 17.8954 12.4885 17.7744 12.8268 17.7746H15.7451C21.4766 17.7746 25.9642 15.447 27.276 8.71339C27.3152 8.51403 27.3481 8.31998 27.3778 8.13017C27.0316 7.94865 26.6709 7.79648 26.2993 7.67525C26.2019 7.64293 26.104 7.61217 26.0056 7.58299Z"
                                fill="#222D65"></path>
                              <path
                                d="M13.1321 8.1641C13.1773 7.87084 13.3262 7.60351 13.5517 7.41068C13.7772 7.21785 14.0644 7.11231 14.3611 7.11323H22.1573C23.0809 7.11323 23.943 7.17368 24.7298 7.30092C25.2623 7.3846 25.7874 7.50976 26.3003 7.67525C26.6874 7.80356 27.0468 7.9552 27.3787 8.13017C27.769 5.64138 27.3756 3.94685 26.0299 2.41243C24.5464 0.7232 21.8688 0 18.4427 0H8.49601C7.79614 0 7.19913 0.508997 7.09097 1.20144L2.94795 27.4625C2.92865 27.5844 2.93599 27.7091 2.96947 27.8279C3.00294 27.9467 3.06176 28.0569 3.14187 28.1508C3.22198 28.2447 3.32149 28.3202 3.43354 28.3719C3.54559 28.4237 3.66753 28.4506 3.79097 28.4508H9.93181L11.4736 18.6685L13.1321 8.1641Z"
                                fill="#253B80"></path>
                            </svg>
                            **** @site.so
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 si6qh j4ei7 iwtls dark:bg-neutral-700 dark:text-neutral-200">
                            <span class="phtd0 r4wnm uiqm5 o49ku iwtls dark:bg-neutral-200"></span>
                            Paid
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            7
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1 k08z1">
                          <div class="hs-dropdown relative pqrvw [--auto-close:inside] [--placement:bottom-right]">
                            <button id="hs-pro-errtmd9" type="button"
                              class="cslsl uunzg pqrvw pdrgo items-center dqqs4 nq4w8 l66z3 ffqht f0dty j4ei7 tgfrq jonah kko9e tk4f7 focus:outline-none focus:bg-stone-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                              <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <circle cx="12" cy="12" r="1"></circle>
                                <circle cx="12" cy="5" r="1"></circle>
                                <circle cx="12" cy="19" r="1"></circle>
                              </svg>
                            </button>

                            <div
                              class="hs-dropdown-menu muqsh c974i transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                              aria-labelledby="hs-pro-errtmd9">
                              <div class="dpnc4">
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Edite
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Duplicate
                                </button>

                                <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td class="o65eo lnq7o mfkro vrf2y tu5ez">
                          <input type="checkbox"
                            class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800">
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <a class="dtjcu mq8xz cmffi cdcib eass7 focus:outline-none focus:underline dark:text-green-400 dark:hover:text-green-500"
                            href="../../pro/ecommerce/order-details.html">
                            #465383
                          </a>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            Mango Women's shoe
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 si6qh j4ei7 iwtls dark:bg-neutral-700 dark:text-neutral-200">
                            <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round">
                              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"></path>
                              <path d="m9 12 2 2 4-4"></path>
                            </svg>
                            Fulfilled
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            O'brien Williams
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="pqrvw items-center azej2 dtjcu v474b dark:text-neutral-400">
                            <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round">
                              <line x1="3" x2="21" y1="22" y2="22"></line>
                              <line x1="6" x2="6" y1="18" y2="11"></line>
                              <line x1="10" x2="10" y1="18" y2="11"></line>
                              <line x1="14" x2="14" y1="18" y2="11"></line>
                              <line x1="18" x2="18" y1="18" y2="11"></line>
                              <polygon points="12 2 20 7 4 7"></polygon>
                            </svg>
                            **** 7887
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 si6qh j4ei7 iwtls dark:bg-neutral-700 dark:text-neutral-200">
                            <span class="phtd0 r4wnm uiqm5 o49ku iwtls dark:bg-neutral-200"></span>
                            Refunded
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            4
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1 k08z1">
                          <div class="hs-dropdown relative pqrvw [--auto-close:inside] [--placement:bottom-right]">
                            <button id="hs-pro-errtmd10" type="button"
                              class="cslsl uunzg pqrvw pdrgo items-center dqqs4 nq4w8 l66z3 ffqht f0dty j4ei7 tgfrq jonah kko9e tk4f7 focus:outline-none focus:bg-stone-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                              <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <circle cx="12" cy="12" r="1"></circle>
                                <circle cx="12" cy="5" r="1"></circle>
                                <circle cx="12" cy="19" r="1"></circle>
                              </svg>
                            </button>

                            <div
                              class="hs-dropdown-menu muqsh c974i transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                              aria-labelledby="hs-pro-errtmd10">
                              <div class="dpnc4">
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Edite
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Duplicate
                                </button>

                                <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td class="o65eo lnq7o mfkro vrf2y tu5ez">
                          <input type="checkbox"
                            class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800">
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <a class="dtjcu mq8xz cmffi cdcib eass7 focus:outline-none focus:underline dark:text-green-400 dark:hover:text-green-500"
                            href="../../pro/ecommerce/order-details.html">
                            #437433
                          </a>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            Nike Air Jordan 1 Yellow
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 si6qh j4ei7 iwtls dark:bg-neutral-700 dark:text-neutral-200">
                            <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round">
                              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"></path>
                              <path d="m9 12 2 2 4-4"></path>
                            </svg>
                            Fulfilled
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            Emma Watson
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="pqrvw items-center azej2 dtjcu v474b dark:text-neutral-400">
                            <svg class="wlxy3 sfo8l qcpnt" width="32" height="20" viewBox="0 0 32 20" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_1704_233491kdx)">
                                <mask id="mask0_1704_23323rd1n1" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                  x="0" y="0" width="32" height="20">
                                  <path d="M32 0H0V19.776H32V0Z" fill="white"></path>
                                </mask>
                                <g mask="url(#mask0_1704_23323rd1n1)">
                                  <path d="M20.32 17.6641H11.68V2.11206H20.32V17.6641Z" fill="#FF5F00"></path>
                                  <path
                                    d="M12.224 9.888C12.224 6.736 13.696 3.92 16 2.112C14.256 0.736 12.112 0 9.888 0C4.432 0 0 4.432 0 9.888C0 15.344 4.432 19.776 9.888 19.776C12.112 19.776 14.256 19.04 16 17.664C13.696 15.856 12.224 13.04 12.224 9.888Z"
                                    fill="#EB001B"></path>
                                  <path
                                    d="M32 9.888C32 15.344 27.568 19.776 22.112 19.776C19.888 19.776 17.744 19.04 16 17.664C18.304 15.856 19.776 13.04 19.776 9.888C19.776 6.736 18.304 3.92 16 2.112C17.744 0.736 19.888 0 22.112 0C27.568 0 32 4.432 32 9.888Z"
                                    fill="#F79E1B"></path>
                                </g>
                              </g>
                              <defs>
                                <clipPath id="clip0_1704_233491kdx">
                                  <rect width="32" height="19.84" fill="white"></rect>
                                </clipPath>
                              </defs>
                            </svg>
                            **** 9024
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 si6qh j4ei7 iwtls dark:bg-neutral-700 dark:text-neutral-200">
                            <span class="phtd0 r4wnm uiqm5 o49ku iwtls dark:bg-neutral-200"></span>
                            Paid
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            2
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1 k08z1">
                          <div class="hs-dropdown relative pqrvw [--auto-close:inside] [--placement:bottom-right]">
                            <button id="hs-pro-errtmd11" type="button"
                              class="cslsl uunzg pqrvw pdrgo items-center dqqs4 nq4w8 l66z3 ffqht f0dty j4ei7 tgfrq jonah kko9e tk4f7 focus:outline-none focus:bg-stone-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                              <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <circle cx="12" cy="12" r="1"></circle>
                                <circle cx="12" cy="5" r="1"></circle>
                                <circle cx="12" cy="19" r="1"></circle>
                              </svg>
                            </button>

                            <div
                              class="hs-dropdown-menu muqsh c974i transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                              aria-labelledby="hs-pro-errtmd11">
                              <div class="dpnc4">
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Edite
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Duplicate
                                </button>

                                <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td class="o65eo lnq7o mfkro vrf2y tu5ez">
                          <input type="checkbox"
                            class="n0yz9 dmrud sg5ei mq8xz focus:ring-green-600 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800">
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <a class="dtjcu mq8xz cmffi cdcib eass7 focus:outline-none focus:underline dark:text-green-400 dark:hover:text-green-500"
                            href="../../pro/ecommerce/order-details.html">
                            #856744
                          </a>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            Hat
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 sgwuf sh246 iwtls dark:bg-red-500/10 dark:text-red-500">
                            <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round">
                              <circle cx="12" cy="12" r="10"></circle>
                              <path d="m15 9-6 6"></path>
                              <path d="m9 9 6 6"></path>
                            </svg>
                            Unfulfilled
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            Chris Williams
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="pqrvw items-center azej2 dtjcu v474b dark:text-neutral-400">
                            <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round">
                              <line x1="3" x2="21" y1="22" y2="22"></line>
                              <line x1="6" x2="6" y1="18" y2="11"></line>
                              <line x1="10" x2="10" y1="18" y2="11"></line>
                              <line x1="14" x2="14" y1="18" y2="11"></line>
                              <line x1="18" x2="18" y1="18" y2="11"></line>
                              <polygon points="12 2 20 7 4 7"></polygon>
                            </svg>
                            **** 3443
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span
                            class="zdxcz grnyh pqrvw items-center pla0f rvi38 eass7 si6qh j4ei7 iwtls dark:bg-neutral-700 dark:text-neutral-200">
                            <span class="phtd0 r4wnm uiqm5 o49ku iwtls dark:bg-neutral-200"></span>
                            Partially refunded
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1">
                          <span class="dtjcu v474b dark:text-neutral-400">
                            1
                          </span>
                        </td>
                        <td class="o65eo lnq7o mfkro pgrdw npcr1 k08z1">
                          <div class="hs-dropdown relative pqrvw [--auto-close:inside] [--placement:bottom-right]">
                            <button id="hs-pro-errtmd12" type="button"
                              class="cslsl uunzg pqrvw pdrgo items-center dqqs4 nq4w8 l66z3 ffqht f0dty j4ei7 tgfrq jonah kko9e tk4f7 focus:outline-none focus:bg-stone-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                              <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <circle cx="12" cy="12" r="1"></circle>
                                <circle cx="12" cy="5" r="1"></circle>
                                <circle cx="12" cy="19" r="1"></circle>
                              </svg>
                            </button>

                            <div
                              class="hs-dropdown-menu muqsh c974i transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                              aria-labelledby="hs-pro-errtmd12">
                              <div class="dpnc4">
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Edite
                                </button>
                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Duplicate
                                </button>

                                <div class="l5ax2 lz37y ffqht dark:border-neutral-800"></div>

                                <button type="button"
                                  class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- End Table -->
                </div>
              </div>
              <!-- End Table Section -->

              <!-- Footer -->
              <div class="i3acl jjege zvyci items-center vj9gl orkeq cc3vv">
                <p class="dtjcu j4ei7 dark:text-neutral-200">
                  <span class="eass7">27</span>
                  <span class="fj6be dark:text-neutral-500">results</span>
                </p>

                <!-- Pagination -->
                <nav class="flex prqc8 items-center azej2">
                  <button type="button"
                    class="oa2y3 zaf61 xxfsd kdz1q pqrvw pdrgo items-center dqqs4 dtjcu nq4w8 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-white dark:hover:bg-white/10 dark:focus:bg-neutral-700">
                    <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                      viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round">
                      <path d="m15 18-6-6 6-6"></path>
                    </svg>
                    <span aria-hidden="true" class="j9hz5">Previous</span>
                  </button>
                  <div class="flex items-center azej2">
                    <span
                      class="oa2y3 zaf61 flex pdrgo items-center si6qh j4ei7 xxfsd vrf2y dtjcu nq4w8 kko9e tk4f7 dark:bg-neutral-700 dark:text-white">1</span>
                    <span class="oa2y3 flex pdrgo items-center fj6be xxfsd zfqg6 dtjcu dark:text-neutral-500">of</span>
                    <span class="oa2y3 flex pdrgo items-center fj6be xxfsd zfqg6 dtjcu dark:text-neutral-500">3</span>
                  </div>
                  <button type="button"
                    class="oa2y3 zaf61 xxfsd kdz1q pqrvw pdrgo items-center dqqs4 dtjcu nq4w8 j4ei7 r1hp8 kko9e tk4f7 focus:outline-none focus:bg-stone-100 dark:text-white dark:hover:bg-white/10 dark:focus:bg-neutral-700">
                    <span aria-hidden="true" class="j9hz5">Next</span>
                    <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                      viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round">
                      <path d="m9 18 6-6-6-6"></path>
                    </svg>
                  </button>
                </nav>
                <!-- End Pagination -->
              </div>
              <!-- End Footer -->
            </div>

            <!-- Tab Content -->
            <div id="hs-pro-tabs-dut-open" class="hidden" role="tabpanel" aria-labelledby="hs-pro-tabs-dut-item-open">
              <!-- Empty State -->
              <div class="gcrxx qazpp p3pc1 2xl:min-h-[397px] flex lgqw8 pdrgo items-center zpy54">
                <svg class="wlxy3 y3buf h75us kufja fj6be" width="16" height="16" viewBox="0 0 16 16" fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.328 0.12197C11.3403 0.0863508 11.3635 0.0554647 11.3942 0.0336055C11.4249 0.0117462 11.4617 0 11.4994 0C11.5371 0 11.5739 0.0117462 11.6046 0.0336055C11.6353 0.0554647 11.6584 0.0863508 11.6708 0.12197L11.9936 1.09019C12.1375 1.52302 12.4766 1.86202 12.9095 2.00588L13.878 2.32862C13.9136 2.34097 13.9445 2.36411 13.9664 2.39482C13.9883 2.42554 14 2.4623 14 2.5C14 2.5377 13.9883 2.57446 13.9664 2.60518C13.9445 2.63589 13.9136 2.65903 13.878 2.67138L12.9095 2.99412C12.6962 3.06508 12.5023 3.18478 12.3433 3.34373C12.1843 3.50269 12.0646 3.69651 11.9936 3.90981L11.6708 4.87803C11.6584 4.91365 11.6353 4.94454 11.6046 4.96639C11.5739 4.98825 11.5371 5 11.4994 5C11.4617 5 11.4249 4.98825 11.3942 4.96639C11.3635 4.94454 11.3403 4.91365 11.328 4.87803L11.0051 3.90981C10.9342 3.69651 10.8144 3.50269 10.6554 3.34373C10.4964 3.18478 10.3026 3.06508 10.0892 2.99412L9.122 2.67138C9.08637 2.65903 9.05548 2.63589 9.03361 2.60518C9.01175 2.57446 9 2.5377 9 2.5C9 2.4623 9.01175 2.42554 9.03361 2.39482C9.05548 2.36411 9.08637 2.34097 9.122 2.32862L10.0905 2.00588C10.5234 1.86202 10.8625 1.52302 11.0064 1.09019L11.328 0.12197Z">
                  </path>
                  <path
                    d="M2.86236 4.09758C2.87224 4.06908 2.89076 4.04437 2.91534 4.02688C2.93992 4.0094 2.96933 4 2.9995 4C3.02967 4 3.05908 4.0094 3.08366 4.02688C3.10824 4.04437 3.12676 4.06908 3.13664 4.09758L3.3949 4.87216C3.51001 5.21841 3.78128 5.48962 4.12763 5.6047L4.9024 5.8629C4.9309 5.87278 4.95562 5.89129 4.97311 5.91586C4.9906 5.94043 5 5.96984 5 6C5 6.03016 4.9906 6.05957 4.97311 6.08414C4.95562 6.10871 4.9309 6.12722 4.9024 6.1371L4.12763 6.3953C3.95694 6.45206 3.80185 6.54783 3.67466 6.67499C3.54746 6.80215 3.45167 6.95721 3.3949 7.12784L3.13664 7.90242C3.12676 7.93092 3.10824 7.95563 3.08366 7.97312C3.05908 7.9906 3.02967 8 2.9995 8C2.96933 8 2.93992 7.9906 2.91534 7.97312C2.89076 7.95563 2.87224 7.93092 2.86236 7.90242L2.6041 7.12784C2.54732 6.95721 2.45154 6.80215 2.32434 6.67499C2.19715 6.54783 2.04205 6.45206 1.87137 6.3953L1.0976 6.1371C1.0691 6.12722 1.04438 6.10871 1.02689 6.08414C1.0094 6.05957 1 6.03016 1 6C1 5.96984 1.0094 5.94043 1.02689 5.91586C1.04438 5.89129 1.0691 5.87278 1.0976 5.8629L1.87237 5.6047C2.21872 5.48962 2.48999 5.21841 2.6051 4.87216L2.86236 4.09758Z">
                  </path>
                  <path
                    d="M7.96129 9.43888C7.98654 9.34447 8 9.24523 8 9.14286C8 8.51168 7.48833 8 6.85714 8C6.22596 8 5.71429 8.51168 5.71429 9.14286C5.71429 9.24523 5.72775 9.34447 5.753 9.43888L1.43888 13.753C1.34447 13.7277 1.24523 13.7143 1.14286 13.7143C0.511675 13.7143 0 14.226 0 14.8571C0 15.4883 0.511675 16 1.14286 16C1.77404 16 2.28571 15.4883 2.28571 14.8571C2.28571 14.7548 2.27225 14.6555 2.247 14.5611L6.56112 10.247C6.65553 10.2723 6.75477 10.2857 6.85714 10.2857C6.95952 10.2857 7.05875 10.2723 7.15317 10.247L9.753 12.8468C9.72775 12.9412 9.71429 13.0405 9.71429 13.1429C9.71429 13.774 10.226 14.2857 10.8571 14.2857C11.4883 14.2857 12 13.774 12 13.1429C12 13.0405 11.9865 12.9412 11.9613 12.8468L14.5611 10.247C14.6555 10.2723 14.7548 10.2857 14.8571 10.2857C15.4883 10.2857 16 9.77404 16 9.14286C16 8.51168 15.4883 8 14.8571 8C14.226 8 13.7143 8.51168 13.7143 9.14286C13.7143 9.24523 13.7277 9.34447 13.753 9.43888L11.1532 12.0387C11.0588 12.0135 10.9595 12 10.8571 12C10.7548 12 10.6555 12.0135 10.5611 12.0387L7.96129 9.43888Z">
                  </path>
                </svg>
                <h4 class="iwr8m eass7 j4ei7 dark:text-neutral-200">
                  Your data will appear here soon.
                </h4>
                <p class="q8w6j diql8 dtjcu fj6be dark:text-neutral-500">
                  In the meantime, you can create new custom insights to monitor your most oypae metrics.
                  <a class="pqrvw items-center pla0f dtjcu mq8xz cmffi cdcib eass7 focus:outline-none focus:underline dark:text-green-400 dark:hover:text-green-500"
                    href="#">
                    Learn more
                    <svg class="wlxy3 sfo8l qcpnt transition le6lt b24uu" xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round">
                      <path d="m9 18 6-6-6-6"></path>
                    </svg>
                  </a>
                </p>
              </div>
              <!-- End Empty State -->
            </div>
            <!-- End Tab Content -->

            <!-- Tab Content -->
            <div id="hs-pro-tabs-dut-unfulfilled" class="hidden" role="tabpanel"
              aria-labelledby="hs-pro-tabs-dut-item-unfulfilled">
              <!-- Empty State -->
              <div class="gcrxx qazpp p3pc1 2xl:min-h-[397px] flex lgqw8 pdrgo items-center zpy54">
                <svg class="wlxy3 y3buf h75us kufja fj6be" width="16" height="16" viewBox="0 0 16 16" fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.328 0.12197C11.3403 0.0863508 11.3635 0.0554647 11.3942 0.0336055C11.4249 0.0117462 11.4617 0 11.4994 0C11.5371 0 11.5739 0.0117462 11.6046 0.0336055C11.6353 0.0554647 11.6584 0.0863508 11.6708 0.12197L11.9936 1.09019C12.1375 1.52302 12.4766 1.86202 12.9095 2.00588L13.878 2.32862C13.9136 2.34097 13.9445 2.36411 13.9664 2.39482C13.9883 2.42554 14 2.4623 14 2.5C14 2.5377 13.9883 2.57446 13.9664 2.60518C13.9445 2.63589 13.9136 2.65903 13.878 2.67138L12.9095 2.99412C12.6962 3.06508 12.5023 3.18478 12.3433 3.34373C12.1843 3.50269 12.0646 3.69651 11.9936 3.90981L11.6708 4.87803C11.6584 4.91365 11.6353 4.94454 11.6046 4.96639C11.5739 4.98825 11.5371 5 11.4994 5C11.4617 5 11.4249 4.98825 11.3942 4.96639C11.3635 4.94454 11.3403 4.91365 11.328 4.87803L11.0051 3.90981C10.9342 3.69651 10.8144 3.50269 10.6554 3.34373C10.4964 3.18478 10.3026 3.06508 10.0892 2.99412L9.122 2.67138C9.08637 2.65903 9.05548 2.63589 9.03361 2.60518C9.01175 2.57446 9 2.5377 9 2.5C9 2.4623 9.01175 2.42554 9.03361 2.39482C9.05548 2.36411 9.08637 2.34097 9.122 2.32862L10.0905 2.00588C10.5234 1.86202 10.8625 1.52302 11.0064 1.09019L11.328 0.12197Z">
                  </path>
                  <path
                    d="M2.86236 4.09758C2.87224 4.06908 2.89076 4.04437 2.91534 4.02688C2.93992 4.0094 2.96933 4 2.9995 4C3.02967 4 3.05908 4.0094 3.08366 4.02688C3.10824 4.04437 3.12676 4.06908 3.13664 4.09758L3.3949 4.87216C3.51001 5.21841 3.78128 5.48962 4.12763 5.6047L4.9024 5.8629C4.9309 5.87278 4.95562 5.89129 4.97311 5.91586C4.9906 5.94043 5 5.96984 5 6C5 6.03016 4.9906 6.05957 4.97311 6.08414C4.95562 6.10871 4.9309 6.12722 4.9024 6.1371L4.12763 6.3953C3.95694 6.45206 3.80185 6.54783 3.67466 6.67499C3.54746 6.80215 3.45167 6.95721 3.3949 7.12784L3.13664 7.90242C3.12676 7.93092 3.10824 7.95563 3.08366 7.97312C3.05908 7.9906 3.02967 8 2.9995 8C2.96933 8 2.93992 7.9906 2.91534 7.97312C2.89076 7.95563 2.87224 7.93092 2.86236 7.90242L2.6041 7.12784C2.54732 6.95721 2.45154 6.80215 2.32434 6.67499C2.19715 6.54783 2.04205 6.45206 1.87137 6.3953L1.0976 6.1371C1.0691 6.12722 1.04438 6.10871 1.02689 6.08414C1.0094 6.05957 1 6.03016 1 6C1 5.96984 1.0094 5.94043 1.02689 5.91586C1.04438 5.89129 1.0691 5.87278 1.0976 5.8629L1.87237 5.6047C2.21872 5.48962 2.48999 5.21841 2.6051 4.87216L2.86236 4.09758Z">
                  </path>
                  <path
                    d="M7.96129 9.43888C7.98654 9.34447 8 9.24523 8 9.14286C8 8.51168 7.48833 8 6.85714 8C6.22596 8 5.71429 8.51168 5.71429 9.14286C5.71429 9.24523 5.72775 9.34447 5.753 9.43888L1.43888 13.753C1.34447 13.7277 1.24523 13.7143 1.14286 13.7143C0.511675 13.7143 0 14.226 0 14.8571C0 15.4883 0.511675 16 1.14286 16C1.77404 16 2.28571 15.4883 2.28571 14.8571C2.28571 14.7548 2.27225 14.6555 2.247 14.5611L6.56112 10.247C6.65553 10.2723 6.75477 10.2857 6.85714 10.2857C6.95952 10.2857 7.05875 10.2723 7.15317 10.247L9.753 12.8468C9.72775 12.9412 9.71429 13.0405 9.71429 13.1429C9.71429 13.774 10.226 14.2857 10.8571 14.2857C11.4883 14.2857 12 13.774 12 13.1429C12 13.0405 11.9865 12.9412 11.9613 12.8468L14.5611 10.247C14.6555 10.2723 14.7548 10.2857 14.8571 10.2857C15.4883 10.2857 16 9.77404 16 9.14286C16 8.51168 15.4883 8 14.8571 8C14.226 8 13.7143 8.51168 13.7143 9.14286C13.7143 9.24523 13.7277 9.34447 13.753 9.43888L11.1532 12.0387C11.0588 12.0135 10.9595 12 10.8571 12C10.7548 12 10.6555 12.0135 10.5611 12.0387L7.96129 9.43888Z">
                  </path>
                </svg>
                <h4 class="iwr8m eass7 j4ei7 dark:text-neutral-200">
                  Your data will appear here soon.
                </h4>
                <p class="q8w6j diql8 dtjcu fj6be dark:text-neutral-500">
                  In the meantime, you can create new custom insights to monitor your most oypae metrics.
                  <a class="pqrvw items-center pla0f dtjcu mq8xz cmffi cdcib eass7 focus:outline-none focus:underline dark:text-green-400 dark:hover:text-green-500"
                    href="#">
                    Learn more
                    <svg class="wlxy3 sfo8l qcpnt transition le6lt b24uu" xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round">
                      <path d="m9 18 6-6-6-6"></path>
                    </svg>
                  </a>
                </p>
              </div>
              <!-- End Empty State -->
            </div>
            <!-- End Tab Content -->

            <!-- Tab Content -->
            <div id="hs-pro-tabs-dut-unpaid" class="hidden" role="tabpanel" aria-labelledby="hs-pro-tabs-dut-item-unpaid">
              <!-- Empty State -->
              <div class="gcrxx qazpp p3pc1 2xl:min-h-[397px] flex lgqw8 pdrgo items-center zpy54">
                <svg class="wlxy3 y3buf h75us kufja fj6be" width="16" height="16" viewBox="0 0 16 16" fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.328 0.12197C11.3403 0.0863508 11.3635 0.0554647 11.3942 0.0336055C11.4249 0.0117462 11.4617 0 11.4994 0C11.5371 0 11.5739 0.0117462 11.6046 0.0336055C11.6353 0.0554647 11.6584 0.0863508 11.6708 0.12197L11.9936 1.09019C12.1375 1.52302 12.4766 1.86202 12.9095 2.00588L13.878 2.32862C13.9136 2.34097 13.9445 2.36411 13.9664 2.39482C13.9883 2.42554 14 2.4623 14 2.5C14 2.5377 13.9883 2.57446 13.9664 2.60518C13.9445 2.63589 13.9136 2.65903 13.878 2.67138L12.9095 2.99412C12.6962 3.06508 12.5023 3.18478 12.3433 3.34373C12.1843 3.50269 12.0646 3.69651 11.9936 3.90981L11.6708 4.87803C11.6584 4.91365 11.6353 4.94454 11.6046 4.96639C11.5739 4.98825 11.5371 5 11.4994 5C11.4617 5 11.4249 4.98825 11.3942 4.96639C11.3635 4.94454 11.3403 4.91365 11.328 4.87803L11.0051 3.90981C10.9342 3.69651 10.8144 3.50269 10.6554 3.34373C10.4964 3.18478 10.3026 3.06508 10.0892 2.99412L9.122 2.67138C9.08637 2.65903 9.05548 2.63589 9.03361 2.60518C9.01175 2.57446 9 2.5377 9 2.5C9 2.4623 9.01175 2.42554 9.03361 2.39482C9.05548 2.36411 9.08637 2.34097 9.122 2.32862L10.0905 2.00588C10.5234 1.86202 10.8625 1.52302 11.0064 1.09019L11.328 0.12197Z">
                  </path>
                  <path
                    d="M2.86236 4.09758C2.87224 4.06908 2.89076 4.04437 2.91534 4.02688C2.93992 4.0094 2.96933 4 2.9995 4C3.02967 4 3.05908 4.0094 3.08366 4.02688C3.10824 4.04437 3.12676 4.06908 3.13664 4.09758L3.3949 4.87216C3.51001 5.21841 3.78128 5.48962 4.12763 5.6047L4.9024 5.8629C4.9309 5.87278 4.95562 5.89129 4.97311 5.91586C4.9906 5.94043 5 5.96984 5 6C5 6.03016 4.9906 6.05957 4.97311 6.08414C4.95562 6.10871 4.9309 6.12722 4.9024 6.1371L4.12763 6.3953C3.95694 6.45206 3.80185 6.54783 3.67466 6.67499C3.54746 6.80215 3.45167 6.95721 3.3949 7.12784L3.13664 7.90242C3.12676 7.93092 3.10824 7.95563 3.08366 7.97312C3.05908 7.9906 3.02967 8 2.9995 8C2.96933 8 2.93992 7.9906 2.91534 7.97312C2.89076 7.95563 2.87224 7.93092 2.86236 7.90242L2.6041 7.12784C2.54732 6.95721 2.45154 6.80215 2.32434 6.67499C2.19715 6.54783 2.04205 6.45206 1.87137 6.3953L1.0976 6.1371C1.0691 6.12722 1.04438 6.10871 1.02689 6.08414C1.0094 6.05957 1 6.03016 1 6C1 5.96984 1.0094 5.94043 1.02689 5.91586C1.04438 5.89129 1.0691 5.87278 1.0976 5.8629L1.87237 5.6047C2.21872 5.48962 2.48999 5.21841 2.6051 4.87216L2.86236 4.09758Z">
                  </path>
                  <path
                    d="M7.96129 9.43888C7.98654 9.34447 8 9.24523 8 9.14286C8 8.51168 7.48833 8 6.85714 8C6.22596 8 5.71429 8.51168 5.71429 9.14286C5.71429 9.24523 5.72775 9.34447 5.753 9.43888L1.43888 13.753C1.34447 13.7277 1.24523 13.7143 1.14286 13.7143C0.511675 13.7143 0 14.226 0 14.8571C0 15.4883 0.511675 16 1.14286 16C1.77404 16 2.28571 15.4883 2.28571 14.8571C2.28571 14.7548 2.27225 14.6555 2.247 14.5611L6.56112 10.247C6.65553 10.2723 6.75477 10.2857 6.85714 10.2857C6.95952 10.2857 7.05875 10.2723 7.15317 10.247L9.753 12.8468C9.72775 12.9412 9.71429 13.0405 9.71429 13.1429C9.71429 13.774 10.226 14.2857 10.8571 14.2857C11.4883 14.2857 12 13.774 12 13.1429C12 13.0405 11.9865 12.9412 11.9613 12.8468L14.5611 10.247C14.6555 10.2723 14.7548 10.2857 14.8571 10.2857C15.4883 10.2857 16 9.77404 16 9.14286C16 8.51168 15.4883 8 14.8571 8C14.226 8 13.7143 8.51168 13.7143 9.14286C13.7143 9.24523 13.7277 9.34447 13.753 9.43888L11.1532 12.0387C11.0588 12.0135 10.9595 12 10.8571 12C10.7548 12 10.6555 12.0135 10.5611 12.0387L7.96129 9.43888Z">
                  </path>
                </svg>
                <h4 class="iwr8m eass7 j4ei7 dark:text-neutral-200">
                  Your data will appear here soon.
                </h4>
                <p class="q8w6j diql8 dtjcu fj6be dark:text-neutral-500">
                  In the meantime, you can create new custom insights to monitor your most oypae metrics.
                  <a class="pqrvw items-center pla0f dtjcu mq8xz cmffi cdcib eass7 focus:outline-none focus:underline dark:text-green-400 dark:hover:text-green-500"
                    href="#">
                    Learn more
                    <svg class="wlxy3 sfo8l qcpnt transition le6lt b24uu" xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round">
                      <path d="m9 18 6-6-6-6"></path>
                    </svg>
                  </a>
                </p>
              </div>
              <!-- End Empty State -->
            </div>
            <!-- End Tab Content -->
          </div>
        </div>
        <!-- End Orders Table Card -->
      </div>
    </div>
  </main>
</template>
<script>
import navigation from '@/views/ecommerce/navigation.vue'
export default {
  components: {
    navigation
  }
}
</script>