<template>
	<section class="dark:bg-neutral-800" id="pricing">
		<div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
			<div class="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
				<h2
					class="mb-4 text-3xl font-extrabold tracking-tight text-neutral-900 text-white"
				>
					Designed for business teams like yours
				</h2>
				<p class="mb-5 font-light text-neutral-500 sm:text-xl text-white">
					Here at Zookish we focus on markets where technology, innovation, and
					capital can unlock long-term value and drive economic growth.
				</p>

				<label class="relative inline-flex items-center cursor-pointer">
					<input type="checkbox" v-model="toggleState" class="sr-only peer" />
					<div
						class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 peer-focus:ring-blue-800 rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-transparent after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-blue-600"
					></div>
					<span class="ml-3 text-sm font-medium text-white capitalize">{{
						billingCycle
					}}</span>
				</label>
			</div>
			<div
				class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0"
			>
				<!-- Pricing Card -->
				<div
					class="flex flex-col max-w-lg p-6 mx-auto text-center text-neutral-900 bg-transparent border border-neutral-100 rounded-lg shadow border-neutral-600 xl:p-8 bg-transparent-800 text-white"
				>
					<h3 class="mb-4 text-2xl font-semibold">Starter</h3>
					<p class="font-light text-neutral-500 sm:text-lg text-white">
						Best option for personal use & for your next project.
					</p>
					<div class="flex items-baseline justify-center my-8">
						<span v-if="hasIP" class="mr-2 text-5xl font-extrabold"
							>{{ currency }}{{ billing[billingCycle].starter.price }}</span
						>
						<span v-else class="mr-2 text-5xl font-extrabold"
							>{{ currency }}{{ billing[billingCycle].starter.price }}</span
						>
						<span class="text-neutral-500 text-white">/{{ billingCycle }}</span>
					</div>
					<!-- List -->
					<ul role="list" class="mb-8 space-y-4 text-left">
						<li class="flex items-center space-x-3" v-for="item in starter">
							<!-- Icon -->
							<svg
								class="flex-shrink-0 w-5 h-5 text-green-500 text-green-400"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
									clip-rule="evenodd"
								></path>
							</svg>
							<span>{{ item }}</span>
						</li>
					</ul>
					<a
						@click="buy(billing[billingCycle].starter.url)"
						class="text-white bg-gradient-to-tl from-blue-600 to-violet-600 shadow-lg shadow-transparent hover:shadow-blue-700/50 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-blue-900"
						>Get Started</a
					>
				</div>
				<!-- Pricing Card -->
				<div
					class="flex flex-col max-w-lg p-6 mx-auto text-center text-neutral-900 bg-transparent border border-neutral-100 rounded-lg shadow border-neutral-600 xl:p-8 bg-transparent-800 text-white"
				>
					<h3 class="mb-4 text-2xl font-semibold">Company</h3>
					<p class="font-light text-neutral-500 sm:text-lg text-white">
						Relevant for multiple users, extended & premium support.
					</p>
					<div class="flex items-baseline justify-center my-8">
						<span v-if="hasIP" class="mr-2 text-5xl font-extrabold"
							>{{ currency }}{{ billing[billingCycle].company.price }}</span
						>
						<span v-else class="mr-2 text-5xl font-extrabold"
							>{{ currency }}{{ billing[billingCycle].company.price }}</span
						>
						<span class="text-neutral-500 text-white" text-white
							>/{{ billingCycle }}</span
						>
					</div>
					<!-- List -->
					<ul role="list" class="mb-8 space-y-4 text-left">
						<li class="flex items-center space-x-3" v-for="item in business">
							<!-- Icon -->
							<svg
								class="flex-shrink-0 w-5 h-5 text-green-500 text-green-400"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
									clip-rule="evenodd"
								></path>
							</svg>
							<span>{{ item }}</span>
						</li>
					</ul>
					<a
						@click="buy(billing[billingCycle].company.url)"
						class="text-white bg-gradient-to-tl from-blue-600 to-violet-600 shadow-lg shadow-transparent hover:shadow-blue-700/50 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-blue-900"
						>Get Started</a
					>
				</div>
				<!-- Pricing Card -->
				<div
					class="flex flex-col max-w-lg p-6 mx-auto text-center text-neutral-900 bg-transparent border border-neutral-100 rounded-lg shadow border-neutral-600 xl:p-8 bg-transparent-800 text-white"
				>
					<h3 class="mb-4 text-2xl font-semibold">Enterprise</h3>
					<p class="font-light text-neutral-500 sm:text-lg text-white">
						Best for large scale uses and extended redistribution rights.
					</p>
					<div class="flex items-baseline justify-center my-8">
						<!-- <span v-if="hasIP" class="mr-2 text-5xl font-extrabold">{{ JSON.parse(IP).currency.code }}{{ billing[billingCycle].enterprise.price }}</span>
                         <span v-else class="mr-2 text-5xl font-extrabold">${{ billing[billingCycle].enterprise.price }}</span>
                        <span class="text-neutral-500 text-white">/{{ billingCycle }}</span> -->
						<span class="mr-2 text-5xl font-extrabold"> Contact Us</span>
					</div>
					<!-- List -->
					<ul role="list" class="mb-8 space-y-4 text-left">
						<li class="flex items-center space-x-3" v-for="item in enterprise">
							<!-- Icon -->
							<svg
								class="flex-shrink-0 w-5 h-5 text-green-500 text-green-400"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
									clip-rule="evenodd"
								></path>
							</svg>
							<span>{{ item }}</span>
						</li>
					</ul>
					<a
						@click="buy(billing[billingCycle].enterprise.url)"
						class="text-white bg-gradient-to-tl from-blue-600 to-violet-600 shadow-lg shadow-transparent hover:shadow-blue-700/50 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-blue-900"
						>Get started</a
					>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
	import { useHostStore } from "@/stores/HostStore"
	import { Convert } from "easy-currencies"
	import { iso1A2Code } from "@rapideditor/country-coder"
	const countryToCurrency = require("country-to-currency")
	export default {
		data: () => {
			return {
				hostStore: useHostStore(),
				count: 0,
				currency: "USD",
				billingCycle: "monthly",
				toggleState: true,
				billing: {
					monthly: {
						enterprise: {
							url: "https://buy.stripe.com/3cs9By6XQd9SedieV6",
							price: "999.99",
						},
						company: {
							url: "https://buy.stripe.com/eVa5li6XQedWedifZ8 ",
							price: "9.99",
						},
						starter: {
							url: "https://buy.stripe.com/00g3daaa21raediaEM",
							price: "4.99",
						},
					},
					yearly: {
						enterprise: {
							url: "https://buy.stripe.com/dR6cNKgyqc5Od9efZ5",
							price: "9999",
						},
						company: {
							url: "https://buy.stripe.com/bIY3da5TM3zi9X214k",
							price: "89.99",
						},
						starter: {
							url: "https://buy.stripe.com/14k7tq5TM6Lu9X2bIX",
							price: "35.99",
						},
					},
				},

				starter: [
					"Build 50 websites per month",
					"Basic customization options (colors, fonts)",
					"Website Builder",
					"Email support",
					"Host 1 custom domain",
					"Pause or cancel anytime",
					"Updates & Security Patches Forever",
				],
				business: [
					"Build 100 Websites per month",
					"Basic customization options (colors, fonts)",
					"Website Builder",
					"Email support",
					"Hosted sites: 3",
					"Pause or cancel anytime",
					"Team size: 5 teammates",
					"Updates & Security Patches Forever",
				],
				enterprise: [
					"Unlimited Builds",
					"CRM: Unlimited Leads",
					"Customized Cloning",
					"Advanced Customization",
					"Web design and Lead Generation Agency ",
					"Web Mastery and Lead Generation.",
					"Unlimited web designs and lead generation.",
					"Super fast delivery",
					"Fixed monthly rate",
					"Top - notch quality",
					"Flexible and scalable",
					"Unique and all yours",
					"Responsive Design",
					"Content Migration",
					"SEO Optimization",
					"Pause or cancel anytime",
					"Performance Optimization",
					"Security Enhancements",
					"Domain Integration",
					"24/7 Support",
					"Monthly Maintenance",
				],
			}
		},
		watch: {
			toggleState(a) {
				if (a) {
					this.billingCycle = "monthly"
				} else {
					this.billingCycle = "yearly"
				}
			},
			billingCycle() {
				if (this.count <= 1) {
					this.setPrices()
				}
			},
		},
		computed: {
			user() {
				return localStorage.getItem("user")
			},
			hostname() {
				return this.hostStore.hostname
			},
			isUser() {
				if (this.user == null) {
					return false
				} else {
					return true
				}
			},
			IP() {
				return localStorage.getItem("IP")
			},
			hasIP() {
				if (this.IP == null) {
					return false
				} else {
					return true
				}
			},
		},
		mounted() {
			this.getLocation()
		},
		methods: {
			buy(href) {
				localStorage.setItem("next", href)
				if (this.isUser) {
					window.location.replace(href)
				} else {
					window.location.replace(`${this.hostname}/api/v1/login`)
				}
			},

			setPrices() {
				this.convertCC(
					this.billing[this.billingCycle][`enterprise`].price,
					`enterprise`
				)
				this.convertCC(
					this.billing[this.billingCycle][`company`].price,
					`company`
				)
				this.convertCC(
					this.billing[this.billingCycle][`starter`].price,
					`starter`
				)
				this.count++
			},

			async convertCC(e, f) {
				Convert(parseInt(e))
					.from("USD")
					.to(countryToCurrency[localStorage.getItem("code")])
					.then(r => {
						console.log(`r:${r}, e: ${e}`)
						this.billing[this.billingCycle][f].price = r.toFixed(2)
					})
			},

			getLocation() {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(this.logPosition)
				}
			},
			logPosition(position) {
				var code = iso1A2Code([
					position.coords.longitude,
					position.coords.latitude,
				])
				localStorage.setItem("code", code)
				this.currency = countryToCurrency[localStorage.getItem("code")]
				this.setPrices()
			},
		},
	}
</script>
