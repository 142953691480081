<template>
    <!-- Workedwith -->
    <!-- iframe from the same domain for demonstration purposes -->
    <iframe id="hiddenIframe" src="https://preline.co/index.html">
    </iframe>

    <section class="bg-white dark:bg-neutral-900" id="builtwith">
        <div class="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-16">
            <div
                class="grid grid-cols-2 gap-8 text-neutral-500 sm:gap-12 sm:grid-cols-3 lg:grid-cols-6 dark:text-neutral-400">
                <a href="#" class="flex items-center lg:justify-center">
                    <figure
                        class="ml-auto mr-20 relative z-[1] max-w-full w-[50rem] h-auto rounded-b-lg shadow-[0_2.75rem_3.5rem_-2rem_rgb(45_55_75_/_20%),_0_0_5rem_-2rem_rgb(45_55_75_/_15%)] dark:shadow-[0_2.75rem_3.5rem_-2rem_rgb(0_0_0_/_20%),_0_0_5rem_-2rem_rgb(0_0_0_/_15%)]">
                        <div
                            class="relative flex items-center max-w-[50rem] bg-gray-800 rounded-t-lg py-2 px-24 dark:bg-gray-700">
                            <div class="flex space-x-1 absolute top-2/4 left-4 -translate-y-1">
                                <span class="w-2 h-2 bg-gray-600 rounded-full dark:bg-gray-600"></span>
                                <span class="w-2 h-2 bg-gray-600 rounded-full dark:bg-gray-600"></span>
                                <span class="w-2 h-2 bg-gray-600 rounded-full dark:bg-gray-600"></span>
                            </div>
                            <div
                                class="flex justify-center items-center w-full h-full bg-gray-700 text-[.25rem] text-gray-400 rounded-sm sm:text-[.5rem] dark:bg-gray-600 dark:text-gray-400">
                                www.preline.com</div>
                        </div>

                        <div class="bg-gray-800 rounded-b-lg">
                            <img id="iframeSnapshot" class="max-w-full h-auto rounded-b-lg" alt="Image Description">

                        </div>
                    </figure>
                </a>

            </div>
        </div>
    </section>
</template>
<script>
    export default {
        mounted() {
            function captureIframeAndSetImage(iframeSelector, imageId) {
                var iframe = document.querySelector(iframeSelector);
                var imgTag = document.getElementById(imageId);
                var canvas = document.createElement('canvas');
                var context = canvas.getContext('2d');
                iframe.addEventListener('load', function() {
                    var iframeContentWindow = iframe.contentWindow;
                    try {
                        var iframeDocument = iframeContentWindow.document;
                        var width = iframeDocument.documentElement.scrollWidth;
                        var height = iframeDocument.documentElement.scrollHeight;
                        canvas.width = width;
                        canvas.height = height;
                        context.drawImage(iframeContentWindow, 0, 0, width, height);
                        // Convert canvas to a data URL and set it as the source of the img tag
                        imgTag.src = canvas.toDataURL('image/png');
                    } catch (e) {
                        console.error('Error capturing iframe content: ', e);
                    }
                });
            }
            captureIframeAndSetImage('#hiddenIframe', 'iframeSnapshot');
        }
    }
</script>
<style>
    #hiddenIframe {
        position: absolute;
        width: 1px;
        height: 1px;
        opacity: 0;
        pointer-events: none;
        overflow: hidden;
    }
</style>