<template>
	<div class='w-full top-0 absolute z-infinity' v-if="load">
		<div class='h-1.5 w-full bg-black overflow-hidden'>
			<div class='progress w-full h-full bg-blue-600 left-right'></div>
		</div>
	</div>
	<div class="editor-row">
		<div class="panel-left" v-if="!mobilemode && showSidebar">
			<div class="layers-container">
				<sidebar v-if="!loading" :project="page.project" :page="page"
					@prev="projectStore.setPrompt(history[history.length - 2])" />
			</div>
		</div>
		<div class="editor-canvas">
			<div id="gjs" v-show="!loading"></div>
		</div>
	</div>
	<loader v-if="loading" />
	<success v-if="hasMessage" @ack="hasMessage = false" :message="message" />
	<data-modal v-if="dataModalView" @exit="dataModalView = false" />
	<launch-modal v-if="launchModalView" @exit="launchModalView = false" />

	<!-- <subscribe-modal v-if="!subscribed && !isLeader" /> -->
</template>
<script>
import sidebar from "./sidebar"
import "grapesjs/dist/css/grapes.min.css"
import grapesjs from "grapesjs"
import api from "@/services/api"
import plugin from "grapesjs-preset-webpage"
import { useCloneStore } from "@/stores/CloneStore"
import { useHostStore } from "@/stores/HostStore"
import { useProjectStore } from "@/stores/ProjectStore"
import CategorizeLinksMixin from "@/mixins/CategorizeLinksMixin.js"
import LeaderboardMixin from "@/mixins/LeaderboardMixin"
import Aimixin from "@/mixins/Aimixin.js"
import blockPlugin from "grapesjs-blocks-basic"
import ckeditor from "grapesjs-plugin-ckeditor"
import gjsForms from "grapesjs-plugin-forms"
import gaPlugin from "grapesjs-ga"
import gpickrPlugin from "grapesjs-style-gpickr"
import exportPlugin from 'grapesjs-plugin-export'
import uppyPlugin from "grapesjs-uppy"
import tuiPlugin from "grapesjs-tui-image-editor"
import gjsCalendly from "grapesjs-calendly"
import "grapesjs-uppy/dist/grapesjs-uppy.min.css"
import gjsIcons from "grapesjs-google-material-icons"
import 'grapesjs-component-code-editor/dist/grapesjs-component-code-editor.min.css'
import codeEditor from 'grapesjs-component-code-editor'
import tailPlugin from "grapesjs-tailwind"
import undrawPlugin from "grapesjs-undraw"
import "grapesjs/dist/css/grapes.min.css"
import "grapesjs-undraw/dist/grapesjs-undraw.min.css"
import twitchPlugin from "grapesjs-component-twitch"
import easingPlugin from "grapesjs-style-easing"
import loadingPlugin from "@silexlabs/grapesjs-loading"
import html2canvas from "html2canvas"
import zoomPlugin from "grapesjs-zoom-plugin"
import rulerPlugin from "grapesjs-rulers"
import "grapesjs-rulers/dist/grapesjs-rulers.min.css"
import DataModal from "./dataModal.vue"
import LaunchModal from "./launchModal.vue"
import SubscribeModal from "./subscribe.vue"
import EcomMixin from "@/mixins/EcomMixin"

export default {
	props: {},
	data: () => {
		return {
			load: false,
			loading: true,
			seedData: null,
			mobilemode: false,
			htmlString: ``,
			cloneStore: useCloneStore(),
			hostStore: useHostStore(),
			projectStore: useProjectStore(),
			page: null,
			message: "Changes Saved Successfully",
			hasMessage: false,
			dataModalView: false,
			launchModalView: false,
			subscribed: true,
			showSidebar: true,
			history: []

		}
	},
	components: {
		sidebar,
		DataModal,
		LaunchModal,
		SubscribeModal,
	},
	mixins: [CategorizeLinksMixin, Aimixin, LeaderboardMixin, EcomMixin],
	mounted() {
		this.skimJSON()
		// Wait for the iframe to load
		window.addEventListener('load', function () {
			// Access the iframe
			var iframe = document.getElementById('your-iframe-id'); // Replace 'your-iframe-id' with the actual ID of your iframe

			// Check if the iframe is loaded
			if (iframe) {
				// Access the iframe's content window
				var iframeWindow = iframe.contentWindow;

				// Override the window.open function within the iframe with a function that does nothing
				iframeWindow.open = function () { };
			}
		});
	},
	watch: {
		htmlString() {
			this.initGjs()
		},
		hasMessage(state) {
			if (state) {
				setTimeout(() => {
					this.hasMessage = false
				}, 3000)
			}
		},
	},
	computed: {
		isViewportBelow900() {
			return window.innerWidth < 900
		},
		response() {
			return this.cloneStore.response
		},
		hostname() {
			return this.hostStore.hostname
		},
		project() {
			return this.projectStore.project
		},
		prompt() {
			return this.projectStore.prompt
		},
		shortText() {
			return this.projectStore.shortText
		},
		aimode() {
			return this.projectStore.aimode
		}
	},
	methods: {
		initGjs() {

			const editor = grapesjs.init({
				container: "#gjs",
				fromElement: true,
				allowScripts: 1,
				height: "100%",
				width: "auto",
				canvas: {
					scripts: this.seedData.js,
					styles: this.seedData.css,
				},
				storageManager: {
					type: "remote",
					stepsBeforeSave: 1,
				},
				panels: {
					defaults: [],
				},
				selectorManager: {
					componentFirst: true,
				},
				plugins: [
					plugin,
					blockPlugin,
					gjsForms,
					gaPlugin,
					gpickrPlugin,
					undrawPlugin,
					easingPlugin,
					twitchPlugin,
					zoomPlugin,
					rulerPlugin,
					exportPlugin,
					codeEditor,
					gjsIcons
				],
				pluginsOpts: {
					[exportPlugin]: {
						filenamePfx: this.project.title + this.page.title
					}
				}
			})
			editor.on("storage:start", () => {
				this.saveTemplate(editor)
			})


			if (this.page.content == "") {
				editor.addComponents(`${this.page.html}`)
			} else {
				editor.loadProjectData(JSON.parse(this.page.content))
			}

			var cmdm = editor.Commands

			var pnm = editor.Panels
			const panelViews = pnm.addPanel({
				id: 'views'
			});
			panelViews.get('buttons').add([{
				attributes: {
					title: 'Open Code'
				},
				className: 'fa fa-file-code-o',
				command: 'open-code',
				togglable: false, //do not close when button is clicked again
				id: 'open-code'
			}]);
			// define this event handler after editor is defined
			// like in const editor = grapesjs.init({ ...config });
			editor.on('component:selected', () => {

				// whenever a component is selected in the editor

				// set your command and icon here
				const commandTitle = 'AI';
				const commandIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>';

				// get the selected componnet and its default toolbar
				const selectedComponent = editor.getSelected();
				selectedComponent.on('copy', function () {
					// Do something when the component is copied
					editor.addComponents(editor.getHtml())
				});
				console.log(selectedComponent)
				const defaultToolbar = selectedComponent.get('toolbar');
				// Check if the selected component is a text component

				// check if this command already exists on this component toolbar
				const commandExists = defaultToolbar.some(item => item.label === commandIcon);

				// if it doesn't already exist, add it
				if (!commandExists) {


					selectedComponent.set({
						toolbar: [...defaultToolbar, {
							attributes: { title: 'AI' }, label: commandIcon, command: async () => {
								if (selectedComponent.get('type') === 'text') {
									// Get the content of the text component
									this.load = true
									selectedComponent.components(await this.GPTResponse(selectedComponent.view.el.innerHTML.length));
									this.load = false
								} else if (selectedComponent.get('type') === 'image') {
									this.load = true
									// Check if the selected component is an image
									const newSrc = await this.getNewSrc();
									selectedComponent.setAttributes({ src: newSrc })

									editor.store()
									this.load = false
								}
							}
						}]
					});
				}


			});
			const getCss = (editor, id) => {
				const style = editor.CssComposer.getRule(`#${id}`);
				const hoverStyle = editor.CssComposer.getRule(`#${id}:hover`);

				if (style) {
					if (hoverStyle) {
						return style.toCSS() + ' ' + hoverStyle.toCSS()
					}
					return style.toCSS()
				}
				else {
					return ''
				}
			}
			const findComponentStyles = (editor, selected) => {
				let css = ''
				if (selected) {
					const childModel = selected.components().models
					if (childModel) {
						for (const model of childModel) {
							css = css + findComponentStyles(editor, model)
						}
						return css + getCss(editor, selected.getId());
					}
					else {
						return getCss(editor, selected.getId());
					}
				}
			}
			const createBlockTemplate = (editor, selected, name_blockId) => {
				const bm = editor.BlockManager
				const blockId = name_blockId.blockId;
				const name = name_blockId.name;

				let elementHTML = selected.getEl().outerHTML;
				let first_partHtml = elementHTML.substring(0, elementHTML.indexOf(' '));
				let second_partHtml = elementHTML.substring(elementHTML.indexOf(' ') + 1);
				first_partHtml += ` custom_block_template=true block_id="${blockId}" `
				let finalHtml = first_partHtml + second_partHtml
				const blockCss = findComponentStyles(editor, selected)
				const css = `<style>${blockCss}</style>`

				if (this.storeProducts != null) {

					const product_layout = this.storeProducts.slice(0, 5).map((product) => {
						// Replace ${product.name}, ${product.price}, ${product._id}, and ${product.images[0]} with actual values
						let htmlWithValues = finalHtml.replace(/\${product\.name}/g, product.name);
						htmlWithValues = htmlWithValues.replace(/\${product\.price}/g, product.price);
						htmlWithValues = htmlWithValues.replace(/\${product\._id}/g, product._id);
						htmlWithValues = htmlWithValues.replace(/src="data:image\/svg\+xml;base64,[^"]*"/g, `src="${product.images[0]}"`);
						// Assuming product.images is an array and you want to replace the first image
						htmlWithValues = htmlWithValues.replace(/\${product\.images\[0\]}/g, product.images[0]);
						return htmlWithValues;
					}).join('');
					const elementHtmlCss = product_layout + css
					bm.add(`customBlockTemplate_${blockId}`, {
						category: 'Custom Blocks',
						attributes: { custom_block_template: true },
						label: `${name}`,
						media: '<i class="fa fa-square"></i>',
						content: `${elementHtmlCss}`,
					})

				}


			}

			editor.on('component:selected', () => {


				const selectedComponent = editor.getSelected();
				if (selectedComponent && selectedComponent.attributes) {
					//createBlockTemplate functionality
					const commandBlockTemplateIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-hexagon"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path></svg>'
					const commandBlockTemplate = () => {
						let blockId = 'customBlockTemplate_' + name.split(' ').join('_')
						let name_blockId = {
							'name': 'block',
							'blockId': blockId
						}

						createBlockTemplate(editor, selectedComponent, name_blockId)
					}
					const defaultToolbar = selectedComponent.get('toolbar');
					const commandExists = defaultToolbar.some(item => item.command.name === 'commandBlockTemplate');
					if (!commandExists) {
						selectedComponent.set({
							toolbar: [...defaultToolbar, { attributes: { title: 'Create Block' }, label: commandBlockTemplateIcon, command: commandBlockTemplate }]
						});
					}
				}
			});

			// Get the BlockManager module first
			editor.RichTextEditor.add('placeholder', {
				name: 'Placeholder',
				icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-git-merge"><circle cx="18" cy="18" r="3"></circle><circle cx="6" cy="6" r="3"></circle><path d="M6 21V9a9 9 0 0 0 9 9"></path></svg>`,
				event: 'change',
				result: () => {
					this.projectStore.setPrompt("write about " + editor.getSelected().view.el.innerHTML)
				},
			});


			if (this.storeProducts != null) {
				// Add a new Block

				const product_layout_1 = this.storeProducts.slice(0, 5).map(product => `<li> <div class="group block overflow-hidden"> <img src="${product.images[0]}" alt="" class="h-[350px] w-full object-cover transition duration-500 group-hover:scale-105 sm:h-[450px]" /> <div class="relative bg-white pt-3"> <h3 class="text-xs text-gray-700 group-hover:underline group-hover:underline-offset-4"> ${product.name} </h3> <p class="mt-2"> <span class="sr-only">${product.category}</span> <span class="tracking-wider text-gray-900">${product.price} </span> </p> </div> </div> </li>`).join('');

				// Add block with dynamically generated content
				editor.Blocks.add('product_layout_1', {
					label: 'Products layout 1',
					media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg>',
					content: ` <ul class="mt-4 grid gap-4 sm:grid-cols-2 lg:grid-cols-4 p-4">${product_layout_1}</ul>`,
				});


				const product_layout_2 = this.storeProducts.slice(0, 5).map(product => `<li> <div class="group relative block overflow-hidden"> <img src="${product.images[0]}" alt="" class="h-64 w-full object-cover transition duration-500 group-hover:scale-105 sm:h-72" /> <div class="relative border border-gray-100 bg-white p-6"> <span class="whitespace-nowrap bg-${this.project.store.primary_color} px-3 py-1.5 text-xs font-medium"> ${product.category} </span> <h3 class="mt-4 text-lg font-medium text-gray-900">	<a href="/product/${product.name}">${product.name}</a></h3> <p class="mt-1.5 text-sm text-gray-700">$${product.price}</p> <div class="mt-4"> <button class="block w-full rounded bg-${this.project.store.primary_color} p-4 text-sm font-medium transition hover:scale-105" data-href="/addToCart/${product._id}"> Add to Cart </button> </div> </div> </div> </li>`).join('');

				// Add block with dynamically generated content
				editor.Blocks.add('product_layout_2', {
					label: 'Products layout 2',
					media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg>',
					content: ` <ul class="mt-4 grid gap-4 sm:grid-cols-1 lg:grid-cols-3 p-4">${product_layout_2}</ul>`,
				});

				const product_layout_3 = this.storeProducts.slice(0, 5).map(product => `<li> <div class="relative m-10 flex w-full max-w-xs flex-col overflow-hidden rounded-lg border border-gray-100 bg-white shadow-md"> <a class="relative mx-3 mt-3 flex h-60 overflow-hidden rounded-xl" href="#"> <img class="object-cover" src="${product.images[0]}" alt="product image" /> <span class="absolute top-0 left-0 m-2 rounded-full bg-black px-2 text-center text-sm font-medium text-white">39% OFF</span> </a> <div class="mt-4 px-5 pb-5"> <a href="#"> <h5 class="text-xl tracking-tight text-slate-900">${product.name}</h5> </a> <div class="mt-2 mb-5 flex items-center justify-between"> <p> <span class="text-3xl font-bold text-slate-900">$${product.price}</span> <span class="text-sm text-slate-900 line-through">$699</span> </p> </div> <a data-href="/addToCart/${product._id}" class="flex items-center justify-center rounded-md bg-slate-900 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300"> <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"> <path stroke-linecap="round" stroke-linejoin="round" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" /> </svg> Add to cart</a > </div> </div> </li>`).join('');

				// Add block with dynamically generated content
				editor.Blocks.add('product_layout_3', {
					label: 'Products layout 3',
					media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg>',
					content: ` <ul class="mt-4 grid gap-4 sm:grid-cols-1 lg:grid-cols-3 p-4">${product_layout_3}</ul>`,
				});
			}
			pnm.removeButton("devices-c", "set-device-desktop")
			pnm.removeButton("devices-c", "set-device-tablet")
			pnm.removeButton("devices-c", "set-device-mobile")

			pnm.removeButton("options", "gjs-open-import-webpage")
			pnm.removeButton("options", "canvas-clear")
			pnm.removeButton("options", "sw-visibility")
			pnm.removeButton("options", "export-template")
			pnm.removeButton("options", "fullscreen")
			pnm.removeButton("options", "preview")
			pnm.removeButton("options", "undo")
			pnm.removeButton("options", "redo")
			pnm.removeButton("options", "Zoom In")
			pnm.removeButton("options", "Zoom Out")

			pnm.removeButton("views", "open-sm")
			pnm.removeButton("views", "open-tm")
			pnm.removeButton("views", "open-layers")
			pnm.removeButton("views", "open-blocks")

			// var container = document.createElement("div")
			// var btnEdit = document.createElement("button")
			// codeViewer.set({
			// 	codeName: "htmlmixed",
			// 	readOnly: 0,
			// 	autoBeautify: true,
			// 	autoCloseTags: true,
			// 	autoCloseBrackets: true,
			// 	lineWrapping: true,
			// 	styleActiveLine: true,
			// 	smartIndent: true,
			// 	indentWithTabs: true,
			// })
			// btnEdit.innerHTML = "Edit"
			// btnEdit.className = pfx + "btn-prim " + pfx + "btn-import"
			// btnEdit.onclick = function () {
			// 	var code = codeViewer.editor.getValue()
			// 	editor.DomComponents.getWrapper().set("content", "")
			// 	editor.setComponents(code.trim())
			// 	modal.close()
			// }

			// cmdm.add("html-edit", {
			// 	run: function (editor, sender) {
			// 		sender && sender.set("active", 0)
			// 		var viewer = codeViewer.editor
			// 		modal.setTitle(btnEdit)
			// 		if (!viewer) {
			// 			var txtarea = document.createElement("textarea")
			// 			container.appendChild(txtarea)
			// 			// container.appendChild(btnEdit)
			// 			codeViewer.init(txtarea)
			// 			viewer = codeViewer.editor
			// 		}
			// 		var InnerHtml = editor.getHtml()
			// 		var Css = editor.getCss()
			// 		modal.setContent("")
			// 		modal.setContent(container)
			// 		codeViewer.setContent(InnerHtml + "<style>" + Css + "</style>")
			// 		modal.open()
			// 		viewer.refresh()
			// 	},
			// })
			cmdm.add("change_mode", {
				run: function (editor) {
					console.log("----> Drag Mode On <----")
					editor.getModel().set("dmode", "absolute")
				},
				stop: function (editor) {
					console.log("----> Drag Mode Off <----")
					editor.getModel().set("dmode", "default")
				},
			})

			pnm.addButton("devices-c", [
				{
					id: "cmdDeviceDesktop",
					label:
						'<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-monitor"><rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect><line x1="8" y1="21" x2="16" y2="21"></line><line x1="12" y1="17" x2="12" y2="21"></line></svg>',
					command: "set-device-desktop",
					attributes: {
						title: "Desktop",
					},
				},
			])

			pnm.addButton("devices-c", [
				{
					id: "cmdDeviceTablet",
					label:
						'<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-tablet"><rect x="4" y="2" width="16" height="20" rx="2" ry="2"></rect><line x1="12" y1="18" x2="12.01" y2="18"></line></svg>',
					command: "set-device-tablet",
					attributes: {
						title: "Tablet",
					},
				},
			])

			pnm.addButton("devices-c", [
				{
					id: "cmdDeviceMobile",
					label:
						'<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-smartphone"><rect x="5" y="2" width="14" height="20" rx="2" ry="2"></rect><line x1="12" y1="18" x2="12.01" y2="18"></line></svg>',
					command: "set-device-mobile",
					attributes: {
						title: "Mobile",
					},
				},
			])

			// pnm.addButton("options", [
			// 	{
			// 		id: "Gentest",
			// 		label:
			// 			'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>',
			// 		command: async () => {
			// 			var y = await this.replaceTextInHtmlExcludingLinks(
			// 				editor.getHtml()
			// 			)
			// 			editor.setComponents(y)
			// 			console.log(y)
			// 		},
			// 		attributes: {
			// 			title: "Gentext",
			// 		},
			// 	},
			// ])

			// pnm.addButton("options", [
			// 	{
			// 		id: "Merge Prompt",

			// 		label:
			// 			'',
			// 		command: () => {
			// 			this.projectStore.setPrompt("write about " + editor.getSelected().view.el.innerHTML)
			// 		},
			// 		attributes: {
			// 			title: "Merge Prompt",
			// 		},
			// 	},
			// ])


			pnm.addButton("options", [
				{
					id: "Connect Store",

					label:
						'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>',
					command: () => {
						// editor.Modal.open({
						// 	title: 'My title', // string | HTMLElement
						// 	content: 'My content', // string | HTMLElement
						// });
						// editor.getSelected().append(`<div>...`);
						this.$router.push(`/connect/store/${this.project._id}`)

					},
					attributes: {
						title: "Connect Store",
					},
				},
			])

			pnm.addButton("options", [
				{
					id: "Page Analytics",

					label:
						'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-pie-chart"><path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path><path d="M22 12A10 10 0 0 0 12 2v10z"></path></svg> ',
					command: () => {
						this.$router.push(`/project/dashboard/${this.$route.params.id}`)
					},
					attributes: {
						title: "Page Analytics",
					},
				},
			])

			pnm.addButton("options", [
				{
					id: "SEO",

					label:
						'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trending-up"><polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline><polyline points="17 6 23 6 23 12"></polyline></svg>',
					command: () => {
						this.$router.push(`/page/seo/${this.$route.params.id}`)
					},
					attributes: {
						title: "SEO",
					},
				},
			])

			pnm.addButton("options", [
				{
					id: "Settings",
					label:
						'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg> ',
					command: () => {
						this.$router.push("/project/configure")
					},
					attributes: {
						title: "Settings",
					},
				},
			])

			pnm.addButton("options", [
				{
					id: "Page Data",
					label:
						'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-server"><rect x="2" y="2" width="20" height="8" rx="2" ry="2"></rect><rect x="2" y="14" width="20" height="8" rx="2" ry="2"></rect><line x1="6" y1="6" x2="6.01" y2="6"></line><line x1="6" y1="18" x2="6.01" y2="18"></line></svg>',
					command: () => {
						this.$router.push(`/page/data/${this.$route.params.id}`)
					},
					attributes: {
						title: "Page Data",
					},
				},
			])

			pnm.addButton("options", [
				{
					id: "preview",
					label:
						'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>',
					command: () => {
						editor.runCommand("preview")
						this.showSidebar = !this.showSidebar
					},
					attributes: {
						title: "Preview",
					},
				},
			])

			pnm.addButton("options", [
				{
					id: "undo",
					label:
						'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-corner-up-left"><polyline points="9 14 4 9 9 4"></polyline><path d="M20 20v-7a4 4 0 0 0-4-4H4"></path></svg>',
					command: () => {
						editor.runCommand('core:undo')
					},
					attributes: {
						title: "Undo",
					},
				},
			])

			pnm.addButton("options", [
				{
					id: "redo",
					label:
						'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-corner-up-right"><polyline points="15 14 20 9 15 4"></polyline><path d="M4 20v-7a4 4 0 0 1 4-4h12"></path></svg>',
					command: () => {
						editor.runCommand('core:redo')
					},
					attributes: {
						title: "Redo",
					},
				},
			])

			pnm.addButton("options", [
				{
					id: "zoomin",
					label:
						'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-zoom-in"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line><line x1="11" y1="8" x2="11" y2="14"></line><line x1="8" y1="11" x2="14" y2="11"></line></svg>',
					command: "zoomin",
					attributes: {
						title: "Zoom In",
					},
				},
			])

			pnm.addButton("options", [
				{
					id: "zoomout",
					label:
						'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-zoom-out"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line><line x1="8" y1="11" x2="14" y2="11"></line></svg>',
					command: "zoomout",
					attributes: {
						title: "Zoom Out",
					},
				},
			])

			pnm.addButton("options", [
				{
					id: "export",
					label:
						'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>',
					command: "gjs-export-zip",
					attributes: {
						title: "Export",
					},
				},
			])
			pnm.addButton("options", [
				{
					label:
						'<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-move"><polyline points="5 9 2 12 5 15"></polyline><polyline points="9 5 12 2 15 5"></polyline><polyline points="15 19 12 22 9 19"></polyline><polyline points="19 9 22 12 19 15"></polyline><line x1="2" y1="12" x2="22" y2="12"></line><line x1="12" y1="2" x2="12" y2="22"></line></svg>',
					command: "change_mode",
					attributes: {
						title: "Drag Mode",
					},
				},
			])

			pnm.addButton("options", [
				{
					id: "save",
					label:
						'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg>',
					command: () => {
						this.saveTemplate(editor)
					},
					attributes: {
						title: "Save Template",
					},
				},
			])
			pnm.addButton("options", [
				{
					id: "back",
					label:
						'<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-command"><path d="M18 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3H6a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3V6a3 3 0 0 0-3-3 3 3 0 0 0-3 3 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 3 3 0 0 0-3-3z"></path></svg>',
					command: () => {
						this.$router.push(`/projects`)
					},
					attributes: {
						title: "Return",
					},
				},
			])

			pnm.addButton("views", [
				{
					id: "open-sm",
					label:
						'<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-droplet"><path d="M12 2.69l5.66 5.66a8 8 0 1 1-11.31 0z"></path></svg>',
					command: "open-sm",
					attributes: {
						title: "Style Manager",
					},
				},
			])
			pnm.addButton("views", [
				{
					id: "open-tm",
					label:
						'<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-link-2"><path d="M15 7h3a5 5 0 0 1 5 5 5 5 0 0 1-5 5h-3m-6 0H6a5 5 0 0 1-5-5 5 5 0 0 1 5-5h3"></path><line x1="8" y1="12" x2="16" y2="12"></line></svg>',
					command: "open-tm",
					attributes: {
						title: "Link Manager",
					},
				},
			])

			pnm.addButton("views", [
				{
					id: "open-layers",
					label:
						'<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>',
					command: "open-layers",
					attributes: {
						title: "Layer Manager",
					},
				},
			])

			pnm.addButton("views", [
				{
					id: "open-blocks",
					label:
						'<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-box"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>',
					command: "open-blocks",
					attributes: {
						title: "Block Manager",
					},
				},
			])
			pnm.addButton("options", [
				{
					id: "Render",
					label: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-up-right"><line x1="7" y1="17" x2="17" y2="7"></line><polyline points="7 7 17 7 17 17"></polyline></svg>`,
					command: () => {
						this.$router.push(`/render/${this.$route.params.id}`)
					},
					attributes: {
						title: "Render",
					},
				},
			])
			if (this.project.domain == "your-domain.com") {
				pnm.addButton("options", [
					{
						id: "Launch",
						className: "py-2 bg-blue-600 font-normal",
						command: () => {
							this.launchModalView = true
						},
						label: `Launch `,
						attributes: {
							title: "Launch Website",
						},
					},
				])
			}


			if (this.isViewportBelow900) {
				console.log("below 900")
				editor.on("load", () => editor.runCommand("preview"))
				this.loading = false
				this.mobilemode = true
				editor.DomComponents.getWrapper().onAll(
					comp => comp.is("text") && comp.set({ editable: true })
				)
			}
			editor.DomComponents.getWrapper().onAll(
				comp => comp.is("text") && comp.set({ editable: true })
			)
			editor.on("load", () => {
				this.loading = false
				const openBl = pnm.getButton("views", "open-blocks")
				openBl?.set("active", true)
			})
			editor.on("component:selected", () => {
				const openSmBtn = pnm.getButton("views", "open-sm")
				const openLayersBtn = pnm.getButton("views", "open-layers")

				// Don't switch when the Layer Manager is on or
				// there is no selected component
				if (
					(!openLayersBtn || !openLayersBtn.get("active")) &&
					editor.getSelected()
				) {
					openSmBtn?.set("active", true)
				}
			})
		},

		async skimJSON() {
			try {
				const response = await api.get(
					`${this.hostname}/api/v1/get/page/${this.$route.params.id}`
				)
				console.log(JSON.parse(localStorage.getItem("user")).data.id)
				if (
					JSON.parse(localStorage.getItem("user")).data.id !=
					response.data.page.project.owner.id
				) {
					this.hasMessage = true
					this.message = "You are not authorised to edit this project"
					this.$router.push("/create/project")
				} else {
					if (!this.hasSubscription(response.data.page.project.owner)) {
						this.subscribed = false
					} else {
						this.subscribed = true
					}
				}
				console.log(response.data)
				var jsonData = response.data.page.project.baselinks
				this.page = response.data.page

				await this.GetProjectPages(response.data.page.project._id)
			} catch (error) {
				console.error("Error:", error)
			}
			const categorizedData = this.categorizeLinks(jsonData)
			console.log(categorizedData)
			this.seedData = categorizedData
			this.seedData.js.push('https://cdn.tailwindcss.com')

		},
		async GetProjectPages(id) {
			const response = await api.get(
				`${this.hostname}/api/v1/projects/pages/get/${id}`
			)
			this.projectStore.setResponse(response.data.project)
			this.GetProjectStore()
		},
		async GetProjectStore() {
			if ('store' in this.project && Object.keys(this.project.store).length > 0) {
				await this.GetStoreProducts(this.project.store._id)
				this.readHTML()
			} else {
				this.readHTML()
			}
		},
		changeImgSrc(htmlString, newSrc) {
			// Regular expression pattern to find src attribute
			let pattern = /(src=")(.*?)(")/;

			// Replace the old src value with the new one
			let modifiedString = htmlString.replace(pattern, '$1' + newSrc + '$3');

			return modifiedString

		},
		hasSubscription(e) {
			// Check if user who owns the project has an active subscription
			// This does not work, need to call stripe directly to see if sub was renewed
			if (e.subscription != "") {
				const sub = JSON.parse(e.subscription)
				if (sub.status == "active" || sub.status == "trialing") {
					return true
				}
				return false
			} else {
				return false
			}
		},
		async getNewSrc() {
			const data = {
				prompt: `${this.prompt}`
			}
			try {
				const response = await api.post(`${this.hostname}/api/v1/store/aiimage`, data)
				console.log(response)
				return response.data.url
			} catch (error) {
				console.log("Error:", error)
			}
		},
		async saveTemplate(editor) {
			// save without publishin
			// Get a reference to the iframe element by its class name
			// Get a reference to the div with class "gjs-frame"

			const gjsFrameDiv = document.querySelector(".gjs-frame")

			// Check if the div exists
			if (gjsFrameDiv) {
				// Specify the HTML tag you want to find inside the div (e.g., 'p', 'span', 'a', etc.)
				const targetTag = "body" // Change this to your desired HTML tag

				// Get all elements with the specified tag inside the div
				const elementsInsideDiv = gjsFrameDiv.querySelectorAll(targetTag)

				// Now you have a NodeList containing all the elements with the specified tag
				console.log(elementsInsideDiv)
			} else {
				console.error("No div element with class 'gjs-frame' found.")
			}

			const data = {
				content: JSON.stringify(editor.getProjectData()),
				html: editor.getHtml(),
				css: editor.getCss(),
				js: editor.getJs(),
			}
			try {
				this.load = true
				const response = await api.post(
					`${this.hostname}/api/v1/pages/content/post/${this.$route.params.id}`,
					data
				)
				console.log("Response:", response.data)
				this.hasMessage = true
				this.message = "Changes were made successfully"
				this.load = false
			} catch (error) {
				console.error("Error:", error)
			}
		},
		async GPTResponse(a) {
			this.history.push(this.prompt);
			var data;
			if (this.aimode == 0) {
				data = {
					prompt: `${this.prompt}. Keep the length to ${a} characters`,
					context: this.history,
					tokens: this.shortText * 20
				}
			}
			if (this.aimode == 1) {
				data = {
					prompt: `Limit response to ${a} characters long!!!! - ${this.prompt}.`,
					context: this.history,
					tokens: this.shortText * 20
				}
			}
			if (this.aimode == 2) {
				data = {
					prompt: `Limit response to 1 sentence long!!!! - ${this.prompt}.`,
					context: this.history,
					tokens: this.shortText * 1
				}
			}

			try {
				const response = await api.post(`${this.hostname}/api/v1/store/nlp`, data)
				this.history.push(response.data.message.content)
				return this.removeQuotes(response.data.message.content)
			} catch (error) {
				this.history = []
				console.log("Error:", error)
			}
		},
		removeQuotes(str) {
			// Use the replace() method with a regular expression to remove double quotes
			return str.replace(/"/g, '');
		},

		async tag(editor) {
			var html = editor.getHtml()
			var text = this.extractTextFromHTML(html)

			const data = {
				payload: this.trimStringToMaxLength(text, 4097),
			}
			try {
				const response = await api.post(
					`${this.hostname}/api/v1/aitag/project`,
					data
				)
				console.log(
					"Response:",
					JSON.parse(response.data.message.content).categories
				)
			} catch (error) {
				console.error("Error:", error)
			}
		},
		extractTextFromHTML(htmlString) {
			// Create a new DOM parser
			// Create a new DOM parser
			var parser = new DOMParser()

			// Parse the HTML string into a document object
			var doc = parser.parseFromString(htmlString, "text/html")

			// Define the tags you are interested in
			var tags = ["p", "h1", "h2", "h3", "h4", "h5", "h6", "span", "div"]

			// Get all elements for these tags and extract their text content
			var textContents = tags.flatMap(tag =>
				Array.from(doc.querySelectorAll(tag), el => el.textContent)
			)

			// Join all text contents with a space (or any other delimiter)
			return textContents.join(" ")
		},

		readHTML() {
			var fileURL = this.seedData.html[0]
			this.fetchHtmlString(fileURL, (error, htmlString) => {
				if (error) {
					console.error(error)
				} else {
					console.log(htmlString)
					this.htmlString = htmlString
					// Now you can work with the 'htmlString'
				}
			})
		},

		addSubmitButtonClassToButtons(htmlString) {
			// Create a new DOM parser
			const parser = new DOMParser()

			// Parse the HTML string into a DOM Document
			const doc = parser.parseFromString(htmlString, "text/html")

			// Find all input elements
			const inputs = doc.querySelectorAll("input")

			// Iterate over each input element
			inputs.forEach(input => {
				// Find the closest button element to each input
				const button = input.nextElementSibling
				if (button && button.tagName === "BUTTON") {
					// Add the 'submitButton' class to the button
					button.classList.add("submitButton")
				}
			})

			// Serialize the Document back to a string
			return doc.body.innerHTML
		},
		takeScreenshot() {
			var iframe = document.querySelector(".gjs-frame") // Replace with your class
			var iframeDocument =
				iframe.contentDocument || iframe.contentWindow.document

			html2canvas(iframeDocument.body).then(canvas => {
				// Create an image
				var image = canvas.toDataURL("image/png")

				// You can then download the image or display it on the page
				var link = document.createElement("a")
				link.href = image
				link.download = "screenshot.png"
				link.click()
			})
		},
		trimStringToMaxLength(str, maxLength) {
			if (str.length > maxLength) {
				return str.slice(0, maxLength)
			}
			return str
		},
		fetchHtmlString(fileURL, callback) {
			var xhr = new XMLHttpRequest()
			xhr.open("GET", fileURL, true)
			xhr.onreadystatechange = function () {
				if (xhr.readyState === XMLHttpRequest.DONE) {
					if (xhr.status === 200) {
						var htmlString = xhr.responseText
						callback(null, htmlString)
					} else {
						callback(
							"Failed to load the file: " + xhr.status + " " + xhr.statusText,
							null
						)
					}
				}
			}
			xhr.send()
		},
	},
}
</script>
<style scoped>
select:not([size]) {
	background-image: none !important;
}

@media screen and (max-width: 899px) {
	.gjs-off-prv {
		display: none !important;
	}
}

.editor-row {
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	flex-wrap: nowrap;
}

.editor-canvas {
	flex-grow: 1;
}

.panel-left {
	flex-basis: 250px;
	position: relative;
	overflow-y: auto;
	background-color: black;
}
</style>
