<template>
    <div class="flex lgqw8 f0dty l66z3 d1k81 tgfrq k3u76 dark:bg-neutral-800 dark:border-neutral-700">
        <!-- Header -->
        <div class="p-5 rbxtu jjege zvyci items-center gap-x-4">
            <div>
                <h2 class="inline-block kxhcs v7056 dark:text-neutral-200">
                    Location
                </h2>
            </div>

        </div>
        <!-- Empty State -->
        <div class="max-w-sm qazpp p3pc1 2xl:min-h-[397px] flex lgqw8 pdrgo items-center text-center">
            <svg class="flex-shrink-0 y3buf h75us kufja fyxhw dark:text-neutral-500" width="16" height="16"
                viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.328 0.12197C11.3403 0.0863508 11.3635 0.0554647 11.3942 0.0336055C11.4249 0.0117462 11.4617 0 11.4994 0C11.5371 0 11.5739 0.0117462 11.6046 0.0336055C11.6353 0.0554647 11.6584 0.0863508 11.6708 0.12197L11.9936 1.09019C12.1375 1.52302 12.4766 1.86202 12.9095 2.00588L13.878 2.32862C13.9136 2.34097 13.9445 2.36411 13.9664 2.39482C13.9883 2.42554 14 2.4623 14 2.5C14 2.5377 13.9883 2.57446 13.9664 2.60518C13.9445 2.63589 13.9136 2.65903 13.878 2.67138L12.9095 2.99412C12.6962 3.06508 12.5023 3.18478 12.3433 3.34373C12.1843 3.50269 12.0646 3.69651 11.9936 3.90981L11.6708 4.87803C11.6584 4.91365 11.6353 4.94454 11.6046 4.96639C11.5739 4.98825 11.5371 5 11.4994 5C11.4617 5 11.4249 4.98825 11.3942 4.96639C11.3635 4.94454 11.3403 4.91365 11.328 4.87803L11.0051 3.90981C10.9342 3.69651 10.8144 3.50269 10.6554 3.34373C10.4964 3.18478 10.3026 3.06508 10.0892 2.99412L9.122 2.67138C9.08637 2.65903 9.05548 2.63589 9.03361 2.60518C9.01175 2.57446 9 2.5377 9 2.5C9 2.4623 9.01175 2.42554 9.03361 2.39482C9.05548 2.36411 9.08637 2.34097 9.122 2.32862L10.0905 2.00588C10.5234 1.86202 10.8625 1.52302 11.0064 1.09019L11.328 0.12197Z">
                </path>
                <path
                    d="M2.86236 4.09758C2.87224 4.06908 2.89076 4.04437 2.91534 4.02688C2.93992 4.0094 2.96933 4 2.9995 4C3.02967 4 3.05908 4.0094 3.08366 4.02688C3.10824 4.04437 3.12676 4.06908 3.13664 4.09758L3.3949 4.87216C3.51001 5.21841 3.78128 5.48962 4.12763 5.6047L4.9024 5.8629C4.9309 5.87278 4.95562 5.89129 4.97311 5.91586C4.9906 5.94043 5 5.96984 5 6C5 6.03016 4.9906 6.05957 4.97311 6.08414C4.95562 6.10871 4.9309 6.12722 4.9024 6.1371L4.12763 6.3953C3.95694 6.45206 3.80185 6.54783 3.67466 6.67499C3.54746 6.80215 3.45167 6.95721 3.3949 7.12784L3.13664 7.90242C3.12676 7.93092 3.10824 7.95563 3.08366 7.97312C3.05908 7.9906 3.02967 8 2.9995 8C2.96933 8 2.93992 7.9906 2.91534 7.97312C2.89076 7.95563 2.87224 7.93092 2.86236 7.90242L2.6041 7.12784C2.54732 6.95721 2.45154 6.80215 2.32434 6.67499C2.19715 6.54783 2.04205 6.45206 1.87137 6.3953L1.0976 6.1371C1.0691 6.12722 1.04438 6.10871 1.02689 6.08414C1.0094 6.05957 1 6.03016 1 6C1 5.96984 1.0094 5.94043 1.02689 5.91586C1.04438 5.89129 1.0691 5.87278 1.0976 5.8629L1.87237 5.6047C2.21872 5.48962 2.48999 5.21841 2.6051 4.87216L2.86236 4.09758Z">
                </path>
                <path
                    d="M7.96129 9.43888C7.98654 9.34447 8 9.24523 8 9.14286C8 8.51168 7.48833 8 6.85714 8C6.22596 8 5.71429 8.51168 5.71429 9.14286C5.71429 9.24523 5.72775 9.34447 5.753 9.43888L1.43888 13.753C1.34447 13.7277 1.24523 13.7143 1.14286 13.7143C0.511675 13.7143 0 14.226 0 14.8571C0 15.4883 0.511675 16 1.14286 16C1.77404 16 2.28571 15.4883 2.28571 14.8571C2.28571 14.7548 2.27225 14.6555 2.247 14.5611L6.56112 10.247C6.65553 10.2723 6.75477 10.2857 6.85714 10.2857C6.95952 10.2857 7.05875 10.2723 7.15317 10.247L9.753 12.8468C9.72775 12.9412 9.71429 13.0405 9.71429 13.1429C9.71429 13.774 10.226 14.2857 10.8571 14.2857C11.4883 14.2857 12 13.774 12 13.1429C12 13.0405 11.9865 12.9412 11.9613 12.8468L14.5611 10.247C14.6555 10.2723 14.7548 10.2857 14.8571 10.2857C15.4883 10.2857 16 9.77404 16 9.14286C16 8.51168 15.4883 8 14.8571 8C14.226 8 13.7143 8.51168 13.7143 9.14286C13.7143 9.24523 13.7277 9.34447 13.753 9.43888L11.1532 12.0387C11.0588 12.0135 10.9595 12 10.8571 12C10.7548 12 10.6555 12.0135 10.5611 12.0387L7.96129 9.43888Z">
                </path>
            </svg>
            <h4 class="md:text-lg eass7 v7056 dark:text-neutral-200">
                Your data will appear here soon.
            </h4>
            <p class="mt-1 diql8 dtjcu fyxhw dark:text-neutral-500">
                In the meantime, you can create new custom insights to monitor your most oypae metrics.
                <a class="inline-flex items-center pla0f dtjcu ho64g cmffi cdcib eass7 focus:outline-none focus:underline dark:text-indigo-400 dark:hover:text-indigo-500"
                    href="#">
                    Learn more
                    <svg class="flex-shrink-0 sfo8l h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path d="m9 18 6-6-6-6"></path>
                    </svg>
                </a>
            </p>
        </div>
        <!-- End Empty State -->
    </div>
</template>