<template>
    <div class="dark:bg-neutral-900 min-h-screen">
        <div class="min-h-screen flex items-center justify-center">
            <div class="min-h-screen flex items-center justify-center">
                <form>
                    <!-- Card -->
                    <div class="bg-white rounded-xl shadow dark:bg-neutral-900">


                        <div class="pt-0 p-4 sm:pt-0 sm:p-7">
                            <!-- Grid -->
                            <div class="space-y-4 sm:space-y-6">


                                <div class="space-y-2">
                                    <label for="af-submit-app-project-name"
                                        class="inline-block text-sm font-medium text-neutral-800 mt-2.5 dark:text-neutral-200">
                                        SEO Title
                                    </label>

                                    <input id="af-submit-app-project-name" type="text"
                                        class="py-2 px-3 pe-11 block w-full border-neutral-200 shadow-sm rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 :opacity-50 :pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:ring-neutral-600"
                                        placeholder="Enter SEO Title" v-model="title">
                                </div>

                                <div class="space-y-2">
                                    <label for="af-submit-app-upload-images"
                                        class="inline-block text-sm font-medium text-neutral-800 mt-2.5 dark:text-neutral-200">
                                        Preview image
                                    </label>

                                    <upload @uploaded="handleImage" :src="``" />
                                </div>

                                <div class="space-y-2">
                                    <label for="af-submit-app-category"
                                        class="inline-block text-sm font-medium text-neutral-800 mt-2.5 dark:text-neutral-200">
                                        Category
                                    </label>

                                    <select id="af-submit-app-category" v-model="category"
                                        class="py-2 px-3 pe-9 block w-full border-neutral-200 shadow-sm rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 :opacity-50 :pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:ring-neutral-600">
                                        <option selected>Select a category</option>
                                        <option>Ecommerce</option>
                                        <option>Finance</option>
                                        <option>Marketplace</option>
                                        <option>Social</option>
                                        <option>Others</option>
                                    </select>
                                </div>

                                <div class="space-y-2">
                                    <label for="af-submit-app-description"
                                        class="inline-block text-sm font-medium text-neutral-800 mt-2.5 dark:text-neutral-200">
                                        SEO Description
                                    </label>

                                    <textarea id="af-submit-app-description" v-model="description"
                                        class="py-2 px-3 block w-full border-neutral-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 :opacity-50 :pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:ring-neutral-600"
                                        rows="6"
                                        placeholder="A detailed summary will better explain your products to the audiences. Our users will see this in your dedicated product page."></textarea>
                                </div>
                            </div>
                            <!-- End Grid -->

                            <div class="mt-5 flex justify-center gap-x-2">
                                <button type="button" @click="updatePage"
                                    class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 :opacity-50 :pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                    Update SEO Settings
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- End Card -->
                </form>
            </div>

        </div>
    </div>
</template>
<script>
import api from '@/services/api'
import { useHostStore } from '@/stores/HostStore'
export default {
    data: () => {
        return {
            title: null,
            description: null,
            image: null,
            hostStore: useHostStore(),
            category: null,
            page: null
        }
    },
    mounted() {
        this.GetPage()
    },
    computed: {
        hostname() {
            return this.hostStore.hostname
        }
    },
    methods: {
        handleImage(r) {
            this.image = r
        },
        async GetPage() {
            try {
                const response = await api.get(
                    `${this.hostname}/api/v1/get/page/${this.$route.params.id}`)
                console.log(response)
                this.hasSuccess = true
                this.page = response.data.page
            } catch (error) {
                console.error("Error:", error)
                this.message = error
                this.hasError = true
            }
        },
        async updatePage() {
            const d = {
                title: this.title,
                description: this.description,
                image: this.image,
                category: this.category
            }

            this.page.metadata = JSON.stringify(d)

            try {
                const response = await api.post(
                    `${this.hostname}/api/v1/page/update/${this.page._id}`,
                    this.page
                )
                this.$router.push(`/render/${this.page._id}`)
                console.log(response)
                this.hasSuccess = true
            } catch (error) {
                console.error("Error:", error)
                this.message = error
                this.hasError = true
            }
        }
    }
}
</script>