<template>
	<body class="bg-white dark:bg-neutral-200">
		<div class="bg-white dark:bg-neutral-900 min-h-screen flex ">
			<div class="mb-24 flex-1 flex flex-col space-y-2 max-w-3xl sm:p-6 sm:my-2 sm:mx-4 sm:rounded-2xl">
				<!-- Content -->
				<content />
			</div>
		</div>
		<!-- <foobar /> -->
	</body>
</template>
<script>
import Content from "./content.vue"
export default {
	components: {
		Content,
	},
}
</script>
