<template>
	<div :class="{'bg-black': $route.name == 'pricing'}">
			<!-- Features -->
	<div>
		<div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
			<!-- Title -->
			<div class="mx-auto max-w-2xl mb-8 lg:mb-14 text-center">
				<h2
					class="text-3xl lg:text-4xl text-neutral-800 font-bold dark:text-neutral-200"
				>
					Solo, agency or Enterprise? We've got you covered.
				</h2>
				<p class="text-white">
					Share and get Zookish for free! If you are featured in the
					<router-link to="leaderboard" class="underline"
						>leaderboard</router-link
					>
				</p>
				<share v-if="isUser" />
			</div>
			<!-- End Title -->

			<div class="relative xl:w-10/12 xl:mx-auto">
				<!-- Grid -->
				<div class="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-8">
					<div>
						<!-- Card -->
						<div
							class="p-4 relative z-10 bg-white border rounded-xl md:p-10 dark:bg-neutral-900 dark:border-neutral-700"
						>
							<h3
								class="text-xl font-bold text-neutral-800 dark:text-neutral-200"
							>
								Silverback Starter
							</h3>
							<div class="text-sm text-neutral-100">
								Everything a small enterprise needs.
							</div>

							<div class="mt-5">
								<span
									class="text-6xl font-bold text-neutral-800 dark:text-neutral-200"
									>$9</span
								>
								<span
									class="text-lg font-bold text-neutral-800 dark:text-neutral-200"
									>.95</span
								>
								<span class="ms-3 text-neutral-100">USD / monthly</span>
							</div>

							<div
								class="mt-5 grid sm:grid-cols-2 gap-y-2 py-4 first:pt-0 last:pb-0 sm:gap-x-6 sm:gap-y-0"
							>
								<!-- List -->
								<ul class="space-y-2 text-sm sm:text-base">
									<li class="flex space-x-3">
										<span
											class="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-white dark:neutra bg-gradient-to-tl from-orange-800 via-purple-600 to-emerald-500l dark:text-blue-500"
										>
											<svg
												class="flex-shrink-0 h-3.5 w-3.5"
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											>
												<polyline points="20 6 9 17 4 12" />
											</svg>
										</span>
										<span class="text-neutral-800 dark:text-neutral-200">
											One Page Site
										</span>
									</li>

									<li class="flex space-x-3">
										<span
											class="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-white dark:neutra bg-gradient-to-tl from-orange-800 via-purple-600 to-emerald-500l dark:text-blue-500"
										>
											<svg
												class="flex-shrink-0 h-3.5 w-3.5"
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											>
												<polyline points="20 6 9 17 4 12" />
											</svg>
										</span>
										<span class="text-neutral-800 dark:text-neutral-200">
											Collect data
										</span>
									</li>

									<li class="flex space-x-3">
										<span
											class="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-white dark:neutra bg-gradient-to-tl from-orange-800 via-purple-600 to-emerald-500l dark:text-blue-500"
										>
											<svg
												class="flex-shrink-0 h-3.5 w-3.5"
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											>
												<polyline points="20 6 9 17 4 12" />
											</svg>
										</span>
										<span class="text-neutral-800 dark:text-neutral-200">
											SEO Tools
										</span>
									</li>
								</ul>
								<!-- End List -->

								<!-- List -->
								<ul class="space-y-2 text-sm sm:text-base">
									<li class="flex space-x-3">
										<span
											class="h-5 w-5 flex justify-center items-center rounded-full bg-neutral-50 text-neutral-100 dark:bg-neutral-800"
										>
											<svg
												class="flex-shrink-0 h-3.5 w-3.5"
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											>
												<path d="M18 6 6 18" />
												<path d="m6 6 12 12" />
											</svg>
										</span>
										<span class="text-neutral-800 dark:text-neutral-200">
											Custom reports
										</span>
									</li>

									<li class="flex space-x-3">
										<span
											class="h-5 w-5 flex justify-center items-center rounded-full bg-neutral-50 text-neutral-100 dark:bg-neutral-800"
										>
											<svg
												class="flex-shrink-0 h-3.5 w-3.5"
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											>
												<path d="M18 6 6 18" />
												<path d="m6 6 12 12" />
											</svg>
										</span>
										<span class="text-neutral-800 dark:text-neutral-200">
											Product support
										</span>
									</li>

									<li class="flex space-x-3">
										<span
											class="h-5 w-5 flex justify-center items-center rounded-full bg-neutral-50 text-neutral-100 dark:bg-neutral-800"
										>
											<svg
												class="flex-shrink-0 h-3.5 w-3.5"
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											>
												<path d="M18 6 6 18" />
												<path d="m6 6 12 12" />
											</svg>
										</span>
										<span class="text-neutral-800 dark:text-neutral-200">
											Activity reporting
										</span>
									</li>
								</ul>
								<!-- End List -->
							</div>

							<div
								class="mt-5 grid grid-cols-2 gap-x-4 py-4 first:pt-0 last:pb-0"
							>
								<div>
									<p class="text-sm text-neutral-100">Cancel anytime.</p>
									<p class="text-sm text-neutral-100">No charge before EOT.</p>
								</div>

								<div class="flex justify-end">
									<a
										href="https://buy.stripe.com/cN2dROeqiedWfhmeVb"
										type="button"
										class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-neutral-200 bg-white text-neutral-800 shadow-sm hover:bg-neutral-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
									>
										Start Free trial
									</a>
								</div>
							</div>
						</div>
						<!-- End Card -->
					</div>

					<div>
						<!-- Card -->
						<div
							class="shadow-xl shadow-neutral-200 p-5 relative z-10 bg-white border rounded-xl md:p-10 pb-btn text-white"
						>
							<h3
								class="text-xl font-bold text-neutral-800 dark:text-neutral-200"
							>
								Bananas Unlimited
							</h3>
							<div class="text-sm text-neutral-100">
								For growing businesses.
							</div>
							<span
								class="absolute top-0 end-0 rounded-se-sm rounded-es-sm text-xs font-medium bg-neutral-800 py-1.5 px-3 dark:bg-white dark:text-neutral-800"
								>Most popular</span
							>

							<div class="mt-5">
								<span
									class="text-6xl font-bold text-neutral-800 dark:text-neutral-200"
									>$36</span
								>
								<span
									class="text-lg font-bold text-neutral-800 dark:text-neutral-200"
									>.99</span
								>
								<span class="ms-3 text-neutral-100">USD / monthly</span>
							</div>

							<div
								class="mt-5 grid sm:grid-cols-2 gap-y-2 py-4 first:pt-0 last:pb-0 sm:gap-x-6 sm:gap-y-0"
							>
								<!-- List -->
								<ul class="space-y-2 text-sm sm:text-base">
									<li class="flex space-x-3">
										<span
											class="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-white dark:neutra bg-gradient-to-tl from-orange-800 via-purple-600 to-emerald-500l dark:text-blue-500"
										>
											<svg
												class="flex-shrink-0 h-3.5 w-3.5"
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											>
												<polyline points="20 6 9 17 4 12" />
											</svg>
										</span>
										<span class="text-neutral-800 dark:text-neutral-200">
											Up to 5 pages
										</span>
									</li>

									<li class="flex space-x-3">
										<span
											class="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-white dark:neutra bg-gradient-to-tl from-orange-800 via-purple-600 to-emerald-500l dark:text-blue-500"
										>
											<svg
												class="flex-shrink-0 h-3.5 w-3.5"
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											>
												<polyline points="20 6 9 17 4 12" />
											</svg>
										</span>
										<span class="text-neutral-800 dark:text-neutral-200">
											Collect data
										</span>
									</li>

									<li class="flex space-x-3">
										<span
											class="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-white dark:neutra bg-gradient-to-tl from-orange-800 via-purple-600 to-emerald-500l dark:text-blue-500"
										>
											<svg
												class="flex-shrink-0 h-3.5 w-3.5"
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											>
												<polyline points="20 6 9 17 4 12" />
											</svg>
										</span>
										<span class="text-neutral-800 dark:text-neutral-200">
											Hacker Tools
										</span>
									</li>
								</ul>
								<!-- End List -->

								<!-- List -->
								<ul class="space-y-2 text-sm sm:text-base">
									<li class="flex space-x-3">
										<span
											class="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-white dark:neutra bg-gradient-to-tl from-orange-800 via-purple-600 to-emerald-500l dark:text-blue-500"
										>
											<svg
												class="flex-shrink-0 h-3.5 w-3.5"
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											>
												<polyline points="20 6 9 17 4 12" />
											</svg>
										</span>
										<span class="text-neutral-800 dark:text-neutral-200">
											Template Customisation
										</span>
									</li>

									<li class="flex space-x-3">
										<span
											class="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-white dark:neutra bg-gradient-to-tl from-orange-800 via-purple-600 to-emerald-500l dark:text-blue-500"
										>
											<svg
												class="flex-shrink-0 h-3.5 w-3.5"
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											>
												<polyline points="20 6 9 17 4 12" />
											</svg>
										</span>
										<span class="text-neutral-800 dark:text-neutral-200">
											Sitemap
										</span>
									</li>

									<li class="flex space-x-3">
										<span
											class="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-white dark:neutra bg-gradient-to-tl from-orange-800 via-purple-600 to-emerald-500l dark:text-blue-500"
										>
											<svg
												class="flex-shrink-0 h-3.5 w-3.5"
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											>
												<polyline points="20 6 9 17 4 12" />
											</svg>
										</span>
										<span class="text-neutral-800 dark:text-neutral-200">
											Custom Code Injection
										</span>
									</li>
								</ul>
								<!-- End List -->
							</div>

							<div
								class="mt-5 grid grid-cols-2 gap-x-4 py-4 first:pt-0 last:pb-0"
							>
								<div>
									<p class="text-sm text-neutral-100">Cancel anytime.</p>
									<p class="text-sm text-neutral-100">No charge before EOT.</p>
								</div>

								<div class="flex justify-end">
									<a
										href="https://buy.stripe.com/28oaFC0zs8TC3yE7sK"
										type="button"
										class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-white dark:text-neutral-800 hover:shadow-3xl disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600 bounce-animation"
									>
										Start Free trial
									</a>
								</div>
							</div>
						</div>
						<!-- End Card -->
					</div>
				</div>
				<!-- End Grid -->

				<!-- SVG Element -->
				<div
					class="hidden md:block absolute top-0 end-0 tranneutral-y-16 tranneutral-x-16"
				>
					<svg
						class="w-16 h-auto text-orange-500"
						width="121"
						height="135"
						viewBox="0 0 121 135"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M5 16.4754C11.7688 27.4499 21.2452 57.3224 5 89.0164"
							stroke="currentColor"
							stroke-width="10"
							stroke-linecap="round"
						/>
						<path
							d="M33.6761 112.104C44.6984 98.1239 74.2618 57.6776 83.4821 5"
							stroke="currentColor"
							stroke-width="10"
							stroke-linecap="round"
						/>
						<path
							d="M50.5525 130C68.2064 127.495 110.731 117.541 116 78.0874"
							stroke="currentColor"
							stroke-width="10"
							stroke-linecap="round"
						/>
					</svg>
				</div>
				<!-- End SVG Element -->

				<!-- SVG Element -->
				<div
					class="hidden md:block absolute bottom-0 start-0 tranneutral-y-16 -tranneutral-x-16"
				>
					<svg
						class="w-56 h-auto text-cyan-500"
						width="347"
						height="188"
						viewBox="0 0 347 188"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M4 82.4591C54.7956 92.8751 30.9771 162.782 68.2065 181.385C112.642 203.59 127.943 78.57 122.161 25.5053C120.504 2.2376 93.4028 -8.11128 89.7468 25.5053C85.8633 61.2125 130.186 199.678 180.982 146.248L214.898 107.02C224.322 95.4118 242.9 79.2851 258.6 107.02C274.299 134.754 299.315 125.589 309.861 117.539L343 93.4426"
							stroke="currentColor"
							stroke-width="7"
							stroke-linecap="round"
						/>
					</svg>
				</div>
				<!-- End SVG Element -->
			</div>

			<div class="mt-7 text-center">
				<p class="text-xs text-neutral-400">
					Prices in USD. Taxes may apply.
				</p>
			</div>
		</div>
	</div>
	<!-- End Features -->

	<!-- Comparison Table -->
	<div class="relative">
		<div
			class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 md:py-14 lg:py-20 mx-auto"
		>
			<div class="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
				<h2
					class="text-2xl font-bold md:text-3xl md:leading-tight dark:text-white"
				>
					Compare plans
				</h2>
			</div>

			<!-- Header -->
			<div
				class="hidden lg:block p-3 rounded-lg shadow-lg sticky top-0 start-0 py-2 bg-white/60 backdrop-blur-md dark:bg-neutral-900/60"
			>
				<!-- Grid -->
				<div class="grid grid-cols-6 gap-6">
					<div class="col-span-2">
						<span
							class="font-semibold text-lg text-neutral-800 dark:text-neutral-200"
						>
							Features
						</span>
					</div>
					<!-- End Col -->

					<div class="col-span-1">
						<span
							class="font-semibold text-lg text-neutral-800 dark:text-neutral-200"
						>
							Silverback Starter
						</span>
						<p class="mt-2 text-sm text-neutral-100">
							Lowest Priced
						</p>
					</div>
					<!-- End Col -->

					<div class="col-span-1">
						<span
							class="font-semibold text-lg text-neutral-800 dark:text-neutral-200"
						>
							Bananas Unlimited
						</span>
						<p class="mt-2 text-sm text-neutral-100">
							$39 per month billed monthly
						</p>
					</div>
					<!-- End Col -->

					<div class="col-span-1">
						<span
							class="font-semibold text-lg text-neutral-800 dark:text-neutral-200"
						>
							Rainforest Rampage Unlimited
						</span>
						<p class="mt-2 text-sm text-neutral-100">
							$89 per month billed monthly
						</p>
					</div>
					<!-- End Col -->

					<div class="col-span-1">
						<span
							class="font-semibold text-lg text-neutral-800 dark:text-neutral-200"
						>
							Kong's Kingdom
						</span>
						<p class="mt-2 text-sm text-neutral-100">
							$149 per month billed monthly
						</p>
					</div>
					<!-- End Col -->
				</div>
				<!-- End Grid -->
			</div>
			<!-- End Header -->

			<!-- Section -->
			<div class="space-y-4 lg:space-y-0">
				<!-- List -->
				<ul class="grid lg:grid-cols-6 lg:gap-6">
					<!-- Item -->
					<li class="lg:col-span-2 lg:py-3">
						<span
							class="text-lg font-semibold text-neutral-800 dark:text-neutral-200"
						>
							General
						</span>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"
					></li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"
					></li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"
					></li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"
					></li>
					<!-- End Item -->
				</ul>
				<!-- End List -->

				<!-- List -->
				<ul class="grid lg:grid-cols-6 lg:gap-6">
					<!-- Item -->
					<li class="lg:col-span-2 pb-1.5 lg:py-3">
						<span
							class="font-semibold lg:font-normal text-sm text-neutral-800 dark:text-neutral-200"
						>
							Number of seats
						</span>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Silverback Starter
							</span>
							<span class="text-sm text-neutral-800 dark:text-neutral-200">
								1
							</span>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Bananas Unlimited
							</span>
							<span class="text-sm text-neutral-800 dark:text-neutral-200">
								Up to 3
							</span>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Rainforest Rampage Unlimited
							</span>
							<span class="text-sm text-neutral-800 dark:text-neutral-200">
								Up to 10
							</span>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Kong's Kingdom
							</span>
							<span class="text-sm text-neutral-800 dark:text-neutral-200">
								Unlimited
							</span>
						</div>
					</li>
					<!-- End Item -->
				</ul>
				<!-- End List -->

				<!-- List -->
				<ul class="grid lg:grid-cols-6 lg:gap-6">
					<!-- Item -->
					<li class="lg:col-span-2 pb-1.5 lg:py-3">
						<span
							class="font-semibold lg:font-normal text-sm text-neutral-800 dark:text-neutral-200"
						>
							Page Limit
						</span>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Silverback Starter
							</span>
							<span class="text-sm text-neutral-800 dark:text-neutral-200">
								1
							</span>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Bananas Unlimited
							</span>
							<span class="text-sm text-neutral-800 dark:text-neutral-200">
								5
							</span>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Rainforest Rampage Unlimited
							</span>
							<span class="text-sm text-neutral-800 dark:text-neutral-200">
								15
							</span>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Kong's Kingdom
							</span>
							<span class="text-sm text-neutral-800 dark:text-neutral-200">
								Unlimited
							</span>
						</div>
					</li>
					<!-- End Item -->
				</ul>
				<!-- End List -->

				<!-- List -->
				<ul class="grid lg:grid-cols-6 lg:gap-6">
					<!-- Item -->
					<li class="lg:col-span-2 pb-1.5 lg:py-3">
						<span
							class="font-semibold lg:font-normal text-sm text-neutral-800 dark:text-neutral-200"
						>
							Email sharing
						</span>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Silverback Starter
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Bananas Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Rainforest Rampage Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Kong's Kingdom
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->
				</ul>
				<!-- End List -->

				<!-- List -->
				<ul class="grid lg:grid-cols-6 lg:gap-6">
					<!-- Item -->
					<li class="lg:col-span-2 pb-1.5 lg:py-3">
						<span
							class="font-semibold lg:font-normal text-sm text-neutral-800 dark:text-neutral-200"
						>
							Any time, anywhere access
						</span>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Silverback Starter
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-neutral-400 dark:text-neutral-600"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<path d="M5 12h14" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Bananas Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Rainforest Rampage Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Kong's Kingdom
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->
				</ul>
				<!-- End List -->
			</div>
			<!-- End Section -->

			<!-- Section -->
			<div class="mt-6 space-y-4 lg:space-y-0">
				<!-- List -->
				<ul class="grid lg:grid-cols-6 lg:gap-6">
					<!-- Item -->
					<li class="lg:col-span-2 lg:py-3">
						<span
							class="text-lg font-semibold text-neutral-800 dark:text-neutral-200"
						>
							Hacker Tools
						</span>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"
					></li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"
					></li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"
					></li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"
					></li>
					<!-- End Item -->
				</ul>
				<!-- End List -->

				<!-- List -->
				<ul class="grid lg:grid-cols-6 lg:gap-6">
					<!-- Item -->
					<li class="lg:col-span-2 pb-1.5 lg:py-3">
						<span
							class="font-semibold lg:font-normal text-sm text-neutral-800 dark:text-neutral-200"
						>
							Sitemap
						</span>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Silverback Starter
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-neutral-400 dark:text-neutral-600"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<path d="M5 12h14" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Bananas Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Rainforest Rampage Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Kong's Kingdom
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->
				</ul>
				<!-- End List -->

				<!-- List -->
				<ul class="grid lg:grid-cols-6 lg:gap-6">
					<!-- Item -->
					<li class="lg:col-span-2 pb-1.5 lg:py-3">
						<span
							class="font-semibold lg:font-normal text-sm text-neutral-800 dark:text-neutral-200"
						>
							Custom Code Injection
						</span>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Silverback Starter
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-neutral-400 dark:text-neutral-600"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<path d="M5 12h14" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Bananas Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Rainforest Rampage Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Kong's Kingdom
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->
				</ul>
				<!-- End List -->
			</div>
			<!-- End Section -->

			<!-- Section -->
			<div class="mt-6 space-y-4 lg:space-y-0">
				<!-- List -->
				<ul class="grid lg:grid-cols-6 lg:gap-6">
					<!-- Item -->
					<li class="lg:col-span-2 lg:py-3">
						<span
							class="text-lg font-semibold text-neutral-800 dark:text-neutral-200"
						>
							Design Tools
						</span>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"
					></li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"
					></li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"
					></li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"
					></li>
					<!-- End Item -->
				</ul>
				<!-- End List -->

				<!-- List -->
				<ul class="grid lg:grid-cols-6 lg:gap-6">
					<!-- Item -->
					<li class="lg:col-span-2 pb-1.5 lg:py-3">
						<span
							class="font-semibold lg:font-normal text-sm text-neutral-800 dark:text-neutral-200"
						>
							Template Customization
						</span>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Silverback Starter
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Bananas Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Rainforest Rampage Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Kong's Kingdom
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->
				</ul>
				<!-- End List -->

				<!-- List -->
				<ul class="grid lg:grid-cols-6 lg:gap-6">
					<!-- Item -->
					<li class="lg:col-span-2 pb-1.5 lg:py-3">
						<span
							class="font-semibold lg:font-normal text-sm text-neutral-800 dark:text-neutral-200"
						>
							Social Media Integration
						</span>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Silverback Starter
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-neutral-400 dark:text-neutral-600"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<path d="M5 12h14" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Bananas Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Rainforest Rampage Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Kong's Kingdom
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->
				</ul>
				<!-- End List -->

				<!-- List -->
				<ul class="grid lg:grid-cols-6 lg:gap-6">
					<!-- Item -->
					<li class="lg:col-span-2 pb-1.5 lg:py-3">
						<span
							class="font-semibold lg:font-normal text-sm text-neutral-800 dark:text-neutral-200"
						>
							Exchange flow
						</span>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Silverback Starter
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-neutral-400 dark:text-neutral-600"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<path d="M5 12h14" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Bananas Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-neutral-400 dark:text-neutral-600"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<path d="M5 12h14" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Rainforest Rampage Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-neutral-400 dark:text-neutral-600"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<path d="M5 12h14" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Kong's Kingdom
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->
				</ul>
				<!-- End List -->

				<!-- List -->
				<ul class="grid lg:grid-cols-6 lg:gap-6">
					<!-- Item -->
					<li class="lg:col-span-2 pb-1.5 lg:py-3">
						<span
							class="font-semibold lg:font-normal text-sm text-neutral-800 dark:text-neutral-200"
						>
							Multilingual Support
						</span>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Silverback Starter
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-neutral-400 dark:text-neutral-600"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<path d="M5 12h14" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Bananas Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Rainforest Rampage Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Kong's Kingdom
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->
				</ul>
				<!-- End List -->

				<!-- List -->
				<ul class="grid lg:grid-cols-6 lg:gap-6">
					<!-- Item -->
					<li class="lg:col-span-2 pb-1.5 lg:py-3">
						<span
							class="font-semibold lg:font-normal text-sm text-neutral-800 dark:text-neutral-200"
						>
							Image Uploader
						</span>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Silverback Starter
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-neutral-400 dark:text-neutral-600"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<path d="M5 12h14" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Bananas Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-neutral-400 dark:text-neutral-600"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<path d="M5 12h14" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Rainforest Rampage Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Kong's Kingdom
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->
				</ul>
				<!-- End List -->
			</div>
			<!-- End Section -->

			<!-- Section -->
			<div class="mt-6 space-y-4 lg:space-y-0">
				<!-- List -->
				<ul class="grid lg:grid-cols-6 lg:gap-6">
					<!-- Item -->
					<li class="lg:col-span-2 lg:py-3">
						<span
							class="text-lg font-semibold text-neutral-800 dark:text-neutral-200"
						>
							Infrastructure
						</span>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"
					></li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"
					></li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"
					></li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"
					></li>
					<!-- End Item -->
				</ul>
				<!-- End List -->

				<!-- List -->
				<ul class="grid lg:grid-cols-6 lg:gap-6">
					<!-- Item -->
					<li class="lg:col-span-2 pb-1.5 lg:py-3">
						<span
							class="font-semibold lg:font-normal text-sm text-neutral-800 dark:text-neutral-200"
						>
							Early Access to Features
						</span>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Silverback Starter
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-neutral-400 dark:text-neutral-600"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<path d="M5 12h14" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Bananas Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-neutral-400 dark:text-neutral-600"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<path d="M5 12h14" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Rainforest Rampage Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Kong's Kingdom
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->
				</ul>
				<!-- End List -->

				<!-- List -->
				<ul class="grid lg:grid-cols-6 lg:gap-6">
					<!-- Item -->
					<li class="lg:col-span-2 pb-1.5 lg:py-3">
						<span
							class="font-semibold lg:font-normal text-sm text-neutral-800 dark:text-neutral-200"
						>
							SSL Certificate
						</span>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Silverback Starter
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-neutral-400 dark:text-neutral-600"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<path d="M5 12h14" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Bananas Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Rainforest Rampage Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Kong's Kingdom
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->
				</ul>
				<!-- End List -->

				<!-- List -->
				<ul class="grid lg:grid-cols-6 lg:gap-6">
					<!-- Item -->
					<li class="lg:col-span-2 pb-1.5 lg:py-3">
						<span
							class="font-semibold lg:font-normal text-sm text-neutral-800 dark:text-neutral-200"
						>
							Dedicated Account Manager
						</span>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Silverback Starter
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-neutral-400 dark:text-neutral-600"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<path d="M5 12h14" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Bananas Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-neutral-400 dark:text-neutral-600"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<path d="M5 12h14" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Rainforest Rampage Unlimited
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-neutral-400 dark:text-neutral-600"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<path d="M5 12h14" />
							</svg>
						</div>
					</li>
					<!-- End Item -->

					<!-- Item -->
					<li
						class="col-span-1 py-1.5 lg:py-3 border-b border-neutral-200 dark:border-neutral-700"
					>
						<div class="grid grid-cols-2 md:grid-cols-6 lg:block">
							<span
								class="lg:hidden md:col-span-2 text-sm text-neutral-800 dark:text-neutral-200"
							>
								Kong's Kingdom
							</span>
							<svg
								class="flex-shrink-0 h-5 w-5 text-white dark:text-blue-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					</li>
					<!-- End Item -->
				</ul>
				<!-- End List -->
			</div>
			<!-- End Section -->

			<!-- Header -->
			<div class="hidden lg:block mt-6">
				<!-- Grid -->
				<div class="grid grid-cols-6 gap-6">
					<div class="col-span-2"></div>
					<!-- End Col -->

					<div class="col-span-1">
						<a
							class="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-neutral-200 bg-white text-neutral-800 shadow-sm hover:bg-neutral-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
							:href="
								isUser
									? `https://buy.stripe.com/cN2dROeqiedWfhmeVb`
									: `/api/v1/login`
							"
							target="_blank"
						>
							Start your free trial
						</a>
					</div>
					<!-- End Col -->

					<div class="col-span-1">
						<a
							class="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent pb-btn text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
							:href="
								isUser
									? `https://buy.stripe.com/28oaFC0zs8TC3yE7sK`
									: `/api/v1/login`
							"
							target="_blank"
						>
							Start your free trial
						</a>
					</div>
					<!-- End Col -->

					<div class="col-span-1">
						<a
							class="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-neutral-200 bg-white text-neutral-800 shadow-sm hover:bg-neutral-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
							:href="
								isUser
									? `https://buy.stripe.com/4gw4he1DwedW2uA4gz`
									: `/api/v1/login`
							"
						>
							Start your free trial
						</a>
					</div>
					<!-- End Col -->

					<div class="col-span-1">
						<a
							class="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-neutral-200 bg-white text-neutral-800 shadow-sm hover:bg-neutral-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
							:href="
								isUser
									? `https://buy.stripe.com/9AQ5ligyq8TCd9e7sM`
									: `/api/v1/login`
							"
						>
							Start your free trial
						</a>
					</div>
					<!-- End Col -->
				</div>
				<!-- End Grid -->
			</div>
			<!-- End Header -->

			<!-- Button Group -->
			<div class="mt-8 md:mt-12 flex justify-center items-center gap-x-3">
				<p class="text-sm text-neutral-100 dark:text-neutral-400">
					Need a custom plan?
				</p>

				<button
					type="button"
					class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-neutral-200 bg-white text-neutral-800 shadow-sm hover:bg-neutral-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
				>
					Contact us
				</button>
			</div>
			<!-- End Button Group -->
		</div>
	</div>
	<!-- End Comparison Table -->
	</div>

</template>
<script>
	export default {
		computed: {
			isUser() {
				if (localStorage.getItem("user") == null) {
					return false
				} else {
					return true
				}
			},
		},
	}
</script>