<template>
	<div
		class="w-full mx-auto bg-neutral-900 flex shadow-none h-screen overflow-y-scroll p-2"
	>
		<Transition name="slide-right">
			<niche
				v-show="stepper === 0"
				@processing="handleProcessing"
				@completed="handleCompleted"
			/>
		</Transition>
		<Transition name="slide-right">
			<name @next="n1" v-if="stepper === 1" />
		</Transition>
		<Transition name="slide-right">
			<prompt @next="n2" v-if="stepper === 2" />
		</Transition>
		<Transition name="slide-right">
			<gpt v-if="stepper === 3" />
		</Transition>
		<!-- Template Selection -->

		<Transition name="fade">
			<a
				@click="stepper--"
				class="text-xl text-white font-bold left-0 absolute top-4 mr-20"
				v-if="stepper > 0"
			>
				<vue-feather type="corner-up-left" size="32" />
			</a>
		</Transition>

		<Transition name="fade">
			<a
				@click="$router.go(-1)"
				class="text-xl text-white font-bold absolute top-4 ml-20 right-0"
				v-if="stepper == 0"
			>
				<vue-feather type="x" size="32" />
			</a>
		</Transition>
		<Transition name="fade">
			<a
				@click="$router.go(-1)"
				class="text-white font-bold absolute top-4 ml-20 right-0"
				v-if="processing"
			>
				<p class="text-xs">
					{{ message }}
					<vue-feather type="x" size="18" class="spin dark:text-white mt-3" />
				</p>
			</a>
		</Transition>
	</div>
</template>
<script>
	import niche from "./niche"
	import name from "./name"
	import prompt from "./prompt"
	import location from "./location"
	import socials from "./socials"
	import logo from "./logo"
	import api from "@/services/api"
	import { useHostStore } from "@/stores/HostStore"
	import { useCloneStore } from "@/stores/CloneStore"
	import { v4 as uuidv4 } from "uuid"
	import Aimixin from "@/mixins/Aimixin"
	import gpt from "./gpt"
	export default {
		watch: {},
		components: {
			name,
			niche,
			location,
			socials,
			logo,
			prompt,
			gpt,
		},
		mixins: [Aimixin],
		data() {
			return {
				stepper: 0,
				name: null,
				prompt: null,
				processing: false,
				hostStore: useHostStore(),
				cloneStore: useCloneStore(),
				sites: [],
				tags: [],
				html: ``,
				cloneData: null,
				screenshot: null,
				project: null,
				sitemap: null,
				message: `Processing templates for site generation`,
			}
		},
		computed: {
			hostname() {
				return this.hostStore.hostname
			},
			ev() {
				return this.cloneStore.ev
			},
		},
		mounted() {
			console.log(this.hostname)
		},

		methods: {
			n1(e) {
				this.name = e
				this.stepper++
			},
			n2(e) {
				this.prompt = e
				this.stepper++
			},
			handleProcessing(e) {
				this.processing = true

				this.tags.push(e)
				this.stepper++
			},
			handleCompleted(e) {
				console.log(e)
				this.sites = e.data
				// if e.data is null do something
				// find alternative link generation methods
				this.clone()
			},
			// use composition api composables in future to avoid code redundancy
			async clone() {
				this.message = "Cloning template and generating site"
				const evtSrc = new EventSource(this.hostname + "/api/v1/clone/events")
				evtSrc.onmessage = event => {
					console.log(event.data)
					this.message = event.data
					this.cloneStore.setEv(event.data)
				}
				const data = {
					urls: [this.sites[0]],
					cookies: ["cookie1=value1; cookie2=value2"],
					proxy: "",
					user_agent: "My User Agent",
					serve: false,
					serve_port: 8081,
					open: false,
				}
				console.log(data)
				try {
					const response = await api.post(`${this.hostname}/api/v1/clone`, data)
					const r = await api.post(`${this.hostname}/api/v1/crawl`, data)
					this.html = r.data.data

					// await tag(html.value)

					console.log("Crawl Response:", r.data.data)
					console.log("Crawl Response:", r.data.screenshot.screenshot)
					console.log("Response:", response.data)
					this.cloneStore.setResponse(response.data)
					this.cloneData = response.data
					this.screenshot = r.data.screenshot.screenshot
					this.sitemap = r.data.sitemap
					this.create()
				} catch (error) {
					console.error("Error:", error)
					if (error && error.response && error.response.data) {
						// error.response.data exists, you can handle it here
						message.value = error.response.data
					} else {
						// error.response.data does not exist, handle the absence accordingly
						message.value = error
					}

					hasError.value = true
				} finally {
					// loading.value = false
				}
			},
			async create() {
				const user = JSON.parse(localStorage.getItem("user"))
				console.log(user.data.id)

				var generate = require("boring-name-generator")
				var slug = generate({
					number: true,
				}).dashed
				var o = {}
				o.template_image = this.screenshot
				o.sitemap = this.sitemap
				const data = {
					_id: uuidv4(),
					title: slug,
					slug: slug,
					cloneOf: this.sites[0],
					domain: "your-domain.com",
					template: true,
					tags: JSON.stringify(this.tags),
					metadata: JSON.stringify(o),
					owner: user.data,
					baselinks: this.cloneData.uploaded_urls,
					pages: [],
					created_at: new Date().toISOString(),
					updated_at: new Date().toISOString(),
				}
				console.log(data)
				try {
					const response = await api.post(
						`${this.hostname}/api/v1/create/project`,
						data
					)
					console.log("Response:", response.data)
					// create home page
					// router.push("/editor");
					this.project = response.data.data
					// this.createPage()
					var y = await this.replaceTextInHtmlExcludingLinks(this.html)
					this.html = y

					this.createPage()
				} catch (error) {
					console.error("Error:", error)
					message.value = error
					hasError.value = true
				} finally {
					// loading.value = false
				}
			},

			async createPage() {
				const data = {
					_id: uuidv4(),
					title: "home",
					content: "",
					html: this.html,
					project: this.project,
					created_at: new Date().toISOString(),
					updated_at: new Date().toISOString(),
				}
				console.log(data)
				try {
					const response = await api.post(
						`${this.hostname}/api/v1/create/page`,
						data
					)
					console.log("Response:", response.data)
					this.$router.push(`/editor/${response.data.page._id}`)
				} catch (error) {
					console.error("Error:", error)
					this.message = error
					this.hasError = true
				} finally {
					// this.loading = false
				}
			},
		},
	}
</script>
<style>
	.typingEffect {
		width: 0;
		overflow: hidden; /* Ensure the text is not visible until the typewriter effect*/
		border-right: 2px solid white; /* The cursor*/
		font-size: 16px;
		white-space: nowrap; /* Keeps the text on a single line */
		animation: typing 2s steps(30) forwards, blink 1s infinite;
	}

	/* The typing animation */
	@keyframes typing {
		from {
			width: 0;
		}
		to {
			width: 100%;
		}
	}
	code {
		background-color: #202129;
		border-radius: 4px;
		padding: 20px;
		height: 70px;
		color: white;
		display: flex;
		margin: 0 auto;
		align-items: center;
	}
	@keyframes blink {
		0%,
		45% {
			border-color: transparent;
		}
		50%,
		100% {
			border-color: white;
		}
	}
</style>
