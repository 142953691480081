<template>
  <div
    class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-infinite overflow-hidden bg-white flex flex-col items-center justify-center">
    <section class="overflow-hidden rounded-lg md:grid md:grid-cols-3 max-w-3xl">
      <img alt="Trainer" src="@/assets/clip7.gif" class=" w-full object-cover md:h-full" />

      <div class="p-4 text-center sm:p-6 md:col-span-2 lg:p-8">
        <p class="text-sm font-semibold uppercase tracking-widest">
          Welcome to the jungle!
        </p>

        <h2 class="mt-6 font-black uppercase">
          <span class="text-4xl font-black sm:text-5xl lg:text-6xl">
            Free
          </span>
          Offer Ends in
        </h2>
        <span class="text-xl mt-4">
          <vue-countdown :time="60 * 1000" v-slot="{ days, hours, minutes, seconds }">
            {{ hours }} hours, {{ minutes }} minutes, {{ seconds }} seconds.
          </vue-countdown>
        </span>

        <a class="mt-8 inline-block w-full bg-black py-4 text-sm font-bold uppercase tracking-widest text-white"
          href="/api/v1/login">
          Get Started for free &rarr;
        </a>

        <p class="mt-8 text-xs font-xs uppercase text-gray-400">
          Limited Offer
        </p>
      </div>
    </section>
  </div>
</template>
