<template>
	<render :pageProp="page" :projectProp="project" v-if="loaded" />
</template>
<script>
import { useHostStore } from "@/stores/HostStore"
import api from "@/services/api"
import render from "../editor/render.vue"
export default {
	data: () => {
		return {
			local: true,
			loading: false,
			isNotFound: false,
			hostStore: useHostStore(),
			page: Object,
			project: Object,
			loaded: false,
		}
	},
	components: {
		render
	},
	computed: {
		hostname() {
			return this.hostStore.hostname
		},
	},
	mounted() {
		// if !zookish or !localhost
		// search projects for a domain that matches the hostnanme
		// if match exists get project home page
		// implement named router views for different routes
		// Else proceed as usual
		// Get the current URL

		var url = window.location.href

		// Use a regular expression to extract the subdomain
		var subdomain = url.match(
			/^(?:https?:\/\/)?((?:[\w-]+)\.(?:[\w-]+)\.(?:[a-z]{2,}))/i
		)

		// Check if a subdomain was found
		if (subdomain && subdomain.length > 1) {
			// The subdomain is captured in the first group of the regular expression
			var extractedSubdomain = subdomain[1]

			// Split the extractedSubdomain by "." and get the first part (subdomain)
			var subdomainParts = extractedSubdomain.split(".")
			var subdomainWithoutDomain = subdomainParts[0]

			console.log("Subdomain: " + subdomainWithoutDomain)
			this.GetPages(subdomainWithoutDomain)
		} else {
			console.log("No subdomain found.")
			const w = window.location.hostname
			if (w == "localhost" || w == "zookish.com") {
				this.GetPages('zookish.com')
			} else {
				this.GetPages(window.location.hostname)
				this.loading = false
			}
		}

		console.log(window.location.hostname)
	},
	methods: {
		async GetPages(e) {
			const destinationUrl = window.location.href
			console.log(e)
			try {
				const response = await api.get(
					`${this.hostname}/api/v1/projects/pages/get/${e}`
				)
				console.log("Response:", response.data)
				var pages = response.data.pages
				// this is a domain so use SEO optimised product routes eg. example.com/product/product-name
				// Check if this is a product route
				// pass the page if it is
				const page = pages.find(
					obj =>
						obj.title ===
						this.trimStringToMaxLength(destinationUrl.split("/").pop(), 20)
				)
				if (Object.keys(page).length != 0) {
					this.page = page
					this.project = response.data.project
					this.local = false
					this.loading = false
					this.loaded = true
				} else {
					this.$router.push("404")
				}
			} catch (error) {
				console.log("Status code:", error)
				// if (error.response.status == 404) {
				// 	this.$router.push("/404")
				// }
			}
		},
		trimStringToMaxLength(str, maxLength) {
			if (str.length > maxLength) {
				return str.slice(0, maxLength)
			}
			return str
		},
	},
}
</script>
