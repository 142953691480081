const FormatDatesMixin = {
    methods: {
        formattedDate(e) {
            // If you have a specific date, e.g., "January 1, 2021"
            const startDate = new Date(e)
            const endDate = new Date()
            const formattedDateDifference = this.formatDateDifference(
                startDate,
                endDate
            )
            return formattedDateDifference
        },
        formatDateDifference(startDate, endDate) {
            let difference = endDate.getTime() - startDate.getTime()
            let TotalDiffDays = Math.ceil(difference / (1000 * 3600 * 24))
            // Check for the day or days
            if (Math.abs(TotalDiffDays) < 2) {
                return Math.abs(TotalDiffDays) === 0 ? "today" : "1 day ago"
            } else if (Math.abs(TotalDiffDays) < 30) {
                return Math.abs(TotalDiffDays) + " days ago"
            }
            // Calculate the total difference in months
            let TotalDiffMonths = Math.floor(TotalDiffDays / 30)
            // Check for the month or months
            if (TotalDiffMonths < 2) {
                return "1 month ago"
            } else if (TotalDiffMonths < 12) {
                return TotalDiffMonths + " months ago"
            }
            // Calculate the total difference in years
            let TotalDiffYears = Math.floor(TotalDiffMonths / 12)
            // Check for the year or years
            if (TotalDiffYears < 2) {
                return "1 year ago"
            } else {
                return TotalDiffYears + " years ago"
            }
        },
    },
}

export default FormatDatesMixin