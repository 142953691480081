// stores/HostStore.js
import { defineStore } from 'pinia';

export const userStore = defineStore('user', {
  state: () => ({
    user: Object
  }),
  actions: {
    setResponse(response) {
      this.user = response;
    },
  },
});
