<template>
	<a @click="toggleTheme" id="theme-toggle">
		<a
			v-if="darkMode"
			class="text-white/50 p-1 hover:bg-indigo-600 dark:hover:bg-neutral-800 inline-flex justify-center rounded-xl indigo-600:bg-neutral-800 indigo-600:text-white smooth-indigo-600"
			href="#"
		>
			<vue-feather type="sun"></vue-feather>
		</a>
		<a
			v-else
			class="text-grey/50 p-1 dark:text-white/50 hover:bg-indigo-600 hover:text-white dark:hover:bg-neutral-800 inline-flex justify-center rounded-xl indigo-600:bg-neutral-800 indigo-600:text-white smooth-indigo-600"
			href="#"
		>
			<vue-feather type="moon"></vue-feather>
		</a>
	</a>
</template>

<script>
	export default {
		data() {
			return {
				darkMode: false, // Initial state is light mode
			}
		},
		mounted() {
			this.initializeTheme()
		},
		methods: {
			initializeTheme() {
				if (
					localStorage.getItem("color-theme") === "dark" ||
					(!localStorage.getItem("color-theme") &&
						window.matchMedia("(prefers-color-scheme: dark)").matches)
				) {
					this.darkMode = true
				}
			},
			toggleTheme() {
				this.darkMode = !this.darkMode

				if (this.darkMode) {
					document.documentElement.classList.add("dark")
					localStorage.setItem("color-theme", "dark")
				} else {
					document.documentElement.classList.remove("dark")
					localStorage.setItem("color-theme", "light")
				}
			},
		},
	}
</script>

<style>
	/* Your global CSS styles */
</style>
