<template>
	<div class="container-fluid fixed-top header disable-selection">
		<div class="row">
			<div class="col"></div>
			<div class="col-md-6">
				<div class="row">
					<div class="col">
						<!-- Hero -->
						<div class="absolute w-full h-screen overflow-y-scroll">
							<!-- <nav2 /> -->
							<div
								class="bg-gradient-to-b from-violet-600/[.15] via-transparent"
							>
								<div
									class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-24 space-y-8"
								>
									<div class="max-w-3xl text-center mx-auto mt-12">
										<h1
											class="block font-medium text-gray-200 text-4xl sm:text-5xl md:text-6xl lg:text-7xl"
										>
											Now it's easier than ever to build websites/
										</h1>
									</div>
									<!-- End Title -->

									<div class="max-w-3xl text-center mx-auto">
										<p class="text-lg text-white">
											Our AI powered website building service is dedicated to
											transforming your unique ideas into a digital reality.
											With a blend of modern design, cutting-edge technology,
											and personalized attention.
										</p>
									</div>

									<!-- Buttons -->
									<div class="text-center">
										<a
											class="inline-flex justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-blue-600 to-violet-600 shadow-lg shadow-transparent hover:shadow-blue-700/50 border border-transparent text-white text-sm font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white py-3 px-6 dark:focus:ring-offset-gray-800"
											:href="!isUser ? `/api/v1/login` : `/dashboard`"
										>
											{{ !isUser ? `Get Started` : `Dashboard` }}
											<svg
												class="flex-shrink-0 w-4 h-4"
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											>
												<path d="m9 18 6-6-6-6" />
											</svg>
										</a>
									</div>
									<!-- End Buttons -->
								</div>
							</div>
							<kpi />
							<pricing />
							<faq />
						</div>
						<!-- End Hero -->
					</div>
				</div>
			</div>
			<div class="col"></div>
		</div>
	</div>
</template>
<script>
	import * as THREE from "three"
	import RectAreaLightHelper from "three/examples/jsm/helpers/RectAreaLightHelper.js"
	import kpi from "./kpi"
	import pricing from "./pricing"
	import faq from "./faq"
	export default {
		data() {
			return {
				fragmentShader: `
        uniform vec3 color;
        uniform sampler2D pointTexture;

        varying vec3 vColor;

        void main() {
          gl_FragColor = vec4( color * vColor, 1.0 );
          gl_FragColor = gl_FragColor * texture2D( pointTexture, gl_PointCoord );
        }
      `,
			}
		},
		components: {
			kpi,
			pricing,
			faq,
		},
		computed: {
			user() {
				return localStorage.getItem("user")
			},
			IP() {
				return localStorage.getItem("IP")
			},
			hasIP() {
				if (this.IP == null) {
					return false
				} else {
					return true
				}
			},
			isUser() {
				if (this.user == null) {
					return false
				} else {
					return true
				}
			},
		},
		mounted() {
			// Three JS Template
			var renderer = new THREE.WebGLRenderer({ antialias: true })
			renderer.setSize(window.innerWidth, window.innerHeight)
			renderer.shadowMap.enabled = false
			renderer.shadowMap.type = THREE.PCFSoftShadowMap
			renderer.shadowMap.needsUpdate = true

			document.body.appendChild(renderer.domElement)
			window.addEventListener("resize", onWindowResize, false)
			function onWindowResize() {
				camera.aspect = window.innerWidth / window.innerHeight
				camera.updateProjectionMatrix()
				renderer.setSize(window.innerWidth, window.innerHeight)
			}
			var camera = new THREE.PerspectiveCamera(
				35,
				window.innerWidth / window.innerHeight,
				1,
				500
			)
			var scene = new THREE.Scene()
			var cameraRange = 3

			var setcolor = 0x000000

			scene.background = new THREE.Color(setcolor)
			scene.fog = new THREE.Fog(setcolor, 2.5, 3.5)

			//-------------------------------------------------------------- SCENE

			var sceneGruop = new THREE.Object3D()
			var particularGruop = new THREE.Object3D()
			var modularGruop = new THREE.Object3D()

			function generateParticle(num, amp = 2) {
				var gmaterial = new THREE.MeshPhysicalMaterial({
					color: 0xffffff,
					side: THREE.DoubleSide,
				})

				var gparticular = new THREE.CircleGeometry(0.2, 5)

				for (var i = 1; i < num; i++) {
					var pscale = 0.001 + Math.abs(mathRandom(0.03))
					var particular = new THREE.Mesh(gparticular, gmaterial)
					particular.position.set(
						mathRandom(amp),
						mathRandom(amp),
						mathRandom(amp)
					)
					particular.rotation.set(mathRandom(), mathRandom(), mathRandom())
					particular.scale.set(pscale, pscale, pscale)
					particular.speedValue = mathRandom(1)

					particularGruop.add(particular)
				}
			}
			generateParticle(200, 2)

			sceneGruop.add(particularGruop)
			scene.add(modularGruop)
			scene.add(sceneGruop)

			function mathRandom(num = 1) {
				var setNumber = -Math.random() * num + Math.random() * num
				return setNumber
			}

			//------------------------------------------------------------- INIT
			function init() {
				for (var i = 0; i < 30; i++) {
					var geometry = new THREE.IcosahedronGeometry(1)
					var material = new THREE.MeshStandardMaterial({
						color: 0x111111,
						transparent: false,
						opacity: 1,
						wireframe: false,
					})
					var cube = new THREE.Mesh(geometry, material)
					cube.speedRotation = Math.random() * 0.1
					cube.positionX = mathRandom()
					cube.positionY = mathRandom()
					cube.positionZ = mathRandom()
					cube.castShadow = true
					cube.receiveShadow = true

					var newScaleValue = mathRandom(0.3)

					cube.scale.set(newScaleValue, newScaleValue, newScaleValue)
					//---
					cube.rotation.x = mathRandom((180 * Math.PI) / 180)
					cube.rotation.y = mathRandom((180 * Math.PI) / 180)
					cube.rotation.z = mathRandom((180 * Math.PI) / 180)
					//
					cube.position.set(cube.positionX, cube.positionY, cube.positionZ)
					modularGruop.add(cube)
				}
			}

			//------------------------------------------------------------- CAMERA
			camera.position.set(0, 0, cameraRange)
			var cameraValue = false
			function cameraSet() {
				if (!cameraValue) {
					TweenMax.to(camera.position, 1, {
						z: cameraRange,
						ease: Power4.easeInOut,
					})
					cameraValue = true
				} else {
					TweenMax.to(camera.position, 1, {
						z: cameraRange,
						x: 0,
						y: 0,
						ease: Power4.easeInOut,
					})
					INTERSECTED = null
					cameraValue = false
				}
			}

			//------------------------------------------------------------- SCENE
			var ambientLight = new THREE.AmbientLight(0xffffff, 0.1)
			//scene.add(ambientLight);

			var light = new THREE.SpotLight(0xffffff, 3)
			light.position.set(5, 5, 2)
			light.castShadow = true
			light.shadow.mapSize.width = 10000
			light.shadow.mapSize.height = light.shadow.mapSize.width
			light.penumbra = 0.5

			var lightBack = new THREE.PointLight(0x0fffff, 1)
			lightBack.position.set(0, -3, -1)

			scene.add(sceneGruop)
			scene.add(light)
			scene.add(lightBack)

			var rectSize = 2
			var intensity = 100
			var rectLight = new THREE.RectAreaLight(
				0x0fffff,
				intensity,
				rectSize,
				rectSize
			)
			rectLight.position.set(0, 0, 1)
			rectLight.lookAt(0, 0, 0)
			scene.add(rectLight)

			// rectLightHelper = new RectAreaLightHelper(rectLight)
			//scene.add( rectLightHelper );,

			//------------------------------------------------------------- RAYCASTER
			var raycaster = new THREE.Raycaster()
			var mouse = new THREE.Vector2(),
				INTERSECTED
			var intersected

			function onMouseMove(event) {
				event.preventDefault()
				mouse.x = (event.clientX / window.innerWidth) * 2 - 1
				mouse.y = -(event.clientY / window.innerHeight) * 2 + 1
			}
			function onMouseDown(event) {
				event.preventDefault()
				onMouseMove(event)
				raycaster.setFromCamera(mouse, camera)
				var intersected = raycaster.intersectObjects(modularGruop.children)
				if (intersected.length > 0) {
					cameraValue = false
					if (INTERSECTED != intersected[0].object) {
						if (INTERSECTED)
							INTERSECTED.material.emissive.setHex(INTERSECTED.currentHex)

						INTERSECTED = intersected[0].object
						INTERSECTED.currentHex = INTERSECTED.material.emissive.getHex()
						INTERSECTED.material.emissive.setHex(0xffff00)
						//INTERSECTED.material.map = null;
						//lightBack.position.set(INTERSECTED.position.x,INTERSECTED.position.y,INTERSECTED.position.z);

						TweenMax.to(camera.position, 1, {
							x: INTERSECTED.position.x,
							y: INTERSECTED.position.y,
							z: INTERSECTED.position.z + 3,
							ease: Power2.easeInOut,
						})
					} else {
						if (INTERSECTED)
							INTERSECTED.material.emissive.setHex(INTERSECTED.currentHex)
						INTERSECTED = null
					}
				}
				console.log(intersected.length)
			}
			function onMouseUp(event) {}

			window.addEventListener("mousedown", onMouseDown, false)
			window.addEventListener("mouseup", onMouseUp, false)
			window.addEventListener("mousemove", onMouseMove, false)

			//------------------------------------------------------------- RENDER
			var uSpeed = 0.1
			function animate() {
				var time = performance.now() * 0.0003
				requestAnimationFrame(animate)
				//---
				for (var i = 0, l = particularGruop.children.length; i < l; i++) {
					var newObject = particularGruop.children[i]
					newObject.rotation.x += newObject.speedValue / 10
					newObject.rotation.y += newObject.speedValue / 10
					newObject.rotation.z += newObject.speedValue / 10
					//---
					//newObject.position.y = Math.sin(time) * 3;
				}

				for (var i = 0, l = modularGruop.children.length; i < l; i++) {
					var newCubes = modularGruop.children[i]
					newCubes.rotation.x += 0.008
					newCubes.rotation.y += 0.005
					newCubes.rotation.z += 0.003
					//---
					newCubes.position.x =
						Math.sin(time * newCubes.positionZ) * newCubes.positionY
					newCubes.position.y =
						Math.cos(time * newCubes.positionX) * newCubes.positionZ
					newCubes.position.z =
						Math.sin(time * newCubes.positionY) * newCubes.positionX
				}
				//---
				particularGruop.rotation.y += 0.005
				//---
				modularGruop.rotation.y -=
					(mouse.x * 4 + modularGruop.rotation.y) * uSpeed
				modularGruop.rotation.x -=
					(-mouse.y * 4 + modularGruop.rotation.x) * uSpeed
				camera.lookAt(scene.position)
				renderer.render(scene, camera)
			}

			animate()
			init()
			init()
		},
	}
</script>

<style scoped>
	canvas {
		display: block;
		width: 100%;
		height: 100%;
	}
	.header {
		/*top:45%;*/
		top: 45%;
		color: #dddddd;
	}
	.footer {
		bottom: 3%;
	}
	.description {
		color: gray;
		padding-top: 50px;
	}
	.btn {
		border-radius: 30px;
		padding: 10px 30px;
	}
	.disable-selection {
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* Internet Explorer */
		-khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
		-webkit-user-select: none; /* Chrome, Safari, and Opera */
		-webkit-touch-callout: none; /* Disable Android and iOS callouts*/
	}
	h1::after {
		font-size: 12px;
		position: absolute;
		top: 3px;
		padding-left: 5px;
		font-weight: 400;
	}
	h2::after {
		/* content: "2"; */
		font-size: 12px;
		position: absolute;
		top: 14px;
		padding-left: 5px;
	}
</style>
