import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/home/index.vue'
import TOSView from '../views/home/components/tos.vue'
import EditorView from '../views/editor/index.vue'
import RenderView from '../views/editor/render.vue'
import CtaView from '../views/editor/cta.vue'
import DashboardView from '../views/dashboard/index.vue'
import Projects from '../views/project/index.vue'
import Settings from '../views/settings/index.vue'
import LoginCallback from '../views/settings/LoginCallback.vue'
import StripeCallback from '../views/settings/StripeCallback.vue'
import NotFound from '../components/404.vue'
import Pricing from '../views/home/components/pricing2.vue'
import Pages from '../views/pages/index.vue'
import Onboarding from '../views/onboarding/index.vue'
import Templates from '../views/project/templates.vue'
import Leaderboard from '../views/leaderboard/index.vue'
import MJML from '../views/mjml/index.vue'
import SEO from '../views/editor/seo'

// Ecommerce Routes
import AddProduct from '../views/ecommerce/product/addProduct.vue'
import StoreDashboard from '../views/ecommerce/dashboard/index.vue'

import ViewOrders from '../views/ecommerce/orders/index.vue'
import ViewProducts from '../views/ecommerce/product/index.vue'

import StoreSettings from '../views/ecommerce/store/index.vue'

import StorePayouts from '../views/ecommerce/store/payouts.vue'
import Stores from '../views/ecommerce/store/view.vue'

const router = createRouter({
  history: createWebHistory('/'),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/invite/ref/:username',
      name: 'SharedHome',
      component: HomeView
    },
    {
      path: '/tos',
      name: 'tos',
      component: TOSView
    },
    {
      path: '/mjml',
      name: 'mjml',
      component: MJML
    },
    {
      path: '/onboarding',
      name: 'onboarding',
      component: Onboarding,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },
    {
      path: '/templates',
      name: 'templates',
      component: Templates
    },
    {
      path: '/leaderboard',
      name: 'leaderboard',
      component: Leaderboard
    },
    {
      path: '/cta',
      name: 'cta',
      component: CtaView
    },
    {
      path: '/editor',
      name: 'editor',
      component: EditorView,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },
    {
      path: '/editor/:id',
      name: 'editor',
      component: EditorView,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },
    {
      path: '/render/:id',
      name: 'render',
      component: RenderView
    },
    {
      path: '/product/:id',
      name: 'Product View',
      component: RenderView
    },
    {
      path: '/render/:id/products/:pid',
      name: 'Product Render',
      component: RenderView
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Projects,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },
    {
      path: '/settings',
      name: 'settings',
      component: Projects,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },
    {
      path: '/projects',
      name: 'projects',
      component: Projects,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },

    {
      path: '/page/data/:id',
      name: 'Page Data',
      component: Projects,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },
    {
      path: '/create/project',
      name: 'Create Project',
      component: Projects,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },
    {
      path: '/project/:id',
      name: 'Read Project',
      component: Projects,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },
    {
      path: '/page/seo/:id',
      name: 'Page Seo',
      component: SEO
    },
    {
      path: '/project/dashboard/:id',
      name: 'Project Dashboard',
      component: Projects,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },
    {
      path: '/project/zmix',
      name: 'zmix',
      component: Projects,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },
    {
      path: '/create/project/clone',
      name: 'clone',
      component: Projects,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },
    {
      path: '/login/:callback',
      name: 'LoginCallback',
      component: LoginCallback
    },
    {
      path: '/subscription/success/:id',
      name: 'StripeCallback',
      component: StripeCallback
    },
    {
      path: '/project/configure',
      name: 'Configure Project',
      component: Projects,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },
    {
      path: '/project/configure/dns',
      name: 'Add Custom Hostname',
      component: Projects,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },
    // Ecommerce Routes
    {
      path: '/view/products/:id',
      name: 'View Products',
      component: ViewProducts,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },
    {
      path: '/add/product/:id',
      name: 'Add Product',
      component: AddProduct,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },
    {
      path: '/edit/product/:id',
      name: 'Edit Product',
      component: AddProduct,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },
    {
      path: '/store/dashboard/:id',
      name: 'Store Dashboard',
      component: StoreDashboard,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },
    {
      path: '/view/orders/:id',
      name: 'View Orders',
      component: ViewOrders,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },

    {
      path: '/store/settings/:id',
      name: 'Store Settings',
      component: StoreSettings,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },


    {
      path: '/add/store',
      name: 'Add Store',
      component: StoreSettings,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },


    {
      path: '/store/payouts/:id',
      name: 'Store Payouts',
      component: StorePayouts,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },

    {
      path: '/stores',
      name: 'View Stores',
      component: Projects,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },

    {
      path: '/connect/store/:id',
      name: 'Connect Store',
      component: Projects,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") == null) next({ name: 'home' })
        else next()
      }
    },

    {
      path: '/404',
      name: 'Not Found',
      component: NotFound
    },
    {
      path: '/:catchAll(.*)', // catch-all route
      name: 'Pages',
      component: Pages,
    },
  ]
})
router.beforeEach((to, from, next) => {
  console.log(`Host> ${window.location.hostname}`)
  const isZookish = to.host === 'zookish.com' || 'localhost'; // Check if the host is zookish.com

  if (!isZookish) {
    // Change the component to WebsiteRenderer if the host is not zookish.com
    to.matched[0].components.default = Pages; // Assuming WebsiteRenderer is your component for rendering websites
  }
  next();
});

router.afterEach((to, from, failure) => {
  if (!failure) {
    setTimeout(() => {
      HSStaticMethods.autoInit();
    }, 100)
  }
});


export default router
