// stores/HostStore.js
import { defineStore } from 'pinia';

export const useCartStore = defineStore('cart', {
    state: () => ({
        cart: []
    }),
    actions: {
        setCart(item) {
            this.cart.push(item);
        },
    },
})