<template>
	<div class="container max-w-screen-lg mx-auto p-4">
		<div>
			<div class="bg-white dark:bg-neutral-900 rounded p-4 px-4 md:p-8 mb-6">
				<div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
					<div class="text-neutral-100 dark:text-white">
						<p class="font-medium text-black text-lg dark:text-white">
							Personal Details
						</p>
						<p class="text-black dark:text-white">
							Please fill out all the fields.
						</p>
					</div>

					<div class="lg:col-span-2">
						<div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
							<div class="md:col-span-5 dark:text-neutral-400">
								<label for="full_name">Username (readonly)</label>
								<input type="text" name="full_name" id="full_name"
									class="h-10 border mt-1 rounded px-4 w-full bg-neutral-50 dark:text-white dark:bg-neutral-800"
									:value="user.data.username" readonly />
							</div>

							<div class="md:col-span-5 dark:text-neutral-400">
								<label for="email">Email Address (readonly)</label>
								<input type="text" name="email" id="email"
									class="h-10 border mt-1 rounded px-4 w-full bg-neutral-50 dark:text-white dark:bg-neutral-800"
									:value="user.data.email" readonly />
							</div>


							<div class="md:col-span-5 dark:text-neutral-400 mt-5">
								<label for="email">Your referral link </label>
								<a @click="copy" class="flex">
									<span
										class="inline-flex items-center h-10 px-4 text-sm text-neutral-900 bg-neutral-200 border border-r-0 border-neutral-300 rounded-l-md dark:bg-neutral-600 dark:text-neutral-400 dark:border-neutral-600">
										<vue-feather type="copy" class="w-4 h-4 text-neutral-500 dark:text-neutral-400" />
									</span>
									<input data-tooltip-target="tooltip-default" type="text" name="dns.zookish.com" id="dns"
										class="h-10 border-dashed border-black border-2 dark:border-white rounded-r-sm px-4 w-full bg-neutral-50 dark:text-white dark:bg-neutral-800"
										:value="`https://zookish.com/invite/ref/${user.data.username}`" readonly />
								</a>
								<share />
							</div>

							<div class="md:col-span-5 dark:text-neutral-400 text-right">
								<!-- <label for="email">Subscription</label> -->
								<div v-if="user.data.subscription != ''">
									<a href="https://billing.stripe.com/p/login/8wM8yYety12VdoY7ss" target="_blank"
										class="mt-2 text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-800 dark:bg-white dark:border-gray-700 dark:text-gray-900 dark:hover:bg-gray-200 mr-2 mb-2">
										<img class="w-10 mr-2 -ml-1" src="@/assets/stripe.png" />
										Manage your Zookish subscription with Stripe
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data: () => {
		return {
			copied: false,
		}
	},
	computed: {
		user() {
			return JSON.parse(localStorage.getItem("user"))
		},
	},
	methods: {
		copy() {
			this.copied = true
			// Get the text field
			var copyText = document.getElementById("dns")

			// Select the text field
			copyText.select()
			copyText.setSelectionRange(0, 99999) // For mobile devices

			// Copy the text inside the text field
			navigator.clipboard.writeText(copyText.value)
		},
	},
}
</script>
