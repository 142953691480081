<template>
	<render v-if="!loading" :pageProp="page" :projectProp="project" />
</template>
<script>
import navigation from "./components/navigation.vue"
import navigation2 from "./components/navigation2.vue"
import prompt from "./components/prompt.vue"
import hero from "./components/hero.vue"
import cta from "./components/cta.vue"
import faq from "./components/faq.vue"
import pricing from "./components/pricing.vue"
import pricing2 from "./components/pricing2.vue"
import { useHostStore } from "@/stores/HostStore"
import api from "@/services/api"
import FindObjMixin from "@/mixins/FindObjMixin"
import GeolocationMixin from "@/mixins/GeolocationMixin"
import render from "../editor/render.vue"
import block from "./components/block.vue"
import builtwith from "./components/builtwith.vue"
import features from "./components/features.vue"
import kpi from "./components/kpi.vue"
import raycaster from "./components/raycaster.vue"
import nav2 from "./components/nav2.vue"
import landing2 from "./components/landing2.vue"
import Landing2 from "./components/landing2.vue"
import Navigation from "./components/navigation.vue"
import Tutorial from "./components/tutorials.vue"

export default {
	components: {
		hero,
		raycaster,
		cta,
		faq,
		pricing,
		pricing2,
		render,
		block,
		navigation,
		navigation2,
		builtwith,
		features,
		landing2,
		prompt,
		kpi,
		nav2,
		Landing2,
		Navigation,
		Tutorial
	},
	mixins: [FindObjMixin, GeolocationMixin],
	// Check if the window.location matches a hostname for any one of the projects
	// If it does show the render view
	data: () => {
		return {
			local: true,
			loading: true,
			isNotFound: false,
			hostStore: useHostStore(),
			page: Object,
			project: Object,
			loaded: false,
		}
	},

	computed: {
		hostname() {
			return this.hostStore.hostname
		},
	},
	mounted() {
		// if !zookish or !localhost
		// search projects for a domain that matches the hostnanme
		// if match exists get project home page
		// implement named router views for different routes
		// Else proceed as usual
		// Get the current URL
		if (this.$route.name == "SharedHome") {
			localStorage.setItem("referrer", this.$route.params.username)
		}
		document.documentElement.classList.add("dark")
		localStorage.setItem("color-theme", "dark")
		this.reverseIP()
		var url = window.location.href

		// Use a regular expression to extract the subdomain
		var subdomain = url.match(
			/^(?:https?:\/\/)?((?:[\w-]+)\.(?:[\w-]+)\.(?:[a-z]{2,}))/i
		)

		// Check if a subdomain was found
		if (subdomain && subdomain.length > 1) {
			// The subdomain is captured in the first group of the regular expression
			var extractedSubdomain = subdomain[1]

			// Split the extractedSubdomain by "." and get the first part (subdomain)
			var subdomainParts = extractedSubdomain.split(".")
			var subdomainWithoutDomain = subdomainParts[0]

			console.log("Subdomain: " + subdomainWithoutDomain)
			this.GetPages(subdomainWithoutDomain)
		} else {
			console.log("No subdomain found.")
			const w = window.location.hostname
			if (
				w == "localhost" ||
				w == "zookish.com" ||
				w == "192.168.142.156" ||
				w == "www.zookish.com"
			) {
				this.GetPages('zookish.com')
				this.addHead()
			} else {
				this.GetPages(window.location.hostname)
			}
		}

		console.log(window.location.hostname)
	},
	methods: {
		addHead() {
			// Set the page title
			const pageTitle = "Discover Our Revolutionary AI Website Builder!";

			// Create a new title element
			const newTitleElement = document.createElement("title");

			// Set the text content of the title element
			newTitleElement.textContent = pageTitle;

			// Append the new title element to the head of the document
			document.head.appendChild(newTitleElement);
			// Open Graph Meta Tags
			const ogMetaTags = [
				{ property: "og:url", content: "https://zookish.com" },
				{ property: "og:type", content: "website" },
				{ property: "og:title", content: "Discover Our Revolutionary AI Website Builder!" },
				{ property: "og:description", content: "Explore the future of website creation with our AI-powered Website Builder. Tailored for ease and efficiency, our tool is designed to transform your digital presence." },
				{ property: "og:image", content: "https://res.cloudinary.com/dxwy9kwne/image/upload/v1704325169/start_wfyrxp.jpg" },
			];

			// Twitter Meta Tags
			const twitterMetaTags = [
				{ name: "twitter:card", content: "summary_large_image" },
				{ name: "twitter:title", content: "Discover Our Revolutionary AI Website Builder!" },
				{ name: "twitter:description", content: "Explore the future of website creation with our AI-powered Website Builder. Tailored for ease and efficiency, our tool is designed to transform your digital presence." },
				{ name: "twitter:image", content: "https://res.cloudinary.com/dxwy9kwne/image/upload/v1704325169/start_wfyrxp.jpg" },
			];

			// Favicon Links
			const faviconLinks = [
				{ rel: "shortcut icon mask-icon", href: "https://res.cloudinary.com/dxwy9kwne/image/upload/v1691897392/zookish_1_ee8kjx-removebg-preview_jnc36m.png", color: "#000000" },
				{ rel: "shortcut icon", href: "https://res.cloudinary.com/dxwy9kwne/image/upload/v1691897392/zookish_1_ee8kjx-removebg-preview_jnc36m.png" },
			];

			// Add Open Graph Meta Tags to the head
			ogMetaTags.forEach(tag => {
				const newMetaTag = document.createElement("meta");
				newMetaTag.setAttribute("property", tag.property);
				newMetaTag.setAttribute("content", tag.content);
				document.head.appendChild(newMetaTag);
			});

			// Add Twitter Meta Tags to the head
			twitterMetaTags.forEach(tag => {
				const newMetaTag = document.createElement("meta");
				newMetaTag.setAttribute("name", tag.name);
				newMetaTag.setAttribute("content", tag.content);
				document.head.appendChild(newMetaTag);
			});

			// Add Favicon Links to the head
			faviconLinks.forEach(link => {
				const newFaviconLink = document.createElement("link");
				Object.entries(link).forEach(([key, value]) => {
					newFaviconLink.setAttribute(key, value);
				});
				document.head.appendChild(newFaviconLink);
			});
			document.dispatchEvent(new Event('render-event'));

		},
		async GetPages(e) {
			try {
				const response = await api.get(
					`${this.hostname}/api/v1/projects/pages/get/${e}`
				)
				console.log("Response:", response.data)
				var pages = response.data.pages
				var page = this.findObjectWithTitle(pages, "home")
				if (Object.keys(page).length != 0) {
					this.page = page
					this.project = response.data.project
					this.local = false
					this.loading = false
				} else {
					this.$router.push("404")
				}
			} catch (error) {
				console.log("Status code:", error.response.status)
				if (error.response.status == 404) {
					this.$router.push("/404")
				}
			}
		},
	},
}
</script>
<style src="@/assets/webflow.min.css" scoped></style>
