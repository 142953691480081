<template>
	<section class="pb-btn">
		<div class="relative max-w-screen-xl px-4 pt-32 mx-auto lg:gap-8 xl:gap-0 lg:pt-16 lg:grid-cols-12 lg:pt-28">
			<div class="mr-auto align-center justify-center text-center place-self-center lg:col-span-12">
				<h1
					class="max-w-2xl mx-auto mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">
					Build Your Dream Site<br />
					in Minutes!
				</h1>
				<p class="max-w-2xl mx-auto mb-6 text-neutral-500 lg:mb-8 md:text-lg lg:text-xl dark:text-neutral-100">
					With our revolutionary Website Builder, you can effortlessly replicate
					the world's highest converting landing pages and websites to build a
					supercharged web presence for your business.
				</p>

				<div class="max-w-2xl mx-auto" v-if="isUser">
					<clone />
				</div>

				<div class="space-y-4 max-w-2xl mx-auto w-full sm:flex sm:space-y-0 sm:space-x-4">
					<div class="mb-8 gap-3 flex justify-center text-center mx-auto" v-if="!isUser">
						<a class="inline-flex justify-center bounce-animation items-center gap-x-3 text-center bg-indigo-500 shadow-lg shadow-transparent hover:shadow-blue-700/50 border border-transparent text-white text-sm font-medium rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white py-3 px-6 dark:focus:ring-offset-amber-600"
							href="/api/v1/login">
							Get started
							<svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
								viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
								stroke-linecap="round" stroke-linejoin="round">
								<path d="m9 18 6-6-6-6" />
							</svg>
						</a>
					</div>
				</div>

				<!-- <div
					v-if="isUser"
					class="trustpilot-widget"
					data-locale="en-US"
					data-template-id="56278e9abfbbba0bdcd568bc"
					data-businessunit-id="659191d4ef5249408ba75d4b"
					data-style-height="72px"
					data-style-width="100%" 
				>
					<a
						href="https://www.trustpilot.com/review/zookish.com"
						target="_blank"
						rel="noopener"
						>Trustpilot</a
					>
				</div> -->
				<img src="@/assets/clip7.gif" class="mx-auto" alt="hero image" />
			</div>
		</div>

	</section>
	<Transition name="fade">
		<hvco v-if="showHVCO && !isUser" @cancel="cancel" />
	</Transition>
</template>
<script>
import clone from "../../project/clone.vue"
import hvco from "./hvco.vue"

export default {
	data() {
		return {
			showHVCO: false,
			shown: false,
		}
	},
	components: {
		clone,
		hvco,
	},
	mounted() {
		window.addEventListener("beforeunload", this.beforeTabClose)
		window.addEventListener("scroll", this.handleScroll)
		document.addEventListener('mouseout', this.handleMouseLeave);
	},
	beforeDestroy() {
		// Remove the event listener when the Vue component is destroyed
		document.removeEventListener('mouseout', (e) => {
			this.handleMouseLeave(e)
		});
	},
	destroyed() {
		window.removeEventListener("beforeunload", this.beforeTabClose)
		window.removeEventListener("scroll", this.handleScroll)
	},
	computed: {
		isUser() {
			if (localStorage.getItem("user") == null) {
				return false
			} else {
				return true
			}
		},
	},
	methods: {
		cancel() {
			this.shown = true
			this.showHVCO = false
		},
		handleMouseLeave(event) {
			// The mouse has left the page
			if (!event.relatedTarget || event.relatedTarget.nodeName === 'HTML') {
				// The mouse has left the page
				console.log('Mouse has left the page');
				if(this.shown == false){
					this.showHVCO = true
				}
				this.shown = true

			

				// You can perform additional actions here if needed
			}
			// You can perform additional actions here if needed
		},
		handleScroll() {
			const scrolled = window.scrollY // Distance from top of the page
			const height =
				document.documentElement.scrollHeight -
				document.documentElement.clientHeight // Total scrollable height
			const scrolledPercentage = (scrolled / height) * 100

			if (scrolledPercentage > 50 && !this.showHVCO && !this.shown) {
				this.showHVCO = true
			}
		},
		beforeTabClose(event) {
			this.showHVCO = true
		},
	},
}
</script>
<style>
/* Fade Enter and Leave Active */
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

/* Fade Enter From and Leave To */
.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

/* Fade Enter To and Leave From (initial state) */
.fade-enter-to,
.fade-leave-from {
	opacity: 1;
}

@keyframes bounce {

	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0);
	}

	40% {
		transform: translateY(-30px);
	}

	60% {
		transform: translateY(-15px);
	}
}

.bounce-animation {
	animation: bounce 2s infinite;
}
</style>