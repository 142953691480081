<template>
	<!-- Sidebar -->
	<div id="application-sidebar-dark"
		class="hs-overlay hs-overlay-open:translate-x-0 pt-2 -translate-x-full transition-all duration-300 transform hidden fixed top-0 start-0 bottom-0 w-64 bg-black border-e border-neutral-800 pb-10 overflow-y-auto lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-neutral-100 [&::-webkit-scrollbar-thumb]:bg-neutral-300 dark:[&::-webkit-scrollbar-track]:bg-black dark:[&::-webkit-scrollbar-thumb]:bg-black">
		<div class="px-6">
			<a class="flex-none font-semibold text-white	 focus:outline-none focus:ring-1 focus:ring-neutral-600" href="#"
				aria-label="Brand">
				<!-- <img
						src="@/assets/icon.png"
						class="h-6 text-center mx-auto left-1/2"
					/> -->
				<figure
					class="ms-auto me-20 relative z-[1] max-w-full w-[50rem] h-auto rounded-b-lg shadow-[0_2.75rem_3.5rem_-2rem_rgb(45_55_75_/_20%),_0_0_5rem_-2rem_rgb(45_55_75_/_15%)] dark:shadow-[0_2.75rem_3.5rem_-2rem_rgb(0_0_0_/_20%),_0_0_5rem_-2rem_rgb(0_0_0_/_15%)]">
					<div class="relative flex items-center bg-neutral-800 rounded-t-lg py-2  dark:bg-neutral-700">

						<div
							class="flex justify-center items-center size-full bg-neutral-700 text-xs  text-neutral-400  dark:text-neutral-400">
							{{ project.title }}</div>
					</div>
				</figure>

			</a>
		</div>

		<nav class="hs-accordion-group p-6 w-full flex flex-col flex-wrap" data-hs-accordion-always-open>
			<ul class="space-y-1.5">
				<li>
					<router-link
						class="flex items-center gap-x-3 py-2 px-2.5 bg-neutral-700 text-sm text-white rounded-lg focus:outline-none focus:ring-1 focus:ring-neutral-600"
						to="/dashboard">
						<vue-feather type="trending-up" />
						Dashboard
					</router-link>
				</li>

				<li class="hs-accordion" id="account-accordion">
					<button type="button"
						class="hs-accordion-toggle w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 hs-accordion-active:text-white hs-accordion-active:hover:bg-transparent text-sm text-neutral-400 rounded-lg hover:bg-neutral-800 hover:text-white focus:outline-none focus:ring-1 focus:ring-neutral-600">
						<vue-feather type="layers" />
						Sitemap

						<svg class="hs-accordion-active:block ms-auto hidden w-4 h-4" xmlns="http://www.w3.org/2000/svg"
							width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
							stroke-linecap="round" stroke-linejoin="round">
							<path d="m18 15-6-6-6 6" />
						</svg>

						<svg class="hs-accordion-active:hidden ms-auto block w-4 h-4" xmlns="http://www.w3.org/2000/svg"
							width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
							stroke-linecap="round" stroke-linejoin="round">
							<path d="m6 9 6 6 6-6" />
						</svg>
					</button>

					<div id="account-accordion-child"
						class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300 hidden">
						<ul class="pt-2 ps-2">
							<li v-for="page in sitemap">
								<div class="flex items-center max-w-sm over gap-x-3.5 py-2 px-2.5 text-sm text-neutral-400 rounded-lg hover:bg-neutral-800 hover:text-white focus:outline-none focus:ring-1 focus:ring-neutral-600"
									href="#">
									<!-- <vue-feather
											type="check-circle"
											class="h-4 text-green-500"
										/> -->
									{{
										trimStringToMaxLength(
											page.split("/").pop() == ``
												? `Home`
												: page.split("/").pop(),
											20
										)
									}}
									<a @click="getPage(page)" class="hs-accordion-active:hidden ms-auto block">
										<vue-feather type="loader" class="spin text-white w-4 h-4"
											v-if="loading && cPage._id == page._id" />
										<vue-feather type="arrow-up-right" class="w-4 h-4" v-else />
									</a>
								</div>

							</li>
						</ul>
						<div class="flex items-center px-2.5  max-w-sm over gap-x-3.5 text-sm text-neutral-400 rounded-lg hover:bg-neutral-800 hover:text-white focus:outline-none focus:ring-1 focus:ring-neutral-600"
							href="#">
							<!-- <vue-feather
											type="check-circle"
											class="h-4 text-green-500"
										/> -->
							<div class="relative">
								<input type="text" v-model="sitemapInput" placeholder="Enter Page Manually"
									class=" px-4 block w-full bg-transparent outline-none border-none focus:outline-none	text-sm ">
								<div class="absolute inset-y-0 end-0 flex items-center pe-3">
									<button @click="getPage(`${project.cloneOf}${sitemapInput}`)"><vue-feather
											type="arrow-right" class="h-3 m-1" /></button>
								</div>
							</div>



						</div>
						<a @click="generateSitemap()" v-if="!loading"
							class="flex mx-auto items-center gap-x-3.5 py-2 px-2.5 text-sm text-neutral-400 rounded-lg hover:bg-neutral-800 hover:text-white focus:outline-none focus:ring-1 focus:ring-neutral-600">
							<vue-feather type="plus" /> Generate Sitemap
						</a>
					</div>
				</li>

				<li class="hs-accordion" id="projects-accordion">
					<button type="button"
						class="hs-accordion-toggle w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 hs-accordion-active:text-white hs-accordion-active:hover:bg-transparent text-sm text-neutral-400 rounded-lg hover:bg-neutral-800 hover:text-white focus:outline-none focus:ring-1 focus:ring-neutral-600">
						<vue-feather type="folder" />
						Pages

						<svg class="hs-accordion-active:block ms-auto hidden w-4 h-4" xmlns="http://www.w3.org/2000/svg"
							width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
							stroke-linecap="round" stroke-linejoin="round">
							<path d="m18 15-6-6-6 6" />
						</svg>

						<svg class="hs-accordion-active:hidden ms-auto block w-4 h-4" xmlns="http://www.w3.org/2000/svg"
							width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
							stroke-linecap="round" stroke-linejoin="round">
							<path d="m6 9 6 6 6-6" />
						</svg>
					</button>

					<div id="projects-accordion-child"
						class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300 hidden">
						<ul class="pt-2 ps-2">
							<div class="mx-auto text-center ">
								<vue-feather type="loader" class="spin text-white h-3" v-if="pages.length == 0" />
							</div>

							<li v-for="page in pages">
								<div class="flex items-center px-2.5  max-w-sm over gap-x-3.5 text-sm text-neutral-400 rounded-lg hover:bg-neutral-800 hover:text-white focus:outline-none focus:ring-1 focus:ring-neutral-600"
									href="#">
									<!-- <vue-feather
											type="check-circle"
											class="h-4 text-green-500"
										/> -->
									<div class="relative">
										<input type="text" v-model="page.title"
											@blur="editPageTitle({ title: page.title, id: page._id })"
											class=" px-4 block w-full bg-transparent outline-none border-none focus:outline-none	text-sm ">
										<div class="absolute inset-y-0 end-0 flex items-center pe-3">
											<router-link :to="`/editor/${page._id}`"><vue-feather type="edit"
													class="h-3 m-1" /></router-link>
										</div>
									</div>



								</div>


							</li>
							<li>
								<a @click="copyPage" v-if="!loading"
									class="flex mx-auto items-center gap-x-3.5 py-2 px-2.5 text-sm text-neutral-400 rounded-lg hover:bg-neutral-800 hover:text-white focus:outline-none focus:ring-1 focus:ring-neutral-600">
									<vue-feather type="plus" /> New Page
								</a>
							</li>
						</ul>
					</div>
				</li>
				<li class="hs-accordion" id="ai-accordion">
					<button type="button"
						class="hs-accordion-toggle w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 hs-accordion-active:text-white hs-accordion-active:hover:bg-transparent text-sm text-neutral-400 rounded-lg hover:bg-neutral-800 hover:text-white focus:outline-none focus:ring-1 focus:ring-neutral-600">
						<vue-feather type="zap" />
						Ai Prompt

						<svg class="hs-accordion-active:block ms-auto hidden w-4 h-4" xmlns="http://www.w3.org/2000/svg"
							width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
							stroke-linecap="round" stroke-linejoin="round">
							<path d="m18 15-6-6-6 6" />
						</svg>

						<svg class="hs-accordion-active:hidden ms-auto block w-4 h-4" xmlns="http://www.w3.org/2000/svg"
							width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
							stroke-linecap="round" stroke-linejoin="round">
							<path d="m6 9 6 6 6-6" />
						</svg>
					</button>

					<div id="ai-accordion-child"
						class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300 hidden">
						<div class="pt-5 flex justify-center">
							<!-- Button Group -->
							<div
								class="inline-block yssg3 f0dty l66z3 d1k81 nq4w8 tgfrq dark:bg-neutral-800 dark:border-neutral-700">
								<div class="flex items-center">
									<div class="hs-tooltip relative inline-block">
										<button type="button" @click="aimode = 0"
											class="hs-tooltip-toggle cslsl uunzg flex wlxy3 pdrgo items-center fyxhw zvbcs p5a84 sg5ei kko9e tk4f7 focus:outline-none focus:bg-neutral-100 dark:hover:bg-neutral-700 dark:text-neutral-400 dark:focus:bg-neutral-700">
											0
										</button>
										<span
											class="hs-tooltip-content tnij2 hnhol opacity-0 uiqm5 absolute imt5j ootlu zdxcz kdz1q bg-neutral-900 rvi38 dafkk nq4w8 dark:bg-neutral-700 hidden"
											role="tooltip" data-popper-placement="top"
											style="position: fixed; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(626px, -62px, 0px);">
											AI Mode 0
										</span>
									</div>
									<div class="hs-tooltip relative inline-block">
										<button type="button" @click="aimode = 1"
											class="hs-tooltip-toggle cslsl uunzg flex wlxy3 pdrgo items-center fyxhw zvbcs p5a84 sg5ei kko9e tk4f7 focus:outline-none focus:bg-neutral-100 dark:hover:bg-neutral-700 dark:text-neutral-400 dark:focus:bg-neutral-700">
											1
										</button>
										<span
											class="hs-tooltip-content tnij2 hnhol opacity-0 uiqm5 absolute imt5j ootlu zdxcz kdz1q bg-neutral-900 rvi38 dafkk nq4w8 dark:bg-neutral-700 hidden"
											role="tooltip" data-popper-placement="top"
											style="position: fixed; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(654px, -62px, 0px);">
											AI Mode 1
										</span>
									</div>
									<div class="hs-tooltip relative inline-block">
										<button type="button" @click="aimode = 2"
											class="hs-tooltip-toggle cslsl uunzg flex wlxy3 pdrgo items-center fyxhw zvbcs p5a84 sg5ei kko9e tk4f7 focus:outline-none focus:bg-neutral-100 dark:hover:bg-neutral-700 dark:text-neutral-400 dark:focus:bg-neutral-700">
											2
										</button>
										<span
											class="hs-tooltip-content tnij2 hnhol opacity-0 uiqm5 absolute imt5j ootlu zdxcz kdz1q bg-neutral-900 rvi38 dafkk nq4w8 dark:bg-neutral-700 hidden"
											role="tooltip" data-popper-placement="top"
											style="position: fixed; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(654px, -62px, 0px);">
											AI Mode 2
										</span>
									</div>
								</div>
							</div>
							<!-- End Button Group -->
						</div>
						<textarea id="textarea-label" v-model="prompt"
							class="py-3 mt-2 px-4 block w-full border-neutral-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:ring-neutral-600"
							rows="3" placeholder="Say hi..."></textarea>
						<label for="minmax-range"
							class="block mb-2 text-sm font-medium text-neutral-900 mt-2 dark:text-white">Tokens</label>
						<input id="minmax-range" type="range" min="1" max="100" v-model="shortText"
							class="w-full h-2 bg-neutral-200 rounded-lg appearance-none cursor-pointer dark:bg-neutral-700">
						<label for="hs-pro-danpe1" class="flex gap-x-4 p-2">

							<span class="flex flex-col">
								<span class="block dtjcu eass7 v7056 dark:text-neutral-300 mt-2">
									{{ shortText }}
								</span>
							</span>
						</label>
						<a @click="$emit('prev')">
							<vue-feather type="chevron-left" />
						</a>
					</div>



				</li>
			</ul>
		</nav>
	</div>
	<!-- End Sidebar -->
</template>
<script>
import api from "@/services/api"
import { useHostStore } from "@/stores/HostStore"
import { v4 as uuidv4 } from "uuid"
import { useProjectStore } from "@/stores/ProjectStore"
export default {
	data() {
		return {
			loading: false,
			projectStore: useProjectStore(),
			sitemap: [],
			cPage: null,
			pages: [],
			htmlStr: null,
			hostStore: useHostStore(),
			sitemapInput: '',
			uneditedPages: []
		}
	},

	props: ["page"],

	emits: ["prev"],

	computed: {
		hostname() {
			return this.hostStore.hostname
		},
		project() {
			return this.projectStore.project
		},
		prompt: {
			get() {
				return this.projectStore.prompt
			},
			set(e) {
				this.projectStore.setPrompt(e)
			}
		},
		shortText: {
			get() {
				return this.projectStore.shortText
			},
			set(e) {
				this.projectStore.setShortText(e)
			}
		},
		aimode: {
			get() {
				return this.projectStore.aimode
			},
			set(e) {
				this.projectStore.setAIMODE(e)
			}
		},


	},
	watch: {
		'page.title': (v) => {
			console.log(v)
		},
	},
	mounted() {
		this.GetPages()
		if (this.project.metadata.trim() != "") {
			var o = JSON.parse(this.project.metadata)

			if ("sitemap" in o) {
				console.log(" o has sitemap")
				let uniqueArray = o.sitemap.filter((item, index) => {
					return o.sitemap.indexOf(item) === index
				})

				this.sitemap = uniqueArray
			}
		}
	},

	methods: {
		async generateSitemap() {
			const data = {
				urls: [this.project.cloneOf],
				cookies: ["cookie1=value1; cookie2=value2"],
				proxy: "",
				user_agent: "My User Agent",
				serve: false,
				serve_port: 8081,
				open: false,
			}
			const r = await api.post(`${this.hostname}/api/v1/crawl`, data)
			this.sitemap = r.data.sitemap
		},
		trimStringToMaxLength(str, maxLength) {
			if (str.length > maxLength) {
				return str.slice(0, maxLength)
			}
			return str
		},
		getRouteFromURL(url) {
			const urlObj = new URL(url)
			return urlObj.pathname
		},
		async editPageTitle(e) {
			try {
				const data = {
					title: e.title
				}
				const response = await api.post(
					`${this.hostname}/api/v1/projects/pages/title/edit/${e.id}`, data
				)
			} catch (e) {
				console.log(e)
			}
		},
		async GetPages() {
			try {
				const response = await api.get(
					`${this.hostname}/api/v1/projects/pages/titles/get/${this.project._id}`
				)
				console.log("Response:", response.data)
				this.pages = response.data.pages
				this.uneditedPages = response.data.pages
				this.projectStore.setPages(this.pages)
				const valuesToRemove = response.data.pages.map(item => item.title)
				console.log(valuesToRemove)
				this.sitemap = this.sitemap.filter(
					item =>
						!valuesToRemove.includes(
							this.trimStringToMaxLength(item.split("/").pop(), 20)
						)
				)
			} catch (error) { }
		},
		isTitleUnique(title) {
			console.log(title)
			// Iterate through the pages array to check if the new title already exists
			return this.uneditedPages.some(obj => obj.title == title.trim());
		},
		async getPage(e) {
			this.loading = true
			const data = {
				urls: [e],
			}
			this.cPage = e
			// get the html of the page
			try {
				const r = await api.post(`${this.hostname}/api/v1/crawl`, data)
				console.log("Response:", r.data.data)
				this.htmlStr = r.data.data
				// check if page exists before creating page
				this.createPage()
			} catch (error) {
				console.error("Error:", error)
			}
			// add the page to the project
			// add the url to page
			// add a catch all for the render that is able to display the page
		},
		async copyPage() {
			this.loading = true
			const data = {
				_id: uuidv4(),
				title: `${this.page.title}-copy`,
				content: this.page.content,
				html: this.html,
				metadata: this.metadata,
				project: this.page.project,
				created_at: new Date().toISOString(),
				updated_at: new Date().toISOString(),
			}
			console.log(data)
			try {
				const response = await api.post(
					`${this.hostname}/api/v1/create/page`,
					data
				)
				console.log("Response:", response.data)
				this.$router.push(`/editor/${response.data.page._id}`)
			} catch (error) {
				console.error("Error:", error)
				// message.value = error
				// hasError.value = true
			} finally {
				// loading.value = false
			}
		},
		async createPage() {
			const data = {
				_id: uuidv4(),
				title: this.trimStringToMaxLength(this.cPage.split("/").pop(), 20),
				content: "",
				route: this.getRouteFromURL(this.cPage),
				origin: this.cPage,
				html: this.htmlStr,
				project: this.project,
				created_at: new Date().toISOString(),
				updated_at: new Date().toISOString(),
			}
			console.log(data)
			try {
				const response = await api.post(
					`${this.hostname}/api/v1/create/page`,
					data
				)
				console.log("Response:", response.data)
				this.$router.push(`/editor/${response.data.page._id}`)
			} catch (error) {
				console.error("Error:", error)
				// message.value = error
				// hasError.value = true
			} finally {
				// loading.value = false
			}
		},
	},
}
</script>
