<template>
	<div>
		<div
			class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
		>
			<div class="relative w-96 my-6 mx-auto max-w-3xl rounded-lg">
				<!--content-->
				<div class="relative rounded-lg shadow backdrop-blur bg-black/50">
					<!-- Modal header -->
					<div
						class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-neutral-600"
					>
						<h3 class="text-lg font-semibold text-neutral-900 dark:text-white">
							Page Data
						</h3>
						<button
							@click="$emit('exit')"
							type="button"
							class="text-neutral-400 bg-transparent hover:bg-neutral-200 hover:text-neutral-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-neutral-600 dark:hover:text-white"
							data-modal-toggle="select-modal"
						>
							<svg
								class="w-3 h-3"
								aria-hidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 14 14"
							>
								<path
									stroke="currentColor"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
								/>
							</svg>
							<span class="sr-only">Close modal</span>
						</button>
					</div>
					<data-view />
				</div>
			</div>
		</div>
		<div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
	</div>
</template>
<script>
	import dataView from "../project/data.vue"

	export default {
		emits: ["exit"],
		components: {
			dataView,
		},
	}
</script>
<style>
	.frost:before {
		box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
	}
	.frost:before {
		box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);

		filter: blur(10px);
	}
	.frost {
		background: inherit;
	}

	.frost:before {
		background: inherit;
	}
</style>
