import { defineStore } from 'pinia';

export const useImageStore = defineStore('image', {
    state: () => ({
        images: null,
    }),
    actions: {
        setImages(images) {
            this.images = images;
        }
    }
});
