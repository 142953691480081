<template>
	<loader v-if="loading" />
	<div class="bg-black" v-else>
		<navigation />

		<div class="mx-auto justify-center max-w-3xl">
			<div class="overflow-hidden">
				<div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-20">
					<div
						class="relative mx-auto max-w-4xl grid space-y-5 sm:space-y-10 mt-12"
					>
						<!-- Title -->
						<div class="text-center">
							<p
								class="text-xs font-semibold text-neutral-500 tracking-wide uppercase mb-3 dark:text-neutral-200"
							>
								Share, refer, and watch your score soar!
							</p>
							<h1
								class="text-3xl text-neutral-800 font-bold sm:text-5xl lg:text-6xl lg:leading-tight dark:text-neutral-200"
							>
								"Welcome to the Elite Circle! 🌟 Join our
								<span class="text-blue-500"> dynamic Leaderboard</span>
							</h1>
						</div>
						<!-- End Title -->

						<!-- Avatar Group -->
						<div
							class="sm:flex sm:justify-center sm:items-center text-center sm:text-start"
						>
							<div class="flex-shrink-0 pb-5 sm:flex sm:pb-0 sm:pe-5">
								<!-- Avatar Group -->
								<div class="flex justify-center -space-x-3">
									<img
										class="inline-block h-8 w-8 rounded-full ring-2 ring-white dark:ring-neutral-800"
										src="https://api.dicebear.com/7.x/fun-emoji/svg?seed=20"
										alt="Image Description"
									/>
									<img
										class="inline-block h-8 w-8 rounded-full ring-2 ring-white dark:ring-neutral-800"
										src="https://api.dicebear.com/7.x/fun-emoji/svg?seed=10"
										alt="Image Description"
									/>
									<img
										class="inline-block h-8 w-8 rounded-full ring-2 ring-white dark:ring-neutral-800"
										src="https://api.dicebear.com/7.x/fun-emoji/svg?seed=5"
										alt="Image Description"
									/>
									<img
										class="inline-block h-8 w-8 rounded-full ring-2 ring-white dark:ring-neutral-800"
										src="https://api.dicebear.com/7.x/fun-emoji/svg?seed=100"
										alt="Image Description"
									/>
									<span
										class="inline-flex items-center justify-center h-8 w-8 rounded-full ring-2 ring-white bg-neutral-800 dark:bg-neutral-900 dark:ring-neutral-800"
									>
										<span
											class="text-xs font-medium leading-none text-white uppercase"
											>7k+</span
										>
									</span>
								</div>
								<!-- End Avatar Group -->
							</div>

							<div
								class="border-t sm:border-t-0 sm:border-s border-neutral-200 w-32 h-px sm:w-auto sm:h-full mx-auto sm:mx-0"
							></div>

							<div class="pt-5 sm:pt-0 sm:ps-5">
								<div
									class="text-lg font-semibold text-neutral-800 dark:text-neutral-200"
								>
									Trust pilot
								</div>
								<div class="text-sm text-neutral-500">
									Soon to be rated best over 37k reviews
								</div>
							</div>
						</div>
						<!-- End Avatar Group -->
					</div>
				</div>
			</div>
			<div
				class="relative py-8 sm:py-8 xl:py-3 min-w-screen min-h-screen flex items-center justify-center text-white"
			>
				<div
					class="grid border-x lg:grid-cols-6 sm:grid-cols-3 grid-cols-2 lg:divide-x divide-solid divide-white"
				>
					<article
						v-for="(leader, i) in leaderboard"
						class="group h-full flex flex-col divide-y text-sm text-center divide-gray-400 px-2"
					>
						<div class="sm:pb-8 pb-3 num">{{ i + 1 }}</div>
						<div class="sm:py-3 py-1.5 uppercase overflow-hidden">
							{{ leader.username }}
						</div>
						<div class="sm:h-28 h-20 flex items-center text-xs">
							<p
								class="break-words font-light whitespace-pre-line mx-auto w-28 p-8"
							>
								<img
									:src="`https://api.dicebear.com/7.x/fun-emoji/svg?seed=${
										i + 1
									}`"
								/>
							</p>
						</div>
						<div class="sm:py-3 py-1.5 uppercase">Points</div>
						<div class="py-8 text-9xl relative">
							<div
								class="sm:pt-36 pt-32 sm:pb-0 pb-1 uppercase w-full max-w-full -scale-100 stretch bg-black sm:group-hover:-translate-y-56 group-hover:-translate-y-44 group-hover:transition-transform flex items-center"
							>
								{{ leader.sharepoints }}
							</div>
						</div>
					</article>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import navigation from "@/views/home/components/navigation.vue"
	import api from "@/services/api"
	import { useHostStore } from "@/stores/HostStore"

	export default {
		data: () => {
			return {
				hostStore: useHostStore(),
				leaderboard: [],
				loading: true,
			}
		},
		computed: {
			hostname() {
				return this.hostStore.hostname
			},
		},
		components: {
			navigation,
		},
		mounted() {
			this.GetLeaderboard()
		},
		methods: {
			async GetLeaderboard() {
				try {
					const response = await api.get(
						`${this.hostname}/api/v1/get/leaderboard`
					)
					console.log("Response:", response.data)
					this.leaderboard = response.data.data
					this.loading = false
				} catch (e) {
					console.log("Status code:", e.response.status)
				}
			},
		},
	}
</script>
<style>
	.group-hover\:transition-transform {
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		transition-duration: 150ms;
		animation-direction: alternate;
	}

	.num {
		font-size: 10rem;
		line-height: 0.9;
		font-weight: 550;
		font-variation-settings: "wdth" 35, "YTFI" 788;
	}

	.stretch {
		font-weight: 500;
		font-variation-settings: "wdth" 35;
		writing-mode: vertical-rl;
	}
</style>
