<template>
	<div class="container max-w-screen-lg mx-auto">
		<transition name="fade">
			<div v-if="isModalVisible">
				<div class="relative z-infinity" aria-labelledby="modal-title" role="dialog" aria-modal="true">
					<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
					<div class="fixed inset-0 z-10 w-screen overflow-y-auto">
						<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<div
								class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
								<div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
									<div class="sm:flex sm:items-start">
										<div
											class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
											<svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24"
												stroke-width="1.5" stroke="currentColor" aria-hidden="true">
												<path stroke-linecap="round" stroke-linejoin="round"
													d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
											</svg>
										</div>
										<div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
											<h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">
												Remove your domain?
											</h3>
											<div class="mt-2">
												<p class="text-sm text-gray-500">
													Are you sure you want to remove your domain? You will
													have to go through the process of adding the domain
													again.
												</p>
											</div>
										</div>
									</div>
								</div>
								<div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
									<button type="button" @click.stop="removeDomain"
										class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">
										Remove
									</button>
									<button type="button" @click.stop="isModalVisible = false"
										class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">
										Cancel
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
		<div>
			<div class="bg-white dark:bg-neutral-900 rounded  p-4 px-4 md:p-8 mb-6">
				<div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
					<div class="text-neutral-100 dark:text-white">
						<p class="font-medium text-black text-lg dark:text-white">
							Configure Project
						</p>
						<p class="text-black dark:text-white">
							Please fill out all the fields.
						</p>
					</div>

					<div class="lg:col-span-2" v-if="Object.keys(this.project).length !== 0">
						<div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
							<div class="md:col-span-5 dark:text-neutral-400">
								<label for="title">Title</label>
								<input type="text" name="title" id="title"
									class="h-10 border mt-1 rounded px-4 w-full bg-neutral-50 dark:text-white dark:bg-neutral-800"
									v-model="project.title" />
							</div>

							<div class="md:col-span-5 dark:text-neutral-400">
								<label for="domain" class="mb-3">Logo</label>
								<upload @uploaded="handleLogo" :src="project.logo" />
							</div>

							<div class="md:col-span-5 dark:text-neutral-400">
								<label for="domain" class="mb-3">Template Image</label>
								<upload @uploaded="handleTemplate" :src="project.metadata == ``
									? ``
									: JSON.parse(project.metadata).template_image
									" />
							</div>
							<div class="md:col-span-5 dark:text-neutral-400">
								<label for="domain" class="mb-3">Tags</label>
								<tags @modified="handleTags" />
							</div>

							<div class="md:col-span-5 dark:text-neutral-400"></div>

							<div class="md:col-span-5 dark:text-neutral-400" v-if="project.hostname != ''">
								<label for="domain">Domain (Read Only)</label>
								<input type="text" name="domain" id="domain"
									class="h-10 border mt-1 rounded px-4 w-full bg-neutral-50 dark:text-white dark:bg-neutral-800"
									:value="JSON.parse(project.hostname).result.hostname" disabled readonly />
							</div>
							<div class="md:col-span-5 dark:text-neutral-400">
								<span class="inline-block align-bottom">Add a custom domain</span>
							</div>
							<div class="md:col-span-5" v-if="project.hostname != ''">
								<div
									class="bg-white dark:bg-transparent shadow-lg shadow-neutral-200 dark:shadow-neutral-900 rounded-2xl p-4">
									<div class="flex items-center">
										<div class="flex-shrink-0 ml-3">
											<span
												class="text-2xl font-bold leading-none text-neutral-900 dark:text-white sm:text-3xl">{{
													project.domain
												}}</span>
										</div>
										<div
											class="flex flex-1 justify-end items-center ml-5 w-0 text-base font-bold text-green-500">
											<button @click.stop="isModalVisible = true" type="button"
												id="deleteProductButton" data-drawer-target="drawer-delete-product-default"
												data-drawer-show="drawer-delete-product-default"
												aria-controls="drawer-delete-product-default" data-drawer-placement="right"
												class="inline-flex items-center px-3 py-1 mt-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:ring-red-300 dark:focus:ring-red-900">
												<svg class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20"
													xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd"
														d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
														clip-rule="evenodd"></path>
												</svg>
												Delete item
											</button>
										</div>
									</div>
								</div>
							</div>
							<div class="md:col-span-5">
								<router-link to="/project/configure/dns" type="button"
									class="dark:text-white w-full text-center focus:ring-4 focus:outline-none focus:ring-indigo/50 font-medium rounded-lg text-sm text-center inline-flex items-center dark:focus:ring-[#050708]/50 dark:hover:bg-[#050708]/30 mr-2 mb-2">
									<span class="p-2 mr-2 dark:hover:bg-neutral-800 inline-flex justify-center rounded-xl">
										<vue-feather type="plus"></vue-feather>
									</span>

									Add a custom domain
								</router-link>
							</div>

							<div class="md:col-span-2 dark:text-neutral-400">
								<label for="cloneOf">Clone Of (Read Only)</label>
								<input type="text" name="cloneOf" id="cloneOf"
									class="h-10 border mt-1 rounded px-4 w-full bg-neutral-50 dark:text-white dark:bg-neutral-800"
									value="" placeholder="" readonly />
							</div>


							<div class="md:col-span-3 dark:text-neutral-400">
								<label for="slug">Slug (Read Only)</label>
								<input type="text" name="slug" id="slug"
									class="h-10 border mt-1 rounded px-4 w-full bg-neutral-50 dark:bg-neutral-800"
									:value="project.slug" placeholder="" readonly />
							</div>

							<div class="md:col-span-5 text-right">
								<div class="inline-flex items-end">
									<button @click="UpdateProject"
										class="bg-indigo-500 dark:bg-neutral-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded">
										Submit
									</button>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>

	<success v-if="hasSuccess" :message="message" @ack="hasSuccess = false" />
</template>
<script>
import api from "@/services/api"
import { useProjectStore } from "@/stores/ProjectStore"
import { useHostStore } from "@/stores/HostStore"
import tags from "./tags"
export default {
	components: {
		tags,
	},
	data: () => {
		return {
			hasSuccess: false,
			message: "Project was update successfully",
			user: Object,
			projectStore: useProjectStore(),
			hostStore: useHostStore(),
			isModalVisible: false,
		}
	},
	computed: {
		project() {
			return this.projectStore.project
		},
		tagsArr() {
			return this.projectStore.tags
		},
		hostname() {
			return this.hostStore.hostname
		},
	},
	mounted() {
		this.user = JSON.parse(localStorage.getItem("user"))
		if (Object.keys(this.project).length === 0) {
			this.$router.push("/projects")
		}
		if (this.project.hostname != "") {
			this.HostnameStatus()
		}

		// Edit link
		// Domain propagator
		// Head Propeties
		// Meta Properties
		// Favicon
		// check if domain is unique on edit
	},
	methods: {
		async removeDomain() {
			try {
				const response = await api.get(
					`${this.hostname}/api/v1/hostname/status/${JSON.parse(this.project.hostname).result.id
					}`
				)
				await api.get(
					`${this.hostname}/api/v1/hostname/delete/${JSON.parse(this.project.hostname).result.id
					}`
				)

				console.log(response.data)
				this.project.hostname = ""
				this.project.domain = ""
				await this.UpdateProject()
				window.location.reload()
			} catch (error) { }
		},
		hasImage() {
			var obj = JSON.parse(this.project.metadata)

			// Check if 'template_image' key exists
			if ("template_image" in obj) {
				console.log("'template_image' key exists.")
				return true
			} else {
				console.log("'template_image' key does not exist.")
				return false
			}
		},
		handleLogo(r) {
			console.log(r)
			this.project.logo = r
			this.UpdateProject()
		},
		handleTemplate(r) {
			var o = {}
			if (this.project.metadata == "") {
				o.template_image = r
			} else {
				var o = JSON.parse(this.project.metadata)
				o.template_image = r
			}
			this.project.metadata = JSON.stringify(o)
			console.log(this.project)
			this.UpdateProject()
		},
		handleTags() {
			this.project.tags = JSON.stringify(this.tagsArr)
			console.log(this.project)
			this.UpdateProject()
		},
		async HostnameStatus() {
			try {
				const response = await api.get(
					`${this.hostname}/api/v1/hostname/status/${JSON.parse(this.project.hostname).result.id
					}`
				)
				console.log(response.data)
				var result = JSON.parse(response.data)
				this.project.hostname = JSON.stringify(result)
				if (JSON.parse(this.project.hostname).result.status == "active") {
					this.project.domain = JSON.parse(
						this.project.hostname
					).result.hostname
				}
				this.UpdateProject()
			} catch (error) { }
		},
		async UpdateProject() {
			try {
				const response = await api.post(
					`${this.hostname}/api/v1/project/update/${this.project._id}`,
					this.project
				)
				console.log(response)
				this.hasSuccess = true
			} catch (error) {
				console.error("Error:", error)
				this.message = error
				this.hasError = true
			}
		},
	},
}
</script>
<style>
.fade-enter,
.fade-leave-to {
	opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 500ms ease-out;
}
</style>
