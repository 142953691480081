<template>
	<div class="flex items-start w-full">
		<!-- Display the selected file image -->
		<div v-if="selectedFile" class="mt-4 flex justify-center">
			<img class="h-56" :src="selectedFileDataUrl" alt="Selected File" />
		</div>
	</div>
	<div v-if="selectedFile && !uploading">
		<button @click="uploadFile" type="button"
			class="py-2 my-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-indigo-600 text-white hover:bg-indigo-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
			Update
		</button>
		<button @click="selectedFile = false" type="button"
			class="px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-indigo-600 disabled:opacity-50 disabled:pointer-events-none dark:text-indigo-500 dark:hover:bg-indigo-800/30 dark:hover:text-indigo-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
			cancel
		</button>
	</div>
	<div class="flex justify-start my-2 mx-3">
		<div v-if="uploading"
			class="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-indigo-600 rounded-full dark:text-white"
			role="status" aria-label="loading">
			<span class="sr-only">Loading...</span>
		</div>
	</div>

	<div v-if="!selectedFile" class="flex items-center justify-center w-full">
		<label for="dropzone-file"
			class="flex flex-col items-center justify-center w-full h-64 border-2 border-neutral-300 border-dashed rounded-lg cursor-pointer bg-neutral-50 dark:hover:bg-bray-800 dark:bg-neutral-700 hover:bg-neutral-100 dark:border-neutral-600 dark:hover:border-neutral-500 dark:hover:bg-neutral-600">
			<div class="flex flex-col items-center justify-center pt-5 pb-6">
				<svg class="w-8 h-8 mb-4 text-neutral-500 dark:text-neutral-400" aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
					<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
						d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
				</svg>
				<p class="mb-2 text-sm text-neutral-500 dark:text-neutral-400">
					<span class="font-semibold">Click to upload</span> or drag and drop
				</p>
				<p class="text-xs text-neutral-500 dark:text-neutral-400">
					Upload JPG, PNG, and SVG Files (Max 300KB)
				</p>
			</div>
			<input id="dropzone-file" type="file" class="hidden" accept=".jpg, .jpeg, .png, .svg"
				@change="handleFileChange" />
		</label>
	</div>
</template>
<script>

import ImageMixin from "@/mixins/ImageMixin"
export default {
	props: ["src"],
	mixins: [ImageMixin],
	emits: ["uploaded"],
	mounted() {
		if (this.src.length > 5) {
			this.selectedFileDataUrl = this.getProperImgSrc(this.src)
			this.selectedFile = true
		}
	}

}
</script>
