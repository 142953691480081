<template>
	<div
		class="fixed top-0 left-0 right-0 bottom-0 w-full h-full z-50 overflow-scroll bg-white dark:bg-black flex flex-col items-center justify-center">
		<div
			class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">

			<div class="relative w-auto my-6 mx-auto max-w-3xl 
			 rounded-lg">
				<div class="mt-[338px] block lg:hidden"></div>
				<section class=" bg-black/50 backdrop-filter shad-lg rounded-lg">
					<div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
						<div class="hidden lg:mt-0 p-6 md:col-span-12 lg:flex">
							<img src="@/assets/clip4.gif" class="h-56 mx-auto" alt="mockup" />
						</div>
						<div class="mr-auto place-self-center md:col-span-12">
							<div class="">
								<a @click="$emit('cancel')" class="right-0 absolute m-5 top-0">
									<vue-feather type="x" size="32" class="text-white" />
								</a>
								<h1
									class="max-w-2xl mt-2  mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">
									6 Powerful Techniques for Web Mastery 🦍
								</h1>
								<p
									class="max-w-2xl mx-auto mb-6 font-medium text-neutral-500 lg:mb-8 md:text-lg lg:text-xl dark:text-neutral-400">
									Before you leave seize the opportunity to elevate your expertise and unleash the power of the web with
									this invaluable resource.
								</p>
								<div class="lg:mt-0 p-6 lg:col-span-5 lg:hidden">
									<img src="@/assets/clip4.gif" alt="mockup"
										class="max-h-96 align-center mx-auto place-content-center " />
								</div>
							</div>

							<share v-if="isUser" />
							<div v-else>
								<!-- Form -->
								<form>
									<div
										class="mx-auto sm:flex sm:space-x-3  rounded-lg shadow-lg shadow-gray-100 dark:bg-black  dark:shadow-gray-900/[.2]">
										<div class="pt-2 sm:pt-0 flex-[1_0_0%]">
											<label for="hs-hero-email-1"
												class="block text-sm font-medium dark:text-white"><span class="sr-only">
													Name</span></label>
											<input type="email" id="hs-hero-email-1" v-model="name"
												class="py-3 px-4 block w-full border-transparent rounded-lg text-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-neutral-900 dark:border-transparent dark:text-gray-400 dark:focus:ring-gray-600"
												placeholder="Name">
										</div>
										<div class="pt-2 sm:pt-0  flex-[1_0_0%]">
											<label for="hs-hero-email-1"
												class="block text-sm font-medium dark:text-white"><span class="sr-only">
													Email Address</span></label>
											<input type="email" id="hs-hero-email-1" v-model="email"
												class="py-3 px-4 block w-full border-transparent rounded-lg text-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-neutral-900 dark:border-transparent dark:text-gray-400 dark:focus:ring-gray-600"
												placeholder="Email ">
										</div>
										<div class="pt-2 sm:pt-0 grid sm:block flex-[0_0_auto]">
											<a class="inline-flex justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-blue-600 to-violet-600 shadow-lg shadow-transparent hover:shadow-blue-700/50 border border-transparent text-white text-sm font-medium rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white py-3 px-6 dark:focus:ring-offset-gray-800"
												@click="SendHVCO">
												Get started
												<svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg"
													width="24" height="24" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round">
													<path d="m9 18 6-6-6-6" />
												</svg>
											</a>
										</div>
									</div>
								</form>
								<!-- End Form -->


							</div>
						</div>

					</div>
				</section>
			</div>
		</div>
		<div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
	</div>
</template>
<script>
import api from "@/services/api"
import { useHostStore } from "@/stores/HostStore"
import { v4 as uuidv4 } from "uuid"
export default {
	data: () => {
		return {
			name: null,
			email: null,
			hostStore: useHostStore()
		}
	},
	emits: ["cancel"],
	computed: {
		isUser() {
			if (localStorage.getItem("user") == null) {
				return false
			} else {
				return true
			}
		},
		hostname() {
			return this.hostStore.hostname
		},
	},
	methods: {
		async SendHVCO() {
			try {
				const data = {
					name: this.name,
					email: this.email
				}
				const response = await api.post(
					`${this.hostname}/api/v1/send/hvco`, data
				)
				const postdata = {
					_id: uuidv4(),
					project: ``,
					page: ``,
					metadata: JSON.stringify(data),
				}
				await api
					.post(`${this.hostname}/api/v1/create/page/data`, postdata)

				console.log(response.data)
				// window.location.replace('https://zookish.com/api/v1/login')
			} catch (e) {
				console.log(e)
			}
		},

	},
}
</script>