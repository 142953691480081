<template>

    <!-- Features -->
<div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
  <!-- Tab Nav -->
  <nav class="max-w-6xl mx-auto grid sm:flex gap-y-px sm:gap-y-0 sm:gap-x-4" aria-label="Tabs" role="tablist">
    <button type="button" class="hs-tab-active:bg-neutral-100 hs-tab-active:hover:border-transparent w-full flex flex-col text-start hover:bg-neutral-100 p-3 md:p-5 rounded-xl dark:hs-tab-active:bg-white/[.05] dark:hover:bg-neutral-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600 active" id="tabs-with-card-item-1" data-hs-tab="#tabs-with-card-1" aria-controls="tabs-with-card-1" role="tab">
      <svg class="flex-shrink-0 hidden sm:block h-7 w-7 hs-tab-active:text-blue-600 text-neutral-800 dark:hs-tab-active:text-blue-500 dark:text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 5.5A3.5 3.5 0 0 1 8.5 2H12v7H8.5A3.5 3.5 0 0 1 5 5.5z"/><path d="M12 2h3.5a3.5 3.5 0 1 1 0 7H12V2z"/><path d="M12 12.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 1 1-7 0z"/><path d="M5 19.5A3.5 3.5 0 0 1 8.5 16H12v3.5a3.5 3.5 0 1 1-7 0z"/><path d="M5 12.5A3.5 3.5 0 0 1 8.5 9H12v7H8.5A3.5 3.5 0 0 1 5 12.5z"/></svg>
      <span class="mt-5">
        <span class="hs-tab-active:text-blue-600 block font-semibold text-neutral-800 dark:hs-tab-active:text-blue-500 dark:text-neutral-200">Getting Started</span>
        <span class="hidden lg:block mt-2 text-neutral-800 dark:text-neutral-200">Flipping your first site.</span>
      </span>
    </button>

    <button type="button" class="hs-tab-active:bg-neutral-100 hs-tab-active:hover:border-transparent w-full flex flex-col text-start hover:bg-neutral-100 p-3 md:p-5 rounded-xl dark:hs-tab-active:bg-white/[.05] dark:hover:bg-neutral-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600" id="tabs-with-card-item-2" data-hs-tab="#tabs-with-card-2" aria-controls="tabs-with-card-2" role="tab">
      <svg class="flex-shrink-0 hidden sm:block h-7 w-7 hs-tab-active:text-blue-600 text-neutral-800 dark:hs-tab-active:text-blue-500 dark:text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m12 14 4-4"/><path d="M3.34 19a10 10 0 1 1 17.32 0"/></svg>
      <span class="mt-5">
        <span class="hs-tab-active:text-blue-600 block font-semibold text-neutral-800 dark:hs-tab-active:text-blue-500 dark:text-neutral-200">Creating Opt-in Pages</span>
        <span class="hidden lg:block mt-2 text-neutral-800 dark:text-neutral-200">Use automation to scale campaigns profitably and save time doing it.</span>
      </span>
    </button>

    <button type="button" class="hs-tab-active:bg-neutral-100 hs-tab-active:hover:border-transparent w-full flex flex-col text-start hover:bg-neutral-100 p-3 md:p-5 rounded-xl dark:hs-tab-active:bg-white/[.05] dark:hover:bg-neutral-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600" id="tabs-with-card-item-3" data-hs-tab="#tabs-with-card-3" aria-controls="tabs-with-card-3" role="tab">
      <svg class="flex-shrink-0 hidden sm:block h-7 w-7 hs-tab-active:text-blue-600 text-neutral-800 dark:hs-tab-active:text-blue-500 dark:text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z"/><path d="M5 3v4"/><path d="M19 17v4"/><path d="M3 5h4"/><path d="M17 19h4"/></svg>
      <span class="mt-5">
        <span class="hs-tab-active:text-blue-600 block font-semibold text-neutral-800 dark:hs-tab-active:text-blue-500 dark:text-neutral-200">Linking Pages</span>
        <span class="hidden lg:block mt-2 text-neutral-800 dark:text-neutral-200">One tool for your company to share knowledge and ship projects.</span>
      </span>
    </button>

    <button type="button" class="hs-tab-active:bg-neutral-100 hs-tab-active:hover:border-transparent w-full flex flex-col text-start hover:bg-neutral-100 p-3 md:p-5 rounded-xl dark:hs-tab-active:bg-white/[.05] dark:hover:bg-neutral-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600" id="tabs-with-card-item-4" data-hs-tab="#tabs-with-card-4" aria-controls="tabs-with-card-4" role="tab">
      <span class="hidden sm:block"><vue-feather type="share" class="text-white "/></span>
      <span class="mt-5">
        <span class="hs-tab-active:text-blue-600 block font-semibold text-neutral-800 dark:hs-tab-active:text-blue-500 dark:text-neutral-200">Bonus: How to get Zookish free</span>
        <span class="hidden lg:block mt-2 text-neutral-800 dark:text-neutral-200">How to share your referral link</span>
      </span>
    </button>

    <button type="button" class="hs-tab-active:bg-neutral-100 hs-tab-active:hover:border-transparent w-full flex flex-col text-start hover:bg-neutral-100 p-3 md:p-5 rounded-xl dark:hs-tab-active:bg-white/[.05] dark:hover:bg-neutral-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600" id="tabs-with-card-item-5" data-hs-tab="#tabs-with-card-5" aria-controls="tabs-with-card-5" role="tab">
      <span class="hidden sm:block"> 🚀</span> 
      <span class="mt-5">
        <span class="hs-tab-active:text-blue-600 block font-semibold text-neutral-800 dark:hs-tab-active:text-blue-500 dark:text-neutral-200">Launching: Connecting a domain name</span>
        <span class="hidden lg:block mt-2 text-neutral-800 dark:text-neutral-200">Let's go live</span>
      </span>
    </button>
  </nav>
  <!-- End Tab Nav -->

  <!-- Tab Content -->
  <div class="mt-12 md:mt-16">
    <div id="tabs-with-card-1" role="tabpanel" aria-labelledby="tabs-with-card-item-1">
      <!-- Devices -->
      <div class="max-w-[1140px] lg:pb-32 relative">


        <!-- Browser Device -->
        <figure class="ms-auto me-12 relative z-[1] max-w-full w-[50rem] h-auto rounded-b-lg shadow-[0_2.75rem_3.5rem_-2rem_rgb(45_55_75_/_20%),_0_0_5rem_-2rem_rgb(45_55_75_/_15%)] dark:shadow-[0_2.75rem_3.5rem_-2rem_rgb(0_0_0_/_20%),_0_0_5rem_-2rem_rgb(0_0_0_/_15%)]">


          <div class="bg-neutral-800 rounded-b-lg">
            <div style="position: relative; padding-bottom: 62.5%; height: 0;"><iframe src="https://www.loom.com/embed/27d4b911bc6c45c385a1c031a1b658cc?sid=093a68d4-4552-4c8c-b48f-c9f7461127ba" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
          </div>
        </figure>
        <!-- End Browser Device -->
      </div>
      <!-- End Devices -->
    </div>

    <div id="tabs-with-card-2" class="hidden" role="tabpanel" aria-labelledby="tabs-with-card-item-2">
      <!-- Devices -->
      <div class="max-w-[1140px] lg:pb-32 relative">


        <!-- Browser Device -->
        <figure class="ms-auto me-12 relative z-[1] max-w-full w-[50rem] h-auto rounded-b-lg shadow-shadow-[0_2.75rem_3.5rem_-2rem_rgb(0_0_0_/_20%),_0_0_5rem_-2rem_rgb(0_0_0_/_15%)]">


          <div class="bg-neutral-800 rounded-b-lg">
            <div style="position: relative; padding-bottom: 62.5%; height: 0;"><iframe src="https://www.loom.com/embed/1ddfd00d42434d019bef2c6de92eec11?sid=69d1fb8d-ffe8-4f57-821a-b2e0559a12c2" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
          </div>
        </figure>
        <!-- End Browser Device -->
      </div>
      <!-- End Devices -->
    </div>

    <div id="tabs-with-card-3" class="hidden" role="tabpanel" aria-labelledby="tabs-with-card-item-3">
      <!-- Devices -->
      <div class="max-w-[1140px] lg:pb-32 relative">

        <!-- Browser Device -->
        <figure class="ms-auto me-12 relative z-[1] max-w-full w-[50rem] h-auto rounded-b-lg shadow-[0_2.75rem_3.5rem_-2rem_rgb(45_55_75_/_20%),_0_0_5rem_-2rem_rgb(45_55_75_/_15%)] dark:shadow-[0_2.75rem_3.5rem_-2rem_rgb(0_0_0_/_20%),_0_0_5rem_-2rem_rgb(0_0_0_/_15%)]">

          <div class="bg-neutral-800 rounded-b-lg">
            <div style="position: relative; padding-bottom: 62.5%; height: 0;"><iframe src="https://www.loom.com/embed/6393f38b91514567b6e42ee384d896c8?sid=f06ed4f3-e66f-4fd1-97ba-9a693be5c245" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
          </div>
        </figure>
        <!-- End Browser Device -->
      </div>
      <!-- End Devices -->
    </div>
    <div id="tabs-with-card-4" class="hidden" role="tabpanel" aria-labelledby="tabs-with-card-item-3">
      <!-- Devices -->
      <div class="max-w-[1140px] lg:pb-32 relative">

        <!-- Browser Device -->
        <figure class="ms-auto me-12 relative z-[1] max-w-full w-[50rem] h-auto rounded-b-lg shadow-[0_2.75rem_3.5rem_-2rem_rgb(45_55_75_/_20%),_0_0_5rem_-2rem_rgb(45_55_75_/_15%)] dark:shadow-[0_2.75rem_3.5rem_-2rem_rgb(0_0_0_/_20%),_0_0_5rem_-2rem_rgb(0_0_0_/_15%)]">

          <div class="bg-neutral-800 rounded-b-lg">
            <div style="position: relative; padding-bottom: 62.5%; height: 0;"><iframe src="https://www.loom.com/embed/779249a0b66e4f96b72e500175e8b887?sid=775a7431-ad42-4cb6-a11c-c7eb62ab8195" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
          </div>
        </figure>
        <!-- End Browser Device -->
      </div>
      <!-- End Devices -->
    </div>
    <div id="tabs-with-card-5" class="hidden" role="tabpanel" aria-labelledby="tabs-with-card-item-3">
      <!-- Devices -->
      <div class="max-w-[1140px] lg:pb-32 relative">

        <!-- Browser Device -->
        <figure class="ms-auto me-12 relative z-[1] max-w-full w-[50rem] h-auto rounded-b-lg shadow-[0_2.75rem_3.5rem_-2rem_rgb(45_55_75_/_20%),_0_0_5rem_-2rem_rgb(45_55_75_/_15%)] dark:shadow-[0_2.75rem_3.5rem_-2rem_rgb(0_0_0_/_20%),_0_0_5rem_-2rem_rgb(0_0_0_/_15%)]">

          <div class="bg-neutral-800 rounded-b-lg">
            <div style="position: relative; padding-bottom: 62.5%; height: 0;"><iframe src="https://www.loom.com/embed/022fe2b5c5d94ab7b6e9ba7dbcc64e43?sid=ec827172-6e2b-4322-a486-5520b034c160" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
          </div>
        </figure>
        <!-- End Browser Device -->
      </div>
      <!-- End Devices -->
    </div>
  </div>
  <!-- End Tab Content -->
</div>
<!-- End Features -->
</template>