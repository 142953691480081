<template>
	<header class="fixed top-0 w-full z-infinity">
		<div
			class="bg-gradient-to-tl from-blue-600 to-violet-600 shadow-lg shadow-transparent hover:shadow-blue-700/50 w-full px-4 py-1 text-white sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8"
		>
			<a
				class="text-center font-medium sm:text-left"
				target="_blank"
				href="https://discord.com/invite/dUepvsJF"
			>
				Need Help?
				<br class="sm:hidden" />
				Join our private discord for fast support
			</a>
		</div>
		<nav class="bg-white border-neutral-200 py-2.5 dark:bg-transparent">
			<div
				class="flex flex-wrap items-center justify-between max-w-screen-xl px-4 mx-auto"
			>
				<a href="/" class="relative inline-flex items-center">
					<img src="@/assets/icon.png" alt="" class="h-10" />
					<!-- <div
            class="absolute inline-flex items-center justify-center text-xs font-bold text-white bg-green-500 border-2 border-white rounded-full -top-2 -right- dark:border-gray-900">
            Alpha</div> -->
				</a>
				<div class="flex items-center lg:order-2">
					<a
						v-if="!isUser"
						href="/api/v1/login"
						class="text-neutral-800 dark:text-white hover:bg-neutral-50 focus:ring-4 focus:ring-neutral-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 dark:hover:bg-neutral-700 focus:outline-none dark:focus:ring-neutral-800"
						>Sign up/in</a
					>

					<img
						v-if="hasIP"
						:src="JSON.parse(IP).country_flag"
						class="h-5 m-2"
					/>
					<!-- <theme /> -->
				</div>
				<!-- <div
					class="items-center justify-between hidden w-full lg:flex lg:w-auto lg:order-1 bg-gradient-to-tl from-blue-600 to-violet-600 p-5 rounded-3xl opacity-90"
					id="mobile-menu-2"
				>
					<ul
						class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0"
					>
						<li class="border-none">
							<a
								href="#features"
								class="block py-2 pl-3 pr-4 text-neutral-700 rounded-3xl hover:bg-neutral-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-100 lg:dark:hover:text-white dark:hover:bg-neutral-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-neutral-700"
								>Features</a
							>
						</li>
						<li>
							<a
								href="#kpis"
								class="block py-2 pl-3 pr-4 text-neutral-700 rounded-3xl hover:bg-neutral-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-100 lg:dark:hover:text-white dark:hover:bg-neutral-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-neutral-700"
								>KPIs</a
							>
						</li>
						<li>
							<a
								href="#pricing"
								class="block py-2 pl-3 pr-4 text-neutral-700 rounded-3xl hover:bg-neutral-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-100 lg:dark:hover:text-white dark:hover:bg-neutral-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-neutral-700"
								>Pricing</a
							>
						</li>

						<li>
							<a
								href="#faq"
								class="block py-2 pl-3 pr-4 text-neutral-700 rounded-3xl hover:bg-neutral-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-100 lg:dark:hover:text-white dark:hover:bg-neutral-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-neutral-700"
								>Faq</a
							>
						</li>
						<li v-if="isUser">
							<router-link
								to="dashboard"
								class="block py-2 pl-3 pr-4 text-neutral-700 rounded-3xl hover:bg-neutral-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-100 lg:dark:hover:text-white dark:hover:bg-neutral-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-neutral-700"
								>Dashboard</router-link
							>
						</li>
						<li v-else>
							<a
								href="/api/v1/login"
								class="block py-2 pl-3 pr-4 text-neutral-700 rounded-3xl hover:bg-neutral-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-100 lg:dark:hover:text-white dark:hover:bg-neutral-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-neutral-700"
								>Sign Up</a
							>
						</li>
					</ul>
				</div> -->
			</div>
		</nav>
	</header>
</template>
<script>
	export default {
		props: {},
		computed: {
			user() {
				return localStorage.getItem("user")
			},
			IP() {
				return localStorage.getItem("IP")
			},
			hasIP() {
				if (this.IP == null) {
					return false
				} else {
					return true
				}
			},

			isUser() {
				if (this.user == null) {
					return false
				} else {
					return true
				}
			},
		},
	}
</script>
<!-- <style>
  *, ::before, ::after {
    border-style: none!important;
  }
</style> -->
