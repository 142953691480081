<template>
	<div class="my-2">
		<span
			class="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-md text-xs font-medium border border-gray-200 bg-white text-gray-800 shadow-sm dark:bg-neutral-900 dark:border-gray-700 dark:text-white"
			v-for="tag in tags"
			>{{ tag }}</span
		>
	</div>
	<div class="relative flex w-full">
		<select
			ref="select"
			id="select-tag"
			name="tags[]"
			v-model="tags"
			multiple
			placeholder="Select tags..."
			autocomplete="off"
			class="block w-full rounded-sm cursor-pointer focus:outline-none bg-white dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
		>
			<option v-for="tag in websiteTags" :value="tag">{{ tag }}</option>
		</select>
	</div>
</template>
<script>
	import TomSelect from "tom-select"
	import "tom-select/dist/css/tom-select.css"
	import { useProjectStore } from "@/stores/ProjectStore"
	export default {
		data() {
			return {
				projectStore: useProjectStore(),
				websiteTags: [
					"Business",
					"Stategy",
					"Health",
					"Adventure",
					"Creative",
					"Environment",
					"Education", // For educational content, e-learning platforms
					"E-commerce", // Online stores, product sales
					"News", // News portals, journalism sites
					"Entertainment", // Movies, music, games, leisure activities
					"Technology", // Tech news, reviews, forums
					"Healthcare", // Health advice, medical information
					"Travel", // Travel blogs, booking sites, travel guides
					"Food", // Cooking, recipes, food blogs
					"Finance", // Banking, investment, financial advice
					"Sports", // Sports news, team websites, fan clubs
					"Lifestyle", // Lifestyle blogs, fashion, living
					"Art", // Galleries, artist portfolios, exhibitions
					"Science", // Scientific research, publications, discussions
					"Social", // Social networking, community building
					"Real Estate", // Property listings, real estate advice
					"Environment", // Environmental activism, information
					"Legal", // Legal advice, law firm websites
					"Government", // Government resources, public services
					"Non-Profit", // Charities, non-profit organizations
					"Corporate", // Corporate websites, business-to-business (B2B)
					"Personal Blog", // Personal blogs, portfolios
					"Forum", // Discussion forums, community boards
					"Event", // Event promotion, ticket sales
					"Multimedia", // Sites rich in multimedia content
					"Educational Resources", // Sites providing educational materials and resources
					"Job Portal", // Employment, career advice, job listings
					"Culture", // Cultural information, ethnic and societal topics
					"Automotive", // Cars, motorbikes, automotive news
					"Gaming", // Video games, gaming communities, news
					"DIY", // Do-it-yourself, crafting, home improvement
					"Pets", // Pet care, pet products, forums
					"Parenting", // Parenting advice, family-oriented content
					"Religion", // Religious content, places of worship
					"History", // Historical information, educational content
					"Psychology", // Mental health, psychology resources
					"Astronomy", // Space, astronomy news, educational content
					"Hobby", // Sites about specific hobbies or interests
					"Music", // Music streaming, artist websites, music news
					"Photography", // Photography portfolios, tips, galleries
					"Fitness", // Fitness advice, workout plans, health tips
					"Dating", // Online dating, relationship advice
					"Politics", // Political blogs, party websites, political news
					"Freelancing", // Resources for freelancers, job listings
					"Tutorial", // How-to guides, instructional content
					"Podcast", // Podcast hosting, episodes, discussions
					"Streaming", // Video streaming, live broadcasts
					"Book", // Book reviews, author websites, online bookstores
					"Anime", // Anime news, forums, streaming
					"Manga", // Manga comics, news, communities
					"VR/AR", // Virtual and Augmented Reality content and news
					"IoT", // Internet of Things, connected devices, news
					"Crypto", // Cryptocurrency news, trading, information
					"Sustainability", // Sustainable living, environmental products
					"Philanthropy", // Philanthropic initiatives, fundraising
					"Research", // Academic research, publications, databases
					"Mobile Apps", // Mobile app promotion, reviews
					"Software", // Software products, SaaS platforms
					"Hardware", // Hardware reviews, tech products
					"Telecommunications", // Telecom services, industry news
					"E-Commerce", // for online stores
					"Blog", // for personal or professional blogs
					"News", // for news portals and magazines
					"Educational", // for educational resources and institutions
					"Entertainment", // for movies, music, gaming sites
					"Portfolio", // for personal or professional portfolios
					"Corporate", // for business and corporate websites
					"Non-Profit", // for charities and non-profit organizations
					"Social Media", // for social networking platforms
					"Healthcare", // for medical and health-related sites
					"Technology", // for tech-focused content
					"Sports", // for sports news, teams, and facilities
					"Travel", // for travel and tourism-related sites
					"Food & Beverage", // for restaurants, recipes, and food blogs
					"Finance", // for banking, investment, and financial advice
					"Lifestyle", // for fashion, beauty, and lifestyle content
					"Real Estate", // for property listings and real estate advice
					"Forum", // for discussion boards and forums
					"Government", // for official government websites
					"Legal", // for legal advice and law-related content
					"Environment", // for environmental and sustainability-focused sites
					"Art & Design", // for art galleries, design, and creative content
					"DIY & Crafts", // for do-it-yourself and crafting sites
					"Personal", // for personal websites, diaries, or journals
					"Mobile App", // for websites related to mobile applications
					"Multimedia", // for sites rich in video, audio, and interactive content
					"Accessibility", // for sites prioritizing accessible content
					"Adult", // for adult content (where appropriate)
					"Job Board", // for employment and career-related sites
					"Community", // for local, community-focused sites
					"Religious", // for religious and spiritual content
					"Cultural", // for cultural and heritage websites
					"Event Planning", // for events, weddings, and party planning
					"SaaS", // for Software as a Service platforms
					"AI & Machine Learning", // for AI and machine learning-related content
					"Gaming", // for video game-related sites
					"Music & Podcast", // for music and podcast streaming services
					"Photography", // for photography and photo-sharing sites
					"Automotive", // for car and automotive-related content
					"Pets & Animals", // for pet care, adoption, and animal-related content
					"Agriculture", // for farming and agricultural websites
					"Science", // for scientific research and discussion
					"Historical", // for historical content and archives
					"Charity", // for fundraising and charitable organizations
					"Subscription Service", // for sites offering subscription-based services
					"VR & AR", // for virtual and augmented reality content
					"Cryptocurrency", // for blockchain and cryptocurrency-related content
					"Online Learning", // for e-learning and online courses
					"Freelancing", // for freelance and gig economy platforms
					"Family & Parenting", // for family-oriented and parenting sites
					"Youth", // for sites targeting children or teenagers
					"Seniors", // for sites focusing on older adults
					"Multilingual", // for websites offering content in multiple languages
					"Local Business", // for small and local business websites
					"Public Service", // for public service and community support sites
					"Podcasting", // specifically for podcast hosting and distribution
					"Crowdfunding", // for crowdfunding and fundraising platforms
					"Hobbies", // for hobby-related content
					"Dating", // for online dating and social meetups
					"Sustainability", // for eco-friendly and sustainable living content
					"Security", // for cybersecurity and online safety content
					"Networking", // for professional networking platforms
					"Startup", // for startup companies and entrepreneurship content
					"Research", // for academic and research-focused websites
					"Review", // for product and service review platforms
					"Collaboration", // for collaborative projects and team sites
					"Mental Health", // for mental health and wellness content
					"Hosting", // for web hosting and domain services
					"Plugin & Extension", // for browser plugins and extensions
					"API Services", // for websites offering API services
					"Open Source", // for open-source projects and communities
					"Marketplace", // for online marketplaces and trading platforms
					"Tutorial", // for educational tutorials and guides
					"Resource", // for resource libraries and directories
					"Comparison", // for comparison and benchmarking sites
					"Web Development", // for web development tools and resources
					"Podcasting", // for podcast creation and distribution
					"Virtual Event", // for online events and webinars
					"Remote Work", // for remote working tools and resources
					"Productivity", // for productivity tools and apps
					"Video Streaming", // for video streaming services and platforms
					"Music Streaming", // for music streaming services and platforms
					"Book & Literature", // for literary content, e-books, and authors
					"News Aggregator", // for news aggregation and curation
					"Search Engine", // for search engines and web directories
					"Plugin & Extension", // for browser plugins and extensions
					"Online Forum", // for online discussion and forums
					"Weather", // for weather forecasting and related information
					"Astrology", // for astrology and horoscope content
					"Meme & Entertainment", // for humor, memes, and entertainment content
					"Fitness & Wellness", // for fitness, gym, and wellness sites
					"Diet & Nutrition", // for diet, nutrition, and healthy eating
					"Social Advocacy", // for social issues and advocacy platforms
					"Digital Marketing", // for digital marketing and SEO tools
					"Analytics", // for analytics and data visualization tools
					"E-Learning", // for e-learning and educational courses
					"Virtual Reality", // for virtual reality experiences and content
					"Augmented Reality", // for augmented reality applications and content
					"Music Production", // for music production and editing tools
					"Graphic Design", // for graphic design tools and resources
					"Film & Video", // for film making and video production content
					"Photography", // for photography tips and photo sharing
					"Fashion & Beauty", // for fashion, makeup, and beauty tips
					"Travel & Adventure", // for travel guides and adventure blogs
					"Food & Cooking", // for cooking recipes and food blogs
					"Gardening & Landscaping", // for gardening tips and landscaping ideas
					"Home Improvement", // for home renovation and DIY projects
					"Parenting & Family", // for parenting advice and family activities
					"Health & Fitness", // for health tips and fitness routines
					"Mental Health", // for mental health resources and support
					"Finance & Investing", // for financial advice and investment tips
					"Technology & Gadgets", // for tech news and gadget reviews
					"Gaming & Esports", // for video game news and esports coverage
					"Sports & Recreation", // for sports news and recreational activities
					"Music & Entertainment", // for music news and entertainment updates
					"Art & Culture", // for art exhibitions and cultural events
					"Science & Research", // for scientific discoveries and research findings
					"Education & Learning", // for educational resources and learning tools
					"Business & Entrepreneurship", // for business news and entrepreneurship advice
					"Politics & Government", // for political news and government updates
					"Environment & Sustainability", // for environmental news and sustainability tips
					"Healthcare & Medicine", // for medical news and health care updates
					"Legal & Law", // for legal advice and law-related news
					"Real Estate & Property", // for real estate listings and property news
					"Automotive & Vehicles", // for car reviews and automotive news
					"Travel & Hospitality", // for travel tips and hospitality news
					"Food & Beverages", // for food industry news and beverage updates
					"Retail & E-commerce", // for retail news and e-commerce trends
					"Manufacturing & Industry", // for manufacturing news and industrial updates
					"Energy & Utilities", // for energy sector news and utility updates
					"Telecommunications & Media", // for telecom news and media updates
					"Banking & Finance", // for banking news and financial updates
					"Insurance & Risk Management", // for insurance news and risk management tips
					"Construction & Engineering", // for construction news and engineering updates
					"Agriculture & Farming", // for agricultural news and farming tips
					"Mining & Resources", // for mining news and resource updates
					"Logistics & Transportation", // for logistics news and transportation updates
					"Public Services & Government", // for public service news and government updates
					"Non-profit & Charity", // for non-profit news and charity updates
					"Education & Academia", // for educational news and academic updates
					"Research & Development", // for research news and development updates
					"Health & Wellness", // for health and wellness tips and updates
					"Lifestyle & Leisure", // for lifestyle news and leisure updates
					"Arts & Entertainment", // for arts news and entertainment updates
					"Sports & Fitness", // for sports news and fitness updates
					"Travel & Tourism", // for travel news and tourism updates
				],
			}
		},
		emits: ["modified"],
		watch: {
			tags() {
				this.$emit("modified")
			},
		},
		computed: {
			project() {
				return this.projectStore.project
			},
			tags: {
				get() {
					return this.projectStore.tags
				},
				set(e) {
					this.projectStore.setTags(e)
				},
			},
		},
		mounted() {
			new TomSelect(this.$refs.select, {
				// Tom Select options
			})
			if ("tags" in this.project && this.project.tags != "") {
				this.tags = JSON.parse(this.project.tags)
			}
		},
	}
</script>
<style>
	.ts-control,
	.ts-wrapper.single.input-active .ts-control {
		background-color: inherit;
		color: inherit;
	}
	.ts-dropdown,
	.ts-control,
	.ts-control input {
		color: inherit !important;
	}
	.ts-background {
		background: inherit;
		color: inherit !important;
	}
</style>
