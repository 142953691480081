<template>
    <!-- <navigation /> -->
    <main class="bg-neutral-900 min-h-screen text-left" id="content" role="main">
        <div class="cl6bi pgrdw qbu9t r0x22 qazpp">
            <!-- Breadcrumb -->
            <ol class="bzhis qsf3i flex items-center mfkro" aria-label="Breadcrumb">
                <li class="flex items-center dtjcu v474b dark:text-neutral-500">
                    Products
                    <svg class="wlxy3 lo4kb trvun qcpnt sfo8l qv2j8 dark:text-neutral-600"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path d="m9 18 6-6-6-6"></path>
                    </svg>
                </li>
                <li class="dtjcu kxhcs j4ei7 truncate dark:text-neutral-200" aria-current="page">
                    Product Details
                </li>
            </ol>
            <!-- End Breadcrumb -->
            <div class=" flex lgqw8 pdrgo zpy54 p-5">

                <vue-feather type="folder-plus" class="text-white mx-auto" size="36" />
                <p class="mt-5 kxhcs v7056 dark:text-neutral-300">
                    Bulk Add Products
                </p>
                <p class="mt-1 dtjcu fyxhw dark:text-neutral-500">
                    You can add products from a spreadsheet to make the process of adding your products faster
                </p>

                <div class="mt-3">
                    <a
                        class="py-2 vrf2y pqrvw items-center dqqs4 dtjcu eass7 nq4w8 l66z3 d1k81 f0dty v7056 tgfrq cjy9h kko9e tk4f7 focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                        <label for="hs-pro-deuuf"
                            class="relative cursor-pointer xxfsd vrf2y pqrvw items-center dqqs4 dtjcu kxhcs nq4w8 l66z3 yj6bp k6q5b dafkk zwj7f kko9e tk4f7  dark:bg-neutral-800 dark:text-green-500 dark:hover:text-green-400">
                            <svg class="wlxy3 jhf5t sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                <polyline points="7 10 12 15 17 10"></polyline>
                                <line x1="12" x2="12" y1="15" y2="3"></line>
                            </svg>
                            Import products
                            <input id="hs-pro-deuuf" type="file"
                                accept=".xlsx, .xls, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                class="sr-only" name="hs-pro-deuuf" @change="handleXLS">

                        </label>
                    </a>
                </div>
                <div id="header-edit" v-if="showXLSMap">

                    <div v-for="(item, i) in predefinedHeaders" class="m-1">
                        <div class="sm:flex rounded-lg shadow-sm">
                            <input type="text" :value="item"
                                class="py-3 px-4 pe-11 block w-full border-neutral-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-100 dark:focus:ring-neutral-600"
                                disabled>
                            <span
                                class="py-3 px-4 inline-flex items-center min-w-fit w-full border border-neutral-200 bg-neutral-50 text-sm text-neutral-500 -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:w-auto sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg dark:bg-neutral-700 dark:border-neutral-700 dark:text-neutral-400">
                                <svg class="hidden sm:block h-4 w-4 text-neutral-400" xmlns="http://www.w3.org/2000/svg"
                                    width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M8 3 4 7l4 4" />
                                    <path d="M4 7h16" />
                                    <path d="m16 21 4-4-4-4" />
                                    <path d="M20 17H4" />
                                </svg>
                                <svg class="sm:hidden mx-auto h-4 w-4 text-neutral-400" xmlns="http://www.w3.org/2000/svg"
                                    width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <path d="m3 16 4 4 4-4" />
                                    <path d="M7 20V4" />
                                    <path d="m21 8-4-4-4 4" />
                                    <path d="M17 4v16" />
                                </svg>
                            </span>
                            <input type="text" v-model="map[item]"
                                class="py-3 px-4 pe-11 block w-full border-neutral-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-100 dark:focus:ring-neutral-600">
                        </div>
                    </div>
                    <button v-if="!load"
                        class="xxfsd vrf2y pqrvw items-center dqqs4 dtjcu kxhcs nq4w8 l66z3 yj6bp k6q5b dafkk zwj7f kko9e tk4f7 focus:outline-none focus:ring-2 focus:ring-green-500 my-2"
                        @click="mapXLS">
                        <svg class="hidden p6u16 wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round">
                            <path d="M5 12h14"></path>
                            <path d="M12 5v14"></path>
                        </svg>
                        Map & Upload
                    </button>
                    <div class="text-center mx-auto justify-center align-center" v-else>
                        <vue-feather type="loader" class="spin dark:text-white" />
                    </div>
                </div>

                <div class="xxfsd ugq3y sly8f bcfme" v-if="!showXLSMap">
                    <!-- Products Grid -->
                    <div class="jjege oh4rk iffth fvpo4">
                        <div class="jpia3 fngso">
                            <!-- Products Card -->
                            <div
                                class="flex lgqw8 f0dty l66z3 ffqht bx801 k3u76 tgfrq dark:bg-neutral-800 dark:border-neutral-700">
                                <!-- Header -->
                                <div class="mvnfe qxuf9 flex azaj8 items-center zn9hg bg8gm ffqht dark:border-neutral-700">
                                    <h2 class="uiqm5 kxhcs j4ei7 dark:text-neutral-200">
                                        Product info
                                    </h2>
                                </div>
                                <!-- End Header -->

                                <!-- Body -->
                                <div class="pqx67 fngso">
                                    <div>
                                        <label for="hs-pro-daufnm"
                                            class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                            Name
                                            <span class="hs-tooltip uiqm5 ja4wp">
                                                <svg class="wlxy3 sfo8l qcpnt fj6be dark:text-neutral-400"
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round">
                                                    <circle cx="12" cy="12" r="10"></circle>
                                                    <path d="M12 16v-4"></path>
                                                    <path d="M12 8h.01"></path>
                                                </svg>
                                                <span
                                                    class="hs-tooltip-content tnij2 hnhol opacity-0 l46pc hidden imt5j kp058 dhtqv npcr1 grnyh kggzg rvi38 qfc33 dafkk nq4w8 tgfrq dark:bg-slate-700"
                                                    role="tooltip" data-popper-placement="top"
                                                    style="position: fixed; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(87.5px, -471px, 0px);">
                                                    Give your product a short and clear name.
                                                </span>
                                            </span>
                                        </label>
                                        <input id="hs-pro-daufnm" type="text"
                                            class="xxfsd vrf2y block w-full ffqht nq4w8 dtjcu j4ei7 mjqc8 focus:z-10 focus:border-green-600 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
                                            placeholder="Shirt, t-shirts, etc." v-model="name">
                                    </div>
                                    <!-- End Input -->

                                    <!-- Input Grid -->
                                    <div class="jjege no4dk c6e0s otbql">
                                        <!-- Input -->
                                        <div>
                                            <label for="hs-pro-daufsku"
                                                class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                                SKU
                                            </label>
                                            <input id="hs-pro-daufsku" type="text"
                                                class="xxfsd vrf2y block w-full ffqht nq4w8 dtjcu j4ei7 mjqc8 focus:z-10 focus:border-green-600 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
                                                placeholder="eg. 348121032" v-model="sku">
                                        </div>
                                        <!-- End Input -->

                                        <!-- Input -->
                                        <div>
                                            <label for="hs-pro-daufwe"
                                                class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                                Weight
                                            </label>
                                            <div class="relative w-full">
                                                <input id="hs-pro-daufwe" type="text"
                                                    class="xxfsd vrf2y block w-full ffqht nq4w8 dtjcu j4ei7 mjqc8 focus:z-10 focus:border-green-600 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
                                                    placeholder="0.0" v-model="weight">
                                            </div>

                                            <p class="f0kop rvi38 fj6be dark:text-neutral-500">
                                                Used to calculate shipping rates at checkout and label prices during
                                                fulfillment.
                                            </p>
                                        </div>
                                        <!-- End Input -->
                                    </div>
                                    <!-- End Input Grid -->

                                    <div>
                                        <label for="hs-pro-daufsku"
                                            class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                            Description
                                        </label>

                                        <!-- Tiptap -->
                                        <div class="l66z3 ffqht k3u76 bx801 dark:border-neutral-700">
                                            <textarea id="hs-pro-daufwe" type="text"
                                                class="xxfsd vrf2y block w-full ffqht nq4w8 dtjcu j4ei7 mjqc8 focus:z-10 focus:border-green-600 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600 h-96"
                                                placeholder="0.0" v-model="description"></textarea>
                                        </div>
                                        <!-- End Tiptap -->
                                    </div>
                                </div>
                                <!-- End Body -->
                            </div>
                            <!-- End Products Card -->

                            <!-- Media Card -->
                            <div
                                class="flex lgqw8 f0dty l66z3 ffqht bx801 k3u76 tgfrq dark:bg-neutral-800 dark:border-neutral-700">
                                <!-- Header -->
                                <div class="mvnfe qxuf9 flex azaj8 items-center zn9hg bg8gm ffqht dark:border-neutral-700">
                                    <h2 class="uiqm5 kxhcs j4ei7 dark:text-neutral-200">
                                        Media
                                    </h2>

                                    <div class="flex prqc8 items-center dqqs4">
                                        <button @click="uploadFiles" type="button"
                                            class="xxfsd kdz1q pqrvw items-center pla0f rvi38 eass7 nq4w8 l66z3 ffqht f0dty j4ei7 tgfrq jonah kko9e tk4f7 focus:outline-none focus:bg-stone-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                                            <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24"
                                                height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                <path d="M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242"></path>
                                                <path d="M12 12v9"></path>
                                                <path d="m16 16-4-4-4 4"></path>
                                            </svg>
                                            Upload files
                                        </button>
                                    </div>
                                </div>
                                <!-- End Header -->

                                <!-- Body -->
                                <div class="pqx67 fngso">
                                    <!-- Grid -->
                                    <div class="jjege zvyci sc5b4 c6e0s">
                                        <!-- Card -->
                                        <div id="dismiss-img1" class="relative" v-for="image in images">
                                            <div class="wlxy3 relative k3u76 bx801 w-full l9mk6">
                                                <img class="w-full raxhy absolute mbsar atruh hffo2 k3u76" :src="image"
                                                    alt="Image Description">
                                            </div>
                                            <div class="absolute yfxdb uy5ft wlsmd">
                                                <button type="button"
                                                    class="cslsl uunzg pqrvw pdrgo items-center pla0f eass7 dtjcu iwtls l66z3 ffqht f0dty v474b tgfrq jonah kko9e tk4f7 focus:outline-none focus:bg-stone-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                                                    data-hs-remove-element="#dismiss-img1">
                                                    <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg"
                                                        width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round">
                                                        <path d="M18 6 6 18"></path>
                                                        <path d="m6 6 12 12"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <!-- End Card -->
                                        <!-- Card -->
                                        <div id="dismiss-img1" class="relative" v-for="file in selectedFiles">
                                            <div class="wlxy3 relative k3u76 bx801 w-full l9mk6">
                                                <img class="w-full raxhy absolute mbsar atruh hffo2 k3u76"
                                                    :src="file.dataUrl" alt="Image Description">
                                            </div>
                                            <div class="absolute yfxdb uy5ft wlsmd">
                                                <button type="button"
                                                    class="cslsl uunzg pqrvw pdrgo items-center pla0f eass7 dtjcu iwtls l66z3 ffqht f0dty v474b tgfrq jonah kko9e tk4f7 focus:outline-none focus:bg-stone-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                                                    data-hs-remove-element="#dismiss-img1">
                                                    <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg"
                                                        width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round">
                                                        <path d="M18 6 6 18"></path>
                                                        <path d="m6 6 12 12"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <!-- End Card -->
                                    </div>
                                    <!-- End Grid -->

                                    <!-- Drag 'n Drop -->
                                    <div class="flex items-center justify-center w-full">
                                        <label for="dropzone-file"
                                            class="flex flex-col items-center justify-center w-full h-64 border-2 border-neutral-300 border-dashed rounded-lg cursor-pointer bg-neutral-50 dark:hover:bg-bray-800 dark:bg-neutral-700 hover:bg-neutral-100 dark:border-neutral-600 dark:hover:border-neutral-500 dark:hover:bg-neutral-600">
                                            <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                                <svg class="w-8 h-8 mb-4 text-neutral-500 dark:text-neutral-400"
                                                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                    viewBox="0 0 20 16">
                                                    <path stroke="currentColor" stroke-linecap="round"
                                                        stroke-linejoin="round" stroke-width="2"
                                                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                                </svg>
                                                <p class="mb-2 text-sm text-neutral-500 dark:text-neutral-400">
                                                    <span class="font-semibold">Click to upload</span> or drag and drop
                                                </p>
                                                <p class="text-xs text-neutral-500 dark:text-neutral-400">
                                                    Upload JPG, PNG, and SVG Files (Max 300KB)
                                                </p>
                                            </div>
                                            <input id="dropzone-file" type="file" class="hidden"
                                                accept=".jpg, .jpeg, .png, .svg, .gif, .webp" @change="handleMultiChange"
                                                multiple />
                                        </label>
                                    </div>
                                    <!-- End Drag 'n Drop -->

                                    <p class="dtjcu fj6be dark:text-neutral-500">
                                        Add up to 10 images to your product.
                                    </p>
                                </div>
                                <!-- End Body -->
                            </div>
                            <!-- End Media Card -->

                        </div>
                        <!-- End Col -->

                        <div class="w6es2">
                            <div class="kg1v7 oys67 fngso">
                                <!-- Product Pricing Card -->
                                <div
                                    class="flex lgqw8 f0dty l66z3 ffqht bx801 k3u76 tgfrq dark:bg-neutral-800 dark:border-neutral-700">
                                    <!-- Header -->
                                    <div
                                        class="mvnfe qxuf9 flex azaj8 items-center zn9hg bg8gm ffqht dark:border-neutral-700">
                                        <h2 class="uiqm5 kxhcs j4ei7 dark:text-neutral-200">
                                            Pricing
                                        </h2>
                                    </div>
                                    <!-- End Header -->

                                    <!-- Body -->
                                    <div id="hs-product-details-pricing-card-body" class="pqx67 fngso">
                                        <!-- Input -->
                                        <div>
                                            <label for="hs-pro-daufpr"
                                                class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                                Price
                                            </label>
                                            <div class="relative w-full">
                                                <input id="hs-pro-daufpr" type="text"
                                                    class="xxfsd vrf2y block w-full ffqht nq4w8 dtjcu j4ei7 mjqc8 focus:z-10 focus:border-green-600 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
                                                    placeholder="0.00" v-model="price">
                                            </div>
                                        </div>
                                        <!-- End Input -->

                                        <!-- Switch/Toggle -->
                                        <div
                                            class="xxfsd vrf2y flex azaj8 items-center l66z3 ffqht nq4w8 dark:border-neutral-700">
                                            <label for="hs-pro-epdas"
                                                class="uiqm5 yssyn dtjcu j4ei7 dark:text-neutral-200">Availability</label>
                                            <div class="relative uiqm5">
                                                <input type="checkbox" id="hs-pro-epdas"
                                                    class="relative xlbew fnype o5z6a si6qh yj6bp tj0d4 iwtls cursor-pointer xgbpo le6lt kfd49 focus:ring-green-600 kko9e tk4f7 checked:bg-none checked:text-green-600 checked:border-green-600 focus:checked:border-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-900

                      o707b mudve kjsto sll73 checked:before:bg-white uim48 checked:before:translate-x-full ud3ze odb7z hv39m dyuqh yr432 hjcrw fx7fi dark:before:bg-neutral-400 dark:checked:before:bg-white"
                                                    v-model="availability">
                                            </div>
                                        </div>
                                        <!-- End Switch/Toggle -->

                                    </div>
                                    <!-- End Body -->
                                </div>
                                <!-- End Product Pricing Card -->

                                <!-- Organization Card -->
                                <div
                                    class="flex lgqw8 f0dty l66z3 ffqht bx801 k3u76 tgfrq dark:bg-neutral-800 dark:border-neutral-700">
                                    <!-- Header -->
                                    <div
                                        class="mvnfe qxuf9 flex azaj8 items-center zn9hg bg8gm ffqht dark:border-neutral-700">
                                        <h2 class="uiqm5 kxhcs j4ei7 dark:text-neutral-200">
                                            Misc
                                        </h2>
                                    </div>
                                    <!-- End Header -->

                                    <!-- Body -->
                                    <div id="hs-product-details-organization-card-body" class="pqx67 fngso">

                                        <!-- Input -->
                                        <div>
                                            <label class="block tn07p dtjcu eass7 j4ei7 dark:text-neutral-200">
                                                Category
                                            </label>
                                            <input id="hs-pro-daufvd" type="text"
                                                class="xxfsd vrf2y block w-full ffqht nq4w8 dtjcu j4ei7 mjqc8 focus:z-10 focus:border-green-600 focus:ring-green-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
                                                placeholder="eg. Nike" v-model="category">

                                        </div>
                                        <!-- End Input -->
                                    </div>
                                    <!-- End Body -->
                                </div>
                                <!-- End Organization Card -->
                            </div>
                        </div>
                        <!-- End Col -->

                        <!-- Save/Discard/Detel Floating Card -->
                        <div class="fixed c2f2v lhik2 -translate-x-1/2 kcshs s269j w-full npofs">
                            <div class="xxfsd cc9n6 sfr0b o49ku iwtls u1489 dark:bg-neutral-950">
                                <div class="flex azaj8 items-center ukt6v">
                                    <a class="fti2m cmffi eass7 dtjcu cdcib focus:outline-none focus:underline dark:text-red-500"
                                        href="#">Delete</a>

                                    <div class="pqrvw items-center dqqs4">
                                        <a class="ovi8j cmffi eass7 dtjcu cdcib focus:outline-none focus:underline dark:text-neutral-400"
                                            @click="$router.go(-1)">Cancel</a>
                                        <div class="lnq7o qcpnt o1een dark:bg-neutral-700"></div>
                                        <a class="c5fwt cmffi eass7 dtjcu cdcib focus:outline-none focus:underline dark:text-green-500"
                                            @click="saveChanges">Save
                                            changes</a>

                                        <!-- Close Button -->
                                        <button type="button"
                                            class="kbkb7 gpe5b pqrvw pdrgo items-center dqqs4 iwtls qv2j8 v53z2 focus:outline-none focus:bg-stone-700 kko9e tk4f7 dark:text-neutral-400 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                            <span class="j9hz5">Close</span>
                                            <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24"
                                                height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                <path d="M18 6 6 18"></path>
                                                <path d="m6 6 12 12"></path>
                                            </svg>
                                        </button>
                                        <!-- End Close Button -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Endn Save/Discard/Detel Floating Card -->
                    </div>
                    <!-- End Products Grid -->
                </div>
            </div>
        </div>
    </main>
    <success v-if="hasMessage" @ack="hasMessage = false" :message="message" />
</template>
<script>
import navigation from '@/views/ecommerce/navigation.vue'
import EcomMixin from '@/mixins/EcomMixin'
import ImageMixin from '@/mixins/ImageMixin'
export default {
    mixins: [EcomMixin, ImageMixin],
    data: () => {
        return {
            uploadModal: false,
            name: '',
            description: '',
            price: '',
            category: '',
            sku: '',
            weight: '',
            availability: '',
            brand: '',
            quantity: '',
            hasMessage: false,
            message: ``,
            load: false
        }
    },
    components: {
        navigation
    },
    mounted() {
        this.GetStore()
        if (this.$route.name == 'Edit Product') {
            this.GetProduct(this.$route.params.id)
        }
    },
    methods: {
        saveChanges() {
            if (this.$route.name == 'Edit Product') {
                this.EditProduct()
            } else {
                this.CreateProduct({})
            }
        }
    }
}
</script>