<template>
	<div
		class="relative overflow-x-auto shadow-md rounded-xl bg-opacity-30"
		v-if="!loading && datas != null"
	>
		<table
			class="w-full text-sm text-left text-neutral-500 dark:text-neutral-400"
		>
			<thead class="text-xs text-neutral-900 uppercase dark:text-neutral-400">
				<tr>
					<th
						scope="col"
						class="px-6 py-3"
						v-for="key in Object.keys(JSON.parse(datas[0].metadata))"
					>
						{{ key }}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="item in datas"
					class="bg-white border-b dark:bg-neutral-800 dark:border-neutral-700 hover:bg-neutral-50 dark:hover:bg-neutral-600"
				>
					<th
						scope="row"
						class="px-6 py-4 font-medium text-neutral-900 whitespace-nowrap dark:text-white"
						v-for="value in Object.values(JSON.parse(item.metadata))"
					>
						{{ value }}
					</th>
				</tr>
			</tbody>
		</table>
	</div>
	<loader v-if="loading" />
	<section
		class="bg-white dark:bg-neutral-900 bg-opacity-30 rounded-lg"
		v-if="datas == null && !loading"
	>
		<div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
			<div class="max-w-screen-md">
				<!-- <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-neutral-900 dark:text-white">No data</h2> -->
				<p
					class="mb-8 font-light text-md text-neutral-500 dark:text-neutral-400"
				>
					Sorry, but there is no data to display at the moment. It seems this
					section is empty. If you would like to submit data add a
					`submitButton` class to a button in the editor.
				</p>
			</div>
		</div>
	</section>
</template>
<script>
	import api from "@/services/api"
	import Cookies from "js-cookie"
	import { useHostStore } from "@/stores/HostStore"
	export default {
		data: () => {
			return {
				datas: null,
				loading: true,
				hostStore: useHostStore(),
			}
		},
		computed: {
			hostname() {
				return this.hostStore.hostname
			},
		},
		mounted() {
			this.getPageData()
		},
		methods: {
			async getPageData() {
				try {
					const response = await api.get(
						`${this.hostname}/api/v1/get/page/data/${this.$route.params.id}`
					)
					this.datas = response.data.data
					this.loading = false
					console.log(response.data)
				} catch (error) {
					console.log(error)
				}
			},
		},
	}
</script>
