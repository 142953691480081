const FindObjMixin = {
  methods: {
    findObjectWithTitle(objectsArray, titleToFind) {
      var foundObject = objectsArray.find(obj => obj.title === titleToFind);
      if (foundObject) {
        console.log("Found object:",foundObject);
        return foundObject
      } else {
        console.log("Object not found with title:", titleToFind);
        return false
      }
    }
  },
}

export default FindObjMixin