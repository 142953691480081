<template>
	<loader />
</template>
<script>
	import api from "@/services/api"
	import Cookies from "js-cookie"
	import { useHostStore } from "@/stores/HostStore"

	export default {
		data: () => {
			return {
				hostStore: useHostStore(),
			}
		},
		computed: {
			hostname() {
				return this.hostStore.hostname
			},
		},
		mounted() {
			this.handleCallback()
			this.AddSharePoints()
		},
		methods: {
			async AddSharePoints() {
				if (localStorage.getItem("referrer") != null) {
					try {
						const data = {
							points: 50,
							username: localStorage.getItem("referrer"),
						}

						const response = await api.post(
							`${this.hostname}/api/v1/add/sharepoints/`,
							data
						)
						console.log("Response:", response.data)
					} catch (error) {
						console.log("Status code:", error.response.status)
					}
				}
			},
			async handleCallback() {
				// Check Cookie
				try {
					const response = await api.get(
						`${this.hostname}/api/v1/checkout/sessions/get/${this.$route.params.id}`
					)
					console.log(response.data)
					this.updateSubscription(response.data[0])
				} catch (error) {
					console.log(error)
				}
				// if true change user subscribed field to true
			},
			async updateSubscription(sub) {
				try {
					const user = JSON.parse(localStorage.getItem("user"))
					user.data.subscription = JSON.stringify(sub)
					const response = await api.post(
						`${this.hostname}/api/v1/update/user/${user.data.id}`,
						user.data
					)
					console.log(response.data)
					localStorage.setItem("user", JSON.stringify(response.data))
					let n = Cookies.get("next")
					console.log(n)
					this.$router.push("/dashboard")
					// if (n != null || n != "") {
					//   this.$router.push(Cookies.get("next"));
					// } else {
					//   this.$router.push("/dashboard");
					// }
				} catch (error) {
					console.log(error)
				}
			},
		},
	}
</script>
