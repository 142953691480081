import api from "@/services/api"
import { useHostStore } from "@/stores/HostStore"
import { useImageStore } from "@/stores/ImageStore"

const ImageMixin = {
    data: () => {
        return {
            uploading: false,
            images: [],
            selectedFiles: [],
            selectedFile: null,
            selectedFileDataUrl: `https://t4.ftcdn.net/jpg/02/51/00/15/360_F_251001540_P8oe2YQ5v5dhZnrN5SFwXgLS0NMZXyNn.jpg`,
            hostStore: useHostStore(),
            imageStore: useImageStore()
        }
    },
    computed: {
        hostname() {
            return this.hostStore.hostname
        }
    },
    methods: {
        getProperImgSrc(url) {
            const base64ImageRegex = /^\s*data:(image\/[a-zA-Z+]+);base64,([A-Za-z0-9+/]+={0,2})\s*$/
            var i = this.getImageType(url)
            if (i == "JPEG" || i == "PNG") {
                return url
            } else {
                return `data:image/png;base64,` + url
            }
        },
        getImageType(url) {
            const extension = url.split(".").pop().toLowerCase()
            if (extension === "jpg" || extension === "jpeg") {
                return "JPEG"
            } else if (extension === "png") {
                return "PNG"
            } else {
                return "Unknown"
            }
        },
        handleFileChange(event) {
            const selectedFile = event.target.files[0]
            console.log("Selected file:", selectedFile)
            var fileSize = selectedFile.size // in bytes
            var maxSize = 800 * 1024 // 300KB
            if (fileSize > maxSize) {
                alert(
                    "Please select a JPG, PNG, or SVG file that is 700KB or smaller."
                )
            } else {
                if (selectedFile) {
                    this.selectedFile = selectedFile

                    // Read the selected file as a data URL
                    const reader = new FileReader()
                    reader.onload = e => {
                        this.selectedFileDataUrl = e.target.result
                    }
                    reader.readAsDataURL(selectedFile)
                    this.uploadFile()
                }
            }
        },
        handleMultiChange(event) {
            const files = event.target.files;
            if (files.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    const reader = new FileReader();
                    reader.onload = () => {
                        this.selectedFiles.push({
                            file: file,
                            dataUrl: reader.result
                        });
                    };
                    reader.readAsDataURL(file);
                }
            }
        },
        async uploadFiles() {
            var m = []
            for (let i = 0; i < this.selectedFiles.length; i++) {
                const f = new FormData()
                f.append("file", this.selectedFiles[i].file)

                const r = await api.post(`${this.hostname}/api/v1/upload/favicon/`, f, {
                    headers: {
                        "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
                    },
                })

                console.log(this.images)
                m.push(r.data.secure_url)
                this.images.push(r.data.secure_url)
            }

            this.imageStore.setImages(m)

        },
        async uploadFile() {
            this.uploading = true
            const f = new FormData()
            console.log(this.selectedFile)
            f.append("file", this.selectedFile)
            const r = await api.post(`${this.hostname}/api/v1/upload/favicon/`, f, {
                headers: {
                    "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
                },
            })
            console.log(r)
            this.$emit("uploaded", r.data.secure_url)
            this.selectedFileDataUrl = r.data.secure_url
            this.uploading = false
        },
    },
}

export default ImageMixin