<template>
	<div v-html="html" :style="css" v-if="!loading"></div>
	<success v-if="hasMessage" @ack="hasMessage = false" :message="message" />
	<popover v-if="submitted" />
	<Transition name="slide-alt">
		<cart v-if="showCart" @close="showCart = false" :project="project" />
	</Transition>
</template>
<script>
import api from "@/services/api"
import { useHostStore } from "@/stores/HostStore"
import CategorizeLinksMixin from "@/mixins/CategorizeLinksMixin.js"
import ParsableMixin from "@/mixins/ParsableMixin.js"
import LeaderboardMixin from "@/mixins/LeaderboardMixin"
import GeolocationMixin from "@/mixins/GeolocationMixin"
import SubscribeModal from "./subscribe.vue"
import PageAnalyticsMixin from "@/mixins/PageAnalyticsMixin"

import Cookies from "js-cookie"
import cta from "./cta.vue"
import { v4 as uuidv4 } from "uuid"
import { useProjectStore } from "@/stores/ProjectStore"
import { Page } from "grapesjs"
import cart from "@/views/ecommerce/gjsPlugins/cart.vue"
import { useCartStore } from "@/stores/CartStore"
import EcomMixin from "@/mixins/EcomMixin"

export default {
	props: ["pageProp", "projectProp"],

	data: () => {
		return {
			unsavedChanges: true,
			submitted: false,
			hostStore: useHostStore(),
			projectStore: useProjectStore(),
			cartStore: useCartStore(),
			content: "",
			html: "",
			css: "",
			js: "",
			message: "",
			hasMessage: false,
			project: null,
			page: null,
			cssSrc: null,
			loading: true,
			owner: Object,
			formData: null,
			showCart: false
		}
	},

	components: {
		cta,
		SubscribeModal,
		cart
	},
	mixins: [CategorizeLinksMixin, ParsableMixin, LeaderboardMixin, PageAnalyticsMixin, EcomMixin],
	mounted() {
		// if route is not render use page prop for page
		this.GetPage()
		Cookies.set("next", `/render/${this.$route.params.id}`)
		this.checkButton()

		document.addEventListener("click", this.handleDocumentClick)
		window.alert = function () {
			// this function overides other alerts
		}
		this.getIP()
		// If this is a view product page then get the product and display the product information

		if (this.$route.name == "Product Render") {
			this.GetProduct(this.$route.params.pid)
		}


	},
	unmounted() {
		document.removeEventListener("click", this.handleDocumentClick)
	},
	computed: {
		cart() {
			return this.cartStore.cart
		},
		pages() {
			return this.projectStore.pages
		},
		hostname() {
			return this.hostStore.hostname
		},
		isOwner() {
			const user = JSON.parse(localStorage.getItem("user"))
			console.log(user.id)
			console.log(this.owner.id)
			if (user != "" && user.data.id == this.owner.id) {
				return true
			}
			return false
		},
		hasSubscription() {
			// Check if user who owns the project has an active subscription
			if (this.owner.subscription != "") {
				const sub = JSON.parse(this.owner.subscription)
				if (sub.status == "active" || sub.status == "trialing") {
					return true
				}
				return false
			} else {
				return false
			}
		},
		isLeader() {
			const exists = this.leaderboard.some(
				element => element["username"] === this.owner.username
			)
			return exists
		},
		processedHtml() {
			const htmlWithoutFavicons = this.removeFavicons(this.html)
			const styleTag = `<style>${this.css}</style>`
			const linkTags = this.cssSrc
				.map(link => `<link rel="stylesheet" href="${link}">`)
				.join("")
			// Create an array of script elements
			const scriptElements = this.js.map(src => {
				const scriptElement = document.createElement("script")
				scriptElement.src = src
				return scriptElement
			})
			// Append the script elements to the body
			scriptElements.forEach(scriptElement => {
				document.body.appendChild(scriptElement)
			})
			return `${styleTag}${linkTags}${htmlWithoutFavicons}`
		},
	},
	methods: {
		mutateProductInfo() {
			var numImages = this.productPage.images.length;
			console.log(numImages)
			// Iterate over each index from 1 to numImages
			for (var i = 1; i <= numImages; i++) {
				console.log('GEtting images')
				// Construct the alt tag dynamically
				var classTag = 'productImage' + i;

				// Get all the images that match the alt attribute
				var images = document.querySelectorAll('img.' + classTag);
				console.log(images)
				// Loop through each image
				images.forEach((image) => {
					// Change the source of each image based on its index
					image.src = this.productPage.images[i - 1]; // Subtract 1 from index because array index starts from 0
				});
			}
			const productTitle = document.querySelector('.productTitle');
			const productPrice = document.querySelector('.productPrice');
			const productDescription = document.querySelector('.productDescription');
			const productShortDescription = document.querySelector('.productShortDescription');
			// const productTitle = document.querySelector('.productTitle');
			// const productTitle = document.querySelector('.productTitle');	

			// Check if the element exists
			if (productTitle) {
				productTitle.textContent = this.productPage.name;
				productPrice.textContent = this.productPage.price;
				productDescription.textContent = this.productPage.description;
				productShortDescription.textContent = this.shortenText(this.productPage.description);

				// productTitle.textContent = this.productPage.name;
				// productTitle.textContent = this.productPage.name;
				// productTitle.textContent = this.productPage.name;

			} else {
				console.error('Element with class "productTitle" not found.');
			}


		},
		shortenText(text) {
			// Split the text into an array of sentences
			let sentences = text.split(/[.!?]/);

			// Remove any empty strings from the array
			sentences = sentences.filter(sentence => sentence.trim() !== '');

			// Take the first three sentences and join them back into a string
			let shortenedText = sentences.slice(0, 3).join('. ');

			return shortenedText;
		},
		async GetPages() {
			try {
				const response = await api.get(
					`${this.hostname}/api/v1/projects/pages/get/${this.project._id}`
				)
				this.projectStore.setPages(response.data.pages)
			} catch (error) { }
		},
		mutateCart() {
			const cartCountElement = document.querySelector('.cartCount') || document.querySelector('.cart-quantity');
			// Check if the element exists
			if (cartCountElement) {
				cartCountElement.textContent = this.cart.length;
			} else {
				console.error('Element with class "cartCount" not found.');
			}
		},
		async handleDocumentClick(event) {
			// Check if 'link' exists to avoid errors in case it's null

			const link = event.target.closest("a") || event.target.closest("button") || event.target.closest("input")
			if (link && link.classList.contains('addToCart')) {
				// The element has the class 'addToCart'
				console.log('This element has the addToCart class.');
				this.cartStore.setCart(this.productPage)
				this.message = `${this.productPage.name} has been added to the cart`
				this.hasMessage = true
				// update the cart count on the 
				this.mutateCart()
			}
			var destinationUrl
			console.log(link)
			if (link) {
				// Prevent the default link navigation
				event.preventDefault()

				// Check if the element is a button
				if (link.tagName === "BUTTON") {
					destinationUrl = link.dataset.href; // Assuming you're using data-href as suggested in the previous answer
					console.log("Button clicked. Destination URL:", destinationUrl);
				}
				// Check if the element is an anchor tag
				else if (link.tagName === "A") {
					destinationUrl = link.href;
					console.log("Anchor clicked. Destination URL:", destinationUrl);
				}
				if (destinationUrl == undefined) {
					return
				}
				var lastSegment = destinationUrl.split("/").pop();
				if (lastSegment.includes("#")) {
					console.log("destinationUrl contains #");
					return
				} else {
					console.log("destinationUrl does not contain #");
				}

				var url = new URL(destinationUrl);

				// Get the hostname
				var hostname = url.hostname;

				if (window.location.hostname === hostname && destinationUrl.split("/").pop() == '') {
					if (this.$route.name != "render") {
						this.$router.push(`/`)
					} else {
						var home_page = this.pages.find(
							obj =>
								obj.title ===
								'home'
						)
						this.$router.push(`/render/${home_page._id}`)
					}
				}


				const currentUrl = window.location

				// if (destinationUrl.hostname !== currentUrl.hostname) {
				// 	window.location.href = destinationUrl
				// }
				console.log(`User tried to navigate to ${destinationUrl}`)

				if (destinationUrl.split("/").pop() == 'cart') {
					this.showCart = true
					return
				}
				const segments = destinationUrl.split("/")

				if (segments[1] == 'addToCart') {

					var p = await this.GetProduct(destinationUrl.split("/").pop())
					this.cartStore.setCart(p)
					this.message = `${p.name} has been added to the cart`
					this.hasMessage = true
					// update the cart count on the 
					this.mutateCart()
					return
				}

				console.log(segments)
				// if it is a render page we need to get the project so at view product event $route to /render/{project}/product/{product_name}

				if (segments[3] == 'products') {
					var product_page = this.pages.find(
						obj =>
							obj.title ===
							'products'
					)
					console.log(product_page)
					if (product_page != undefined) {
						if (this.projectProp == undefined) {
							this.$router.push(`/render/${product_page._id}/products/${segments[4]}`)
						} else {
							this.$router.push(`/products/${segments[4]}`)
						}
					}

					return
				}


				const a = this.pages.find(
					obj =>
						obj.title ===
						destinationUrl.split("/").pop()
				)

				console.log(this.trimStringToMaxLength(destinationUrl.split("/").pop(), 20))
				const buylinks = [
					`https://buy.stripe.com/cN2dROeqiedWfhmeVb`,
					`https://buy.stripe.com/28oaFC0zs8TC3yE7sK`,
					`https://buy.stripe.com/4gw4he1DwedW2uA4gz`,
					`https://buy.stripe.com/9AQ5ligyq8TCd9e7sM`,
				]
				if (a != undefined) {

					if (this.$route.name != "render") {
						this.$router.push(`/${a.title}`)
					} else {
						this.$router.push(`/render/${a._id}`)
					}
				} else {
					window.location.replace(destinationUrl)
				}

				// if (buylinks.includes(destinationUrl)) {
				// 	window.location.replace(destinationUrl)
				// } else {
				// 	this.message = "The requested route has not been created yet "
				// 	this.hasMessage = true
				// }

				// Here, add your custom logic
				// For example, check if there are unsaved changes, confirm navigation, etc.

				// If navigation is allowed after your checks, you can redirect manually
				// window.location.href = destinationUrl;
			}
		},
		trimStringToMaxLength(str, maxLength) {
			if (str.length > maxLength) {
				return str.slice(0, maxLength)
			}
			return str
		},
		async checkButton() {
			const button = document.querySelector(".submitButton") // Replace with your class name
			if (button !== null) {
				console.log("Button found!")
				// Add an event listener to a button or any other trigger
				document
					.querySelector(".submitButton")
					.addEventListener("click", () => {
						event.preventDefault() // Prevent any default button behavior
						const inputElements = document.querySelectorAll("input")
						// Create an object to store form data
						const formData = {}
						// Loop through each input element and gather data
						inputElements.forEach(input => {
							formData[input.name] = input.value
						})
						console.log("Submit button clicked")
						console.log(formData)
						this.formData = formData

						try {
							const data = {
								_id: uuidv4(),
								project: this.project._id,
								page: this.$route.params.id,
								metadata: JSON.stringify(this.formData),
							}
							api
								.post(`${this.hostname}/api/v1/create/page/data`, data)
								.then(r => {
									console.log("page data created")
									this.submitted = true
									setTimeout(() => {
										this.submitted = false
									}, 3000)
								})
						} catch (error) {
							console.error("Error:", error)
						} finally {
							// loading.value = false;
						}
					})
			} else {
				// Button does not exist yet, schedule the check again in 1 second
				setTimeout(this.checkButton, 1000) // 1000 milliseconds = 1 second
			}
		},
		removeFavicons(html) {
			const parser = new DOMParser()
			const doc = parser.parseFromString(html, "text/html")
			const faviconLinks = doc.querySelectorAll(
				'link[rel="icon"], link[rel="shortcut icon"], link[rel="apple-touch-icon"]'
			)
			faviconLinks.forEach(link => link.remove())
			const metaTags = doc.querySelectorAll(
				'meta[name="msapplication-TileImage"]'
			)
			metaTags.forEach(tag => tag.remove())
			// Create a new link element for the new favicon
			const newFaviconLink = document.createElement("link")
			// Set attributes for the new favicon link
			newFaviconLink.rel = "icon" // or 'shortcut icon' depending on your preference
			newFaviconLink.type = "image/x-icon" // Specify the favicon file type
			if (this.project.logo.length > 5) {
				newFaviconLink.href = this.project.logo
			} else {
				newFaviconLink.href = `https://api.dicebear.com/7.x/identicon/svg?seed=${"fasdkasdnlfasdnk"}`
			}
			// Replace with the path to your new favicon
			// Append the new favicon link to the head of the document
			document.head.appendChild(newFaviconLink)
			return doc.documentElement.outerHTML
		},
		async GetOwner(obj) {
			try {
				const response = await api.post(
					`${this.hostname}/api/v1/get/user`,
					obj
				)
				this.owner = response.data.data
				// Check if subscription has expired
				// if (!this.hasSubscription && this.isOwner) {
				// 	this.$router.push("/pricing")
				// }
				// if (!this.hasSubscription && !this.isOwner) {
				// 	this.$router.push("/cta")
				// }
			} catch (error) {
				console.log(error)
			}
		},
		async GetPage() {
			console.log("Getting Page ")
			try {
				let page
				let project
				if (this.$route.name == "render" || this.$route.name == "Product Render") {
					const response = await api.get(
						`${this.hostname}/api/v1/get/page/${this.$route.params.id}`
					)
					console.log(response.data)
					page = response.data.page
					project = response.data.project

				} else {
					page = this.pageProp
					project = this.projectProp
					// this is a domain so use SEO optimised product routes eg. example.com/product/product-name

				}


				console.log(page)
				const newSiteData = {
					title: project.title,
					description: "Updated website description",
				}
				this.project = project
				this.page = page
				this.GetPages()
				this.hostStore.setSiteData(newSiteData)
				await this.GetOwner(project.owner)
				this.html = page.html
				this.css = page.css
				this.js = page.js
				// Initialize empty objects for each category
				const categorizedData = this.categorizeLinks(project.baselinks)
				this.cssSrc = categorizedData.css
				this.js = categorizedData.js
				var h = this.processedHtml
				this.html = h
				this.loading = false
				if (this.page.metadata.length > 5) {
					this.addHead()
				}
				setTimeout(() => this.mutateCart(), 1000);
				setTimeout(() => this.mutateProductInfo(), 1);

			} catch (error) {
				console.error("Error:", error)
			}
		},
		addHead() {
			// Set the page title
			const pageTitle = JSON.parse(this.page.metadata).title

			// Create a new title element
			const newTitleElement = document.createElement("title");

			// Set the text content of the title element
			newTitleElement.textContent = pageTitle;

			// Append the new title element to the head of the document
			document.head.appendChild(newTitleElement);
			// Open Graph Meta Tags
			const ogMetaTags = [
				{ property: "og:url", content: this.project.domain },
				{ property: "og:type", content: "website" },
				{ property: "og:title", content: JSON.parse(this.page.metadata).title },
				{ property: "og:description", content: JSON.parse(this.page.metadata).description },
				{ property: "og:image", content: JSON.parse(this.page.metadata).image },
			];

			// Twitter Meta Tags
			const twitterMetaTags = [
				{ name: "twitter:card", content: "summary_large_image" },
				{ name: "twitter:title", content: JSON.parse(this.page.metadata).title },
				{ name: "twitter:description", content: JSON.parse(this.page.metadata).description },
				{ name: "twitter:image", content: JSON.parse(this.page.metadata).image },
			];

			// Add Open Graph Meta Tags to the head
			ogMetaTags.forEach(tag => {
				const newMetaTag = document.createElement("meta");
				newMetaTag.setAttribute("property", tag.property);
				newMetaTag.setAttribute("content", tag.content);
				document.head.appendChild(newMetaTag);
			});

			// Add Twitter Meta Tags to the head
			twitterMetaTags.forEach(tag => {
				const newMetaTag = document.createElement("meta");
				newMetaTag.setAttribute("name", tag.name);
				newMetaTag.setAttribute("content", tag.content);
				document.head.appendChild(newMetaTag);
			});
			document.dispatchEvent(new Event('render-event'));

		},
		setupScopedIframe() {
			const iframeDocument = this.$refs.scopedIframe.contentDocument
			if (iframeDocument) {
				const style = document.createElement("style")
				style.textContent = this.cssSrc
				iframeDocument.head.appendChild(style)
				const div = document.createElement("div")
				div.innerHTML = this.html
				iframeDocument.body.appendChild(div)
			}
		},


	},
}
</script>
<style :src="cssSrc"></style>
<style>
.w-webflow-badge {
	display: none !important;
}

.zookish-badge {
	position: fixed !important;
	display: inline-block !important;
	visibility: visible !important;
	z-index: 9999999999999999999999 !important;
	top: auto !important;
	right: 12px !important;
	bottom: 12px !important;
	left: auto !important;
	color: #aaadb0 !important;
	background-color: #fff !important;
	border-radius: 3px !important;
	padding: 6px 8px 6px 6px !important;
	font-size: 12px !important;
	opacity: 1 !important;
	line-height: 12px !important;
	text-decoration: none !important;
	transform: none !important;
	margin: 0 !important;
	width: auto !important;
	height: auto !important;
	overflow: visible !important;
	white-space: nowrap;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
	cursor: pointer;
}

.zookish-badge>img {
	display: inline-block !important;
	visibility: visible !important;
	opacity: 1 !important;
	vertical-align: middle !important;
}
</style>
