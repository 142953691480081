<template>
	<RouterView :key="$route.fullPath" />
	<div v-if="!closed">
		<message v-if="!isOnline || isSlowNetwork" @close="closed = true" />
	</div>
</template>
<script>
import message from './components/message.vue';
export default {
	components: {
		message
	},
	data() {
		return {
			isOnline: navigator.onLine,
			connectionType: null,
			isSlowNetwork: false,
			closed: false
		};
	},
	mounted() {
		document.documentElement.classList.add("dark")
		// Check initial online status
		this.checkOnlineStatus();

		// Add event listeners for online/offline events
		window.addEventListener('online', this.checkOnlineStatus);
		window.addEventListener('offline', this.checkOnlineStatus);

		// Check initial connection type
		this.checkConnectionType();
		this.checkNetworkSpeed();

		// Add event listener for changes in connection type
		window.addEventListener('change', this.checkConnectionType);
		window.addEventListener('change', this.checkNetworkSpeed);
	},
	beforeDestroy() {
		// Remove event listeners to prevent memory leaks
		window.removeEventListener('online', this.checkOnlineStatus);
		window.removeEventListener('offline', this.checkOnlineStatus);
		window.removeEventListener('change', this.checkConnectionType);
		window.removeEventListener('change', this.checkNetworkSpeed);
	},
	methods: {
		checkOnlineStatus() {
			this.isOnline = navigator.onLine;
		},
		checkConnectionType() {
			if (navigator.connection) {
				this.connectionType = navigator.connection.effectiveType;
			}
		},
		checkNetworkSpeed() {
			if (navigator.connection && navigator.connection.downlink) {
				// Assuming a downlink speed less than 1 Mbps is considered slow
				this.isSlowNetwork = navigator.connection.uplink < 3;
				console.log(navigator.connection.downlink)
			}
		},
	},
}
</script>
