// stores/HostStore.js
import { defineStore } from 'pinia';

export const useProjectStore = defineStore('project', {
  state: () => ({
    projects: [],
    project: Object,
    tags: [],
    pages: [],
    results: [],
    prompt: 'Hi there',
    shortText: 1,
    aimode: 0
  }),
  actions: {
    setProjects(response) {
      this.projects = response;
    },
    setPrompt(response) {
      this.prompt = response
    },
    setShortText(response) {
      this.shortText = response
    },
    setAIMODE(response) {
      this.aimode = response
    },
    setResponse(response) {
      this.project = response;
    },
    setTags(response) {
      this.tags = response;
    },
    setPages(response) {
      this.pages = response;
    },
    setResults(response) {
      this.results = response
    }
  },
});
