<template>
	<button
		type="button"
		class="relative group pl-0 p-2 ps-3 inline-flex items-center gap-x-2 text-sm font-mono rounded-lg"
	>
		<ul class="flex justify-center my-3 space-x-5">
			<li>
				<ShareNetwork
					network="facebook"
					:url="`https://zookish.com/invite/ref/${user.username}`"
					title="Discover Our Revolutionary AI Website Builder!"
					description="Unlock an Exclusive Offer: Join the Share Leaderboard! 🏆🌟 Create Your Dream Website Effortlessly - No Tech Skills Required! 💻🛠️

👍🎊 Lead & Earn: Press 'Share' 🔄 and let everyone know about our user-friendly AI website builder. Perfect for all, regardless of tech savvy! 🚀👩‍💼👨‍💼

💰💳 Win Big with Top 10 Rewards: Be a top sharer and enjoy Zookish for free! 🎁 Dive into easy, AI-powered website creation, designed for everyone. Skip the code, embrace simplicity! 🎨🌐🤖"
					quote="Building a website is now as easy as a few clicks, thanks to our advanced AI algorithms. - Zookish"
					hashtags="AIWebsiteBuilder, WebDesign, DigitalMarketing"
				>
					<img src="@/assets/facebook.svg" />
				</ShareNetwork>
			</li>
			<li>
				<ShareNetwork
					network="linkedin"
					:url="`https://zookish.com/invite/ref/${user.username}`"
					title="Discover Our Revolutionary AI Website Builder!"
					description="Unlock an Exclusive Offer: Join the Share Leaderboard! 🏆🌟 Create Your Dream Website Effortlessly - No Tech Skills Required! 💻🛠️

👍🎊 Lead & Earn: Press 'Share' 🔄 and let everyone know about our user-friendly AI website builder. Perfect for all, regardless of tech savvy! 🚀👩‍💼👨‍💼

💰💳 Win Big with Top 10 Rewards: Be a top sharer and enjoy Zookish for free! 🎁 Dive into easy, AI-powered website creation, designed for everyone. Skip the code, embrace simplicity! 🎨🌐🤖"
					quote="Building a website is now as easy as a few clicks, thanks to our advanced AI algorithms. - Zookish"
					hashtags="AIWebsiteBuilder, WebDesign, DigitalMarketing"
				>
					<img src="@/assets/linkedin.svg" />
				</ShareNetwork>
			</li>
			<li>
				<ShareNetwork
					network="twitter"
					:url="`https://zookish.com/invite/ref/${user.username}`"
					title="Discover Our Revolutionary AI Website Builder!"
					description="Unlock an Exclusive Offer: Join the Share Leaderboard! 🏆🌟 Create Your Dream Website Effortlessly - No Tech Skills Required! 💻🛠️

👍🎊 Lead & Earn: Press 'Share' 🔄 and let everyone know about our user-friendly AI website builder. Perfect for all, regardless of tech savvy! 🚀👩‍💼👨‍💼

💰💳 Win Big with Top 10 Rewards: Be a top sharer and enjoy Zookish for free! 🎁 Dive into easy, AI-powered website creation, designed for everyone. Skip the code, embrace simplicity! 🎨🌐🤖"
					quote="Building a website is now as easy as a few clicks, thanks to our advanced AI algorithms. - Zookish"
					hashtags="AIWebsiteBuilder, WebDesign, DigitalMarketing"
				>
					<img src="@/assets/twitterx.svg" />
				</ShareNetwork>
			</li>
			<li>
				<ShareNetwork
					network="whatsapp"
					:url="`https://zookish.com/invite/ref/${user.username}`"
					title="Discover Our Revolutionary AI Website Builder!"
					description="Unlock an Exclusive Offer: Join the Share Leaderboard! 🏆🌟 Create Your Dream Website Effortlessly - No Tech Skills Required! 💻🛠️

👍🎊 Lead & Earn: Press 'Share' 🔄 and let everyone know about our user-friendly AI website builder. Perfect for all, regardless of tech savvy! 🚀👩‍💼👨‍💼

💰💳 Win Big with Top 10 Rewards: Be a top sharer and enjoy Zookish for free! 🎁 Dive into easy, AI-powered website creation, designed for everyone. Skip the code, embrace simplicity! 🎨🌐🤖"
					quote="Building a website is now as easy as a few clicks, thanks to our advanced AI algorithms. - Zookish"
					hashtags="AIWebsiteBuilder, WebDesign, DigitalMarketing"
				>
					<img src="@/assets/whatsapp.svg" />
				</ShareNetwork>
			</li>
			<li>
				<ShareNetwork
					network="telegram"
					:url="`https://zookish.com/invite/ref/${user.username}`"
					title="Discover Our Revolutionary AI Website Builder!"
					description="Unlock an Exclusive Offer: Join the Share Leaderboard! 🏆🌟 Create Your Dream Website Effortlessly - No Tech Skills Required! 💻🛠️

👍🎊 Lead & Earn: Press 'Share' 🔄 and let everyone know about our user-friendly AI website builder. Perfect for all, regardless of tech savvy! 🚀👩‍💼👨‍💼

💰💳 Win Big with Top 10 Rewards: Be a top sharer and enjoy Zookish for free! 🎁 Dive into easy, AI-powered website creation, designed for everyone. Skip the code, embrace simplicity! 🎨🌐🤖"
					quote="Building a website is now as easy as a few clicks, thanks to our advanced AI algorithms. - Zookish"
					hashtags="AIWebsiteBuilder, WebDesign, DigitalMarketing"
				>
					<img src="@/assets/telegram.svg" />
				</ShareNetwork>
			</li>
			<li>
				<ShareNetwork
					network="reddit"
					:url="`https://zookish.com/invite/ref/${user.username}`"
					title="Discover Our Revolutionary AI Website Builder!"
					description="Unlock an Exclusive Offer: Join the Share Leaderboard! 🏆🌟 Create Your Dream Website Effortlessly - No Tech Skills Required! 💻🛠️

👍🎊 Lead & Earn: Press 'Share' 🔄 and let everyone know about our user-friendly AI website builder. Perfect for all, regardless of tech savvy! 🚀👩‍💼👨‍💼

💰💳 Win Big with Top 10 Rewards: Be a top sharer and enjoy Zookish for free! 🎁 Dive into easy, AI-powered website creation, designed for everyone. Skip the code, embrace simplicity! 🎨🌐🤖"
					quote="Building a website is now as easy as a few clicks, thanks to our advanced AI algorithms. - Zookish"
					hashtags="AIWebsiteBuilder, WebDesign, DigitalMarketing"
				>
					<img src="@/assets/reddit.svg" />
				</ShareNetwork>
			</li>
		</ul>
	</button>
</template>
<script>
	export default {
		methods: {},
		data() {
			return {}
		},
		computed: {
			user() {
				return JSON.parse(localStorage.getItem("user")).data
			},
		},
	}
</script>
