<template>
    <div class="flex lgqw8 f0dty l66z3 d1k81 tgfrq k3u76 dark:bg-neutral-800 dark:border-neutral-700">
        <!-- Header -->
        <div class="p-5 rbxtu jjege zvyci items-center gap-x-4">
            <div>
                <h2 class="inline-block kxhcs v7056 dark:text-neutral-200">
                    Referral Traffic
                </h2>
            </div>
            <!-- End Col -->

            <div class="flex prqc8 items-center gap-x-1">
                <!-- Download Dropdown -->
                <div class="hs-dropdown relative pqrvw [--placement:top-right] [--auto-close:inside]">
                    <button id="hs-pro-dbrrtchdd" type="button"
                        class="w-[34px] r2uwe pqrvw pdrgo items-center dqqs4 nq4w8 l66z3 yj6bp fyxhw zvbcs focus:outline-none focus:bg-gray-100 kko9e tk4f7 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                        <svg class="flex-shrink-0 sfo8l h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round">
                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                            <polyline points="7 10 12 15 17 10"></polyline>
                            <line x1="12" x2="12" y1="15" y2="3"></line>
                        </svg>
                    </button>

                    <!-- Download Dropdown -->
                    <div class="hs-dropdown-menu muqsh idxal transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                        aria-labelledby="hs-pro-dbrrtchdd">
                        <div class="p-1">
                            <div class="py-2 px-3">
                                <span class="block kxhcs v7056 dark:text-neutral-200">
                                    Download Report
                                </span>
                                <span class="block rvi38 fyxhw dark:text-neutral-500">
                                    Select Options
                                </span>
                            </div>

                            <div class="m-2 lz37y d1k81 dark:border-neutral-700"></div>

                            <div class="py-1 px-2">
                                <div class="flex items-center em545 k3u76 dpnc4 dark:bg-neutral-800">
                                    <label for="hs-pro-dbrrtchddts1"
                                        class="relative zdxcz vrf2y w-full cursor-pointer zpy54 dtjcu v7056 nq4w8 dark:text-neutral-200">
                                        <svg class="relative wlsmd steqe uiqm5 ja4wp wlxy3 sfo8l h-4" width="32" height="32"
                                            viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M20.0324 1.91994H9.45071C9.09999 1.91994 8.76367 2.05926 8.51567 2.30725C8.26767 2.55523 8.12839 2.89158 8.12839 3.24228V8.86395L20.0324 15.8079L25.9844 18.3197L31.9364 15.8079V8.86395L20.0324 1.91994Z"
                                                fill="#21A366"></path>
                                            <path d="M8.12839 8.86395H20.0324V15.8079H8.12839V8.86395Z" fill="#107C41">
                                            </path>
                                            <path
                                                d="M30.614 1.91994H20.0324V8.86395H31.9364V3.24228C31.9364 2.89158 31.7971 2.55523 31.5491 2.30725C31.3011 2.05926 30.9647 1.91994 30.614 1.91994Z"
                                                fill="#33C481"></path>
                                            <path
                                                d="M20.0324 15.8079H8.12839V28.3736C8.12839 28.7243 8.26767 29.0607 8.51567 29.3087C8.76367 29.5567 9.09999 29.6959 9.45071 29.6959H30.6141C30.9647 29.6959 31.3011 29.5567 31.549 29.3087C31.797 29.0607 31.9364 28.7243 31.9364 28.3736V22.7519L20.0324 15.8079Z"
                                                fill="#185C37"></path>
                                            <path d="M20.0324 15.8079H31.9364V22.7519H20.0324V15.8079Z" fill="#107C41">
                                            </path>
                                            <path opacity="0.1"
                                                d="M16.7261 6.87994H8.12839V25.7279H16.7261C17.0764 25.7269 17.4121 25.5872 17.6599 25.3395C17.9077 25.0917 18.0473 24.756 18.0484 24.4056V8.20226C18.0473 7.8519 17.9077 7.51616 17.6599 7.2684C17.4121 7.02064 17.0764 6.88099 16.7261 6.87994Z"
                                                class="fill-black dark:fill-neutral-200" fill="currentColor"></path>
                                            <path opacity="0.2"
                                                d="M15.7341 7.87194H8.12839V26.7199H15.7341C16.0844 26.7189 16.4201 26.5792 16.6679 26.3315C16.9157 26.0837 17.0553 25.748 17.0564 25.3976V9.19426C17.0553 8.84386 16.9157 8.50818 16.6679 8.26042C16.4201 8.01266 16.0844 7.87299 15.7341 7.87194Z"
                                                class="fill-black dark:fill-neutral-200" fill="currentColor"></path>
                                            <path opacity="0.2"
                                                d="M15.7341 7.87194H8.12839V24.7359H15.7341C16.0844 24.7349 16.4201 24.5952 16.6679 24.3475C16.9157 24.0997 17.0553 23.764 17.0564 23.4136V9.19426C17.0553 8.84386 16.9157 8.50818 16.6679 8.26042C16.4201 8.01266 16.0844 7.87299 15.7341 7.87194Z"
                                                class="fill-black dark:fill-neutral-200" fill="currentColor"></path>
                                            <path opacity="0.2"
                                                d="M14.7421 7.87194H8.12839V24.7359H14.7421C15.0924 24.7349 15.4281 24.5952 15.6759 24.3475C15.9237 24.0997 16.0633 23.764 16.0644 23.4136V9.19426C16.0633 8.84386 15.9237 8.50818 15.6759 8.26042C15.4281 8.01266 15.0924 7.87299 14.7421 7.87194Z"
                                                class="fill-black dark:fill-neutral-200" fill="currentColor"></path>
                                            <path
                                                d="M1.51472 7.87194H14.7421C15.0927 7.87194 15.4291 8.01122 15.6771 8.25922C15.925 8.50722 16.0644 8.84354 16.0644 9.19426V22.4216C16.0644 22.7723 15.925 23.1087 15.6771 23.3567C15.4291 23.6047 15.0927 23.7439 14.7421 23.7439H1.51472C1.16402 23.7439 0.827672 23.6047 0.579686 23.3567C0.3317 23.1087 0.192383 22.7723 0.192383 22.4216V9.19426C0.192383 8.84354 0.3317 8.50722 0.579686 8.25922C0.827672 8.01122 1.16402 7.87194 1.51472 7.87194Z"
                                                fill="#107C41"></path>
                                            <path
                                                d="M3.69711 20.7679L6.90722 15.794L3.96694 10.8479H6.33286L7.93791 14.0095C8.08536 14.3091 8.18688 14.5326 8.24248 14.68H8.26328C8.36912 14.4407 8.47984 14.2079 8.5956 13.9817L10.3108 10.8479H12.4822L9.46656 15.7663L12.5586 20.7679H10.2473L8.3932 17.2959C8.30592 17.148 8.23184 16.9927 8.172 16.8317H8.14424C8.09016 16.9891 8.01824 17.1399 7.92998 17.2811L6.02236 20.7679H3.69711Z"
                                                fill="white"></path>
                                        </svg>
                                        <span class="relative wlsmd align-middle">
                                            Excel
                                        </span>
                                        <input type="radio" name="hs-pro-dbrrtchddts"
                                            class="shrink-0 i8ytw f6gtg jalbw erlgg tj0d4 cursor-pointer kw6wr s920d u1sov checked:bg-none checked:before:bg-white checked:before:shadow-sm focus:ring-0 focus:before:opacity-80 dark:checked:before:bg-neutral-900 dark:focus:before:bg-neutral-950"
                                            id="hs-pro-dbrrtchddts1" checked="">
                                    </label>

                                    <label for="hs-pro-dbrrtchddts2"
                                        class="relative zdxcz vrf2y w-full cursor-pointer zpy54 dtjcu v7056 nq4w8 dark:text-neutral-200">
                                        <svg class="relative wlsmd steqe uiqm5 ja4wp wlxy3 sfo8l h-4" width="32" height="32"
                                            viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M30.6141 1.91994H9.45071C9.09999 1.91994 8.76367 2.05926 8.51567 2.30725C8.26767 2.55523 8.12839 2.89158 8.12839 3.24228V8.86395L20.0324 12.3359L31.9364 8.86395V3.24228C31.9364 2.89158 31.797 2.55523 31.549 2.30725C31.3011 2.05926 30.9647 1.91994 30.6141 1.91994Z"
                                                fill="#41A5EE"></path>
                                            <path
                                                d="M31.9364 8.86395H8.12839V15.8079L20.0324 19.2799L31.9364 15.8079V8.86395Z"
                                                fill="#2B7CD3"></path>
                                            <path
                                                d="M31.9364 15.8079H8.12839V22.7519L20.0324 26.2239L31.9364 22.7519V15.8079Z"
                                                fill="#185ABD"></path>
                                            <path
                                                d="M31.9364 22.752H8.12839V28.3736C8.12839 28.7244 8.26767 29.0607 8.51567 29.3087C8.76367 29.5567 9.09999 29.696 9.45071 29.696H30.6141C30.9647 29.696 31.3011 29.5567 31.549 29.3087C31.797 29.0607 31.9364 28.7244 31.9364 28.3736V22.752Z"
                                                fill="#103F91"></path>
                                            <path opacity="0.1"
                                                d="M16.7261 6.87994H8.12839V25.7279H16.7261C17.0764 25.7269 17.4121 25.5872 17.6599 25.3395C17.9077 25.0917 18.0473 24.756 18.0484 24.4056V8.20226C18.0473 7.8519 17.9077 7.51616 17.6599 7.2684C17.4121 7.02064 17.0764 6.88099 16.7261 6.87994Z"
                                                class="fill-black dark:fill-neutral-200" fill="currentColor"></path>
                                            <path opacity="0.2"
                                                d="M15.7341 7.87194H8.12839V26.7199H15.7341C16.0844 26.7189 16.4201 26.5792 16.6679 26.3315C16.9157 26.0837 17.0553 25.748 17.0564 25.3976V9.19426C17.0553 8.84386 16.9157 8.50818 16.6679 8.26042C16.4201 8.01266 16.0844 7.87299 15.7341 7.87194Z"
                                                class="fill-black dark:fill-neutral-200" fill="currentColor"></path>
                                            <path opacity="0.2"
                                                d="M15.7341 7.87194H8.12839V24.7359H15.7341C16.0844 24.7349 16.4201 24.5952 16.6679 24.3475C16.9157 24.0997 17.0553 23.764 17.0564 23.4136V9.19426C17.0553 8.84386 16.9157 8.50818 16.6679 8.26042C16.4201 8.01266 16.0844 7.87299 15.7341 7.87194Z"
                                                class="fill-black dark:fill-neutral-200" fill="currentColor"></path>
                                            <path opacity="0.2"
                                                d="M14.7421 7.87194H8.12839V24.7359H14.7421C15.0924 24.7349 15.4281 24.5952 15.6759 24.3475C15.9237 24.0997 16.0633 23.764 16.0644 23.4136V9.19426C16.0633 8.84386 15.9237 8.50818 15.6759 8.26042C15.4281 8.01266 15.0924 7.87299 14.7421 7.87194Z"
                                                class="fill-black dark:fill-neutral-200" fill="currentColor"></path>
                                            <path
                                                d="M1.51472 7.87194H14.7421C15.0927 7.87194 15.4291 8.01122 15.6771 8.25922C15.925 8.50722 16.0644 8.84354 16.0644 9.19426V22.4216C16.0644 22.7723 15.925 23.1087 15.6771 23.3567C15.4291 23.6047 15.0927 23.7439 14.7421 23.7439H1.51472C1.16401 23.7439 0.827669 23.6047 0.579687 23.3567C0.3317 23.1087 0.192383 22.7723 0.192383 22.4216V9.19426C0.192383 8.84354 0.3317 8.50722 0.579687 8.25922C0.827669 8.01122 1.16401 7.87194 1.51472 7.87194Z"
                                                fill="#185ABD"></path>
                                            <path
                                                d="M12.0468 20.7679H10.2612L8.17801 13.9231L5.99558 20.7679H4.20998L2.22598 10.8479H4.01158L5.40038 17.7919L7.48358 11.0463H8.97161L10.9556 17.7919L12.3444 10.8479H14.0308L12.0468 20.7679Z"
                                                fill="white"></path>
                                        </svg>
                                        <span class="relative wlsmd align-middle">
                                            Word
                                        </span>
                                        <input type="radio" name="hs-pro-dbrrtchddts"
                                            class="shrink-0 i8ytw f6gtg jalbw erlgg tj0d4 cursor-pointer kw6wr s920d u1sov checked:bg-none checked:before:bg-white checked:before:shadow-sm focus:ring-0 focus:before:opacity-80 dark:checked:before:bg-neutral-900 dark:focus:before:bg-neutral-950"
                                            id="hs-pro-dbrrtchddts2">
                                    </label>
                                </div>
                            </div>

                            <div class="m-2 lz37y d1k81 dark:border-neutral-700"></div>

                            <div
                                class="flex azaj8 items-center xxfsd vrf2y cursor-pointer nq4w8 zvbcs dark:hover:bg-neutral-800 dark:focus:bg-neutral-700">
                                <label for="hs-pro-dbrrtchdds1"
                                    class="flex yssyn items-center ukt6v cursor-pointer dtjcu v7056 dark:text-neutral-300">
                                    <svg class="flex-shrink-0 sfo8l h-4" xmlns="http://www.w3.org/2000/svg" width="24"
                                        height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z"></path>
                                        <path d="m15 5 4 4"></path>
                                    </svg>
                                    Section name
                                </label>
                                <input type="checkbox"
                                    class="shrink-0 xtsb0 f5cx3 fw1pc sg5ei yiq1v focus:ring-blue-500 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                    id="hs-pro-dbrrtchdds1" checked="">
                            </div>

                            <div
                                class="flex azaj8 items-center xxfsd vrf2y cursor-pointer nq4w8 zvbcs dark:hover:bg-neutral-800 dark:focus:bg-neutral-700">
                                <label for="hs-pro-dbrrtchdds2"
                                    class="flex yssyn items-center ukt6v cursor-pointer dtjcu v7056 dark:text-neutral-300">
                                    <svg class="flex-shrink-0 sfo8l h-4" xmlns="http://www.w3.org/2000/svg" width="24"
                                        height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path d="M16 22h2a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v3"></path>
                                        <polyline points="14 2 14 8 20 8"></polyline>
                                        <path d="M4.04 11.71a5.84 5.84 0 1 0 8.2 8.29"></path>
                                        <path d="M13.83 16A5.83 5.83 0 0 0 8 10.17V16h5.83Z"></path>
                                    </svg>
                                    Comparison stats
                                </label>
                                <input type="checkbox"
                                    class="shrink-0 xtsb0 f5cx3 fw1pc sg5ei yiq1v focus:ring-blue-500 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                    id="hs-pro-dbrrtchdds2" checked="">
                            </div>

                            <div
                                class="flex azaj8 items-center xxfsd vrf2y cursor-pointer nq4w8 zvbcs dark:hover:bg-neutral-800 dark:focus:bg-neutral-700">
                                <label for="hs-pro-dbrrtchdds3"
                                    class="flex yssyn items-center ukt6v cursor-pointer dtjcu v7056 dark:text-neutral-300">
                                    <svg class="flex-shrink-0 sfo8l h-4" xmlns="http://www.w3.org/2000/svg" width="24"
                                        height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <circle cx="12" cy="12" r="10"></circle>
                                    </svg>
                                    Legend indicator
                                </label>
                                <input type="checkbox"
                                    class="shrink-0 xtsb0 f5cx3 fw1pc sg5ei yiq1v focus:ring-blue-500 kko9e tk4f7 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                    id="hs-pro-dbrrtchdds3" checked="">
                            </div>

                            <div class="my-1 lz37y d1k81 dark:border-neutral-700"></div>

                            <button type="button"
                                class="w-full xxfsd vrf2y pqrvw pdrgo items-center dqqs4 dtjcu kxhcs nq4w8 l66z3 yj6bp wpeco dafkk nkmbv kko9e tk4f7 focus:outline-none focus:ring-2 focus:ring-blue-500">
                                Download
                            </button>
                        </div>
                    </div>
                    <!-- End Download Dropdown -->
                </div>
                <!-- End Download Dropdown -->

                <!-- More Dropdown -->
                <div class="hs-dropdown relative pqrvw [--placement:top-right]">
                    <button id="hs-pro-dbrrtchmd" type="button"
                        class="w-[34px] r2uwe pqrvw pdrgo items-center dqqs4 nq4w8 l66z3 yj6bp fyxhw zvbcs focus:outline-none focus:bg-gray-100 kko9e tk4f7 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                        <svg class="flex-shrink-0 sfo8l h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round">
                            <circle cx="12" cy="12" r="1"></circle>
                            <circle cx="12" cy="5" r="1"></circle>
                            <circle cx="12" cy="19" r="1"></circle>
                        </svg>
                    </button>

                    <!-- Help Dropdown -->
                    <div class="hs-dropdown-menu muqsh sk3zy transition-[opacity,margin] duration opacity-0 hidden wlsmd f0dty k3u76 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
                        aria-labelledby="hs-pro-dbrrtchmd">
                        <div class="p-1">
                            <button type="button"
                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 v7056 zvbcs kko9e focus:outline-none focus:bg-gray-100 tk4f7 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                                <svg class="flex-shrink-0 xtsb0 h-3.5" xmlns="http://www.w3.org/2000/svg" width="24"
                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <circle cx="18" cy="5" r="3"></circle>
                                    <circle cx="6" cy="12" r="3"></circle>
                                    <circle cx="18" cy="19" r="3"></circle>
                                    <line x1="8.59" x2="15.42" y1="13.51" y2="17.49"></line>
                                    <line x1="15.41" x2="8.59" y1="6.51" y2="10.49"></line>
                                </svg>
                                Share reports
                            </button>
                            <button type="button"
                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 v7056 zvbcs kko9e focus:outline-none focus:bg-gray-100 tk4f7 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                                <svg class="flex-shrink-0 xtsb0 h-3.5" xmlns="http://www.w3.org/2000/svg" width="24"
                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M8 3H5a2 2 0 0 0-2 2v3"></path>
                                    <path d="M21 8V5a2 2 0 0 0-2-2h-3"></path>
                                    <path d="M3 16v3a2 2 0 0 0 2 2h3"></path>
                                    <path d="M16 21h3a2 2 0 0 0 2-2v-3"></path>
                                </svg>
                                View in fullscreen
                            </button>
                            <button type="button"
                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 v7056 zvbcs kko9e focus:outline-none focus:bg-gray-100 tk4f7 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                                <svg class="flex-shrink-0 xtsb0 h-3.5" xmlns="http://www.w3.org/2000/svg" width="24"
                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M3 3h6l6 18h6"></path>
                                    <path d="M14 3h7"></path>
                                </svg>
                                Connect other apps
                            </button>

                            <div class="my-1 lz37y d1k81 dark:border-neutral-700"></div>

                            <button type="button"
                                class="w-full flex items-center ukt6v zdxcz grnyh nq4w8 ldcb4 v7056 zvbcs kko9e focus:outline-none focus:bg-gray-100 tk4f7 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                                <svg class="flex-shrink-0 xtsb0 h-3.5" xmlns="http://www.w3.org/2000/svg" width="24"
                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                                    <line x1="9" x2="15" y1="10" y2="10"></line>
                                    <line x1="12" x2="12" y1="7" y2="13"></line>
                                </svg>
                                Submit Feedback
                            </button>
                        </div>
                    </div>
                    <!-- End Help Dropdown -->
                </div>
                <!-- End More Dropdown -->
            </div>
            <!-- End Col -->
        </div>
        <!-- End Header -->

        <!-- Body -->
        <div class="h-full pqx67 pt-0">
            <div class="h-full flex lgqw8 azaj8 space-y-4">
                <div class="space-y-4">
                    <div class="flex e56at w-full vzn8i iwtls overflow-hidden">
                        <div class="flex lgqw8 pdrgo bx801 u67tl rvi38 dafkk zpy54 whitespace-nowrap" style="width: 42%"
                            role="progressbar" aria-valuenow="42" aria-valuemin="0" aria-valuemax="100"></div>
                        <div class="flex lgqw8 pdrgo bx801 qvuq5 rvi38 dafkk zpy54 whitespace-nowrap" style="width: 27%"
                            role="progressbar" aria-valuenow="27" aria-valuemin="0" aria-valuemax="100"></div>
                        <div class="flex lgqw8 pdrgo bx801 clmtm rvi38 dafkk zpy54 whitespace-nowrap" style="width: 16%"
                            role="progressbar" aria-valuenow="16" aria-valuemin="0" aria-valuemax="100"></div>
                        <div class="flex lgqw8 pdrgo bx801 mubyn rvi38 dafkk zpy54 whitespace-nowrap" style="width: 6%"
                            role="progressbar" aria-valuenow="6" aria-valuemin="0" aria-valuemax="100"></div>
                        <div class="flex lgqw8 pdrgo bx801 hr03e rvi38 dafkk zpy54 mfkro dark:bg-neutral-500"
                            style="width: 9%" role="progressbar" aria-valuenow="9" aria-valuemin="0" aria-valuemax="100">
                        </div>
                    </div>

                    <!-- List Group -->
                    <ul>
                        <!-- List Item -->
                        <li class="py-2 jjege zvyci azaj8 items-center gap-x-4">
                            <div class="flex items-center">
                                <span class="flex-shrink-0 w1ile vzn8i uiqm5 wpeco ifon2 me-2.5"></span>
                                <span class="text-sm v7056 dark:text-neutral-200">
                                    github.com
                                </span>
                            </div>
                            <div class="text-end">
                                <span class="text-sm fyxhw dark:text-neutral-500">
                                    164k
                                </span>
                            </div>
                        </li>
                        <!-- End List Item -->

                        <!-- List Item -->
                    <li class="py-2 jjege zvyci azaj8 items-center gap-x-4">
                        <div class="flex items-center">
                            <span class="flex-shrink-0 w1ile vzn8i uiqm5 qvuq5 ifon2 me-2.5"></span>
                            <span class="text-sm v7056 dark:text-neutral-200">
                                accounts.google.com
                            </span>
                        </div>
                        <div class="text-end">
                            <span class="text-sm fyxhw dark:text-neutral-500">
                                49k
                            </span>
                        </div>
                    </li>
                    <!-- End List Item -->

                    <!-- List Item -->
                    <li class="py-2 jjege zvyci azaj8 items-center gap-x-4">
                        <div class="flex items-center">
                            <span class="flex-shrink-0 w1ile vzn8i uiqm5 clmtm ifon2 me-2.5"></span>
                            <span class="text-sm v7056 dark:text-neutral-200">
                                themes.getbootstrap.com
                            </span>
                        </div>
                        <div class="text-end">
                            <span class="text-sm fyxhw dark:text-neutral-500">
                                26k
                            </span>
                        </div>
                    </li>
                    <!-- End List Item -->

                    <!-- List Item -->
                    <li class="py-2 jjege zvyci azaj8 items-center gap-x-4">
                        <div class="flex items-center">
                            <span class="flex-shrink-0 w1ile vzn8i uiqm5 mubyn ifon2 me-2.5"></span>
                            <span class="text-sm v7056 dark:text-neutral-200">
                                htmlstream.com
                            </span>
                        </div>
                        <div class="text-end">
                            <span class="text-sm fyxhw dark:text-neutral-500">
                                8k
                            </span>
                        </div>
                    </li>
                    <!-- End List Item -->

                    <!-- List Item -->
                    <li class="py-2 jjege zvyci azaj8 items-center gap-x-4">
                        <div class="flex items-center">
                            <span class="flex-shrink-0 w1ile vzn8i uiqm5 hr03e ifon2 nhufc dark:bg-neutral-500"></span>
                            <span class="text-sm v7056 dark:text-neutral-200">
                                Others
                            </span>
                        </div>
                        <div class="text-end">
                            <span class="text-sm fyxhw dark:text-neutral-500">
                                12k
                            </span>
                        </div>
                    </li>
                    <!-- End List Item -->
                </ul>
                <!-- End List Group -->
            </div>

            <!-- Avatar Group -->
            <div class="flex items-center -space-x-2">
                <div class="hs-tooltip hover:z-10">
                    <img class="flex-shrink-0 cslsl uunzg pn3s9 phz2n u40k2 jm60s iwtls dark:border-neutral-700"
                        src="https://images.unsplash.com/photo-1659482633369-9fe69af50bfb?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=facearea&amp;facepad=2.5&amp;w=320&amp;h=320&amp;q=80"
                        alt="Image Description">
                    <span
                        class="hs-tooltip-content tnij2 hnhol opacity-0 l46pc hidden imt5j wlsmd npcr1 grnyh bg-gray-900 rvi38 eass7 dafkk nq4w8 tgfrq dark:bg-neutral-700"
                        role="tooltip" data-popper-placement="top"
                        style="position: fixed; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(312.5px, -148px, 0px);">
                        James Collins
                    </span>
                </div>
                <div class="hs-tooltip hover:z-10">
                    <span
                        class="flex wlxy3 pdrgo items-center cslsl uunzg pn3s9 phz2n f0dty l66z3 d1k81 trxwx rvi38 zbvlp eass7 ouifk iwtls dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300">
                        E
                    </span>
                    <span
                        class="hs-tooltip-content tnij2 hnhol opacity-0 l46pc hidden imt5j wlsmd npcr1 grnyh bg-gray-900 rvi38 eass7 dafkk nq4w8 tgfrq dark:bg-neutral-700"
                        role="tooltip" data-popper-placement="top"
                        style="position: fixed; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(332.5px, -148px, 0px);">
                        Ella Lauda
                    </span>
                </div>
                <div class="hs-tooltip hover:z-10">
                    <img class="flex-shrink-0 cslsl uunzg pn3s9 phz2n u40k2 jm60s iwtls dark:border-neutral-700"
                        src="https://images.unsplash.com/photo-1601935111741-ae98b2b230b0?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=facearea&amp;facepad=2.5&amp;w=320&amp;h=320&amp;q=80"
                        alt="Image Description">
                    <span
                        class="hs-tooltip-content tnij2 hnhol opacity-0 l46pc hidden imt5j wlsmd npcr1 grnyh bg-gray-900 rvi38 eass7 dafkk nq4w8 tgfrq dark:bg-neutral-700"
                        role="tooltip" data-popper-placement="top"
                        style="position: fixed; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(352.5px, -148px, 0px);">
                        Costa Quinn
                    </span>
                </div>
                <div class="hs-tooltip hover:z-10">
                    <img class="flex-shrink-0 cslsl uunzg pn3s9 phz2n u40k2 jm60s iwtls dark:border-neutral-700"
                        src="https://images.unsplash.com/photo-1541101767792-f9b2b1c4f127?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=facearea&amp;facepad=2.5&amp;w=320&amp;h=320&amp;q=80"
                        alt="Image Description">
                    <span
                        class="hs-tooltip-content tnij2 hnhol opacity-0 l46pc hidden imt5j wlsmd npcr1 grnyh bg-gray-900 rvi38 eass7 dafkk nq4w8 tgfrq dark:bg-neutral-700"
                        role="tooltip" data-popper-placement="top"
                        style="position: fixed; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(372.5px, -148px, 0px);">
                        Amanda Harvey
                    </span>
                </div>
                <div class="hs-tooltip hover:z-10">
                    <img class="flex-shrink-0 cslsl uunzg pn3s9 phz2n u40k2 jm60s iwtls dark:border-neutral-700"
                        src="https://images.unsplash.com/photo-1568048689711-5e0325cea8c0?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=facearea&amp;facepad=2.5&amp;w=320&amp;h=320&amp;q=80"
                        alt="Image Description">
                    <span
                        class="hs-tooltip-content tnij2 hnhol opacity-0 l46pc hidden imt5j wlsmd npcr1 grnyh bg-gray-900 rvi38 eass7 dafkk nq4w8 tgfrq dark:bg-neutral-700"
                        role="tooltip" data-popper-placement="top"
                        style="position: fixed; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(392.5px, -148px, 0px);">
                        Alisa Grasso
                    </span>
                </div>
                <div class="hs-tooltip hover:z-10">
                    <span
                        class="flex wlxy3 pdrgo items-center cslsl uunzg pn3s9 phz2n f0dty l66z3 d1k81 trxwx rvi38 zbvlp eass7 ouifk iwtls dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300">
                        O
                    </span>
                    <span
                        class="hs-tooltip-content tnij2 hnhol opacity-0 l46pc hidden imt5j wlsmd npcr1 grnyh bg-gray-900 rvi38 eass7 dafkk nq4w8 tgfrq dark:bg-neutral-700"
                        role="tooltip" data-popper-placement="top"
                        style="position: fixed; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(412.5px, -148px, 0px);">
                        Ols Schols
                    </span>
                </div>
                <div>
                    <span class="ms-4 ma8nl dtjcu fyxhw dark:text-neutral-500">
                        215k more
                    </span>
                </div>
            </div>
            <!-- End Avatar Group -->
        </div>
    </div>
    <!-- End Body -->

    <!-- Footer -->
    <div class="text-center lz37y d1k81 dark:border-neutral-700">
        <a class="p-3 flex pdrgo items-center dqqs4 dtjcu m7g7n eass7 a9vfa qhplw focus:outline-none focus:decoration-2 focus:underline focus:text-blue-700 dark:text-blue-500 dark:hover:text-blue-600 dark:focus:text-blue-600"
            href="#">
            View full reports
        </a>
    </div>
    <!-- End Footer -->
</div></template>