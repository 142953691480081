<template>
	<div id="hs-danger-alert" class="hidden w-full h-full fixed top-1/4 start-0 z-[60] overflow-x-hidden overflow-y-auto">
		<div
			class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all md:max-w-2xl md:w-full m-3 md:mx-auto">
			<div
				class="relative flex flex-col bg-white border shadow-sm rounded-xl overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">
				<div class="absolute top-2 end-2">
					<button type="button"
						class="flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-lg border border-transparent text-neutral-800 hover:bg-neutral-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:border-transparent dark:hover:bg-neutral-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
						data-hs-overlay="#hs-danger-alert">
						<span class="sr-only">Close</span>
						<svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
							viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
							stroke-linejoin="round">
							<path d="M18 6 6 18" />
							<path d="m6 6 12 12" />
						</svg>
					</button>
				</div>

				<div class="p-4 sm:p-10 overflow-y-auto">
					<div class="flex gap-x-4 md:gap-x-7">
						<!-- Icon -->
						<span
							class="flex-shrink-0 inline-flex justify-center items-center w-[46px] h-[46px] sm:w-[62px] sm:h-[62px] rounded-full border-4 border-red-50 bg-red-100 text-red-500 dark:bg-red-700 dark:border-red-600 dark:text-red-100">
							<svg class="flex-shrink-0 w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
								fill="currentColor" viewBox="0 0 16 16">
								<path
									d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
							</svg>
						</span>
						<!-- End Icon -->

						<div class="grow">
							<h3 class="mb-2 text-xl font-bold text-neutral-800 dark:text-neutral-200">
								Are you sure want to delete this?
							</h3>
							<p class="text-neutral-500 text-sm">
								Are you sure you want to delete this item? Please be aware that
								this action is irreversible and will permanently remove all
								related data from our system. This includes any associated
								files, settings, and configurations. Once deleted, this
								information cannot be recovered.
							</p>
						</div>
					</div>
				</div>

				<div
					class="flex justify-end items-center gap-x-2 py-3 px-4 bg-neutral-50 border-t dark:bg-neutral-800 dark:border-neutral-700">
					<button type="button"
						class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-neutral-200 bg-white text-neutral-800 shadow-sm hover:bg-neutral-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
						data-hs-overlay="#hs-danger-alert">
						Cancel
					</button>
					<button data-hs-overlay="#hs-danger-alert"
						class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-500 text-white hover:bg-red-600 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
						@click="$emit('deleteConfirmed')">
						Delete
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	emits: ['deleteConfirmed']
}
</script>
<style>
.hs-overlay-backdrop {
	background-color: #171717a3 !important;
}
</style>
