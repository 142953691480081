<template>
	<div class="min-h-screen flex items-center justify-center">
		<!-- Grid -->
		<div class="grid md:items-center">
			<div class="mx-auto justify-center">
				<div
					class="w-full max-w-sm p-4 bg-white border border-neutral-200 rounded-lg shadow sm:p-6 dark:bg-neutral-800 dark:border-neutral-700">

					<h5 class="mb-3 text-base font-semibold text-neutral-900 md:text-xl dark:text-white">

						How to Point a Subdomain to dns.zookish.com
					</h5>
					<p class="text-sm font-normal text-neutral-500 dark:text-neutral-400">
						Simple CNAME Record Update Instructions
					</p>
					<div style="position: relative; padding-bottom: 62.5%; height: 0;"><iframe
							src="https://www.loom.com/embed/022fe2b5c5d94ab7b6e9ba7dbcc64e43?sid=ec827172-6e2b-4322-a486-5520b034c160"
							frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen
							style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
					<div class="grid justify-center gap-4 gap-y-2 text-sm grid-cols-1 ">
						<div class="md:col-span-2 dark:text-neutral-400">
							<label for="title">Type</label>
							<div class="flex">
								<input type="text" name="cname" id="cname"
									class="h-10 border-dashed border-black border-2 dark:border-white rounded-r-sm px-4 w-full bg-neutral-50 dark:text-white dark:bg-neutral-800"
									value="cname" readonly />
							</div>
						</div>
						<div class="md:col-span-2 dark:text-neutral-400">
							<label for="title">Host</label>
							<div class="flex">
								<span
									class="inline-flex items-center h-10 px-4 text-sm text-neutral-900 bg-neutral-200 border border-r-0 border-neutral-300 rounded-l-md dark:bg-neutral-600 dark:text-neutral-400 dark:border-neutral-600">
									<vue-feather type="edit" class="w-4 h-4 text-neutral-500 dark:text-neutral-400" />
								</span>
								<input type="text" name="yourdomain.com" v-model="domain" id="yourdomain.com"
									class="h-10 border-dashed border-black border-2 dark:border-white rounded-r-sm px-4 w-full bg-neutral-50 dark:text-white dark:bg-neutral-800" />
							</div>

							<span class="font-bold text-white">Insert your domain here</span>
						</div>
						<div class="md:col-span-2 dark:text-neutral-400">
							<label for="title">Value | Target</label>
							<a @click="copy" class="flex">
								<span
									class="inline-flex items-center h-10 px-4 text-sm text-neutral-900 bg-neutral-200 border border-r-0 border-neutral-300 rounded-l-md dark:bg-neutral-600 dark:text-neutral-400 dark:border-neutral-600">
									<vue-feather type="copy" class="w-4 h-4 text-neutral-500 dark:text-neutral-400" />
								</span>
								<input data-tooltip-target="tooltip-default" type="text" name="dns.zookish.com" id="dns"
									class="h-10 border-dashed border-black border-2 dark:border-white rounded-r-sm px-4 w-full bg-neutral-50 dark:text-white dark:bg-neutral-800"
									value="dns.zookish.com" readonly />
							</a>
							<div id="tooltip-default" role="tooltip"
								class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
								Copy
								<div class="tooltip-arrow" data-popper-arrow></div>
							</div>
							<input type="hidden" id="hs-clipboard-tooltip" value="npm install preline">

							<label for="title" class="font-bold">{{
								copied ? "Copied!" : "Click to copy"
							}}</label>
						</div>
						<p class="text-green-700"></p>
					</div>
					<error v-if="hasError" :message="message" />
					<button v-if="!added" @click="addCustomHostname" type="button"
						class="text-white bg-blue-500 hover:bg-blue-600 w-full focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">
						I have added these records to my domain registrar
					</button>
					<div v-else>
						<div class="w-full">
							<div class="h-1.5 w-full bg-indigo-100 dark:bg-neutral-100 overflow-hidden">
								<div class="progress w-full h-full bg-indigo-500 dark:bg-neutral-500 left-right"></div>
							</div>
						</div>
					</div>
					<p class="text-sm dark:text-white text-center">
						If you need help email support@zookish.com
					</p>
					<div>
						<a href="#"
							class="inline-flex items-center text-xs font-normal text-neutral-500 hover:underline dark:text-neutral-400">
							<svg class="w-3 h-3 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
								viewBox="0 0 20 20">
								<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
									d="M7.529 7.988a2.502 2.502 0 0 1 5 .191A2.441 2.441 0 0 1 10 10.582V12m-.01 3.008H10M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
							</svg>
							Learn more about how to get a domain?</a>
					</div>
				</div>
			</div>
		</div>
		<!-- End Grid -->
	</div>
</template>
<script>
import api from "@/services/api"
import { useHostStore } from "@/stores/HostStore"
import { useProjectStore } from "@/stores/ProjectStore"
export default {
	data: () => {
		return {
			hasError: false,
			message: String,
			added: false,
			result: null,
			hostStore: useHostStore(),
			copied: false,
			domain: "yourdomain.com",
			projectStore: useProjectStore(),
			instructions: [
				{
					title: "1. Log into Your Domain Registrar Account",
					body: "",
				},
				{
					title: "2. Access Domain Management",
					body:
						" Locate the option for managing your domains. It might be called Domain Management, DNS Settings, or something similar.",
				},
				{
					title: "3. Select the Domain",
					body:
						"If you have multiple domains, select the one you want to add a CNAME record to.",
				},
				{
					title: "4. Find DNS Settings or DNS Management",
					body:
						"Look for a section related to DNS settings, DNS management, or DNS records. It's typically under the domain management section.",
				},
				{
					title: "5. Add a CNAME Record",
					body:
						"Within the DNS settings or management area, find the option to add a new DNS record.",
				},
				{
					title: "6. Configure the CNAME Record",
					body:
						"Choose the record type as CNAME and ensure the Host Points to @ <br> Then enter the target domain as <span class='font-bold'>dns.zookish.com</span>  ",
				},
				{
					title: "7. Save Changes",
					body:
						"After entering the necessary information, look for a Save, Add Record, or similar button to save your CNAME record.",
				},
				{
					title: "8. Propagation Time",
					body:
						"Keep in mind that DNS changes can take some time to propagate across the internet. We have partnered with Cloudflare to ensure that the process is mediated in under 5 minutes",
				},
				{
					title: "9. Verify Your CNAME Record",
					body:
						"To ensure that your CNAME record is correctly set up, you can use online DNS lookup tools or wait for propagation to complete and test your domain to see if it redirects as expected.",
				},
			],
		}
	},
	computed: {
		hostname() {
			return this.hostStore.hostname
		},
		project() {
			return this.projectStore.project
		},
	},
	mounted() {
		if (Object.keys(this.project).length === 0) {
			this.$router.push("/projects")
		}
	},
	methods: {
		async addCustomHostname() {
			try {
				const payload = {
					hostname: this.domain,
					ssl: {
						method: "http",
						type: "dv",
						settings: {
							min_tls_version: "1.0",
						},
					},
				}
				const data = {
					payload: JSON.stringify(payload),
				}
				const response = await api.post(
					`${this.hostname}/api/v1/project/hostname`,
					data
				)
				this.result = JSON.parse(response.data)
				if (JSON.parse(response.data).success) {
					// store the hostname and record id in the project
					this.AddHostnameToProject(JSON.stringify(this.result))

				} else {
					this.message = JSON.parse(response.data).errors[0].message
					// check


					if (this.message == 'Duplicate custom hostname found.') {
						this.checkIfDomainExists()

					}

					this.hasError = true
				}
			} catch (error) {
				console.error("Error:", error)
				this.message = error
				this.hasError = true
			}
		},
		async checkIfDomainExists() {
			try {
				const response = await api.get(
					`${this.hostname}/api/v1/projects/pages/get/${this.domain}`
				)
			} catch (error) {
				if (error.request.status == 404) {
					this.AddHostnameToProject(JSON.stringify(this.result))
				}
			}
		},
		async AddHostnameToProject(result) {
			try {
				const data = this.project
				data.hostname = result
				data.domain = this.domain
				const response = await api.post(
					`${this.hostname}/api/v1/project/update/${this.project._id}`,
					data
				)
				console.log(response)
				this.$router.push("/project/configure")
				this.HostnameStatus()
			} catch (error) {
				console.error("Error:", error)
				this.message = error
				this.hasError = true
			}
		},
		async HostnameStatus() {
			try {
				const response = await api.get(
					`${this.hostname}/api/v1/hostname/status/${JSON.parse(this.project.hostname).result.id
					}`
				)
				console.log(response.data)
				var result = JSON.parse(response.data)
				this.project.hostname = JSON.stringify(result)
				if (JSON.parse(this.project.hostname).result.status == "active") {
					this.project.domain = JSON.parse(
						this.project.hostname
					).result.hostname
				}
			} catch (error) { }
		},
		copy() {
			this.copied = true
			// Get the text field
			var copyText = document.getElementById("dns")

			// Select the text field
			copyText.select()
			copyText.setSelectionRange(0, 99999) // For mobile devices

			// Copy the text inside the text field
			navigator.clipboard.writeText(copyText.value)
		},
	},
}
</script>
<style>
.progress {
	animation: progress 1s infinite linear;
}

.left-right {
	transform-origin: 0% 50%;
}

@keyframes progress {
	0% {
		transform: translateX(0) scaleX(0);
	}

	40% {
		transform: translateX(0) scaleX(0.4);
	}

	100% {
		transform: translateX(100%) scaleX(0.5);
	}
}
</style>
