<template>
    <main id="content" role="main" class="my-5">
        <div class="lfmff o3wmg ugq3y bcfme">
            <div class="z9hkr qazpp fngso">
                <div
                    class="relative overflow-hidden before:absolute before:top-0 before:start-1/2 before:bg-[url('https://preline.co/assets/svg/examples/polygon-bg-element.svg')] before:bg-no-repeat before:bg-top before:bg-cover before:w-full before:h-full before:-z-[1] before:transform before:-tranneutral-x-1/2 dark:before:bg-[url('https://preline.co/assets/svg/examples/polygon-bg-element-dark.svg')] max-w-xl mx-auto">
                    <div class="mt-24 max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 pb-10">
                        <!-- Title -->
                        <div class="mt-5 max-w-2xl text-center mx-auto">
                            <h1
                                class="block font-bold text-neutral-800 text-4xl md:text-5xl lg:text-6xl dark:text-neutral-200">
                                Let's Build
                                <span
                                    class="bg-clip-text bg-gradient-to-tl from-blue-600 to-violet-600 text-transparent">Together</span>
                            </h1>
                        </div>
                        <!-- End Title -->

                        <div class="mt-5 max-w-3xl text-center mx-auto">
                            <p class="text-lg text-neutral-600 dark:text-neutral-400">
                                Generate a site template from an existing website url
                            </p>
                        </div>

                        <!-- Buttons -->
                        <div class="mt-8 gap-3 flex justify-center">
                            <clone />
                        </div>

                    </div>
                </div>
                <!-- Header -->
                <div class="relative wlsmd flex azaj8 items-center">
                    <div>
                        <h2 class="uiqm5 hbd69 kxhcs v7056 dark:text-neutral-200">
                            Welcome
                        </h2>
                    </div>

                    <div class="flex items-center dqqs4">
                        <button type="button"
                            class="lfmff pqrvw pdrgo items-center dqqs4 l66z3 yj6bp v7056 dtjcu eass7 nq4w8 gk940 kko9e tk4f7 focus:outline-none focus:bg-gray-200 dark:text-white dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                            <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <polygon points="5 3 19 12 5 21 5 3"></polygon>
                            </svg>
                            Watch intro
                        </button>

                        <button type="button"
                            class="lfmff pqrvw pdrgo items-center dqqs4 l66z3 yj6bp v7056 dtjcu eass7 nq4w8 gk940 kko9e tk4f7 focus:outline-none focus:bg-gray-200 dark:text-white dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                            <svg class="wlxy3 xtsb0 f5cx3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <rect width="18" height="18" x="3" y="4" rx="2" ry="2"></rect>
                                <line x1="16" x2="16" y1="2" y2="6"></line>
                                <line x1="8" x2="8" y1="2" y2="6"></line>
                                <line x1="3" x2="21" y1="10" y2="10"></line>
                                <path d="M8 14h.01"></path>
                                <path d="M12 14h.01"></path>
                                <path d="M16 14h.01"></path>
                                <path d="M8 18h.01"></path>
                                <path d="M12 18h.01"></path>
                                <path d="M16 18h.01"></path>
                            </svg>
                            Book a demo
                        </button>
                    </div>
                </div>
                <!-- End Header -->

                <!-- Card -->
                <div class="pqx67 f0dty l66z3 d1k81 tgfrq k3u76 dark:bg-neutral-800 dark:border-neutral-700">
                    <div class="jjege oaqdw uda7p">
                        <div class="xay4z hhi2j">
                            <div class="em545 k3u76 bx801 dark:bg-neutral-700">
                                <img class="cc9n6 qsf3i w-full"
                                    src="https://res.cloudinary.com/dxwy9kwne/image/upload/v1708211026/hnzf9jrdnoryyokaklqv.png"
                                    alt="Image Description">
                            </div>
                        </div>
                        <!-- End Col -->

                        <div class="g2c74 fgyjv">
                            <div class="raxhy flex lgqw8 azaj8 knmvi">
                                <div>
                                    <h3 class="hbd69 qaep8 kxhcs v7056 dark:text-neutral-200">
                                        Explore the most popular Preline dashboard templates
                                    </h3>
                                    <p class="q8w6j bil2m fyxhw dark:text-neutral-500">
                                        Grab the ones that fit your marketing strategy
                                    </p>
                                </div>
                                <p>
                                    <a class="pqrvw items-center azej2 dtjcu m7g7n cmffi cdcib eass7 focus:outline-none focus:underline dark:text-blue-400 dark:hover:text-blue-500"
                                        href="#">
                                        Explore templates
                                        <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24"
                                            height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="m9 18 6-6-6-6"></path>
                                        </svg>
                                    </a>
                                </p>
                            </div>
                        </div>
                        <!-- End Col -->
                    </div>
                </div>
                <!-- End Card -->

                <!-- Card -->
                <div class="pqx67 f0dty l66z3 d1k81 tgfrq k3u76 dark:bg-neutral-800 dark:border-neutral-700">
                    <div class="jjege oaqdw uda7p">
                        <div class="xay4z hhi2j">
                            <div class="p34yo k3u76 bx801 dark:bg-blue-900">
                                <img class="cc9n6 qsf3i w-full"
                                    src="https://res.cloudinary.com/dxwy9kwne/image/upload/v1708211026/hnzf9jrdnoryyokaklqv.png"
                                    alt="Image Description">
                            </div>
                        </div>
                        <!-- End Col -->

                        <div class="g2c74 fgyjv">
                            <div class="raxhy flex lgqw8 azaj8 knmvi">
                                <div>
                                    <h3 class="hbd69 qaep8 kxhcs v7056 dark:text-neutral-200">
                                        Documentation
                                    </h3>
                                    <p class="q8w6j bil2m fyxhw dark:text-neutral-500">
                                        Whether you're a startup or a global enterprise, learn how to integrate with
                                        Preline.
                                    </p>
                                </div>
                                <p>
                                    <a class="pqrvw items-center azej2 dtjcu m7g7n cmffi cdcib eass7 focus:outline-none focus:underline dark:text-blue-400 dark:hover:text-blue-500"
                                        href="#">
                                        Learn more
                                        <svg class="wlxy3 sfo8l qcpnt" xmlns="http://www.w3.org/2000/svg" width="24"
                                            height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="m9 18 6-6-6-6"></path>
                                        </svg>
                                    </a>
                                </p>
                            </div>
                        </div>
                        <!-- End Col -->
                    </div>
                </div>
                <!-- End Card -->

                <!-- Card -->
                <div
                    class="e6pqd qxuf9 relative bx801 zpy54 f0dty l66z3 d1k81 tgfrq k3u76 dark:bg-neutral-800 dark:border-neutral-700">
                    <div class="relative wlsmd">
                        <h3 class="civh3 wv5xa kxhcs v7056 dark:text-neutral-200">
                            Invite friends
                        </h3>
                        <p class="q8w6j fyxhw dark:text-neutral-500">
                            Invite teammates to collaborate in Preline
                        </p>
                        <div class="vd2z8 ipzsx">
                            <button type="button"
                                class="xxfsd vrf2y pqrvw items-center dqqs4 dtjcu eass7 nq4w8 l66z3 d1k81 f0dty v7056 tgfrq cjy9h kko9e tk4f7 focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                                data-hs-overlay="#hs-pro-dshm">
                                Send an invite
                            </button>
                        </div>
                    </div>

                    <div class="absolute owd4s xe52r">
                        <img class="wlxy3 h8i1i mfm8t iwtls"
                            src="https://images.unsplash.com/photo-1659482633369-9fe69af50bfb?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=320&amp;h=320&amp;q=80"
                            alt="Image Description">
                    </div>

                    <div class="hidden p6u16 absolute z6ef6 v824x">
                        <img class="wlxy3 e88cr d1511 iwtls"
                            src="https://images.unsplash.com/photo-1659482634023-2c4fda99ac0c?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=facearea&amp;facepad=2.5&amp;w=320&amp;h=320&amp;q=80"
                            alt="Image Description">
                    </div>

                    <div class="absolute ucofb veq0i">
                        <img class="wlxy3 kbkb7 gpe5b iwtls"
                            src="https://images.unsplash.com/photo-1568048689711-5e0325cea8c0?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=facearea&amp;facepad=2.5&amp;w=320&amp;h=320&amp;q=80"
                            alt="Image Description">
                    </div>

                    <div class="absolute efntt nzziy">
                        <img class="wlxy3 kbkb7 gpe5b iwtls"
                            src="https://images.unsplash.com/photo-1548142813-c348350df52b?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=facearea&amp;facepad=2.5&amp;w=320&amp;h=320&amp;q=80"
                            alt="Image Description">
                    </div>

                    <div class="absolute -top-5 gc7nb">
                        <img class="wlxy3 e88cr d1511 iwtls"
                            src="https://images.unsplash.com/photo-1570654639102-bdd95efeca7a?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=320&amp;h=320&amp;q=80"
                            alt="Image Description">
                    </div>

                    <div class="absolute l09ed ja65r">
                        <img class="wlxy3 kbkb7 gpe5b iwtls"
                            src="https://images.unsplash.com/photo-1670272505340-d906d8d77d03?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=320&amp;h=320&amp;q=80"
                            alt="Image Description">
                    </div>

                    <div class="hidden p6u16 absolute z6ef6 c376y">
                        <img class="wlxy3 e88cr d1511 iwtls"
                            src="https://images.unsplash.com/photo-1601935111741-ae98b2b230b0?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=320&amp;h=320&amp;q=80"
                            alt="Image Description">
                    </div>

                    <div class="hidden p6u16 absolute ucofb gc7nb">
                        <img class="wlxy3 kbkb7 gpe5b iwtls"
                            src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=facearea&amp;facepad=2.5&amp;w=320&amp;h=320&amp;q=80"
                            alt="Image Description">
                    </div>

                    <div class="absolute efntt fdiw9">
                        <img class="wlxy3 kbkb7 gpe5b iwtls"
                            src="https://images.unsplash.com/photo-1679412330254-90cb240038c5?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=facearea&amp;facepad=2.5&amp;w=320&amp;h=320&amp;q=80"
                            alt="Image Description">
                    </div>
                </div>
                <!-- End Card -->
            </div>
        </div>
    </main>
</template>
<script>
import clone from './clone.vue';
export default {
    components: {
        clone
    }
}
</script>