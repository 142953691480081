<template>
    <transition name="fade">
      <div >
        <div class="relative z-infinity" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div
                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                 <section class="rounded-3xl shadow-2xl">
                        <div class="p-8 text-center sm:p-12">
                            <p class="text-sm font-semibold uppercase tracking-widest text-neutral-500">
                            Your submission has been received 
                            </p>

                            <h2 class="mt-6 text-3xl font-bold text-neutral-800">
                            Thank you for your submission.
                            </h2>
                        </div>
                    </section>
              </div>
            </div>
          </div>
        </div>

      </div>
    </transition>
</template>
