<template>

    <body class="dark:bg-neutral-900" style="">
        <!-- ========== MAIN CONTENT ========== -->
        <div class="max-w-[90rem] mx-auto">
            <!-- Sidebar Toggle -->
            <div
                class="sticky top-0 inset-x-0 z-20 bg-white border-y px-4 sm:px-6 md:px-8 lg:hidden dark:bg-neutral-900 dark:border-neutral-700">
                <div class="max-w-3xl mx-auto py-2">
                    <!-- Navigation Toggle -->
                    <button type="button"
                        class="flex justify-between gap-x-2 items-center w-full text-neutral-500 hover:text-neutral-600"
                        data-hs-overlay="#docs-sidebar" aria-controls="docs-sidebar" aria-label="Toggle navigation">
                        <span class="text-sm">Toggle Navigation</span>
                        <svg class="flex-shrink-0 w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round">
                            <line x1="3" x2="21" y1="6" y2="6"></line>
                            <line x1="3" x2="21" y1="12" y2="12"></line>
                            <line x1="3" x2="21" y1="18" y2="18"></line>
                        </svg>
                    </button>
                    <!-- End Navigation Toggle -->
                </div>
            </div>
            <!-- End Sidebar Toggle -->

            <!-- Sidebar -->
            <div id="docs-sidebar"
                class="hs-overlay hs-overlay-open:tranneutral-x-0 -tranneutral-x-full transition-all duration-300 transform fixed top-0 start-0 bottom-0 z-[60] w-80 bg-white border-e border-neutral-200 py-10 px-8 overflow-y-auto lg:block lg:tranneutral-x-0 lg:top-0 lg:end-auto lg:bottom-0 lg:start-[max(0px,calc(50%-45rem))] lg:z-10 d[&amp;::-webkit-scrollbar]:w-2 [&amp;::-webkit-scrollbar-thumb]:rounded-full [&amp;::-webkit-scrollbar-track]:bg-neutral-100 [&amp;::-webkit-scrollbar-thumb]:bg-neutral-300 dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 hidden">
                <!-- Navigation Toggle -->
                <button type="button"
                    class="ms-auto flex justify-end lg:hidden text-neutral-500 hover:text-neutral-600 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
                    data-hs-overlay="#docs-sidebar" aria-controls="docs-sidebar" aria-label="Toggle navigation">
                    <span class="sr-only">Toggle Navigation</span>
                    <svg class="flex-shrink-0 w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                    </svg>
                </button>
                <!-- End Navigation Toggle -->

                <!-- Nav -->
                <nav id="sidebar-nav" class="relative space-y-8">
                    <h5 class="mb-3 text-sm font-semibold text-neutral-900 dark:text-neutral-200">
                        TOS
                    </h5>
                    <ul class="ms-0.5 space-y-2 border-s-2 border-neutral-100 dark:border-neutral-800"
                        data-hs-scrollspy="#scrollspy">
                        <li>
                            <a class="block py-1 ps-4 -ms-px border-s-2 border-transparent text-sm text-neutral-700 hover:border-neutral-400 hover:text-neutral-900 dark:text-neutral-400 dark:hover:text-neutral-300 hs-scrollspy-active:font-medium hs-scrollspy-active:text-blue-600 dark:hs-scrollspy-active:text-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
                                href="#introduction">Introduction</a>
                        </li>
                        <li>
                            <a class="block py-1 ps-4 -ms-px border-s-2 border-transparent text-sm text-neutral-700 hover:border-neutral-400 hover:text-neutral-900 dark:text-neutral-400 dark:hover:text-neutral-300 hs-scrollspy-active:font-medium hs-scrollspy-active:text-blue-600 dark:hs-scrollspy-active:text-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
                                href="#service-description">Service Description</a>
                        </li>
                        <li>
                            <a class="block py-1 ps-4 -ms-px border-s-2 border-transparent text-sm text-neutral-700 hover:border-neutral-400 hover:text-neutral-900 dark:text-neutral-400 dark:hover:text-neutral-300 hs-scrollspy-active:font-medium hs-scrollspy-active:text-blue-600 dark:hs-scrollspy-active:text-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
                                href="#user-obligations">User Obligations</a>
                        </li>
                        <li>
                            <a class="block py-1 ps-4 -ms-px border-s-2 border-transparent text-sm text-neutral-700 hover:border-neutral-400 hover:text-neutral-900 dark:text-neutral-400 dark:hover:text-neutral-300 hs-scrollspy-active:font-medium hs-scrollspy-active:text-blue-600 dark:hs-scrollspy-active:text-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
                                href="#intellectual-property">Intellectual Property</a>
                        </li>
                        <li>
                            <a class="block py-1 ps-4 -ms-px border-s-2 border-transparent text-sm text-neutral-700 hover:border-neutral-400 hover:text-neutral-900 dark:text-neutral-400 dark:hover:text-neutral-300 hs-scrollspy-active:font-medium hs-scrollspy-active:text-blue-600 dark:hs-scrollspy-active:text-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
                                href="#limitations-liability">Limitations of Liability</a>
                        </li>
                        <li>
                            <a class="block py-1 ps-4 -ms-px border-s-2 border-transparent text-sm text-neutral-700 hover:border-neutral-400 hover:text-neutral-900 dark:text-neutral-400 dark:hover:text-neutral-300 hs-scrollspy-active:font-medium hs-scrollspy-active:text-blue-600 dark:hs-scrollspy-active:text-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
                                href="#warranties-disclaimers">Warranties and Disclaimers</a>
                        </li>
                        <!-- Additional items can be added here for other sections like 'Amendments to the ToS', 'Termination', 'Governing Law', etc. -->
                    </ul>
                </nav>
                <!-- Nav -->
            </div>
            <!-- End Sidebar -->

            <!-- Content -->
            <div class="w-full px-4 sm:px-6 md:px-8 lg:ps-[22rem]">
                <div class="max-w-3xl mx-auto  text-white xl:max-w-none py-10 xl:ms-0 xl:me-64 xl:pe-16">
                    <!-- Page Heading -->
                    <header class="max-w-3xl">
                        <p class="mb-2 text-sm font-semibold text-blue-600">
                            Zookish (MUST READ TOS)
                        </p>
                        <h1 class="block text-2xl font-bold text-neutral-800 sm:text-3xl dark:text-white">
                            Preliminary
                        </h1>
                        <p class="mt-2 text-lg text-neutral-800 dark:text-neutral-400">
                            <p>
                                Welcome to Zookish LLC, your innovative partner in website design and development. This
                                Preliminary Introduction serves as a brief overview of our Terms of Service (ToS) and is
                                intended to guide you through the key aspects of using our services. Zookish LLC offers
                                unique AI-driven tools, including web cloning capabilities, to help you effortlessly
                                create and manage your online presence.
                            </p>
                            <p>
                                Our ToS is an essential document that governs your use of our services. It outlines our
                                commitments to you, as well as your rights, responsibilities, and limitations while
                                using Zookish LLC. Whether you are a seasoned web developer or a first-time website
                                creator, our terms are designed to ensure a clear, fair, and legal use of our services.
                            </p>
                            <p>
                                We encourage you to read the complete Terms of Service carefully to fully understand the
                                scope of our agreement. By accessing and using Zookish LLC, you are agreeing to comply
                                with and be bound by these terms. If you have any questions or need clarification on any
                                aspect of these terms, please feel free to contact us.
                            </p>
                        </p>
                        <div class="mt-5 flex flex-col items-center gap-2 sm:flex-row sm:gap-3">
                            <router-link
                                class="w-full sm:w-auto py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
                                to="/">
                                <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24"
                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="m15 18-6-6 6-6"></path>
                                </svg>
                                Back to Home
                            </router-link>
                        </div>
                    </header>
                    <!-- End Page Heading -->

                    <!-- Content -->
                    <div class="mt-16">
                        <div id="scrollspy" class="space-y-10 md:space-y-16 text-white">
                            <!-- Introduction -->
                            <div id="introduction" class="scroll-mt-24 min-h-[25rem]">
                                <h2 class="text-lg font-semibold text-neutral-800 dark:text-white">
                                    Introduction
                                </h2>

                                <p>
                                    Welcome to Zookish LLC! These Terms of Service ("ToS")
                                    constitute a legal agreement between you (either an individual
                                    or a legal entity that you represent) and Zookish LLC. By
                                    accessing or using our website building and web cloning
                                    services, you agree to be bound by the terms and conditions
                                    outlined in these ToS. Our services are designed to offer
                                    innovative and user-friendly tools to create and manage
                                    websites, leveraging the power of artificial intelligence for
                                    efficiency and creativity.
                                </p>
                                <p>
                                    This document outlines the rules and guidelines for using our
                                    services, including our web cloning technology, which allows
                                    users to draw inspiration from existing website designs while
                                    ensuring the creation of unique and legally compliant web
                                    content. It is important to read and understand these ToS
                                    before using our services, as they cover essential aspects
                                    such as user obligations, intellectual property rights,
                                    limitations of liability, and warranties.
                                </p>
                                <p>
                                    Please note that by using our services, you acknowledge that
                                    you have read, understood, and agree to be bound by these
                                    terms. If you do not agree with any part of these ToS, you
                                    should refrain from using our services. We reserve the right
                                    to update or change our ToS at any time, and your continued
                                    use of the service after such changes signifies your
                                    acceptance of the updated terms.
                                </p>
                            </div>

                            <!-- Service Description -->
                            <div id="service-description" class="scroll-mt-24 min-h-[25rem]">
                                <h2 class="text-lg font-semibold text-neutral-800 dark:text-white">
                                    Service Description
                                </h2>
                                <p>
                                    Zookish LLC specializes in providing advanced, AI-powered web development tools that
                                    cater to a wide range of website creation needs. Our platform offers a seamless,
                                    user-friendly experience for both novice and experienced web developers. With
                                    Zookish LLC, users can access a suite of tools that simplify the process of
                                    designing, building, and managing websites.
                                </p>
                                <p>
                                    Our standout feature is the web cloning capability, which allows users to draw
                                    inspiration from existing web designs. This tool intelligently analyzes and suggests
                                    website layouts and elements based on current design trends, ensuring that your
                                    website is both modern and functional. While the tool is inspired by existing
                                    designs, it creates unique renditions to ensure that your website stands out and
                                    remains copyright compliant.
                                </p>
                                <p>
                                    In addition to web cloning, Zookish LLC provides a range of customizable templates,
                                    drag-and-drop editors, and integrated SEO tools to enhance the visibility and
                                    performance of your website. Our platform is also equipped with analytics tools to
                                    help you understand visitor behavior and make data-driven decisions for website
                                    improvements.
                                </p>
                                <p>
                                    We continually update our service offerings to incorporate the latest advancements
                                    in AI and web development. This commitment ensures that our users are always
                                    equipped with cutting-edge tools to create dynamic, responsive, and visually
                                    appealing websites. Whether you are looking to create a personal blog, a corporate
                                    website, or an e-commerce platform, Zookish LLC provides the tools and support to
                                    bring your vision to life.
                                </p>
                            </div>

                            <!-- User Obligations -->
                            <div id="user-obligations" class="scroll-mt-24 min-h-[25rem]">
                                <h2 class="text-lg font-semibold text-neutral-800 dark:text-white">
                                    User Obligations
                                </h2>
                                <p>
                                    Users agree to comply with all applicable laws, are
                                    responsible for their content, and must not engage in illegal
                                    activities or infringe on the rights of others.
                                </p>
                                <p>
                                    As a user of Zookish LLC, you are entrusted with certain responsibilities to ensure
                                    a positive, legal, and safe experience for all users. Adherence to these obligations
                                    is crucial for maintaining the integrity and functionality of our services.
                                </p>
                                <p>
                                    <strong>Legal Compliance:</strong> You must use Zookish LLC’s services in compliance
                                    with all applicable local, national, and international laws. This includes, but is
                                    not limited to, laws pertaining to copyright, intellectual property, and online
                                    conduct and content.
                                </p>
                                <p>
                                    <strong>Content Responsibility:</strong> You are solely responsible for any content
                                    you create, upload, or publish using our platform. This includes ensuring that your
                                    content does not infringe upon the rights of others, is not defamatory, obscene, or
                                    offensive, and does not promote illegal activities.
                                </p>
                                <p>
                                    <strong>Account Security:</strong> You are responsible for maintaining the
                                    confidentiality of your account information, including your password. Any activities
                                    under your account are your responsibility, and you should immediately report any
                                    unauthorized use of your account to Zookish LLC.
                                </p>
                                <p>
                                    <strong>Prohibited Uses:</strong> You must not use Zookish LLC’s services for any
                                    unlawful or unauthorized purpose. This includes engaging in behavior that is
                                    harmful, harassing, or disruptive to the service or other users. The use of
                                    automated systems or software to extract data from our service for commercial
                                    purposes ('screen scraping') is strictly prohibited.
                                </p>
                                <p>
                                    <strong>Respect for Intellectual Property:</strong> You must respect the
                                    intellectual property rights of others. This includes not uploading, sharing, or
                                    replicating content that you do not have the right to use. Our web cloning tool is
                                    designed to inspire original creations, not to facilitate the copying of existing
                                    copyrighted materials.
                                </p>
                                <p>
                                    Your adherence to these obligations is essential for your continued access to and
                                    use of Zookish LLC's services. Failure to comply with these obligations may result
                                    in the suspension or termination of your account and legal action, where necessary.
                                </p>
                            </div>

                            <!-- Intellectual Property -->
                            <div id="intellectual-property" class="scroll-mt-24 min-h-[25rem]">
                                <h2 class="text-lg font-semibold text-neutral-800 dark:text-white">
                                    Intellectual Property
                                </h2>
                                <p>
                                    Zookish LLC's web cloning feature assists in creating website
                                    layouts inspired by existing designs without duplicating
                                    copyrighted content. Users retain rights to their content but
                                    grant Zookish LLC certain usage rights.
                                </p>
                                <p>
                                    At Zookish LLC, we deeply respect intellectual property rights and expect our users
                                    to do the same. This section outlines the intellectual property policies and
                                    responsibilities that apply when using our services.
                                </p>
                                <p>
                                    <strong>Ownership of User Content:</strong> As a user, you retain all ownership
                                    rights to the content you create, upload, or publish through our platform. However,
                                    by using our services, you grant Zookish LLC a non-exclusive, transferable,
                                    sub-licensable, royalty-free, and worldwide license to use, reproduce, modify,
                                    display, and distribute your content in connection with the service. This license is
                                    solely for the purpose of operating, promoting, and improving our services, and to
                                    develop new ones.
                                </p>
                                <p>
                                    <strong>Zookish LLC’s Intellectual Property:</strong> All rights, titles, and
                                    interests in and to the Zookish LLC service and its original content, features, and
                                    functionality are and will remain the exclusive property of Zookish LLC and its
                                    licensors. Our service is protected by copyright, trademark, and other laws both in
                                    the United States and internationally. Our trademarks and trade dress may not be
                                    used in connection with any product or service without the prior written consent of
                                    Zookish LLC.
                                </p>
                                <p>
                                    <strong>Web Cloning and Copyright:</strong> Our web cloning feature is designed to
                                    assist in the creation of unique website layouts by drawing inspiration from
                                    existing designs. It is imperative that users understand this tool does not
                                    replicate copyrighted material and should be used to create original content. Users
                                    are responsible for ensuring that the content they create using our tools does not
                                    infringe upon the intellectual property rights of others.
                                </p>
                                <p>
                                    <strong>Reporting Infringements:</strong> If you believe that your intellectual
                                    property rights have been infringed upon by a user of our service, we encourage you
                                    to contact us. Zookish LLC is committed to promptly addressing such concerns and
                                    taking appropriate action, which may include removing the infringing content and
                                    terminating the accounts of repeat infringers.
                                </p>
                                <p>
                                    Your respect for intellectual property not only upholds legal standards but also
                                    fosters a creative and innovative environment. We thank you for honoring these
                                    principles.
                                </p>
                            </div>

                            <!-- Limitations of Liability -->
                            <div id="limitations-liability" class="scroll-mt-24 min-h-[25rem]">
                                <h2 class="text-lg font-semibold text-neutral-800 dark:text-white">
                                    Limitations of Liability
                                </h2>
                                <p>
                                    Zookish LLC is not liable for indirect, incidental, special,
                                    consequential, or punitive damages, or any loss of profits,
                                    revenues, data, or other intangible losses.
                                </p>
                                <p>
                                    Zookish LLC, its affiliates, and its service providers will not be liable for any
                                    indirect, incidental, special, consequential, or punitive damages, or any loss of
                                    profits or revenues, whether incurred directly or indirectly, or any loss of data,
                                    use, goodwill, or other intangible losses. This limitation of liability applies
                                    regardless of the legal theory on which the liability is based and even if Zookish
                                    LLC has been advised of the possibility of such damages.
                                </p>
                                <p>
                                    <strong>Scope of Liability:</strong> Our liability is limited to the maximum extent
                                    permitted by law. We are not responsible for any losses or damages not reasonably
                                    foreseeable or that are caused by unauthorized access to our services through your
                                    account.
                                </p>
                                <p>
                                    <strong>Service Availability:</strong> While we strive to keep our service
                                    operational, we do not guarantee uninterrupted, timely, secure, or error-free
                                    operation of our services or that defects in the service will always be corrected.
                                </p>
                                <p>
                                    <strong>User Content and Interactions:</strong> Zookish LLC is not responsible for
                                    the content created, uploaded, or shared by its users on the platform. We also do
                                    not endorse, support, represent, or guarantee the completeness, truthfulness,
                                    accuracy, or reliability of any user content or communications posted via our
                                    services or endorse any opinions expressed via our services. Interactions with other
                                    users or third parties through our service, including payment and delivery of goods
                                    or services, and any other terms, conditions, warranties, or representations
                                    associated with such dealings, are solely between you and such individuals or
                                    organizations.
                                </p>
                                <p>
                                    <strong>Modifications and Updates:</strong> We may modify, update, or discontinue
                                    our services at our discretion without notice or liability to you or any third
                                    party.
                                </p>
                                <p>
                                    This limitation of liability is a fundamental element of the basis of the bargain
                                    between Zookish LLC and you and shall apply to all claims of liability (e.g.,
                                    warranty, tort, negligence, contract, law) and even if Zookish LLC has been told of
                                    the possibility of any such damage, and even if these remedies fail their essential
                                    purpose.
                                </p>
                            </div>

                            <!-- Warranties and Disclaimers -->
                            <div id="warranties-disclaimers" class="scroll-mt-24 min-h-[25rem]">
                                <h2 class="text-lg font-semibold text-neutral-800 dark:text-white">
                                    Warranties and Disclaimers
                                </h2>

                                <p>
                                    Zookish LLC provides its services on an "as is" and "as available" basis, and hereby
                                    expressly disclaims all warranties of any kind, whether express, implied, statutory,
                                    or otherwise. This includes, but is not limited to, any warranties of
                                    merchantability, fitness for a particular purpose, and non-infringement. Zookish LLC
                                    does not warrant that the service will meet your requirements, will be
                                    uninterrupted, timely, secure, or error-free, or that the results obtained from the
                                    use of the service will be accurate or reliable.
                                </p>
                                <p>
                                    <strong>No Guarantee of Accuracy:</strong> While we strive to ensure that the
                                    information provided through our service is current and accurate, we cannot
                                    guarantee that all the information on our platform is complete, true, or up-to-date.
                                    Users should verify any information before relying on it.
                                </p>
                                <p>
                                    <strong>Third-Party Links and Content:</strong> Our service may contain links to
                                    third-party websites or resources. Zookish LLC provides these links as a convenience
                                    and is not responsible for the content, products, or services on or available from
                                    those websites or resources. The inclusion of any link does not imply endorsement by
                                    Zookish LLC. Use of any such linked website is at the user's own risk.
                                </p>
                                <p>
                                    <strong>Limitation on Claims:</strong> Any claims related to Zookish LLC’s services
                                    must be brought within one year of the date of the event giving rise to such action
                                    occurred. Remedies under these Terms of Service are exclusive and are limited to
                                    those expressly provided for in these Terms.
                                </p>
                                <p>
                                    The disclaimers and exclusions under this section will apply to the maximum extent
                                    allowable under applicable law. Users’ use of Zookish LLC's services is at their own
                                    risk, and Zookish LLC is not liable for any harm resulting from users’ reliance on
                                    the service.
                                </p>
                            </div>

                            <!-- Additional sections like Amendments to the ToS, Termination, Governing Law, etc., can be added following the same structure -->
                        </div>
                    </div>

                    <!-- End Content -->
                </div>
            </div>
            <!-- End Content -->
        </div>
    </body>
</template>