<template>
	<div
		class="fixed top-0 z-infinity left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden backdrop-blur bg-black/50 flex flex-col items-center justify-center"
	>
		<div
			class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center"
		>
			<pricing />
		</div>
		<div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
	</div>
</template>
<script>
	import pricing from "@/views/home/components/pricing2.vue"
	export default {
		components: {
			pricing,
		},
	}
</script>
