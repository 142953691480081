// stores/CloneStore.js
import { defineStore } from 'pinia';

export const useCloneStore = defineStore('clone', {
  state: () => ({
    response: null,
    ev: `Processing templates for site generation`
  }),
  actions: {
    setResponse(response) {
      this.response = response;
    },
    setEv(ev) {
      this.ev = ev
    }
  },
});
