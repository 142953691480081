<template>
	<!-- Hero -->
	<div class="relative overflow-hidden">
		<div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-10 sm:py-24">
			<div class="text-center">
				<h1
					class="text-4xl pb-7 sm:text-6xl font-extrabold bg-clip-text text-transparent bg-indigo-500 text-black dark:text-white"
				>
					Projects
				</h1>

				<p class="mt-3 text-neutral-600 dark:text-neutral-400">
					This project search feature is designed for efficiently finding
					relevant projects based on specific criteria.
				</p>

				<div class="mt-7 sm:mt-12 mx-auto max-w-xl relative">
					<!-- Form -->
					<div
						class="relative z-10 flex space-x-3 p-3 bg-white border rounded-lg shadow-lg shadow-neutral-100 dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-900/[.2]"
					>
						<div class="flex-[1_0_0%] rounded-md overflow-hidden">
							<label
								for="hs-search-article-1"
								class="block text-sm text-neutral-700 font-medium dark:text-white"
								><span class="sr-only">Search projects</span></label
							>
							<input
								type="text"
								v-model="query"
								@keyup.enter="search"
								class="py-2.5 px-4 block w-full border-transparent focus:border-indigo-500 focus:ring-indigo-500 dark:bg-neutral-900 dark:border-transparent dark:text-neutral-400 dark:focus:ring-neutral-600"
								placeholder="Search projects"
							/>
						</div>
						<div class="flex-[0_0_auto]">
							<a
								class="w-[46px] h-[46px] inline-flex justify-center hover:text-white items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-indigo-100 dark:hover:bg-indigo-500 text-black dark:text-white disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
								@click="results = projects"
							>
								<vue-feather
									type="refresh-ccw"
									size="24"
									class="text-black dark:text-white"
								/>
							</a>
							<a
								class="w-[46px] h-[46px] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-indigo-100 dark:hover:bg-indigo-500 text-black dark:text-white disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
								@click="search"
							>
								<vue-feather
									type="search"
									size="24"
									class="text-black dark:text-white"
								/>
							</a>
						</div>
					</div>
					<!-- End Form -->

					<!-- SVG Element -->
					<div
						class="hidden md:block absolute top-0 end-0 -tranneutral-y-12 tranneutral-x-20"
					>
						<svg
							class="w-16 h-auto text-orange-500"
							width="121"
							height="135"
							viewBox="0 0 121 135"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M5 16.4754C11.7688 27.4499 21.2452 57.3224 5 89.0164"
								stroke="currentColor"
								stroke-width="10"
								stroke-linecap="round"
							/>
							<path
								d="M33.6761 112.104C44.6984 98.1239 74.2618 57.6776 83.4821 5"
								stroke="currentColor"
								stroke-width="10"
								stroke-linecap="round"
							/>
							<path
								d="M50.5525 130C68.2064 127.495 110.731 117.541 116 78.0874"
								stroke="currentColor"
								stroke-width="10"
								stroke-linecap="round"
							/>
						</svg>
					</div>
					<!-- End SVG Element -->

					<!-- SVG Element -->
					<div
						class="hidden md:block absolute bottom-0 start-0 tranneutral-y-10 -tranneutral-x-32"
					>
						<svg
							class="w-40 h-auto text-cyan-500"
							width="347"
							height="188"
							viewBox="0 0 347 188"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M4 82.4591C54.7956 92.8751 30.9771 162.782 68.2065 181.385C112.642 203.59 127.943 78.57 122.161 25.5053C120.504 2.2376 93.4028 -8.11128 89.7468 25.5053C85.8633 61.2125 130.186 199.678 180.982 146.248L214.898 107.02C224.322 95.4118 242.9 79.2851 258.6 107.02C274.299 134.754 299.315 125.589 309.861 117.539L343 93.4426"
								stroke="currentColor"
								stroke-width="7"
								stroke-linecap="round"
							/>
						</svg>
					</div>
					<!-- End SVG Element -->
				</div>

				<div class="mt-10 sm:mt-20">
					<a
						class="m-1 py-3 px-4 inline-flex items-center gap-x-2 text-sm rounded-lg border border-neutral-200 bg-white text-neutral-800 shadow-sm hover:bg-neutral-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
						href="#"
					>
						<svg
							class="flex-shrink-0 w-3 h-auto"
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							viewBox="0 0 16 16"
						>
							<path
								d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z"
							/>
						</svg>
						Business
					</a>
					<a
						class="m-1 py-3 px-4 inline-flex items-center gap-x-2 text-sm rounded-lg border border-neutral-200 bg-white text-neutral-800 shadow-sm hover:bg-neutral-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
						href="#"
					>
						<svg
							class="flex-shrink-0 w-3 h-auto"
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							viewBox="0 0 16 16"
						>
							<path
								d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"
							/>
							<path
								d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"
							/>
						</svg>
						Strategy
					</a>
					<a
						class="m-1 py-3 px-4 inline-flex items-center gap-x-2 text-sm rounded-lg border border-neutral-200 bg-white text-neutral-800 shadow-sm hover:bg-neutral-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
						href="#"
					>
						<svg
							class="flex-shrink-0 w-3 h-auto"
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							viewBox="0 0 16 16"
						>
							<path
								d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"
							/>
						</svg>
						Health
					</a>
					<a
						class="m-1 py-3 px-4 inline-flex items-center gap-x-2 text-sm rounded-lg border border-neutral-200 bg-white text-neutral-800 shadow-sm hover:bg-neutral-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
						href="#"
					>
						<svg
							class="flex-shrink-0 w-3 h-auto"
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							viewBox="0 0 16 16"
						>
							<path
								d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z"
							/>
						</svg>
						Creative
					</a>
					<a
						class="m-1 py-3 px-4 inline-flex items-center gap-x-2 text-sm rounded-lg border border-neutral-200 bg-white text-neutral-800 shadow-sm hover:bg-neutral-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
						href="#"
					>
						<svg
							class="flex-shrink-0 w-3 h-auto"
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							viewBox="0 0 16 16"
						>
							<path
								fill-rule="evenodd"
								d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
							/>
							<path
								d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"
							/>
						</svg>
						Environment
					</a>
					<a
						class="m-1 py-3 px-4 inline-flex items-center gap-x-2 text-sm rounded-lg border border-neutral-200 bg-white text-neutral-800 shadow-sm hover:bg-neutral-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
						href="#"
					>
						<svg
							class="flex-shrink-0 w-3 h-auto"
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							viewBox="0 0 16 16"
						>
							<path
								d="M7 7V1.414a1 1 0 0 1 2 0V2h5a1 1 0 0 1 .8.4l.975 1.3a.5.5 0 0 1 0 .6L14.8 5.6a1 1 0 0 1-.8.4H9v10H7v-5H2a1 1 0 0 1-.8-.4L.225 9.3a.5.5 0 0 1 0-.6L1.2 7.4A1 1 0 0 1 2 7h5zm1 3V8H2l-.75 1L2 10h6zm0-5h6l.75-1L14 3H8v2z"
							/>
						</svg>
						Adventure
					</a>
				</div>
			</div>
		</div>
	</div>
	<!-- End Hero -->
</template>
<script>
	import { useProjectStore } from "@/stores/ProjectStore"
	export default {
		data() {
			return {
				query: null,
				projectStore: useProjectStore(),
			}
		},
		computed: {
			projects() {
				return this.projectStore.projects
			},
			results: {
				get() {
					return this.projectStore.results
				},
				set(e) {
					this.projectStore.setResults(e)
				},
			},
		},
		methods: {
			search() {
				this.results = []
				const fuzzysort = require("fuzzysort")

				let results = fuzzysort.go(this.query, this.projects, {
					keys: ["title", "tags", "cloneOf", "domain", "hostname"],
					// Create a custom combined score to sort by. -100 to the desc score makes it a worse match
					scoreFn: a =>
						Math.max(
							a[0] ? a[0].score : -1000,
							a[1] ? a[1].score - 100 : -1000
						),
				})
				console.log(results)

				results.forEach(result => {
					if (result.score > -1000) {
						this.results.push(result.obj)
					}
				})

				// if (this.applyFilter) {
				// 	this.filter()
				// }
			},
		},
	}
</script>
<style scoped>
	input {
		border-width: 2pt;
		border-image-slice: 1;
		border-radius: 20px;
	}
</style>
