<template>
	<div
		class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-white dark:bg-neutral-900 flex flex-col items-center justify-center"
	>
		<div class="text-center mx-auto justify-center align-center">
			<vue-feather type="loader" class="spin dark:text-white" />
			<!-- <div class="scene" id="scene" style=""></div> -->
			<!-- <h2 class="text-center text-white text-xl font-semibold">Loading...</h2> -->
			<p class="text-sm text-center dark:text-white">
				<!-- <a
					target="_blank"
					href="https://www.siteground.com/index.htm?afcode=af17681a597c680953255f6f1ac2a6bf"
					>Dreams are what life is made of</a
				> -->
			</p>
		</div>
	</div>
</template>
