import { useCloneStore } from "@/stores/CloneStore"
import { useProjectStore } from "@/stores/ProjectStore"
import {
    useHostStore
} from "@/stores/HostStore"
import { useImageStore } from "@/stores/ImageStore"
import api from "@/services/api"
import { v4 as uuidv4 } from "uuid"
import { useImage } from "@vueuse/core"

const EcomMixin = {
    data: () => {
        return {
            cloneStore: useCloneStore(),
            hostStore: useHostStore(),
            projectStore: useProjectStore(),
            imageStore: useImageStore(),
            showXLSMap: false,
            userStores: [],
            storeProducts: [],
            dataHeaders: [],
            datasheet: [],
            productPage: {},
            map: {
                name: '',
                description: '',
                price: '',
                category: '',
                sku: '',
                weight: '',
                availability: '',
                brand: '',
                quantity: '',
                images: ''
            },
            predefinedHeaders: ['name', 'description', 'price', 'category', 'sku', 'weight', 'availability', 'brand', 'quantity', 'images']

        }
    },
    computed: {
        hostname() {
            return this.hostStore.hostname
        },
        store() {
            return this.hostStore.shop
        },
        project() {
            return this.projectStore.project
        },
        gImages() {
            return this.imageStore.images
        }
    },
    methods: {
        StoreConnected(e) {
            if ('store' in this.project) {
                if (Object.keys(this.project.store).length > 0 && this.project.store._id == e) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        },
        async GetStore() {
            try {
                const response = await api.post(`${this.hostname}/api/v1/get/store/${this.$route.params.id}`)
                this.hostStore.setShop(response.data.store)
                this.selectedFileDataUrl = response.data.store.logo
                this.StoreName = response.data.store.store_name
                this.StoreURL = response.data.store.store_url
                this.ContactEmail = response.data.store.contact_email
                this.ContactPhone = response.data.store.contact_phone
                this.Currency = response.data.store.currency
                this.StoreAddress = response.data.store.store_address
                this.AddressApartmentSuite = response.data.store.address_apartment_suite
                this.AddressPostalCode = response.data.store.address_postal_code
                this.AddressCity = response.data.store.address_city
                this.primaryColor = response.data.store.primary_color
            } catch (error) {
                console.log("Error:", error)
            }
        },
        async CreateStore() {
            const data = {
                _id: this.store._id,
                owner: JSON.parse(localStorage.getItem('user')).data,
                logo: this.selectedFileDataUrl,
                currency: 'USD',
                store_name: this.StoreName,
                store_url: this.StoreURL,
                contact_email: this.ContactEmail,
                contact_phone: this.ContactPhone,
                store_address: this.StoreAddress,
                address_apartment_suite: this.AddressApartmentSuite,
                address_postal_code: this.AddressPostalCode,
                address_city: this.AddressCity,
                primary_color: this.primaryColor
            }
            try {
                console.log(data)
                const response = await api.post(`${this.hostname}/api/v1/create/store`, data)
                this.$router.push(`/add/product/${response.data.store._id}`)
            } catch (error) {
                console.log("Error:", error)
            }
        },
        async UpdateStore() {
            const data = {
                _id: this.store._id,
                owner: JSON.parse(localStorage.getItem('user')).data,
                logo: this.selectedFileDataUrl,
                currency: 'USD',
                store_name: this.StoreName,
                store_url: this.StoreURL,
                contact_email: this.ContactEmail,
                contact_phone: this.ContactPhone,
                store_address: this.StoreAddress,
                address_apartment_suite: this.AddressApartmentSuite,
                address_postal_code: this.AddressPostalCode,
                address_city: this.AddressCity,
                primary_color: this.primaryColor
            }
            try {
                console.log(data)
                await api.post(`${this.hostname}/api/v1/update/store/${this.store._id}`, data)
                this.$router.go()
            } catch (error) {
                console.log("Error:", error)
            }
        },
        async GetUserStores() {
            try {
                const response = await api.get(`${this.hostname}/api/v1/get/user/stores/${JSON.parse(localStorage.getItem('user')).data.id}`)
                console.log(response.data.stores)
                this.userStores = response.data.stores
            } catch (error) {
                console.log("Error:", error)
            }
        },
        async GetProduct(id) {
            try {
                const response = await api.get(`${this.hostname}/api/v1/get/product/${id}`)
                this.name = response.data.product.name
                this.description = response.data.product.description
                this.price = response.data.product.price
                this.category = response.data.product.category
                this.sku = response.data.product.sku
                this.weight = response.data.product.weight
                this.availability = response.data.product.availability
                this.brand = response.data.product.brand
                this.quantity = response.data.product.quantity
                this.images = response.data.product.images
                this.productPage = response.data.product
                return response.data.product
            } catch (error) {
                console.log("Error:", error)
            }
        },
        async EditProduct() {
            const data = {
                _id: this.$route.params.id,
                name: this.name,
                store: this.store,
                description: this.description,
                price: parseFloat(this.price),
                category: this.category,
                sku: this.sku,
                weight: parseFloat(this.weight),
                availability: this.availability,
                brand: this.brand,
                quantity: parseInt(this.quantity),
                images: this.images,
            }
            try {
                const response = await api.post(`${this.hostname}/api/v1/edit/product/${this.$route.params.id}`, data)
                this.$router.go()
            } catch (error) {
                console.log("Error:", error)
            }

        },
        handleXLS(event) {
            const XLSX = require('xlsx');
            const file = event.target.files[0];
            console.log(file)
            if (!file) return; // No file selected

            const reader = new FileReader();

            reader.onload = (event) => {
                const csvData = event.target.result;

                // Parse the CSV data
                const workbook = XLSX.read(csvData, { type: 'string' });

                // Get the first sheet
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];

                // Convert the sheet data into an array of objects
                const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                this.datasheet = XLSX.utils.sheet_to_json(sheet)
                // Extract titles (header row)
                // Extract titles (header row)
                this.dataHeaders = data[0]
                this.showXLSMap = true

                for (let i = 0; i < this.dataHeaders.length; i++) {
                    this.map[this.predefinedHeaders[i]] = this.dataHeaders[i]
                }

            };

            reader.readAsText(file);

        },
        mapXLS() {
            this.load = true
            const mapping = this.map
            const reversedMapping = {};
            for (const key in mapping) {
                reversedMapping[mapping[key].trim()] = key.trim();
            }

            const mappedArray = this.datasheet.map(obj => {
                const mappedObj = {};
                for (const key in obj) {
                    const newKey = reversedMapping[key.trim()] || key.trim(); // Use the reversed key if it exists, otherwise keep the original key
                    mappedObj[newKey.trim()] = obj[key];
                }
                return mappedObj
            })

            mappedArray.forEach(item => {
                this.CreateProduct(item)
            })
        },
        updateHeaders() {
            const newHeaders = document.getElementById('header-input').value.split(',');
            console.log(newHeaders);
            // Process newHeaders here, maybe update data array accordingly
            // You can use originalHeaders to map new headers to old ones
        },
        async GetStoreProducts(e) {
            try {
                const response = await api.get(`${this.hostname}/api/v1/get/store/products/${e}`)
                console.log(response.data.products)
                this.storeProducts = response.data.products
            } catch (error) {
                console.log("Error:", error)
            }
        },
        async CreateProduct(a) {
            var i = []
            var d = ''

            if ('images' in a) {
                i = a.images.split(",")
            } else {
                i = this.images
            }

            if (a.name == undefined) {

            }

            if (a.description == undefined && this.description == '') {
                d = await this.storeNLP(a.name)
            }

            const data = {
                _id: uuidv4(),
                store: this.store,
                name: (a.name != undefined) ? a.name : this.name,
                description: (a.description != undefined) ? a.description : this.description,
                price: (a.price != undefined) ? parseFloat(a.price) : parseFloat(this.price),
                category: (a.category != undefined) ? a.category : this.category,
                sku: (a.sku != undefined) ? a.sku : this.sku,
                weight: (a.weight != undefined) ? parseFloat(a.weight) : parseFloat(0.99),
                availability: true,
                brand: (a.brand != undefined) ? a.brand : this.brand,
                quantity: (a.quantity != undefined) ? parseInt(a.quantity) : parseInt(this.quantity),
                images: i,
            }

            console.log(data)

            try {
                const response = await api.post(`${this.hostname}/api/v1/create/product`, data)
                console.log(response.data)
                this.hasMessage = true
                this.message = `Successfully Added ${response.data.product.name}`
            } catch (error) {
                console.log("Error:", error)
            }


        },
        async storeNLP(a) {
            const data = {
                prompt: `Write a long worded SEO keyword optimised product description for ${a}`,
                context: [],
                tokens: 300
            }
            try {
                const response = await api.post(`${this.hostname}/api/v1/store/nlp`, data)
                return response.data.message.content
            } catch (error) {
                console.log("Error:", error)
            }
        },

        async connectStore(store) {
            var s = store

            if (s.projects === null) {
                s.projects = []
            }
            s.projects.push(this.project._id)
            this.project.store = s

            console.log(s)
            console.log(this.project)

            await this.UpdateProjectConnection()
            await this.UpdateStoreConnection(s)

            this.$router.go(-1)
        },

        async UpdateProjectConnection() {
            try {
                const response = await api.post(
                    `${this.hostname}/api/v1/project/update/${this.project._id}`,
                    this.project
                )
                console.log(response)
            } catch (e) {
                console.log(e)
            }
        },
        async UpdateStoreConnection(s) {
            try {
                const response = await api.post(`${this.hostname}/api/v1/update/store/${s._id}`, s)
                console.log(response)
            } catch (error) {
                console.log("Error:", error)
            }
        }

    },
}

export default EcomMixin