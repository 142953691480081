<template>
	<div v-if="!loading" class="my-5 w-full mx-auto">
		<label for="default-search" class="mb-2 text-sm font-medium text-neutral-900 sr-only dark:text-white">Search</label>
		<div class="relative">
			<div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
				<svg class="w-4 h-4 text-neutral-500 dark:text-neutral-400" aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
					<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
						d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
				</svg>
			</div>
			<input v-model="url" @keyup.enter="clone" placeholder="e.g zookish.com"
				class="block w-full p-4 pl-10 text-sm text-neutral-900 border border-neutral-300 rounded-lg bg-neutral-50 dark:focus:ring-neutral-500 dark:focus:border-neutral-500 focus:border-blue-500 dark:bg-black/50 backdrop-filter dark:border-neutral-100 dark:placeholder-neutral-400 dark:text-white dark:focus:ring-neutral-500 dark:focus:border-neutral-500 dark:outline-white"
				required />
			<button type="submit" @click.prevent="clone" v-if="isUser"
				class="absolute right-2.5 bottom-2.5 p-1.5 text-base font-bold text-neutral-900 rounded-lg bg-neutral-50 hover:bg-neutral-100 group hover:shadow pb-btn text-white">
				go
				<vue-feather type="arrow-right" class="mx-1 mt-[3px]" size="16" />
			</button>
			<a type="submit" href="/api/v1/login" v-else
				class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 dark:bg-neutral-100 dark:hover:bg-neutral-700 dark:focus:ring-neutral-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
				Clone
			</a>
		</div>
		<ul class="max-w-md my-3 space-y-2 text-left text-neutral-500 list-inside dark:text-neutral-400">
			<a href="#"
				class="inline-flex items-left text-left text-xs font-normal text-neutral-500 hover:underline dark:text-neutral-400">
				<svg class="w-3 h-3 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
					viewBox="0 0 20 20">
					<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
						d="M7.529 7.988a2.502 2.502 0 0 1 5 .191A2.441 2.441 0 0 1 10 10.582V12m-.01 3.008H10M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
				</svg>
				This link must be a full website link
			</a>
		</ul>
		<error v-if="hasError" :message="message" @ack="hasError = false" />
	</div>
	<div class="w-full text-center" v-else>
		<h2 class="mb-2 text-lg font-semibold text-neutral-900 dark:text-white">
			Converting your webpage:
		</h2>
		<ul class="space-y-2 text-neutral-500 list-inside dark:text-white">
			<li class="flex items-center">
				<svg class="w-4 h-4 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
					<path
						d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
				</svg>
				Uploading your file to our website
			</li>
			<li class="flex items-center">
				<svg class="w-4 h-4 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
					<path
						d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
				</svg>
				Extrapolating
			</li>
			<li class="flex items-center">
				<div role="status">
					<svg aria-hidden="true"
						class="w-4 h-4 mr-2 text-neutral-100 animate-spin dark:text-neutral-100 fill-blue-600"
						viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
							fill="currentColor" />
						<path
							d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
							fill="currentFill" />
					</svg>
					<span class="sr-only">Loading...</span>
				</div>
				Preparing your website
			</li>
			<li>
				<button @click.prevent="viewDetails = !viewDetails" type="button"
					class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-neutral-800 hover:bg-indigo-600 hover:text-white disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-100 dark:bg-white dark:text-neutral-800">
					{{ (viewDetails) ? 'Hide' : 'View' }} Details
				</button>
			</li>
			<Transition name="slide-right">
				<li v-if="!viewDetails">
					<img src="@/assets/cloning.gif" alt="" class="h-96 mx-auto">
				</li>
			</Transition>

		</ul>
		<Transition name="slide-right">
			<div class="console w-full" v-if="viewDetails">
				<!-- <header>
          <div class="text-black dark:text-white">guest@zookish</div>
        </header> -->
				<div class="consolebody shadow-xl bg-white dark:bg-black/50 backdrop-filter">
					<p v-for="log in logs">> {{ log }}</p>
				</div>
			</div>
		</Transition>

	</div>
</template>
<script>
import { ref, onMounted, onUnmounted, watchEffect, watch, computed, toRaw } from "vue"
import api from "@/services/api"
import { useCloneStore } from "@/stores/CloneStore"
import { useHostStore } from "@/stores/HostStore"
import { useRouter } from "vue-router"
import { v4 as uuidv4 } from "uuid"
export default {
	setup() {
		const url = ref("")
		const html = ref("")
		const tags = ref("")
		const loading = ref(false)
		const hasError = ref(false)
		const viewDetails = ref(false)
		const message = ref("")
		const logs = ref([])
		const sitemap = ref([])
		const project = ref({})
		const cloneData = ref({})
		const screenshot = ref("")
		const cloneStore = useCloneStore()
		const router = useRouter() // Get the router instance
		const sanitizedLink = ref("")
		const invalidInput = ref(true)
		const isPhone = ref(false)
		const hostStoreInstance = useHostStore()
		const hostnameObj = computed(() => hostStoreInstance.hostname)
		const hostname = hostnameObj.value
		const userObj = ref(localStorage.getItem("user"))
		const isUser = computed(() => userObj.value !== null)
		let evtSrc; // Declare evtSrc using let instead of const

		onMounted(() => {
			checkIfPhone()
			console.log(hostname)
			evtSrc = new EventSource(hostname + "/api/v1/clone/events");
			evtSrc.onmessage = event => {
				console.log(event.data)
				logs.value.push(event.data)
			}
			// Event triggered when the connection is opened
			evtSrc.addEventListener('open', (event) => {
				console.log('Connection opened:', event);
			});

			// Event triggered when a message is received
			evtSrc.addEventListener('message', (event) => {
				console.log('Message received:', event.data);
				logs.value.push(event.data);
			});

			// Event triggered when an error occurs
			evtSrc.addEventListener('error', (event) => {
				if (event.eventPhase === EventSource.CLOSED) {
					console.log('Connection closed:', event);
				} else {
					console.error('Error occurred:', event);
				}
			});
		})
		onUnmounted(() => {
			if (evtSrc) {
				evtSrc.close();
			}
		});
		watchEffect(() => {
			// Recreate EventSource when route changes
			if (evtSrc) {
				evtSrc.close();
			}
			evtSrc = new EventSource(hostname + "/api/v1/clone/events");
			evtSrc.onmessage = event => {
				console.log(event.data);
				logs.value.push(event.data);
			};
			// Event triggered when the connection is opened
			evtSrc.addEventListener('open', (event) => {
				console.log('Connection opened:', event);
			});

			// Event triggered when a message is received
			evtSrc.addEventListener('message', (event) => {
				console.log('Message received:', event.data);
				logs.value.push(event.data);
			});

			// Event triggered when an error occurs
			evtSrc.addEventListener('error', (event) => {
				if (event.eventPhase === EventSource.CLOSED) {
					console.log('Connection closed:', event);
				} else {
					console.error('Error occurred:', event);
				}
			});
		});

		watch(url, () => {
			// Automatically sanitize whenever the input value changes
			sanitizeAndDisplay()
		})
		const checkIfPhone = () => {
			const userAgent = navigator.userAgent
			const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				userAgent
			)
			const isNarrowScreen = window.innerWidth <= 768 // You can  adjust this threshold as needed
			isPhone.value = isMobileDevice || isNarrowScreen
		}
		async function getTime() {
			try {
				const res = await fetch(hostname + "/time")
				console.log(res)
			} catch (error) {
				console.error("Error:", error)
			}
		}
		const sanitizeLink = url => {
			url = url.trim()
			// Check if the URL contains a valid domain or domain with route
			const domainWithRoutePattern = /^(https?:\/\/)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[^?#]*)?$/
			if (domainWithRoutePattern.test(url)) {
				if (!/^https?:\/\//.test(url)) {
					url = `https://${url}`
				}
				return url.replace(/\/?$/, "/")
			} else {
				return null
			}
		}
		const sanitizeAndDisplay = () => {
			invalidInput.value = false
			sanitizedLink.value = ""
			const result = sanitizeLink(url.value)
			if (result) {
				sanitizedLink.value = result
			} else {
				invalidInput.value = true
			}
			console.log(sanitizedLink.value)
		}
		async function clone() {

			loading.value = true
			const data = {
				urls: [url.value],
				cookies: ["cookie1=value1; cookie2=value2"],
				proxy: "",
				user_agent: "My User Agent",
				serve: false,
				serve_port: 8081,
				open: false,
			}
			console.log(data)
			try {
				const response = await api.post(`${hostname}/api/v1/clone`, data)
				const r = await api.post(`${hostname}/api/v1/crawl`, data)
				html.value = r.data.data

				await tag(html.value)

				console.log("Crawl Response:", r.data.data)
				console.log("Crawl Response:", r.data.screenshot.screenshot)
				console.log("Response:", response.data)
				cloneStore.setResponse(response.data)
				cloneData.value = response.data
				screenshot.value = r.data.screenshot.screenshot
				sitemap.value = r.data.sitemap
				create()
			} catch (error) {
				console.error("Error:", error)
				if (error && error.response && error.response.data) {
					// error.response.data exists, you can handle it here
					message.value = error.response.data
				} else {
					// error.response.data does not exist, handle the absence accordingly
					message.value = error
				}

				hasError.value = true
			} finally {
			}
		}
		function extractTextFromHTML(htmlString) {
			// Create a new DOM parser
			// Create a new DOM parser
			var parser = new DOMParser()

			// Parse the HTML string into a document object
			var doc = parser.parseFromString(htmlString, "text/html")

			// Define the tags you are interested in
			var tags = ["p", "h1", "h2", "h3", "h4", "h5", "h6", "span", "div"]

			// Get all elements for these tags and extract their text content
			var textContents = tags.flatMap(tag =>
				Array.from(doc.querySelectorAll(tag), el => el.textContent)
			)

			// Join all text contents with a space (or any other delimiter)
			return textContents.join(" ")
		}

		function trimStringToMaxLength(str, maxLength) {
			if (str.length > maxLength) {
				return str.slice(0, maxLength)
			}
			return str
		}
		async function tag(html) {
			var text = extractTextFromHTML(html)

			const data = {
				payload: trimStringToMaxLength(text, 4097),
			}
			try {
				const response = await api.post(
					`${hostname}/api/v1/aitag/project`,
					data
				)
				console.log("Response:", response.data)
				tags.value = JSON.parse(response.data.message.content).categories
			} catch (error) {
				console.error("Error:", error)
			}
		}
		function addSubmitButtonClassToButtons(htmlString) {
			const parser = new DOMParser()
			const doc = parser.parseFromString(htmlString, "text/html")

			// Find all input elements
			const inputs = doc.querySelectorAll("input")

			// Iterate over each input element
			inputs.forEach(input => {
				// Find all button elements within the same parent
				const buttons = Array.from(
					input.parentElement.querySelectorAll("button")
				)

				// Add 'submitButton' class to these buttons
				buttons.forEach(button => {
					button.classList.add("submitButton")
				})
			})

			// Serialize the Document back to a string
			return doc.body.innerHTML
		}
		async function create() {
			const user = JSON.parse(localStorage.getItem("user"))
			console.log(user.data.id)

			var generate = require("boring-name-generator")
			var slug = generate({
				number: true,
			}).dashed
			var o = {}
			o.sitemap = sitemap.value
			const data = {
				_id: uuidv4(),
				title: slug,
				slug: slug,
				cloneOf: url.value,
				sitemap: JSON.stringify(sitemap.value),
				domain: "your-domain.com",
				template: true,
				tags: JSON.stringify(tags.value),
				metadata: JSON.stringify(o),
				owner: user.data,
				baselinks: toRaw(cloneData)._rawValue.uploaded_urls,
				pages: [],
				created_at: new Date().toISOString(),
				updated_at: new Date().toISOString(),
			}
			console.log(data)
			try {
				const response = await api.post(
					`${hostname}/api/v1/create/project`,
					data
				)
				console.log("Response:", response.data)
				// create home page
				// router.push("/editor");
				project.value = response.data
				createPage()
			} catch (error) {
				console.error("Error:", error)
				message.value = error
				hasError.value = true
			} finally {
			}
		}

		async function createPage() {
			console.log(toRaw(project.value))
			const data = {
				_id: uuidv4(),
				title: "home",
				content: "",
				html: html.value,
				project: toRaw(project.value).data,
				created_at: new Date().toISOString(),
				updated_at: new Date().toISOString(),
			}
			console.log(data)
			try {
				const response = await api.post(
					`${hostname}/api/v1/create/page`,
					data
				)
				console.log("Response:", response.data)
				router.push(`/editor/${response.data.page._id}`)
			} catch (error) {
				console.error("Error:", error)
				message.value = error
				hasError.value = true
			} finally {
			}
		}
		return {
			url,
			userObj,
			isUser,
			html,
			loading,
			logs,
			getTime,
			screenshot,
			sitemap,
			clone,
			create,
			project,
			sanitizedLink,
			invalidInput,
			sanitizeAndDisplay,
			hasError,
			tags,
			message,
			viewDetails
		}
	},
}
</script>

<style>
body {
	font-size: large;
}

@media screen and (max-width: 499px) {
	.search {
		width: 320px !important;
	}

	.search button {
		width: 60px !important;
	}

	.search input {
		width: 250px !important;
	}

	/* .console {
    width: 30vh !important;
  } */
}

/* @media screen and (max-width: 699px) {
  .console {
    width: 40vh !important;
  }
} */

.search {
	width: 500px;
	height: 60px;
	background: #444;
	background: rgba(0, 0, 0, 0.2);
	border-radius: 3px;
	border: 1px solid #fff;
}

.search input {
	width: 370px;
	padding: 10px 5px;
	float: left;
	font-size: x-large;
	color: #ccc;
	border: 0;
	background: transparent;
	border-radius: 3px 0 0 3px;
}

.search input:focus {
	outline: 0;
	background: transparent;
}

.search button {
	position: relative;
	float: right;
	border: 0;
	padding: 0;
	font-size: large;
	cursor: pointer;
	height: 60px;
	width: 120px;
	color: #fff;
	background: transparent;
	border-left: 1px solid #fff;
	border-radius: 0 3px 3px 0;
}

.search button:hover {
	background: #fff;
	color: #444;
}

.search button:active {
	box-shadow: 0px 0px 12px 0px rgba(225, 225, 225, 1);
}

.search button:focus {
	outline: 0;
}

body {
	min-width: 100vw;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.console {
	margin-top: 30px;
	font-family: "Fira Mono";
	width: 100%;
	height: auto;
	box-sizing: border-box;
}

.console header {
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	background-color: #555;
	height: 45px;
	padding: 5px;
	line-height: 45px;
	text-align: start;
	color: #ddd;
}

.console .consolebody {
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	box-sizing: border-box;
	text-align: start;
	height: 400px;
	width: 100%;
	padding: 20px;
	overflow-x: hidden;
	overflow-y: scroll;
	scrollbar-color: #fff;
	scrollbar-width: 10px;
	color: #fff !important;
}

.console .consolebody p {
	color: #fff !important;
}

.console .consolebody p {
	line-height: 1.5rem;
}

.slide-enter-active,
.slide-leave-active {
	transition: transform 0.5s;
}

.slide-enter,
.slide-leave-to

/* .slide-leave-active in <2.1.8 */
	{
	transform: translateX(0);
}

.slide-leave-active,
.slide-enter-to

/* .slide-enter-active in <2.1.8 */
	{
	transform: translateX(100%);
}
</style>
