<template>
  <section class="section-hero-home">
    <div class="hero-wrapper">
      <div class="home-hero">
        <div class="preview-hero">
          <div class="hero-home-content p-0">
            <navigation />
            <div class="home p-0">
              <video
                muted
                loop
                autoplay>
                <source
                  src="@/assets/lion.mp4"
                  type="video/mp4" />
              </video>
              <div class="home-content">
                <div class="w-full p-4 text-center bg-transparent">
                  <h1 class="heading-xhuge text-white">
                    World Wild<br />
                    <span class="text-span">Web</span> Design
                  </h1>
                  <p class="text-size-regular my-2 text-white">
                    Clone, edit and deploy any website in minutes!
                  </p>
                  <a
                    href="/api/v1/login"
                    class="bg-black my-3 inline-flex items-center justify-center px-4 py-2.5 text-base font-medium text-center bg-primary-700 rounded-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 focus:ring-primary-900">
                    Get started for free
                  </a>
                  <div class="mx-auto mt-5">
                    <div
                      class="center animate-bounce bg-black p-2 w-10 h-10 ring-1 ring-slate-200/20 shadow-lg rounded-full flex items-center justify-center">
                      <svg
                        class="w-6 h-6 text-white"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
                      </svg>
                    </div>

                    <p class="text-white text-xs">Scroll Down</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        data-w-id="b45e44b7-89d4-2d9d-5e42-693294cf4c4e"
        class="hero-trigger"></div>
    </div>
  </section>
</template>
<script>
  import navigation from "./navigation.vue";
  export default {
    components: {
      navigation,
    },
  };
</script>
<style>
  .text-white {
    color: white !important;
  }
  .bg-black {
    background-color: black !important;
  }
  *,
  ::before,
  ::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .home {
    height: 100vh;
    width: 100%;
    position: relative;
  }

  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .home-content {
    position: relative;
    padding-top: 150px;
    color: #fff;
    text-align: center;
  }

  .home p {
    margin-top: 10px;
  }

  .home-content button {
    display: block;
    font-size: clamp(14px, 1.5vw, 18px);
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    background: transparent;
    color: #fff;
    margin: 50px auto 0;
    padding: 12px 20px;
    cursor: pointer;
  }

  .section-sticky-cards {
    background-image: transparent !important;
    background: transparent !important;
  }

  .center {
    margin: auto;
    padding: 10px;
  }
</style>
<style
  src="@/assets/webflow.min.css"
  scoped></style>
