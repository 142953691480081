<template>
	<!-- Hero -->
	<div>
		<!-- Grid -->
		<div class="grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-20 md:items-center">
			<div class="mx-auto justify-center">
				<div
					class="w-full max-w-sm p-4 bg-white border border-neutral-200 rounded-lg shadow sm:p-6 dark:bg-neutral-800 dark:border-neutral-700"
				>
					<h5
						class="mb-3 text-base font-semibold text-neutral-900 md:text-xl dark:text-white"
					>
						What/How do you want to build?
					</h5>
					<p class="text-sm font-normal text-neutral-500 dark:text-neutral-400">
						Choose an option below depending on what best fits your project
					</p>
					<ul class="my-4 space-y-3">
						<li>
							<router-link
								to="/create/project/clone"
								class="flex items-center p-3 text-base font-bold text-neutral-900 rounded-lg bg-neutral-50 hover:bg-neutral-100 group hover:shadow dark:bg-neutral-600 dark:hover:bg-neutral-500 dark:text-white"
							>
								<vue-feather type="copy" />
								<span class="flex-1 ms-3 whitespace-nowrap">Z-Clone</span>
							</router-link>
						</li>
						<!-- <li>
							<router-link
								to="/onboarding"
								class="flex items-center p-3 text-base font-bold text-neutral-900 rounded-lg bg-neutral-50 hover:bg-neutral-100 group hover:shadow dark:bg-neutral-600 dark:hover:bg-neutral-500 dark:text-white"
							>
								<vue-feather type="zap" />
								<span class="flex-1 ms-3 whitespace-nowrap">Z-AI</span>
							</router-link>
						</li> -->
						<!-- <li>
							<router-link
								to="/project/zmix"
								class="flex items-center p-3 text-base font-bold text-neutral-900 rounded-lg bg-neutral-50 hover:bg-neutral-100 group hover:shadow dark:bg-neutral-600 dark:hover:bg-neutral-500 dark:text-white"
							>
								<vue-feather type="refresh-cw" />
								<span class="flex-1 ms-3 whitespace-nowrap">Z-Mix</span>
							</router-link>
						</li>
						<li>
							<a
								href="#"
								class="flex items-center p-3 text-base font-bold text-neutral-900 rounded-lg bg-neutral-50 hover:bg-neutral-100 group hover:shadow dark:bg-neutral-600 dark:hover:bg-neutral-500 dark:text-white"
							>
								<vue-feather type="edit" />
								<span class="flex-1 ms-3 whitespace-nowrap">Z-AI Blogger</span>
							</a>
						</li> -->
					</ul>
					<div>
						<a
							href="#"
							class="inline-flex items-center text-xs font-normal text-neutral-500 hover:underline dark:text-neutral-400"
						>
							<svg
								class="w-3 h-3 me-2"
								aria-hidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 20 20"
							>
								<path
									stroke="currentColor"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M7.529 7.988a2.502 2.502 0 0 1 5 .191A2.441 2.441 0 0 1 10 10.582V12m-.01 3.008H10M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
								/>
							</svg>
							Easy options to get started</a
						>
					</div>
				</div>
			</div>
			<!-- End Col -->

			<div class="relative ms-4">
				<img class="w-full" src="@/assets/clip4.gif" alt="Image Description" />
				<div
					class="absolute inset-0 -z-[1] bg-gradient-to-tr from-gray-200 via-white/0 to-white/0 w-full h-full rounded-md mt-4 -mb-4 me-4 -ms-4 lg:mt-6 lg:-mb-6 lg:me-6 lg:-ms-6 dark:from-slate-800 dark:via-slate-900/0 dark:to-slate-900/0"
				></div>

				<!-- End SVG-->
			</div>
			<!-- End Col -->
		</div>
		<!-- End Grid -->
	</div>
	<!-- End Hero -->
</template>
<script>
	export default {
		methods: {
			zmix() {},
		},
	}
</script>
