const AddHeadMixin = {
    methods: {
        addHead() {
            // Set the page title
            const pageTitle = "Discover Our Revolutionary AI Website Builder!";

            // Create a new title element
            const newTitleElement = document.createElement("title");

            // Set the text content of the title element
            newTitleElement.textContent = pageTitle;

            // Append the new title element to the head of the document
            document.head.appendChild(newTitleElement);
            // Open Graph Meta Tags
            const ogMetaTags = [
                { property: "og:url", content: "https://zookish.com" },
                { property: "og:type", content: "website" },
                { property: "og:title", content: "Discover Our Revolutionary AI Website Builder!" },
                { property: "og:description", content: "Explore the future of website creation with our AI-powered Website Builder. Tailored for ease and efficiency, our tool is designed to transform your digital presence." },
                { property: "og:image", content: "https://res.cloudinary.com/dxwy9kwne/image/upload/v1704325169/start_wfyrxp.jpg" },
            ];

            // Twitter Meta Tags
            const twitterMetaTags = [
                { name: "twitter:card", content: "summary_large_image" },
                { name: "twitter:title", content: "Discover Our Revolutionary AI Website Builder!" },
                { name: "twitter:description", content: "Explore the future of website creation with our AI-powered Website Builder. Tailored for ease and efficiency, our tool is designed to transform your digital presence." },
                { name: "twitter:image", content: "https://res.cloudinary.com/dxwy9kwne/image/upload/v1704325169/start_wfyrxp.jpg" },
            ];

            // Favicon Links
            const faviconLinks = [
                { rel: "shortcut icon mask-icon", href: "https://res.cloudinary.com/dxwy9kwne/image/upload/v1691897392/zookish_1_ee8kjx-removebg-preview_jnc36m.png", color: "#000000" },
                { rel: "shortcut icon", href: "https://res.cloudinary.com/dxwy9kwne/image/upload/v1691897392/zookish_1_ee8kjx-removebg-preview_jnc36m.png" },
            ];

            // Add Open Graph Meta Tags to the head
            ogMetaTags.forEach(tag => {
                const newMetaTag = document.createElement("meta");
                newMetaTag.setAttribute("property", tag.property);
                newMetaTag.setAttribute("content", tag.content);
                document.head.appendChild(newMetaTag);
            });

            // Add Twitter Meta Tags to the head
            twitterMetaTags.forEach(tag => {
                const newMetaTag = document.createElement("meta");
                newMetaTag.setAttribute("name", tag.name);
                newMetaTag.setAttribute("content", tag.content);
                document.head.appendChild(newMetaTag);
            });

            // Add Favicon Links to the head
            faviconLinks.forEach(link => {
                const newFaviconLink = document.createElement("link");
                Object.entries(link).forEach(([key, value]) => {
                    newFaviconLink.setAttribute(key, value);
                });
                document.head.appendChild(newFaviconLink);
            });
            document.dispatchEvent(new Event('render-event'));

        }
    },
}

export default AddHeadMixin