<template>
	<body class="bg-neutral-50 dark:bg-neutral-900">
		<!-- Sidebar Toggle -->
		<div
			class="sticky top-0 inset-x-0 z-20 bg-white border-y px-4 sm:px-6 md:px-8 lg:hidden dark:bg-neutral-800 dark:border-neutral-700">
			<div class="flex items-center py-4">
				<!-- Navigation Toggle -->
				<button type="button" class="text-neutral-500 hover:text-neutral-600"
					data-hs-overlay="#application-sidebar-dark" aria-controls="application-sidebar-dark"
					aria-label="Toggle navigation">
					<span class="sr-only">Toggle Navigation</span>
					<svg class="w-5 h-5" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
						<path fill-rule="evenodd"
							d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
					</svg>
				</button>
				<!-- End Navigation Toggle -->

				<!-- Breadcrumb -->
				<ol class="ms-3 flex items-center whitespace-nowrap" aria-label="Breadcrumb">
					<li class="flex items-center text-sm text-neutral-800 dark:text-neutral-400">
						<img src="@/assets/icon.png" alt="" class="h-10">
						<svg class="flex-shrink-0 mx-3 overflow-visible h-2.5 w-2.5 text-neutral-400 dark:text-neutral-600"
							width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M5 1L10.6869 7.16086C10.8637 7.35239 10.8637 7.64761 10.6869 7.83914L5 14"
								stroke="currentColor" stroke-width="2" stroke-linecap="round" />
						</svg>
					</li>
					<li class="text-sm font-semibold text-neutral-800 truncate dark:text-neutral-400" aria-current="page">
						{{ $route.name }}
					</li>
				</ol>
				<!-- End Breadcrumb -->
			</div>
		</div>
		<!-- End Sidebar Toggle -->

		<!-- Sidebar -->
		<div id="application-sidebar-dark"
			class="hs-overlay hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform hidden fixed top-0 start-0 bottom-0 z-[60] w-64 bg-neutral-900 border-e border-neutral-800 pt-7 pb-10 overflow-y-auto lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 ">


			<nav class="hs-accordion-group p-6 w-full flex flex-col flex-wrap" data-hs-accordion-always-open>
				<ul class="space-y-1.5">
					<li>
						<router-link
							class="flex items-center gap-x-3 py-2 px-2.5 bg-neutral-700 text-sm text-white rounded-lg focus:outline-none focus:ring-1 focus:ring-neutral-600"
							to="/dashboard">
							<vue-feather type="activity" />
							Dashboard
						</router-link>
					</li>
					<li class="hs-accordion" id="users-accordion">
						<button type="button"
							class="hs-accordion-toggle w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 hs-accordion-active:text-white hs-accordion-active:hover:bg-transparent text-sm text-neutral-400 rounded-lg hover:bg-neutral-800 hover:text-white focus:outline-none focus:ring-1 focus:ring-neutral-600">
							<vue-feather type="tool" />
							Projects

							<svg class="hs-accordion-active:block ms-auto hidden w-4 h-4" xmlns="http://www.w3.org/2000/svg"
								width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
								stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
								<path d="m18 15-6-6-6 6" />
							</svg>

							<svg class="hs-accordion-active:hidden ms-auto block w-4 h-4" xmlns="http://www.w3.org/2000/svg"
								width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
								stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
								<path d="m6 9 6 6 6-6" />
							</svg>
						</button>

						<div id="users-accordion-child"
							class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300">
							<ul class="hs-accordion-group ps-3 pt-2" data-hs-accordion-always-open>
								<li class="hs-accordion" id="users-accordion-sub-1">
									<button type="button"
										class="hs-accordion-toggle w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 hs-accordion-active:text-white hs-accordion-active:hover:bg-transparent text-sm text-neutral-400 rounded-lg hover:bg-neutral-800 hover:text-white focus:outline-none focus:ring-1 focus:ring-neutral-600">
										Create Project
										<svg class="hs-accordion-active:block ms-auto hidden w-4 h-4"
											xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
											fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
											stroke-linejoin="round">
											<path d="m18 15-6-6-6 6" />
										</svg>

										<svg class="hs-accordion-active:hidden ms-auto block w-4 h-4"
											xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
											fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
											stroke-linejoin="round">
											<path d="m6 9 6 6 6-6" />
										</svg>

									</button>
									<div
										class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300">
										<ul class="pt-2 ps-2">
											<li>
												<router-link
													class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-neutral-400 rounded-lg hover:bg-neutral-800 hover:text-white focus:outline-none focus:ring-1 focus:ring-neutral-600"
													to="/create/project/clone">
													Generate Site
												</router-link>
											</li>
										</ul>
									</div>



								</li>


								<li class="hs-accordion" id="users-accordion-sub-2">
									<router-link type="button" to="/projects"
										class="hs-accordion-toggle w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 hs-accordion-active:text-white hs-accordion-active:hover:bg-transparent text-sm text-neutral-400 rounded-lg hover:bg-neutral-800 hover:text-white focus:outline-none focus:ring-1 focus:ring-neutral-600">
										View Projects
									</router-link>

								</li>
							</ul>
						</div>
					</li>
					<li><router-link
							class="w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-neutral-400 rounded-lg hover:bg-neutral-800 hover:text-white-300 focus:outline-none focus:ring-1 focus:ring-neutral-600"
							to="/stores">
							<vue-feather type="shopping-bag" />
							Stores
						</router-link></li>
					<li><router-link
							class="w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-neutral-400 rounded-lg hover:bg-neutral-800 hover:text-white-300 focus:outline-none focus:ring-1 focus:ring-neutral-600"
							to="settings">
							<vue-feather type="settings" />
							Manage settings
						</router-link></li>
					<li><router-link
							class="w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-neutral-400 rounded-lg hover:bg-neutral-800 hover:text-white-300 focus:outline-none focus:ring-1 focus:ring-neutral-600"
							to="tos">
							<vue-feather type="life-buoy" />
							Privacy Policy
						</router-link></li>
				</ul>

			</nav>
		</div>
		<!-- End Sidebar -->

		<!-- Content -->
		<div class="w-full lg:ps-64">
			<!-- Content -->
			<listProjects v-if="$route.name == 'projects'" />
			<createProject v-if="$route.name == 'Create Project'" />
			<readProject v-if="$route.name == 'Read Project'" />
			<!-- Hero -->
			<welcome v-if="$route.name == 'clone'" />
			<!-- End Hero -->

			<configure v-if="$route.name == 'Configure Project'" />
			<dns v-if="$route.name == 'Add Custom Hostname'" />
			<zmix v-if="$route.name == 'zmix'" />
			<stores v-if="$route.name == 'View Stores' || $route.name == 'Connect Store'" />
			<dataComponent v-if="$route.name == 'Page Data'" />
			<dashboard v-if="$route.name == 'Project Dashboard' || $route.name == 'dashboard'" />
			<settings v-if="$route.name == 'settings'" />
			<!-- End Page Heading -->
		</div>
		<!-- End Content -->
		<!-- ========== END MAIN CONTENT ========== -->
	</body>
</template>
<script>
import dashboard from "@/views/dashboard/index.vue"
import settings from "@/views/settings/index.vue"
import listProjects from "./list.vue"
import createProject from "./create.vue"
import readProject from "./read.vue"
import clone from "./clone.vue"
import configure from "./configure.vue"
import dns from "./dns.vue"
import dataComponent from "./data"
import zmix from "./zmix"
import templates from "./templates"
import subscribe from "@/views/editor/subscribe.vue"
import LeaderboardMixin from "@/mixins/LeaderboardMixin"
import sidebar from './sidebar.vue'
import welcome from './welcome.vue'
import stores from "./stores.vue"
import AddHeadMixin from "@/mixins/AddHead"
export default {
	components: {
		clone,
		listProjects,
		createProject,
		readProject,
		configure,
		dns,
		zmix,
		dataComponent,
		templates,
		subscribe,
		dashboard,
		settings,
		sidebar,
		welcome,
		stores
	},
	mixins: [LeaderboardMixin, AddHeadMixin],
	mounted() {
		this.addHead()
	},
	computed: {
		user() {
			return JSON.parse(localStorage.getItem("user"))
		},
		hasSubscription() {
			// Check if user who owns the project has an active subscription
			// This does not work, need to call stripe directly to see if sub was renewed

			if (JSON.parse(localStorage.getItem("user")).data.subscription != "") {
				const sub = JSON.parse(
					JSON.parse(localStorage.getItem("user")).data.subscription
				)
				console.log(sub.status)
				if (sub.status == "active" || sub.status == "trialing") {
					return true
				}
				return false
			} else {
				return false
			}
		},
	},
}
</script>
